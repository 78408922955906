import { Injectable } from '@angular/core'
import { getState } from '@app/srv/store.service'
import { PageGuard } from '../../guards/page.guard'

@Injectable({
  providedIn: 'root',
})
export class PartnerViewGuard extends PageGuard {
  className = 'PartnerViewGuard'

  get requirements(): boolean[] {
    const requirements = [!!getState().partnerAccount]

    return requirements
  }
}
