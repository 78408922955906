import { storage3Service } from '@app/srv/storage3/storage3.service'
import { distinctUntilDeeplyChanged } from '@app/util/distinctUntilDeeplyChanged'
import { logUtil } from '@app/util/log.util'
import { BackendResponseFM, GlossaryResponse } from '@naturalcycles/shared'
import { Action, Reducer } from 'redux'
import { Subject } from 'rxjs'
import { debounceTime, mergeMap } from 'rxjs/operators'

export interface GlossaryState extends GlossaryResponse {}

type State = GlossaryState

const initialState: State = {
  glossaryHash: '',
  items: [],
}

const actions: any = {
  onBackendResponse(state: State, action: { type: string; payload: BackendResponseFM }): State {
    if (action.payload.glossary) {
      // Side-effect
      persistGlossarySubject.next(action.payload.glossary)

      return { ...action.payload.glossary }
    }

    return state
  },
}

// biome-ignore lint/style/useDefaultParameterLast: ok
export const glossaryReducer: Reducer<State> = (state = initialState, action: Action) => {
  if (actions[action.type]) return actions[action.type](state, action)
  return state
}

const persistGlossarySubject = new Subject<GlossaryResponse | undefined>()

persistGlossarySubject
  .pipe(
    debounceTime(1000),
    distinctUntilDeeplyChanged(),
    mergeMap(async glossary => {
      await storage3Service.setItem('glossary', glossary).catch(err => {
        logUtil.log('glossary.reducer Error storing glossary')
        logUtil.error(err)
      })
    }, 1),
  )
  .subscribe()
