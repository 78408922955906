<div class="multiEntries__content">
  @if (title) {
    <h2 class="ion-padding-horizontal">{{ title | tr }}</h2>
  }

  @if (body) {
    <p class="ion-padding-horizontal" [innerHTML]="body | tmd"></p>
  }

  @for (item of entryItems; track item.date) {
    <ion-radio-group
      [allowEmptySelection]="!item.hasSavedTemps"
      [value]="0"
      [name]="item.date"
      [class.multi]="item.entries.length !== 1"
    >
      @for (entry of item.entries; track $index; let i = $index) {
        <ion-radio
          [class.entryItem--editable]="editable"
          slot="start"
          mode="ios"
          labelPlacement="end"
          justify="start"
          [value]="i"
        >
          @if (!editable && entry.icon; as icon) {
            <div
              class="entryItem__statusIcon"
              [class.entryItem__statusIcon--temperature]="icon.color === 'white'"
            >
              <ion-icon [color]="icon.color" [src]="icon.icon" />
            </div>
          }

          <div
            class="entryItem__temperature"
            [class.entryItem__temperature--deviating]="entry.deviating"
          >
            {{ entry.temperature | temperature }}
          </div>

          @if (entry.temperatureMeasuredTimestamp; as timestamp) {
            {{ timestamp | nc_date: 'MEDIUM_WITH_TIME' }}
          } @else {
            {{ entry.date | nc_date: 'MEDIUM' }}
          }

          @if (
            !isOura && !entry.temperatureMeasuredTimestamp && entry.temperatureUpdatedTimestamp
          ) {
            <ion-chip color="pink">
              <ion-label>{{ 'txt-manual' | tr }}</ion-label>
            </ion-chip>
          }
        </ion-radio>
      }
    </ion-radio-group>
  }
</div>

<ion-footer class="footer--shadow">
  <ion-toolbar color="transparent">
    <ion-buttons slot="end">
      <ion-button uid="multiEntries__close" color="primary" (click)="dismiss()">
        <ion-label>{{ dismissTitle | tr }}</ion-label>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
