/**
  Maps from 5-digit integer temperature values in Celsius to corresponding Celsius and Fahrenheit temperature rounded to 2 decimals
 
  C function used to generate expected values
    printf("%.2f\n", (float)temperature / 1000);
 
  For fahrenheit, integer celsius values are converted using this formula *before* rounding
    (((temperatureF_t)(celsius) * 9) / 5) + (32 * 1000);
 */

export const T3_TEMPERATURE_MAP = {
  '32000': '32.00',
  '32001': '32.00',
  '32002': '32.00',
  '32003': '32.00',
  '32004': '32.00',
  '32005': '32.01',
  '32006': '32.01',
  '32007': '32.01',
  '32008': '32.01',
  '32009': '32.01',
  '32010': '32.01',
  '32011': '32.01',
  '32012': '32.01',
  '32013': '32.01',
  '32014': '32.01',
  '32015': '32.01',
  '32016': '32.02',
  '32017': '32.02',
  '32018': '32.02',
  '32019': '32.02',
  '32020': '32.02',
  '32021': '32.02',
  '32022': '32.02',
  '32023': '32.02',
  '32024': '32.02',
  '32025': '32.03',
  '32026': '32.03',
  '32027': '32.03',
  '32028': '32.03',
  '32029': '32.03',
  '32030': '32.03',
  '32031': '32.03',
  '32032': '32.03',
  '32033': '32.03',
  '32034': '32.03',
  '32035': '32.03',
  '32036': '32.04',
  '32037': '32.04',
  '32038': '32.04',
  '32039': '32.04',
  '32040': '32.04',
  '32041': '32.04',
  '32042': '32.04',
  '32043': '32.04',
  '32044': '32.04',
  '32045': '32.04',
  '32046': '32.05',
  '32047': '32.05',
  '32048': '32.05',
  '32049': '32.05',
  '32050': '32.05',
  '32051': '32.05',
  '32052': '32.05',
  '32053': '32.05',
  '32054': '32.05',
  '32055': '32.06',
  '32056': '32.06',
  '32057': '32.06',
  '32058': '32.06',
  '32059': '32.06',
  '32060': '32.06',
  '32061': '32.06',
  '32062': '32.06',
  '32063': '32.06',
  '32064': '32.06',
  '32065': '32.06',
  '32066': '32.07',
  '32067': '32.07',
  '32068': '32.07',
  '32069': '32.07',
  '32070': '32.07',
  '32071': '32.07',
  '32072': '32.07',
  '32073': '32.07',
  '32074': '32.07',
  '32075': '32.08',
  '32076': '32.08',
  '32077': '32.08',
  '32078': '32.08',
  '32079': '32.08',
  '32080': '32.08',
  '32081': '32.08',
  '32082': '32.08',
  '32083': '32.08',
  '32084': '32.08',
  '32085': '32.08',
  '32086': '32.09',
  '32087': '32.09',
  '32088': '32.09',
  '32089': '32.09',
  '32090': '32.09',
  '32091': '32.09',
  '32092': '32.09',
  '32093': '32.09',
  '32094': '32.09',
  '32095': '32.10',
  '32096': '32.10',
  '32097': '32.10',
  '32098': '32.10',
  '32099': '32.10',
  '32100': '32.10',
  '32101': '32.10',
  '32102': '32.10',
  '32103': '32.10',
  '32104': '32.10',
  '32105': '32.10',
  '32106': '32.11',
  '32107': '32.11',
  '32108': '32.11',
  '32109': '32.11',
  '32110': '32.11',
  '32111': '32.11',
  '32112': '32.11',
  '32113': '32.11',
  '32114': '32.11',
  '32115': '32.12',
  '32116': '32.12',
  '32117': '32.12',
  '32118': '32.12',
  '32119': '32.12',
  '32120': '32.12',
  '32121': '32.12',
  '32122': '32.12',
  '32123': '32.12',
  '32124': '32.12',
  '32125': '32.12',
  '32126': '32.13',
  '32127': '32.13',
  '32128': '32.13',
  '32129': '32.13',
  '32130': '32.13',
  '32131': '32.13',
  '32132': '32.13',
  '32133': '32.13',
  '32134': '32.13',
  '32135': '32.13',
  '32136': '32.14',
  '32137': '32.14',
  '32138': '32.14',
  '32139': '32.14',
  '32140': '32.14',
  '32141': '32.14',
  '32142': '32.14',
  '32143': '32.14',
  '32144': '32.14',
  '32145': '32.15',
  '32146': '32.15',
  '32147': '32.15',
  '32148': '32.15',
  '32149': '32.15',
  '32150': '32.15',
  '32151': '32.15',
  '32152': '32.15',
  '32153': '32.15',
  '32154': '32.15',
  '32155': '32.15',
  '32156': '32.16',
  '32157': '32.16',
  '32158': '32.16',
  '32159': '32.16',
  '32160': '32.16',
  '32161': '32.16',
  '32162': '32.16',
  '32163': '32.16',
  '32164': '32.16',
  '32165': '32.17',
  '32166': '32.17',
  '32167': '32.17',
  '32168': '32.17',
  '32169': '32.17',
  '32170': '32.17',
  '32171': '32.17',
  '32172': '32.17',
  '32173': '32.17',
  '32174': '32.17',
  '32175': '32.17',
  '32176': '32.18',
  '32177': '32.18',
  '32178': '32.18',
  '32179': '32.18',
  '32180': '32.18',
  '32181': '32.18',
  '32182': '32.18',
  '32183': '32.18',
  '32184': '32.18',
  '32185': '32.19',
  '32186': '32.19',
  '32187': '32.19',
  '32188': '32.19',
  '32189': '32.19',
  '32190': '32.19',
  '32191': '32.19',
  '32192': '32.19',
  '32193': '32.19',
  '32194': '32.19',
  '32195': '32.19',
  '32196': '32.20',
  '32197': '32.20',
  '32198': '32.20',
  '32199': '32.20',
  '32200': '32.20',
  '32201': '32.20',
  '32202': '32.20',
  '32203': '32.20',
  '32204': '32.20',
  '32205': '32.21',
  '32206': '32.21',
  '32207': '32.21',
  '32208': '32.21',
  '32209': '32.21',
  '32210': '32.21',
  '32211': '32.21',
  '32212': '32.21',
  '32213': '32.21',
  '32214': '32.21',
  '32215': '32.22',
  '32216': '32.22',
  '32217': '32.22',
  '32218': '32.22',
  '32219': '32.22',
  '32220': '32.22',
  '32221': '32.22',
  '32222': '32.22',
  '32223': '32.22',
  '32224': '32.22',
  '32225': '32.22',
  '32226': '32.23',
  '32227': '32.23',
  '32228': '32.23',
  '32229': '32.23',
  '32230': '32.23',
  '32231': '32.23',
  '32232': '32.23',
  '32233': '32.23',
  '32234': '32.23',
  '32235': '32.24',
  '32236': '32.24',
  '32237': '32.24',
  '32238': '32.24',
  '32239': '32.24',
  '32240': '32.24',
  '32241': '32.24',
  '32242': '32.24',
  '32243': '32.24',
  '32244': '32.24',
  '32245': '32.24',
  '32246': '32.25',
  '32247': '32.25',
  '32248': '32.25',
  '32249': '32.25',
  '32250': '32.25',
  '32251': '32.25',
  '32252': '32.25',
  '32253': '32.25',
  '32254': '32.25',
  '32255': '32.26',
  '32256': '32.26',
  '32257': '32.26',
  '32258': '32.26',
  '32259': '32.26',
  '32260': '32.26',
  '32261': '32.26',
  '32262': '32.26',
  '32263': '32.26',
  '32264': '32.26',
  '32265': '32.26',
  '32266': '32.27',
  '32267': '32.27',
  '32268': '32.27',
  '32269': '32.27',
  '32270': '32.27',
  '32271': '32.27',
  '32272': '32.27',
  '32273': '32.27',
  '32274': '32.27',
  '32275': '32.28',
  '32276': '32.28',
  '32277': '32.28',
  '32278': '32.28',
  '32279': '32.28',
  '32280': '32.28',
  '32281': '32.28',
  '32282': '32.28',
  '32283': '32.28',
  '32284': '32.28',
  '32285': '32.28',
  '32286': '32.29',
  '32287': '32.29',
  '32288': '32.29',
  '32289': '32.29',
  '32290': '32.29',
  '32291': '32.29',
  '32292': '32.29',
  '32293': '32.29',
  '32294': '32.29',
  '32295': '32.29',
  '32296': '32.30',
  '32297': '32.30',
  '32298': '32.30',
  '32299': '32.30',
  '32300': '32.30',
  '32301': '32.30',
  '32302': '32.30',
  '32303': '32.30',
  '32304': '32.30',
  '32305': '32.31',
  '32306': '32.31',
  '32307': '32.31',
  '32308': '32.31',
  '32309': '32.31',
  '32310': '32.31',
  '32311': '32.31',
  '32312': '32.31',
  '32313': '32.31',
  '32314': '32.31',
  '32315': '32.31',
  '32316': '32.32',
  '32317': '32.32',
  '32318': '32.32',
  '32319': '32.32',
  '32320': '32.32',
  '32321': '32.32',
  '32322': '32.32',
  '32323': '32.32',
  '32324': '32.32',
  '32325': '32.33',
  '32326': '32.33',
  '32327': '32.33',
  '32328': '32.33',
  '32329': '32.33',
  '32330': '32.33',
  '32331': '32.33',
  '32332': '32.33',
  '32333': '32.33',
  '32334': '32.33',
  '32335': '32.33',
  '32336': '32.34',
  '32337': '32.34',
  '32338': '32.34',
  '32339': '32.34',
  '32340': '32.34',
  '32341': '32.34',
  '32342': '32.34',
  '32343': '32.34',
  '32344': '32.34',
  '32345': '32.35',
  '32346': '32.35',
  '32347': '32.35',
  '32348': '32.35',
  '32349': '32.35',
  '32350': '32.35',
  '32351': '32.35',
  '32352': '32.35',
  '32353': '32.35',
  '32354': '32.35',
  '32355': '32.35',
  '32356': '32.36',
  '32357': '32.36',
  '32358': '32.36',
  '32359': '32.36',
  '32360': '32.36',
  '32361': '32.36',
  '32362': '32.36',
  '32363': '32.36',
  '32364': '32.36',
  '32365': '32.37',
  '32366': '32.37',
  '32367': '32.37',
  '32368': '32.37',
  '32369': '32.37',
  '32370': '32.37',
  '32371': '32.37',
  '32372': '32.37',
  '32373': '32.37',
  '32374': '32.37',
  '32375': '32.38',
  '32376': '32.38',
  '32377': '32.38',
  '32378': '32.38',
  '32379': '32.38',
  '32380': '32.38',
  '32381': '32.38',
  '32382': '32.38',
  '32383': '32.38',
  '32384': '32.38',
  '32385': '32.38',
  '32386': '32.39',
  '32387': '32.39',
  '32388': '32.39',
  '32389': '32.39',
  '32390': '32.39',
  '32391': '32.39',
  '32392': '32.39',
  '32393': '32.39',
  '32394': '32.39',
  '32395': '32.40',
  '32396': '32.40',
  '32397': '32.40',
  '32398': '32.40',
  '32399': '32.40',
  '32400': '32.40',
  '32401': '32.40',
  '32402': '32.40',
  '32403': '32.40',
  '32404': '32.40',
  '32405': '32.40',
  '32406': '32.41',
  '32407': '32.41',
  '32408': '32.41',
  '32409': '32.41',
  '32410': '32.41',
  '32411': '32.41',
  '32412': '32.41',
  '32413': '32.41',
  '32414': '32.41',
  '32415': '32.42',
  '32416': '32.42',
  '32417': '32.42',
  '32418': '32.42',
  '32419': '32.42',
  '32420': '32.42',
  '32421': '32.42',
  '32422': '32.42',
  '32423': '32.42',
  '32424': '32.42',
  '32425': '32.42',
  '32426': '32.43',
  '32427': '32.43',
  '32428': '32.43',
  '32429': '32.43',
  '32430': '32.43',
  '32431': '32.43',
  '32432': '32.43',
  '32433': '32.43',
  '32434': '32.43',
  '32435': '32.44',
  '32436': '32.44',
  '32437': '32.44',
  '32438': '32.44',
  '32439': '32.44',
  '32440': '32.44',
  '32441': '32.44',
  '32442': '32.44',
  '32443': '32.44',
  '32444': '32.44',
  '32445': '32.44',
  '32446': '32.45',
  '32447': '32.45',
  '32448': '32.45',
  '32449': '32.45',
  '32450': '32.45',
  '32451': '32.45',
  '32452': '32.45',
  '32453': '32.45',
  '32454': '32.45',
  '32455': '32.46',
  '32456': '32.46',
  '32457': '32.46',
  '32458': '32.46',
  '32459': '32.46',
  '32460': '32.46',
  '32461': '32.46',
  '32462': '32.46',
  '32463': '32.46',
  '32464': '32.46',
  '32465': '32.47',
  '32466': '32.47',
  '32467': '32.47',
  '32468': '32.47',
  '32469': '32.47',
  '32470': '32.47',
  '32471': '32.47',
  '32472': '32.47',
  '32473': '32.47',
  '32474': '32.47',
  '32475': '32.47',
  '32476': '32.48',
  '32477': '32.48',
  '32478': '32.48',
  '32479': '32.48',
  '32480': '32.48',
  '32481': '32.48',
  '32482': '32.48',
  '32483': '32.48',
  '32484': '32.48',
  '32485': '32.49',
  '32486': '32.49',
  '32487': '32.49',
  '32488': '32.49',
  '32489': '32.49',
  '32490': '32.49',
  '32491': '32.49',
  '32492': '32.49',
  '32493': '32.49',
  '32494': '32.49',
  '32495': '32.49',
  '32496': '32.50',
  '32497': '32.50',
  '32498': '32.50',
  '32499': '32.50',
  '32500': '32.50',
  '32501': '32.50',
  '32502': '32.50',
  '32503': '32.50',
  '32504': '32.50',
  '32505': '32.51',
  '32506': '32.51',
  '32507': '32.51',
  '32508': '32.51',
  '32509': '32.51',
  '32510': '32.51',
  '32511': '32.51',
  '32512': '32.51',
  '32513': '32.51',
  '32514': '32.51',
  '32515': '32.51',
  '32516': '32.52',
  '32517': '32.52',
  '32518': '32.52',
  '32519': '32.52',
  '32520': '32.52',
  '32521': '32.52',
  '32522': '32.52',
  '32523': '32.52',
  '32524': '32.52',
  '32525': '32.53',
  '32526': '32.53',
  '32527': '32.53',
  '32528': '32.53',
  '32529': '32.53',
  '32530': '32.53',
  '32531': '32.53',
  '32532': '32.53',
  '32533': '32.53',
  '32534': '32.53',
  '32535': '32.53',
  '32536': '32.54',
  '32537': '32.54',
  '32538': '32.54',
  '32539': '32.54',
  '32540': '32.54',
  '32541': '32.54',
  '32542': '32.54',
  '32543': '32.54',
  '32544': '32.54',
  '32545': '32.54',
  '32546': '32.55',
  '32547': '32.55',
  '32548': '32.55',
  '32549': '32.55',
  '32550': '32.55',
  '32551': '32.55',
  '32552': '32.55',
  '32553': '32.55',
  '32554': '32.55',
  '32555': '32.56',
  '32556': '32.56',
  '32557': '32.56',
  '32558': '32.56',
  '32559': '32.56',
  '32560': '32.56',
  '32561': '32.56',
  '32562': '32.56',
  '32563': '32.56',
  '32564': '32.56',
  '32565': '32.56',
  '32566': '32.57',
  '32567': '32.57',
  '32568': '32.57',
  '32569': '32.57',
  '32570': '32.57',
  '32571': '32.57',
  '32572': '32.57',
  '32573': '32.57',
  '32574': '32.57',
  '32575': '32.58',
  '32576': '32.58',
  '32577': '32.58',
  '32578': '32.58',
  '32579': '32.58',
  '32580': '32.58',
  '32581': '32.58',
  '32582': '32.58',
  '32583': '32.58',
  '32584': '32.58',
  '32585': '32.58',
  '32586': '32.59',
  '32587': '32.59',
  '32588': '32.59',
  '32589': '32.59',
  '32590': '32.59',
  '32591': '32.59',
  '32592': '32.59',
  '32593': '32.59',
  '32594': '32.59',
  '32595': '32.60',
  '32596': '32.60',
  '32597': '32.60',
  '32598': '32.60',
  '32599': '32.60',
  '32600': '32.60',
  '32601': '32.60',
  '32602': '32.60',
  '32603': '32.60',
  '32604': '32.60',
  '32605': '32.60',
  '32606': '32.61',
  '32607': '32.61',
  '32608': '32.61',
  '32609': '32.61',
  '32610': '32.61',
  '32611': '32.61',
  '32612': '32.61',
  '32613': '32.61',
  '32614': '32.61',
  '32615': '32.62',
  '32616': '32.62',
  '32617': '32.62',
  '32618': '32.62',
  '32619': '32.62',
  '32620': '32.62',
  '32621': '32.62',
  '32622': '32.62',
  '32623': '32.62',
  '32624': '32.62',
  '32625': '32.62',
  '32626': '32.63',
  '32627': '32.63',
  '32628': '32.63',
  '32629': '32.63',
  '32630': '32.63',
  '32631': '32.63',
  '32632': '32.63',
  '32633': '32.63',
  '32634': '32.63',
  '32635': '32.63',
  '32636': '32.64',
  '32637': '32.64',
  '32638': '32.64',
  '32639': '32.64',
  '32640': '32.64',
  '32641': '32.64',
  '32642': '32.64',
  '32643': '32.64',
  '32644': '32.64',
  '32645': '32.65',
  '32646': '32.65',
  '32647': '32.65',
  '32648': '32.65',
  '32649': '32.65',
  '32650': '32.65',
  '32651': '32.65',
  '32652': '32.65',
  '32653': '32.65',
  '32654': '32.65',
  '32655': '32.65',
  '32656': '32.66',
  '32657': '32.66',
  '32658': '32.66',
  '32659': '32.66',
  '32660': '32.66',
  '32661': '32.66',
  '32662': '32.66',
  '32663': '32.66',
  '32664': '32.66',
  '32665': '32.67',
  '32666': '32.67',
  '32667': '32.67',
  '32668': '32.67',
  '32669': '32.67',
  '32670': '32.67',
  '32671': '32.67',
  '32672': '32.67',
  '32673': '32.67',
  '32674': '32.67',
  '32675': '32.67',
  '32676': '32.68',
  '32677': '32.68',
  '32678': '32.68',
  '32679': '32.68',
  '32680': '32.68',
  '32681': '32.68',
  '32682': '32.68',
  '32683': '32.68',
  '32684': '32.68',
  '32685': '32.69',
  '32686': '32.69',
  '32687': '32.69',
  '32688': '32.69',
  '32689': '32.69',
  '32690': '32.69',
  '32691': '32.69',
  '32692': '32.69',
  '32693': '32.69',
  '32694': '32.69',
  '32695': '32.69',
  '32696': '32.70',
  '32697': '32.70',
  '32698': '32.70',
  '32699': '32.70',
  '32700': '32.70',
  '32701': '32.70',
  '32702': '32.70',
  '32703': '32.70',
  '32704': '32.70',
  '32705': '32.71',
  '32706': '32.71',
  '32707': '32.71',
  '32708': '32.71',
  '32709': '32.71',
  '32710': '32.71',
  '32711': '32.71',
  '32712': '32.71',
  '32713': '32.71',
  '32714': '32.71',
  '32715': '32.72',
  '32716': '32.72',
  '32717': '32.72',
  '32718': '32.72',
  '32719': '32.72',
  '32720': '32.72',
  '32721': '32.72',
  '32722': '32.72',
  '32723': '32.72',
  '32724': '32.72',
  '32725': '32.72',
  '32726': '32.73',
  '32727': '32.73',
  '32728': '32.73',
  '32729': '32.73',
  '32730': '32.73',
  '32731': '32.73',
  '32732': '32.73',
  '32733': '32.73',
  '32734': '32.73',
  '32735': '32.74',
  '32736': '32.74',
  '32737': '32.74',
  '32738': '32.74',
  '32739': '32.74',
  '32740': '32.74',
  '32741': '32.74',
  '32742': '32.74',
  '32743': '32.74',
  '32744': '32.74',
  '32745': '32.74',
  '32746': '32.75',
  '32747': '32.75',
  '32748': '32.75',
  '32749': '32.75',
  '32750': '32.75',
  '32751': '32.75',
  '32752': '32.75',
  '32753': '32.75',
  '32754': '32.75',
  '32755': '32.76',
  '32756': '32.76',
  '32757': '32.76',
  '32758': '32.76',
  '32759': '32.76',
  '32760': '32.76',
  '32761': '32.76',
  '32762': '32.76',
  '32763': '32.76',
  '32764': '32.76',
  '32765': '32.76',
  '32766': '32.77',
  '32767': '32.77',
  '32768': '32.77',
  '32769': '32.77',
  '32770': '32.77',
  '32771': '32.77',
  '32772': '32.77',
  '32773': '32.77',
  '32774': '32.77',
  '32775': '32.78',
  '32776': '32.78',
  '32777': '32.78',
  '32778': '32.78',
  '32779': '32.78',
  '32780': '32.78',
  '32781': '32.78',
  '32782': '32.78',
  '32783': '32.78',
  '32784': '32.78',
  '32785': '32.78',
  '32786': '32.79',
  '32787': '32.79',
  '32788': '32.79',
  '32789': '32.79',
  '32790': '32.79',
  '32791': '32.79',
  '32792': '32.79',
  '32793': '32.79',
  '32794': '32.79',
  '32795': '32.79',
  '32796': '32.80',
  '32797': '32.80',
  '32798': '32.80',
  '32799': '32.80',
  '32800': '32.80',
  '32801': '32.80',
  '32802': '32.80',
  '32803': '32.80',
  '32804': '32.80',
  '32805': '32.81',
  '32806': '32.81',
  '32807': '32.81',
  '32808': '32.81',
  '32809': '32.81',
  '32810': '32.81',
  '32811': '32.81',
  '32812': '32.81',
  '32813': '32.81',
  '32814': '32.81',
  '32815': '32.81',
  '32816': '32.82',
  '32817': '32.82',
  '32818': '32.82',
  '32819': '32.82',
  '32820': '32.82',
  '32821': '32.82',
  '32822': '32.82',
  '32823': '32.82',
  '32824': '32.82',
  '32825': '32.83',
  '32826': '32.83',
  '32827': '32.83',
  '32828': '32.83',
  '32829': '32.83',
  '32830': '32.83',
  '32831': '32.83',
  '32832': '32.83',
  '32833': '32.83',
  '32834': '32.83',
  '32835': '32.83',
  '32836': '32.84',
  '32837': '32.84',
  '32838': '32.84',
  '32839': '32.84',
  '32840': '32.84',
  '32841': '32.84',
  '32842': '32.84',
  '32843': '32.84',
  '32844': '32.84',
  '32845': '32.85',
  '32846': '32.85',
  '32847': '32.85',
  '32848': '32.85',
  '32849': '32.85',
  '32850': '32.85',
  '32851': '32.85',
  '32852': '32.85',
  '32853': '32.85',
  '32854': '32.85',
  '32855': '32.85',
  '32856': '32.86',
  '32857': '32.86',
  '32858': '32.86',
  '32859': '32.86',
  '32860': '32.86',
  '32861': '32.86',
  '32862': '32.86',
  '32863': '32.86',
  '32864': '32.86',
  '32865': '32.87',
  '32866': '32.87',
  '32867': '32.87',
  '32868': '32.87',
  '32869': '32.87',
  '32870': '32.87',
  '32871': '32.87',
  '32872': '32.87',
  '32873': '32.87',
  '32874': '32.87',
  '32875': '32.88',
  '32876': '32.88',
  '32877': '32.88',
  '32878': '32.88',
  '32879': '32.88',
  '32880': '32.88',
  '32881': '32.88',
  '32882': '32.88',
  '32883': '32.88',
  '32884': '32.88',
  '32885': '32.88',
  '32886': '32.89',
  '32887': '32.89',
  '32888': '32.89',
  '32889': '32.89',
  '32890': '32.89',
  '32891': '32.89',
  '32892': '32.89',
  '32893': '32.89',
  '32894': '32.89',
  '32895': '32.90',
  '32896': '32.90',
  '32897': '32.90',
  '32898': '32.90',
  '32899': '32.90',
  '32900': '32.90',
  '32901': '32.90',
  '32902': '32.90',
  '32903': '32.90',
  '32904': '32.90',
  '32905': '32.90',
  '32906': '32.91',
  '32907': '32.91',
  '32908': '32.91',
  '32909': '32.91',
  '32910': '32.91',
  '32911': '32.91',
  '32912': '32.91',
  '32913': '32.91',
  '32914': '32.91',
  '32915': '32.92',
  '32916': '32.92',
  '32917': '32.92',
  '32918': '32.92',
  '32919': '32.92',
  '32920': '32.92',
  '32921': '32.92',
  '32922': '32.92',
  '32923': '32.92',
  '32924': '32.92',
  '32925': '32.92',
  '32926': '32.93',
  '32927': '32.93',
  '32928': '32.93',
  '32929': '32.93',
  '32930': '32.93',
  '32931': '32.93',
  '32932': '32.93',
  '32933': '32.93',
  '32934': '32.93',
  '32935': '32.94',
  '32936': '32.94',
  '32937': '32.94',
  '32938': '32.94',
  '32939': '32.94',
  '32940': '32.94',
  '32941': '32.94',
  '32942': '32.94',
  '32943': '32.94',
  '32944': '32.94',
  '32945': '32.94',
  '32946': '32.95',
  '32947': '32.95',
  '32948': '32.95',
  '32949': '32.95',
  '32950': '32.95',
  '32951': '32.95',
  '32952': '32.95',
  '32953': '32.95',
  '32954': '32.95',
  '32955': '32.96',
  '32956': '32.96',
  '32957': '32.96',
  '32958': '32.96',
  '32959': '32.96',
  '32960': '32.96',
  '32961': '32.96',
  '32962': '32.96',
  '32963': '32.96',
  '32964': '32.96',
  '32965': '32.97',
  '32966': '32.97',
  '32967': '32.97',
  '32968': '32.97',
  '32969': '32.97',
  '32970': '32.97',
  '32971': '32.97',
  '32972': '32.97',
  '32973': '32.97',
  '32974': '32.97',
  '32975': '32.97',
  '32976': '32.98',
  '32977': '32.98',
  '32978': '32.98',
  '32979': '32.98',
  '32980': '32.98',
  '32981': '32.98',
  '32982': '32.98',
  '32983': '32.98',
  '32984': '32.98',
  '32985': '32.99',
  '32986': '32.99',
  '32987': '32.99',
  '32988': '32.99',
  '32989': '32.99',
  '32990': '32.99',
  '32991': '32.99',
  '32992': '32.99',
  '32993': '32.99',
  '32994': '32.99',
  '32995': '32.99',
  '32996': '33.00',
  '32997': '33.00',
  '32998': '33.00',
  '32999': '33.00',
  '33000': '33.00',
  '33001': '33.00',
  '33002': '33.00',
  '33003': '33.00',
  '33004': '33.00',
  '33005': '33.01',
  '33006': '33.01',
  '33007': '33.01',
  '33008': '33.01',
  '33009': '33.01',
  '33010': '33.01',
  '33011': '33.01',
  '33012': '33.01',
  '33013': '33.01',
  '33014': '33.01',
  '33015': '33.01',
  '33016': '33.02',
  '33017': '33.02',
  '33018': '33.02',
  '33019': '33.02',
  '33020': '33.02',
  '33021': '33.02',
  '33022': '33.02',
  '33023': '33.02',
  '33024': '33.02',
  '33025': '33.03',
  '33026': '33.03',
  '33027': '33.03',
  '33028': '33.03',
  '33029': '33.03',
  '33030': '33.03',
  '33031': '33.03',
  '33032': '33.03',
  '33033': '33.03',
  '33034': '33.03',
  '33035': '33.03',
  '33036': '33.04',
  '33037': '33.04',
  '33038': '33.04',
  '33039': '33.04',
  '33040': '33.04',
  '33041': '33.04',
  '33042': '33.04',
  '33043': '33.04',
  '33044': '33.04',
  '33045': '33.04',
  '33046': '33.05',
  '33047': '33.05',
  '33048': '33.05',
  '33049': '33.05',
  '33050': '33.05',
  '33051': '33.05',
  '33052': '33.05',
  '33053': '33.05',
  '33054': '33.05',
  '33055': '33.06',
  '33056': '33.06',
  '33057': '33.06',
  '33058': '33.06',
  '33059': '33.06',
  '33060': '33.06',
  '33061': '33.06',
  '33062': '33.06',
  '33063': '33.06',
  '33064': '33.06',
  '33065': '33.06',
  '33066': '33.07',
  '33067': '33.07',
  '33068': '33.07',
  '33069': '33.07',
  '33070': '33.07',
  '33071': '33.07',
  '33072': '33.07',
  '33073': '33.07',
  '33074': '33.07',
  '33075': '33.08',
  '33076': '33.08',
  '33077': '33.08',
  '33078': '33.08',
  '33079': '33.08',
  '33080': '33.08',
  '33081': '33.08',
  '33082': '33.08',
  '33083': '33.08',
  '33084': '33.08',
  '33085': '33.08',
  '33086': '33.09',
  '33087': '33.09',
  '33088': '33.09',
  '33089': '33.09',
  '33090': '33.09',
  '33091': '33.09',
  '33092': '33.09',
  '33093': '33.09',
  '33094': '33.09',
  '33095': '33.10',
  '33096': '33.10',
  '33097': '33.10',
  '33098': '33.10',
  '33099': '33.10',
  '33100': '33.10',
  '33101': '33.10',
  '33102': '33.10',
  '33103': '33.10',
  '33104': '33.10',
  '33105': '33.10',
  '33106': '33.11',
  '33107': '33.11',
  '33108': '33.11',
  '33109': '33.11',
  '33110': '33.11',
  '33111': '33.11',
  '33112': '33.11',
  '33113': '33.11',
  '33114': '33.11',
  '33115': '33.12',
  '33116': '33.12',
  '33117': '33.12',
  '33118': '33.12',
  '33119': '33.12',
  '33120': '33.12',
  '33121': '33.12',
  '33122': '33.12',
  '33123': '33.12',
  '33124': '33.12',
  '33125': '33.12',
  '33126': '33.13',
  '33127': '33.13',
  '33128': '33.13',
  '33129': '33.13',
  '33130': '33.13',
  '33131': '33.13',
  '33132': '33.13',
  '33133': '33.13',
  '33134': '33.13',
  '33135': '33.13',
  '33136': '33.14',
  '33137': '33.14',
  '33138': '33.14',
  '33139': '33.14',
  '33140': '33.14',
  '33141': '33.14',
  '33142': '33.14',
  '33143': '33.14',
  '33144': '33.14',
  '33145': '33.15',
  '33146': '33.15',
  '33147': '33.15',
  '33148': '33.15',
  '33149': '33.15',
  '33150': '33.15',
  '33151': '33.15',
  '33152': '33.15',
  '33153': '33.15',
  '33154': '33.15',
  '33155': '33.15',
  '33156': '33.16',
  '33157': '33.16',
  '33158': '33.16',
  '33159': '33.16',
  '33160': '33.16',
  '33161': '33.16',
  '33162': '33.16',
  '33163': '33.16',
  '33164': '33.16',
  '33165': '33.17',
  '33166': '33.17',
  '33167': '33.17',
  '33168': '33.17',
  '33169': '33.17',
  '33170': '33.17',
  '33171': '33.17',
  '33172': '33.17',
  '33173': '33.17',
  '33174': '33.17',
  '33175': '33.17',
  '33176': '33.18',
  '33177': '33.18',
  '33178': '33.18',
  '33179': '33.18',
  '33180': '33.18',
  '33181': '33.18',
  '33182': '33.18',
  '33183': '33.18',
  '33184': '33.18',
  '33185': '33.19',
  '33186': '33.19',
  '33187': '33.19',
  '33188': '33.19',
  '33189': '33.19',
  '33190': '33.19',
  '33191': '33.19',
  '33192': '33.19',
  '33193': '33.19',
  '33194': '33.19',
  '33195': '33.19',
  '33196': '33.20',
  '33197': '33.20',
  '33198': '33.20',
  '33199': '33.20',
  '33200': '33.20',
  '33201': '33.20',
  '33202': '33.20',
  '33203': '33.20',
  '33204': '33.20',
  '33205': '33.21',
  '33206': '33.21',
  '33207': '33.21',
  '33208': '33.21',
  '33209': '33.21',
  '33210': '33.21',
  '33211': '33.21',
  '33212': '33.21',
  '33213': '33.21',
  '33214': '33.21',
  '33215': '33.22',
  '33216': '33.22',
  '33217': '33.22',
  '33218': '33.22',
  '33219': '33.22',
  '33220': '33.22',
  '33221': '33.22',
  '33222': '33.22',
  '33223': '33.22',
  '33224': '33.22',
  '33225': '33.22',
  '33226': '33.23',
  '33227': '33.23',
  '33228': '33.23',
  '33229': '33.23',
  '33230': '33.23',
  '33231': '33.23',
  '33232': '33.23',
  '33233': '33.23',
  '33234': '33.23',
  '33235': '33.24',
  '33236': '33.24',
  '33237': '33.24',
  '33238': '33.24',
  '33239': '33.24',
  '33240': '33.24',
  '33241': '33.24',
  '33242': '33.24',
  '33243': '33.24',
  '33244': '33.24',
  '33245': '33.24',
  '33246': '33.25',
  '33247': '33.25',
  '33248': '33.25',
  '33249': '33.25',
  '33250': '33.25',
  '33251': '33.25',
  '33252': '33.25',
  '33253': '33.25',
  '33254': '33.25',
  '33255': '33.26',
  '33256': '33.26',
  '33257': '33.26',
  '33258': '33.26',
  '33259': '33.26',
  '33260': '33.26',
  '33261': '33.26',
  '33262': '33.26',
  '33263': '33.26',
  '33264': '33.26',
  '33265': '33.26',
  '33266': '33.27',
  '33267': '33.27',
  '33268': '33.27',
  '33269': '33.27',
  '33270': '33.27',
  '33271': '33.27',
  '33272': '33.27',
  '33273': '33.27',
  '33274': '33.27',
  '33275': '33.28',
  '33276': '33.28',
  '33277': '33.28',
  '33278': '33.28',
  '33279': '33.28',
  '33280': '33.28',
  '33281': '33.28',
  '33282': '33.28',
  '33283': '33.28',
  '33284': '33.28',
  '33285': '33.28',
  '33286': '33.29',
  '33287': '33.29',
  '33288': '33.29',
  '33289': '33.29',
  '33290': '33.29',
  '33291': '33.29',
  '33292': '33.29',
  '33293': '33.29',
  '33294': '33.29',
  '33295': '33.29',
  '33296': '33.30',
  '33297': '33.30',
  '33298': '33.30',
  '33299': '33.30',
  '33300': '33.30',
  '33301': '33.30',
  '33302': '33.30',
  '33303': '33.30',
  '33304': '33.30',
  '33305': '33.31',
  '33306': '33.31',
  '33307': '33.31',
  '33308': '33.31',
  '33309': '33.31',
  '33310': '33.31',
  '33311': '33.31',
  '33312': '33.31',
  '33313': '33.31',
  '33314': '33.31',
  '33315': '33.31',
  '33316': '33.32',
  '33317': '33.32',
  '33318': '33.32',
  '33319': '33.32',
  '33320': '33.32',
  '33321': '33.32',
  '33322': '33.32',
  '33323': '33.32',
  '33324': '33.32',
  '33325': '33.33',
  '33326': '33.33',
  '33327': '33.33',
  '33328': '33.33',
  '33329': '33.33',
  '33330': '33.33',
  '33331': '33.33',
  '33332': '33.33',
  '33333': '33.33',
  '33334': '33.33',
  '33335': '33.33',
  '33336': '33.34',
  '33337': '33.34',
  '33338': '33.34',
  '33339': '33.34',
  '33340': '33.34',
  '33341': '33.34',
  '33342': '33.34',
  '33343': '33.34',
  '33344': '33.34',
  '33345': '33.35',
  '33346': '33.35',
  '33347': '33.35',
  '33348': '33.35',
  '33349': '33.35',
  '33350': '33.35',
  '33351': '33.35',
  '33352': '33.35',
  '33353': '33.35',
  '33354': '33.35',
  '33355': '33.35',
  '33356': '33.36',
  '33357': '33.36',
  '33358': '33.36',
  '33359': '33.36',
  '33360': '33.36',
  '33361': '33.36',
  '33362': '33.36',
  '33363': '33.36',
  '33364': '33.36',
  '33365': '33.37',
  '33366': '33.37',
  '33367': '33.37',
  '33368': '33.37',
  '33369': '33.37',
  '33370': '33.37',
  '33371': '33.37',
  '33372': '33.37',
  '33373': '33.37',
  '33374': '33.37',
  '33375': '33.38',
  '33376': '33.38',
  '33377': '33.38',
  '33378': '33.38',
  '33379': '33.38',
  '33380': '33.38',
  '33381': '33.38',
  '33382': '33.38',
  '33383': '33.38',
  '33384': '33.38',
  '33385': '33.38',
  '33386': '33.39',
  '33387': '33.39',
  '33388': '33.39',
  '33389': '33.39',
  '33390': '33.39',
  '33391': '33.39',
  '33392': '33.39',
  '33393': '33.39',
  '33394': '33.39',
  '33395': '33.40',
  '33396': '33.40',
  '33397': '33.40',
  '33398': '33.40',
  '33399': '33.40',
  '33400': '33.40',
  '33401': '33.40',
  '33402': '33.40',
  '33403': '33.40',
  '33404': '33.40',
  '33405': '33.40',
  '33406': '33.41',
  '33407': '33.41',
  '33408': '33.41',
  '33409': '33.41',
  '33410': '33.41',
  '33411': '33.41',
  '33412': '33.41',
  '33413': '33.41',
  '33414': '33.41',
  '33415': '33.42',
  '33416': '33.42',
  '33417': '33.42',
  '33418': '33.42',
  '33419': '33.42',
  '33420': '33.42',
  '33421': '33.42',
  '33422': '33.42',
  '33423': '33.42',
  '33424': '33.42',
  '33425': '33.42',
  '33426': '33.43',
  '33427': '33.43',
  '33428': '33.43',
  '33429': '33.43',
  '33430': '33.43',
  '33431': '33.43',
  '33432': '33.43',
  '33433': '33.43',
  '33434': '33.43',
  '33435': '33.44',
  '33436': '33.44',
  '33437': '33.44',
  '33438': '33.44',
  '33439': '33.44',
  '33440': '33.44',
  '33441': '33.44',
  '33442': '33.44',
  '33443': '33.44',
  '33444': '33.44',
  '33445': '33.44',
  '33446': '33.45',
  '33447': '33.45',
  '33448': '33.45',
  '33449': '33.45',
  '33450': '33.45',
  '33451': '33.45',
  '33452': '33.45',
  '33453': '33.45',
  '33454': '33.45',
  '33455': '33.46',
  '33456': '33.46',
  '33457': '33.46',
  '33458': '33.46',
  '33459': '33.46',
  '33460': '33.46',
  '33461': '33.46',
  '33462': '33.46',
  '33463': '33.46',
  '33464': '33.46',
  '33465': '33.47',
  '33466': '33.47',
  '33467': '33.47',
  '33468': '33.47',
  '33469': '33.47',
  '33470': '33.47',
  '33471': '33.47',
  '33472': '33.47',
  '33473': '33.47',
  '33474': '33.47',
  '33475': '33.47',
  '33476': '33.48',
  '33477': '33.48',
  '33478': '33.48',
  '33479': '33.48',
  '33480': '33.48',
  '33481': '33.48',
  '33482': '33.48',
  '33483': '33.48',
  '33484': '33.48',
  '33485': '33.49',
  '33486': '33.49',
  '33487': '33.49',
  '33488': '33.49',
  '33489': '33.49',
  '33490': '33.49',
  '33491': '33.49',
  '33492': '33.49',
  '33493': '33.49',
  '33494': '33.49',
  '33495': '33.49',
  '33496': '33.50',
  '33497': '33.50',
  '33498': '33.50',
  '33499': '33.50',
  '33500': '33.50',
  '33501': '33.50',
  '33502': '33.50',
  '33503': '33.50',
  '33504': '33.50',
  '33505': '33.51',
  '33506': '33.51',
  '33507': '33.51',
  '33508': '33.51',
  '33509': '33.51',
  '33510': '33.51',
  '33511': '33.51',
  '33512': '33.51',
  '33513': '33.51',
  '33514': '33.51',
  '33515': '33.51',
  '33516': '33.52',
  '33517': '33.52',
  '33518': '33.52',
  '33519': '33.52',
  '33520': '33.52',
  '33521': '33.52',
  '33522': '33.52',
  '33523': '33.52',
  '33524': '33.52',
  '33525': '33.53',
  '33526': '33.53',
  '33527': '33.53',
  '33528': '33.53',
  '33529': '33.53',
  '33530': '33.53',
  '33531': '33.53',
  '33532': '33.53',
  '33533': '33.53',
  '33534': '33.53',
  '33535': '33.53',
  '33536': '33.54',
  '33537': '33.54',
  '33538': '33.54',
  '33539': '33.54',
  '33540': '33.54',
  '33541': '33.54',
  '33542': '33.54',
  '33543': '33.54',
  '33544': '33.54',
  '33545': '33.54',
  '33546': '33.55',
  '33547': '33.55',
  '33548': '33.55',
  '33549': '33.55',
  '33550': '33.55',
  '33551': '33.55',
  '33552': '33.55',
  '33553': '33.55',
  '33554': '33.55',
  '33555': '33.56',
  '33556': '33.56',
  '33557': '33.56',
  '33558': '33.56',
  '33559': '33.56',
  '33560': '33.56',
  '33561': '33.56',
  '33562': '33.56',
  '33563': '33.56',
  '33564': '33.56',
  '33565': '33.56',
  '33566': '33.57',
  '33567': '33.57',
  '33568': '33.57',
  '33569': '33.57',
  '33570': '33.57',
  '33571': '33.57',
  '33572': '33.57',
  '33573': '33.57',
  '33574': '33.57',
  '33575': '33.58',
  '33576': '33.58',
  '33577': '33.58',
  '33578': '33.58',
  '33579': '33.58',
  '33580': '33.58',
  '33581': '33.58',
  '33582': '33.58',
  '33583': '33.58',
  '33584': '33.58',
  '33585': '33.58',
  '33586': '33.59',
  '33587': '33.59',
  '33588': '33.59',
  '33589': '33.59',
  '33590': '33.59',
  '33591': '33.59',
  '33592': '33.59',
  '33593': '33.59',
  '33594': '33.59',
  '33595': '33.60',
  '33596': '33.60',
  '33597': '33.60',
  '33598': '33.60',
  '33599': '33.60',
  '33600': '33.60',
  '33601': '33.60',
  '33602': '33.60',
  '33603': '33.60',
  '33604': '33.60',
  '33605': '33.60',
  '33606': '33.61',
  '33607': '33.61',
  '33608': '33.61',
  '33609': '33.61',
  '33610': '33.61',
  '33611': '33.61',
  '33612': '33.61',
  '33613': '33.61',
  '33614': '33.61',
  '33615': '33.62',
  '33616': '33.62',
  '33617': '33.62',
  '33618': '33.62',
  '33619': '33.62',
  '33620': '33.62',
  '33621': '33.62',
  '33622': '33.62',
  '33623': '33.62',
  '33624': '33.62',
  '33625': '33.62',
  '33626': '33.63',
  '33627': '33.63',
  '33628': '33.63',
  '33629': '33.63',
  '33630': '33.63',
  '33631': '33.63',
  '33632': '33.63',
  '33633': '33.63',
  '33634': '33.63',
  '33635': '33.63',
  '33636': '33.64',
  '33637': '33.64',
  '33638': '33.64',
  '33639': '33.64',
  '33640': '33.64',
  '33641': '33.64',
  '33642': '33.64',
  '33643': '33.64',
  '33644': '33.64',
  '33645': '33.65',
  '33646': '33.65',
  '33647': '33.65',
  '33648': '33.65',
  '33649': '33.65',
  '33650': '33.65',
  '33651': '33.65',
  '33652': '33.65',
  '33653': '33.65',
  '33654': '33.65',
  '33655': '33.65',
  '33656': '33.66',
  '33657': '33.66',
  '33658': '33.66',
  '33659': '33.66',
  '33660': '33.66',
  '33661': '33.66',
  '33662': '33.66',
  '33663': '33.66',
  '33664': '33.66',
  '33665': '33.67',
  '33666': '33.67',
  '33667': '33.67',
  '33668': '33.67',
  '33669': '33.67',
  '33670': '33.67',
  '33671': '33.67',
  '33672': '33.67',
  '33673': '33.67',
  '33674': '33.67',
  '33675': '33.67',
  '33676': '33.68',
  '33677': '33.68',
  '33678': '33.68',
  '33679': '33.68',
  '33680': '33.68',
  '33681': '33.68',
  '33682': '33.68',
  '33683': '33.68',
  '33684': '33.68',
  '33685': '33.69',
  '33686': '33.69',
  '33687': '33.69',
  '33688': '33.69',
  '33689': '33.69',
  '33690': '33.69',
  '33691': '33.69',
  '33692': '33.69',
  '33693': '33.69',
  '33694': '33.69',
  '33695': '33.69',
  '33696': '33.70',
  '33697': '33.70',
  '33698': '33.70',
  '33699': '33.70',
  '33700': '33.70',
  '33701': '33.70',
  '33702': '33.70',
  '33703': '33.70',
  '33704': '33.70',
  '33705': '33.71',
  '33706': '33.71',
  '33707': '33.71',
  '33708': '33.71',
  '33709': '33.71',
  '33710': '33.71',
  '33711': '33.71',
  '33712': '33.71',
  '33713': '33.71',
  '33714': '33.71',
  '33715': '33.72',
  '33716': '33.72',
  '33717': '33.72',
  '33718': '33.72',
  '33719': '33.72',
  '33720': '33.72',
  '33721': '33.72',
  '33722': '33.72',
  '33723': '33.72',
  '33724': '33.72',
  '33725': '33.72',
  '33726': '33.73',
  '33727': '33.73',
  '33728': '33.73',
  '33729': '33.73',
  '33730': '33.73',
  '33731': '33.73',
  '33732': '33.73',
  '33733': '33.73',
  '33734': '33.73',
  '33735': '33.74',
  '33736': '33.74',
  '33737': '33.74',
  '33738': '33.74',
  '33739': '33.74',
  '33740': '33.74',
  '33741': '33.74',
  '33742': '33.74',
  '33743': '33.74',
  '33744': '33.74',
  '33745': '33.74',
  '33746': '33.75',
  '33747': '33.75',
  '33748': '33.75',
  '33749': '33.75',
  '33750': '33.75',
  '33751': '33.75',
  '33752': '33.75',
  '33753': '33.75',
  '33754': '33.75',
  '33755': '33.76',
  '33756': '33.76',
  '33757': '33.76',
  '33758': '33.76',
  '33759': '33.76',
  '33760': '33.76',
  '33761': '33.76',
  '33762': '33.76',
  '33763': '33.76',
  '33764': '33.76',
  '33765': '33.76',
  '33766': '33.77',
  '33767': '33.77',
  '33768': '33.77',
  '33769': '33.77',
  '33770': '33.77',
  '33771': '33.77',
  '33772': '33.77',
  '33773': '33.77',
  '33774': '33.77',
  '33775': '33.78',
  '33776': '33.78',
  '33777': '33.78',
  '33778': '33.78',
  '33779': '33.78',
  '33780': '33.78',
  '33781': '33.78',
  '33782': '33.78',
  '33783': '33.78',
  '33784': '33.78',
  '33785': '33.78',
  '33786': '33.79',
  '33787': '33.79',
  '33788': '33.79',
  '33789': '33.79',
  '33790': '33.79',
  '33791': '33.79',
  '33792': '33.79',
  '33793': '33.79',
  '33794': '33.79',
  '33795': '33.79',
  '33796': '33.80',
  '33797': '33.80',
  '33798': '33.80',
  '33799': '33.80',
  '33800': '33.80',
  '33801': '33.80',
  '33802': '33.80',
  '33803': '33.80',
  '33804': '33.80',
  '33805': '33.81',
  '33806': '33.81',
  '33807': '33.81',
  '33808': '33.81',
  '33809': '33.81',
  '33810': '33.81',
  '33811': '33.81',
  '33812': '33.81',
  '33813': '33.81',
  '33814': '33.81',
  '33815': '33.81',
  '33816': '33.82',
  '33817': '33.82',
  '33818': '33.82',
  '33819': '33.82',
  '33820': '33.82',
  '33821': '33.82',
  '33822': '33.82',
  '33823': '33.82',
  '33824': '33.82',
  '33825': '33.83',
  '33826': '33.83',
  '33827': '33.83',
  '33828': '33.83',
  '33829': '33.83',
  '33830': '33.83',
  '33831': '33.83',
  '33832': '33.83',
  '33833': '33.83',
  '33834': '33.83',
  '33835': '33.83',
  '33836': '33.84',
  '33837': '33.84',
  '33838': '33.84',
  '33839': '33.84',
  '33840': '33.84',
  '33841': '33.84',
  '33842': '33.84',
  '33843': '33.84',
  '33844': '33.84',
  '33845': '33.85',
  '33846': '33.85',
  '33847': '33.85',
  '33848': '33.85',
  '33849': '33.85',
  '33850': '33.85',
  '33851': '33.85',
  '33852': '33.85',
  '33853': '33.85',
  '33854': '33.85',
  '33855': '33.85',
  '33856': '33.86',
  '33857': '33.86',
  '33858': '33.86',
  '33859': '33.86',
  '33860': '33.86',
  '33861': '33.86',
  '33862': '33.86',
  '33863': '33.86',
  '33864': '33.86',
  '33865': '33.87',
  '33866': '33.87',
  '33867': '33.87',
  '33868': '33.87',
  '33869': '33.87',
  '33870': '33.87',
  '33871': '33.87',
  '33872': '33.87',
  '33873': '33.87',
  '33874': '33.87',
  '33875': '33.88',
  '33876': '33.88',
  '33877': '33.88',
  '33878': '33.88',
  '33879': '33.88',
  '33880': '33.88',
  '33881': '33.88',
  '33882': '33.88',
  '33883': '33.88',
  '33884': '33.88',
  '33885': '33.88',
  '33886': '33.89',
  '33887': '33.89',
  '33888': '33.89',
  '33889': '33.89',
  '33890': '33.89',
  '33891': '33.89',
  '33892': '33.89',
  '33893': '33.89',
  '33894': '33.89',
  '33895': '33.90',
  '33896': '33.90',
  '33897': '33.90',
  '33898': '33.90',
  '33899': '33.90',
  '33900': '33.90',
  '33901': '33.90',
  '33902': '33.90',
  '33903': '33.90',
  '33904': '33.90',
  '33905': '33.90',
  '33906': '33.91',
  '33907': '33.91',
  '33908': '33.91',
  '33909': '33.91',
  '33910': '33.91',
  '33911': '33.91',
  '33912': '33.91',
  '33913': '33.91',
  '33914': '33.91',
  '33915': '33.92',
  '33916': '33.92',
  '33917': '33.92',
  '33918': '33.92',
  '33919': '33.92',
  '33920': '33.92',
  '33921': '33.92',
  '33922': '33.92',
  '33923': '33.92',
  '33924': '33.92',
  '33925': '33.92',
  '33926': '33.93',
  '33927': '33.93',
  '33928': '33.93',
  '33929': '33.93',
  '33930': '33.93',
  '33931': '33.93',
  '33932': '33.93',
  '33933': '33.93',
  '33934': '33.93',
  '33935': '33.94',
  '33936': '33.94',
  '33937': '33.94',
  '33938': '33.94',
  '33939': '33.94',
  '33940': '33.94',
  '33941': '33.94',
  '33942': '33.94',
  '33943': '33.94',
  '33944': '33.94',
  '33945': '33.94',
  '33946': '33.95',
  '33947': '33.95',
  '33948': '33.95',
  '33949': '33.95',
  '33950': '33.95',
  '33951': '33.95',
  '33952': '33.95',
  '33953': '33.95',
  '33954': '33.95',
  '33955': '33.96',
  '33956': '33.96',
  '33957': '33.96',
  '33958': '33.96',
  '33959': '33.96',
  '33960': '33.96',
  '33961': '33.96',
  '33962': '33.96',
  '33963': '33.96',
  '33964': '33.96',
  '33965': '33.97',
  '33966': '33.97',
  '33967': '33.97',
  '33968': '33.97',
  '33969': '33.97',
  '33970': '33.97',
  '33971': '33.97',
  '33972': '33.97',
  '33973': '33.97',
  '33974': '33.97',
  '33975': '33.97',
  '33976': '33.98',
  '33977': '33.98',
  '33978': '33.98',
  '33979': '33.98',
  '33980': '33.98',
  '33981': '33.98',
  '33982': '33.98',
  '33983': '33.98',
  '33984': '33.98',
  '33985': '33.99',
  '33986': '33.99',
  '33987': '33.99',
  '33988': '33.99',
  '33989': '33.99',
  '33990': '33.99',
  '33991': '33.99',
  '33992': '33.99',
  '33993': '33.99',
  '33994': '33.99',
  '33995': '33.99',
  '33996': '34.00',
  '33997': '34.00',
  '33998': '34.00',
  '33999': '34.00',
  '34000': '34.00',
  '34001': '34.00',
  '34002': '34.00',
  '34003': '34.00',
  '34004': '34.00',
  '34005': '34.01',
  '34006': '34.01',
  '34007': '34.01',
  '34008': '34.01',
  '34009': '34.01',
  '34010': '34.01',
  '34011': '34.01',
  '34012': '34.01',
  '34013': '34.01',
  '34014': '34.01',
  '34015': '34.01',
  '34016': '34.02',
  '34017': '34.02',
  '34018': '34.02',
  '34019': '34.02',
  '34020': '34.02',
  '34021': '34.02',
  '34022': '34.02',
  '34023': '34.02',
  '34024': '34.02',
  '34025': '34.03',
  '34026': '34.03',
  '34027': '34.03',
  '34028': '34.03',
  '34029': '34.03',
  '34030': '34.03',
  '34031': '34.03',
  '34032': '34.03',
  '34033': '34.03',
  '34034': '34.03',
  '34035': '34.03',
  '34036': '34.04',
  '34037': '34.04',
  '34038': '34.04',
  '34039': '34.04',
  '34040': '34.04',
  '34041': '34.04',
  '34042': '34.04',
  '34043': '34.04',
  '34044': '34.04',
  '34045': '34.04',
  '34046': '34.05',
  '34047': '34.05',
  '34048': '34.05',
  '34049': '34.05',
  '34050': '34.05',
  '34051': '34.05',
  '34052': '34.05',
  '34053': '34.05',
  '34054': '34.05',
  '34055': '34.06',
  '34056': '34.06',
  '34057': '34.06',
  '34058': '34.06',
  '34059': '34.06',
  '34060': '34.06',
  '34061': '34.06',
  '34062': '34.06',
  '34063': '34.06',
  '34064': '34.06',
  '34065': '34.06',
  '34066': '34.07',
  '34067': '34.07',
  '34068': '34.07',
  '34069': '34.07',
  '34070': '34.07',
  '34071': '34.07',
  '34072': '34.07',
  '34073': '34.07',
  '34074': '34.07',
  '34075': '34.08',
  '34076': '34.08',
  '34077': '34.08',
  '34078': '34.08',
  '34079': '34.08',
  '34080': '34.08',
  '34081': '34.08',
  '34082': '34.08',
  '34083': '34.08',
  '34084': '34.08',
  '34085': '34.08',
  '34086': '34.09',
  '34087': '34.09',
  '34088': '34.09',
  '34089': '34.09',
  '34090': '34.09',
  '34091': '34.09',
  '34092': '34.09',
  '34093': '34.09',
  '34094': '34.09',
  '34095': '34.10',
  '34096': '34.10',
  '34097': '34.10',
  '34098': '34.10',
  '34099': '34.10',
  '34100': '34.10',
  '34101': '34.10',
  '34102': '34.10',
  '34103': '34.10',
  '34104': '34.10',
  '34105': '34.10',
  '34106': '34.11',
  '34107': '34.11',
  '34108': '34.11',
  '34109': '34.11',
  '34110': '34.11',
  '34111': '34.11',
  '34112': '34.11',
  '34113': '34.11',
  '34114': '34.11',
  '34115': '34.12',
  '34116': '34.12',
  '34117': '34.12',
  '34118': '34.12',
  '34119': '34.12',
  '34120': '34.12',
  '34121': '34.12',
  '34122': '34.12',
  '34123': '34.12',
  '34124': '34.12',
  '34125': '34.12',
  '34126': '34.13',
  '34127': '34.13',
  '34128': '34.13',
  '34129': '34.13',
  '34130': '34.13',
  '34131': '34.13',
  '34132': '34.13',
  '34133': '34.13',
  '34134': '34.13',
  '34135': '34.13',
  '34136': '34.14',
  '34137': '34.14',
  '34138': '34.14',
  '34139': '34.14',
  '34140': '34.14',
  '34141': '34.14',
  '34142': '34.14',
  '34143': '34.14',
  '34144': '34.14',
  '34145': '34.15',
  '34146': '34.15',
  '34147': '34.15',
  '34148': '34.15',
  '34149': '34.15',
  '34150': '34.15',
  '34151': '34.15',
  '34152': '34.15',
  '34153': '34.15',
  '34154': '34.15',
  '34155': '34.15',
  '34156': '34.16',
  '34157': '34.16',
  '34158': '34.16',
  '34159': '34.16',
  '34160': '34.16',
  '34161': '34.16',
  '34162': '34.16',
  '34163': '34.16',
  '34164': '34.16',
  '34165': '34.17',
  '34166': '34.17',
  '34167': '34.17',
  '34168': '34.17',
  '34169': '34.17',
  '34170': '34.17',
  '34171': '34.17',
  '34172': '34.17',
  '34173': '34.17',
  '34174': '34.17',
  '34175': '34.17',
  '34176': '34.18',
  '34177': '34.18',
  '34178': '34.18',
  '34179': '34.18',
  '34180': '34.18',
  '34181': '34.18',
  '34182': '34.18',
  '34183': '34.18',
  '34184': '34.18',
  '34185': '34.19',
  '34186': '34.19',
  '34187': '34.19',
  '34188': '34.19',
  '34189': '34.19',
  '34190': '34.19',
  '34191': '34.19',
  '34192': '34.19',
  '34193': '34.19',
  '34194': '34.19',
  '34195': '34.19',
  '34196': '34.20',
  '34197': '34.20',
  '34198': '34.20',
  '34199': '34.20',
  '34200': '34.20',
  '34201': '34.20',
  '34202': '34.20',
  '34203': '34.20',
  '34204': '34.20',
  '34205': '34.21',
  '34206': '34.21',
  '34207': '34.21',
  '34208': '34.21',
  '34209': '34.21',
  '34210': '34.21',
  '34211': '34.21',
  '34212': '34.21',
  '34213': '34.21',
  '34214': '34.21',
  '34215': '34.22',
  '34216': '34.22',
  '34217': '34.22',
  '34218': '34.22',
  '34219': '34.22',
  '34220': '34.22',
  '34221': '34.22',
  '34222': '34.22',
  '34223': '34.22',
  '34224': '34.22',
  '34225': '34.22',
  '34226': '34.23',
  '34227': '34.23',
  '34228': '34.23',
  '34229': '34.23',
  '34230': '34.23',
  '34231': '34.23',
  '34232': '34.23',
  '34233': '34.23',
  '34234': '34.23',
  '34235': '34.24',
  '34236': '34.24',
  '34237': '34.24',
  '34238': '34.24',
  '34239': '34.24',
  '34240': '34.24',
  '34241': '34.24',
  '34242': '34.24',
  '34243': '34.24',
  '34244': '34.24',
  '34245': '34.24',
  '34246': '34.25',
  '34247': '34.25',
  '34248': '34.25',
  '34249': '34.25',
  '34250': '34.25',
  '34251': '34.25',
  '34252': '34.25',
  '34253': '34.25',
  '34254': '34.25',
  '34255': '34.26',
  '34256': '34.26',
  '34257': '34.26',
  '34258': '34.26',
  '34259': '34.26',
  '34260': '34.26',
  '34261': '34.26',
  '34262': '34.26',
  '34263': '34.26',
  '34264': '34.26',
  '34265': '34.26',
  '34266': '34.27',
  '34267': '34.27',
  '34268': '34.27',
  '34269': '34.27',
  '34270': '34.27',
  '34271': '34.27',
  '34272': '34.27',
  '34273': '34.27',
  '34274': '34.27',
  '34275': '34.28',
  '34276': '34.28',
  '34277': '34.28',
  '34278': '34.28',
  '34279': '34.28',
  '34280': '34.28',
  '34281': '34.28',
  '34282': '34.28',
  '34283': '34.28',
  '34284': '34.28',
  '34285': '34.28',
  '34286': '34.29',
  '34287': '34.29',
  '34288': '34.29',
  '34289': '34.29',
  '34290': '34.29',
  '34291': '34.29',
  '34292': '34.29',
  '34293': '34.29',
  '34294': '34.29',
  '34295': '34.29',
  '34296': '34.30',
  '34297': '34.30',
  '34298': '34.30',
  '34299': '34.30',
  '34300': '34.30',
  '34301': '34.30',
  '34302': '34.30',
  '34303': '34.30',
  '34304': '34.30',
  '34305': '34.31',
  '34306': '34.31',
  '34307': '34.31',
  '34308': '34.31',
  '34309': '34.31',
  '34310': '34.31',
  '34311': '34.31',
  '34312': '34.31',
  '34313': '34.31',
  '34314': '34.31',
  '34315': '34.31',
  '34316': '34.32',
  '34317': '34.32',
  '34318': '34.32',
  '34319': '34.32',
  '34320': '34.32',
  '34321': '34.32',
  '34322': '34.32',
  '34323': '34.32',
  '34324': '34.32',
  '34325': '34.33',
  '34326': '34.33',
  '34327': '34.33',
  '34328': '34.33',
  '34329': '34.33',
  '34330': '34.33',
  '34331': '34.33',
  '34332': '34.33',
  '34333': '34.33',
  '34334': '34.33',
  '34335': '34.33',
  '34336': '34.34',
  '34337': '34.34',
  '34338': '34.34',
  '34339': '34.34',
  '34340': '34.34',
  '34341': '34.34',
  '34342': '34.34',
  '34343': '34.34',
  '34344': '34.34',
  '34345': '34.35',
  '34346': '34.35',
  '34347': '34.35',
  '34348': '34.35',
  '34349': '34.35',
  '34350': '34.35',
  '34351': '34.35',
  '34352': '34.35',
  '34353': '34.35',
  '34354': '34.35',
  '34355': '34.35',
  '34356': '34.36',
  '34357': '34.36',
  '34358': '34.36',
  '34359': '34.36',
  '34360': '34.36',
  '34361': '34.36',
  '34362': '34.36',
  '34363': '34.36',
  '34364': '34.36',
  '34365': '34.37',
  '34366': '34.37',
  '34367': '34.37',
  '34368': '34.37',
  '34369': '34.37',
  '34370': '34.37',
  '34371': '34.37',
  '34372': '34.37',
  '34373': '34.37',
  '34374': '34.37',
  '34375': '34.38',
  '34376': '34.38',
  '34377': '34.38',
  '34378': '34.38',
  '34379': '34.38',
  '34380': '34.38',
  '34381': '34.38',
  '34382': '34.38',
  '34383': '34.38',
  '34384': '34.38',
  '34385': '34.38',
  '34386': '34.39',
  '34387': '34.39',
  '34388': '34.39',
  '34389': '34.39',
  '34390': '34.39',
  '34391': '34.39',
  '34392': '34.39',
  '34393': '34.39',
  '34394': '34.39',
  '34395': '34.40',
  '34396': '34.40',
  '34397': '34.40',
  '34398': '34.40',
  '34399': '34.40',
  '34400': '34.40',
  '34401': '34.40',
  '34402': '34.40',
  '34403': '34.40',
  '34404': '34.40',
  '34405': '34.40',
  '34406': '34.41',
  '34407': '34.41',
  '34408': '34.41',
  '34409': '34.41',
  '34410': '34.41',
  '34411': '34.41',
  '34412': '34.41',
  '34413': '34.41',
  '34414': '34.41',
  '34415': '34.42',
  '34416': '34.42',
  '34417': '34.42',
  '34418': '34.42',
  '34419': '34.42',
  '34420': '34.42',
  '34421': '34.42',
  '34422': '34.42',
  '34423': '34.42',
  '34424': '34.42',
  '34425': '34.42',
  '34426': '34.43',
  '34427': '34.43',
  '34428': '34.43',
  '34429': '34.43',
  '34430': '34.43',
  '34431': '34.43',
  '34432': '34.43',
  '34433': '34.43',
  '34434': '34.43',
  '34435': '34.44',
  '34436': '34.44',
  '34437': '34.44',
  '34438': '34.44',
  '34439': '34.44',
  '34440': '34.44',
  '34441': '34.44',
  '34442': '34.44',
  '34443': '34.44',
  '34444': '34.44',
  '34445': '34.44',
  '34446': '34.45',
  '34447': '34.45',
  '34448': '34.45',
  '34449': '34.45',
  '34450': '34.45',
  '34451': '34.45',
  '34452': '34.45',
  '34453': '34.45',
  '34454': '34.45',
  '34455': '34.46',
  '34456': '34.46',
  '34457': '34.46',
  '34458': '34.46',
  '34459': '34.46',
  '34460': '34.46',
  '34461': '34.46',
  '34462': '34.46',
  '34463': '34.46',
  '34464': '34.46',
  '34465': '34.47',
  '34466': '34.47',
  '34467': '34.47',
  '34468': '34.47',
  '34469': '34.47',
  '34470': '34.47',
  '34471': '34.47',
  '34472': '34.47',
  '34473': '34.47',
  '34474': '34.47',
  '34475': '34.47',
  '34476': '34.48',
  '34477': '34.48',
  '34478': '34.48',
  '34479': '34.48',
  '34480': '34.48',
  '34481': '34.48',
  '34482': '34.48',
  '34483': '34.48',
  '34484': '34.48',
  '34485': '34.49',
  '34486': '34.49',
  '34487': '34.49',
  '34488': '34.49',
  '34489': '34.49',
  '34490': '34.49',
  '34491': '34.49',
  '34492': '34.49',
  '34493': '34.49',
  '34494': '34.49',
  '34495': '34.49',
  '34496': '34.50',
  '34497': '34.50',
  '34498': '34.50',
  '34499': '34.50',
  '34500': '34.50',
  '34501': '34.50',
  '34502': '34.50',
  '34503': '34.50',
  '34504': '34.50',
  '34505': '34.51',
  '34506': '34.51',
  '34507': '34.51',
  '34508': '34.51',
  '34509': '34.51',
  '34510': '34.51',
  '34511': '34.51',
  '34512': '34.51',
  '34513': '34.51',
  '34514': '34.51',
  '34515': '34.51',
  '34516': '34.52',
  '34517': '34.52',
  '34518': '34.52',
  '34519': '34.52',
  '34520': '34.52',
  '34521': '34.52',
  '34522': '34.52',
  '34523': '34.52',
  '34524': '34.52',
  '34525': '34.53',
  '34526': '34.53',
  '34527': '34.53',
  '34528': '34.53',
  '34529': '34.53',
  '34530': '34.53',
  '34531': '34.53',
  '34532': '34.53',
  '34533': '34.53',
  '34534': '34.53',
  '34535': '34.53',
  '34536': '34.54',
  '34537': '34.54',
  '34538': '34.54',
  '34539': '34.54',
  '34540': '34.54',
  '34541': '34.54',
  '34542': '34.54',
  '34543': '34.54',
  '34544': '34.54',
  '34545': '34.54',
  '34546': '34.55',
  '34547': '34.55',
  '34548': '34.55',
  '34549': '34.55',
  '34550': '34.55',
  '34551': '34.55',
  '34552': '34.55',
  '34553': '34.55',
  '34554': '34.55',
  '34555': '34.56',
  '34556': '34.56',
  '34557': '34.56',
  '34558': '34.56',
  '34559': '34.56',
  '34560': '34.56',
  '34561': '34.56',
  '34562': '34.56',
  '34563': '34.56',
  '34564': '34.56',
  '34565': '34.56',
  '34566': '34.57',
  '34567': '34.57',
  '34568': '34.57',
  '34569': '34.57',
  '34570': '34.57',
  '34571': '34.57',
  '34572': '34.57',
  '34573': '34.57',
  '34574': '34.57',
  '34575': '34.58',
  '34576': '34.58',
  '34577': '34.58',
  '34578': '34.58',
  '34579': '34.58',
  '34580': '34.58',
  '34581': '34.58',
  '34582': '34.58',
  '34583': '34.58',
  '34584': '34.58',
  '34585': '34.58',
  '34586': '34.59',
  '34587': '34.59',
  '34588': '34.59',
  '34589': '34.59',
  '34590': '34.59',
  '34591': '34.59',
  '34592': '34.59',
  '34593': '34.59',
  '34594': '34.59',
  '34595': '34.60',
  '34596': '34.60',
  '34597': '34.60',
  '34598': '34.60',
  '34599': '34.60',
  '34600': '34.60',
  '34601': '34.60',
  '34602': '34.60',
  '34603': '34.60',
  '34604': '34.60',
  '34605': '34.60',
  '34606': '34.61',
  '34607': '34.61',
  '34608': '34.61',
  '34609': '34.61',
  '34610': '34.61',
  '34611': '34.61',
  '34612': '34.61',
  '34613': '34.61',
  '34614': '34.61',
  '34615': '34.62',
  '34616': '34.62',
  '34617': '34.62',
  '34618': '34.62',
  '34619': '34.62',
  '34620': '34.62',
  '34621': '34.62',
  '34622': '34.62',
  '34623': '34.62',
  '34624': '34.62',
  '34625': '34.62',
  '34626': '34.63',
  '34627': '34.63',
  '34628': '34.63',
  '34629': '34.63',
  '34630': '34.63',
  '34631': '34.63',
  '34632': '34.63',
  '34633': '34.63',
  '34634': '34.63',
  '34635': '34.63',
  '34636': '34.64',
  '34637': '34.64',
  '34638': '34.64',
  '34639': '34.64',
  '34640': '34.64',
  '34641': '34.64',
  '34642': '34.64',
  '34643': '34.64',
  '34644': '34.64',
  '34645': '34.65',
  '34646': '34.65',
  '34647': '34.65',
  '34648': '34.65',
  '34649': '34.65',
  '34650': '34.65',
  '34651': '34.65',
  '34652': '34.65',
  '34653': '34.65',
  '34654': '34.65',
  '34655': '34.65',
  '34656': '34.66',
  '34657': '34.66',
  '34658': '34.66',
  '34659': '34.66',
  '34660': '34.66',
  '34661': '34.66',
  '34662': '34.66',
  '34663': '34.66',
  '34664': '34.66',
  '34665': '34.67',
  '34666': '34.67',
  '34667': '34.67',
  '34668': '34.67',
  '34669': '34.67',
  '34670': '34.67',
  '34671': '34.67',
  '34672': '34.67',
  '34673': '34.67',
  '34674': '34.67',
  '34675': '34.67',
  '34676': '34.68',
  '34677': '34.68',
  '34678': '34.68',
  '34679': '34.68',
  '34680': '34.68',
  '34681': '34.68',
  '34682': '34.68',
  '34683': '34.68',
  '34684': '34.68',
  '34685': '34.69',
  '34686': '34.69',
  '34687': '34.69',
  '34688': '34.69',
  '34689': '34.69',
  '34690': '34.69',
  '34691': '34.69',
  '34692': '34.69',
  '34693': '34.69',
  '34694': '34.69',
  '34695': '34.69',
  '34696': '34.70',
  '34697': '34.70',
  '34698': '34.70',
  '34699': '34.70',
  '34700': '34.70',
  '34701': '34.70',
  '34702': '34.70',
  '34703': '34.70',
  '34704': '34.70',
  '34705': '34.71',
  '34706': '34.71',
  '34707': '34.71',
  '34708': '34.71',
  '34709': '34.71',
  '34710': '34.71',
  '34711': '34.71',
  '34712': '34.71',
  '34713': '34.71',
  '34714': '34.71',
  '34715': '34.72',
  '34716': '34.72',
  '34717': '34.72',
  '34718': '34.72',
  '34719': '34.72',
  '34720': '34.72',
  '34721': '34.72',
  '34722': '34.72',
  '34723': '34.72',
  '34724': '34.72',
  '34725': '34.72',
  '34726': '34.73',
  '34727': '34.73',
  '34728': '34.73',
  '34729': '34.73',
  '34730': '34.73',
  '34731': '34.73',
  '34732': '34.73',
  '34733': '34.73',
  '34734': '34.73',
  '34735': '34.74',
  '34736': '34.74',
  '34737': '34.74',
  '34738': '34.74',
  '34739': '34.74',
  '34740': '34.74',
  '34741': '34.74',
  '34742': '34.74',
  '34743': '34.74',
  '34744': '34.74',
  '34745': '34.74',
  '34746': '34.75',
  '34747': '34.75',
  '34748': '34.75',
  '34749': '34.75',
  '34750': '34.75',
  '34751': '34.75',
  '34752': '34.75',
  '34753': '34.75',
  '34754': '34.75',
  '34755': '34.76',
  '34756': '34.76',
  '34757': '34.76',
  '34758': '34.76',
  '34759': '34.76',
  '34760': '34.76',
  '34761': '34.76',
  '34762': '34.76',
  '34763': '34.76',
  '34764': '34.76',
  '34765': '34.76',
  '34766': '34.77',
  '34767': '34.77',
  '34768': '34.77',
  '34769': '34.77',
  '34770': '34.77',
  '34771': '34.77',
  '34772': '34.77',
  '34773': '34.77',
  '34774': '34.77',
  '34775': '34.78',
  '34776': '34.78',
  '34777': '34.78',
  '34778': '34.78',
  '34779': '34.78',
  '34780': '34.78',
  '34781': '34.78',
  '34782': '34.78',
  '34783': '34.78',
  '34784': '34.78',
  '34785': '34.78',
  '34786': '34.79',
  '34787': '34.79',
  '34788': '34.79',
  '34789': '34.79',
  '34790': '34.79',
  '34791': '34.79',
  '34792': '34.79',
  '34793': '34.79',
  '34794': '34.79',
  '34795': '34.79',
  '34796': '34.80',
  '34797': '34.80',
  '34798': '34.80',
  '34799': '34.80',
  '34800': '34.80',
  '34801': '34.80',
  '34802': '34.80',
  '34803': '34.80',
  '34804': '34.80',
  '34805': '34.81',
  '34806': '34.81',
  '34807': '34.81',
  '34808': '34.81',
  '34809': '34.81',
  '34810': '34.81',
  '34811': '34.81',
  '34812': '34.81',
  '34813': '34.81',
  '34814': '34.81',
  '34815': '34.81',
  '34816': '34.82',
  '34817': '34.82',
  '34818': '34.82',
  '34819': '34.82',
  '34820': '34.82',
  '34821': '34.82',
  '34822': '34.82',
  '34823': '34.82',
  '34824': '34.82',
  '34825': '34.83',
  '34826': '34.83',
  '34827': '34.83',
  '34828': '34.83',
  '34829': '34.83',
  '34830': '34.83',
  '34831': '34.83',
  '34832': '34.83',
  '34833': '34.83',
  '34834': '34.83',
  '34835': '34.83',
  '34836': '34.84',
  '34837': '34.84',
  '34838': '34.84',
  '34839': '34.84',
  '34840': '34.84',
  '34841': '34.84',
  '34842': '34.84',
  '34843': '34.84',
  '34844': '34.84',
  '34845': '34.85',
  '34846': '34.85',
  '34847': '34.85',
  '34848': '34.85',
  '34849': '34.85',
  '34850': '34.85',
  '34851': '34.85',
  '34852': '34.85',
  '34853': '34.85',
  '34854': '34.85',
  '34855': '34.85',
  '34856': '34.86',
  '34857': '34.86',
  '34858': '34.86',
  '34859': '34.86',
  '34860': '34.86',
  '34861': '34.86',
  '34862': '34.86',
  '34863': '34.86',
  '34864': '34.86',
  '34865': '34.87',
  '34866': '34.87',
  '34867': '34.87',
  '34868': '34.87',
  '34869': '34.87',
  '34870': '34.87',
  '34871': '34.87',
  '34872': '34.87',
  '34873': '34.87',
  '34874': '34.87',
  '34875': '34.88',
  '34876': '34.88',
  '34877': '34.88',
  '34878': '34.88',
  '34879': '34.88',
  '34880': '34.88',
  '34881': '34.88',
  '34882': '34.88',
  '34883': '34.88',
  '34884': '34.88',
  '34885': '34.88',
  '34886': '34.89',
  '34887': '34.89',
  '34888': '34.89',
  '34889': '34.89',
  '34890': '34.89',
  '34891': '34.89',
  '34892': '34.89',
  '34893': '34.89',
  '34894': '34.89',
  '34895': '34.90',
  '34896': '34.90',
  '34897': '34.90',
  '34898': '34.90',
  '34899': '34.90',
  '34900': '34.90',
  '34901': '34.90',
  '34902': '34.90',
  '34903': '34.90',
  '34904': '34.90',
  '34905': '34.90',
  '34906': '34.91',
  '34907': '34.91',
  '34908': '34.91',
  '34909': '34.91',
  '34910': '34.91',
  '34911': '34.91',
  '34912': '34.91',
  '34913': '34.91',
  '34914': '34.91',
  '34915': '34.92',
  '34916': '34.92',
  '34917': '34.92',
  '34918': '34.92',
  '34919': '34.92',
  '34920': '34.92',
  '34921': '34.92',
  '34922': '34.92',
  '34923': '34.92',
  '34924': '34.92',
  '34925': '34.92',
  '34926': '34.93',
  '34927': '34.93',
  '34928': '34.93',
  '34929': '34.93',
  '34930': '34.93',
  '34931': '34.93',
  '34932': '34.93',
  '34933': '34.93',
  '34934': '34.93',
  '34935': '34.94',
  '34936': '34.94',
  '34937': '34.94',
  '34938': '34.94',
  '34939': '34.94',
  '34940': '34.94',
  '34941': '34.94',
  '34942': '34.94',
  '34943': '34.94',
  '34944': '34.94',
  '34945': '34.94',
  '34946': '34.95',
  '34947': '34.95',
  '34948': '34.95',
  '34949': '34.95',
  '34950': '34.95',
  '34951': '34.95',
  '34952': '34.95',
  '34953': '34.95',
  '34954': '34.95',
  '34955': '34.96',
  '34956': '34.96',
  '34957': '34.96',
  '34958': '34.96',
  '34959': '34.96',
  '34960': '34.96',
  '34961': '34.96',
  '34962': '34.96',
  '34963': '34.96',
  '34964': '34.96',
  '34965': '34.97',
  '34966': '34.97',
  '34967': '34.97',
  '34968': '34.97',
  '34969': '34.97',
  '34970': '34.97',
  '34971': '34.97',
  '34972': '34.97',
  '34973': '34.97',
  '34974': '34.97',
  '34975': '34.97',
  '34976': '34.98',
  '34977': '34.98',
  '34978': '34.98',
  '34979': '34.98',
  '34980': '34.98',
  '34981': '34.98',
  '34982': '34.98',
  '34983': '34.98',
  '34984': '34.98',
  '34985': '34.99',
  '34986': '34.99',
  '34987': '34.99',
  '34988': '34.99',
  '34989': '34.99',
  '34990': '34.99',
  '34991': '34.99',
  '34992': '34.99',
  '34993': '34.99',
  '34994': '34.99',
  '34995': '34.99',
  '34996': '35.00',
  '34997': '35.00',
  '34998': '35.00',
  '34999': '35.00',
  '35000': '35.00',
  '35001': '35.00',
  '35002': '35.00',
  '35003': '35.00',
  '35004': '35.00',
  '35005': '35.01',
  '35006': '35.01',
  '35007': '35.01',
  '35008': '35.01',
  '35009': '35.01',
  '35010': '35.01',
  '35011': '35.01',
  '35012': '35.01',
  '35013': '35.01',
  '35014': '35.01',
  '35015': '35.01',
  '35016': '35.02',
  '35017': '35.02',
  '35018': '35.02',
  '35019': '35.02',
  '35020': '35.02',
  '35021': '35.02',
  '35022': '35.02',
  '35023': '35.02',
  '35024': '35.02',
  '35025': '35.03',
  '35026': '35.03',
  '35027': '35.03',
  '35028': '35.03',
  '35029': '35.03',
  '35030': '35.03',
  '35031': '35.03',
  '35032': '35.03',
  '35033': '35.03',
  '35034': '35.03',
  '35035': '35.03',
  '35036': '35.04',
  '35037': '35.04',
  '35038': '35.04',
  '35039': '35.04',
  '35040': '35.04',
  '35041': '35.04',
  '35042': '35.04',
  '35043': '35.04',
  '35044': '35.04',
  '35045': '35.04',
  '35046': '35.05',
  '35047': '35.05',
  '35048': '35.05',
  '35049': '35.05',
  '35050': '35.05',
  '35051': '35.05',
  '35052': '35.05',
  '35053': '35.05',
  '35054': '35.05',
  '35055': '35.06',
  '35056': '35.06',
  '35057': '35.06',
  '35058': '35.06',
  '35059': '35.06',
  '35060': '35.06',
  '35061': '35.06',
  '35062': '35.06',
  '35063': '35.06',
  '35064': '35.06',
  '35065': '35.06',
  '35066': '35.07',
  '35067': '35.07',
  '35068': '35.07',
  '35069': '35.07',
  '35070': '35.07',
  '35071': '35.07',
  '35072': '35.07',
  '35073': '35.07',
  '35074': '35.07',
  '35075': '35.08',
  '35076': '35.08',
  '35077': '35.08',
  '35078': '35.08',
  '35079': '35.08',
  '35080': '35.08',
  '35081': '35.08',
  '35082': '35.08',
  '35083': '35.08',
  '35084': '35.08',
  '35085': '35.08',
  '35086': '35.09',
  '35087': '35.09',
  '35088': '35.09',
  '35089': '35.09',
  '35090': '35.09',
  '35091': '35.09',
  '35092': '35.09',
  '35093': '35.09',
  '35094': '35.09',
  '35095': '35.10',
  '35096': '35.10',
  '35097': '35.10',
  '35098': '35.10',
  '35099': '35.10',
  '35100': '35.10',
  '35101': '35.10',
  '35102': '35.10',
  '35103': '35.10',
  '35104': '35.10',
  '35105': '35.10',
  '35106': '35.11',
  '35107': '35.11',
  '35108': '35.11',
  '35109': '35.11',
  '35110': '35.11',
  '35111': '35.11',
  '35112': '35.11',
  '35113': '35.11',
  '35114': '35.11',
  '35115': '35.12',
  '35116': '35.12',
  '35117': '35.12',
  '35118': '35.12',
  '35119': '35.12',
  '35120': '35.12',
  '35121': '35.12',
  '35122': '35.12',
  '35123': '35.12',
  '35124': '35.12',
  '35125': '35.12',
  '35126': '35.13',
  '35127': '35.13',
  '35128': '35.13',
  '35129': '35.13',
  '35130': '35.13',
  '35131': '35.13',
  '35132': '35.13',
  '35133': '35.13',
  '35134': '35.13',
  '35135': '35.13',
  '35136': '35.14',
  '35137': '35.14',
  '35138': '35.14',
  '35139': '35.14',
  '35140': '35.14',
  '35141': '35.14',
  '35142': '35.14',
  '35143': '35.14',
  '35144': '35.14',
  '35145': '35.15',
  '35146': '35.15',
  '35147': '35.15',
  '35148': '35.15',
  '35149': '35.15',
  '35150': '35.15',
  '35151': '35.15',
  '35152': '35.15',
  '35153': '35.15',
  '35154': '35.15',
  '35155': '35.15',
  '35156': '35.16',
  '35157': '35.16',
  '35158': '35.16',
  '35159': '35.16',
  '35160': '35.16',
  '35161': '35.16',
  '35162': '35.16',
  '35163': '35.16',
  '35164': '35.16',
  '35165': '35.17',
  '35166': '35.17',
  '35167': '35.17',
  '35168': '35.17',
  '35169': '35.17',
  '35170': '35.17',
  '35171': '35.17',
  '35172': '35.17',
  '35173': '35.17',
  '35174': '35.17',
  '35175': '35.17',
  '35176': '35.18',
  '35177': '35.18',
  '35178': '35.18',
  '35179': '35.18',
  '35180': '35.18',
  '35181': '35.18',
  '35182': '35.18',
  '35183': '35.18',
  '35184': '35.18',
  '35185': '35.19',
  '35186': '35.19',
  '35187': '35.19',
  '35188': '35.19',
  '35189': '35.19',
  '35190': '35.19',
  '35191': '35.19',
  '35192': '35.19',
  '35193': '35.19',
  '35194': '35.19',
  '35195': '35.19',
  '35196': '35.20',
  '35197': '35.20',
  '35198': '35.20',
  '35199': '35.20',
  '35200': '35.20',
  '35201': '35.20',
  '35202': '35.20',
  '35203': '35.20',
  '35204': '35.20',
  '35205': '35.21',
  '35206': '35.21',
  '35207': '35.21',
  '35208': '35.21',
  '35209': '35.21',
  '35210': '35.21',
  '35211': '35.21',
  '35212': '35.21',
  '35213': '35.21',
  '35214': '35.21',
  '35215': '35.22',
  '35216': '35.22',
  '35217': '35.22',
  '35218': '35.22',
  '35219': '35.22',
  '35220': '35.22',
  '35221': '35.22',
  '35222': '35.22',
  '35223': '35.22',
  '35224': '35.22',
  '35225': '35.22',
  '35226': '35.23',
  '35227': '35.23',
  '35228': '35.23',
  '35229': '35.23',
  '35230': '35.23',
  '35231': '35.23',
  '35232': '35.23',
  '35233': '35.23',
  '35234': '35.23',
  '35235': '35.24',
  '35236': '35.24',
  '35237': '35.24',
  '35238': '35.24',
  '35239': '35.24',
  '35240': '35.24',
  '35241': '35.24',
  '35242': '35.24',
  '35243': '35.24',
  '35244': '35.24',
  '35245': '35.24',
  '35246': '35.25',
  '35247': '35.25',
  '35248': '35.25',
  '35249': '35.25',
  '35250': '35.25',
  '35251': '35.25',
  '35252': '35.25',
  '35253': '35.25',
  '35254': '35.25',
  '35255': '35.26',
  '35256': '35.26',
  '35257': '35.26',
  '35258': '35.26',
  '35259': '35.26',
  '35260': '35.26',
  '35261': '35.26',
  '35262': '35.26',
  '35263': '35.26',
  '35264': '35.26',
  '35265': '35.26',
  '35266': '35.27',
  '35267': '35.27',
  '35268': '35.27',
  '35269': '35.27',
  '35270': '35.27',
  '35271': '35.27',
  '35272': '35.27',
  '35273': '35.27',
  '35274': '35.27',
  '35275': '35.28',
  '35276': '35.28',
  '35277': '35.28',
  '35278': '35.28',
  '35279': '35.28',
  '35280': '35.28',
  '35281': '35.28',
  '35282': '35.28',
  '35283': '35.28',
  '35284': '35.28',
  '35285': '35.28',
  '35286': '35.29',
  '35287': '35.29',
  '35288': '35.29',
  '35289': '35.29',
  '35290': '35.29',
  '35291': '35.29',
  '35292': '35.29',
  '35293': '35.29',
  '35294': '35.29',
  '35295': '35.29',
  '35296': '35.30',
  '35297': '35.30',
  '35298': '35.30',
  '35299': '35.30',
  '35300': '35.30',
  '35301': '35.30',
  '35302': '35.30',
  '35303': '35.30',
  '35304': '35.30',
  '35305': '35.31',
  '35306': '35.31',
  '35307': '35.31',
  '35308': '35.31',
  '35309': '35.31',
  '35310': '35.31',
  '35311': '35.31',
  '35312': '35.31',
  '35313': '35.31',
  '35314': '35.31',
  '35315': '35.31',
  '35316': '35.32',
  '35317': '35.32',
  '35318': '35.32',
  '35319': '35.32',
  '35320': '35.32',
  '35321': '35.32',
  '35322': '35.32',
  '35323': '35.32',
  '35324': '35.32',
  '35325': '35.33',
  '35326': '35.33',
  '35327': '35.33',
  '35328': '35.33',
  '35329': '35.33',
  '35330': '35.33',
  '35331': '35.33',
  '35332': '35.33',
  '35333': '35.33',
  '35334': '35.33',
  '35335': '35.33',
  '35336': '35.34',
  '35337': '35.34',
  '35338': '35.34',
  '35339': '35.34',
  '35340': '35.34',
  '35341': '35.34',
  '35342': '35.34',
  '35343': '35.34',
  '35344': '35.34',
  '35345': '35.35',
  '35346': '35.35',
  '35347': '35.35',
  '35348': '35.35',
  '35349': '35.35',
  '35350': '35.35',
  '35351': '35.35',
  '35352': '35.35',
  '35353': '35.35',
  '35354': '35.35',
  '35355': '35.35',
  '35356': '35.36',
  '35357': '35.36',
  '35358': '35.36',
  '35359': '35.36',
  '35360': '35.36',
  '35361': '35.36',
  '35362': '35.36',
  '35363': '35.36',
  '35364': '35.36',
  '35365': '35.37',
  '35366': '35.37',
  '35367': '35.37',
  '35368': '35.37',
  '35369': '35.37',
  '35370': '35.37',
  '35371': '35.37',
  '35372': '35.37',
  '35373': '35.37',
  '35374': '35.37',
  '35375': '35.38',
  '35376': '35.38',
  '35377': '35.38',
  '35378': '35.38',
  '35379': '35.38',
  '35380': '35.38',
  '35381': '35.38',
  '35382': '35.38',
  '35383': '35.38',
  '35384': '35.38',
  '35385': '35.38',
  '35386': '35.39',
  '35387': '35.39',
  '35388': '35.39',
  '35389': '35.39',
  '35390': '35.39',
  '35391': '35.39',
  '35392': '35.39',
  '35393': '35.39',
  '35394': '35.39',
  '35395': '35.40',
  '35396': '35.40',
  '35397': '35.40',
  '35398': '35.40',
  '35399': '35.40',
  '35400': '35.40',
  '35401': '35.40',
  '35402': '35.40',
  '35403': '35.40',
  '35404': '35.40',
  '35405': '35.40',
  '35406': '35.41',
  '35407': '35.41',
  '35408': '35.41',
  '35409': '35.41',
  '35410': '35.41',
  '35411': '35.41',
  '35412': '35.41',
  '35413': '35.41',
  '35414': '35.41',
  '35415': '35.42',
  '35416': '35.42',
  '35417': '35.42',
  '35418': '35.42',
  '35419': '35.42',
  '35420': '35.42',
  '35421': '35.42',
  '35422': '35.42',
  '35423': '35.42',
  '35424': '35.42',
  '35425': '35.42',
  '35426': '35.43',
  '35427': '35.43',
  '35428': '35.43',
  '35429': '35.43',
  '35430': '35.43',
  '35431': '35.43',
  '35432': '35.43',
  '35433': '35.43',
  '35434': '35.43',
  '35435': '35.44',
  '35436': '35.44',
  '35437': '35.44',
  '35438': '35.44',
  '35439': '35.44',
  '35440': '35.44',
  '35441': '35.44',
  '35442': '35.44',
  '35443': '35.44',
  '35444': '35.44',
  '35445': '35.44',
  '35446': '35.45',
  '35447': '35.45',
  '35448': '35.45',
  '35449': '35.45',
  '35450': '35.45',
  '35451': '35.45',
  '35452': '35.45',
  '35453': '35.45',
  '35454': '35.45',
  '35455': '35.46',
  '35456': '35.46',
  '35457': '35.46',
  '35458': '35.46',
  '35459': '35.46',
  '35460': '35.46',
  '35461': '35.46',
  '35462': '35.46',
  '35463': '35.46',
  '35464': '35.46',
  '35465': '35.47',
  '35466': '35.47',
  '35467': '35.47',
  '35468': '35.47',
  '35469': '35.47',
  '35470': '35.47',
  '35471': '35.47',
  '35472': '35.47',
  '35473': '35.47',
  '35474': '35.47',
  '35475': '35.47',
  '35476': '35.48',
  '35477': '35.48',
  '35478': '35.48',
  '35479': '35.48',
  '35480': '35.48',
  '35481': '35.48',
  '35482': '35.48',
  '35483': '35.48',
  '35484': '35.48',
  '35485': '35.49',
  '35486': '35.49',
  '35487': '35.49',
  '35488': '35.49',
  '35489': '35.49',
  '35490': '35.49',
  '35491': '35.49',
  '35492': '35.49',
  '35493': '35.49',
  '35494': '35.49',
  '35495': '35.49',
  '35496': '35.50',
  '35497': '35.50',
  '35498': '35.50',
  '35499': '35.50',
  '35500': '35.50',
  '35501': '35.50',
  '35502': '35.50',
  '35503': '35.50',
  '35504': '35.50',
  '35505': '35.51',
  '35506': '35.51',
  '35507': '35.51',
  '35508': '35.51',
  '35509': '35.51',
  '35510': '35.51',
  '35511': '35.51',
  '35512': '35.51',
  '35513': '35.51',
  '35514': '35.51',
  '35515': '35.51',
  '35516': '35.52',
  '35517': '35.52',
  '35518': '35.52',
  '35519': '35.52',
  '35520': '35.52',
  '35521': '35.52',
  '35522': '35.52',
  '35523': '35.52',
  '35524': '35.52',
  '35525': '35.53',
  '35526': '35.53',
  '35527': '35.53',
  '35528': '35.53',
  '35529': '35.53',
  '35530': '35.53',
  '35531': '35.53',
  '35532': '35.53',
  '35533': '35.53',
  '35534': '35.53',
  '35535': '35.53',
  '35536': '35.54',
  '35537': '35.54',
  '35538': '35.54',
  '35539': '35.54',
  '35540': '35.54',
  '35541': '35.54',
  '35542': '35.54',
  '35543': '35.54',
  '35544': '35.54',
  '35545': '35.54',
  '35546': '35.55',
  '35547': '35.55',
  '35548': '35.55',
  '35549': '35.55',
  '35550': '35.55',
  '35551': '35.55',
  '35552': '35.55',
  '35553': '35.55',
  '35554': '35.55',
  '35555': '35.56',
  '35556': '35.56',
  '35557': '35.56',
  '35558': '35.56',
  '35559': '35.56',
  '35560': '35.56',
  '35561': '35.56',
  '35562': '35.56',
  '35563': '35.56',
  '35564': '35.56',
  '35565': '35.56',
  '35566': '35.57',
  '35567': '35.57',
  '35568': '35.57',
  '35569': '35.57',
  '35570': '35.57',
  '35571': '35.57',
  '35572': '35.57',
  '35573': '35.57',
  '35574': '35.57',
  '35575': '35.58',
  '35576': '35.58',
  '35577': '35.58',
  '35578': '35.58',
  '35579': '35.58',
  '35580': '35.58',
  '35581': '35.58',
  '35582': '35.58',
  '35583': '35.58',
  '35584': '35.58',
  '35585': '35.58',
  '35586': '35.59',
  '35587': '35.59',
  '35588': '35.59',
  '35589': '35.59',
  '35590': '35.59',
  '35591': '35.59',
  '35592': '35.59',
  '35593': '35.59',
  '35594': '35.59',
  '35595': '35.60',
  '35596': '35.60',
  '35597': '35.60',
  '35598': '35.60',
  '35599': '35.60',
  '35600': '35.60',
  '35601': '35.60',
  '35602': '35.60',
  '35603': '35.60',
  '35604': '35.60',
  '35605': '35.60',
  '35606': '35.61',
  '35607': '35.61',
  '35608': '35.61',
  '35609': '35.61',
  '35610': '35.61',
  '35611': '35.61',
  '35612': '35.61',
  '35613': '35.61',
  '35614': '35.61',
  '35615': '35.62',
  '35616': '35.62',
  '35617': '35.62',
  '35618': '35.62',
  '35619': '35.62',
  '35620': '35.62',
  '35621': '35.62',
  '35622': '35.62',
  '35623': '35.62',
  '35624': '35.62',
  '35625': '35.62',
  '35626': '35.63',
  '35627': '35.63',
  '35628': '35.63',
  '35629': '35.63',
  '35630': '35.63',
  '35631': '35.63',
  '35632': '35.63',
  '35633': '35.63',
  '35634': '35.63',
  '35635': '35.63',
  '35636': '35.64',
  '35637': '35.64',
  '35638': '35.64',
  '35639': '35.64',
  '35640': '35.64',
  '35641': '35.64',
  '35642': '35.64',
  '35643': '35.64',
  '35644': '35.64',
  '35645': '35.65',
  '35646': '35.65',
  '35647': '35.65',
  '35648': '35.65',
  '35649': '35.65',
  '35650': '35.65',
  '35651': '35.65',
  '35652': '35.65',
  '35653': '35.65',
  '35654': '35.65',
  '35655': '35.65',
  '35656': '35.66',
  '35657': '35.66',
  '35658': '35.66',
  '35659': '35.66',
  '35660': '35.66',
  '35661': '35.66',
  '35662': '35.66',
  '35663': '35.66',
  '35664': '35.66',
  '35665': '35.67',
  '35666': '35.67',
  '35667': '35.67',
  '35668': '35.67',
  '35669': '35.67',
  '35670': '35.67',
  '35671': '35.67',
  '35672': '35.67',
  '35673': '35.67',
  '35674': '35.67',
  '35675': '35.67',
  '35676': '35.68',
  '35677': '35.68',
  '35678': '35.68',
  '35679': '35.68',
  '35680': '35.68',
  '35681': '35.68',
  '35682': '35.68',
  '35683': '35.68',
  '35684': '35.68',
  '35685': '35.69',
  '35686': '35.69',
  '35687': '35.69',
  '35688': '35.69',
  '35689': '35.69',
  '35690': '35.69',
  '35691': '35.69',
  '35692': '35.69',
  '35693': '35.69',
  '35694': '35.69',
  '35695': '35.69',
  '35696': '35.70',
  '35697': '35.70',
  '35698': '35.70',
  '35699': '35.70',
  '35700': '35.70',
  '35701': '35.70',
  '35702': '35.70',
  '35703': '35.70',
  '35704': '35.70',
  '35705': '35.71',
  '35706': '35.71',
  '35707': '35.71',
  '35708': '35.71',
  '35709': '35.71',
  '35710': '35.71',
  '35711': '35.71',
  '35712': '35.71',
  '35713': '35.71',
  '35714': '35.71',
  '35715': '35.72',
  '35716': '35.72',
  '35717': '35.72',
  '35718': '35.72',
  '35719': '35.72',
  '35720': '35.72',
  '35721': '35.72',
  '35722': '35.72',
  '35723': '35.72',
  '35724': '35.72',
  '35725': '35.72',
  '35726': '35.73',
  '35727': '35.73',
  '35728': '35.73',
  '35729': '35.73',
  '35730': '35.73',
  '35731': '35.73',
  '35732': '35.73',
  '35733': '35.73',
  '35734': '35.73',
  '35735': '35.74',
  '35736': '35.74',
  '35737': '35.74',
  '35738': '35.74',
  '35739': '35.74',
  '35740': '35.74',
  '35741': '35.74',
  '35742': '35.74',
  '35743': '35.74',
  '35744': '35.74',
  '35745': '35.74',
  '35746': '35.75',
  '35747': '35.75',
  '35748': '35.75',
  '35749': '35.75',
  '35750': '35.75',
  '35751': '35.75',
  '35752': '35.75',
  '35753': '35.75',
  '35754': '35.75',
  '35755': '35.76',
  '35756': '35.76',
  '35757': '35.76',
  '35758': '35.76',
  '35759': '35.76',
  '35760': '35.76',
  '35761': '35.76',
  '35762': '35.76',
  '35763': '35.76',
  '35764': '35.76',
  '35765': '35.76',
  '35766': '35.77',
  '35767': '35.77',
  '35768': '35.77',
  '35769': '35.77',
  '35770': '35.77',
  '35771': '35.77',
  '35772': '35.77',
  '35773': '35.77',
  '35774': '35.77',
  '35775': '35.78',
  '35776': '35.78',
  '35777': '35.78',
  '35778': '35.78',
  '35779': '35.78',
  '35780': '35.78',
  '35781': '35.78',
  '35782': '35.78',
  '35783': '35.78',
  '35784': '35.78',
  '35785': '35.78',
  '35786': '35.79',
  '35787': '35.79',
  '35788': '35.79',
  '35789': '35.79',
  '35790': '35.79',
  '35791': '35.79',
  '35792': '35.79',
  '35793': '35.79',
  '35794': '35.79',
  '35795': '35.79',
  '35796': '35.80',
  '35797': '35.80',
  '35798': '35.80',
  '35799': '35.80',
  '35800': '35.80',
  '35801': '35.80',
  '35802': '35.80',
  '35803': '35.80',
  '35804': '35.80',
  '35805': '35.81',
  '35806': '35.81',
  '35807': '35.81',
  '35808': '35.81',
  '35809': '35.81',
  '35810': '35.81',
  '35811': '35.81',
  '35812': '35.81',
  '35813': '35.81',
  '35814': '35.81',
  '35815': '35.81',
  '35816': '35.82',
  '35817': '35.82',
  '35818': '35.82',
  '35819': '35.82',
  '35820': '35.82',
  '35821': '35.82',
  '35822': '35.82',
  '35823': '35.82',
  '35824': '35.82',
  '35825': '35.83',
  '35826': '35.83',
  '35827': '35.83',
  '35828': '35.83',
  '35829': '35.83',
  '35830': '35.83',
  '35831': '35.83',
  '35832': '35.83',
  '35833': '35.83',
  '35834': '35.83',
  '35835': '35.83',
  '35836': '35.84',
  '35837': '35.84',
  '35838': '35.84',
  '35839': '35.84',
  '35840': '35.84',
  '35841': '35.84',
  '35842': '35.84',
  '35843': '35.84',
  '35844': '35.84',
  '35845': '35.85',
  '35846': '35.85',
  '35847': '35.85',
  '35848': '35.85',
  '35849': '35.85',
  '35850': '35.85',
  '35851': '35.85',
  '35852': '35.85',
  '35853': '35.85',
  '35854': '35.85',
  '35855': '35.85',
  '35856': '35.86',
  '35857': '35.86',
  '35858': '35.86',
  '35859': '35.86',
  '35860': '35.86',
  '35861': '35.86',
  '35862': '35.86',
  '35863': '35.86',
  '35864': '35.86',
  '35865': '35.87',
  '35866': '35.87',
  '35867': '35.87',
  '35868': '35.87',
  '35869': '35.87',
  '35870': '35.87',
  '35871': '35.87',
  '35872': '35.87',
  '35873': '35.87',
  '35874': '35.87',
  '35875': '35.88',
  '35876': '35.88',
  '35877': '35.88',
  '35878': '35.88',
  '35879': '35.88',
  '35880': '35.88',
  '35881': '35.88',
  '35882': '35.88',
  '35883': '35.88',
  '35884': '35.88',
  '35885': '35.88',
  '35886': '35.89',
  '35887': '35.89',
  '35888': '35.89',
  '35889': '35.89',
  '35890': '35.89',
  '35891': '35.89',
  '35892': '35.89',
  '35893': '35.89',
  '35894': '35.89',
  '35895': '35.90',
  '35896': '35.90',
  '35897': '35.90',
  '35898': '35.90',
  '35899': '35.90',
  '35900': '35.90',
  '35901': '35.90',
  '35902': '35.90',
  '35903': '35.90',
  '35904': '35.90',
  '35905': '35.90',
  '35906': '35.91',
  '35907': '35.91',
  '35908': '35.91',
  '35909': '35.91',
  '35910': '35.91',
  '35911': '35.91',
  '35912': '35.91',
  '35913': '35.91',
  '35914': '35.91',
  '35915': '35.92',
  '35916': '35.92',
  '35917': '35.92',
  '35918': '35.92',
  '35919': '35.92',
  '35920': '35.92',
  '35921': '35.92',
  '35922': '35.92',
  '35923': '35.92',
  '35924': '35.92',
  '35925': '35.92',
  '35926': '35.93',
  '35927': '35.93',
  '35928': '35.93',
  '35929': '35.93',
  '35930': '35.93',
  '35931': '35.93',
  '35932': '35.93',
  '35933': '35.93',
  '35934': '35.93',
  '35935': '35.94',
  '35936': '35.94',
  '35937': '35.94',
  '35938': '35.94',
  '35939': '35.94',
  '35940': '35.94',
  '35941': '35.94',
  '35942': '35.94',
  '35943': '35.94',
  '35944': '35.94',
  '35945': '35.94',
  '35946': '35.95',
  '35947': '35.95',
  '35948': '35.95',
  '35949': '35.95',
  '35950': '35.95',
  '35951': '35.95',
  '35952': '35.95',
  '35953': '35.95',
  '35954': '35.95',
  '35955': '35.96',
  '35956': '35.96',
  '35957': '35.96',
  '35958': '35.96',
  '35959': '35.96',
  '35960': '35.96',
  '35961': '35.96',
  '35962': '35.96',
  '35963': '35.96',
  '35964': '35.96',
  '35965': '35.97',
  '35966': '35.97',
  '35967': '35.97',
  '35968': '35.97',
  '35969': '35.97',
  '35970': '35.97',
  '35971': '35.97',
  '35972': '35.97',
  '35973': '35.97',
  '35974': '35.97',
  '35975': '35.97',
  '35976': '35.98',
  '35977': '35.98',
  '35978': '35.98',
  '35979': '35.98',
  '35980': '35.98',
  '35981': '35.98',
  '35982': '35.98',
  '35983': '35.98',
  '35984': '35.98',
  '35985': '35.99',
  '35986': '35.99',
  '35987': '35.99',
  '35988': '35.99',
  '35989': '35.99',
  '35990': '35.99',
  '35991': '35.99',
  '35992': '35.99',
  '35993': '35.99',
  '35994': '35.99',
  '35995': '35.99',
  '35996': '36.00',
  '35997': '36.00',
  '35998': '36.00',
  '35999': '36.00',
  '36000': '36.00',
  '36001': '36.00',
  '36002': '36.00',
  '36003': '36.00',
  '36004': '36.00',
  '36005': '36.01',
  '36006': '36.01',
  '36007': '36.01',
  '36008': '36.01',
  '36009': '36.01',
  '36010': '36.01',
  '36011': '36.01',
  '36012': '36.01',
  '36013': '36.01',
  '36014': '36.01',
  '36015': '36.01',
  '36016': '36.02',
  '36017': '36.02',
  '36018': '36.02',
  '36019': '36.02',
  '36020': '36.02',
  '36021': '36.02',
  '36022': '36.02',
  '36023': '36.02',
  '36024': '36.02',
  '36025': '36.03',
  '36026': '36.03',
  '36027': '36.03',
  '36028': '36.03',
  '36029': '36.03',
  '36030': '36.03',
  '36031': '36.03',
  '36032': '36.03',
  '36033': '36.03',
  '36034': '36.03',
  '36035': '36.03',
  '36036': '36.04',
  '36037': '36.04',
  '36038': '36.04',
  '36039': '36.04',
  '36040': '36.04',
  '36041': '36.04',
  '36042': '36.04',
  '36043': '36.04',
  '36044': '36.04',
  '36045': '36.04',
  '36046': '36.05',
  '36047': '36.05',
  '36048': '36.05',
  '36049': '36.05',
  '36050': '36.05',
  '36051': '36.05',
  '36052': '36.05',
  '36053': '36.05',
  '36054': '36.05',
  '36055': '36.06',
  '36056': '36.06',
  '36057': '36.06',
  '36058': '36.06',
  '36059': '36.06',
  '36060': '36.06',
  '36061': '36.06',
  '36062': '36.06',
  '36063': '36.06',
  '36064': '36.06',
  '36065': '36.06',
  '36066': '36.07',
  '36067': '36.07',
  '36068': '36.07',
  '36069': '36.07',
  '36070': '36.07',
  '36071': '36.07',
  '36072': '36.07',
  '36073': '36.07',
  '36074': '36.07',
  '36075': '36.08',
  '36076': '36.08',
  '36077': '36.08',
  '36078': '36.08',
  '36079': '36.08',
  '36080': '36.08',
  '36081': '36.08',
  '36082': '36.08',
  '36083': '36.08',
  '36084': '36.08',
  '36085': '36.08',
  '36086': '36.09',
  '36087': '36.09',
  '36088': '36.09',
  '36089': '36.09',
  '36090': '36.09',
  '36091': '36.09',
  '36092': '36.09',
  '36093': '36.09',
  '36094': '36.09',
  '36095': '36.10',
  '36096': '36.10',
  '36097': '36.10',
  '36098': '36.10',
  '36099': '36.10',
  '36100': '36.10',
  '36101': '36.10',
  '36102': '36.10',
  '36103': '36.10',
  '36104': '36.10',
  '36105': '36.10',
  '36106': '36.11',
  '36107': '36.11',
  '36108': '36.11',
  '36109': '36.11',
  '36110': '36.11',
  '36111': '36.11',
  '36112': '36.11',
  '36113': '36.11',
  '36114': '36.11',
  '36115': '36.12',
  '36116': '36.12',
  '36117': '36.12',
  '36118': '36.12',
  '36119': '36.12',
  '36120': '36.12',
  '36121': '36.12',
  '36122': '36.12',
  '36123': '36.12',
  '36124': '36.12',
  '36125': '36.12',
  '36126': '36.13',
  '36127': '36.13',
  '36128': '36.13',
  '36129': '36.13',
  '36130': '36.13',
  '36131': '36.13',
  '36132': '36.13',
  '36133': '36.13',
  '36134': '36.13',
  '36135': '36.13',
  '36136': '36.14',
  '36137': '36.14',
  '36138': '36.14',
  '36139': '36.14',
  '36140': '36.14',
  '36141': '36.14',
  '36142': '36.14',
  '36143': '36.14',
  '36144': '36.14',
  '36145': '36.15',
  '36146': '36.15',
  '36147': '36.15',
  '36148': '36.15',
  '36149': '36.15',
  '36150': '36.15',
  '36151': '36.15',
  '36152': '36.15',
  '36153': '36.15',
  '36154': '36.15',
  '36155': '36.15',
  '36156': '36.16',
  '36157': '36.16',
  '36158': '36.16',
  '36159': '36.16',
  '36160': '36.16',
  '36161': '36.16',
  '36162': '36.16',
  '36163': '36.16',
  '36164': '36.16',
  '36165': '36.17',
  '36166': '36.17',
  '36167': '36.17',
  '36168': '36.17',
  '36169': '36.17',
  '36170': '36.17',
  '36171': '36.17',
  '36172': '36.17',
  '36173': '36.17',
  '36174': '36.17',
  '36175': '36.17',
  '36176': '36.18',
  '36177': '36.18',
  '36178': '36.18',
  '36179': '36.18',
  '36180': '36.18',
  '36181': '36.18',
  '36182': '36.18',
  '36183': '36.18',
  '36184': '36.18',
  '36185': '36.19',
  '36186': '36.19',
  '36187': '36.19',
  '36188': '36.19',
  '36189': '36.19',
  '36190': '36.19',
  '36191': '36.19',
  '36192': '36.19',
  '36193': '36.19',
  '36194': '36.19',
  '36195': '36.19',
  '36196': '36.20',
  '36197': '36.20',
  '36198': '36.20',
  '36199': '36.20',
  '36200': '36.20',
  '36201': '36.20',
  '36202': '36.20',
  '36203': '36.20',
  '36204': '36.20',
  '36205': '36.21',
  '36206': '36.21',
  '36207': '36.21',
  '36208': '36.21',
  '36209': '36.21',
  '36210': '36.21',
  '36211': '36.21',
  '36212': '36.21',
  '36213': '36.21',
  '36214': '36.21',
  '36215': '36.22',
  '36216': '36.22',
  '36217': '36.22',
  '36218': '36.22',
  '36219': '36.22',
  '36220': '36.22',
  '36221': '36.22',
  '36222': '36.22',
  '36223': '36.22',
  '36224': '36.22',
  '36225': '36.22',
  '36226': '36.23',
  '36227': '36.23',
  '36228': '36.23',
  '36229': '36.23',
  '36230': '36.23',
  '36231': '36.23',
  '36232': '36.23',
  '36233': '36.23',
  '36234': '36.23',
  '36235': '36.24',
  '36236': '36.24',
  '36237': '36.24',
  '36238': '36.24',
  '36239': '36.24',
  '36240': '36.24',
  '36241': '36.24',
  '36242': '36.24',
  '36243': '36.24',
  '36244': '36.24',
  '36245': '36.24',
  '36246': '36.25',
  '36247': '36.25',
  '36248': '36.25',
  '36249': '36.25',
  '36250': '36.25',
  '36251': '36.25',
  '36252': '36.25',
  '36253': '36.25',
  '36254': '36.25',
  '36255': '36.26',
  '36256': '36.26',
  '36257': '36.26',
  '36258': '36.26',
  '36259': '36.26',
  '36260': '36.26',
  '36261': '36.26',
  '36262': '36.26',
  '36263': '36.26',
  '36264': '36.26',
  '36265': '36.26',
  '36266': '36.27',
  '36267': '36.27',
  '36268': '36.27',
  '36269': '36.27',
  '36270': '36.27',
  '36271': '36.27',
  '36272': '36.27',
  '36273': '36.27',
  '36274': '36.27',
  '36275': '36.28',
  '36276': '36.28',
  '36277': '36.28',
  '36278': '36.28',
  '36279': '36.28',
  '36280': '36.28',
  '36281': '36.28',
  '36282': '36.28',
  '36283': '36.28',
  '36284': '36.28',
  '36285': '36.28',
  '36286': '36.29',
  '36287': '36.29',
  '36288': '36.29',
  '36289': '36.29',
  '36290': '36.29',
  '36291': '36.29',
  '36292': '36.29',
  '36293': '36.29',
  '36294': '36.29',
  '36295': '36.29',
  '36296': '36.30',
  '36297': '36.30',
  '36298': '36.30',
  '36299': '36.30',
  '36300': '36.30',
  '36301': '36.30',
  '36302': '36.30',
  '36303': '36.30',
  '36304': '36.30',
  '36305': '36.31',
  '36306': '36.31',
  '36307': '36.31',
  '36308': '36.31',
  '36309': '36.31',
  '36310': '36.31',
  '36311': '36.31',
  '36312': '36.31',
  '36313': '36.31',
  '36314': '36.31',
  '36315': '36.31',
  '36316': '36.32',
  '36317': '36.32',
  '36318': '36.32',
  '36319': '36.32',
  '36320': '36.32',
  '36321': '36.32',
  '36322': '36.32',
  '36323': '36.32',
  '36324': '36.32',
  '36325': '36.33',
  '36326': '36.33',
  '36327': '36.33',
  '36328': '36.33',
  '36329': '36.33',
  '36330': '36.33',
  '36331': '36.33',
  '36332': '36.33',
  '36333': '36.33',
  '36334': '36.33',
  '36335': '36.33',
  '36336': '36.34',
  '36337': '36.34',
  '36338': '36.34',
  '36339': '36.34',
  '36340': '36.34',
  '36341': '36.34',
  '36342': '36.34',
  '36343': '36.34',
  '36344': '36.34',
  '36345': '36.35',
  '36346': '36.35',
  '36347': '36.35',
  '36348': '36.35',
  '36349': '36.35',
  '36350': '36.35',
  '36351': '36.35',
  '36352': '36.35',
  '36353': '36.35',
  '36354': '36.35',
  '36355': '36.35',
  '36356': '36.36',
  '36357': '36.36',
  '36358': '36.36',
  '36359': '36.36',
  '36360': '36.36',
  '36361': '36.36',
  '36362': '36.36',
  '36363': '36.36',
  '36364': '36.36',
  '36365': '36.37',
  '36366': '36.37',
  '36367': '36.37',
  '36368': '36.37',
  '36369': '36.37',
  '36370': '36.37',
  '36371': '36.37',
  '36372': '36.37',
  '36373': '36.37',
  '36374': '36.37',
  '36375': '36.38',
  '36376': '36.38',
  '36377': '36.38',
  '36378': '36.38',
  '36379': '36.38',
  '36380': '36.38',
  '36381': '36.38',
  '36382': '36.38',
  '36383': '36.38',
  '36384': '36.38',
  '36385': '36.38',
  '36386': '36.39',
  '36387': '36.39',
  '36388': '36.39',
  '36389': '36.39',
  '36390': '36.39',
  '36391': '36.39',
  '36392': '36.39',
  '36393': '36.39',
  '36394': '36.39',
  '36395': '36.40',
  '36396': '36.40',
  '36397': '36.40',
  '36398': '36.40',
  '36399': '36.40',
  '36400': '36.40',
  '36401': '36.40',
  '36402': '36.40',
  '36403': '36.40',
  '36404': '36.40',
  '36405': '36.40',
  '36406': '36.41',
  '36407': '36.41',
  '36408': '36.41',
  '36409': '36.41',
  '36410': '36.41',
  '36411': '36.41',
  '36412': '36.41',
  '36413': '36.41',
  '36414': '36.41',
  '36415': '36.42',
  '36416': '36.42',
  '36417': '36.42',
  '36418': '36.42',
  '36419': '36.42',
  '36420': '36.42',
  '36421': '36.42',
  '36422': '36.42',
  '36423': '36.42',
  '36424': '36.42',
  '36425': '36.42',
  '36426': '36.43',
  '36427': '36.43',
  '36428': '36.43',
  '36429': '36.43',
  '36430': '36.43',
  '36431': '36.43',
  '36432': '36.43',
  '36433': '36.43',
  '36434': '36.43',
  '36435': '36.44',
  '36436': '36.44',
  '36437': '36.44',
  '36438': '36.44',
  '36439': '36.44',
  '36440': '36.44',
  '36441': '36.44',
  '36442': '36.44',
  '36443': '36.44',
  '36444': '36.44',
  '36445': '36.44',
  '36446': '36.45',
  '36447': '36.45',
  '36448': '36.45',
  '36449': '36.45',
  '36450': '36.45',
  '36451': '36.45',
  '36452': '36.45',
  '36453': '36.45',
  '36454': '36.45',
  '36455': '36.46',
  '36456': '36.46',
  '36457': '36.46',
  '36458': '36.46',
  '36459': '36.46',
  '36460': '36.46',
  '36461': '36.46',
  '36462': '36.46',
  '36463': '36.46',
  '36464': '36.46',
  '36465': '36.47',
  '36466': '36.47',
  '36467': '36.47',
  '36468': '36.47',
  '36469': '36.47',
  '36470': '36.47',
  '36471': '36.47',
  '36472': '36.47',
  '36473': '36.47',
  '36474': '36.47',
  '36475': '36.47',
  '36476': '36.48',
  '36477': '36.48',
  '36478': '36.48',
  '36479': '36.48',
  '36480': '36.48',
  '36481': '36.48',
  '36482': '36.48',
  '36483': '36.48',
  '36484': '36.48',
  '36485': '36.49',
  '36486': '36.49',
  '36487': '36.49',
  '36488': '36.49',
  '36489': '36.49',
  '36490': '36.49',
  '36491': '36.49',
  '36492': '36.49',
  '36493': '36.49',
  '36494': '36.49',
  '36495': '36.49',
  '36496': '36.50',
  '36497': '36.50',
  '36498': '36.50',
  '36499': '36.50',
  '36500': '36.50',
  '36501': '36.50',
  '36502': '36.50',
  '36503': '36.50',
  '36504': '36.50',
  '36505': '36.51',
  '36506': '36.51',
  '36507': '36.51',
  '36508': '36.51',
  '36509': '36.51',
  '36510': '36.51',
  '36511': '36.51',
  '36512': '36.51',
  '36513': '36.51',
  '36514': '36.51',
  '36515': '36.51',
  '36516': '36.52',
  '36517': '36.52',
  '36518': '36.52',
  '36519': '36.52',
  '36520': '36.52',
  '36521': '36.52',
  '36522': '36.52',
  '36523': '36.52',
  '36524': '36.52',
  '36525': '36.53',
  '36526': '36.53',
  '36527': '36.53',
  '36528': '36.53',
  '36529': '36.53',
  '36530': '36.53',
  '36531': '36.53',
  '36532': '36.53',
  '36533': '36.53',
  '36534': '36.53',
  '36535': '36.53',
  '36536': '36.54',
  '36537': '36.54',
  '36538': '36.54',
  '36539': '36.54',
  '36540': '36.54',
  '36541': '36.54',
  '36542': '36.54',
  '36543': '36.54',
  '36544': '36.54',
  '36545': '36.54',
  '36546': '36.55',
  '36547': '36.55',
  '36548': '36.55',
  '36549': '36.55',
  '36550': '36.55',
  '36551': '36.55',
  '36552': '36.55',
  '36553': '36.55',
  '36554': '36.55',
  '36555': '36.56',
  '36556': '36.56',
  '36557': '36.56',
  '36558': '36.56',
  '36559': '36.56',
  '36560': '36.56',
  '36561': '36.56',
  '36562': '36.56',
  '36563': '36.56',
  '36564': '36.56',
  '36565': '36.56',
  '36566': '36.57',
  '36567': '36.57',
  '36568': '36.57',
  '36569': '36.57',
  '36570': '36.57',
  '36571': '36.57',
  '36572': '36.57',
  '36573': '36.57',
  '36574': '36.57',
  '36575': '36.58',
  '36576': '36.58',
  '36577': '36.58',
  '36578': '36.58',
  '36579': '36.58',
  '36580': '36.58',
  '36581': '36.58',
  '36582': '36.58',
  '36583': '36.58',
  '36584': '36.58',
  '36585': '36.58',
  '36586': '36.59',
  '36587': '36.59',
  '36588': '36.59',
  '36589': '36.59',
  '36590': '36.59',
  '36591': '36.59',
  '36592': '36.59',
  '36593': '36.59',
  '36594': '36.59',
  '36595': '36.60',
  '36596': '36.60',
  '36597': '36.60',
  '36598': '36.60',
  '36599': '36.60',
  '36600': '36.60',
  '36601': '36.60',
  '36602': '36.60',
  '36603': '36.60',
  '36604': '36.60',
  '36605': '36.60',
  '36606': '36.61',
  '36607': '36.61',
  '36608': '36.61',
  '36609': '36.61',
  '36610': '36.61',
  '36611': '36.61',
  '36612': '36.61',
  '36613': '36.61',
  '36614': '36.61',
  '36615': '36.62',
  '36616': '36.62',
  '36617': '36.62',
  '36618': '36.62',
  '36619': '36.62',
  '36620': '36.62',
  '36621': '36.62',
  '36622': '36.62',
  '36623': '36.62',
  '36624': '36.62',
  '36625': '36.62',
  '36626': '36.63',
  '36627': '36.63',
  '36628': '36.63',
  '36629': '36.63',
  '36630': '36.63',
  '36631': '36.63',
  '36632': '36.63',
  '36633': '36.63',
  '36634': '36.63',
  '36635': '36.63',
  '36636': '36.64',
  '36637': '36.64',
  '36638': '36.64',
  '36639': '36.64',
  '36640': '36.64',
  '36641': '36.64',
  '36642': '36.64',
  '36643': '36.64',
  '36644': '36.64',
  '36645': '36.65',
  '36646': '36.65',
  '36647': '36.65',
  '36648': '36.65',
  '36649': '36.65',
  '36650': '36.65',
  '36651': '36.65',
  '36652': '36.65',
  '36653': '36.65',
  '36654': '36.65',
  '36655': '36.65',
  '36656': '36.66',
  '36657': '36.66',
  '36658': '36.66',
  '36659': '36.66',
  '36660': '36.66',
  '36661': '36.66',
  '36662': '36.66',
  '36663': '36.66',
  '36664': '36.66',
  '36665': '36.67',
  '36666': '36.67',
  '36667': '36.67',
  '36668': '36.67',
  '36669': '36.67',
  '36670': '36.67',
  '36671': '36.67',
  '36672': '36.67',
  '36673': '36.67',
  '36674': '36.67',
  '36675': '36.67',
  '36676': '36.68',
  '36677': '36.68',
  '36678': '36.68',
  '36679': '36.68',
  '36680': '36.68',
  '36681': '36.68',
  '36682': '36.68',
  '36683': '36.68',
  '36684': '36.68',
  '36685': '36.69',
  '36686': '36.69',
  '36687': '36.69',
  '36688': '36.69',
  '36689': '36.69',
  '36690': '36.69',
  '36691': '36.69',
  '36692': '36.69',
  '36693': '36.69',
  '36694': '36.69',
  '36695': '36.69',
  '36696': '36.70',
  '36697': '36.70',
  '36698': '36.70',
  '36699': '36.70',
  '36700': '36.70',
  '36701': '36.70',
  '36702': '36.70',
  '36703': '36.70',
  '36704': '36.70',
  '36705': '36.71',
  '36706': '36.71',
  '36707': '36.71',
  '36708': '36.71',
  '36709': '36.71',
  '36710': '36.71',
  '36711': '36.71',
  '36712': '36.71',
  '36713': '36.71',
  '36714': '36.71',
  '36715': '36.72',
  '36716': '36.72',
  '36717': '36.72',
  '36718': '36.72',
  '36719': '36.72',
  '36720': '36.72',
  '36721': '36.72',
  '36722': '36.72',
  '36723': '36.72',
  '36724': '36.72',
  '36725': '36.72',
  '36726': '36.73',
  '36727': '36.73',
  '36728': '36.73',
  '36729': '36.73',
  '36730': '36.73',
  '36731': '36.73',
  '36732': '36.73',
  '36733': '36.73',
  '36734': '36.73',
  '36735': '36.74',
  '36736': '36.74',
  '36737': '36.74',
  '36738': '36.74',
  '36739': '36.74',
  '36740': '36.74',
  '36741': '36.74',
  '36742': '36.74',
  '36743': '36.74',
  '36744': '36.74',
  '36745': '36.74',
  '36746': '36.75',
  '36747': '36.75',
  '36748': '36.75',
  '36749': '36.75',
  '36750': '36.75',
  '36751': '36.75',
  '36752': '36.75',
  '36753': '36.75',
  '36754': '36.75',
  '36755': '36.76',
  '36756': '36.76',
  '36757': '36.76',
  '36758': '36.76',
  '36759': '36.76',
  '36760': '36.76',
  '36761': '36.76',
  '36762': '36.76',
  '36763': '36.76',
  '36764': '36.76',
  '36765': '36.76',
  '36766': '36.77',
  '36767': '36.77',
  '36768': '36.77',
  '36769': '36.77',
  '36770': '36.77',
  '36771': '36.77',
  '36772': '36.77',
  '36773': '36.77',
  '36774': '36.77',
  '36775': '36.78',
  '36776': '36.78',
  '36777': '36.78',
  '36778': '36.78',
  '36779': '36.78',
  '36780': '36.78',
  '36781': '36.78',
  '36782': '36.78',
  '36783': '36.78',
  '36784': '36.78',
  '36785': '36.78',
  '36786': '36.79',
  '36787': '36.79',
  '36788': '36.79',
  '36789': '36.79',
  '36790': '36.79',
  '36791': '36.79',
  '36792': '36.79',
  '36793': '36.79',
  '36794': '36.79',
  '36795': '36.79',
  '36796': '36.80',
  '36797': '36.80',
  '36798': '36.80',
  '36799': '36.80',
  '36800': '36.80',
  '36801': '36.80',
  '36802': '36.80',
  '36803': '36.80',
  '36804': '36.80',
  '36805': '36.81',
  '36806': '36.81',
  '36807': '36.81',
  '36808': '36.81',
  '36809': '36.81',
  '36810': '36.81',
  '36811': '36.81',
  '36812': '36.81',
  '36813': '36.81',
  '36814': '36.81',
  '36815': '36.81',
  '36816': '36.82',
  '36817': '36.82',
  '36818': '36.82',
  '36819': '36.82',
  '36820': '36.82',
  '36821': '36.82',
  '36822': '36.82',
  '36823': '36.82',
  '36824': '36.82',
  '36825': '36.83',
  '36826': '36.83',
  '36827': '36.83',
  '36828': '36.83',
  '36829': '36.83',
  '36830': '36.83',
  '36831': '36.83',
  '36832': '36.83',
  '36833': '36.83',
  '36834': '36.83',
  '36835': '36.83',
  '36836': '36.84',
  '36837': '36.84',
  '36838': '36.84',
  '36839': '36.84',
  '36840': '36.84',
  '36841': '36.84',
  '36842': '36.84',
  '36843': '36.84',
  '36844': '36.84',
  '36845': '36.85',
  '36846': '36.85',
  '36847': '36.85',
  '36848': '36.85',
  '36849': '36.85',
  '36850': '36.85',
  '36851': '36.85',
  '36852': '36.85',
  '36853': '36.85',
  '36854': '36.85',
  '36855': '36.85',
  '36856': '36.86',
  '36857': '36.86',
  '36858': '36.86',
  '36859': '36.86',
  '36860': '36.86',
  '36861': '36.86',
  '36862': '36.86',
  '36863': '36.86',
  '36864': '36.86',
  '36865': '36.87',
  '36866': '36.87',
  '36867': '36.87',
  '36868': '36.87',
  '36869': '36.87',
  '36870': '36.87',
  '36871': '36.87',
  '36872': '36.87',
  '36873': '36.87',
  '36874': '36.87',
  '36875': '36.88',
  '36876': '36.88',
  '36877': '36.88',
  '36878': '36.88',
  '36879': '36.88',
  '36880': '36.88',
  '36881': '36.88',
  '36882': '36.88',
  '36883': '36.88',
  '36884': '36.88',
  '36885': '36.88',
  '36886': '36.89',
  '36887': '36.89',
  '36888': '36.89',
  '36889': '36.89',
  '36890': '36.89',
  '36891': '36.89',
  '36892': '36.89',
  '36893': '36.89',
  '36894': '36.89',
  '36895': '36.90',
  '36896': '36.90',
  '36897': '36.90',
  '36898': '36.90',
  '36899': '36.90',
  '36900': '36.90',
  '36901': '36.90',
  '36902': '36.90',
  '36903': '36.90',
  '36904': '36.90',
  '36905': '36.90',
  '36906': '36.91',
  '36907': '36.91',
  '36908': '36.91',
  '36909': '36.91',
  '36910': '36.91',
  '36911': '36.91',
  '36912': '36.91',
  '36913': '36.91',
  '36914': '36.91',
  '36915': '36.92',
  '36916': '36.92',
  '36917': '36.92',
  '36918': '36.92',
  '36919': '36.92',
  '36920': '36.92',
  '36921': '36.92',
  '36922': '36.92',
  '36923': '36.92',
  '36924': '36.92',
  '36925': '36.92',
  '36926': '36.93',
  '36927': '36.93',
  '36928': '36.93',
  '36929': '36.93',
  '36930': '36.93',
  '36931': '36.93',
  '36932': '36.93',
  '36933': '36.93',
  '36934': '36.93',
  '36935': '36.94',
  '36936': '36.94',
  '36937': '36.94',
  '36938': '36.94',
  '36939': '36.94',
  '36940': '36.94',
  '36941': '36.94',
  '36942': '36.94',
  '36943': '36.94',
  '36944': '36.94',
  '36945': '36.94',
  '36946': '36.95',
  '36947': '36.95',
  '36948': '36.95',
  '36949': '36.95',
  '36950': '36.95',
  '36951': '36.95',
  '36952': '36.95',
  '36953': '36.95',
  '36954': '36.95',
  '36955': '36.96',
  '36956': '36.96',
  '36957': '36.96',
  '36958': '36.96',
  '36959': '36.96',
  '36960': '36.96',
  '36961': '36.96',
  '36962': '36.96',
  '36963': '36.96',
  '36964': '36.96',
  '36965': '36.97',
  '36966': '36.97',
  '36967': '36.97',
  '36968': '36.97',
  '36969': '36.97',
  '36970': '36.97',
  '36971': '36.97',
  '36972': '36.97',
  '36973': '36.97',
  '36974': '36.97',
  '36975': '36.97',
  '36976': '36.98',
  '36977': '36.98',
  '36978': '36.98',
  '36979': '36.98',
  '36980': '36.98',
  '36981': '36.98',
  '36982': '36.98',
  '36983': '36.98',
  '36984': '36.98',
  '36985': '36.99',
  '36986': '36.99',
  '36987': '36.99',
  '36988': '36.99',
  '36989': '36.99',
  '36990': '36.99',
  '36991': '36.99',
  '36992': '36.99',
  '36993': '36.99',
  '36994': '36.99',
  '36995': '36.99',
  '36996': '37.00',
  '36997': '37.00',
  '36998': '37.00',
  '36999': '37.00',
  '37000': '37.00',
  '37001': '37.00',
  '37002': '37.00',
  '37003': '37.00',
  '37004': '37.00',
  '37005': '37.01',
  '37006': '37.01',
  '37007': '37.01',
  '37008': '37.01',
  '37009': '37.01',
  '37010': '37.01',
  '37011': '37.01',
  '37012': '37.01',
  '37013': '37.01',
  '37014': '37.01',
  '37015': '37.01',
  '37016': '37.02',
  '37017': '37.02',
  '37018': '37.02',
  '37019': '37.02',
  '37020': '37.02',
  '37021': '37.02',
  '37022': '37.02',
  '37023': '37.02',
  '37024': '37.02',
  '37025': '37.03',
  '37026': '37.03',
  '37027': '37.03',
  '37028': '37.03',
  '37029': '37.03',
  '37030': '37.03',
  '37031': '37.03',
  '37032': '37.03',
  '37033': '37.03',
  '37034': '37.03',
  '37035': '37.03',
  '37036': '37.04',
  '37037': '37.04',
  '37038': '37.04',
  '37039': '37.04',
  '37040': '37.04',
  '37041': '37.04',
  '37042': '37.04',
  '37043': '37.04',
  '37044': '37.04',
  '37045': '37.04',
  '37046': '37.05',
  '37047': '37.05',
  '37048': '37.05',
  '37049': '37.05',
  '37050': '37.05',
  '37051': '37.05',
  '37052': '37.05',
  '37053': '37.05',
  '37054': '37.05',
  '37055': '37.06',
  '37056': '37.06',
  '37057': '37.06',
  '37058': '37.06',
  '37059': '37.06',
  '37060': '37.06',
  '37061': '37.06',
  '37062': '37.06',
  '37063': '37.06',
  '37064': '37.06',
  '37065': '37.06',
  '37066': '37.07',
  '37067': '37.07',
  '37068': '37.07',
  '37069': '37.07',
  '37070': '37.07',
  '37071': '37.07',
  '37072': '37.07',
  '37073': '37.07',
  '37074': '37.07',
  '37075': '37.08',
  '37076': '37.08',
  '37077': '37.08',
  '37078': '37.08',
  '37079': '37.08',
  '37080': '37.08',
  '37081': '37.08',
  '37082': '37.08',
  '37083': '37.08',
  '37084': '37.08',
  '37085': '37.08',
  '37086': '37.09',
  '37087': '37.09',
  '37088': '37.09',
  '37089': '37.09',
  '37090': '37.09',
  '37091': '37.09',
  '37092': '37.09',
  '37093': '37.09',
  '37094': '37.09',
  '37095': '37.10',
  '37096': '37.10',
  '37097': '37.10',
  '37098': '37.10',
  '37099': '37.10',
  '37100': '37.10',
  '37101': '37.10',
  '37102': '37.10',
  '37103': '37.10',
  '37104': '37.10',
  '37105': '37.10',
  '37106': '37.11',
  '37107': '37.11',
  '37108': '37.11',
  '37109': '37.11',
  '37110': '37.11',
  '37111': '37.11',
  '37112': '37.11',
  '37113': '37.11',
  '37114': '37.11',
  '37115': '37.12',
  '37116': '37.12',
  '37117': '37.12',
  '37118': '37.12',
  '37119': '37.12',
  '37120': '37.12',
  '37121': '37.12',
  '37122': '37.12',
  '37123': '37.12',
  '37124': '37.12',
  '37125': '37.12',
  '37126': '37.13',
  '37127': '37.13',
  '37128': '37.13',
  '37129': '37.13',
  '37130': '37.13',
  '37131': '37.13',
  '37132': '37.13',
  '37133': '37.13',
  '37134': '37.13',
  '37135': '37.13',
  '37136': '37.14',
  '37137': '37.14',
  '37138': '37.14',
  '37139': '37.14',
  '37140': '37.14',
  '37141': '37.14',
  '37142': '37.14',
  '37143': '37.14',
  '37144': '37.14',
  '37145': '37.15',
  '37146': '37.15',
  '37147': '37.15',
  '37148': '37.15',
  '37149': '37.15',
  '37150': '37.15',
  '37151': '37.15',
  '37152': '37.15',
  '37153': '37.15',
  '37154': '37.15',
  '37155': '37.15',
  '37156': '37.16',
  '37157': '37.16',
  '37158': '37.16',
  '37159': '37.16',
  '37160': '37.16',
  '37161': '37.16',
  '37162': '37.16',
  '37163': '37.16',
  '37164': '37.16',
  '37165': '37.17',
  '37166': '37.17',
  '37167': '37.17',
  '37168': '37.17',
  '37169': '37.17',
  '37170': '37.17',
  '37171': '37.17',
  '37172': '37.17',
  '37173': '37.17',
  '37174': '37.17',
  '37175': '37.17',
  '37176': '37.18',
  '37177': '37.18',
  '37178': '37.18',
  '37179': '37.18',
  '37180': '37.18',
  '37181': '37.18',
  '37182': '37.18',
  '37183': '37.18',
  '37184': '37.18',
  '37185': '37.19',
  '37186': '37.19',
  '37187': '37.19',
  '37188': '37.19',
  '37189': '37.19',
  '37190': '37.19',
  '37191': '37.19',
  '37192': '37.19',
  '37193': '37.19',
  '37194': '37.19',
  '37195': '37.19',
  '37196': '37.20',
  '37197': '37.20',
  '37198': '37.20',
  '37199': '37.20',
  '37200': '37.20',
  '37201': '37.20',
  '37202': '37.20',
  '37203': '37.20',
  '37204': '37.20',
  '37205': '37.21',
  '37206': '37.21',
  '37207': '37.21',
  '37208': '37.21',
  '37209': '37.21',
  '37210': '37.21',
  '37211': '37.21',
  '37212': '37.21',
  '37213': '37.21',
  '37214': '37.21',
  '37215': '37.22',
  '37216': '37.22',
  '37217': '37.22',
  '37218': '37.22',
  '37219': '37.22',
  '37220': '37.22',
  '37221': '37.22',
  '37222': '37.22',
  '37223': '37.22',
  '37224': '37.22',
  '37225': '37.22',
  '37226': '37.23',
  '37227': '37.23',
  '37228': '37.23',
  '37229': '37.23',
  '37230': '37.23',
  '37231': '37.23',
  '37232': '37.23',
  '37233': '37.23',
  '37234': '37.23',
  '37235': '37.24',
  '37236': '37.24',
  '37237': '37.24',
  '37238': '37.24',
  '37239': '37.24',
  '37240': '37.24',
  '37241': '37.24',
  '37242': '37.24',
  '37243': '37.24',
  '37244': '37.24',
  '37245': '37.24',
  '37246': '37.25',
  '37247': '37.25',
  '37248': '37.25',
  '37249': '37.25',
  '37250': '37.25',
  '37251': '37.25',
  '37252': '37.25',
  '37253': '37.25',
  '37254': '37.25',
  '37255': '37.26',
  '37256': '37.26',
  '37257': '37.26',
  '37258': '37.26',
  '37259': '37.26',
  '37260': '37.26',
  '37261': '37.26',
  '37262': '37.26',
  '37263': '37.26',
  '37264': '37.26',
  '37265': '37.26',
  '37266': '37.27',
  '37267': '37.27',
  '37268': '37.27',
  '37269': '37.27',
  '37270': '37.27',
  '37271': '37.27',
  '37272': '37.27',
  '37273': '37.27',
  '37274': '37.27',
  '37275': '37.28',
  '37276': '37.28',
  '37277': '37.28',
  '37278': '37.28',
  '37279': '37.28',
  '37280': '37.28',
  '37281': '37.28',
  '37282': '37.28',
  '37283': '37.28',
  '37284': '37.28',
  '37285': '37.28',
  '37286': '37.29',
  '37287': '37.29',
  '37288': '37.29',
  '37289': '37.29',
  '37290': '37.29',
  '37291': '37.29',
  '37292': '37.29',
  '37293': '37.29',
  '37294': '37.29',
  '37295': '37.29',
  '37296': '37.30',
  '37297': '37.30',
  '37298': '37.30',
  '37299': '37.30',
  '37300': '37.30',
  '37301': '37.30',
  '37302': '37.30',
  '37303': '37.30',
  '37304': '37.30',
  '37305': '37.31',
  '37306': '37.31',
  '37307': '37.31',
  '37308': '37.31',
  '37309': '37.31',
  '37310': '37.31',
  '37311': '37.31',
  '37312': '37.31',
  '37313': '37.31',
  '37314': '37.31',
  '37315': '37.31',
  '37316': '37.32',
  '37317': '37.32',
  '37318': '37.32',
  '37319': '37.32',
  '37320': '37.32',
  '37321': '37.32',
  '37322': '37.32',
  '37323': '37.32',
  '37324': '37.32',
  '37325': '37.33',
  '37326': '37.33',
  '37327': '37.33',
  '37328': '37.33',
  '37329': '37.33',
  '37330': '37.33',
  '37331': '37.33',
  '37332': '37.33',
  '37333': '37.33',
  '37334': '37.33',
  '37335': '37.33',
  '37336': '37.34',
  '37337': '37.34',
  '37338': '37.34',
  '37339': '37.34',
  '37340': '37.34',
  '37341': '37.34',
  '37342': '37.34',
  '37343': '37.34',
  '37344': '37.34',
  '37345': '37.35',
  '37346': '37.35',
  '37347': '37.35',
  '37348': '37.35',
  '37349': '37.35',
  '37350': '37.35',
  '37351': '37.35',
  '37352': '37.35',
  '37353': '37.35',
  '37354': '37.35',
  '37355': '37.35',
  '37356': '37.36',
  '37357': '37.36',
  '37358': '37.36',
  '37359': '37.36',
  '37360': '37.36',
  '37361': '37.36',
  '37362': '37.36',
  '37363': '37.36',
  '37364': '37.36',
  '37365': '37.37',
  '37366': '37.37',
  '37367': '37.37',
  '37368': '37.37',
  '37369': '37.37',
  '37370': '37.37',
  '37371': '37.37',
  '37372': '37.37',
  '37373': '37.37',
  '37374': '37.37',
  '37375': '37.38',
  '37376': '37.38',
  '37377': '37.38',
  '37378': '37.38',
  '37379': '37.38',
  '37380': '37.38',
  '37381': '37.38',
  '37382': '37.38',
  '37383': '37.38',
  '37384': '37.38',
  '37385': '37.38',
  '37386': '37.39',
  '37387': '37.39',
  '37388': '37.39',
  '37389': '37.39',
  '37390': '37.39',
  '37391': '37.39',
  '37392': '37.39',
  '37393': '37.39',
  '37394': '37.39',
  '37395': '37.40',
  '37396': '37.40',
  '37397': '37.40',
  '37398': '37.40',
  '37399': '37.40',
  '37400': '37.40',
  '37401': '37.40',
  '37402': '37.40',
  '37403': '37.40',
  '37404': '37.40',
  '37405': '37.40',
  '37406': '37.41',
  '37407': '37.41',
  '37408': '37.41',
  '37409': '37.41',
  '37410': '37.41',
  '37411': '37.41',
  '37412': '37.41',
  '37413': '37.41',
  '37414': '37.41',
  '37415': '37.42',
  '37416': '37.42',
  '37417': '37.42',
  '37418': '37.42',
  '37419': '37.42',
  '37420': '37.42',
  '37421': '37.42',
  '37422': '37.42',
  '37423': '37.42',
  '37424': '37.42',
  '37425': '37.42',
  '37426': '37.43',
  '37427': '37.43',
  '37428': '37.43',
  '37429': '37.43',
  '37430': '37.43',
  '37431': '37.43',
  '37432': '37.43',
  '37433': '37.43',
  '37434': '37.43',
  '37435': '37.44',
  '37436': '37.44',
  '37437': '37.44',
  '37438': '37.44',
  '37439': '37.44',
  '37440': '37.44',
  '37441': '37.44',
  '37442': '37.44',
  '37443': '37.44',
  '37444': '37.44',
  '37445': '37.44',
  '37446': '37.45',
  '37447': '37.45',
  '37448': '37.45',
  '37449': '37.45',
  '37450': '37.45',
  '37451': '37.45',
  '37452': '37.45',
  '37453': '37.45',
  '37454': '37.45',
  '37455': '37.46',
  '37456': '37.46',
  '37457': '37.46',
  '37458': '37.46',
  '37459': '37.46',
  '37460': '37.46',
  '37461': '37.46',
  '37462': '37.46',
  '37463': '37.46',
  '37464': '37.46',
  '37465': '37.47',
  '37466': '37.47',
  '37467': '37.47',
  '37468': '37.47',
  '37469': '37.47',
  '37470': '37.47',
  '37471': '37.47',
  '37472': '37.47',
  '37473': '37.47',
  '37474': '37.47',
  '37475': '37.47',
  '37476': '37.48',
  '37477': '37.48',
  '37478': '37.48',
  '37479': '37.48',
  '37480': '37.48',
  '37481': '37.48',
  '37482': '37.48',
  '37483': '37.48',
  '37484': '37.48',
  '37485': '37.49',
  '37486': '37.49',
  '37487': '37.49',
  '37488': '37.49',
  '37489': '37.49',
  '37490': '37.49',
  '37491': '37.49',
  '37492': '37.49',
  '37493': '37.49',
  '37494': '37.49',
  '37495': '37.49',
  '37496': '37.50',
  '37497': '37.50',
  '37498': '37.50',
  '37499': '37.50',
  '37500': '37.50',
  '37501': '37.50',
  '37502': '37.50',
  '37503': '37.50',
  '37504': '37.50',
  '37505': '37.51',
  '37506': '37.51',
  '37507': '37.51',
  '37508': '37.51',
  '37509': '37.51',
  '37510': '37.51',
  '37511': '37.51',
  '37512': '37.51',
  '37513': '37.51',
  '37514': '37.51',
  '37515': '37.51',
  '37516': '37.52',
  '37517': '37.52',
  '37518': '37.52',
  '37519': '37.52',
  '37520': '37.52',
  '37521': '37.52',
  '37522': '37.52',
  '37523': '37.52',
  '37524': '37.52',
  '37525': '37.53',
  '37526': '37.53',
  '37527': '37.53',
  '37528': '37.53',
  '37529': '37.53',
  '37530': '37.53',
  '37531': '37.53',
  '37532': '37.53',
  '37533': '37.53',
  '37534': '37.53',
  '37535': '37.53',
  '37536': '37.54',
  '37537': '37.54',
  '37538': '37.54',
  '37539': '37.54',
  '37540': '37.54',
  '37541': '37.54',
  '37542': '37.54',
  '37543': '37.54',
  '37544': '37.54',
  '37545': '37.54',
  '37546': '37.55',
  '37547': '37.55',
  '37548': '37.55',
  '37549': '37.55',
  '37550': '37.55',
  '37551': '37.55',
  '37552': '37.55',
  '37553': '37.55',
  '37554': '37.55',
  '37555': '37.56',
  '37556': '37.56',
  '37557': '37.56',
  '37558': '37.56',
  '37559': '37.56',
  '37560': '37.56',
  '37561': '37.56',
  '37562': '37.56',
  '37563': '37.56',
  '37564': '37.56',
  '37565': '37.56',
  '37566': '37.57',
  '37567': '37.57',
  '37568': '37.57',
  '37569': '37.57',
  '37570': '37.57',
  '37571': '37.57',
  '37572': '37.57',
  '37573': '37.57',
  '37574': '37.57',
  '37575': '37.58',
  '37576': '37.58',
  '37577': '37.58',
  '37578': '37.58',
  '37579': '37.58',
  '37580': '37.58',
  '37581': '37.58',
  '37582': '37.58',
  '37583': '37.58',
  '37584': '37.58',
  '37585': '37.58',
  '37586': '37.59',
  '37587': '37.59',
  '37588': '37.59',
  '37589': '37.59',
  '37590': '37.59',
  '37591': '37.59',
  '37592': '37.59',
  '37593': '37.59',
  '37594': '37.59',
  '37595': '37.60',
  '37596': '37.60',
  '37597': '37.60',
  '37598': '37.60',
  '37599': '37.60',
  '37600': '37.60',
  '37601': '37.60',
  '37602': '37.60',
  '37603': '37.60',
  '37604': '37.60',
  '37605': '37.60',
  '37606': '37.61',
  '37607': '37.61',
  '37608': '37.61',
  '37609': '37.61',
  '37610': '37.61',
  '37611': '37.61',
  '37612': '37.61',
  '37613': '37.61',
  '37614': '37.61',
  '37615': '37.62',
  '37616': '37.62',
  '37617': '37.62',
  '37618': '37.62',
  '37619': '37.62',
  '37620': '37.62',
  '37621': '37.62',
  '37622': '37.62',
  '37623': '37.62',
  '37624': '37.62',
  '37625': '37.62',
  '37626': '37.63',
  '37627': '37.63',
  '37628': '37.63',
  '37629': '37.63',
  '37630': '37.63',
  '37631': '37.63',
  '37632': '37.63',
  '37633': '37.63',
  '37634': '37.63',
  '37635': '37.63',
  '37636': '37.64',
  '37637': '37.64',
  '37638': '37.64',
  '37639': '37.64',
  '37640': '37.64',
  '37641': '37.64',
  '37642': '37.64',
  '37643': '37.64',
  '37644': '37.64',
  '37645': '37.65',
  '37646': '37.65',
  '37647': '37.65',
  '37648': '37.65',
  '37649': '37.65',
  '37650': '37.65',
  '37651': '37.65',
  '37652': '37.65',
  '37653': '37.65',
  '37654': '37.65',
  '37655': '37.65',
  '37656': '37.66',
  '37657': '37.66',
  '37658': '37.66',
  '37659': '37.66',
  '37660': '37.66',
  '37661': '37.66',
  '37662': '37.66',
  '37663': '37.66',
  '37664': '37.66',
  '37665': '37.67',
  '37666': '37.67',
  '37667': '37.67',
  '37668': '37.67',
  '37669': '37.67',
  '37670': '37.67',
  '37671': '37.67',
  '37672': '37.67',
  '37673': '37.67',
  '37674': '37.67',
  '37675': '37.67',
  '37676': '37.68',
  '37677': '37.68',
  '37678': '37.68',
  '37679': '37.68',
  '37680': '37.68',
  '37681': '37.68',
  '37682': '37.68',
  '37683': '37.68',
  '37684': '37.68',
  '37685': '37.69',
  '37686': '37.69',
  '37687': '37.69',
  '37688': '37.69',
  '37689': '37.69',
  '37690': '37.69',
  '37691': '37.69',
  '37692': '37.69',
  '37693': '37.69',
  '37694': '37.69',
  '37695': '37.69',
  '37696': '37.70',
  '37697': '37.70',
  '37698': '37.70',
  '37699': '37.70',
  '37700': '37.70',
  '37701': '37.70',
  '37702': '37.70',
  '37703': '37.70',
  '37704': '37.70',
  '37705': '37.71',
  '37706': '37.71',
  '37707': '37.71',
  '37708': '37.71',
  '37709': '37.71',
  '37710': '37.71',
  '37711': '37.71',
  '37712': '37.71',
  '37713': '37.71',
  '37714': '37.71',
  '37715': '37.72',
  '37716': '37.72',
  '37717': '37.72',
  '37718': '37.72',
  '37719': '37.72',
  '37720': '37.72',
  '37721': '37.72',
  '37722': '37.72',
  '37723': '37.72',
  '37724': '37.72',
  '37725': '37.72',
  '37726': '37.73',
  '37727': '37.73',
  '37728': '37.73',
  '37729': '37.73',
  '37730': '37.73',
  '37731': '37.73',
  '37732': '37.73',
  '37733': '37.73',
  '37734': '37.73',
  '37735': '37.74',
  '37736': '37.74',
  '37737': '37.74',
  '37738': '37.74',
  '37739': '37.74',
  '37740': '37.74',
  '37741': '37.74',
  '37742': '37.74',
  '37743': '37.74',
  '37744': '37.74',
  '37745': '37.74',
  '37746': '37.75',
  '37747': '37.75',
  '37748': '37.75',
  '37749': '37.75',
  '37750': '37.75',
  '37751': '37.75',
  '37752': '37.75',
  '37753': '37.75',
  '37754': '37.75',
  '37755': '37.76',
  '37756': '37.76',
  '37757': '37.76',
  '37758': '37.76',
  '37759': '37.76',
  '37760': '37.76',
  '37761': '37.76',
  '37762': '37.76',
  '37763': '37.76',
  '37764': '37.76',
  '37765': '37.76',
  '37766': '37.77',
  '37767': '37.77',
  '37768': '37.77',
  '37769': '37.77',
  '37770': '37.77',
  '37771': '37.77',
  '37772': '37.77',
  '37773': '37.77',
  '37774': '37.77',
  '37775': '37.78',
  '37776': '37.78',
  '37777': '37.78',
  '37778': '37.78',
  '37779': '37.78',
  '37780': '37.78',
  '37781': '37.78',
  '37782': '37.78',
  '37783': '37.78',
  '37784': '37.78',
  '37785': '37.78',
  '37786': '37.79',
  '37787': '37.79',
  '37788': '37.79',
  '37789': '37.79',
  '37790': '37.79',
  '37791': '37.79',
  '37792': '37.79',
  '37793': '37.79',
  '37794': '37.79',
  '37795': '37.79',
  '37796': '37.80',
  '37797': '37.80',
  '37798': '37.80',
  '37799': '37.80',
  '37800': '37.80',
  '37801': '37.80',
  '37802': '37.80',
  '37803': '37.80',
  '37804': '37.80',
  '37805': '37.81',
  '37806': '37.81',
  '37807': '37.81',
  '37808': '37.81',
  '37809': '37.81',
  '37810': '37.81',
  '37811': '37.81',
  '37812': '37.81',
  '37813': '37.81',
  '37814': '37.81',
  '37815': '37.81',
  '37816': '37.82',
  '37817': '37.82',
  '37818': '37.82',
  '37819': '37.82',
  '37820': '37.82',
  '37821': '37.82',
  '37822': '37.82',
  '37823': '37.82',
  '37824': '37.82',
  '37825': '37.83',
  '37826': '37.83',
  '37827': '37.83',
  '37828': '37.83',
  '37829': '37.83',
  '37830': '37.83',
  '37831': '37.83',
  '37832': '37.83',
  '37833': '37.83',
  '37834': '37.83',
  '37835': '37.83',
  '37836': '37.84',
  '37837': '37.84',
  '37838': '37.84',
  '37839': '37.84',
  '37840': '37.84',
  '37841': '37.84',
  '37842': '37.84',
  '37843': '37.84',
  '37844': '37.84',
  '37845': '37.85',
  '37846': '37.85',
  '37847': '37.85',
  '37848': '37.85',
  '37849': '37.85',
  '37850': '37.85',
  '37851': '37.85',
  '37852': '37.85',
  '37853': '37.85',
  '37854': '37.85',
  '37855': '37.85',
  '37856': '37.86',
  '37857': '37.86',
  '37858': '37.86',
  '37859': '37.86',
  '37860': '37.86',
  '37861': '37.86',
  '37862': '37.86',
  '37863': '37.86',
  '37864': '37.86',
  '37865': '37.87',
  '37866': '37.87',
  '37867': '37.87',
  '37868': '37.87',
  '37869': '37.87',
  '37870': '37.87',
  '37871': '37.87',
  '37872': '37.87',
  '37873': '37.87',
  '37874': '37.87',
  '37875': '37.88',
  '37876': '37.88',
  '37877': '37.88',
  '37878': '37.88',
  '37879': '37.88',
  '37880': '37.88',
  '37881': '37.88',
  '37882': '37.88',
  '37883': '37.88',
  '37884': '37.88',
  '37885': '37.88',
  '37886': '37.89',
  '37887': '37.89',
  '37888': '37.89',
  '37889': '37.89',
  '37890': '37.89',
  '37891': '37.89',
  '37892': '37.89',
  '37893': '37.89',
  '37894': '37.89',
  '37895': '37.90',
  '37896': '37.90',
  '37897': '37.90',
  '37898': '37.90',
  '37899': '37.90',
  '37900': '37.90',
  '37901': '37.90',
  '37902': '37.90',
  '37903': '37.90',
  '37904': '37.90',
  '37905': '37.90',
  '37906': '37.91',
  '37907': '37.91',
  '37908': '37.91',
  '37909': '37.91',
  '37910': '37.91',
  '37911': '37.91',
  '37912': '37.91',
  '37913': '37.91',
  '37914': '37.91',
  '37915': '37.92',
  '37916': '37.92',
  '37917': '37.92',
  '37918': '37.92',
  '37919': '37.92',
  '37920': '37.92',
  '37921': '37.92',
  '37922': '37.92',
  '37923': '37.92',
  '37924': '37.92',
  '37925': '37.92',
  '37926': '37.93',
  '37927': '37.93',
  '37928': '37.93',
  '37929': '37.93',
  '37930': '37.93',
  '37931': '37.93',
  '37932': '37.93',
  '37933': '37.93',
  '37934': '37.93',
  '37935': '37.94',
  '37936': '37.94',
  '37937': '37.94',
  '37938': '37.94',
  '37939': '37.94',
  '37940': '37.94',
  '37941': '37.94',
  '37942': '37.94',
  '37943': '37.94',
  '37944': '37.94',
  '37945': '37.94',
  '37946': '37.95',
  '37947': '37.95',
  '37948': '37.95',
  '37949': '37.95',
  '37950': '37.95',
  '37951': '37.95',
  '37952': '37.95',
  '37953': '37.95',
  '37954': '37.95',
  '37955': '37.96',
  '37956': '37.96',
  '37957': '37.96',
  '37958': '37.96',
  '37959': '37.96',
  '37960': '37.96',
  '37961': '37.96',
  '37962': '37.96',
  '37963': '37.96',
  '37964': '37.96',
  '37965': '37.97',
  '37966': '37.97',
  '37967': '37.97',
  '37968': '37.97',
  '37969': '37.97',
  '37970': '37.97',
  '37971': '37.97',
  '37972': '37.97',
  '37973': '37.97',
  '37974': '37.97',
  '37975': '37.97',
  '37976': '37.98',
  '37977': '37.98',
  '37978': '37.98',
  '37979': '37.98',
  '37980': '37.98',
  '37981': '37.98',
  '37982': '37.98',
  '37983': '37.98',
  '37984': '37.98',
  '37985': '37.99',
  '37986': '37.99',
  '37987': '37.99',
  '37988': '37.99',
  '37989': '37.99',
  '37990': '37.99',
  '37991': '37.99',
  '37992': '37.99',
  '37993': '37.99',
  '37994': '37.99',
  '37995': '37.99',
  '37996': '38.00',
  '37997': '38.00',
  '37998': '38.00',
  '37999': '38.00',
  '38000': '38.00',
  '38001': '38.00',
  '38002': '38.00',
  '38003': '38.00',
  '38004': '38.00',
  '38005': '38.01',
  '38006': '38.01',
  '38007': '38.01',
  '38008': '38.01',
  '38009': '38.01',
  '38010': '38.01',
  '38011': '38.01',
  '38012': '38.01',
  '38013': '38.01',
  '38014': '38.01',
  '38015': '38.01',
  '38016': '38.02',
  '38017': '38.02',
  '38018': '38.02',
  '38019': '38.02',
  '38020': '38.02',
  '38021': '38.02',
  '38022': '38.02',
  '38023': '38.02',
  '38024': '38.02',
  '38025': '38.03',
  '38026': '38.03',
  '38027': '38.03',
  '38028': '38.03',
  '38029': '38.03',
  '38030': '38.03',
  '38031': '38.03',
  '38032': '38.03',
  '38033': '38.03',
  '38034': '38.03',
  '38035': '38.03',
  '38036': '38.04',
  '38037': '38.04',
  '38038': '38.04',
  '38039': '38.04',
  '38040': '38.04',
  '38041': '38.04',
  '38042': '38.04',
  '38043': '38.04',
  '38044': '38.04',
  '38045': '38.04',
  '38046': '38.05',
  '38047': '38.05',
  '38048': '38.05',
  '38049': '38.05',
  '38050': '38.05',
  '38051': '38.05',
  '38052': '38.05',
  '38053': '38.05',
  '38054': '38.05',
  '38055': '38.06',
  '38056': '38.06',
  '38057': '38.06',
  '38058': '38.06',
  '38059': '38.06',
  '38060': '38.06',
  '38061': '38.06',
  '38062': '38.06',
  '38063': '38.06',
  '38064': '38.06',
  '38065': '38.06',
  '38066': '38.07',
  '38067': '38.07',
  '38068': '38.07',
  '38069': '38.07',
  '38070': '38.07',
  '38071': '38.07',
  '38072': '38.07',
  '38073': '38.07',
  '38074': '38.07',
  '38075': '38.08',
  '38076': '38.08',
  '38077': '38.08',
  '38078': '38.08',
  '38079': '38.08',
  '38080': '38.08',
  '38081': '38.08',
  '38082': '38.08',
  '38083': '38.08',
  '38084': '38.08',
  '38085': '38.08',
  '38086': '38.09',
  '38087': '38.09',
  '38088': '38.09',
  '38089': '38.09',
  '38090': '38.09',
  '38091': '38.09',
  '38092': '38.09',
  '38093': '38.09',
  '38094': '38.09',
  '38095': '38.10',
  '38096': '38.10',
  '38097': '38.10',
  '38098': '38.10',
  '38099': '38.10',
  '38100': '38.10',
  '38101': '38.10',
  '38102': '38.10',
  '38103': '38.10',
  '38104': '38.10',
  '38105': '38.10',
  '38106': '38.11',
  '38107': '38.11',
  '38108': '38.11',
  '38109': '38.11',
  '38110': '38.11',
  '38111': '38.11',
  '38112': '38.11',
  '38113': '38.11',
  '38114': '38.11',
  '38115': '38.12',
  '38116': '38.12',
  '38117': '38.12',
  '38118': '38.12',
  '38119': '38.12',
  '38120': '38.12',
  '38121': '38.12',
  '38122': '38.12',
  '38123': '38.12',
  '38124': '38.12',
  '38125': '38.12',
  '38126': '38.13',
  '38127': '38.13',
  '38128': '38.13',
  '38129': '38.13',
  '38130': '38.13',
  '38131': '38.13',
  '38132': '38.13',
  '38133': '38.13',
  '38134': '38.13',
  '38135': '38.13',
  '38136': '38.14',
  '38137': '38.14',
  '38138': '38.14',
  '38139': '38.14',
  '38140': '38.14',
  '38141': '38.14',
  '38142': '38.14',
  '38143': '38.14',
  '38144': '38.14',
  '38145': '38.15',
  '38146': '38.15',
  '38147': '38.15',
  '38148': '38.15',
  '38149': '38.15',
  '38150': '38.15',
  '38151': '38.15',
  '38152': '38.15',
  '38153': '38.15',
  '38154': '38.15',
  '38155': '38.15',
  '38156': '38.16',
  '38157': '38.16',
  '38158': '38.16',
  '38159': '38.16',
  '38160': '38.16',
  '38161': '38.16',
  '38162': '38.16',
  '38163': '38.16',
  '38164': '38.16',
  '38165': '38.17',
  '38166': '38.17',
  '38167': '38.17',
  '38168': '38.17',
  '38169': '38.17',
  '38170': '38.17',
  '38171': '38.17',
  '38172': '38.17',
  '38173': '38.17',
  '38174': '38.17',
  '38175': '38.17',
  '38176': '38.18',
  '38177': '38.18',
  '38178': '38.18',
  '38179': '38.18',
  '38180': '38.18',
  '38181': '38.18',
  '38182': '38.18',
  '38183': '38.18',
  '38184': '38.18',
  '38185': '38.19',
  '38186': '38.19',
  '38187': '38.19',
  '38188': '38.19',
  '38189': '38.19',
  '38190': '38.19',
  '38191': '38.19',
  '38192': '38.19',
  '38193': '38.19',
  '38194': '38.19',
  '38195': '38.19',
  '38196': '38.20',
  '38197': '38.20',
  '38198': '38.20',
  '38199': '38.20',
  '38200': '38.20',
  '38201': '38.20',
  '38202': '38.20',
  '38203': '38.20',
  '38204': '38.20',
  '38205': '38.21',
  '38206': '38.21',
  '38207': '38.21',
  '38208': '38.21',
  '38209': '38.21',
  '38210': '38.21',
  '38211': '38.21',
  '38212': '38.21',
  '38213': '38.21',
  '38214': '38.21',
  '38215': '38.22',
  '38216': '38.22',
  '38217': '38.22',
  '38218': '38.22',
  '38219': '38.22',
  '38220': '38.22',
  '38221': '38.22',
  '38222': '38.22',
  '38223': '38.22',
  '38224': '38.22',
  '38225': '38.22',
  '38226': '38.23',
  '38227': '38.23',
  '38228': '38.23',
  '38229': '38.23',
  '38230': '38.23',
  '38231': '38.23',
  '38232': '38.23',
  '38233': '38.23',
  '38234': '38.23',
  '38235': '38.24',
  '38236': '38.24',
  '38237': '38.24',
  '38238': '38.24',
  '38239': '38.24',
  '38240': '38.24',
  '38241': '38.24',
  '38242': '38.24',
  '38243': '38.24',
  '38244': '38.24',
  '38245': '38.24',
  '38246': '38.25',
  '38247': '38.25',
  '38248': '38.25',
  '38249': '38.25',
  '38250': '38.25',
  '38251': '38.25',
  '38252': '38.25',
  '38253': '38.25',
  '38254': '38.25',
  '38255': '38.26',
  '38256': '38.26',
  '38257': '38.26',
  '38258': '38.26',
  '38259': '38.26',
  '38260': '38.26',
  '38261': '38.26',
  '38262': '38.26',
  '38263': '38.26',
  '38264': '38.26',
  '38265': '38.26',
  '38266': '38.27',
  '38267': '38.27',
  '38268': '38.27',
  '38269': '38.27',
  '38270': '38.27',
  '38271': '38.27',
  '38272': '38.27',
  '38273': '38.27',
  '38274': '38.27',
  '38275': '38.28',
  '38276': '38.28',
  '38277': '38.28',
  '38278': '38.28',
  '38279': '38.28',
  '38280': '38.28',
  '38281': '38.28',
  '38282': '38.28',
  '38283': '38.28',
  '38284': '38.28',
  '38285': '38.28',
  '38286': '38.29',
  '38287': '38.29',
  '38288': '38.29',
  '38289': '38.29',
  '38290': '38.29',
  '38291': '38.29',
  '38292': '38.29',
  '38293': '38.29',
  '38294': '38.29',
  '38295': '38.29',
  '38296': '38.30',
  '38297': '38.30',
  '38298': '38.30',
  '38299': '38.30',
  '38300': '38.30',
  '38301': '38.30',
  '38302': '38.30',
  '38303': '38.30',
  '38304': '38.30',
  '38305': '38.31',
  '38306': '38.31',
  '38307': '38.31',
  '38308': '38.31',
  '38309': '38.31',
  '38310': '38.31',
  '38311': '38.31',
  '38312': '38.31',
  '38313': '38.31',
  '38314': '38.31',
  '38315': '38.31',
  '38316': '38.32',
  '38317': '38.32',
  '38318': '38.32',
  '38319': '38.32',
  '38320': '38.32',
  '38321': '38.32',
  '38322': '38.32',
  '38323': '38.32',
  '38324': '38.32',
  '38325': '38.33',
  '38326': '38.33',
  '38327': '38.33',
  '38328': '38.33',
  '38329': '38.33',
  '38330': '38.33',
  '38331': '38.33',
  '38332': '38.33',
  '38333': '38.33',
  '38334': '38.33',
  '38335': '38.33',
  '38336': '38.34',
  '38337': '38.34',
  '38338': '38.34',
  '38339': '38.34',
  '38340': '38.34',
  '38341': '38.34',
  '38342': '38.34',
  '38343': '38.34',
  '38344': '38.34',
  '38345': '38.35',
  '38346': '38.35',
  '38347': '38.35',
  '38348': '38.35',
  '38349': '38.35',
  '38350': '38.35',
  '38351': '38.35',
  '38352': '38.35',
  '38353': '38.35',
  '38354': '38.35',
  '38355': '38.35',
  '38356': '38.36',
  '38357': '38.36',
  '38358': '38.36',
  '38359': '38.36',
  '38360': '38.36',
  '38361': '38.36',
  '38362': '38.36',
  '38363': '38.36',
  '38364': '38.36',
  '38365': '38.37',
  '38366': '38.37',
  '38367': '38.37',
  '38368': '38.37',
  '38369': '38.37',
  '38370': '38.37',
  '38371': '38.37',
  '38372': '38.37',
  '38373': '38.37',
  '38374': '38.37',
  '38375': '38.38',
  '38376': '38.38',
  '38377': '38.38',
  '38378': '38.38',
  '38379': '38.38',
  '38380': '38.38',
  '38381': '38.38',
  '38382': '38.38',
  '38383': '38.38',
  '38384': '38.38',
  '38385': '38.38',
  '38386': '38.39',
  '38387': '38.39',
  '38388': '38.39',
  '38389': '38.39',
  '38390': '38.39',
  '38391': '38.39',
  '38392': '38.39',
  '38393': '38.39',
  '38394': '38.39',
  '38395': '38.40',
  '38396': '38.40',
  '38397': '38.40',
  '38398': '38.40',
  '38399': '38.40',
  '38400': '38.40',
  '38401': '38.40',
  '38402': '38.40',
  '38403': '38.40',
  '38404': '38.40',
  '38405': '38.40',
  '38406': '38.41',
  '38407': '38.41',
  '38408': '38.41',
  '38409': '38.41',
  '38410': '38.41',
  '38411': '38.41',
  '38412': '38.41',
  '38413': '38.41',
  '38414': '38.41',
  '38415': '38.42',
  '38416': '38.42',
  '38417': '38.42',
  '38418': '38.42',
  '38419': '38.42',
  '38420': '38.42',
  '38421': '38.42',
  '38422': '38.42',
  '38423': '38.42',
  '38424': '38.42',
  '38425': '38.42',
  '38426': '38.43',
  '38427': '38.43',
  '38428': '38.43',
  '38429': '38.43',
  '38430': '38.43',
  '38431': '38.43',
  '38432': '38.43',
  '38433': '38.43',
  '38434': '38.43',
  '38435': '38.44',
  '38436': '38.44',
  '38437': '38.44',
  '38438': '38.44',
  '38439': '38.44',
  '38440': '38.44',
  '38441': '38.44',
  '38442': '38.44',
  '38443': '38.44',
  '38444': '38.44',
  '38445': '38.44',
  '38446': '38.45',
  '38447': '38.45',
  '38448': '38.45',
  '38449': '38.45',
  '38450': '38.45',
  '38451': '38.45',
  '38452': '38.45',
  '38453': '38.45',
  '38454': '38.45',
  '38455': '38.46',
  '38456': '38.46',
  '38457': '38.46',
  '38458': '38.46',
  '38459': '38.46',
  '38460': '38.46',
  '38461': '38.46',
  '38462': '38.46',
  '38463': '38.46',
  '38464': '38.46',
  '38465': '38.47',
  '38466': '38.47',
  '38467': '38.47',
  '38468': '38.47',
  '38469': '38.47',
  '38470': '38.47',
  '38471': '38.47',
  '38472': '38.47',
  '38473': '38.47',
  '38474': '38.47',
  '38475': '38.47',
  '38476': '38.48',
  '38477': '38.48',
  '38478': '38.48',
  '38479': '38.48',
  '38480': '38.48',
  '38481': '38.48',
  '38482': '38.48',
  '38483': '38.48',
  '38484': '38.48',
  '38485': '38.49',
  '38486': '38.49',
  '38487': '38.49',
  '38488': '38.49',
  '38489': '38.49',
  '38490': '38.49',
  '38491': '38.49',
  '38492': '38.49',
  '38493': '38.49',
  '38494': '38.49',
  '38495': '38.49',
  '38496': '38.50',
  '38497': '38.50',
  '38498': '38.50',
  '38499': '38.50',
  '38500': '38.50',
  '38501': '38.50',
  '38502': '38.50',
  '38503': '38.50',
  '38504': '38.50',
  '38505': '38.51',
  '38506': '38.51',
  '38507': '38.51',
  '38508': '38.51',
  '38509': '38.51',
  '38510': '38.51',
  '38511': '38.51',
  '38512': '38.51',
  '38513': '38.51',
  '38514': '38.51',
  '38515': '38.51',
  '38516': '38.52',
  '38517': '38.52',
  '38518': '38.52',
  '38519': '38.52',
  '38520': '38.52',
  '38521': '38.52',
  '38522': '38.52',
  '38523': '38.52',
  '38524': '38.52',
  '38525': '38.53',
  '38526': '38.53',
  '38527': '38.53',
  '38528': '38.53',
  '38529': '38.53',
  '38530': '38.53',
  '38531': '38.53',
  '38532': '38.53',
  '38533': '38.53',
  '38534': '38.53',
  '38535': '38.53',
  '38536': '38.54',
  '38537': '38.54',
  '38538': '38.54',
  '38539': '38.54',
  '38540': '38.54',
  '38541': '38.54',
  '38542': '38.54',
  '38543': '38.54',
  '38544': '38.54',
  '38545': '38.54',
  '38546': '38.55',
  '38547': '38.55',
  '38548': '38.55',
  '38549': '38.55',
  '38550': '38.55',
  '38551': '38.55',
  '38552': '38.55',
  '38553': '38.55',
  '38554': '38.55',
  '38555': '38.56',
  '38556': '38.56',
  '38557': '38.56',
  '38558': '38.56',
  '38559': '38.56',
  '38560': '38.56',
  '38561': '38.56',
  '38562': '38.56',
  '38563': '38.56',
  '38564': '38.56',
  '38565': '38.56',
  '38566': '38.57',
  '38567': '38.57',
  '38568': '38.57',
  '38569': '38.57',
  '38570': '38.57',
  '38571': '38.57',
  '38572': '38.57',
  '38573': '38.57',
  '38574': '38.57',
  '38575': '38.58',
  '38576': '38.58',
  '38577': '38.58',
  '38578': '38.58',
  '38579': '38.58',
  '38580': '38.58',
  '38581': '38.58',
  '38582': '38.58',
  '38583': '38.58',
  '38584': '38.58',
  '38585': '38.58',
  '38586': '38.59',
  '38587': '38.59',
  '38588': '38.59',
  '38589': '38.59',
  '38590': '38.59',
  '38591': '38.59',
  '38592': '38.59',
  '38593': '38.59',
  '38594': '38.59',
  '38595': '38.60',
  '38596': '38.60',
  '38597': '38.60',
  '38598': '38.60',
  '38599': '38.60',
  '38600': '38.60',
  '38601': '38.60',
  '38602': '38.60',
  '38603': '38.60',
  '38604': '38.60',
  '38605': '38.60',
  '38606': '38.61',
  '38607': '38.61',
  '38608': '38.61',
  '38609': '38.61',
  '38610': '38.61',
  '38611': '38.61',
  '38612': '38.61',
  '38613': '38.61',
  '38614': '38.61',
  '38615': '38.62',
  '38616': '38.62',
  '38617': '38.62',
  '38618': '38.62',
  '38619': '38.62',
  '38620': '38.62',
  '38621': '38.62',
  '38622': '38.62',
  '38623': '38.62',
  '38624': '38.62',
  '38625': '38.62',
  '38626': '38.63',
  '38627': '38.63',
  '38628': '38.63',
  '38629': '38.63',
  '38630': '38.63',
  '38631': '38.63',
  '38632': '38.63',
  '38633': '38.63',
  '38634': '38.63',
  '38635': '38.63',
  '38636': '38.64',
  '38637': '38.64',
  '38638': '38.64',
  '38639': '38.64',
  '38640': '38.64',
  '38641': '38.64',
  '38642': '38.64',
  '38643': '38.64',
  '38644': '38.64',
  '38645': '38.65',
  '38646': '38.65',
  '38647': '38.65',
  '38648': '38.65',
  '38649': '38.65',
  '38650': '38.65',
  '38651': '38.65',
  '38652': '38.65',
  '38653': '38.65',
  '38654': '38.65',
  '38655': '38.65',
  '38656': '38.66',
  '38657': '38.66',
  '38658': '38.66',
  '38659': '38.66',
  '38660': '38.66',
  '38661': '38.66',
  '38662': '38.66',
  '38663': '38.66',
  '38664': '38.66',
  '38665': '38.67',
  '38666': '38.67',
  '38667': '38.67',
  '38668': '38.67',
  '38669': '38.67',
  '38670': '38.67',
  '38671': '38.67',
  '38672': '38.67',
  '38673': '38.67',
  '38674': '38.67',
  '38675': '38.67',
  '38676': '38.68',
  '38677': '38.68',
  '38678': '38.68',
  '38679': '38.68',
  '38680': '38.68',
  '38681': '38.68',
  '38682': '38.68',
  '38683': '38.68',
  '38684': '38.68',
  '38685': '38.69',
  '38686': '38.69',
  '38687': '38.69',
  '38688': '38.69',
  '38689': '38.69',
  '38690': '38.69',
  '38691': '38.69',
  '38692': '38.69',
  '38693': '38.69',
  '38694': '38.69',
  '38695': '38.69',
  '38696': '38.70',
  '38697': '38.70',
  '38698': '38.70',
  '38699': '38.70',
  '38700': '38.70',
  '38701': '38.70',
  '38702': '38.70',
  '38703': '38.70',
  '38704': '38.70',
  '38705': '38.71',
  '38706': '38.71',
  '38707': '38.71',
  '38708': '38.71',
  '38709': '38.71',
  '38710': '38.71',
  '38711': '38.71',
  '38712': '38.71',
  '38713': '38.71',
  '38714': '38.71',
  '38715': '38.72',
  '38716': '38.72',
  '38717': '38.72',
  '38718': '38.72',
  '38719': '38.72',
  '38720': '38.72',
  '38721': '38.72',
  '38722': '38.72',
  '38723': '38.72',
  '38724': '38.72',
  '38725': '38.72',
  '38726': '38.73',
  '38727': '38.73',
  '38728': '38.73',
  '38729': '38.73',
  '38730': '38.73',
  '38731': '38.73',
  '38732': '38.73',
  '38733': '38.73',
  '38734': '38.73',
  '38735': '38.74',
  '38736': '38.74',
  '38737': '38.74',
  '38738': '38.74',
  '38739': '38.74',
  '38740': '38.74',
  '38741': '38.74',
  '38742': '38.74',
  '38743': '38.74',
  '38744': '38.74',
  '38745': '38.74',
  '38746': '38.75',
  '38747': '38.75',
  '38748': '38.75',
  '38749': '38.75',
  '38750': '38.75',
  '38751': '38.75',
  '38752': '38.75',
  '38753': '38.75',
  '38754': '38.75',
  '38755': '38.76',
  '38756': '38.76',
  '38757': '38.76',
  '38758': '38.76',
  '38759': '38.76',
  '38760': '38.76',
  '38761': '38.76',
  '38762': '38.76',
  '38763': '38.76',
  '38764': '38.76',
  '38765': '38.76',
  '38766': '38.77',
  '38767': '38.77',
  '38768': '38.77',
  '38769': '38.77',
  '38770': '38.77',
  '38771': '38.77',
  '38772': '38.77',
  '38773': '38.77',
  '38774': '38.77',
  '38775': '38.78',
  '38776': '38.78',
  '38777': '38.78',
  '38778': '38.78',
  '38779': '38.78',
  '38780': '38.78',
  '38781': '38.78',
  '38782': '38.78',
  '38783': '38.78',
  '38784': '38.78',
  '38785': '38.78',
  '38786': '38.79',
  '38787': '38.79',
  '38788': '38.79',
  '38789': '38.79',
  '38790': '38.79',
  '38791': '38.79',
  '38792': '38.79',
  '38793': '38.79',
  '38794': '38.79',
  '38795': '38.79',
  '38796': '38.80',
  '38797': '38.80',
  '38798': '38.80',
  '38799': '38.80',
  '38800': '38.80',
  '38801': '38.80',
  '38802': '38.80',
  '38803': '38.80',
  '38804': '38.80',
  '38805': '38.81',
  '38806': '38.81',
  '38807': '38.81',
  '38808': '38.81',
  '38809': '38.81',
  '38810': '38.81',
  '38811': '38.81',
  '38812': '38.81',
  '38813': '38.81',
  '38814': '38.81',
  '38815': '38.81',
  '38816': '38.82',
  '38817': '38.82',
  '38818': '38.82',
  '38819': '38.82',
  '38820': '38.82',
  '38821': '38.82',
  '38822': '38.82',
  '38823': '38.82',
  '38824': '38.82',
  '38825': '38.83',
  '38826': '38.83',
  '38827': '38.83',
  '38828': '38.83',
  '38829': '38.83',
  '38830': '38.83',
  '38831': '38.83',
  '38832': '38.83',
  '38833': '38.83',
  '38834': '38.83',
  '38835': '38.83',
  '38836': '38.84',
  '38837': '38.84',
  '38838': '38.84',
  '38839': '38.84',
  '38840': '38.84',
  '38841': '38.84',
  '38842': '38.84',
  '38843': '38.84',
  '38844': '38.84',
  '38845': '38.85',
  '38846': '38.85',
  '38847': '38.85',
  '38848': '38.85',
  '38849': '38.85',
  '38850': '38.85',
  '38851': '38.85',
  '38852': '38.85',
  '38853': '38.85',
  '38854': '38.85',
  '38855': '38.85',
  '38856': '38.86',
  '38857': '38.86',
  '38858': '38.86',
  '38859': '38.86',
  '38860': '38.86',
  '38861': '38.86',
  '38862': '38.86',
  '38863': '38.86',
  '38864': '38.86',
  '38865': '38.87',
  '38866': '38.87',
  '38867': '38.87',
  '38868': '38.87',
  '38869': '38.87',
  '38870': '38.87',
  '38871': '38.87',
  '38872': '38.87',
  '38873': '38.87',
  '38874': '38.87',
  '38875': '38.88',
  '38876': '38.88',
  '38877': '38.88',
  '38878': '38.88',
  '38879': '38.88',
  '38880': '38.88',
  '38881': '38.88',
  '38882': '38.88',
  '38883': '38.88',
  '38884': '38.88',
  '38885': '38.88',
  '38886': '38.89',
  '38887': '38.89',
  '38888': '38.89',
  '38889': '38.89',
  '38890': '38.89',
  '38891': '38.89',
  '38892': '38.89',
  '38893': '38.89',
  '38894': '38.89',
  '38895': '38.90',
  '38896': '38.90',
  '38897': '38.90',
  '38898': '38.90',
  '38899': '38.90',
  '38900': '38.90',
  '38901': '38.90',
  '38902': '38.90',
  '38903': '38.90',
  '38904': '38.90',
  '38905': '38.90',
  '38906': '38.91',
  '38907': '38.91',
  '38908': '38.91',
  '38909': '38.91',
  '38910': '38.91',
  '38911': '38.91',
  '38912': '38.91',
  '38913': '38.91',
  '38914': '38.91',
  '38915': '38.92',
  '38916': '38.92',
  '38917': '38.92',
  '38918': '38.92',
  '38919': '38.92',
  '38920': '38.92',
  '38921': '38.92',
  '38922': '38.92',
  '38923': '38.92',
  '38924': '38.92',
  '38925': '38.92',
  '38926': '38.93',
  '38927': '38.93',
  '38928': '38.93',
  '38929': '38.93',
  '38930': '38.93',
  '38931': '38.93',
  '38932': '38.93',
  '38933': '38.93',
  '38934': '38.93',
  '38935': '38.94',
  '38936': '38.94',
  '38937': '38.94',
  '38938': '38.94',
  '38939': '38.94',
  '38940': '38.94',
  '38941': '38.94',
  '38942': '38.94',
  '38943': '38.94',
  '38944': '38.94',
  '38945': '38.94',
  '38946': '38.95',
  '38947': '38.95',
  '38948': '38.95',
  '38949': '38.95',
  '38950': '38.95',
  '38951': '38.95',
  '38952': '38.95',
  '38953': '38.95',
  '38954': '38.95',
  '38955': '38.96',
  '38956': '38.96',
  '38957': '38.96',
  '38958': '38.96',
  '38959': '38.96',
  '38960': '38.96',
  '38961': '38.96',
  '38962': '38.96',
  '38963': '38.96',
  '38964': '38.96',
  '38965': '38.97',
  '38966': '38.97',
  '38967': '38.97',
  '38968': '38.97',
  '38969': '38.97',
  '38970': '38.97',
  '38971': '38.97',
  '38972': '38.97',
  '38973': '38.97',
  '38974': '38.97',
  '38975': '38.97',
  '38976': '38.98',
  '38977': '38.98',
  '38978': '38.98',
  '38979': '38.98',
  '38980': '38.98',
  '38981': '38.98',
  '38982': '38.98',
  '38983': '38.98',
  '38984': '38.98',
  '38985': '38.99',
  '38986': '38.99',
  '38987': '38.99',
  '38988': '38.99',
  '38989': '38.99',
  '38990': '38.99',
  '38991': '38.99',
  '38992': '38.99',
  '38993': '38.99',
  '38994': '38.99',
  '38995': '38.99',
  '38996': '39.00',
  '38997': '39.00',
  '38998': '39.00',
  '38999': '39.00',
  '39000': '39.00',
  '39001': '39.00',
  '39002': '39.00',
  '39003': '39.00',
  '39004': '39.00',
  '39005': '39.01',
  '39006': '39.01',
  '39007': '39.01',
  '39008': '39.01',
  '39009': '39.01',
  '39010': '39.01',
  '39011': '39.01',
  '39012': '39.01',
  '39013': '39.01',
  '39014': '39.01',
  '39015': '39.01',
  '39016': '39.02',
  '39017': '39.02',
  '39018': '39.02',
  '39019': '39.02',
  '39020': '39.02',
  '39021': '39.02',
  '39022': '39.02',
  '39023': '39.02',
  '39024': '39.02',
  '39025': '39.03',
  '39026': '39.03',
  '39027': '39.03',
  '39028': '39.03',
  '39029': '39.03',
  '39030': '39.03',
  '39031': '39.03',
  '39032': '39.03',
  '39033': '39.03',
  '39034': '39.03',
  '39035': '39.03',
  '39036': '39.04',
  '39037': '39.04',
  '39038': '39.04',
  '39039': '39.04',
  '39040': '39.04',
  '39041': '39.04',
  '39042': '39.04',
  '39043': '39.04',
  '39044': '39.04',
  '39045': '39.04',
  '39046': '39.05',
  '39047': '39.05',
  '39048': '39.05',
  '39049': '39.05',
  '39050': '39.05',
  '39051': '39.05',
  '39052': '39.05',
  '39053': '39.05',
  '39054': '39.05',
  '39055': '39.06',
  '39056': '39.06',
  '39057': '39.06',
  '39058': '39.06',
  '39059': '39.06',
  '39060': '39.06',
  '39061': '39.06',
  '39062': '39.06',
  '39063': '39.06',
  '39064': '39.06',
  '39065': '39.06',
  '39066': '39.07',
  '39067': '39.07',
  '39068': '39.07',
  '39069': '39.07',
  '39070': '39.07',
  '39071': '39.07',
  '39072': '39.07',
  '39073': '39.07',
  '39074': '39.07',
  '39075': '39.08',
  '39076': '39.08',
  '39077': '39.08',
  '39078': '39.08',
  '39079': '39.08',
  '39080': '39.08',
  '39081': '39.08',
  '39082': '39.08',
  '39083': '39.08',
  '39084': '39.08',
  '39085': '39.08',
  '39086': '39.09',
  '39087': '39.09',
  '39088': '39.09',
  '39089': '39.09',
  '39090': '39.09',
  '39091': '39.09',
  '39092': '39.09',
  '39093': '39.09',
  '39094': '39.09',
  '39095': '39.10',
  '39096': '39.10',
  '39097': '39.10',
  '39098': '39.10',
  '39099': '39.10',
  '39100': '39.10',
  '39101': '39.10',
  '39102': '39.10',
  '39103': '39.10',
  '39104': '39.10',
  '39105': '39.10',
  '39106': '39.11',
  '39107': '39.11',
  '39108': '39.11',
  '39109': '39.11',
  '39110': '39.11',
  '39111': '39.11',
  '39112': '39.11',
  '39113': '39.11',
  '39114': '39.11',
  '39115': '39.12',
  '39116': '39.12',
  '39117': '39.12',
  '39118': '39.12',
  '39119': '39.12',
  '39120': '39.12',
  '39121': '39.12',
  '39122': '39.12',
  '39123': '39.12',
  '39124': '39.12',
  '39125': '39.12',
  '39126': '39.13',
  '39127': '39.13',
  '39128': '39.13',
  '39129': '39.13',
  '39130': '39.13',
  '39131': '39.13',
  '39132': '39.13',
  '39133': '39.13',
  '39134': '39.13',
  '39135': '39.13',
  '39136': '39.14',
  '39137': '39.14',
  '39138': '39.14',
  '39139': '39.14',
  '39140': '39.14',
  '39141': '39.14',
  '39142': '39.14',
  '39143': '39.14',
  '39144': '39.14',
  '39145': '39.15',
  '39146': '39.15',
  '39147': '39.15',
  '39148': '39.15',
  '39149': '39.15',
  '39150': '39.15',
  '39151': '39.15',
  '39152': '39.15',
  '39153': '39.15',
  '39154': '39.15',
  '39155': '39.15',
  '39156': '39.16',
  '39157': '39.16',
  '39158': '39.16',
  '39159': '39.16',
  '39160': '39.16',
  '39161': '39.16',
  '39162': '39.16',
  '39163': '39.16',
  '39164': '39.16',
  '39165': '39.17',
  '39166': '39.17',
  '39167': '39.17',
  '39168': '39.17',
  '39169': '39.17',
  '39170': '39.17',
  '39171': '39.17',
  '39172': '39.17',
  '39173': '39.17',
  '39174': '39.17',
  '39175': '39.17',
  '39176': '39.18',
  '39177': '39.18',
  '39178': '39.18',
  '39179': '39.18',
  '39180': '39.18',
  '39181': '39.18',
  '39182': '39.18',
  '39183': '39.18',
  '39184': '39.18',
  '39185': '39.19',
  '39186': '39.19',
  '39187': '39.19',
  '39188': '39.19',
  '39189': '39.19',
  '39190': '39.19',
  '39191': '39.19',
  '39192': '39.19',
  '39193': '39.19',
  '39194': '39.19',
  '39195': '39.19',
  '39196': '39.20',
  '39197': '39.20',
  '39198': '39.20',
  '39199': '39.20',
  '39200': '39.20',
  '39201': '39.20',
  '39202': '39.20',
  '39203': '39.20',
  '39204': '39.20',
  '39205': '39.21',
  '39206': '39.21',
  '39207': '39.21',
  '39208': '39.21',
  '39209': '39.21',
  '39210': '39.21',
  '39211': '39.21',
  '39212': '39.21',
  '39213': '39.21',
  '39214': '39.21',
  '39215': '39.22',
  '39216': '39.22',
  '39217': '39.22',
  '39218': '39.22',
  '39219': '39.22',
  '39220': '39.22',
  '39221': '39.22',
  '39222': '39.22',
  '39223': '39.22',
  '39224': '39.22',
  '39225': '39.22',
  '39226': '39.23',
  '39227': '39.23',
  '39228': '39.23',
  '39229': '39.23',
  '39230': '39.23',
  '39231': '39.23',
  '39232': '39.23',
  '39233': '39.23',
  '39234': '39.23',
  '39235': '39.24',
  '39236': '39.24',
  '39237': '39.24',
  '39238': '39.24',
  '39239': '39.24',
  '39240': '39.24',
  '39241': '39.24',
  '39242': '39.24',
  '39243': '39.24',
  '39244': '39.24',
  '39245': '39.24',
  '39246': '39.25',
  '39247': '39.25',
  '39248': '39.25',
  '39249': '39.25',
  '39250': '39.25',
  '39251': '39.25',
  '39252': '39.25',
  '39253': '39.25',
  '39254': '39.25',
  '39255': '39.26',
  '39256': '39.26',
  '39257': '39.26',
  '39258': '39.26',
  '39259': '39.26',
  '39260': '39.26',
  '39261': '39.26',
  '39262': '39.26',
  '39263': '39.26',
  '39264': '39.26',
  '39265': '39.26',
  '39266': '39.27',
  '39267': '39.27',
  '39268': '39.27',
  '39269': '39.27',
  '39270': '39.27',
  '39271': '39.27',
  '39272': '39.27',
  '39273': '39.27',
  '39274': '39.27',
  '39275': '39.28',
  '39276': '39.28',
  '39277': '39.28',
  '39278': '39.28',
  '39279': '39.28',
  '39280': '39.28',
  '39281': '39.28',
  '39282': '39.28',
  '39283': '39.28',
  '39284': '39.28',
  '39285': '39.28',
  '39286': '39.29',
  '39287': '39.29',
  '39288': '39.29',
  '39289': '39.29',
  '39290': '39.29',
  '39291': '39.29',
  '39292': '39.29',
  '39293': '39.29',
  '39294': '39.29',
  '39295': '39.29',
  '39296': '39.30',
  '39297': '39.30',
  '39298': '39.30',
  '39299': '39.30',
  '39300': '39.30',
  '39301': '39.30',
  '39302': '39.30',
  '39303': '39.30',
  '39304': '39.30',
  '39305': '39.31',
  '39306': '39.31',
  '39307': '39.31',
  '39308': '39.31',
  '39309': '39.31',
  '39310': '39.31',
  '39311': '39.31',
  '39312': '39.31',
  '39313': '39.31',
  '39314': '39.31',
  '39315': '39.31',
  '39316': '39.32',
  '39317': '39.32',
  '39318': '39.32',
  '39319': '39.32',
  '39320': '39.32',
  '39321': '39.32',
  '39322': '39.32',
  '39323': '39.32',
  '39324': '39.32',
  '39325': '39.33',
  '39326': '39.33',
  '39327': '39.33',
  '39328': '39.33',
  '39329': '39.33',
  '39330': '39.33',
  '39331': '39.33',
  '39332': '39.33',
  '39333': '39.33',
  '39334': '39.33',
  '39335': '39.33',
  '39336': '39.34',
  '39337': '39.34',
  '39338': '39.34',
  '39339': '39.34',
  '39340': '39.34',
  '39341': '39.34',
  '39342': '39.34',
  '39343': '39.34',
  '39344': '39.34',
  '39345': '39.35',
  '39346': '39.35',
  '39347': '39.35',
  '39348': '39.35',
  '39349': '39.35',
  '39350': '39.35',
  '39351': '39.35',
  '39352': '39.35',
  '39353': '39.35',
  '39354': '39.35',
  '39355': '39.35',
  '39356': '39.36',
  '39357': '39.36',
  '39358': '39.36',
  '39359': '39.36',
  '39360': '39.36',
  '39361': '39.36',
  '39362': '39.36',
  '39363': '39.36',
  '39364': '39.36',
  '39365': '39.37',
  '39366': '39.37',
  '39367': '39.37',
  '39368': '39.37',
  '39369': '39.37',
  '39370': '39.37',
  '39371': '39.37',
  '39372': '39.37',
  '39373': '39.37',
  '39374': '39.37',
  '39375': '39.38',
  '39376': '39.38',
  '39377': '39.38',
  '39378': '39.38',
  '39379': '39.38',
  '39380': '39.38',
  '39381': '39.38',
  '39382': '39.38',
  '39383': '39.38',
  '39384': '39.38',
  '39385': '39.38',
  '39386': '39.39',
  '39387': '39.39',
  '39388': '39.39',
  '39389': '39.39',
  '39390': '39.39',
  '39391': '39.39',
  '39392': '39.39',
  '39393': '39.39',
  '39394': '39.39',
  '39395': '39.40',
  '39396': '39.40',
  '39397': '39.40',
  '39398': '39.40',
  '39399': '39.40',
  '39400': '39.40',
  '39401': '39.40',
  '39402': '39.40',
  '39403': '39.40',
  '39404': '39.40',
  '39405': '39.40',
  '39406': '39.41',
  '39407': '39.41',
  '39408': '39.41',
  '39409': '39.41',
  '39410': '39.41',
  '39411': '39.41',
  '39412': '39.41',
  '39413': '39.41',
  '39414': '39.41',
  '39415': '39.42',
  '39416': '39.42',
  '39417': '39.42',
  '39418': '39.42',
  '39419': '39.42',
  '39420': '39.42',
  '39421': '39.42',
  '39422': '39.42',
  '39423': '39.42',
  '39424': '39.42',
  '39425': '39.42',
  '39426': '39.43',
  '39427': '39.43',
  '39428': '39.43',
  '39429': '39.43',
  '39430': '39.43',
  '39431': '39.43',
  '39432': '39.43',
  '39433': '39.43',
  '39434': '39.43',
  '39435': '39.44',
  '39436': '39.44',
  '39437': '39.44',
  '39438': '39.44',
  '39439': '39.44',
  '39440': '39.44',
  '39441': '39.44',
  '39442': '39.44',
  '39443': '39.44',
  '39444': '39.44',
  '39445': '39.44',
  '39446': '39.45',
  '39447': '39.45',
  '39448': '39.45',
  '39449': '39.45',
  '39450': '39.45',
  '39451': '39.45',
  '39452': '39.45',
  '39453': '39.45',
  '39454': '39.45',
  '39455': '39.46',
  '39456': '39.46',
  '39457': '39.46',
  '39458': '39.46',
  '39459': '39.46',
  '39460': '39.46',
  '39461': '39.46',
  '39462': '39.46',
  '39463': '39.46',
  '39464': '39.46',
  '39465': '39.47',
  '39466': '39.47',
  '39467': '39.47',
  '39468': '39.47',
  '39469': '39.47',
  '39470': '39.47',
  '39471': '39.47',
  '39472': '39.47',
  '39473': '39.47',
  '39474': '39.47',
  '39475': '39.47',
  '39476': '39.48',
  '39477': '39.48',
  '39478': '39.48',
  '39479': '39.48',
  '39480': '39.48',
  '39481': '39.48',
  '39482': '39.48',
  '39483': '39.48',
  '39484': '39.48',
  '39485': '39.49',
  '39486': '39.49',
  '39487': '39.49',
  '39488': '39.49',
  '39489': '39.49',
  '39490': '39.49',
  '39491': '39.49',
  '39492': '39.49',
  '39493': '39.49',
  '39494': '39.49',
  '39495': '39.49',
  '39496': '39.50',
  '39497': '39.50',
  '39498': '39.50',
  '39499': '39.50',
  '39500': '39.50',
  '39501': '39.50',
  '39502': '39.50',
  '39503': '39.50',
  '39504': '39.50',
  '39505': '39.51',
  '39506': '39.51',
  '39507': '39.51',
  '39508': '39.51',
  '39509': '39.51',
  '39510': '39.51',
  '39511': '39.51',
  '39512': '39.51',
  '39513': '39.51',
  '39514': '39.51',
  '39515': '39.51',
  '39516': '39.52',
  '39517': '39.52',
  '39518': '39.52',
  '39519': '39.52',
  '39520': '39.52',
  '39521': '39.52',
  '39522': '39.52',
  '39523': '39.52',
  '39524': '39.52',
  '39525': '39.53',
  '39526': '39.53',
  '39527': '39.53',
  '39528': '39.53',
  '39529': '39.53',
  '39530': '39.53',
  '39531': '39.53',
  '39532': '39.53',
  '39533': '39.53',
  '39534': '39.53',
  '39535': '39.53',
  '39536': '39.54',
  '39537': '39.54',
  '39538': '39.54',
  '39539': '39.54',
  '39540': '39.54',
  '39541': '39.54',
  '39542': '39.54',
  '39543': '39.54',
  '39544': '39.54',
  '39545': '39.54',
  '39546': '39.55',
  '39547': '39.55',
  '39548': '39.55',
  '39549': '39.55',
  '39550': '39.55',
  '39551': '39.55',
  '39552': '39.55',
  '39553': '39.55',
  '39554': '39.55',
  '39555': '39.56',
  '39556': '39.56',
  '39557': '39.56',
  '39558': '39.56',
  '39559': '39.56',
  '39560': '39.56',
  '39561': '39.56',
  '39562': '39.56',
  '39563': '39.56',
  '39564': '39.56',
  '39565': '39.56',
  '39566': '39.57',
  '39567': '39.57',
  '39568': '39.57',
  '39569': '39.57',
  '39570': '39.57',
  '39571': '39.57',
  '39572': '39.57',
  '39573': '39.57',
  '39574': '39.57',
  '39575': '39.58',
  '39576': '39.58',
  '39577': '39.58',
  '39578': '39.58',
  '39579': '39.58',
  '39580': '39.58',
  '39581': '39.58',
  '39582': '39.58',
  '39583': '39.58',
  '39584': '39.58',
  '39585': '39.58',
  '39586': '39.59',
  '39587': '39.59',
  '39588': '39.59',
  '39589': '39.59',
  '39590': '39.59',
  '39591': '39.59',
  '39592': '39.59',
  '39593': '39.59',
  '39594': '39.59',
  '39595': '39.60',
  '39596': '39.60',
  '39597': '39.60',
  '39598': '39.60',
  '39599': '39.60',
  '39600': '39.60',
  '39601': '39.60',
  '39602': '39.60',
  '39603': '39.60',
  '39604': '39.60',
  '39605': '39.60',
  '39606': '39.61',
  '39607': '39.61',
  '39608': '39.61',
  '39609': '39.61',
  '39610': '39.61',
  '39611': '39.61',
  '39612': '39.61',
  '39613': '39.61',
  '39614': '39.61',
  '39615': '39.62',
  '39616': '39.62',
  '39617': '39.62',
  '39618': '39.62',
  '39619': '39.62',
  '39620': '39.62',
  '39621': '39.62',
  '39622': '39.62',
  '39623': '39.62',
  '39624': '39.62',
  '39625': '39.62',
  '39626': '39.63',
  '39627': '39.63',
  '39628': '39.63',
  '39629': '39.63',
  '39630': '39.63',
  '39631': '39.63',
  '39632': '39.63',
  '39633': '39.63',
  '39634': '39.63',
  '39635': '39.63',
  '39636': '39.64',
  '39637': '39.64',
  '39638': '39.64',
  '39639': '39.64',
  '39640': '39.64',
  '39641': '39.64',
  '39642': '39.64',
  '39643': '39.64',
  '39644': '39.64',
  '39645': '39.65',
  '39646': '39.65',
  '39647': '39.65',
  '39648': '39.65',
  '39649': '39.65',
  '39650': '39.65',
  '39651': '39.65',
  '39652': '39.65',
  '39653': '39.65',
  '39654': '39.65',
  '39655': '39.65',
  '39656': '39.66',
  '39657': '39.66',
  '39658': '39.66',
  '39659': '39.66',
  '39660': '39.66',
  '39661': '39.66',
  '39662': '39.66',
  '39663': '39.66',
  '39664': '39.66',
  '39665': '39.67',
  '39666': '39.67',
  '39667': '39.67',
  '39668': '39.67',
  '39669': '39.67',
  '39670': '39.67',
  '39671': '39.67',
  '39672': '39.67',
  '39673': '39.67',
  '39674': '39.67',
  '39675': '39.67',
  '39676': '39.68',
  '39677': '39.68',
  '39678': '39.68',
  '39679': '39.68',
  '39680': '39.68',
  '39681': '39.68',
  '39682': '39.68',
  '39683': '39.68',
  '39684': '39.68',
  '39685': '39.69',
  '39686': '39.69',
  '39687': '39.69',
  '39688': '39.69',
  '39689': '39.69',
  '39690': '39.69',
  '39691': '39.69',
  '39692': '39.69',
  '39693': '39.69',
  '39694': '39.69',
  '39695': '39.69',
  '39696': '39.70',
  '39697': '39.70',
  '39698': '39.70',
  '39699': '39.70',
  '39700': '39.70',
  '39701': '39.70',
  '39702': '39.70',
  '39703': '39.70',
  '39704': '39.70',
  '39705': '39.71',
  '39706': '39.71',
  '39707': '39.71',
  '39708': '39.71',
  '39709': '39.71',
  '39710': '39.71',
  '39711': '39.71',
  '39712': '39.71',
  '39713': '39.71',
  '39714': '39.71',
  '39715': '39.72',
  '39716': '39.72',
  '39717': '39.72',
  '39718': '39.72',
  '39719': '39.72',
  '39720': '39.72',
  '39721': '39.72',
  '39722': '39.72',
  '39723': '39.72',
  '39724': '39.72',
  '39725': '39.72',
  '39726': '39.73',
  '39727': '39.73',
  '39728': '39.73',
  '39729': '39.73',
  '39730': '39.73',
  '39731': '39.73',
  '39732': '39.73',
  '39733': '39.73',
  '39734': '39.73',
  '39735': '39.74',
  '39736': '39.74',
  '39737': '39.74',
  '39738': '39.74',
  '39739': '39.74',
  '39740': '39.74',
  '39741': '39.74',
  '39742': '39.74',
  '39743': '39.74',
  '39744': '39.74',
  '39745': '39.74',
  '39746': '39.75',
  '39747': '39.75',
  '39748': '39.75',
  '39749': '39.75',
  '39750': '39.75',
  '39751': '39.75',
  '39752': '39.75',
  '39753': '39.75',
  '39754': '39.75',
  '39755': '39.76',
  '39756': '39.76',
  '39757': '39.76',
  '39758': '39.76',
  '39759': '39.76',
  '39760': '39.76',
  '39761': '39.76',
  '39762': '39.76',
  '39763': '39.76',
  '39764': '39.76',
  '39765': '39.76',
  '39766': '39.77',
  '39767': '39.77',
  '39768': '39.77',
  '39769': '39.77',
  '39770': '39.77',
  '39771': '39.77',
  '39772': '39.77',
  '39773': '39.77',
  '39774': '39.77',
  '39775': '39.78',
  '39776': '39.78',
  '39777': '39.78',
  '39778': '39.78',
  '39779': '39.78',
  '39780': '39.78',
  '39781': '39.78',
  '39782': '39.78',
  '39783': '39.78',
  '39784': '39.78',
  '39785': '39.78',
  '39786': '39.79',
  '39787': '39.79',
  '39788': '39.79',
  '39789': '39.79',
  '39790': '39.79',
  '39791': '39.79',
  '39792': '39.79',
  '39793': '39.79',
  '39794': '39.79',
  '39795': '39.79',
  '39796': '39.80',
  '39797': '39.80',
  '39798': '39.80',
  '39799': '39.80',
  '39800': '39.80',
  '39801': '39.80',
  '39802': '39.80',
  '39803': '39.80',
  '39804': '39.80',
  '39805': '39.81',
  '39806': '39.81',
  '39807': '39.81',
  '39808': '39.81',
  '39809': '39.81',
  '39810': '39.81',
  '39811': '39.81',
  '39812': '39.81',
  '39813': '39.81',
  '39814': '39.81',
  '39815': '39.81',
  '39816': '39.82',
  '39817': '39.82',
  '39818': '39.82',
  '39819': '39.82',
  '39820': '39.82',
  '39821': '39.82',
  '39822': '39.82',
  '39823': '39.82',
  '39824': '39.82',
  '39825': '39.83',
  '39826': '39.83',
  '39827': '39.83',
  '39828': '39.83',
  '39829': '39.83',
  '39830': '39.83',
  '39831': '39.83',
  '39832': '39.83',
  '39833': '39.83',
  '39834': '39.83',
  '39835': '39.83',
  '39836': '39.84',
  '39837': '39.84',
  '39838': '39.84',
  '39839': '39.84',
  '39840': '39.84',
  '39841': '39.84',
  '39842': '39.84',
  '39843': '39.84',
  '39844': '39.84',
  '39845': '39.85',
  '39846': '39.85',
  '39847': '39.85',
  '39848': '39.85',
  '39849': '39.85',
  '39850': '39.85',
  '39851': '39.85',
  '39852': '39.85',
  '39853': '39.85',
  '39854': '39.85',
  '39855': '39.85',
  '39856': '39.86',
  '39857': '39.86',
  '39858': '39.86',
  '39859': '39.86',
  '39860': '39.86',
  '39861': '39.86',
  '39862': '39.86',
  '39863': '39.86',
  '39864': '39.86',
  '39865': '39.87',
  '39866': '39.87',
  '39867': '39.87',
  '39868': '39.87',
  '39869': '39.87',
  '39870': '39.87',
  '39871': '39.87',
  '39872': '39.87',
  '39873': '39.87',
  '39874': '39.87',
  '39875': '39.88',
  '39876': '39.88',
  '39877': '39.88',
  '39878': '39.88',
  '39879': '39.88',
  '39880': '39.88',
  '39881': '39.88',
  '39882': '39.88',
  '39883': '39.88',
  '39884': '39.88',
  '39885': '39.88',
  '39886': '39.89',
  '39887': '39.89',
  '39888': '39.89',
  '39889': '39.89',
  '39890': '39.89',
  '39891': '39.89',
  '39892': '39.89',
  '39893': '39.89',
  '39894': '39.89',
  '39895': '39.90',
  '39896': '39.90',
  '39897': '39.90',
  '39898': '39.90',
  '39899': '39.90',
  '39900': '39.90',
  '39901': '39.90',
  '39902': '39.90',
  '39903': '39.90',
  '39904': '39.90',
  '39905': '39.90',
  '39906': '39.91',
  '39907': '39.91',
  '39908': '39.91',
  '39909': '39.91',
  '39910': '39.91',
  '39911': '39.91',
  '39912': '39.91',
  '39913': '39.91',
  '39914': '39.91',
  '39915': '39.92',
  '39916': '39.92',
  '39917': '39.92',
  '39918': '39.92',
  '39919': '39.92',
  '39920': '39.92',
  '39921': '39.92',
  '39922': '39.92',
  '39923': '39.92',
  '39924': '39.92',
  '39925': '39.92',
  '39926': '39.93',
  '39927': '39.93',
  '39928': '39.93',
  '39929': '39.93',
  '39930': '39.93',
  '39931': '39.93',
  '39932': '39.93',
  '39933': '39.93',
  '39934': '39.93',
  '39935': '39.94',
  '39936': '39.94',
  '39937': '39.94',
  '39938': '39.94',
  '39939': '39.94',
  '39940': '39.94',
  '39941': '39.94',
  '39942': '39.94',
  '39943': '39.94',
  '39944': '39.94',
  '39945': '39.94',
  '39946': '39.95',
  '39947': '39.95',
  '39948': '39.95',
  '39949': '39.95',
  '39950': '39.95',
  '39951': '39.95',
  '39952': '39.95',
  '39953': '39.95',
  '39954': '39.95',
  '39955': '39.96',
  '39956': '39.96',
  '39957': '39.96',
  '39958': '39.96',
  '39959': '39.96',
  '39960': '39.96',
  '39961': '39.96',
  '39962': '39.96',
  '39963': '39.96',
  '39964': '39.96',
  '39965': '39.97',
  '39966': '39.97',
  '39967': '39.97',
  '39968': '39.97',
  '39969': '39.97',
  '39970': '39.97',
  '39971': '39.97',
  '39972': '39.97',
  '39973': '39.97',
  '39974': '39.97',
  '39975': '39.97',
  '39976': '39.98',
  '39977': '39.98',
  '39978': '39.98',
  '39979': '39.98',
  '39980': '39.98',
  '39981': '39.98',
  '39982': '39.98',
  '39983': '39.98',
  '39984': '39.98',
  '39985': '39.99',
  '39986': '39.99',
  '39987': '39.99',
  '39988': '39.99',
  '39989': '39.99',
  '39990': '39.99',
  '39991': '39.99',
  '39992': '39.99',
  '39993': '39.99',
  '39994': '39.99',
  '39995': '39.99',
  '39996': '40.00',
  '39997': '40.00',
  '39998': '40.00',
  '39999': '40.00',
  '40000': '40.00',
  '40001': '40.00',
  '40002': '40.00',
  '40003': '40.00',
  '40004': '40.00',
  '40005': '40.01',
  '40006': '40.01',
  '40007': '40.01',
  '40008': '40.01',
  '40009': '40.01',
  '40010': '40.01',
  '40011': '40.01',
  '40012': '40.01',
  '40013': '40.01',
  '40014': '40.01',
  '40015': '40.01',
  '40016': '40.02',
  '40017': '40.02',
  '40018': '40.02',
  '40019': '40.02',
  '40020': '40.02',
  '40021': '40.02',
  '40022': '40.02',
  '40023': '40.02',
  '40024': '40.02',
  '40025': '40.03',
  '40026': '40.03',
  '40027': '40.03',
  '40028': '40.03',
  '40029': '40.03',
  '40030': '40.03',
  '40031': '40.03',
  '40032': '40.03',
  '40033': '40.03',
  '40034': '40.03',
  '40035': '40.03',
  '40036': '40.04',
  '40037': '40.04',
  '40038': '40.04',
  '40039': '40.04',
  '40040': '40.04',
  '40041': '40.04',
  '40042': '40.04',
  '40043': '40.04',
  '40044': '40.04',
  '40045': '40.04',
  '40046': '40.05',
  '40047': '40.05',
  '40048': '40.05',
  '40049': '40.05',
  '40050': '40.05',
  '40051': '40.05',
  '40052': '40.05',
  '40053': '40.05',
  '40054': '40.05',
  '40055': '40.06',
  '40056': '40.06',
  '40057': '40.06',
  '40058': '40.06',
  '40059': '40.06',
  '40060': '40.06',
  '40061': '40.06',
  '40062': '40.06',
  '40063': '40.06',
  '40064': '40.06',
  '40065': '40.06',
  '40066': '40.07',
  '40067': '40.07',
  '40068': '40.07',
  '40069': '40.07',
  '40070': '40.07',
  '40071': '40.07',
  '40072': '40.07',
  '40073': '40.07',
  '40074': '40.07',
  '40075': '40.08',
  '40076': '40.08',
  '40077': '40.08',
  '40078': '40.08',
  '40079': '40.08',
  '40080': '40.08',
  '40081': '40.08',
  '40082': '40.08',
  '40083': '40.08',
  '40084': '40.08',
  '40085': '40.08',
  '40086': '40.09',
  '40087': '40.09',
  '40088': '40.09',
  '40089': '40.09',
  '40090': '40.09',
  '40091': '40.09',
  '40092': '40.09',
  '40093': '40.09',
  '40094': '40.09',
  '40095': '40.10',
  '40096': '40.10',
  '40097': '40.10',
  '40098': '40.10',
  '40099': '40.10',
  '40100': '40.10',
  '40101': '40.10',
  '40102': '40.10',
  '40103': '40.10',
  '40104': '40.10',
  '40105': '40.10',
  '40106': '40.11',
  '40107': '40.11',
  '40108': '40.11',
  '40109': '40.11',
  '40110': '40.11',
  '40111': '40.11',
  '40112': '40.11',
  '40113': '40.11',
  '40114': '40.11',
  '40115': '40.12',
  '40116': '40.12',
  '40117': '40.12',
  '40118': '40.12',
  '40119': '40.12',
  '40120': '40.12',
  '40121': '40.12',
  '40122': '40.12',
  '40123': '40.12',
  '40124': '40.12',
  '40125': '40.12',
  '40126': '40.13',
  '40127': '40.13',
  '40128': '40.13',
  '40129': '40.13',
  '40130': '40.13',
  '40131': '40.13',
  '40132': '40.13',
  '40133': '40.13',
  '40134': '40.13',
  '40135': '40.13',
  '40136': '40.14',
  '40137': '40.14',
  '40138': '40.14',
  '40139': '40.14',
  '40140': '40.14',
  '40141': '40.14',
  '40142': '40.14',
  '40143': '40.14',
  '40144': '40.14',
  '40145': '40.15',
  '40146': '40.15',
  '40147': '40.15',
  '40148': '40.15',
  '40149': '40.15',
  '40150': '40.15',
  '40151': '40.15',
  '40152': '40.15',
  '40153': '40.15',
  '40154': '40.15',
  '40155': '40.15',
  '40156': '40.16',
  '40157': '40.16',
  '40158': '40.16',
  '40159': '40.16',
  '40160': '40.16',
  '40161': '40.16',
  '40162': '40.16',
  '40163': '40.16',
  '40164': '40.16',
  '40165': '40.17',
  '40166': '40.17',
  '40167': '40.17',
  '40168': '40.17',
  '40169': '40.17',
  '40170': '40.17',
  '40171': '40.17',
  '40172': '40.17',
  '40173': '40.17',
  '40174': '40.17',
  '40175': '40.17',
  '40176': '40.18',
  '40177': '40.18',
  '40178': '40.18',
  '40179': '40.18',
  '40180': '40.18',
  '40181': '40.18',
  '40182': '40.18',
  '40183': '40.18',
  '40184': '40.18',
  '40185': '40.19',
  '40186': '40.19',
  '40187': '40.19',
  '40188': '40.19',
  '40189': '40.19',
  '40190': '40.19',
  '40191': '40.19',
  '40192': '40.19',
  '40193': '40.19',
  '40194': '40.19',
  '40195': '40.19',
  '40196': '40.20',
  '40197': '40.20',
  '40198': '40.20',
  '40199': '40.20',
  '40200': '40.20',
  '40201': '40.20',
  '40202': '40.20',
  '40203': '40.20',
  '40204': '40.20',
  '40205': '40.21',
  '40206': '40.21',
  '40207': '40.21',
  '40208': '40.21',
  '40209': '40.21',
  '40210': '40.21',
  '40211': '40.21',
  '40212': '40.21',
  '40213': '40.21',
  '40214': '40.21',
  '40215': '40.22',
  '40216': '40.22',
  '40217': '40.22',
  '40218': '40.22',
  '40219': '40.22',
  '40220': '40.22',
  '40221': '40.22',
  '40222': '40.22',
  '40223': '40.22',
  '40224': '40.22',
  '40225': '40.22',
  '40226': '40.23',
  '40227': '40.23',
  '40228': '40.23',
  '40229': '40.23',
  '40230': '40.23',
  '40231': '40.23',
  '40232': '40.23',
  '40233': '40.23',
  '40234': '40.23',
  '40235': '40.24',
  '40236': '40.24',
  '40237': '40.24',
  '40238': '40.24',
  '40239': '40.24',
  '40240': '40.24',
  '40241': '40.24',
  '40242': '40.24',
  '40243': '40.24',
  '40244': '40.24',
  '40245': '40.24',
  '40246': '40.25',
  '40247': '40.25',
  '40248': '40.25',
  '40249': '40.25',
  '40250': '40.25',
  '40251': '40.25',
  '40252': '40.25',
  '40253': '40.25',
  '40254': '40.25',
  '40255': '40.26',
  '40256': '40.26',
  '40257': '40.26',
  '40258': '40.26',
  '40259': '40.26',
  '40260': '40.26',
  '40261': '40.26',
  '40262': '40.26',
  '40263': '40.26',
  '40264': '40.26',
  '40265': '40.26',
  '40266': '40.27',
  '40267': '40.27',
  '40268': '40.27',
  '40269': '40.27',
  '40270': '40.27',
  '40271': '40.27',
  '40272': '40.27',
  '40273': '40.27',
  '40274': '40.27',
  '40275': '40.28',
  '40276': '40.28',
  '40277': '40.28',
  '40278': '40.28',
  '40279': '40.28',
  '40280': '40.28',
  '40281': '40.28',
  '40282': '40.28',
  '40283': '40.28',
  '40284': '40.28',
  '40285': '40.28',
  '40286': '40.29',
  '40287': '40.29',
  '40288': '40.29',
  '40289': '40.29',
  '40290': '40.29',
  '40291': '40.29',
  '40292': '40.29',
  '40293': '40.29',
  '40294': '40.29',
  '40295': '40.29',
  '40296': '40.30',
  '40297': '40.30',
  '40298': '40.30',
  '40299': '40.30',
  '40300': '40.30',
  '40301': '40.30',
  '40302': '40.30',
  '40303': '40.30',
  '40304': '40.30',
  '40305': '40.31',
  '40306': '40.31',
  '40307': '40.31',
  '40308': '40.31',
  '40309': '40.31',
  '40310': '40.31',
  '40311': '40.31',
  '40312': '40.31',
  '40313': '40.31',
  '40314': '40.31',
  '40315': '40.31',
  '40316': '40.32',
  '40317': '40.32',
  '40318': '40.32',
  '40319': '40.32',
  '40320': '40.32',
  '40321': '40.32',
  '40322': '40.32',
  '40323': '40.32',
  '40324': '40.32',
  '40325': '40.33',
  '40326': '40.33',
  '40327': '40.33',
  '40328': '40.33',
  '40329': '40.33',
  '40330': '40.33',
  '40331': '40.33',
  '40332': '40.33',
  '40333': '40.33',
  '40334': '40.33',
  '40335': '40.33',
  '40336': '40.34',
  '40337': '40.34',
  '40338': '40.34',
  '40339': '40.34',
  '40340': '40.34',
  '40341': '40.34',
  '40342': '40.34',
  '40343': '40.34',
  '40344': '40.34',
  '40345': '40.35',
  '40346': '40.35',
  '40347': '40.35',
  '40348': '40.35',
  '40349': '40.35',
  '40350': '40.35',
  '40351': '40.35',
  '40352': '40.35',
  '40353': '40.35',
  '40354': '40.35',
  '40355': '40.35',
  '40356': '40.36',
  '40357': '40.36',
  '40358': '40.36',
  '40359': '40.36',
  '40360': '40.36',
  '40361': '40.36',
  '40362': '40.36',
  '40363': '40.36',
  '40364': '40.36',
  '40365': '40.37',
  '40366': '40.37',
  '40367': '40.37',
  '40368': '40.37',
  '40369': '40.37',
  '40370': '40.37',
  '40371': '40.37',
  '40372': '40.37',
  '40373': '40.37',
  '40374': '40.37',
  '40375': '40.38',
  '40376': '40.38',
  '40377': '40.38',
  '40378': '40.38',
  '40379': '40.38',
  '40380': '40.38',
  '40381': '40.38',
  '40382': '40.38',
  '40383': '40.38',
  '40384': '40.38',
  '40385': '40.38',
  '40386': '40.39',
  '40387': '40.39',
  '40388': '40.39',
  '40389': '40.39',
  '40390': '40.39',
  '40391': '40.39',
  '40392': '40.39',
  '40393': '40.39',
  '40394': '40.39',
  '40395': '40.40',
  '40396': '40.40',
  '40397': '40.40',
  '40398': '40.40',
  '40399': '40.40',
  '40400': '40.40',
  '40401': '40.40',
  '40402': '40.40',
  '40403': '40.40',
  '40404': '40.40',
  '40405': '40.40',
  '40406': '40.41',
  '40407': '40.41',
  '40408': '40.41',
  '40409': '40.41',
  '40410': '40.41',
  '40411': '40.41',
  '40412': '40.41',
  '40413': '40.41',
  '40414': '40.41',
  '40415': '40.42',
  '40416': '40.42',
  '40417': '40.42',
  '40418': '40.42',
  '40419': '40.42',
  '40420': '40.42',
  '40421': '40.42',
  '40422': '40.42',
  '40423': '40.42',
  '40424': '40.42',
  '40425': '40.42',
  '40426': '40.43',
  '40427': '40.43',
  '40428': '40.43',
  '40429': '40.43',
  '40430': '40.43',
  '40431': '40.43',
  '40432': '40.43',
  '40433': '40.43',
  '40434': '40.43',
  '40435': '40.44',
  '40436': '40.44',
  '40437': '40.44',
  '40438': '40.44',
  '40439': '40.44',
  '40440': '40.44',
  '40441': '40.44',
  '40442': '40.44',
  '40443': '40.44',
  '40444': '40.44',
  '40445': '40.44',
  '40446': '40.45',
  '40447': '40.45',
  '40448': '40.45',
  '40449': '40.45',
  '40450': '40.45',
  '40451': '40.45',
  '40452': '40.45',
  '40453': '40.45',
  '40454': '40.45',
  '40455': '40.46',
  '40456': '40.46',
  '40457': '40.46',
  '40458': '40.46',
  '40459': '40.46',
  '40460': '40.46',
  '40461': '40.46',
  '40462': '40.46',
  '40463': '40.46',
  '40464': '40.46',
  '40465': '40.47',
  '40466': '40.47',
  '40467': '40.47',
  '40468': '40.47',
  '40469': '40.47',
  '40470': '40.47',
  '40471': '40.47',
  '40472': '40.47',
  '40473': '40.47',
  '40474': '40.47',
  '40475': '40.47',
  '40476': '40.48',
  '40477': '40.48',
  '40478': '40.48',
  '40479': '40.48',
  '40480': '40.48',
  '40481': '40.48',
  '40482': '40.48',
  '40483': '40.48',
  '40484': '40.48',
  '40485': '40.49',
  '40486': '40.49',
  '40487': '40.49',
  '40488': '40.49',
  '40489': '40.49',
  '40490': '40.49',
  '40491': '40.49',
  '40492': '40.49',
  '40493': '40.49',
  '40494': '40.49',
  '40495': '40.49',
  '40496': '40.50',
  '40497': '40.50',
  '40498': '40.50',
  '40499': '40.50',
  '40500': '40.50',
  '40501': '40.50',
  '40502': '40.50',
  '40503': '40.50',
  '40504': '40.50',
  '40505': '40.51',
  '40506': '40.51',
  '40507': '40.51',
  '40508': '40.51',
  '40509': '40.51',
  '40510': '40.51',
  '40511': '40.51',
  '40512': '40.51',
  '40513': '40.51',
  '40514': '40.51',
  '40515': '40.51',
  '40516': '40.52',
  '40517': '40.52',
  '40518': '40.52',
  '40519': '40.52',
  '40520': '40.52',
  '40521': '40.52',
  '40522': '40.52',
  '40523': '40.52',
  '40524': '40.52',
  '40525': '40.53',
  '40526': '40.53',
  '40527': '40.53',
  '40528': '40.53',
  '40529': '40.53',
  '40530': '40.53',
  '40531': '40.53',
  '40532': '40.53',
  '40533': '40.53',
  '40534': '40.53',
  '40535': '40.53',
  '40536': '40.54',
  '40537': '40.54',
  '40538': '40.54',
  '40539': '40.54',
  '40540': '40.54',
  '40541': '40.54',
  '40542': '40.54',
  '40543': '40.54',
  '40544': '40.54',
  '40545': '40.54',
  '40546': '40.55',
  '40547': '40.55',
  '40548': '40.55',
  '40549': '40.55',
  '40550': '40.55',
  '40551': '40.55',
  '40552': '40.55',
  '40553': '40.55',
  '40554': '40.55',
  '40555': '40.56',
  '40556': '40.56',
  '40557': '40.56',
  '40558': '40.56',
  '40559': '40.56',
  '40560': '40.56',
  '40561': '40.56',
  '40562': '40.56',
  '40563': '40.56',
  '40564': '40.56',
  '40565': '40.56',
  '40566': '40.57',
  '40567': '40.57',
  '40568': '40.57',
  '40569': '40.57',
  '40570': '40.57',
  '40571': '40.57',
  '40572': '40.57',
  '40573': '40.57',
  '40574': '40.57',
  '40575': '40.58',
  '40576': '40.58',
  '40577': '40.58',
  '40578': '40.58',
  '40579': '40.58',
  '40580': '40.58',
  '40581': '40.58',
  '40582': '40.58',
  '40583': '40.58',
  '40584': '40.58',
  '40585': '40.58',
  '40586': '40.59',
  '40587': '40.59',
  '40588': '40.59',
  '40589': '40.59',
  '40590': '40.59',
  '40591': '40.59',
  '40592': '40.59',
  '40593': '40.59',
  '40594': '40.59',
  '40595': '40.60',
  '40596': '40.60',
  '40597': '40.60',
  '40598': '40.60',
  '40599': '40.60',
  '40600': '40.60',
  '40601': '40.60',
  '40602': '40.60',
  '40603': '40.60',
  '40604': '40.60',
  '40605': '40.60',
  '40606': '40.61',
  '40607': '40.61',
  '40608': '40.61',
  '40609': '40.61',
  '40610': '40.61',
  '40611': '40.61',
  '40612': '40.61',
  '40613': '40.61',
  '40614': '40.61',
  '40615': '40.62',
  '40616': '40.62',
  '40617': '40.62',
  '40618': '40.62',
  '40619': '40.62',
  '40620': '40.62',
  '40621': '40.62',
  '40622': '40.62',
  '40623': '40.62',
  '40624': '40.62',
  '40625': '40.62',
  '40626': '40.63',
  '40627': '40.63',
  '40628': '40.63',
  '40629': '40.63',
  '40630': '40.63',
  '40631': '40.63',
  '40632': '40.63',
  '40633': '40.63',
  '40634': '40.63',
  '40635': '40.63',
  '40636': '40.64',
  '40637': '40.64',
  '40638': '40.64',
  '40639': '40.64',
  '40640': '40.64',
  '40641': '40.64',
  '40642': '40.64',
  '40643': '40.64',
  '40644': '40.64',
  '40645': '40.65',
  '40646': '40.65',
  '40647': '40.65',
  '40648': '40.65',
  '40649': '40.65',
  '40650': '40.65',
  '40651': '40.65',
  '40652': '40.65',
  '40653': '40.65',
  '40654': '40.65',
  '40655': '40.65',
  '40656': '40.66',
  '40657': '40.66',
  '40658': '40.66',
  '40659': '40.66',
  '40660': '40.66',
  '40661': '40.66',
  '40662': '40.66',
  '40663': '40.66',
  '40664': '40.66',
  '40665': '40.67',
  '40666': '40.67',
  '40667': '40.67',
  '40668': '40.67',
  '40669': '40.67',
  '40670': '40.67',
  '40671': '40.67',
  '40672': '40.67',
  '40673': '40.67',
  '40674': '40.67',
  '40675': '40.67',
  '40676': '40.68',
  '40677': '40.68',
  '40678': '40.68',
  '40679': '40.68',
  '40680': '40.68',
  '40681': '40.68',
  '40682': '40.68',
  '40683': '40.68',
  '40684': '40.68',
  '40685': '40.69',
  '40686': '40.69',
  '40687': '40.69',
  '40688': '40.69',
  '40689': '40.69',
  '40690': '40.69',
  '40691': '40.69',
  '40692': '40.69',
  '40693': '40.69',
  '40694': '40.69',
  '40695': '40.69',
  '40696': '40.70',
  '40697': '40.70',
  '40698': '40.70',
  '40699': '40.70',
  '40700': '40.70',
  '40701': '40.70',
  '40702': '40.70',
  '40703': '40.70',
  '40704': '40.70',
  '40705': '40.71',
  '40706': '40.71',
  '40707': '40.71',
  '40708': '40.71',
  '40709': '40.71',
  '40710': '40.71',
  '40711': '40.71',
  '40712': '40.71',
  '40713': '40.71',
  '40714': '40.71',
  '40715': '40.72',
  '40716': '40.72',
  '40717': '40.72',
  '40718': '40.72',
  '40719': '40.72',
  '40720': '40.72',
  '40721': '40.72',
  '40722': '40.72',
  '40723': '40.72',
  '40724': '40.72',
  '40725': '40.72',
  '40726': '40.73',
  '40727': '40.73',
  '40728': '40.73',
  '40729': '40.73',
  '40730': '40.73',
  '40731': '40.73',
  '40732': '40.73',
  '40733': '40.73',
  '40734': '40.73',
  '40735': '40.74',
  '40736': '40.74',
  '40737': '40.74',
  '40738': '40.74',
  '40739': '40.74',
  '40740': '40.74',
  '40741': '40.74',
  '40742': '40.74',
  '40743': '40.74',
  '40744': '40.74',
  '40745': '40.74',
  '40746': '40.75',
  '40747': '40.75',
  '40748': '40.75',
  '40749': '40.75',
  '40750': '40.75',
  '40751': '40.75',
  '40752': '40.75',
  '40753': '40.75',
  '40754': '40.75',
  '40755': '40.76',
  '40756': '40.76',
  '40757': '40.76',
  '40758': '40.76',
  '40759': '40.76',
  '40760': '40.76',
  '40761': '40.76',
  '40762': '40.76',
  '40763': '40.76',
  '40764': '40.76',
  '40765': '40.76',
  '40766': '40.77',
  '40767': '40.77',
  '40768': '40.77',
  '40769': '40.77',
  '40770': '40.77',
  '40771': '40.77',
  '40772': '40.77',
  '40773': '40.77',
  '40774': '40.77',
  '40775': '40.78',
  '40776': '40.78',
  '40777': '40.78',
  '40778': '40.78',
  '40779': '40.78',
  '40780': '40.78',
  '40781': '40.78',
  '40782': '40.78',
  '40783': '40.78',
  '40784': '40.78',
  '40785': '40.78',
  '40786': '40.79',
  '40787': '40.79',
  '40788': '40.79',
  '40789': '40.79',
  '40790': '40.79',
  '40791': '40.79',
  '40792': '40.79',
  '40793': '40.79',
  '40794': '40.79',
  '40795': '40.79',
  '40796': '40.80',
  '40797': '40.80',
  '40798': '40.80',
  '40799': '40.80',
  '40800': '40.80',
  '40801': '40.80',
  '40802': '40.80',
  '40803': '40.80',
  '40804': '40.80',
  '40805': '40.81',
  '40806': '40.81',
  '40807': '40.81',
  '40808': '40.81',
  '40809': '40.81',
  '40810': '40.81',
  '40811': '40.81',
  '40812': '40.81',
  '40813': '40.81',
  '40814': '40.81',
  '40815': '40.81',
  '40816': '40.82',
  '40817': '40.82',
  '40818': '40.82',
  '40819': '40.82',
  '40820': '40.82',
  '40821': '40.82',
  '40822': '40.82',
  '40823': '40.82',
  '40824': '40.82',
  '40825': '40.83',
  '40826': '40.83',
  '40827': '40.83',
  '40828': '40.83',
  '40829': '40.83',
  '40830': '40.83',
  '40831': '40.83',
  '40832': '40.83',
  '40833': '40.83',
  '40834': '40.83',
  '40835': '40.83',
  '40836': '40.84',
  '40837': '40.84',
  '40838': '40.84',
  '40839': '40.84',
  '40840': '40.84',
  '40841': '40.84',
  '40842': '40.84',
  '40843': '40.84',
  '40844': '40.84',
  '40845': '40.85',
  '40846': '40.85',
  '40847': '40.85',
  '40848': '40.85',
  '40849': '40.85',
  '40850': '40.85',
  '40851': '40.85',
  '40852': '40.85',
  '40853': '40.85',
  '40854': '40.85',
  '40855': '40.85',
  '40856': '40.86',
  '40857': '40.86',
  '40858': '40.86',
  '40859': '40.86',
  '40860': '40.86',
  '40861': '40.86',
  '40862': '40.86',
  '40863': '40.86',
  '40864': '40.86',
  '40865': '40.87',
  '40866': '40.87',
  '40867': '40.87',
  '40868': '40.87',
  '40869': '40.87',
  '40870': '40.87',
  '40871': '40.87',
  '40872': '40.87',
  '40873': '40.87',
  '40874': '40.87',
  '40875': '40.88',
  '40876': '40.88',
  '40877': '40.88',
  '40878': '40.88',
  '40879': '40.88',
  '40880': '40.88',
  '40881': '40.88',
  '40882': '40.88',
  '40883': '40.88',
  '40884': '40.88',
  '40885': '40.88',
  '40886': '40.89',
  '40887': '40.89',
  '40888': '40.89',
  '40889': '40.89',
  '40890': '40.89',
  '40891': '40.89',
  '40892': '40.89',
  '40893': '40.89',
  '40894': '40.89',
  '40895': '40.90',
  '40896': '40.90',
  '40897': '40.90',
  '40898': '40.90',
  '40899': '40.90',
  '40900': '40.90',
  '40901': '40.90',
  '40902': '40.90',
  '40903': '40.90',
  '40904': '40.90',
  '40905': '40.90',
  '40906': '40.91',
  '40907': '40.91',
  '40908': '40.91',
  '40909': '40.91',
  '40910': '40.91',
  '40911': '40.91',
  '40912': '40.91',
  '40913': '40.91',
  '40914': '40.91',
  '40915': '40.92',
  '40916': '40.92',
  '40917': '40.92',
  '40918': '40.92',
  '40919': '40.92',
  '40920': '40.92',
  '40921': '40.92',
  '40922': '40.92',
  '40923': '40.92',
  '40924': '40.92',
  '40925': '40.92',
  '40926': '40.93',
  '40927': '40.93',
  '40928': '40.93',
  '40929': '40.93',
  '40930': '40.93',
  '40931': '40.93',
  '40932': '40.93',
  '40933': '40.93',
  '40934': '40.93',
  '40935': '40.94',
  '40936': '40.94',
  '40937': '40.94',
  '40938': '40.94',
  '40939': '40.94',
  '40940': '40.94',
  '40941': '40.94',
  '40942': '40.94',
  '40943': '40.94',
  '40944': '40.94',
  '40945': '40.94',
  '40946': '40.95',
  '40947': '40.95',
  '40948': '40.95',
  '40949': '40.95',
  '40950': '40.95',
  '40951': '40.95',
  '40952': '40.95',
  '40953': '40.95',
  '40954': '40.95',
  '40955': '40.96',
  '40956': '40.96',
  '40957': '40.96',
  '40958': '40.96',
  '40959': '40.96',
  '40960': '40.96',
  '40961': '40.96',
  '40962': '40.96',
  '40963': '40.96',
  '40964': '40.96',
  '40965': '40.97',
  '40966': '40.97',
  '40967': '40.97',
  '40968': '40.97',
  '40969': '40.97',
  '40970': '40.97',
  '40971': '40.97',
  '40972': '40.97',
  '40973': '40.97',
  '40974': '40.97',
  '40975': '40.97',
  '40976': '40.98',
  '40977': '40.98',
  '40978': '40.98',
  '40979': '40.98',
  '40980': '40.98',
  '40981': '40.98',
  '40982': '40.98',
  '40983': '40.98',
  '40984': '40.98',
  '40985': '40.99',
  '40986': '40.99',
  '40987': '40.99',
  '40988': '40.99',
  '40989': '40.99',
  '40990': '40.99',
  '40991': '40.99',
  '40992': '40.99',
  '40993': '40.99',
  '40994': '40.99',
  '40995': '40.99',
  '40996': '41.00',
  '40997': '41.00',
  '40998': '41.00',
  '40999': '41.00',
  '41000': '41.00',
  '41001': '41.00',
  '41002': '41.00',
  '41003': '41.00',
  '41004': '41.00',
  '41005': '41.01',
  '41006': '41.01',
  '41007': '41.01',
  '41008': '41.01',
  '41009': '41.01',
  '41010': '41.01',
  '41011': '41.01',
  '41012': '41.01',
  '41013': '41.01',
  '41014': '41.01',
  '41015': '41.01',
  '41016': '41.02',
  '41017': '41.02',
  '41018': '41.02',
  '41019': '41.02',
  '41020': '41.02',
  '41021': '41.02',
  '41022': '41.02',
  '41023': '41.02',
  '41024': '41.02',
  '41025': '41.03',
  '41026': '41.03',
  '41027': '41.03',
  '41028': '41.03',
  '41029': '41.03',
  '41030': '41.03',
  '41031': '41.03',
  '41032': '41.03',
  '41033': '41.03',
  '41034': '41.03',
  '41035': '41.03',
  '41036': '41.04',
  '41037': '41.04',
  '41038': '41.04',
  '41039': '41.04',
  '41040': '41.04',
  '41041': '41.04',
  '41042': '41.04',
  '41043': '41.04',
  '41044': '41.04',
  '41045': '41.04',
  '41046': '41.05',
  '41047': '41.05',
  '41048': '41.05',
  '41049': '41.05',
  '41050': '41.05',
  '41051': '41.05',
  '41052': '41.05',
  '41053': '41.05',
  '41054': '41.05',
  '41055': '41.06',
  '41056': '41.06',
  '41057': '41.06',
  '41058': '41.06',
  '41059': '41.06',
  '41060': '41.06',
  '41061': '41.06',
  '41062': '41.06',
  '41063': '41.06',
  '41064': '41.06',
  '41065': '41.06',
  '41066': '41.07',
  '41067': '41.07',
  '41068': '41.07',
  '41069': '41.07',
  '41070': '41.07',
  '41071': '41.07',
  '41072': '41.07',
  '41073': '41.07',
  '41074': '41.07',
  '41075': '41.08',
  '41076': '41.08',
  '41077': '41.08',
  '41078': '41.08',
  '41079': '41.08',
  '41080': '41.08',
  '41081': '41.08',
  '41082': '41.08',
  '41083': '41.08',
  '41084': '41.08',
  '41085': '41.08',
  '41086': '41.09',
  '41087': '41.09',
  '41088': '41.09',
  '41089': '41.09',
  '41090': '41.09',
  '41091': '41.09',
  '41092': '41.09',
  '41093': '41.09',
  '41094': '41.09',
  '41095': '41.10',
  '41096': '41.10',
  '41097': '41.10',
  '41098': '41.10',
  '41099': '41.10',
  '41100': '41.10',
  '41101': '41.10',
  '41102': '41.10',
  '41103': '41.10',
  '41104': '41.10',
  '41105': '41.10',
  '41106': '41.11',
  '41107': '41.11',
  '41108': '41.11',
  '41109': '41.11',
  '41110': '41.11',
  '41111': '41.11',
  '41112': '41.11',
  '41113': '41.11',
  '41114': '41.11',
  '41115': '41.12',
  '41116': '41.12',
  '41117': '41.12',
  '41118': '41.12',
  '41119': '41.12',
  '41120': '41.12',
  '41121': '41.12',
  '41122': '41.12',
  '41123': '41.12',
  '41124': '41.12',
  '41125': '41.12',
  '41126': '41.13',
  '41127': '41.13',
  '41128': '41.13',
  '41129': '41.13',
  '41130': '41.13',
  '41131': '41.13',
  '41132': '41.13',
  '41133': '41.13',
  '41134': '41.13',
  '41135': '41.13',
  '41136': '41.14',
  '41137': '41.14',
  '41138': '41.14',
  '41139': '41.14',
  '41140': '41.14',
  '41141': '41.14',
  '41142': '41.14',
  '41143': '41.14',
  '41144': '41.14',
  '41145': '41.15',
  '41146': '41.15',
  '41147': '41.15',
  '41148': '41.15',
  '41149': '41.15',
  '41150': '41.15',
  '41151': '41.15',
  '41152': '41.15',
  '41153': '41.15',
  '41154': '41.15',
  '41155': '41.15',
  '41156': '41.16',
  '41157': '41.16',
  '41158': '41.16',
  '41159': '41.16',
  '41160': '41.16',
  '41161': '41.16',
  '41162': '41.16',
  '41163': '41.16',
  '41164': '41.16',
  '41165': '41.17',
  '41166': '41.17',
  '41167': '41.17',
  '41168': '41.17',
  '41169': '41.17',
  '41170': '41.17',
  '41171': '41.17',
  '41172': '41.17',
  '41173': '41.17',
  '41174': '41.17',
  '41175': '41.17',
  '41176': '41.18',
  '41177': '41.18',
  '41178': '41.18',
  '41179': '41.18',
  '41180': '41.18',
  '41181': '41.18',
  '41182': '41.18',
  '41183': '41.18',
  '41184': '41.18',
  '41185': '41.19',
  '41186': '41.19',
  '41187': '41.19',
  '41188': '41.19',
  '41189': '41.19',
  '41190': '41.19',
  '41191': '41.19',
  '41192': '41.19',
  '41193': '41.19',
  '41194': '41.19',
  '41195': '41.19',
  '41196': '41.20',
  '41197': '41.20',
  '41198': '41.20',
  '41199': '41.20',
  '41200': '41.20',
  '41201': '41.20',
  '41202': '41.20',
  '41203': '41.20',
  '41204': '41.20',
  '41205': '41.21',
  '41206': '41.21',
  '41207': '41.21',
  '41208': '41.21',
  '41209': '41.21',
  '41210': '41.21',
  '41211': '41.21',
  '41212': '41.21',
  '41213': '41.21',
  '41214': '41.21',
  '41215': '41.22',
  '41216': '41.22',
  '41217': '41.22',
  '41218': '41.22',
  '41219': '41.22',
  '41220': '41.22',
  '41221': '41.22',
  '41222': '41.22',
  '41223': '41.22',
  '41224': '41.22',
  '41225': '41.22',
  '41226': '41.23',
  '41227': '41.23',
  '41228': '41.23',
  '41229': '41.23',
  '41230': '41.23',
  '41231': '41.23',
  '41232': '41.23',
  '41233': '41.23',
  '41234': '41.23',
  '41235': '41.24',
  '41236': '41.24',
  '41237': '41.24',
  '41238': '41.24',
  '41239': '41.24',
  '41240': '41.24',
  '41241': '41.24',
  '41242': '41.24',
  '41243': '41.24',
  '41244': '41.24',
  '41245': '41.24',
  '41246': '41.25',
  '41247': '41.25',
  '41248': '41.25',
  '41249': '41.25',
  '41250': '41.25',
  '41251': '41.25',
  '41252': '41.25',
  '41253': '41.25',
  '41254': '41.25',
  '41255': '41.26',
  '41256': '41.26',
  '41257': '41.26',
  '41258': '41.26',
  '41259': '41.26',
  '41260': '41.26',
  '41261': '41.26',
  '41262': '41.26',
  '41263': '41.26',
  '41264': '41.26',
  '41265': '41.26',
  '41266': '41.27',
  '41267': '41.27',
  '41268': '41.27',
  '41269': '41.27',
  '41270': '41.27',
  '41271': '41.27',
  '41272': '41.27',
  '41273': '41.27',
  '41274': '41.27',
  '41275': '41.28',
  '41276': '41.28',
  '41277': '41.28',
  '41278': '41.28',
  '41279': '41.28',
  '41280': '41.28',
  '41281': '41.28',
  '41282': '41.28',
  '41283': '41.28',
  '41284': '41.28',
  '41285': '41.28',
  '41286': '41.29',
  '41287': '41.29',
  '41288': '41.29',
  '41289': '41.29',
  '41290': '41.29',
  '41291': '41.29',
  '41292': '41.29',
  '41293': '41.29',
  '41294': '41.29',
  '41295': '41.29',
  '41296': '41.30',
  '41297': '41.30',
  '41298': '41.30',
  '41299': '41.30',
  '41300': '41.30',
  '41301': '41.30',
  '41302': '41.30',
  '41303': '41.30',
  '41304': '41.30',
  '41305': '41.31',
  '41306': '41.31',
  '41307': '41.31',
  '41308': '41.31',
  '41309': '41.31',
  '41310': '41.31',
  '41311': '41.31',
  '41312': '41.31',
  '41313': '41.31',
  '41314': '41.31',
  '41315': '41.31',
  '41316': '41.32',
  '41317': '41.32',
  '41318': '41.32',
  '41319': '41.32',
  '41320': '41.32',
  '41321': '41.32',
  '41322': '41.32',
  '41323': '41.32',
  '41324': '41.32',
  '41325': '41.33',
  '41326': '41.33',
  '41327': '41.33',
  '41328': '41.33',
  '41329': '41.33',
  '41330': '41.33',
  '41331': '41.33',
  '41332': '41.33',
  '41333': '41.33',
  '41334': '41.33',
  '41335': '41.33',
  '41336': '41.34',
  '41337': '41.34',
  '41338': '41.34',
  '41339': '41.34',
  '41340': '41.34',
  '41341': '41.34',
  '41342': '41.34',
  '41343': '41.34',
  '41344': '41.34',
  '41345': '41.35',
  '41346': '41.35',
  '41347': '41.35',
  '41348': '41.35',
  '41349': '41.35',
  '41350': '41.35',
  '41351': '41.35',
  '41352': '41.35',
  '41353': '41.35',
  '41354': '41.35',
  '41355': '41.35',
  '41356': '41.36',
  '41357': '41.36',
  '41358': '41.36',
  '41359': '41.36',
  '41360': '41.36',
  '41361': '41.36',
  '41362': '41.36',
  '41363': '41.36',
  '41364': '41.36',
  '41365': '41.37',
  '41366': '41.37',
  '41367': '41.37',
  '41368': '41.37',
  '41369': '41.37',
  '41370': '41.37',
  '41371': '41.37',
  '41372': '41.37',
  '41373': '41.37',
  '41374': '41.37',
  '41375': '41.38',
  '41376': '41.38',
  '41377': '41.38',
  '41378': '41.38',
  '41379': '41.38',
  '41380': '41.38',
  '41381': '41.38',
  '41382': '41.38',
  '41383': '41.38',
  '41384': '41.38',
  '41385': '41.38',
  '41386': '41.39',
  '41387': '41.39',
  '41388': '41.39',
  '41389': '41.39',
  '41390': '41.39',
  '41391': '41.39',
  '41392': '41.39',
  '41393': '41.39',
  '41394': '41.39',
  '41395': '41.40',
  '41396': '41.40',
  '41397': '41.40',
  '41398': '41.40',
  '41399': '41.40',
  '41400': '41.40',
  '41401': '41.40',
  '41402': '41.40',
  '41403': '41.40',
  '41404': '41.40',
  '41405': '41.40',
  '41406': '41.41',
  '41407': '41.41',
  '41408': '41.41',
  '41409': '41.41',
  '41410': '41.41',
  '41411': '41.41',
  '41412': '41.41',
  '41413': '41.41',
  '41414': '41.41',
  '41415': '41.42',
  '41416': '41.42',
  '41417': '41.42',
  '41418': '41.42',
  '41419': '41.42',
  '41420': '41.42',
  '41421': '41.42',
  '41422': '41.42',
  '41423': '41.42',
  '41424': '41.42',
  '41425': '41.42',
  '41426': '41.43',
  '41427': '41.43',
  '41428': '41.43',
  '41429': '41.43',
  '41430': '41.43',
  '41431': '41.43',
  '41432': '41.43',
  '41433': '41.43',
  '41434': '41.43',
  '41435': '41.44',
  '41436': '41.44',
  '41437': '41.44',
  '41438': '41.44',
  '41439': '41.44',
  '41440': '41.44',
  '41441': '41.44',
  '41442': '41.44',
  '41443': '41.44',
  '41444': '41.44',
  '41445': '41.44',
  '41446': '41.45',
  '41447': '41.45',
  '41448': '41.45',
  '41449': '41.45',
  '41450': '41.45',
  '41451': '41.45',
  '41452': '41.45',
  '41453': '41.45',
  '41454': '41.45',
  '41455': '41.46',
  '41456': '41.46',
  '41457': '41.46',
  '41458': '41.46',
  '41459': '41.46',
  '41460': '41.46',
  '41461': '41.46',
  '41462': '41.46',
  '41463': '41.46',
  '41464': '41.46',
  '41465': '41.47',
  '41466': '41.47',
  '41467': '41.47',
  '41468': '41.47',
  '41469': '41.47',
  '41470': '41.47',
  '41471': '41.47',
  '41472': '41.47',
  '41473': '41.47',
  '41474': '41.47',
  '41475': '41.47',
  '41476': '41.48',
  '41477': '41.48',
  '41478': '41.48',
  '41479': '41.48',
  '41480': '41.48',
  '41481': '41.48',
  '41482': '41.48',
  '41483': '41.48',
  '41484': '41.48',
  '41485': '41.49',
  '41486': '41.49',
  '41487': '41.49',
  '41488': '41.49',
  '41489': '41.49',
  '41490': '41.49',
  '41491': '41.49',
  '41492': '41.49',
  '41493': '41.49',
  '41494': '41.49',
  '41495': '41.49',
  '41496': '41.50',
  '41497': '41.50',
  '41498': '41.50',
  '41499': '41.50',
  '41500': '41.50',
  '41501': '41.50',
  '41502': '41.50',
  '41503': '41.50',
  '41504': '41.50',
  '41505': '41.51',
  '41506': '41.51',
  '41507': '41.51',
  '41508': '41.51',
  '41509': '41.51',
  '41510': '41.51',
  '41511': '41.51',
  '41512': '41.51',
  '41513': '41.51',
  '41514': '41.51',
  '41515': '41.51',
  '41516': '41.52',
  '41517': '41.52',
  '41518': '41.52',
  '41519': '41.52',
  '41520': '41.52',
  '41521': '41.52',
  '41522': '41.52',
  '41523': '41.52',
  '41524': '41.52',
  '41525': '41.53',
  '41526': '41.53',
  '41527': '41.53',
  '41528': '41.53',
  '41529': '41.53',
  '41530': '41.53',
  '41531': '41.53',
  '41532': '41.53',
  '41533': '41.53',
  '41534': '41.53',
  '41535': '41.53',
  '41536': '41.54',
  '41537': '41.54',
  '41538': '41.54',
  '41539': '41.54',
  '41540': '41.54',
  '41541': '41.54',
  '41542': '41.54',
  '41543': '41.54',
  '41544': '41.54',
  '41545': '41.54',
  '41546': '41.55',
  '41547': '41.55',
  '41548': '41.55',
  '41549': '41.55',
  '41550': '41.55',
  '41551': '41.55',
  '41552': '41.55',
  '41553': '41.55',
  '41554': '41.55',
  '41555': '41.56',
  '41556': '41.56',
  '41557': '41.56',
  '41558': '41.56',
  '41559': '41.56',
  '41560': '41.56',
  '41561': '41.56',
  '41562': '41.56',
  '41563': '41.56',
  '41564': '41.56',
  '41565': '41.56',
  '41566': '41.57',
  '41567': '41.57',
  '41568': '41.57',
  '41569': '41.57',
  '41570': '41.57',
  '41571': '41.57',
  '41572': '41.57',
  '41573': '41.57',
  '41574': '41.57',
  '41575': '41.58',
  '41576': '41.58',
  '41577': '41.58',
  '41578': '41.58',
  '41579': '41.58',
  '41580': '41.58',
  '41581': '41.58',
  '41582': '41.58',
  '41583': '41.58',
  '41584': '41.58',
  '41585': '41.58',
  '41586': '41.59',
  '41587': '41.59',
  '41588': '41.59',
  '41589': '41.59',
  '41590': '41.59',
  '41591': '41.59',
  '41592': '41.59',
  '41593': '41.59',
  '41594': '41.59',
  '41595': '41.60',
  '41596': '41.60',
  '41597': '41.60',
  '41598': '41.60',
  '41599': '41.60',
  '41600': '41.60',
  '41601': '41.60',
  '41602': '41.60',
  '41603': '41.60',
  '41604': '41.60',
  '41605': '41.60',
  '41606': '41.61',
  '41607': '41.61',
  '41608': '41.61',
  '41609': '41.61',
  '41610': '41.61',
  '41611': '41.61',
  '41612': '41.61',
  '41613': '41.61',
  '41614': '41.61',
  '41615': '41.62',
  '41616': '41.62',
  '41617': '41.62',
  '41618': '41.62',
  '41619': '41.62',
  '41620': '41.62',
  '41621': '41.62',
  '41622': '41.62',
  '41623': '41.62',
  '41624': '41.62',
  '41625': '41.62',
  '41626': '41.63',
  '41627': '41.63',
  '41628': '41.63',
  '41629': '41.63',
  '41630': '41.63',
  '41631': '41.63',
  '41632': '41.63',
  '41633': '41.63',
  '41634': '41.63',
  '41635': '41.63',
  '41636': '41.64',
  '41637': '41.64',
  '41638': '41.64',
  '41639': '41.64',
  '41640': '41.64',
  '41641': '41.64',
  '41642': '41.64',
  '41643': '41.64',
  '41644': '41.64',
  '41645': '41.65',
  '41646': '41.65',
  '41647': '41.65',
  '41648': '41.65',
  '41649': '41.65',
  '41650': '41.65',
  '41651': '41.65',
  '41652': '41.65',
  '41653': '41.65',
  '41654': '41.65',
  '41655': '41.65',
  '41656': '41.66',
  '41657': '41.66',
  '41658': '41.66',
  '41659': '41.66',
  '41660': '41.66',
  '41661': '41.66',
  '41662': '41.66',
  '41663': '41.66',
  '41664': '41.66',
  '41665': '41.67',
  '41666': '41.67',
  '41667': '41.67',
  '41668': '41.67',
  '41669': '41.67',
  '41670': '41.67',
  '41671': '41.67',
  '41672': '41.67',
  '41673': '41.67',
  '41674': '41.67',
  '41675': '41.67',
  '41676': '41.68',
  '41677': '41.68',
  '41678': '41.68',
  '41679': '41.68',
  '41680': '41.68',
  '41681': '41.68',
  '41682': '41.68',
  '41683': '41.68',
  '41684': '41.68',
  '41685': '41.69',
  '41686': '41.69',
  '41687': '41.69',
  '41688': '41.69',
  '41689': '41.69',
  '41690': '41.69',
  '41691': '41.69',
  '41692': '41.69',
  '41693': '41.69',
  '41694': '41.69',
  '41695': '41.69',
  '41696': '41.70',
  '41697': '41.70',
  '41698': '41.70',
  '41699': '41.70',
  '41700': '41.70',
  '41701': '41.70',
  '41702': '41.70',
  '41703': '41.70',
  '41704': '41.70',
  '41705': '41.71',
  '41706': '41.71',
  '41707': '41.71',
  '41708': '41.71',
  '41709': '41.71',
  '41710': '41.71',
  '41711': '41.71',
  '41712': '41.71',
  '41713': '41.71',
  '41714': '41.71',
  '41715': '41.72',
  '41716': '41.72',
  '41717': '41.72',
  '41718': '41.72',
  '41719': '41.72',
  '41720': '41.72',
  '41721': '41.72',
  '41722': '41.72',
  '41723': '41.72',
  '41724': '41.72',
  '41725': '41.72',
  '41726': '41.73',
  '41727': '41.73',
  '41728': '41.73',
  '41729': '41.73',
  '41730': '41.73',
  '41731': '41.73',
  '41732': '41.73',
  '41733': '41.73',
  '41734': '41.73',
  '41735': '41.74',
  '41736': '41.74',
  '41737': '41.74',
  '41738': '41.74',
  '41739': '41.74',
  '41740': '41.74',
  '41741': '41.74',
  '41742': '41.74',
  '41743': '41.74',
  '41744': '41.74',
  '41745': '41.74',
  '41746': '41.75',
  '41747': '41.75',
  '41748': '41.75',
  '41749': '41.75',
  '41750': '41.75',
  '41751': '41.75',
  '41752': '41.75',
  '41753': '41.75',
  '41754': '41.75',
  '41755': '41.76',
  '41756': '41.76',
  '41757': '41.76',
  '41758': '41.76',
  '41759': '41.76',
  '41760': '41.76',
  '41761': '41.76',
  '41762': '41.76',
  '41763': '41.76',
  '41764': '41.76',
  '41765': '41.76',
  '41766': '41.77',
  '41767': '41.77',
  '41768': '41.77',
  '41769': '41.77',
  '41770': '41.77',
  '41771': '41.77',
  '41772': '41.77',
  '41773': '41.77',
  '41774': '41.77',
  '41775': '41.78',
  '41776': '41.78',
  '41777': '41.78',
  '41778': '41.78',
  '41779': '41.78',
  '41780': '41.78',
  '41781': '41.78',
  '41782': '41.78',
  '41783': '41.78',
  '41784': '41.78',
  '41785': '41.78',
  '41786': '41.79',
  '41787': '41.79',
  '41788': '41.79',
  '41789': '41.79',
  '41790': '41.79',
  '41791': '41.79',
  '41792': '41.79',
  '41793': '41.79',
  '41794': '41.79',
  '41795': '41.79',
  '41796': '41.80',
  '41797': '41.80',
  '41798': '41.80',
  '41799': '41.80',
  '41800': '41.80',
  '41801': '41.80',
  '41802': '41.80',
  '41803': '41.80',
  '41804': '41.80',
  '41805': '41.81',
  '41806': '41.81',
  '41807': '41.81',
  '41808': '41.81',
  '41809': '41.81',
  '41810': '41.81',
  '41811': '41.81',
  '41812': '41.81',
  '41813': '41.81',
  '41814': '41.81',
  '41815': '41.81',
  '41816': '41.82',
  '41817': '41.82',
  '41818': '41.82',
  '41819': '41.82',
  '41820': '41.82',
  '41821': '41.82',
  '41822': '41.82',
  '41823': '41.82',
  '41824': '41.82',
  '41825': '41.83',
  '41826': '41.83',
  '41827': '41.83',
  '41828': '41.83',
  '41829': '41.83',
  '41830': '41.83',
  '41831': '41.83',
  '41832': '41.83',
  '41833': '41.83',
  '41834': '41.83',
  '41835': '41.83',
  '41836': '41.84',
  '41837': '41.84',
  '41838': '41.84',
  '41839': '41.84',
  '41840': '41.84',
  '41841': '41.84',
  '41842': '41.84',
  '41843': '41.84',
  '41844': '41.84',
  '41845': '41.85',
  '41846': '41.85',
  '41847': '41.85',
  '41848': '41.85',
  '41849': '41.85',
  '41850': '41.85',
  '41851': '41.85',
  '41852': '41.85',
  '41853': '41.85',
  '41854': '41.85',
  '41855': '41.85',
  '41856': '41.86',
  '41857': '41.86',
  '41858': '41.86',
  '41859': '41.86',
  '41860': '41.86',
  '41861': '41.86',
  '41862': '41.86',
  '41863': '41.86',
  '41864': '41.86',
  '41865': '41.87',
  '41866': '41.87',
  '41867': '41.87',
  '41868': '41.87',
  '41869': '41.87',
  '41870': '41.87',
  '41871': '41.87',
  '41872': '41.87',
  '41873': '41.87',
  '41874': '41.87',
  '41875': '41.88',
  '41876': '41.88',
  '41877': '41.88',
  '41878': '41.88',
  '41879': '41.88',
  '41880': '41.88',
  '41881': '41.88',
  '41882': '41.88',
  '41883': '41.88',
  '41884': '41.88',
  '41885': '41.88',
  '41886': '41.89',
  '41887': '41.89',
  '41888': '41.89',
  '41889': '41.89',
  '41890': '41.89',
  '41891': '41.89',
  '41892': '41.89',
  '41893': '41.89',
  '41894': '41.89',
  '41895': '41.90',
  '41896': '41.90',
  '41897': '41.90',
  '41898': '41.90',
  '41899': '41.90',
  '41900': '41.90',
  '41901': '41.90',
  '41902': '41.90',
  '41903': '41.90',
  '41904': '41.90',
  '41905': '41.90',
  '41906': '41.91',
  '41907': '41.91',
  '41908': '41.91',
  '41909': '41.91',
  '41910': '41.91',
  '41911': '41.91',
  '41912': '41.91',
  '41913': '41.91',
  '41914': '41.91',
  '41915': '41.92',
  '41916': '41.92',
  '41917': '41.92',
  '41918': '41.92',
  '41919': '41.92',
  '41920': '41.92',
  '41921': '41.92',
  '41922': '41.92',
  '41923': '41.92',
  '41924': '41.92',
  '41925': '41.92',
  '41926': '41.93',
  '41927': '41.93',
  '41928': '41.93',
  '41929': '41.93',
  '41930': '41.93',
  '41931': '41.93',
  '41932': '41.93',
  '41933': '41.93',
  '41934': '41.93',
  '41935': '41.94',
  '41936': '41.94',
  '41937': '41.94',
  '41938': '41.94',
  '41939': '41.94',
  '41940': '41.94',
  '41941': '41.94',
  '41942': '41.94',
  '41943': '41.94',
  '41944': '41.94',
  '41945': '41.94',
  '41946': '41.95',
  '41947': '41.95',
  '41948': '41.95',
  '41949': '41.95',
  '41950': '41.95',
  '41951': '41.95',
  '41952': '41.95',
  '41953': '41.95',
  '41954': '41.95',
  '41955': '41.96',
  '41956': '41.96',
  '41957': '41.96',
  '41958': '41.96',
  '41959': '41.96',
  '41960': '41.96',
  '41961': '41.96',
  '41962': '41.96',
  '41963': '41.96',
  '41964': '41.96',
  '41965': '41.97',
  '41966': '41.97',
  '41967': '41.97',
  '41968': '41.97',
  '41969': '41.97',
  '41970': '41.97',
  '41971': '41.97',
  '41972': '41.97',
  '41973': '41.97',
  '41974': '41.97',
  '41975': '41.97',
  '41976': '41.98',
  '41977': '41.98',
  '41978': '41.98',
  '41979': '41.98',
  '41980': '41.98',
  '41981': '41.98',
  '41982': '41.98',
  '41983': '41.98',
  '41984': '41.98',
  '41985': '41.99',
  '41986': '41.99',
  '41987': '41.99',
  '41988': '41.99',
  '41989': '41.99',
  '41990': '41.99',
  '41991': '41.99',
  '41992': '41.99',
  '41993': '41.99',
  '41994': '41.99',
  '41995': '41.99',
  '41996': '42.00',
  '41997': '42.00',
  '41998': '42.00',
  '41999': '42.00',
  '42000': '42.00',
}

export const T3_TEMPERATURE_MAP_FAHRENHEIT = {
  '32000': '89.60',
  '32001': '89.60',
  '32002': '89.60',
  '32003': '89.61',
  '32004': '89.61',
  '32005': '89.61',
  '32006': '89.61',
  '32007': '89.61',
  '32008': '89.61',
  '32009': '89.62',
  '32010': '89.62',
  '32011': '89.62',
  '32012': '89.62',
  '32013': '89.62',
  '32014': '89.62',
  '32015': '89.63',
  '32016': '89.63',
  '32017': '89.63',
  '32018': '89.63',
  '32019': '89.63',
  '32020': '89.64',
  '32021': '89.64',
  '32022': '89.64',
  '32023': '89.64',
  '32024': '89.64',
  '32025': '89.64',
  '32026': '89.65',
  '32027': '89.65',
  '32028': '89.65',
  '32029': '89.65',
  '32030': '89.65',
  '32031': '89.65',
  '32032': '89.66',
  '32033': '89.66',
  '32034': '89.66',
  '32035': '89.66',
  '32036': '89.66',
  '32037': '89.67',
  '32038': '89.67',
  '32039': '89.67',
  '32040': '89.67',
  '32041': '89.67',
  '32042': '89.68',
  '32043': '89.68',
  '32044': '89.68',
  '32045': '89.68',
  '32046': '89.68',
  '32047': '89.68',
  '32048': '89.69',
  '32049': '89.69',
  '32050': '89.69',
  '32051': '89.69',
  '32052': '89.69',
  '32053': '89.69',
  '32054': '89.70',
  '32055': '89.70',
  '32056': '89.70',
  '32057': '89.70',
  '32058': '89.70',
  '32059': '89.71',
  '32060': '89.71',
  '32061': '89.71',
  '32062': '89.71',
  '32063': '89.71',
  '32064': '89.71',
  '32065': '89.72',
  '32066': '89.72',
  '32067': '89.72',
  '32068': '89.72',
  '32069': '89.72',
  '32070': '89.73',
  '32071': '89.73',
  '32072': '89.73',
  '32073': '89.73',
  '32074': '89.73',
  '32075': '89.74',
  '32076': '89.74',
  '32077': '89.74',
  '32078': '89.74',
  '32079': '89.74',
  '32080': '89.74',
  '32081': '89.75',
  '32082': '89.75',
  '32083': '89.75',
  '32084': '89.75',
  '32085': '89.75',
  '32086': '89.75',
  '32087': '89.76',
  '32088': '89.76',
  '32089': '89.76',
  '32090': '89.76',
  '32091': '89.76',
  '32092': '89.76',
  '32093': '89.77',
  '32094': '89.77',
  '32095': '89.77',
  '32096': '89.77',
  '32097': '89.77',
  '32098': '89.78',
  '32099': '89.78',
  '32100': '89.78',
  '32101': '89.78',
  '32102': '89.78',
  '32103': '89.79',
  '32104': '89.79',
  '32105': '89.79',
  '32106': '89.79',
  '32107': '89.79',
  '32108': '89.79',
  '32109': '89.80',
  '32110': '89.80',
  '32111': '89.80',
  '32112': '89.80',
  '32113': '89.80',
  '32114': '89.81',
  '32115': '89.81',
  '32116': '89.81',
  '32117': '89.81',
  '32118': '89.81',
  '32119': '89.81',
  '32120': '89.82',
  '32121': '89.82',
  '32122': '89.82',
  '32123': '89.82',
  '32124': '89.82',
  '32125': '89.82',
  '32126': '89.83',
  '32127': '89.83',
  '32128': '89.83',
  '32129': '89.83',
  '32130': '89.83',
  '32131': '89.83',
  '32132': '89.84',
  '32133': '89.84',
  '32134': '89.84',
  '32135': '89.84',
  '32136': '89.84',
  '32137': '89.85',
  '32138': '89.85',
  '32139': '89.85',
  '32140': '89.85',
  '32141': '89.85',
  '32142': '89.86',
  '32143': '89.86',
  '32144': '89.86',
  '32145': '89.86',
  '32146': '89.86',
  '32147': '89.86',
  '32148': '89.87',
  '32149': '89.87',
  '32150': '89.87',
  '32151': '89.87',
  '32152': '89.87',
  '32153': '89.88',
  '32154': '89.88',
  '32155': '89.88',
  '32156': '89.88',
  '32157': '89.88',
  '32158': '89.88',
  '32159': '89.89',
  '32160': '89.89',
  '32161': '89.89',
  '32162': '89.89',
  '32163': '89.89',
  '32164': '89.89',
  '32165': '89.90',
  '32166': '89.90',
  '32167': '89.90',
  '32168': '89.90',
  '32169': '89.90',
  '32170': '89.91',
  '32171': '89.91',
  '32172': '89.91',
  '32173': '89.91',
  '32174': '89.91',
  '32175': '89.92',
  '32176': '89.92',
  '32177': '89.92',
  '32178': '89.92',
  '32179': '89.92',
  '32180': '89.92',
  '32181': '89.93',
  '32182': '89.93',
  '32183': '89.93',
  '32184': '89.93',
  '32185': '89.93',
  '32186': '89.93',
  '32187': '89.94',
  '32188': '89.94',
  '32189': '89.94',
  '32190': '89.94',
  '32191': '89.94',
  '32192': '89.94',
  '32193': '89.95',
  '32194': '89.95',
  '32195': '89.95',
  '32196': '89.95',
  '32197': '89.95',
  '32198': '89.96',
  '32199': '89.96',
  '32200': '89.96',
  '32201': '89.96',
  '32202': '89.96',
  '32203': '89.96',
  '32204': '89.97',
  '32205': '89.97',
  '32206': '89.97',
  '32207': '89.97',
  '32208': '89.97',
  '32209': '89.98',
  '32210': '89.98',
  '32211': '89.98',
  '32212': '89.98',
  '32213': '89.98',
  '32214': '89.99',
  '32215': '89.99',
  '32216': '89.99',
  '32217': '89.99',
  '32218': '89.99',
  '32219': '89.99',
  '32220': '90.00',
  '32221': '90.00',
  '32222': '90.00',
  '32223': '90.00',
  '32224': '90.00',
  '32225': '90.00',
  '32226': '90.01',
  '32227': '90.01',
  '32228': '90.01',
  '32229': '90.01',
  '32230': '90.01',
  '32231': '90.01',
  '32232': '90.02',
  '32233': '90.02',
  '32234': '90.02',
  '32235': '90.02',
  '32236': '90.02',
  '32237': '90.03',
  '32238': '90.03',
  '32239': '90.03',
  '32240': '90.03',
  '32241': '90.03',
  '32242': '90.04',
  '32243': '90.04',
  '32244': '90.04',
  '32245': '90.04',
  '32246': '90.04',
  '32247': '90.04',
  '32248': '90.05',
  '32249': '90.05',
  '32250': '90.05',
  '32251': '90.05',
  '32252': '90.05',
  '32253': '90.06',
  '32254': '90.06',
  '32255': '90.06',
  '32256': '90.06',
  '32257': '90.06',
  '32258': '90.06',
  '32259': '90.07',
  '32260': '90.07',
  '32261': '90.07',
  '32262': '90.07',
  '32263': '90.07',
  '32264': '90.07',
  '32265': '90.08',
  '32266': '90.08',
  '32267': '90.08',
  '32268': '90.08',
  '32269': '90.08',
  '32270': '90.09',
  '32271': '90.09',
  '32272': '90.09',
  '32273': '90.09',
  '32274': '90.09',
  '32275': '90.10',
  '32276': '90.10',
  '32277': '90.10',
  '32278': '90.10',
  '32279': '90.10',
  '32280': '90.10',
  '32281': '90.11',
  '32282': '90.11',
  '32283': '90.11',
  '32284': '90.11',
  '32285': '90.11',
  '32286': '90.11',
  '32287': '90.12',
  '32288': '90.12',
  '32289': '90.12',
  '32290': '90.12',
  '32291': '90.12',
  '32292': '90.12',
  '32293': '90.13',
  '32294': '90.13',
  '32295': '90.13',
  '32296': '90.13',
  '32297': '90.13',
  '32298': '90.14',
  '32299': '90.14',
  '32300': '90.14',
  '32301': '90.14',
  '32302': '90.14',
  '32303': '90.14',
  '32304': '90.15',
  '32305': '90.15',
  '32306': '90.15',
  '32307': '90.15',
  '32308': '90.15',
  '32309': '90.16',
  '32310': '90.16',
  '32311': '90.16',
  '32312': '90.16',
  '32313': '90.16',
  '32314': '90.17',
  '32315': '90.17',
  '32316': '90.17',
  '32317': '90.17',
  '32318': '90.17',
  '32319': '90.17',
  '32320': '90.18',
  '32321': '90.18',
  '32322': '90.18',
  '32323': '90.18',
  '32324': '90.18',
  '32325': '90.18',
  '32326': '90.19',
  '32327': '90.19',
  '32328': '90.19',
  '32329': '90.19',
  '32330': '90.19',
  '32331': '90.19',
  '32332': '90.20',
  '32333': '90.20',
  '32334': '90.20',
  '32335': '90.20',
  '32336': '90.20',
  '32337': '90.21',
  '32338': '90.21',
  '32339': '90.21',
  '32340': '90.21',
  '32341': '90.21',
  '32342': '90.21',
  '32343': '90.22',
  '32344': '90.22',
  '32345': '90.22',
  '32346': '90.22',
  '32347': '90.22',
  '32348': '90.23',
  '32349': '90.23',
  '32350': '90.23',
  '32351': '90.23',
  '32352': '90.23',
  '32353': '90.24',
  '32354': '90.24',
  '32355': '90.24',
  '32356': '90.24',
  '32357': '90.24',
  '32358': '90.24',
  '32359': '90.25',
  '32360': '90.25',
  '32361': '90.25',
  '32362': '90.25',
  '32363': '90.25',
  '32364': '90.25',
  '32365': '90.26',
  '32366': '90.26',
  '32367': '90.26',
  '32368': '90.26',
  '32369': '90.26',
  '32370': '90.27',
  '32371': '90.27',
  '32372': '90.27',
  '32373': '90.27',
  '32374': '90.27',
  '32375': '90.28',
  '32376': '90.28',
  '32377': '90.28',
  '32378': '90.28',
  '32379': '90.28',
  '32380': '90.28',
  '32381': '90.29',
  '32382': '90.29',
  '32383': '90.29',
  '32384': '90.29',
  '32385': '90.29',
  '32386': '90.29',
  '32387': '90.30',
  '32388': '90.30',
  '32389': '90.30',
  '32390': '90.30',
  '32391': '90.30',
  '32392': '90.31',
  '32393': '90.31',
  '32394': '90.31',
  '32395': '90.31',
  '32396': '90.31',
  '32397': '90.31',
  '32398': '90.32',
  '32399': '90.32',
  '32400': '90.32',
  '32401': '90.32',
  '32402': '90.32',
  '32403': '90.32',
  '32404': '90.33',
  '32405': '90.33',
  '32406': '90.33',
  '32407': '90.33',
  '32408': '90.33',
  '32409': '90.34',
  '32410': '90.34',
  '32411': '90.34',
  '32412': '90.34',
  '32413': '90.34',
  '32414': '90.35',
  '32415': '90.35',
  '32416': '90.35',
  '32417': '90.35',
  '32418': '90.35',
  '32419': '90.35',
  '32420': '90.36',
  '32421': '90.36',
  '32422': '90.36',
  '32423': '90.36',
  '32424': '90.36',
  '32425': '90.36',
  '32426': '90.37',
  '32427': '90.37',
  '32428': '90.37',
  '32429': '90.37',
  '32430': '90.37',
  '32431': '90.38',
  '32432': '90.38',
  '32433': '90.38',
  '32434': '90.38',
  '32435': '90.38',
  '32436': '90.38',
  '32437': '90.39',
  '32438': '90.39',
  '32439': '90.39',
  '32440': '90.39',
  '32441': '90.39',
  '32442': '90.39',
  '32443': '90.40',
  '32444': '90.40',
  '32445': '90.40',
  '32446': '90.40',
  '32447': '90.40',
  '32448': '90.41',
  '32449': '90.41',
  '32450': '90.41',
  '32451': '90.41',
  '32452': '90.41',
  '32453': '90.42',
  '32454': '90.42',
  '32455': '90.42',
  '32456': '90.42',
  '32457': '90.42',
  '32458': '90.42',
  '32459': '90.43',
  '32460': '90.43',
  '32461': '90.43',
  '32462': '90.43',
  '32463': '90.43',
  '32464': '90.43',
  '32465': '90.44',
  '32466': '90.44',
  '32467': '90.44',
  '32468': '90.44',
  '32469': '90.44',
  '32470': '90.45',
  '32471': '90.45',
  '32472': '90.45',
  '32473': '90.45',
  '32474': '90.45',
  '32475': '90.46',
  '32476': '90.46',
  '32477': '90.46',
  '32478': '90.46',
  '32479': '90.46',
  '32480': '90.46',
  '32481': '90.46',
  '32482': '90.47',
  '32483': '90.47',
  '32484': '90.47',
  '32485': '90.47',
  '32486': '90.47',
  '32487': '90.48',
  '32488': '90.48',
  '32489': '90.48',
  '32490': '90.48',
  '32491': '90.48',
  '32492': '90.49',
  '32493': '90.49',
  '32494': '90.49',
  '32495': '90.49',
  '32496': '90.49',
  '32497': '90.49',
  '32498': '90.50',
  '32499': '90.50',
  '32500': '90.50',
  '32501': '90.50',
  '32502': '90.50',
  '32503': '90.50',
  '32504': '90.51',
  '32505': '90.51',
  '32506': '90.51',
  '32507': '90.51',
  '32508': '90.51',
  '32509': '90.52',
  '32510': '90.52',
  '32511': '90.52',
  '32512': '90.52',
  '32513': '90.52',
  '32514': '90.53',
  '32515': '90.53',
  '32516': '90.53',
  '32517': '90.53',
  '32518': '90.53',
  '32519': '90.53',
  '32520': '90.54',
  '32521': '90.54',
  '32522': '90.54',
  '32523': '90.54',
  '32524': '90.54',
  '32525': '90.54',
  '32526': '90.55',
  '32527': '90.55',
  '32528': '90.55',
  '32529': '90.55',
  '32530': '90.55',
  '32531': '90.56',
  '32532': '90.56',
  '32533': '90.56',
  '32534': '90.56',
  '32535': '90.56',
  '32536': '90.56',
  '32537': '90.57',
  '32538': '90.57',
  '32539': '90.57',
  '32540': '90.57',
  '32541': '90.57',
  '32542': '90.57',
  '32543': '90.58',
  '32544': '90.58',
  '32545': '90.58',
  '32546': '90.58',
  '32547': '90.58',
  '32548': '90.59',
  '32549': '90.59',
  '32550': '90.59',
  '32551': '90.59',
  '32552': '90.59',
  '32553': '90.60',
  '32554': '90.60',
  '32555': '90.60',
  '32556': '90.60',
  '32557': '90.60',
  '32558': '90.60',
  '32559': '90.61',
  '32560': '90.61',
  '32561': '90.61',
  '32562': '90.61',
  '32563': '90.61',
  '32564': '90.61',
  '32565': '90.62',
  '32566': '90.62',
  '32567': '90.62',
  '32568': '90.62',
  '32569': '90.62',
  '32570': '90.63',
  '32571': '90.63',
  '32572': '90.63',
  '32573': '90.63',
  '32574': '90.63',
  '32575': '90.64',
  '32576': '90.64',
  '32577': '90.64',
  '32578': '90.64',
  '32579': '90.64',
  '32580': '90.64',
  '32581': '90.64',
  '32582': '90.65',
  '32583': '90.65',
  '32584': '90.65',
  '32585': '90.65',
  '32586': '90.65',
  '32587': '90.66',
  '32588': '90.66',
  '32589': '90.66',
  '32590': '90.66',
  '32591': '90.66',
  '32592': '90.67',
  '32593': '90.67',
  '32594': '90.67',
  '32595': '90.67',
  '32596': '90.67',
  '32597': '90.67',
  '32598': '90.68',
  '32599': '90.68',
  '32600': '90.68',
  '32601': '90.68',
  '32602': '90.68',
  '32603': '90.68',
  '32604': '90.69',
  '32605': '90.69',
  '32606': '90.69',
  '32607': '90.69',
  '32608': '90.69',
  '32609': '90.70',
  '32610': '90.70',
  '32611': '90.70',
  '32612': '90.70',
  '32613': '90.70',
  '32614': '90.71',
  '32615': '90.71',
  '32616': '90.71',
  '32617': '90.71',
  '32618': '90.71',
  '32619': '90.71',
  '32620': '90.72',
  '32621': '90.72',
  '32622': '90.72',
  '32623': '90.72',
  '32624': '90.72',
  '32625': '90.72',
  '32626': '90.73',
  '32627': '90.73',
  '32628': '90.73',
  '32629': '90.73',
  '32630': '90.73',
  '32631': '90.74',
  '32632': '90.74',
  '32633': '90.74',
  '32634': '90.74',
  '32635': '90.74',
  '32636': '90.74',
  '32637': '90.75',
  '32638': '90.75',
  '32639': '90.75',
  '32640': '90.75',
  '32641': '90.75',
  '32642': '90.75',
  '32643': '90.76',
  '32644': '90.76',
  '32645': '90.76',
  '32646': '90.76',
  '32647': '90.76',
  '32648': '90.77',
  '32649': '90.77',
  '32650': '90.77',
  '32651': '90.77',
  '32652': '90.77',
  '32653': '90.78',
  '32654': '90.78',
  '32655': '90.78',
  '32656': '90.78',
  '32657': '90.78',
  '32658': '90.78',
  '32659': '90.79',
  '32660': '90.79',
  '32661': '90.79',
  '32662': '90.79',
  '32663': '90.79',
  '32664': '90.79',
  '32665': '90.80',
  '32666': '90.80',
  '32667': '90.80',
  '32668': '90.80',
  '32669': '90.80',
  '32670': '90.81',
  '32671': '90.81',
  '32672': '90.81',
  '32673': '90.81',
  '32674': '90.81',
  '32675': '90.82',
  '32676': '90.82',
  '32677': '90.82',
  '32678': '90.82',
  '32679': '90.82',
  '32680': '90.82',
  '32681': '90.82',
  '32682': '90.83',
  '32683': '90.83',
  '32684': '90.83',
  '32685': '90.83',
  '32686': '90.83',
  '32687': '90.84',
  '32688': '90.84',
  '32689': '90.84',
  '32690': '90.84',
  '32691': '90.84',
  '32692': '90.85',
  '32693': '90.85',
  '32694': '90.85',
  '32695': '90.85',
  '32696': '90.85',
  '32697': '90.85',
  '32698': '90.86',
  '32699': '90.86',
  '32700': '90.86',
  '32701': '90.86',
  '32702': '90.86',
  '32703': '90.86',
  '32704': '90.87',
  '32705': '90.87',
  '32706': '90.87',
  '32707': '90.87',
  '32708': '90.87',
  '32709': '90.88',
  '32710': '90.88',
  '32711': '90.88',
  '32712': '90.88',
  '32713': '90.88',
  '32714': '90.89',
  '32715': '90.89',
  '32716': '90.89',
  '32717': '90.89',
  '32718': '90.89',
  '32719': '90.89',
  '32720': '90.90',
  '32721': '90.90',
  '32722': '90.90',
  '32723': '90.90',
  '32724': '90.90',
  '32725': '90.90',
  '32726': '90.91',
  '32727': '90.91',
  '32728': '90.91',
  '32729': '90.91',
  '32730': '90.91',
  '32731': '90.92',
  '32732': '90.92',
  '32733': '90.92',
  '32734': '90.92',
  '32735': '90.92',
  '32736': '90.92',
  '32737': '90.93',
  '32738': '90.93',
  '32739': '90.93',
  '32740': '90.93',
  '32741': '90.93',
  '32742': '90.93',
  '32743': '90.94',
  '32744': '90.94',
  '32745': '90.94',
  '32746': '90.94',
  '32747': '90.94',
  '32748': '90.95',
  '32749': '90.95',
  '32750': '90.95',
  '32751': '90.95',
  '32752': '90.95',
  '32753': '90.96',
  '32754': '90.96',
  '32755': '90.96',
  '32756': '90.96',
  '32757': '90.96',
  '32758': '90.96',
  '32759': '90.97',
  '32760': '90.97',
  '32761': '90.97',
  '32762': '90.97',
  '32763': '90.97',
  '32764': '90.97',
  '32765': '90.98',
  '32766': '90.98',
  '32767': '90.98',
  '32768': '90.98',
  '32769': '90.98',
  '32770': '90.99',
  '32771': '90.99',
  '32772': '90.99',
  '32773': '90.99',
  '32774': '90.99',
  '32775': '91.00',
  '32776': '91.00',
  '32777': '91.00',
  '32778': '91.00',
  '32779': '91.00',
  '32780': '91.00',
  '32781': '91.00',
  '32782': '91.01',
  '32783': '91.01',
  '32784': '91.01',
  '32785': '91.01',
  '32786': '91.01',
  '32787': '91.02',
  '32788': '91.02',
  '32789': '91.02',
  '32790': '91.02',
  '32791': '91.02',
  '32792': '91.03',
  '32793': '91.03',
  '32794': '91.03',
  '32795': '91.03',
  '32796': '91.03',
  '32797': '91.03',
  '32798': '91.04',
  '32799': '91.04',
  '32800': '91.04',
  '32801': '91.04',
  '32802': '91.04',
  '32803': '91.04',
  '32804': '91.05',
  '32805': '91.05',
  '32806': '91.05',
  '32807': '91.05',
  '32808': '91.05',
  '32809': '91.06',
  '32810': '91.06',
  '32811': '91.06',
  '32812': '91.06',
  '32813': '91.06',
  '32814': '91.07',
  '32815': '91.07',
  '32816': '91.07',
  '32817': '91.07',
  '32818': '91.07',
  '32819': '91.07',
  '32820': '91.08',
  '32821': '91.08',
  '32822': '91.08',
  '32823': '91.08',
  '32824': '91.08',
  '32825': '91.08',
  '32826': '91.09',
  '32827': '91.09',
  '32828': '91.09',
  '32829': '91.09',
  '32830': '91.09',
  '32831': '91.10',
  '32832': '91.10',
  '32833': '91.10',
  '32834': '91.10',
  '32835': '91.10',
  '32836': '91.10',
  '32837': '91.11',
  '32838': '91.11',
  '32839': '91.11',
  '32840': '91.11',
  '32841': '91.11',
  '32842': '91.11',
  '32843': '91.12',
  '32844': '91.12',
  '32845': '91.12',
  '32846': '91.12',
  '32847': '91.12',
  '32848': '91.13',
  '32849': '91.13',
  '32850': '91.13',
  '32851': '91.13',
  '32852': '91.13',
  '32853': '91.14',
  '32854': '91.14',
  '32855': '91.14',
  '32856': '91.14',
  '32857': '91.14',
  '32858': '91.14',
  '32859': '91.15',
  '32860': '91.15',
  '32861': '91.15',
  '32862': '91.15',
  '32863': '91.15',
  '32864': '91.15',
  '32865': '91.16',
  '32866': '91.16',
  '32867': '91.16',
  '32868': '91.16',
  '32869': '91.16',
  '32870': '91.17',
  '32871': '91.17',
  '32872': '91.17',
  '32873': '91.17',
  '32874': '91.17',
  '32875': '91.18',
  '32876': '91.18',
  '32877': '91.18',
  '32878': '91.18',
  '32879': '91.18',
  '32880': '91.18',
  '32881': '91.18',
  '32882': '91.19',
  '32883': '91.19',
  '32884': '91.19',
  '32885': '91.19',
  '32886': '91.19',
  '32887': '91.20',
  '32888': '91.20',
  '32889': '91.20',
  '32890': '91.20',
  '32891': '91.20',
  '32892': '91.21',
  '32893': '91.21',
  '32894': '91.21',
  '32895': '91.21',
  '32896': '91.21',
  '32897': '91.21',
  '32898': '91.22',
  '32899': '91.22',
  '32900': '91.22',
  '32901': '91.22',
  '32902': '91.22',
  '32903': '91.22',
  '32904': '91.23',
  '32905': '91.23',
  '32906': '91.23',
  '32907': '91.23',
  '32908': '91.23',
  '32909': '91.24',
  '32910': '91.24',
  '32911': '91.24',
  '32912': '91.24',
  '32913': '91.24',
  '32914': '91.25',
  '32915': '91.25',
  '32916': '91.25',
  '32917': '91.25',
  '32918': '91.25',
  '32919': '91.25',
  '32920': '91.26',
  '32921': '91.26',
  '32922': '91.26',
  '32923': '91.26',
  '32924': '91.26',
  '32925': '91.26',
  '32926': '91.27',
  '32927': '91.27',
  '32928': '91.27',
  '32929': '91.27',
  '32930': '91.27',
  '32931': '91.28',
  '32932': '91.28',
  '32933': '91.28',
  '32934': '91.28',
  '32935': '91.28',
  '32936': '91.28',
  '32937': '91.29',
  '32938': '91.29',
  '32939': '91.29',
  '32940': '91.29',
  '32941': '91.29',
  '32942': '91.29',
  '32943': '91.30',
  '32944': '91.30',
  '32945': '91.30',
  '32946': '91.30',
  '32947': '91.30',
  '32948': '91.31',
  '32949': '91.31',
  '32950': '91.31',
  '32951': '91.31',
  '32952': '91.31',
  '32953': '91.32',
  '32954': '91.32',
  '32955': '91.32',
  '32956': '91.32',
  '32957': '91.32',
  '32958': '91.32',
  '32959': '91.33',
  '32960': '91.33',
  '32961': '91.33',
  '32962': '91.33',
  '32963': '91.33',
  '32964': '91.33',
  '32965': '91.34',
  '32966': '91.34',
  '32967': '91.34',
  '32968': '91.34',
  '32969': '91.34',
  '32970': '91.35',
  '32971': '91.35',
  '32972': '91.35',
  '32973': '91.35',
  '32974': '91.35',
  '32975': '91.36',
  '32976': '91.36',
  '32977': '91.36',
  '32978': '91.36',
  '32979': '91.36',
  '32980': '91.36',
  '32981': '91.36',
  '32982': '91.37',
  '32983': '91.37',
  '32984': '91.37',
  '32985': '91.37',
  '32986': '91.37',
  '32987': '91.38',
  '32988': '91.38',
  '32989': '91.38',
  '32990': '91.38',
  '32991': '91.38',
  '32992': '91.39',
  '32993': '91.39',
  '32994': '91.39',
  '32995': '91.39',
  '32996': '91.39',
  '32997': '91.39',
  '32998': '91.40',
  '32999': '91.40',
  '33000': '91.40',
  '33001': '91.40',
  '33002': '91.40',
  '33003': '91.40',
  '33004': '91.41',
  '33005': '91.41',
  '33006': '91.41',
  '33007': '91.41',
  '33008': '91.41',
  '33009': '91.42',
  '33010': '91.42',
  '33011': '91.42',
  '33012': '91.42',
  '33013': '91.42',
  '33014': '91.43',
  '33015': '91.43',
  '33016': '91.43',
  '33017': '91.43',
  '33018': '91.43',
  '33019': '91.43',
  '33020': '91.44',
  '33021': '91.44',
  '33022': '91.44',
  '33023': '91.44',
  '33024': '91.44',
  '33025': '91.44',
  '33026': '91.45',
  '33027': '91.45',
  '33028': '91.45',
  '33029': '91.45',
  '33030': '91.45',
  '33031': '91.46',
  '33032': '91.46',
  '33033': '91.46',
  '33034': '91.46',
  '33035': '91.46',
  '33036': '91.46',
  '33037': '91.47',
  '33038': '91.47',
  '33039': '91.47',
  '33040': '91.47',
  '33041': '91.47',
  '33042': '91.47',
  '33043': '91.48',
  '33044': '91.48',
  '33045': '91.48',
  '33046': '91.48',
  '33047': '91.48',
  '33048': '91.49',
  '33049': '91.49',
  '33050': '91.49',
  '33051': '91.49',
  '33052': '91.49',
  '33053': '91.50',
  '33054': '91.50',
  '33055': '91.50',
  '33056': '91.50',
  '33057': '91.50',
  '33058': '91.50',
  '33059': '91.51',
  '33060': '91.51',
  '33061': '91.51',
  '33062': '91.51',
  '33063': '91.51',
  '33064': '91.51',
  '33065': '91.52',
  '33066': '91.52',
  '33067': '91.52',
  '33068': '91.52',
  '33069': '91.52',
  '33070': '91.53',
  '33071': '91.53',
  '33072': '91.53',
  '33073': '91.53',
  '33074': '91.53',
  '33075': '91.54',
  '33076': '91.54',
  '33077': '91.54',
  '33078': '91.54',
  '33079': '91.54',
  '33080': '91.54',
  '33081': '91.54',
  '33082': '91.55',
  '33083': '91.55',
  '33084': '91.55',
  '33085': '91.55',
  '33086': '91.55',
  '33087': '91.56',
  '33088': '91.56',
  '33089': '91.56',
  '33090': '91.56',
  '33091': '91.56',
  '33092': '91.57',
  '33093': '91.57',
  '33094': '91.57',
  '33095': '91.57',
  '33096': '91.57',
  '33097': '91.57',
  '33098': '91.58',
  '33099': '91.58',
  '33100': '91.58',
  '33101': '91.58',
  '33102': '91.58',
  '33103': '91.58',
  '33104': '91.59',
  '33105': '91.59',
  '33106': '91.59',
  '33107': '91.59',
  '33108': '91.59',
  '33109': '91.60',
  '33110': '91.60',
  '33111': '91.60',
  '33112': '91.60',
  '33113': '91.60',
  '33114': '91.61',
  '33115': '91.61',
  '33116': '91.61',
  '33117': '91.61',
  '33118': '91.61',
  '33119': '91.61',
  '33120': '91.62',
  '33121': '91.62',
  '33122': '91.62',
  '33123': '91.62',
  '33124': '91.62',
  '33125': '91.62',
  '33126': '91.63',
  '33127': '91.63',
  '33128': '91.63',
  '33129': '91.63',
  '33130': '91.63',
  '33131': '91.64',
  '33132': '91.64',
  '33133': '91.64',
  '33134': '91.64',
  '33135': '91.64',
  '33136': '91.64',
  '33137': '91.65',
  '33138': '91.65',
  '33139': '91.65',
  '33140': '91.65',
  '33141': '91.65',
  '33142': '91.65',
  '33143': '91.66',
  '33144': '91.66',
  '33145': '91.66',
  '33146': '91.66',
  '33147': '91.66',
  '33148': '91.67',
  '33149': '91.67',
  '33150': '91.67',
  '33151': '91.67',
  '33152': '91.67',
  '33153': '91.68',
  '33154': '91.68',
  '33155': '91.68',
  '33156': '91.68',
  '33157': '91.68',
  '33158': '91.68',
  '33159': '91.69',
  '33160': '91.69',
  '33161': '91.69',
  '33162': '91.69',
  '33163': '91.69',
  '33164': '91.69',
  '33165': '91.70',
  '33166': '91.70',
  '33167': '91.70',
  '33168': '91.70',
  '33169': '91.70',
  '33170': '91.71',
  '33171': '91.71',
  '33172': '91.71',
  '33173': '91.71',
  '33174': '91.71',
  '33175': '91.71',
  '33176': '91.72',
  '33177': '91.72',
  '33178': '91.72',
  '33179': '91.72',
  '33180': '91.72',
  '33181': '91.72',
  '33182': '91.73',
  '33183': '91.73',
  '33184': '91.73',
  '33185': '91.73',
  '33186': '91.73',
  '33187': '91.74',
  '33188': '91.74',
  '33189': '91.74',
  '33190': '91.74',
  '33191': '91.74',
  '33192': '91.75',
  '33193': '91.75',
  '33194': '91.75',
  '33195': '91.75',
  '33196': '91.75',
  '33197': '91.75',
  '33198': '91.76',
  '33199': '91.76',
  '33200': '91.76',
  '33201': '91.76',
  '33202': '91.76',
  '33203': '91.76',
  '33204': '91.77',
  '33205': '91.77',
  '33206': '91.77',
  '33207': '91.77',
  '33208': '91.77',
  '33209': '91.78',
  '33210': '91.78',
  '33211': '91.78',
  '33212': '91.78',
  '33213': '91.78',
  '33214': '91.79',
  '33215': '91.79',
  '33216': '91.79',
  '33217': '91.79',
  '33218': '91.79',
  '33219': '91.79',
  '33220': '91.80',
  '33221': '91.80',
  '33222': '91.80',
  '33223': '91.80',
  '33224': '91.80',
  '33225': '91.81',
  '33226': '91.81',
  '33227': '91.81',
  '33228': '91.81',
  '33229': '91.81',
  '33230': '91.81',
  '33231': '91.82',
  '33232': '91.82',
  '33233': '91.82',
  '33234': '91.82',
  '33235': '91.82',
  '33236': '91.82',
  '33237': '91.83',
  '33238': '91.83',
  '33239': '91.83',
  '33240': '91.83',
  '33241': '91.83',
  '33242': '91.83',
  '33243': '91.84',
  '33244': '91.84',
  '33245': '91.84',
  '33246': '91.84',
  '33247': '91.84',
  '33248': '91.85',
  '33249': '91.85',
  '33250': '91.85',
  '33251': '91.85',
  '33252': '91.85',
  '33253': '91.86',
  '33254': '91.86',
  '33255': '91.86',
  '33256': '91.86',
  '33257': '91.86',
  '33258': '91.86',
  '33259': '91.87',
  '33260': '91.87',
  '33261': '91.87',
  '33262': '91.87',
  '33263': '91.87',
  '33264': '91.88',
  '33265': '91.88',
  '33266': '91.88',
  '33267': '91.88',
  '33268': '91.88',
  '33269': '91.88',
  '33270': '91.89',
  '33271': '91.89',
  '33272': '91.89',
  '33273': '91.89',
  '33274': '91.89',
  '33275': '91.89',
  '33276': '91.90',
  '33277': '91.90',
  '33278': '91.90',
  '33279': '91.90',
  '33280': '91.90',
  '33281': '91.90',
  '33282': '91.91',
  '33283': '91.91',
  '33284': '91.91',
  '33285': '91.91',
  '33286': '91.91',
  '33287': '91.92',
  '33288': '91.92',
  '33289': '91.92',
  '33290': '91.92',
  '33291': '91.92',
  '33292': '91.93',
  '33293': '91.93',
  '33294': '91.93',
  '33295': '91.93',
  '33296': '91.93',
  '33297': '91.93',
  '33298': '91.94',
  '33299': '91.94',
  '33300': '91.94',
  '33301': '91.94',
  '33302': '91.94',
  '33303': '91.94',
  '33304': '91.95',
  '33305': '91.95',
  '33306': '91.95',
  '33307': '91.95',
  '33308': '91.95',
  '33309': '91.96',
  '33310': '91.96',
  '33311': '91.96',
  '33312': '91.96',
  '33313': '91.96',
  '33314': '91.96',
  '33315': '91.97',
  '33316': '91.97',
  '33317': '91.97',
  '33318': '91.97',
  '33319': '91.97',
  '33320': '91.98',
  '33321': '91.98',
  '33322': '91.98',
  '33323': '91.98',
  '33324': '91.98',
  '33325': '91.99',
  '33326': '91.99',
  '33327': '91.99',
  '33328': '91.99',
  '33329': '91.99',
  '33330': '91.99',
  '33331': '92.00',
  '33332': '92.00',
  '33333': '92.00',
  '33334': '92.00',
  '33335': '92.00',
  '33336': '92.00',
  '33337': '92.01',
  '33338': '92.01',
  '33339': '92.01',
  '33340': '92.01',
  '33341': '92.01',
  '33342': '92.01',
  '33343': '92.02',
  '33344': '92.02',
  '33345': '92.02',
  '33346': '92.02',
  '33347': '92.02',
  '33348': '92.03',
  '33349': '92.03',
  '33350': '92.03',
  '33351': '92.03',
  '33352': '92.03',
  '33353': '92.04',
  '33354': '92.04',
  '33355': '92.04',
  '33356': '92.04',
  '33357': '92.04',
  '33358': '92.04',
  '33359': '92.05',
  '33360': '92.05',
  '33361': '92.05',
  '33362': '92.05',
  '33363': '92.05',
  '33364': '92.06',
  '33365': '92.06',
  '33366': '92.06',
  '33367': '92.06',
  '33368': '92.06',
  '33369': '92.06',
  '33370': '92.07',
  '33371': '92.07',
  '33372': '92.07',
  '33373': '92.07',
  '33374': '92.07',
  '33375': '92.07',
  '33376': '92.08',
  '33377': '92.08',
  '33378': '92.08',
  '33379': '92.08',
  '33380': '92.08',
  '33381': '92.08',
  '33382': '92.09',
  '33383': '92.09',
  '33384': '92.09',
  '33385': '92.09',
  '33386': '92.09',
  '33387': '92.10',
  '33388': '92.10',
  '33389': '92.10',
  '33390': '92.10',
  '33391': '92.10',
  '33392': '92.11',
  '33393': '92.11',
  '33394': '92.11',
  '33395': '92.11',
  '33396': '92.11',
  '33397': '92.11',
  '33398': '92.12',
  '33399': '92.12',
  '33400': '92.12',
  '33401': '92.12',
  '33402': '92.12',
  '33403': '92.12',
  '33404': '92.13',
  '33405': '92.13',
  '33406': '92.13',
  '33407': '92.13',
  '33408': '92.13',
  '33409': '92.14',
  '33410': '92.14',
  '33411': '92.14',
  '33412': '92.14',
  '33413': '92.14',
  '33414': '92.14',
  '33415': '92.15',
  '33416': '92.15',
  '33417': '92.15',
  '33418': '92.15',
  '33419': '92.15',
  '33420': '92.16',
  '33421': '92.16',
  '33422': '92.16',
  '33423': '92.16',
  '33424': '92.16',
  '33425': '92.17',
  '33426': '92.17',
  '33427': '92.17',
  '33428': '92.17',
  '33429': '92.17',
  '33430': '92.17',
  '33431': '92.18',
  '33432': '92.18',
  '33433': '92.18',
  '33434': '92.18',
  '33435': '92.18',
  '33436': '92.18',
  '33437': '92.19',
  '33438': '92.19',
  '33439': '92.19',
  '33440': '92.19',
  '33441': '92.19',
  '33442': '92.19',
  '33443': '92.20',
  '33444': '92.20',
  '33445': '92.20',
  '33446': '92.20',
  '33447': '92.20',
  '33448': '92.21',
  '33449': '92.21',
  '33450': '92.21',
  '33451': '92.21',
  '33452': '92.21',
  '33453': '92.21',
  '33454': '92.22',
  '33455': '92.22',
  '33456': '92.22',
  '33457': '92.22',
  '33458': '92.22',
  '33459': '92.23',
  '33460': '92.23',
  '33461': '92.23',
  '33462': '92.23',
  '33463': '92.23',
  '33464': '92.24',
  '33465': '92.24',
  '33466': '92.24',
  '33467': '92.24',
  '33468': '92.24',
  '33469': '92.24',
  '33470': '92.25',
  '33471': '92.25',
  '33472': '92.25',
  '33473': '92.25',
  '33474': '92.25',
  '33475': '92.25',
  '33476': '92.26',
  '33477': '92.26',
  '33478': '92.26',
  '33479': '92.26',
  '33480': '92.26',
  '33481': '92.26',
  '33482': '92.27',
  '33483': '92.27',
  '33484': '92.27',
  '33485': '92.27',
  '33486': '92.27',
  '33487': '92.28',
  '33488': '92.28',
  '33489': '92.28',
  '33490': '92.28',
  '33491': '92.28',
  '33492': '92.29',
  '33493': '92.29',
  '33494': '92.29',
  '33495': '92.29',
  '33496': '92.29',
  '33497': '92.29',
  '33498': '92.30',
  '33499': '92.30',
  '33500': '92.30',
  '33501': '92.30',
  '33502': '92.30',
  '33503': '92.31',
  '33504': '92.31',
  '33505': '92.31',
  '33506': '92.31',
  '33507': '92.31',
  '33508': '92.31',
  '33509': '92.32',
  '33510': '92.32',
  '33511': '92.32',
  '33512': '92.32',
  '33513': '92.32',
  '33514': '92.32',
  '33515': '92.33',
  '33516': '92.33',
  '33517': '92.33',
  '33518': '92.33',
  '33519': '92.33',
  '33520': '92.34',
  '33521': '92.34',
  '33522': '92.34',
  '33523': '92.34',
  '33524': '92.34',
  '33525': '92.35',
  '33526': '92.35',
  '33527': '92.35',
  '33528': '92.35',
  '33529': '92.35',
  '33530': '92.35',
  '33531': '92.36',
  '33532': '92.36',
  '33533': '92.36',
  '33534': '92.36',
  '33535': '92.36',
  '33536': '92.36',
  '33537': '92.37',
  '33538': '92.37',
  '33539': '92.37',
  '33540': '92.37',
  '33541': '92.37',
  '33542': '92.38',
  '33543': '92.38',
  '33544': '92.38',
  '33545': '92.38',
  '33546': '92.38',
  '33547': '92.38',
  '33548': '92.39',
  '33549': '92.39',
  '33550': '92.39',
  '33551': '92.39',
  '33552': '92.39',
  '33553': '92.39',
  '33554': '92.40',
  '33555': '92.40',
  '33556': '92.40',
  '33557': '92.40',
  '33558': '92.40',
  '33559': '92.41',
  '33560': '92.41',
  '33561': '92.41',
  '33562': '92.41',
  '33563': '92.41',
  '33564': '92.42',
  '33565': '92.42',
  '33566': '92.42',
  '33567': '92.42',
  '33568': '92.42',
  '33569': '92.42',
  '33570': '92.43',
  '33571': '92.43',
  '33572': '92.43',
  '33573': '92.43',
  '33574': '92.43',
  '33575': '92.43',
  '33576': '92.44',
  '33577': '92.44',
  '33578': '92.44',
  '33579': '92.44',
  '33580': '92.44',
  '33581': '92.44',
  '33582': '92.45',
  '33583': '92.45',
  '33584': '92.45',
  '33585': '92.45',
  '33586': '92.45',
  '33587': '92.46',
  '33588': '92.46',
  '33589': '92.46',
  '33590': '92.46',
  '33591': '92.46',
  '33592': '92.46',
  '33593': '92.47',
  '33594': '92.47',
  '33595': '92.47',
  '33596': '92.47',
  '33597': '92.47',
  '33598': '92.48',
  '33599': '92.48',
  '33600': '92.48',
  '33601': '92.48',
  '33602': '92.48',
  '33603': '92.49',
  '33604': '92.49',
  '33605': '92.49',
  '33606': '92.49',
  '33607': '92.49',
  '33608': '92.49',
  '33609': '92.50',
  '33610': '92.50',
  '33611': '92.50',
  '33612': '92.50',
  '33613': '92.50',
  '33614': '92.50',
  '33615': '92.51',
  '33616': '92.51',
  '33617': '92.51',
  '33618': '92.51',
  '33619': '92.51',
  '33620': '92.52',
  '33621': '92.52',
  '33622': '92.52',
  '33623': '92.52',
  '33624': '92.52',
  '33625': '92.53',
  '33626': '92.53',
  '33627': '92.53',
  '33628': '92.53',
  '33629': '92.53',
  '33630': '92.53',
  '33631': '92.54',
  '33632': '92.54',
  '33633': '92.54',
  '33634': '92.54',
  '33635': '92.54',
  '33636': '92.54',
  '33637': '92.55',
  '33638': '92.55',
  '33639': '92.55',
  '33640': '92.55',
  '33641': '92.55',
  '33642': '92.56',
  '33643': '92.56',
  '33644': '92.56',
  '33645': '92.56',
  '33646': '92.56',
  '33647': '92.56',
  '33648': '92.57',
  '33649': '92.57',
  '33650': '92.57',
  '33651': '92.57',
  '33652': '92.57',
  '33653': '92.57',
  '33654': '92.58',
  '33655': '92.58',
  '33656': '92.58',
  '33657': '92.58',
  '33658': '92.58',
  '33659': '92.59',
  '33660': '92.59',
  '33661': '92.59',
  '33662': '92.59',
  '33663': '92.59',
  '33664': '92.60',
  '33665': '92.60',
  '33666': '92.60',
  '33667': '92.60',
  '33668': '92.60',
  '33669': '92.60',
  '33670': '92.61',
  '33671': '92.61',
  '33672': '92.61',
  '33673': '92.61',
  '33674': '92.61',
  '33675': '92.61',
  '33676': '92.62',
  '33677': '92.62',
  '33678': '92.62',
  '33679': '92.62',
  '33680': '92.62',
  '33681': '92.62',
  '33682': '92.63',
  '33683': '92.63',
  '33684': '92.63',
  '33685': '92.63',
  '33686': '92.63',
  '33687': '92.64',
  '33688': '92.64',
  '33689': '92.64',
  '33690': '92.64',
  '33691': '92.64',
  '33692': '92.64',
  '33693': '92.65',
  '33694': '92.65',
  '33695': '92.65',
  '33696': '92.65',
  '33697': '92.65',
  '33698': '92.66',
  '33699': '92.66',
  '33700': '92.66',
  '33701': '92.66',
  '33702': '92.66',
  '33703': '92.67',
  '33704': '92.67',
  '33705': '92.67',
  '33706': '92.67',
  '33707': '92.67',
  '33708': '92.67',
  '33709': '92.68',
  '33710': '92.68',
  '33711': '92.68',
  '33712': '92.68',
  '33713': '92.68',
  '33714': '92.68',
  '33715': '92.69',
  '33716': '92.69',
  '33717': '92.69',
  '33718': '92.69',
  '33719': '92.69',
  '33720': '92.70',
  '33721': '92.70',
  '33722': '92.70',
  '33723': '92.70',
  '33724': '92.70',
  '33725': '92.71',
  '33726': '92.71',
  '33727': '92.71',
  '33728': '92.71',
  '33729': '92.71',
  '33730': '92.71',
  '33731': '92.71',
  '33732': '92.72',
  '33733': '92.72',
  '33734': '92.72',
  '33735': '92.72',
  '33736': '92.72',
  '33737': '92.73',
  '33738': '92.73',
  '33739': '92.73',
  '33740': '92.73',
  '33741': '92.73',
  '33742': '92.74',
  '33743': '92.74',
  '33744': '92.74',
  '33745': '92.74',
  '33746': '92.74',
  '33747': '92.74',
  '33748': '92.75',
  '33749': '92.75',
  '33750': '92.75',
  '33751': '92.75',
  '33752': '92.75',
  '33753': '92.75',
  '33754': '92.76',
  '33755': '92.76',
  '33756': '92.76',
  '33757': '92.76',
  '33758': '92.76',
  '33759': '92.77',
  '33760': '92.77',
  '33761': '92.77',
  '33762': '92.77',
  '33763': '92.77',
  '33764': '92.78',
  '33765': '92.78',
  '33766': '92.78',
  '33767': '92.78',
  '33768': '92.78',
  '33769': '92.78',
  '33770': '92.79',
  '33771': '92.79',
  '33772': '92.79',
  '33773': '92.79',
  '33774': '92.79',
  '33775': '92.79',
  '33776': '92.80',
  '33777': '92.80',
  '33778': '92.80',
  '33779': '92.80',
  '33780': '92.80',
  '33781': '92.81',
  '33782': '92.81',
  '33783': '92.81',
  '33784': '92.81',
  '33785': '92.81',
  '33786': '92.81',
  '33787': '92.82',
  '33788': '92.82',
  '33789': '92.82',
  '33790': '92.82',
  '33791': '92.82',
  '33792': '92.82',
  '33793': '92.83',
  '33794': '92.83',
  '33795': '92.83',
  '33796': '92.83',
  '33797': '92.83',
  '33798': '92.84',
  '33799': '92.84',
  '33800': '92.84',
  '33801': '92.84',
  '33802': '92.84',
  '33803': '92.85',
  '33804': '92.85',
  '33805': '92.85',
  '33806': '92.85',
  '33807': '92.85',
  '33808': '92.85',
  '33809': '92.86',
  '33810': '92.86',
  '33811': '92.86',
  '33812': '92.86',
  '33813': '92.86',
  '33814': '92.86',
  '33815': '92.87',
  '33816': '92.87',
  '33817': '92.87',
  '33818': '92.87',
  '33819': '92.87',
  '33820': '92.88',
  '33821': '92.88',
  '33822': '92.88',
  '33823': '92.88',
  '33824': '92.88',
  '33825': '92.89',
  '33826': '92.89',
  '33827': '92.89',
  '33828': '92.89',
  '33829': '92.89',
  '33830': '92.89',
  '33831': '92.89',
  '33832': '92.90',
  '33833': '92.90',
  '33834': '92.90',
  '33835': '92.90',
  '33836': '92.90',
  '33837': '92.91',
  '33838': '92.91',
  '33839': '92.91',
  '33840': '92.91',
  '33841': '92.91',
  '33842': '92.92',
  '33843': '92.92',
  '33844': '92.92',
  '33845': '92.92',
  '33846': '92.92',
  '33847': '92.92',
  '33848': '92.93',
  '33849': '92.93',
  '33850': '92.93',
  '33851': '92.93',
  '33852': '92.93',
  '33853': '92.93',
  '33854': '92.94',
  '33855': '92.94',
  '33856': '92.94',
  '33857': '92.94',
  '33858': '92.94',
  '33859': '92.95',
  '33860': '92.95',
  '33861': '92.95',
  '33862': '92.95',
  '33863': '92.95',
  '33864': '92.96',
  '33865': '92.96',
  '33866': '92.96',
  '33867': '92.96',
  '33868': '92.96',
  '33869': '92.96',
  '33870': '92.97',
  '33871': '92.97',
  '33872': '92.97',
  '33873': '92.97',
  '33874': '92.97',
  '33875': '92.97',
  '33876': '92.98',
  '33877': '92.98',
  '33878': '92.98',
  '33879': '92.98',
  '33880': '92.98',
  '33881': '92.99',
  '33882': '92.99',
  '33883': '92.99',
  '33884': '92.99',
  '33885': '92.99',
  '33886': '92.99',
  '33887': '93.00',
  '33888': '93.00',
  '33889': '93.00',
  '33890': '93.00',
  '33891': '93.00',
  '33892': '93.00',
  '33893': '93.01',
  '33894': '93.01',
  '33895': '93.01',
  '33896': '93.01',
  '33897': '93.01',
  '33898': '93.02',
  '33899': '93.02',
  '33900': '93.02',
  '33901': '93.02',
  '33902': '93.02',
  '33903': '93.03',
  '33904': '93.03',
  '33905': '93.03',
  '33906': '93.03',
  '33907': '93.03',
  '33908': '93.03',
  '33909': '93.04',
  '33910': '93.04',
  '33911': '93.04',
  '33912': '93.04',
  '33913': '93.04',
  '33914': '93.04',
  '33915': '93.05',
  '33916': '93.05',
  '33917': '93.05',
  '33918': '93.05',
  '33919': '93.05',
  '33920': '93.06',
  '33921': '93.06',
  '33922': '93.06',
  '33923': '93.06',
  '33924': '93.06',
  '33925': '93.07',
  '33926': '93.07',
  '33927': '93.07',
  '33928': '93.07',
  '33929': '93.07',
  '33930': '93.07',
  '33931': '93.07',
  '33932': '93.08',
  '33933': '93.08',
  '33934': '93.08',
  '33935': '93.08',
  '33936': '93.08',
  '33937': '93.09',
  '33938': '93.09',
  '33939': '93.09',
  '33940': '93.09',
  '33941': '93.09',
  '33942': '93.10',
  '33943': '93.10',
  '33944': '93.10',
  '33945': '93.10',
  '33946': '93.10',
  '33947': '93.10',
  '33948': '93.11',
  '33949': '93.11',
  '33950': '93.11',
  '33951': '93.11',
  '33952': '93.11',
  '33953': '93.11',
  '33954': '93.12',
  '33955': '93.12',
  '33956': '93.12',
  '33957': '93.12',
  '33958': '93.12',
  '33959': '93.13',
  '33960': '93.13',
  '33961': '93.13',
  '33962': '93.13',
  '33963': '93.13',
  '33964': '93.14',
  '33965': '93.14',
  '33966': '93.14',
  '33967': '93.14',
  '33968': '93.14',
  '33969': '93.14',
  '33970': '93.15',
  '33971': '93.15',
  '33972': '93.15',
  '33973': '93.15',
  '33974': '93.15',
  '33975': '93.15',
  '33976': '93.16',
  '33977': '93.16',
  '33978': '93.16',
  '33979': '93.16',
  '33980': '93.16',
  '33981': '93.17',
  '33982': '93.17',
  '33983': '93.17',
  '33984': '93.17',
  '33985': '93.17',
  '33986': '93.17',
  '33987': '93.18',
  '33988': '93.18',
  '33989': '93.18',
  '33990': '93.18',
  '33991': '93.18',
  '33992': '93.18',
  '33993': '93.19',
  '33994': '93.19',
  '33995': '93.19',
  '33996': '93.19',
  '33997': '93.19',
  '33998': '93.20',
  '33999': '93.20',
  '34000': '93.20',
  '34001': '93.20',
  '34002': '93.20',
  '34003': '93.21',
  '34004': '93.21',
  '34005': '93.21',
  '34006': '93.21',
  '34007': '93.21',
  '34008': '93.21',
  '34009': '93.22',
  '34010': '93.22',
  '34011': '93.22',
  '34012': '93.22',
  '34013': '93.22',
  '34014': '93.22',
  '34015': '93.23',
  '34016': '93.23',
  '34017': '93.23',
  '34018': '93.23',
  '34019': '93.23',
  '34020': '93.24',
  '34021': '93.24',
  '34022': '93.24',
  '34023': '93.24',
  '34024': '93.24',
  '34025': '93.25',
  '34026': '93.25',
  '34027': '93.25',
  '34028': '93.25',
  '34029': '93.25',
  '34030': '93.25',
  '34031': '93.25',
  '34032': '93.26',
  '34033': '93.26',
  '34034': '93.26',
  '34035': '93.26',
  '34036': '93.26',
  '34037': '93.27',
  '34038': '93.27',
  '34039': '93.27',
  '34040': '93.27',
  '34041': '93.27',
  '34042': '93.28',
  '34043': '93.28',
  '34044': '93.28',
  '34045': '93.28',
  '34046': '93.28',
  '34047': '93.28',
  '34048': '93.29',
  '34049': '93.29',
  '34050': '93.29',
  '34051': '93.29',
  '34052': '93.29',
  '34053': '93.29',
  '34054': '93.30',
  '34055': '93.30',
  '34056': '93.30',
  '34057': '93.30',
  '34058': '93.30',
  '34059': '93.31',
  '34060': '93.31',
  '34061': '93.31',
  '34062': '93.31',
  '34063': '93.31',
  '34064': '93.32',
  '34065': '93.32',
  '34066': '93.32',
  '34067': '93.32',
  '34068': '93.32',
  '34069': '93.32',
  '34070': '93.33',
  '34071': '93.33',
  '34072': '93.33',
  '34073': '93.33',
  '34074': '93.33',
  '34075': '93.33',
  '34076': '93.34',
  '34077': '93.34',
  '34078': '93.34',
  '34079': '93.34',
  '34080': '93.34',
  '34081': '93.35',
  '34082': '93.35',
  '34083': '93.35',
  '34084': '93.35',
  '34085': '93.35',
  '34086': '93.35',
  '34087': '93.36',
  '34088': '93.36',
  '34089': '93.36',
  '34090': '93.36',
  '34091': '93.36',
  '34092': '93.36',
  '34093': '93.37',
  '34094': '93.37',
  '34095': '93.37',
  '34096': '93.37',
  '34097': '93.37',
  '34098': '93.38',
  '34099': '93.38',
  '34100': '93.38',
  '34101': '93.38',
  '34102': '93.38',
  '34103': '93.39',
  '34104': '93.39',
  '34105': '93.39',
  '34106': '93.39',
  '34107': '93.39',
  '34108': '93.39',
  '34109': '93.40',
  '34110': '93.40',
  '34111': '93.40',
  '34112': '93.40',
  '34113': '93.40',
  '34114': '93.40',
  '34115': '93.41',
  '34116': '93.41',
  '34117': '93.41',
  '34118': '93.41',
  '34119': '93.41',
  '34120': '93.42',
  '34121': '93.42',
  '34122': '93.42',
  '34123': '93.42',
  '34124': '93.42',
  '34125': '93.43',
  '34126': '93.43',
  '34127': '93.43',
  '34128': '93.43',
  '34129': '93.43',
  '34130': '93.43',
  '34131': '93.43',
  '34132': '93.44',
  '34133': '93.44',
  '34134': '93.44',
  '34135': '93.44',
  '34136': '93.44',
  '34137': '93.45',
  '34138': '93.45',
  '34139': '93.45',
  '34140': '93.45',
  '34141': '93.45',
  '34142': '93.46',
  '34143': '93.46',
  '34144': '93.46',
  '34145': '93.46',
  '34146': '93.46',
  '34147': '93.46',
  '34148': '93.47',
  '34149': '93.47',
  '34150': '93.47',
  '34151': '93.47',
  '34152': '93.47',
  '34153': '93.47',
  '34154': '93.48',
  '34155': '93.48',
  '34156': '93.48',
  '34157': '93.48',
  '34158': '93.48',
  '34159': '93.49',
  '34160': '93.49',
  '34161': '93.49',
  '34162': '93.49',
  '34163': '93.49',
  '34164': '93.50',
  '34165': '93.50',
  '34166': '93.50',
  '34167': '93.50',
  '34168': '93.50',
  '34169': '93.50',
  '34170': '93.51',
  '34171': '93.51',
  '34172': '93.51',
  '34173': '93.51',
  '34174': '93.51',
  '34175': '93.51',
  '34176': '93.52',
  '34177': '93.52',
  '34178': '93.52',
  '34179': '93.52',
  '34180': '93.52',
  '34181': '93.53',
  '34182': '93.53',
  '34183': '93.53',
  '34184': '93.53',
  '34185': '93.53',
  '34186': '93.53',
  '34187': '93.54',
  '34188': '93.54',
  '34189': '93.54',
  '34190': '93.54',
  '34191': '93.54',
  '34192': '93.54',
  '34193': '93.55',
  '34194': '93.55',
  '34195': '93.55',
  '34196': '93.55',
  '34197': '93.55',
  '34198': '93.56',
  '34199': '93.56',
  '34200': '93.56',
  '34201': '93.56',
  '34202': '93.56',
  '34203': '93.57',
  '34204': '93.57',
  '34205': '93.57',
  '34206': '93.57',
  '34207': '93.57',
  '34208': '93.57',
  '34209': '93.58',
  '34210': '93.58',
  '34211': '93.58',
  '34212': '93.58',
  '34213': '93.58',
  '34214': '93.58',
  '34215': '93.59',
  '34216': '93.59',
  '34217': '93.59',
  '34218': '93.59',
  '34219': '93.59',
  '34220': '93.60',
  '34221': '93.60',
  '34222': '93.60',
  '34223': '93.60',
  '34224': '93.60',
  '34225': '93.61',
  '34226': '93.61',
  '34227': '93.61',
  '34228': '93.61',
  '34229': '93.61',
  '34230': '93.61',
  '34231': '93.61',
  '34232': '93.62',
  '34233': '93.62',
  '34234': '93.62',
  '34235': '93.62',
  '34236': '93.62',
  '34237': '93.63',
  '34238': '93.63',
  '34239': '93.63',
  '34240': '93.63',
  '34241': '93.63',
  '34242': '93.64',
  '34243': '93.64',
  '34244': '93.64',
  '34245': '93.64',
  '34246': '93.64',
  '34247': '93.64',
  '34248': '93.65',
  '34249': '93.65',
  '34250': '93.65',
  '34251': '93.65',
  '34252': '93.65',
  '34253': '93.65',
  '34254': '93.66',
  '34255': '93.66',
  '34256': '93.66',
  '34257': '93.66',
  '34258': '93.66',
  '34259': '93.67',
  '34260': '93.67',
  '34261': '93.67',
  '34262': '93.67',
  '34263': '93.67',
  '34264': '93.68',
  '34265': '93.68',
  '34266': '93.68',
  '34267': '93.68',
  '34268': '93.68',
  '34269': '93.68',
  '34270': '93.69',
  '34271': '93.69',
  '34272': '93.69',
  '34273': '93.69',
  '34274': '93.69',
  '34275': '93.69',
  '34276': '93.70',
  '34277': '93.70',
  '34278': '93.70',
  '34279': '93.70',
  '34280': '93.70',
  '34281': '93.71',
  '34282': '93.71',
  '34283': '93.71',
  '34284': '93.71',
  '34285': '93.71',
  '34286': '93.71',
  '34287': '93.72',
  '34288': '93.72',
  '34289': '93.72',
  '34290': '93.72',
  '34291': '93.72',
  '34292': '93.72',
  '34293': '93.73',
  '34294': '93.73',
  '34295': '93.73',
  '34296': '93.73',
  '34297': '93.73',
  '34298': '93.74',
  '34299': '93.74',
  '34300': '93.74',
  '34301': '93.74',
  '34302': '93.74',
  '34303': '93.75',
  '34304': '93.75',
  '34305': '93.75',
  '34306': '93.75',
  '34307': '93.75',
  '34308': '93.75',
  '34309': '93.76',
  '34310': '93.76',
  '34311': '93.76',
  '34312': '93.76',
  '34313': '93.76',
  '34314': '93.76',
  '34315': '93.77',
  '34316': '93.77',
  '34317': '93.77',
  '34318': '93.77',
  '34319': '93.77',
  '34320': '93.78',
  '34321': '93.78',
  '34322': '93.78',
  '34323': '93.78',
  '34324': '93.78',
  '34325': '93.79',
  '34326': '93.79',
  '34327': '93.79',
  '34328': '93.79',
  '34329': '93.79',
  '34330': '93.79',
  '34331': '93.79',
  '34332': '93.80',
  '34333': '93.80',
  '34334': '93.80',
  '34335': '93.80',
  '34336': '93.80',
  '34337': '93.81',
  '34338': '93.81',
  '34339': '93.81',
  '34340': '93.81',
  '34341': '93.81',
  '34342': '93.82',
  '34343': '93.82',
  '34344': '93.82',
  '34345': '93.82',
  '34346': '93.82',
  '34347': '93.82',
  '34348': '93.83',
  '34349': '93.83',
  '34350': '93.83',
  '34351': '93.83',
  '34352': '93.83',
  '34353': '93.83',
  '34354': '93.84',
  '34355': '93.84',
  '34356': '93.84',
  '34357': '93.84',
  '34358': '93.84',
  '34359': '93.85',
  '34360': '93.85',
  '34361': '93.85',
  '34362': '93.85',
  '34363': '93.85',
  '34364': '93.86',
  '34365': '93.86',
  '34366': '93.86',
  '34367': '93.86',
  '34368': '93.86',
  '34369': '93.86',
  '34370': '93.87',
  '34371': '93.87',
  '34372': '93.87',
  '34373': '93.87',
  '34374': '93.87',
  '34375': '93.88',
  '34376': '93.88',
  '34377': '93.88',
  '34378': '93.88',
  '34379': '93.88',
  '34380': '93.88',
  '34381': '93.89',
  '34382': '93.89',
  '34383': '93.89',
  '34384': '93.89',
  '34385': '93.89',
  '34386': '93.89',
  '34387': '93.90',
  '34388': '93.90',
  '34389': '93.90',
  '34390': '93.90',
  '34391': '93.90',
  '34392': '93.90',
  '34393': '93.91',
  '34394': '93.91',
  '34395': '93.91',
  '34396': '93.91',
  '34397': '93.91',
  '34398': '93.92',
  '34399': '93.92',
  '34400': '93.92',
  '34401': '93.92',
  '34402': '93.92',
  '34403': '93.93',
  '34404': '93.93',
  '34405': '93.93',
  '34406': '93.93',
  '34407': '93.93',
  '34408': '93.93',
  '34409': '93.94',
  '34410': '93.94',
  '34411': '93.94',
  '34412': '93.94',
  '34413': '93.94',
  '34414': '93.94',
  '34415': '93.95',
  '34416': '93.95',
  '34417': '93.95',
  '34418': '93.95',
  '34419': '93.95',
  '34420': '93.96',
  '34421': '93.96',
  '34422': '93.96',
  '34423': '93.96',
  '34424': '93.96',
  '34425': '93.96',
  '34426': '93.97',
  '34427': '93.97',
  '34428': '93.97',
  '34429': '93.97',
  '34430': '93.97',
  '34431': '93.97',
  '34432': '93.98',
  '34433': '93.98',
  '34434': '93.98',
  '34435': '93.98',
  '34436': '93.98',
  '34437': '93.99',
  '34438': '93.99',
  '34439': '93.99',
  '34440': '93.99',
  '34441': '93.99',
  '34442': '94.00',
  '34443': '94.00',
  '34444': '94.00',
  '34445': '94.00',
  '34446': '94.00',
  '34447': '94.00',
  '34448': '94.01',
  '34449': '94.01',
  '34450': '94.01',
  '34451': '94.01',
  '34452': '94.01',
  '34453': '94.01',
  '34454': '94.02',
  '34455': '94.02',
  '34456': '94.02',
  '34457': '94.02',
  '34458': '94.02',
  '34459': '94.03',
  '34460': '94.03',
  '34461': '94.03',
  '34462': '94.03',
  '34463': '94.03',
  '34464': '94.04',
  '34465': '94.04',
  '34466': '94.04',
  '34467': '94.04',
  '34468': '94.04',
  '34469': '94.04',
  '34470': '94.05',
  '34471': '94.05',
  '34472': '94.05',
  '34473': '94.05',
  '34474': '94.05',
  '34475': '94.06',
  '34476': '94.06',
  '34477': '94.06',
  '34478': '94.06',
  '34479': '94.06',
  '34480': '94.06',
  '34481': '94.07',
  '34482': '94.07',
  '34483': '94.07',
  '34484': '94.07',
  '34485': '94.07',
  '34486': '94.07',
  '34487': '94.08',
  '34488': '94.08',
  '34489': '94.08',
  '34490': '94.08',
  '34491': '94.08',
  '34492': '94.08',
  '34493': '94.09',
  '34494': '94.09',
  '34495': '94.09',
  '34496': '94.09',
  '34497': '94.09',
  '34498': '94.10',
  '34499': '94.10',
  '34500': '94.10',
  '34501': '94.10',
  '34502': '94.10',
  '34503': '94.11',
  '34504': '94.11',
  '34505': '94.11',
  '34506': '94.11',
  '34507': '94.11',
  '34508': '94.11',
  '34509': '94.12',
  '34510': '94.12',
  '34511': '94.12',
  '34512': '94.12',
  '34513': '94.12',
  '34514': '94.12',
  '34515': '94.13',
  '34516': '94.13',
  '34517': '94.13',
  '34518': '94.13',
  '34519': '94.13',
  '34520': '94.14',
  '34521': '94.14',
  '34522': '94.14',
  '34523': '94.14',
  '34524': '94.14',
  '34525': '94.14',
  '34526': '94.15',
  '34527': '94.15',
  '34528': '94.15',
  '34529': '94.15',
  '34530': '94.15',
  '34531': '94.15',
  '34532': '94.16',
  '34533': '94.16',
  '34534': '94.16',
  '34535': '94.16',
  '34536': '94.16',
  '34537': '94.17',
  '34538': '94.17',
  '34539': '94.17',
  '34540': '94.17',
  '34541': '94.17',
  '34542': '94.18',
  '34543': '94.18',
  '34544': '94.18',
  '34545': '94.18',
  '34546': '94.18',
  '34547': '94.18',
  '34548': '94.19',
  '34549': '94.19',
  '34550': '94.19',
  '34551': '94.19',
  '34552': '94.19',
  '34553': '94.19',
  '34554': '94.20',
  '34555': '94.20',
  '34556': '94.20',
  '34557': '94.20',
  '34558': '94.20',
  '34559': '94.21',
  '34560': '94.21',
  '34561': '94.21',
  '34562': '94.21',
  '34563': '94.21',
  '34564': '94.21',
  '34565': '94.22',
  '34566': '94.22',
  '34567': '94.22',
  '34568': '94.22',
  '34569': '94.22',
  '34570': '94.23',
  '34571': '94.23',
  '34572': '94.23',
  '34573': '94.23',
  '34574': '94.23',
  '34575': '94.24',
  '34576': '94.24',
  '34577': '94.24',
  '34578': '94.24',
  '34579': '94.24',
  '34580': '94.24',
  '34581': '94.25',
  '34582': '94.25',
  '34583': '94.25',
  '34584': '94.25',
  '34585': '94.25',
  '34586': '94.25',
  '34587': '94.26',
  '34588': '94.26',
  '34589': '94.26',
  '34590': '94.26',
  '34591': '94.26',
  '34592': '94.26',
  '34593': '94.27',
  '34594': '94.27',
  '34595': '94.27',
  '34596': '94.27',
  '34597': '94.27',
  '34598': '94.28',
  '34599': '94.28',
  '34600': '94.28',
  '34601': '94.28',
  '34602': '94.28',
  '34603': '94.29',
  '34604': '94.29',
  '34605': '94.29',
  '34606': '94.29',
  '34607': '94.29',
  '34608': '94.29',
  '34609': '94.30',
  '34610': '94.30',
  '34611': '94.30',
  '34612': '94.30',
  '34613': '94.30',
  '34614': '94.31',
  '34615': '94.31',
  '34616': '94.31',
  '34617': '94.31',
  '34618': '94.31',
  '34619': '94.31',
  '34620': '94.32',
  '34621': '94.32',
  '34622': '94.32',
  '34623': '94.32',
  '34624': '94.32',
  '34625': '94.32',
  '34626': '94.33',
  '34627': '94.33',
  '34628': '94.33',
  '34629': '94.33',
  '34630': '94.33',
  '34631': '94.33',
  '34632': '94.34',
  '34633': '94.34',
  '34634': '94.34',
  '34635': '94.34',
  '34636': '94.34',
  '34637': '94.35',
  '34638': '94.35',
  '34639': '94.35',
  '34640': '94.35',
  '34641': '94.35',
  '34642': '94.36',
  '34643': '94.36',
  '34644': '94.36',
  '34645': '94.36',
  '34646': '94.36',
  '34647': '94.36',
  '34648': '94.37',
  '34649': '94.37',
  '34650': '94.37',
  '34651': '94.37',
  '34652': '94.37',
  '34653': '94.38',
  '34654': '94.38',
  '34655': '94.38',
  '34656': '94.38',
  '34657': '94.38',
  '34658': '94.38',
  '34659': '94.39',
  '34660': '94.39',
  '34661': '94.39',
  '34662': '94.39',
  '34663': '94.39',
  '34664': '94.39',
  '34665': '94.40',
  '34666': '94.40',
  '34667': '94.40',
  '34668': '94.40',
  '34669': '94.40',
  '34670': '94.41',
  '34671': '94.41',
  '34672': '94.41',
  '34673': '94.41',
  '34674': '94.41',
  '34675': '94.42',
  '34676': '94.42',
  '34677': '94.42',
  '34678': '94.42',
  '34679': '94.42',
  '34680': '94.42',
  '34681': '94.43',
  '34682': '94.43',
  '34683': '94.43',
  '34684': '94.43',
  '34685': '94.43',
  '34686': '94.43',
  '34687': '94.44',
  '34688': '94.44',
  '34689': '94.44',
  '34690': '94.44',
  '34691': '94.44',
  '34692': '94.44',
  '34693': '94.45',
  '34694': '94.45',
  '34695': '94.45',
  '34696': '94.45',
  '34697': '94.45',
  '34698': '94.46',
  '34699': '94.46',
  '34700': '94.46',
  '34701': '94.46',
  '34702': '94.46',
  '34703': '94.46',
  '34704': '94.47',
  '34705': '94.47',
  '34706': '94.47',
  '34707': '94.47',
  '34708': '94.47',
  '34709': '94.48',
  '34710': '94.48',
  '34711': '94.48',
  '34712': '94.48',
  '34713': '94.48',
  '34714': '94.49',
  '34715': '94.49',
  '34716': '94.49',
  '34717': '94.49',
  '34718': '94.49',
  '34719': '94.49',
  '34720': '94.50',
  '34721': '94.50',
  '34722': '94.50',
  '34723': '94.50',
  '34724': '94.50',
  '34725': '94.50',
  '34726': '94.51',
  '34727': '94.51',
  '34728': '94.51',
  '34729': '94.51',
  '34730': '94.51',
  '34731': '94.51',
  '34732': '94.52',
  '34733': '94.52',
  '34734': '94.52',
  '34735': '94.52',
  '34736': '94.52',
  '34737': '94.53',
  '34738': '94.53',
  '34739': '94.53',
  '34740': '94.53',
  '34741': '94.53',
  '34742': '94.54',
  '34743': '94.54',
  '34744': '94.54',
  '34745': '94.54',
  '34746': '94.54',
  '34747': '94.54',
  '34748': '94.55',
  '34749': '94.55',
  '34750': '94.55',
  '34751': '94.55',
  '34752': '94.55',
  '34753': '94.56',
  '34754': '94.56',
  '34755': '94.56',
  '34756': '94.56',
  '34757': '94.56',
  '34758': '94.56',
  '34759': '94.57',
  '34760': '94.57',
  '34761': '94.57',
  '34762': '94.57',
  '34763': '94.57',
  '34764': '94.57',
  '34765': '94.58',
  '34766': '94.58',
  '34767': '94.58',
  '34768': '94.58',
  '34769': '94.58',
  '34770': '94.59',
  '34771': '94.59',
  '34772': '94.59',
  '34773': '94.59',
  '34774': '94.59',
  '34775': '94.60',
  '34776': '94.60',
  '34777': '94.60',
  '34778': '94.60',
  '34779': '94.60',
  '34780': '94.60',
  '34781': '94.61',
  '34782': '94.61',
  '34783': '94.61',
  '34784': '94.61',
  '34785': '94.61',
  '34786': '94.61',
  '34787': '94.62',
  '34788': '94.62',
  '34789': '94.62',
  '34790': '94.62',
  '34791': '94.62',
  '34792': '94.62',
  '34793': '94.63',
  '34794': '94.63',
  '34795': '94.63',
  '34796': '94.63',
  '34797': '94.63',
  '34798': '94.64',
  '34799': '94.64',
  '34800': '94.64',
  '34801': '94.64',
  '34802': '94.64',
  '34803': '94.64',
  '34804': '94.65',
  '34805': '94.65',
  '34806': '94.65',
  '34807': '94.65',
  '34808': '94.65',
  '34809': '94.66',
  '34810': '94.66',
  '34811': '94.66',
  '34812': '94.66',
  '34813': '94.66',
  '34814': '94.67',
  '34815': '94.67',
  '34816': '94.67',
  '34817': '94.67',
  '34818': '94.67',
  '34819': '94.67',
  '34820': '94.68',
  '34821': '94.68',
  '34822': '94.68',
  '34823': '94.68',
  '34824': '94.68',
  '34825': '94.68',
  '34826': '94.69',
  '34827': '94.69',
  '34828': '94.69',
  '34829': '94.69',
  '34830': '94.69',
  '34831': '94.69',
  '34832': '94.70',
  '34833': '94.70',
  '34834': '94.70',
  '34835': '94.70',
  '34836': '94.70',
  '34837': '94.71',
  '34838': '94.71',
  '34839': '94.71',
  '34840': '94.71',
  '34841': '94.71',
  '34842': '94.71',
  '34843': '94.72',
  '34844': '94.72',
  '34845': '94.72',
  '34846': '94.72',
  '34847': '94.72',
  '34848': '94.73',
  '34849': '94.73',
  '34850': '94.73',
  '34851': '94.73',
  '34852': '94.73',
  '34853': '94.74',
  '34854': '94.74',
  '34855': '94.74',
  '34856': '94.74',
  '34857': '94.74',
  '34858': '94.74',
  '34859': '94.75',
  '34860': '94.75',
  '34861': '94.75',
  '34862': '94.75',
  '34863': '94.75',
  '34864': '94.75',
  '34865': '94.76',
  '34866': '94.76',
  '34867': '94.76',
  '34868': '94.76',
  '34869': '94.76',
  '34870': '94.77',
  '34871': '94.77',
  '34872': '94.77',
  '34873': '94.77',
  '34874': '94.77',
  '34875': '94.78',
  '34876': '94.78',
  '34877': '94.78',
  '34878': '94.78',
  '34879': '94.78',
  '34880': '94.78',
  '34881': '94.79',
  '34882': '94.79',
  '34883': '94.79',
  '34884': '94.79',
  '34885': '94.79',
  '34886': '94.79',
  '34887': '94.80',
  '34888': '94.80',
  '34889': '94.80',
  '34890': '94.80',
  '34891': '94.80',
  '34892': '94.81',
  '34893': '94.81',
  '34894': '94.81',
  '34895': '94.81',
  '34896': '94.81',
  '34897': '94.81',
  '34898': '94.82',
  '34899': '94.82',
  '34900': '94.82',
  '34901': '94.82',
  '34902': '94.82',
  '34903': '94.82',
  '34904': '94.83',
  '34905': '94.83',
  '34906': '94.83',
  '34907': '94.83',
  '34908': '94.83',
  '34909': '94.84',
  '34910': '94.84',
  '34911': '94.84',
  '34912': '94.84',
  '34913': '94.84',
  '34914': '94.85',
  '34915': '94.85',
  '34916': '94.85',
  '34917': '94.85',
  '34918': '94.85',
  '34919': '94.85',
  '34920': '94.86',
  '34921': '94.86',
  '34922': '94.86',
  '34923': '94.86',
  '34924': '94.86',
  '34925': '94.86',
  '34926': '94.87',
  '34927': '94.87',
  '34928': '94.87',
  '34929': '94.87',
  '34930': '94.87',
  '34931': '94.88',
  '34932': '94.88',
  '34933': '94.88',
  '34934': '94.88',
  '34935': '94.88',
  '34936': '94.88',
  '34937': '94.89',
  '34938': '94.89',
  '34939': '94.89',
  '34940': '94.89',
  '34941': '94.89',
  '34942': '94.89',
  '34943': '94.90',
  '34944': '94.90',
  '34945': '94.90',
  '34946': '94.90',
  '34947': '94.90',
  '34948': '94.91',
  '34949': '94.91',
  '34950': '94.91',
  '34951': '94.91',
  '34952': '94.91',
  '34953': '94.92',
  '34954': '94.92',
  '34955': '94.92',
  '34956': '94.92',
  '34957': '94.92',
  '34958': '94.92',
  '34959': '94.93',
  '34960': '94.93',
  '34961': '94.93',
  '34962': '94.93',
  '34963': '94.93',
  '34964': '94.93',
  '34965': '94.94',
  '34966': '94.94',
  '34967': '94.94',
  '34968': '94.94',
  '34969': '94.94',
  '34970': '94.95',
  '34971': '94.95',
  '34972': '94.95',
  '34973': '94.95',
  '34974': '94.95',
  '34975': '94.96',
  '34976': '94.96',
  '34977': '94.96',
  '34978': '94.96',
  '34979': '94.96',
  '34980': '94.96',
  '34981': '94.96',
  '34982': '94.97',
  '34983': '94.97',
  '34984': '94.97',
  '34985': '94.97',
  '34986': '94.97',
  '34987': '94.98',
  '34988': '94.98',
  '34989': '94.98',
  '34990': '94.98',
  '34991': '94.98',
  '34992': '94.99',
  '34993': '94.99',
  '34994': '94.99',
  '34995': '94.99',
  '34996': '94.99',
  '34997': '94.99',
  '34998': '95.00',
  '34999': '95.00',
  '35000': '95.00',
  '35001': '95.00',
  '35002': '95.00',
  '35003': '95.00',
  '35004': '95.01',
  '35005': '95.01',
  '35006': '95.01',
  '35007': '95.01',
  '35008': '95.01',
  '35009': '95.02',
  '35010': '95.02',
  '35011': '95.02',
  '35012': '95.02',
  '35013': '95.02',
  '35014': '95.03',
  '35015': '95.03',
  '35016': '95.03',
  '35017': '95.03',
  '35018': '95.03',
  '35019': '95.03',
  '35020': '95.04',
  '35021': '95.04',
  '35022': '95.04',
  '35023': '95.04',
  '35024': '95.04',
  '35025': '95.04',
  '35026': '95.05',
  '35027': '95.05',
  '35028': '95.05',
  '35029': '95.05',
  '35030': '95.05',
  '35031': '95.06',
  '35032': '95.06',
  '35033': '95.06',
  '35034': '95.06',
  '35035': '95.06',
  '35036': '95.06',
  '35037': '95.07',
  '35038': '95.07',
  '35039': '95.07',
  '35040': '95.07',
  '35041': '95.07',
  '35042': '95.07',
  '35043': '95.08',
  '35044': '95.08',
  '35045': '95.08',
  '35046': '95.08',
  '35047': '95.08',
  '35048': '95.09',
  '35049': '95.09',
  '35050': '95.09',
  '35051': '95.09',
  '35052': '95.09',
  '35053': '95.10',
  '35054': '95.10',
  '35055': '95.10',
  '35056': '95.10',
  '35057': '95.10',
  '35058': '95.10',
  '35059': '95.11',
  '35060': '95.11',
  '35061': '95.11',
  '35062': '95.11',
  '35063': '95.11',
  '35064': '95.11',
  '35065': '95.12',
  '35066': '95.12',
  '35067': '95.12',
  '35068': '95.12',
  '35069': '95.12',
  '35070': '95.13',
  '35071': '95.13',
  '35072': '95.13',
  '35073': '95.13',
  '35074': '95.13',
  '35075': '95.14',
  '35076': '95.14',
  '35077': '95.14',
  '35078': '95.14',
  '35079': '95.14',
  '35080': '95.14',
  '35081': '95.14',
  '35082': '95.15',
  '35083': '95.15',
  '35084': '95.15',
  '35085': '95.15',
  '35086': '95.15',
  '35087': '95.16',
  '35088': '95.16',
  '35089': '95.16',
  '35090': '95.16',
  '35091': '95.16',
  '35092': '95.17',
  '35093': '95.17',
  '35094': '95.17',
  '35095': '95.17',
  '35096': '95.17',
  '35097': '95.17',
  '35098': '95.18',
  '35099': '95.18',
  '35100': '95.18',
  '35101': '95.18',
  '35102': '95.18',
  '35103': '95.18',
  '35104': '95.19',
  '35105': '95.19',
  '35106': '95.19',
  '35107': '95.19',
  '35108': '95.19',
  '35109': '95.20',
  '35110': '95.20',
  '35111': '95.20',
  '35112': '95.20',
  '35113': '95.20',
  '35114': '95.21',
  '35115': '95.21',
  '35116': '95.21',
  '35117': '95.21',
  '35118': '95.21',
  '35119': '95.21',
  '35120': '95.22',
  '35121': '95.22',
  '35122': '95.22',
  '35123': '95.22',
  '35124': '95.22',
  '35125': '95.22',
  '35126': '95.23',
  '35127': '95.23',
  '35128': '95.23',
  '35129': '95.23',
  '35130': '95.23',
  '35131': '95.24',
  '35132': '95.24',
  '35133': '95.24',
  '35134': '95.24',
  '35135': '95.24',
  '35136': '95.24',
  '35137': '95.25',
  '35138': '95.25',
  '35139': '95.25',
  '35140': '95.25',
  '35141': '95.25',
  '35142': '95.25',
  '35143': '95.26',
  '35144': '95.26',
  '35145': '95.26',
  '35146': '95.26',
  '35147': '95.26',
  '35148': '95.27',
  '35149': '95.27',
  '35150': '95.27',
  '35151': '95.27',
  '35152': '95.27',
  '35153': '95.28',
  '35154': '95.28',
  '35155': '95.28',
  '35156': '95.28',
  '35157': '95.28',
  '35158': '95.28',
  '35159': '95.29',
  '35160': '95.29',
  '35161': '95.29',
  '35162': '95.29',
  '35163': '95.29',
  '35164': '95.29',
  '35165': '95.30',
  '35166': '95.30',
  '35167': '95.30',
  '35168': '95.30',
  '35169': '95.30',
  '35170': '95.31',
  '35171': '95.31',
  '35172': '95.31',
  '35173': '95.31',
  '35174': '95.31',
  '35175': '95.32',
  '35176': '95.32',
  '35177': '95.32',
  '35178': '95.32',
  '35179': '95.32',
  '35180': '95.32',
  '35181': '95.32',
  '35182': '95.33',
  '35183': '95.33',
  '35184': '95.33',
  '35185': '95.33',
  '35186': '95.33',
  '35187': '95.34',
  '35188': '95.34',
  '35189': '95.34',
  '35190': '95.34',
  '35191': '95.34',
  '35192': '95.35',
  '35193': '95.35',
  '35194': '95.35',
  '35195': '95.35',
  '35196': '95.35',
  '35197': '95.35',
  '35198': '95.36',
  '35199': '95.36',
  '35200': '95.36',
  '35201': '95.36',
  '35202': '95.36',
  '35203': '95.36',
  '35204': '95.37',
  '35205': '95.37',
  '35206': '95.37',
  '35207': '95.37',
  '35208': '95.37',
  '35209': '95.38',
  '35210': '95.38',
  '35211': '95.38',
  '35212': '95.38',
  '35213': '95.38',
  '35214': '95.39',
  '35215': '95.39',
  '35216': '95.39',
  '35217': '95.39',
  '35218': '95.39',
  '35219': '95.39',
  '35220': '95.40',
  '35221': '95.40',
  '35222': '95.40',
  '35223': '95.40',
  '35224': '95.40',
  '35225': '95.40',
  '35226': '95.41',
  '35227': '95.41',
  '35228': '95.41',
  '35229': '95.41',
  '35230': '95.41',
  '35231': '95.42',
  '35232': '95.42',
  '35233': '95.42',
  '35234': '95.42',
  '35235': '95.42',
  '35236': '95.42',
  '35237': '95.43',
  '35238': '95.43',
  '35239': '95.43',
  '35240': '95.43',
  '35241': '95.43',
  '35242': '95.43',
  '35243': '95.44',
  '35244': '95.44',
  '35245': '95.44',
  '35246': '95.44',
  '35247': '95.44',
  '35248': '95.45',
  '35249': '95.45',
  '35250': '95.45',
  '35251': '95.45',
  '35252': '95.45',
  '35253': '95.46',
  '35254': '95.46',
  '35255': '95.46',
  '35256': '95.46',
  '35257': '95.46',
  '35258': '95.46',
  '35259': '95.47',
  '35260': '95.47',
  '35261': '95.47',
  '35262': '95.47',
  '35263': '95.47',
  '35264': '95.47',
  '35265': '95.48',
  '35266': '95.48',
  '35267': '95.48',
  '35268': '95.48',
  '35269': '95.48',
  '35270': '95.49',
  '35271': '95.49',
  '35272': '95.49',
  '35273': '95.49',
  '35274': '95.49',
  '35275': '95.50',
  '35276': '95.50',
  '35277': '95.50',
  '35278': '95.50',
  '35279': '95.50',
  '35280': '95.50',
  '35281': '95.50',
  '35282': '95.51',
  '35283': '95.51',
  '35284': '95.51',
  '35285': '95.51',
  '35286': '95.51',
  '35287': '95.52',
  '35288': '95.52',
  '35289': '95.52',
  '35290': '95.52',
  '35291': '95.52',
  '35292': '95.53',
  '35293': '95.53',
  '35294': '95.53',
  '35295': '95.53',
  '35296': '95.53',
  '35297': '95.53',
  '35298': '95.54',
  '35299': '95.54',
  '35300': '95.54',
  '35301': '95.54',
  '35302': '95.54',
  '35303': '95.54',
  '35304': '95.55',
  '35305': '95.55',
  '35306': '95.55',
  '35307': '95.55',
  '35308': '95.55',
  '35309': '95.56',
  '35310': '95.56',
  '35311': '95.56',
  '35312': '95.56',
  '35313': '95.56',
  '35314': '95.57',
  '35315': '95.57',
  '35316': '95.57',
  '35317': '95.57',
  '35318': '95.57',
  '35319': '95.57',
  '35320': '95.58',
  '35321': '95.58',
  '35322': '95.58',
  '35323': '95.58',
  '35324': '95.58',
  '35325': '95.58',
  '35326': '95.59',
  '35327': '95.59',
  '35328': '95.59',
  '35329': '95.59',
  '35330': '95.59',
  '35331': '95.60',
  '35332': '95.60',
  '35333': '95.60',
  '35334': '95.60',
  '35335': '95.60',
  '35336': '95.60',
  '35337': '95.61',
  '35338': '95.61',
  '35339': '95.61',
  '35340': '95.61',
  '35341': '95.61',
  '35342': '95.61',
  '35343': '95.62',
  '35344': '95.62',
  '35345': '95.62',
  '35346': '95.62',
  '35347': '95.62',
  '35348': '95.63',
  '35349': '95.63',
  '35350': '95.63',
  '35351': '95.63',
  '35352': '95.63',
  '35353': '95.64',
  '35354': '95.64',
  '35355': '95.64',
  '35356': '95.64',
  '35357': '95.64',
  '35358': '95.64',
  '35359': '95.65',
  '35360': '95.65',
  '35361': '95.65',
  '35362': '95.65',
  '35363': '95.65',
  '35364': '95.65',
  '35365': '95.66',
  '35366': '95.66',
  '35367': '95.66',
  '35368': '95.66',
  '35369': '95.66',
  '35370': '95.67',
  '35371': '95.67',
  '35372': '95.67',
  '35373': '95.67',
  '35374': '95.67',
  '35375': '95.68',
  '35376': '95.68',
  '35377': '95.68',
  '35378': '95.68',
  '35379': '95.68',
  '35380': '95.68',
  '35381': '95.68',
  '35382': '95.69',
  '35383': '95.69',
  '35384': '95.69',
  '35385': '95.69',
  '35386': '95.69',
  '35387': '95.70',
  '35388': '95.70',
  '35389': '95.70',
  '35390': '95.70',
  '35391': '95.70',
  '35392': '95.71',
  '35393': '95.71',
  '35394': '95.71',
  '35395': '95.71',
  '35396': '95.71',
  '35397': '95.71',
  '35398': '95.72',
  '35399': '95.72',
  '35400': '95.72',
  '35401': '95.72',
  '35402': '95.72',
  '35403': '95.72',
  '35404': '95.73',
  '35405': '95.73',
  '35406': '95.73',
  '35407': '95.73',
  '35408': '95.73',
  '35409': '95.74',
  '35410': '95.74',
  '35411': '95.74',
  '35412': '95.74',
  '35413': '95.74',
  '35414': '95.75',
  '35415': '95.75',
  '35416': '95.75',
  '35417': '95.75',
  '35418': '95.75',
  '35419': '95.75',
  '35420': '95.76',
  '35421': '95.76',
  '35422': '95.76',
  '35423': '95.76',
  '35424': '95.76',
  '35425': '95.76',
  '35426': '95.77',
  '35427': '95.77',
  '35428': '95.77',
  '35429': '95.77',
  '35430': '95.77',
  '35431': '95.78',
  '35432': '95.78',
  '35433': '95.78',
  '35434': '95.78',
  '35435': '95.78',
  '35436': '95.78',
  '35437': '95.79',
  '35438': '95.79',
  '35439': '95.79',
  '35440': '95.79',
  '35441': '95.79',
  '35442': '95.79',
  '35443': '95.80',
  '35444': '95.80',
  '35445': '95.80',
  '35446': '95.80',
  '35447': '95.80',
  '35448': '95.81',
  '35449': '95.81',
  '35450': '95.81',
  '35451': '95.81',
  '35452': '95.81',
  '35453': '95.82',
  '35454': '95.82',
  '35455': '95.82',
  '35456': '95.82',
  '35457': '95.82',
  '35458': '95.82',
  '35459': '95.83',
  '35460': '95.83',
  '35461': '95.83',
  '35462': '95.83',
  '35463': '95.83',
  '35464': '95.83',
  '35465': '95.84',
  '35466': '95.84',
  '35467': '95.84',
  '35468': '95.84',
  '35469': '95.84',
  '35470': '95.85',
  '35471': '95.85',
  '35472': '95.85',
  '35473': '95.85',
  '35474': '95.85',
  '35475': '95.86',
  '35476': '95.86',
  '35477': '95.86',
  '35478': '95.86',
  '35479': '95.86',
  '35480': '95.86',
  '35481': '95.86',
  '35482': '95.87',
  '35483': '95.87',
  '35484': '95.87',
  '35485': '95.87',
  '35486': '95.87',
  '35487': '95.88',
  '35488': '95.88',
  '35489': '95.88',
  '35490': '95.88',
  '35491': '95.88',
  '35492': '95.89',
  '35493': '95.89',
  '35494': '95.89',
  '35495': '95.89',
  '35496': '95.89',
  '35497': '95.89',
  '35498': '95.90',
  '35499': '95.90',
  '35500': '95.90',
  '35501': '95.90',
  '35502': '95.90',
  '35503': '95.90',
  '35504': '95.91',
  '35505': '95.91',
  '35506': '95.91',
  '35507': '95.91',
  '35508': '95.91',
  '35509': '95.92',
  '35510': '95.92',
  '35511': '95.92',
  '35512': '95.92',
  '35513': '95.92',
  '35514': '95.93',
  '35515': '95.93',
  '35516': '95.93',
  '35517': '95.93',
  '35518': '95.93',
  '35519': '95.93',
  '35520': '95.94',
  '35521': '95.94',
  '35522': '95.94',
  '35523': '95.94',
  '35524': '95.94',
  '35525': '95.94',
  '35526': '95.95',
  '35527': '95.95',
  '35528': '95.95',
  '35529': '95.95',
  '35530': '95.95',
  '35531': '95.96',
  '35532': '95.96',
  '35533': '95.96',
  '35534': '95.96',
  '35535': '95.96',
  '35536': '95.96',
  '35537': '95.97',
  '35538': '95.97',
  '35539': '95.97',
  '35540': '95.97',
  '35541': '95.97',
  '35542': '95.97',
  '35543': '95.98',
  '35544': '95.98',
  '35545': '95.98',
  '35546': '95.98',
  '35547': '95.98',
  '35548': '95.99',
  '35549': '95.99',
  '35550': '95.99',
  '35551': '95.99',
  '35552': '95.99',
  '35553': '96.00',
  '35554': '96.00',
  '35555': '96.00',
  '35556': '96.00',
  '35557': '96.00',
  '35558': '96.00',
  '35559': '96.01',
  '35560': '96.01',
  '35561': '96.01',
  '35562': '96.01',
  '35563': '96.01',
  '35564': '96.01',
  '35565': '96.02',
  '35566': '96.02',
  '35567': '96.02',
  '35568': '96.02',
  '35569': '96.02',
  '35570': '96.03',
  '35571': '96.03',
  '35572': '96.03',
  '35573': '96.03',
  '35574': '96.03',
  '35575': '96.04',
  '35576': '96.04',
  '35577': '96.04',
  '35578': '96.04',
  '35579': '96.04',
  '35580': '96.04',
  '35581': '96.04',
  '35582': '96.05',
  '35583': '96.05',
  '35584': '96.05',
  '35585': '96.05',
  '35586': '96.05',
  '35587': '96.06',
  '35588': '96.06',
  '35589': '96.06',
  '35590': '96.06',
  '35591': '96.06',
  '35592': '96.07',
  '35593': '96.07',
  '35594': '96.07',
  '35595': '96.07',
  '35596': '96.07',
  '35597': '96.07',
  '35598': '96.08',
  '35599': '96.08',
  '35600': '96.08',
  '35601': '96.08',
  '35602': '96.08',
  '35603': '96.08',
  '35604': '96.09',
  '35605': '96.09',
  '35606': '96.09',
  '35607': '96.09',
  '35608': '96.09',
  '35609': '96.10',
  '35610': '96.10',
  '35611': '96.10',
  '35612': '96.10',
  '35613': '96.10',
  '35614': '96.11',
  '35615': '96.11',
  '35616': '96.11',
  '35617': '96.11',
  '35618': '96.11',
  '35619': '96.11',
  '35620': '96.12',
  '35621': '96.12',
  '35622': '96.12',
  '35623': '96.12',
  '35624': '96.12',
  '35625': '96.12',
  '35626': '96.13',
  '35627': '96.13',
  '35628': '96.13',
  '35629': '96.13',
  '35630': '96.13',
  '35631': '96.14',
  '35632': '96.14',
  '35633': '96.14',
  '35634': '96.14',
  '35635': '96.14',
  '35636': '96.14',
  '35637': '96.15',
  '35638': '96.15',
  '35639': '96.15',
  '35640': '96.15',
  '35641': '96.15',
  '35642': '96.15',
  '35643': '96.16',
  '35644': '96.16',
  '35645': '96.16',
  '35646': '96.16',
  '35647': '96.16',
  '35648': '96.17',
  '35649': '96.17',
  '35650': '96.17',
  '35651': '96.17',
  '35652': '96.17',
  '35653': '96.18',
  '35654': '96.18',
  '35655': '96.18',
  '35656': '96.18',
  '35657': '96.18',
  '35658': '96.18',
  '35659': '96.19',
  '35660': '96.19',
  '35661': '96.19',
  '35662': '96.19',
  '35663': '96.19',
  '35664': '96.19',
  '35665': '96.20',
  '35666': '96.20',
  '35667': '96.20',
  '35668': '96.20',
  '35669': '96.20',
  '35670': '96.21',
  '35671': '96.21',
  '35672': '96.21',
  '35673': '96.21',
  '35674': '96.21',
  '35675': '96.21',
  '35676': '96.22',
  '35677': '96.22',
  '35678': '96.22',
  '35679': '96.22',
  '35680': '96.22',
  '35681': '96.22',
  '35682': '96.23',
  '35683': '96.23',
  '35684': '96.23',
  '35685': '96.23',
  '35686': '96.23',
  '35687': '96.24',
  '35688': '96.24',
  '35689': '96.24',
  '35690': '96.24',
  '35691': '96.24',
  '35692': '96.25',
  '35693': '96.25',
  '35694': '96.25',
  '35695': '96.25',
  '35696': '96.25',
  '35697': '96.25',
  '35698': '96.26',
  '35699': '96.26',
  '35700': '96.26',
  '35701': '96.26',
  '35702': '96.26',
  '35703': '96.26',
  '35704': '96.27',
  '35705': '96.27',
  '35706': '96.27',
  '35707': '96.27',
  '35708': '96.27',
  '35709': '96.28',
  '35710': '96.28',
  '35711': '96.28',
  '35712': '96.28',
  '35713': '96.28',
  '35714': '96.29',
  '35715': '96.29',
  '35716': '96.29',
  '35717': '96.29',
  '35718': '96.29',
  '35719': '96.29',
  '35720': '96.30',
  '35721': '96.30',
  '35722': '96.30',
  '35723': '96.30',
  '35724': '96.30',
  '35725': '96.31',
  '35726': '96.31',
  '35727': '96.31',
  '35728': '96.31',
  '35729': '96.31',
  '35730': '96.31',
  '35731': '96.32',
  '35732': '96.32',
  '35733': '96.32',
  '35734': '96.32',
  '35735': '96.32',
  '35736': '96.32',
  '35737': '96.33',
  '35738': '96.33',
  '35739': '96.33',
  '35740': '96.33',
  '35741': '96.33',
  '35742': '96.33',
  '35743': '96.34',
  '35744': '96.34',
  '35745': '96.34',
  '35746': '96.34',
  '35747': '96.34',
  '35748': '96.35',
  '35749': '96.35',
  '35750': '96.35',
  '35751': '96.35',
  '35752': '96.35',
  '35753': '96.36',
  '35754': '96.36',
  '35755': '96.36',
  '35756': '96.36',
  '35757': '96.36',
  '35758': '96.36',
  '35759': '96.37',
  '35760': '96.37',
  '35761': '96.37',
  '35762': '96.37',
  '35763': '96.37',
  '35764': '96.38',
  '35765': '96.38',
  '35766': '96.38',
  '35767': '96.38',
  '35768': '96.38',
  '35769': '96.38',
  '35770': '96.39',
  '35771': '96.39',
  '35772': '96.39',
  '35773': '96.39',
  '35774': '96.39',
  '35775': '96.39',
  '35776': '96.40',
  '35777': '96.40',
  '35778': '96.40',
  '35779': '96.40',
  '35780': '96.40',
  '35781': '96.40',
  '35782': '96.41',
  '35783': '96.41',
  '35784': '96.41',
  '35785': '96.41',
  '35786': '96.41',
  '35787': '96.42',
  '35788': '96.42',
  '35789': '96.42',
  '35790': '96.42',
  '35791': '96.42',
  '35792': '96.43',
  '35793': '96.43',
  '35794': '96.43',
  '35795': '96.43',
  '35796': '96.43',
  '35797': '96.43',
  '35798': '96.44',
  '35799': '96.44',
  '35800': '96.44',
  '35801': '96.44',
  '35802': '96.44',
  '35803': '96.44',
  '35804': '96.45',
  '35805': '96.45',
  '35806': '96.45',
  '35807': '96.45',
  '35808': '96.45',
  '35809': '96.46',
  '35810': '96.46',
  '35811': '96.46',
  '35812': '96.46',
  '35813': '96.46',
  '35814': '96.46',
  '35815': '96.47',
  '35816': '96.47',
  '35817': '96.47',
  '35818': '96.47',
  '35819': '96.47',
  '35820': '96.48',
  '35821': '96.48',
  '35822': '96.48',
  '35823': '96.48',
  '35824': '96.48',
  '35825': '96.49',
  '35826': '96.49',
  '35827': '96.49',
  '35828': '96.49',
  '35829': '96.49',
  '35830': '96.49',
  '35831': '96.50',
  '35832': '96.50',
  '35833': '96.50',
  '35834': '96.50',
  '35835': '96.50',
  '35836': '96.50',
  '35837': '96.51',
  '35838': '96.51',
  '35839': '96.51',
  '35840': '96.51',
  '35841': '96.51',
  '35842': '96.51',
  '35843': '96.52',
  '35844': '96.52',
  '35845': '96.52',
  '35846': '96.52',
  '35847': '96.52',
  '35848': '96.53',
  '35849': '96.53',
  '35850': '96.53',
  '35851': '96.53',
  '35852': '96.53',
  '35853': '96.54',
  '35854': '96.54',
  '35855': '96.54',
  '35856': '96.54',
  '35857': '96.54',
  '35858': '96.54',
  '35859': '96.55',
  '35860': '96.55',
  '35861': '96.55',
  '35862': '96.55',
  '35863': '96.55',
  '35864': '96.56',
  '35865': '96.56',
  '35866': '96.56',
  '35867': '96.56',
  '35868': '96.56',
  '35869': '96.56',
  '35870': '96.57',
  '35871': '96.57',
  '35872': '96.57',
  '35873': '96.57',
  '35874': '96.57',
  '35875': '96.57',
  '35876': '96.58',
  '35877': '96.58',
  '35878': '96.58',
  '35879': '96.58',
  '35880': '96.58',
  '35881': '96.58',
  '35882': '96.59',
  '35883': '96.59',
  '35884': '96.59',
  '35885': '96.59',
  '35886': '96.59',
  '35887': '96.60',
  '35888': '96.60',
  '35889': '96.60',
  '35890': '96.60',
  '35891': '96.60',
  '35892': '96.61',
  '35893': '96.61',
  '35894': '96.61',
  '35895': '96.61',
  '35896': '96.61',
  '35897': '96.61',
  '35898': '96.62',
  '35899': '96.62',
  '35900': '96.62',
  '35901': '96.62',
  '35902': '96.62',
  '35903': '96.62',
  '35904': '96.63',
  '35905': '96.63',
  '35906': '96.63',
  '35907': '96.63',
  '35908': '96.63',
  '35909': '96.64',
  '35910': '96.64',
  '35911': '96.64',
  '35912': '96.64',
  '35913': '96.64',
  '35914': '96.64',
  '35915': '96.65',
  '35916': '96.65',
  '35917': '96.65',
  '35918': '96.65',
  '35919': '96.65',
  '35920': '96.66',
  '35921': '96.66',
  '35922': '96.66',
  '35923': '96.66',
  '35924': '96.66',
  '35925': '96.67',
  '35926': '96.67',
  '35927': '96.67',
  '35928': '96.67',
  '35929': '96.67',
  '35930': '96.67',
  '35931': '96.68',
  '35932': '96.68',
  '35933': '96.68',
  '35934': '96.68',
  '35935': '96.68',
  '35936': '96.68',
  '35937': '96.69',
  '35938': '96.69',
  '35939': '96.69',
  '35940': '96.69',
  '35941': '96.69',
  '35942': '96.69',
  '35943': '96.70',
  '35944': '96.70',
  '35945': '96.70',
  '35946': '96.70',
  '35947': '96.70',
  '35948': '96.71',
  '35949': '96.71',
  '35950': '96.71',
  '35951': '96.71',
  '35952': '96.71',
  '35953': '96.71',
  '35954': '96.72',
  '35955': '96.72',
  '35956': '96.72',
  '35957': '96.72',
  '35958': '96.72',
  '35959': '96.73',
  '35960': '96.73',
  '35961': '96.73',
  '35962': '96.73',
  '35963': '96.73',
  '35964': '96.74',
  '35965': '96.74',
  '35966': '96.74',
  '35967': '96.74',
  '35968': '96.74',
  '35969': '96.74',
  '35970': '96.75',
  '35971': '96.75',
  '35972': '96.75',
  '35973': '96.75',
  '35974': '96.75',
  '35975': '96.75',
  '35976': '96.76',
  '35977': '96.76',
  '35978': '96.76',
  '35979': '96.76',
  '35980': '96.76',
  '35981': '96.76',
  '35982': '96.77',
  '35983': '96.77',
  '35984': '96.77',
  '35985': '96.77',
  '35986': '96.77',
  '35987': '96.78',
  '35988': '96.78',
  '35989': '96.78',
  '35990': '96.78',
  '35991': '96.78',
  '35992': '96.79',
  '35993': '96.79',
  '35994': '96.79',
  '35995': '96.79',
  '35996': '96.79',
  '35997': '96.79',
  '35998': '96.80',
  '35999': '96.80',
  '36000': '96.80',
  '36001': '96.80',
  '36002': '96.80',
  '36003': '96.81',
  '36004': '96.81',
  '36005': '96.81',
  '36006': '96.81',
  '36007': '96.81',
  '36008': '96.81',
  '36009': '96.82',
  '36010': '96.82',
  '36011': '96.82',
  '36012': '96.82',
  '36013': '96.82',
  '36014': '96.82',
  '36015': '96.83',
  '36016': '96.83',
  '36017': '96.83',
  '36018': '96.83',
  '36019': '96.83',
  '36020': '96.84',
  '36021': '96.84',
  '36022': '96.84',
  '36023': '96.84',
  '36024': '96.84',
  '36025': '96.85',
  '36026': '96.85',
  '36027': '96.85',
  '36028': '96.85',
  '36029': '96.85',
  '36030': '96.85',
  '36031': '96.86',
  '36032': '96.86',
  '36033': '96.86',
  '36034': '96.86',
  '36035': '96.86',
  '36036': '96.86',
  '36037': '96.87',
  '36038': '96.87',
  '36039': '96.87',
  '36040': '96.87',
  '36041': '96.87',
  '36042': '96.88',
  '36043': '96.88',
  '36044': '96.88',
  '36045': '96.88',
  '36046': '96.88',
  '36047': '96.88',
  '36048': '96.89',
  '36049': '96.89',
  '36050': '96.89',
  '36051': '96.89',
  '36052': '96.89',
  '36053': '96.89',
  '36054': '96.90',
  '36055': '96.90',
  '36056': '96.90',
  '36057': '96.90',
  '36058': '96.90',
  '36059': '96.91',
  '36060': '96.91',
  '36061': '96.91',
  '36062': '96.91',
  '36063': '96.91',
  '36064': '96.92',
  '36065': '96.92',
  '36066': '96.92',
  '36067': '96.92',
  '36068': '96.92',
  '36069': '96.92',
  '36070': '96.93',
  '36071': '96.93',
  '36072': '96.93',
  '36073': '96.93',
  '36074': '96.93',
  '36075': '96.93',
  '36076': '96.94',
  '36077': '96.94',
  '36078': '96.94',
  '36079': '96.94',
  '36080': '96.94',
  '36081': '96.94',
  '36082': '96.95',
  '36083': '96.95',
  '36084': '96.95',
  '36085': '96.95',
  '36086': '96.95',
  '36087': '96.96',
  '36088': '96.96',
  '36089': '96.96',
  '36090': '96.96',
  '36091': '96.96',
  '36092': '96.96',
  '36093': '96.97',
  '36094': '96.97',
  '36095': '96.97',
  '36096': '96.97',
  '36097': '96.97',
  '36098': '96.98',
  '36099': '96.98',
  '36100': '96.98',
  '36101': '96.98',
  '36102': '96.98',
  '36103': '96.99',
  '36104': '96.99',
  '36105': '96.99',
  '36106': '96.99',
  '36107': '96.99',
  '36108': '96.99',
  '36109': '97.00',
  '36110': '97.00',
  '36111': '97.00',
  '36112': '97.00',
  '36113': '97.00',
  '36114': '97.00',
  '36115': '97.01',
  '36116': '97.01',
  '36117': '97.01',
  '36118': '97.01',
  '36119': '97.01',
  '36120': '97.02',
  '36121': '97.02',
  '36122': '97.02',
  '36123': '97.02',
  '36124': '97.02',
  '36125': '97.03',
  '36126': '97.03',
  '36127': '97.03',
  '36128': '97.03',
  '36129': '97.03',
  '36130': '97.03',
  '36131': '97.04',
  '36132': '97.04',
  '36133': '97.04',
  '36134': '97.04',
  '36135': '97.04',
  '36136': '97.04',
  '36137': '97.05',
  '36138': '97.05',
  '36139': '97.05',
  '36140': '97.05',
  '36141': '97.05',
  '36142': '97.06',
  '36143': '97.06',
  '36144': '97.06',
  '36145': '97.06',
  '36146': '97.06',
  '36147': '97.06',
  '36148': '97.07',
  '36149': '97.07',
  '36150': '97.07',
  '36151': '97.07',
  '36152': '97.07',
  '36153': '97.07',
  '36154': '97.08',
  '36155': '97.08',
  '36156': '97.08',
  '36157': '97.08',
  '36158': '97.08',
  '36159': '97.09',
  '36160': '97.09',
  '36161': '97.09',
  '36162': '97.09',
  '36163': '97.09',
  '36164': '97.10',
  '36165': '97.10',
  '36166': '97.10',
  '36167': '97.10',
  '36168': '97.10',
  '36169': '97.10',
  '36170': '97.11',
  '36171': '97.11',
  '36172': '97.11',
  '36173': '97.11',
  '36174': '97.11',
  '36175': '97.11',
  '36176': '97.12',
  '36177': '97.12',
  '36178': '97.12',
  '36179': '97.12',
  '36180': '97.12',
  '36181': '97.12',
  '36182': '97.13',
  '36183': '97.13',
  '36184': '97.13',
  '36185': '97.13',
  '36186': '97.13',
  '36187': '97.14',
  '36188': '97.14',
  '36189': '97.14',
  '36190': '97.14',
  '36191': '97.14',
  '36192': '97.14',
  '36193': '97.15',
  '36194': '97.15',
  '36195': '97.15',
  '36196': '97.15',
  '36197': '97.15',
  '36198': '97.16',
  '36199': '97.16',
  '36200': '97.16',
  '36201': '97.16',
  '36202': '97.16',
  '36203': '97.17',
  '36204': '97.17',
  '36205': '97.17',
  '36206': '97.17',
  '36207': '97.17',
  '36208': '97.17',
  '36209': '97.18',
  '36210': '97.18',
  '36211': '97.18',
  '36212': '97.18',
  '36213': '97.18',
  '36214': '97.18',
  '36215': '97.19',
  '36216': '97.19',
  '36217': '97.19',
  '36218': '97.19',
  '36219': '97.19',
  '36220': '97.20',
  '36221': '97.20',
  '36222': '97.20',
  '36223': '97.20',
  '36224': '97.20',
  '36225': '97.21',
  '36226': '97.21',
  '36227': '97.21',
  '36228': '97.21',
  '36229': '97.21',
  '36230': '97.21',
  '36231': '97.21',
  '36232': '97.22',
  '36233': '97.22',
  '36234': '97.22',
  '36235': '97.22',
  '36236': '97.22',
  '36237': '97.23',
  '36238': '97.23',
  '36239': '97.23',
  '36240': '97.23',
  '36241': '97.23',
  '36242': '97.24',
  '36243': '97.24',
  '36244': '97.24',
  '36245': '97.24',
  '36246': '97.24',
  '36247': '97.24',
  '36248': '97.25',
  '36249': '97.25',
  '36250': '97.25',
  '36251': '97.25',
  '36252': '97.25',
  '36253': '97.25',
  '36254': '97.26',
  '36255': '97.26',
  '36256': '97.26',
  '36257': '97.26',
  '36258': '97.26',
  '36259': '97.27',
  '36260': '97.27',
  '36261': '97.27',
  '36262': '97.27',
  '36263': '97.27',
  '36264': '97.28',
  '36265': '97.28',
  '36266': '97.28',
  '36267': '97.28',
  '36268': '97.28',
  '36269': '97.28',
  '36270': '97.29',
  '36271': '97.29',
  '36272': '97.29',
  '36273': '97.29',
  '36274': '97.29',
  '36275': '97.29',
  '36276': '97.30',
  '36277': '97.30',
  '36278': '97.30',
  '36279': '97.30',
  '36280': '97.30',
  '36281': '97.31',
  '36282': '97.31',
  '36283': '97.31',
  '36284': '97.31',
  '36285': '97.31',
  '36286': '97.31',
  '36287': '97.32',
  '36288': '97.32',
  '36289': '97.32',
  '36290': '97.32',
  '36291': '97.32',
  '36292': '97.32',
  '36293': '97.33',
  '36294': '97.33',
  '36295': '97.33',
  '36296': '97.33',
  '36297': '97.33',
  '36298': '97.34',
  '36299': '97.34',
  '36300': '97.34',
  '36301': '97.34',
  '36302': '97.34',
  '36303': '97.35',
  '36304': '97.35',
  '36305': '97.35',
  '36306': '97.35',
  '36307': '97.35',
  '36308': '97.35',
  '36309': '97.36',
  '36310': '97.36',
  '36311': '97.36',
  '36312': '97.36',
  '36313': '97.36',
  '36314': '97.36',
  '36315': '97.37',
  '36316': '97.37',
  '36317': '97.37',
  '36318': '97.37',
  '36319': '97.37',
  '36320': '97.38',
  '36321': '97.38',
  '36322': '97.38',
  '36323': '97.38',
  '36324': '97.38',
  '36325': '97.39',
  '36326': '97.39',
  '36327': '97.39',
  '36328': '97.39',
  '36329': '97.39',
  '36330': '97.39',
  '36331': '97.39',
  '36332': '97.40',
  '36333': '97.40',
  '36334': '97.40',
  '36335': '97.40',
  '36336': '97.40',
  '36337': '97.41',
  '36338': '97.41',
  '36339': '97.41',
  '36340': '97.41',
  '36341': '97.41',
  '36342': '97.42',
  '36343': '97.42',
  '36344': '97.42',
  '36345': '97.42',
  '36346': '97.42',
  '36347': '97.42',
  '36348': '97.43',
  '36349': '97.43',
  '36350': '97.43',
  '36351': '97.43',
  '36352': '97.43',
  '36353': '97.43',
  '36354': '97.44',
  '36355': '97.44',
  '36356': '97.44',
  '36357': '97.44',
  '36358': '97.44',
  '36359': '97.45',
  '36360': '97.45',
  '36361': '97.45',
  '36362': '97.45',
  '36363': '97.45',
  '36364': '97.46',
  '36365': '97.46',
  '36366': '97.46',
  '36367': '97.46',
  '36368': '97.46',
  '36369': '97.46',
  '36370': '97.47',
  '36371': '97.47',
  '36372': '97.47',
  '36373': '97.47',
  '36374': '97.47',
  '36375': '97.47',
  '36376': '97.48',
  '36377': '97.48',
  '36378': '97.48',
  '36379': '97.48',
  '36380': '97.48',
  '36381': '97.49',
  '36382': '97.49',
  '36383': '97.49',
  '36384': '97.49',
  '36385': '97.49',
  '36386': '97.49',
  '36387': '97.50',
  '36388': '97.50',
  '36389': '97.50',
  '36390': '97.50',
  '36391': '97.50',
  '36392': '97.50',
  '36393': '97.51',
  '36394': '97.51',
  '36395': '97.51',
  '36396': '97.51',
  '36397': '97.51',
  '36398': '97.52',
  '36399': '97.52',
  '36400': '97.52',
  '36401': '97.52',
  '36402': '97.52',
  '36403': '97.53',
  '36404': '97.53',
  '36405': '97.53',
  '36406': '97.53',
  '36407': '97.53',
  '36408': '97.53',
  '36409': '97.54',
  '36410': '97.54',
  '36411': '97.54',
  '36412': '97.54',
  '36413': '97.54',
  '36414': '97.54',
  '36415': '97.55',
  '36416': '97.55',
  '36417': '97.55',
  '36418': '97.55',
  '36419': '97.55',
  '36420': '97.56',
  '36421': '97.56',
  '36422': '97.56',
  '36423': '97.56',
  '36424': '97.56',
  '36425': '97.57',
  '36426': '97.57',
  '36427': '97.57',
  '36428': '97.57',
  '36429': '97.57',
  '36430': '97.57',
  '36431': '97.57',
  '36432': '97.58',
  '36433': '97.58',
  '36434': '97.58',
  '36435': '97.58',
  '36436': '97.58',
  '36437': '97.59',
  '36438': '97.59',
  '36439': '97.59',
  '36440': '97.59',
  '36441': '97.59',
  '36442': '97.60',
  '36443': '97.60',
  '36444': '97.60',
  '36445': '97.60',
  '36446': '97.60',
  '36447': '97.60',
  '36448': '97.61',
  '36449': '97.61',
  '36450': '97.61',
  '36451': '97.61',
  '36452': '97.61',
  '36453': '97.61',
  '36454': '97.62',
  '36455': '97.62',
  '36456': '97.62',
  '36457': '97.62',
  '36458': '97.62',
  '36459': '97.63',
  '36460': '97.63',
  '36461': '97.63',
  '36462': '97.63',
  '36463': '97.63',
  '36464': '97.64',
  '36465': '97.64',
  '36466': '97.64',
  '36467': '97.64',
  '36468': '97.64',
  '36469': '97.64',
  '36470': '97.65',
  '36471': '97.65',
  '36472': '97.65',
  '36473': '97.65',
  '36474': '97.65',
  '36475': '97.65',
  '36476': '97.66',
  '36477': '97.66',
  '36478': '97.66',
  '36479': '97.66',
  '36480': '97.66',
  '36481': '97.67',
  '36482': '97.67',
  '36483': '97.67',
  '36484': '97.67',
  '36485': '97.67',
  '36486': '97.67',
  '36487': '97.68',
  '36488': '97.68',
  '36489': '97.68',
  '36490': '97.68',
  '36491': '97.68',
  '36492': '97.68',
  '36493': '97.69',
  '36494': '97.69',
  '36495': '97.69',
  '36496': '97.69',
  '36497': '97.69',
  '36498': '97.70',
  '36499': '97.70',
  '36500': '97.70',
  '36501': '97.70',
  '36502': '97.70',
  '36503': '97.71',
  '36504': '97.71',
  '36505': '97.71',
  '36506': '97.71',
  '36507': '97.71',
  '36508': '97.71',
  '36509': '97.72',
  '36510': '97.72',
  '36511': '97.72',
  '36512': '97.72',
  '36513': '97.72',
  '36514': '97.72',
  '36515': '97.73',
  '36516': '97.73',
  '36517': '97.73',
  '36518': '97.73',
  '36519': '97.73',
  '36520': '97.74',
  '36521': '97.74',
  '36522': '97.74',
  '36523': '97.74',
  '36524': '97.74',
  '36525': '97.75',
  '36526': '97.75',
  '36527': '97.75',
  '36528': '97.75',
  '36529': '97.75',
  '36530': '97.75',
  '36531': '97.75',
  '36532': '97.76',
  '36533': '97.76',
  '36534': '97.76',
  '36535': '97.76',
  '36536': '97.76',
  '36537': '97.77',
  '36538': '97.77',
  '36539': '97.77',
  '36540': '97.77',
  '36541': '97.77',
  '36542': '97.78',
  '36543': '97.78',
  '36544': '97.78',
  '36545': '97.78',
  '36546': '97.78',
  '36547': '97.78',
  '36548': '97.79',
  '36549': '97.79',
  '36550': '97.79',
  '36551': '97.79',
  '36552': '97.79',
  '36553': '97.79',
  '36554': '97.80',
  '36555': '97.80',
  '36556': '97.80',
  '36557': '97.80',
  '36558': '97.80',
  '36559': '97.81',
  '36560': '97.81',
  '36561': '97.81',
  '36562': '97.81',
  '36563': '97.81',
  '36564': '97.82',
  '36565': '97.82',
  '36566': '97.82',
  '36567': '97.82',
  '36568': '97.82',
  '36569': '97.82',
  '36570': '97.83',
  '36571': '97.83',
  '36572': '97.83',
  '36573': '97.83',
  '36574': '97.83',
  '36575': '97.83',
  '36576': '97.84',
  '36577': '97.84',
  '36578': '97.84',
  '36579': '97.84',
  '36580': '97.84',
  '36581': '97.85',
  '36582': '97.85',
  '36583': '97.85',
  '36584': '97.85',
  '36585': '97.85',
  '36586': '97.85',
  '36587': '97.86',
  '36588': '97.86',
  '36589': '97.86',
  '36590': '97.86',
  '36591': '97.86',
  '36592': '97.86',
  '36593': '97.87',
  '36594': '97.87',
  '36595': '97.87',
  '36596': '97.87',
  '36597': '97.87',
  '36598': '97.88',
  '36599': '97.88',
  '36600': '97.88',
  '36601': '97.88',
  '36602': '97.88',
  '36603': '97.89',
  '36604': '97.89',
  '36605': '97.89',
  '36606': '97.89',
  '36607': '97.89',
  '36608': '97.89',
  '36609': '97.90',
  '36610': '97.90',
  '36611': '97.90',
  '36612': '97.90',
  '36613': '97.90',
  '36614': '97.90',
  '36615': '97.91',
  '36616': '97.91',
  '36617': '97.91',
  '36618': '97.91',
  '36619': '97.91',
  '36620': '97.92',
  '36621': '97.92',
  '36622': '97.92',
  '36623': '97.92',
  '36624': '97.92',
  '36625': '97.93',
  '36626': '97.93',
  '36627': '97.93',
  '36628': '97.93',
  '36629': '97.93',
  '36630': '97.93',
  '36631': '97.93',
  '36632': '97.94',
  '36633': '97.94',
  '36634': '97.94',
  '36635': '97.94',
  '36636': '97.94',
  '36637': '97.95',
  '36638': '97.95',
  '36639': '97.95',
  '36640': '97.95',
  '36641': '97.95',
  '36642': '97.96',
  '36643': '97.96',
  '36644': '97.96',
  '36645': '97.96',
  '36646': '97.96',
  '36647': '97.96',
  '36648': '97.97',
  '36649': '97.97',
  '36650': '97.97',
  '36651': '97.97',
  '36652': '97.97',
  '36653': '97.97',
  '36654': '97.98',
  '36655': '97.98',
  '36656': '97.98',
  '36657': '97.98',
  '36658': '97.98',
  '36659': '97.99',
  '36660': '97.99',
  '36661': '97.99',
  '36662': '97.99',
  '36663': '97.99',
  '36664': '98.00',
  '36665': '98.00',
  '36666': '98.00',
  '36667': '98.00',
  '36668': '98.00',
  '36669': '98.00',
  '36670': '98.01',
  '36671': '98.01',
  '36672': '98.01',
  '36673': '98.01',
  '36674': '98.01',
  '36675': '98.01',
  '36676': '98.02',
  '36677': '98.02',
  '36678': '98.02',
  '36679': '98.02',
  '36680': '98.02',
  '36681': '98.03',
  '36682': '98.03',
  '36683': '98.03',
  '36684': '98.03',
  '36685': '98.03',
  '36686': '98.03',
  '36687': '98.04',
  '36688': '98.04',
  '36689': '98.04',
  '36690': '98.04',
  '36691': '98.04',
  '36692': '98.04',
  '36693': '98.05',
  '36694': '98.05',
  '36695': '98.05',
  '36696': '98.05',
  '36697': '98.05',
  '36698': '98.06',
  '36699': '98.06',
  '36700': '98.06',
  '36701': '98.06',
  '36702': '98.06',
  '36703': '98.07',
  '36704': '98.07',
  '36705': '98.07',
  '36706': '98.07',
  '36707': '98.07',
  '36708': '98.07',
  '36709': '98.08',
  '36710': '98.08',
  '36711': '98.08',
  '36712': '98.08',
  '36713': '98.08',
  '36714': '98.08',
  '36715': '98.09',
  '36716': '98.09',
  '36717': '98.09',
  '36718': '98.09',
  '36719': '98.09',
  '36720': '98.10',
  '36721': '98.10',
  '36722': '98.10',
  '36723': '98.10',
  '36724': '98.10',
  '36725': '98.11',
  '36726': '98.11',
  '36727': '98.11',
  '36728': '98.11',
  '36729': '98.11',
  '36730': '98.11',
  '36731': '98.11',
  '36732': '98.12',
  '36733': '98.12',
  '36734': '98.12',
  '36735': '98.12',
  '36736': '98.12',
  '36737': '98.13',
  '36738': '98.13',
  '36739': '98.13',
  '36740': '98.13',
  '36741': '98.13',
  '36742': '98.14',
  '36743': '98.14',
  '36744': '98.14',
  '36745': '98.14',
  '36746': '98.14',
  '36747': '98.14',
  '36748': '98.15',
  '36749': '98.15',
  '36750': '98.15',
  '36751': '98.15',
  '36752': '98.15',
  '36753': '98.15',
  '36754': '98.16',
  '36755': '98.16',
  '36756': '98.16',
  '36757': '98.16',
  '36758': '98.16',
  '36759': '98.17',
  '36760': '98.17',
  '36761': '98.17',
  '36762': '98.17',
  '36763': '98.17',
  '36764': '98.18',
  '36765': '98.18',
  '36766': '98.18',
  '36767': '98.18',
  '36768': '98.18',
  '36769': '98.18',
  '36770': '98.19',
  '36771': '98.19',
  '36772': '98.19',
  '36773': '98.19',
  '36774': '98.19',
  '36775': '98.19',
  '36776': '98.20',
  '36777': '98.20',
  '36778': '98.20',
  '36779': '98.20',
  '36780': '98.20',
  '36781': '98.21',
  '36782': '98.21',
  '36783': '98.21',
  '36784': '98.21',
  '36785': '98.21',
  '36786': '98.21',
  '36787': '98.22',
  '36788': '98.22',
  '36789': '98.22',
  '36790': '98.22',
  '36791': '98.22',
  '36792': '98.22',
  '36793': '98.23',
  '36794': '98.23',
  '36795': '98.23',
  '36796': '98.23',
  '36797': '98.23',
  '36798': '98.24',
  '36799': '98.24',
  '36800': '98.24',
  '36801': '98.24',
  '36802': '98.24',
  '36803': '98.25',
  '36804': '98.25',
  '36805': '98.25',
  '36806': '98.25',
  '36807': '98.25',
  '36808': '98.25',
  '36809': '98.26',
  '36810': '98.26',
  '36811': '98.26',
  '36812': '98.26',
  '36813': '98.26',
  '36814': '98.26',
  '36815': '98.27',
  '36816': '98.27',
  '36817': '98.27',
  '36818': '98.27',
  '36819': '98.27',
  '36820': '98.28',
  '36821': '98.28',
  '36822': '98.28',
  '36823': '98.28',
  '36824': '98.28',
  '36825': '98.29',
  '36826': '98.29',
  '36827': '98.29',
  '36828': '98.29',
  '36829': '98.29',
  '36830': '98.29',
  '36831': '98.29',
  '36832': '98.30',
  '36833': '98.30',
  '36834': '98.30',
  '36835': '98.30',
  '36836': '98.30',
  '36837': '98.31',
  '36838': '98.31',
  '36839': '98.31',
  '36840': '98.31',
  '36841': '98.31',
  '36842': '98.32',
  '36843': '98.32',
  '36844': '98.32',
  '36845': '98.32',
  '36846': '98.32',
  '36847': '98.32',
  '36848': '98.33',
  '36849': '98.33',
  '36850': '98.33',
  '36851': '98.33',
  '36852': '98.33',
  '36853': '98.33',
  '36854': '98.34',
  '36855': '98.34',
  '36856': '98.34',
  '36857': '98.34',
  '36858': '98.34',
  '36859': '98.35',
  '36860': '98.35',
  '36861': '98.35',
  '36862': '98.35',
  '36863': '98.35',
  '36864': '98.36',
  '36865': '98.36',
  '36866': '98.36',
  '36867': '98.36',
  '36868': '98.36',
  '36869': '98.36',
  '36870': '98.37',
  '36871': '98.37',
  '36872': '98.37',
  '36873': '98.37',
  '36874': '98.37',
  '36875': '98.38',
  '36876': '98.38',
  '36877': '98.38',
  '36878': '98.38',
  '36879': '98.38',
  '36880': '98.38',
  '36881': '98.39',
  '36882': '98.39',
  '36883': '98.39',
  '36884': '98.39',
  '36885': '98.39',
  '36886': '98.39',
  '36887': '98.40',
  '36888': '98.40',
  '36889': '98.40',
  '36890': '98.40',
  '36891': '98.40',
  '36892': '98.40',
  '36893': '98.41',
  '36894': '98.41',
  '36895': '98.41',
  '36896': '98.41',
  '36897': '98.41',
  '36898': '98.42',
  '36899': '98.42',
  '36900': '98.42',
  '36901': '98.42',
  '36902': '98.42',
  '36903': '98.43',
  '36904': '98.43',
  '36905': '98.43',
  '36906': '98.43',
  '36907': '98.43',
  '36908': '98.43',
  '36909': '98.44',
  '36910': '98.44',
  '36911': '98.44',
  '36912': '98.44',
  '36913': '98.44',
  '36914': '98.44',
  '36915': '98.45',
  '36916': '98.45',
  '36917': '98.45',
  '36918': '98.45',
  '36919': '98.45',
  '36920': '98.46',
  '36921': '98.46',
  '36922': '98.46',
  '36923': '98.46',
  '36924': '98.46',
  '36925': '98.46',
  '36926': '98.47',
  '36927': '98.47',
  '36928': '98.47',
  '36929': '98.47',
  '36930': '98.47',
  '36931': '98.47',
  '36932': '98.48',
  '36933': '98.48',
  '36934': '98.48',
  '36935': '98.48',
  '36936': '98.48',
  '36937': '98.49',
  '36938': '98.49',
  '36939': '98.49',
  '36940': '98.49',
  '36941': '98.49',
  '36942': '98.50',
  '36943': '98.50',
  '36944': '98.50',
  '36945': '98.50',
  '36946': '98.50',
  '36947': '98.50',
  '36948': '98.51',
  '36949': '98.51',
  '36950': '98.51',
  '36951': '98.51',
  '36952': '98.51',
  '36953': '98.51',
  '36954': '98.52',
  '36955': '98.52',
  '36956': '98.52',
  '36957': '98.52',
  '36958': '98.52',
  '36959': '98.53',
  '36960': '98.53',
  '36961': '98.53',
  '36962': '98.53',
  '36963': '98.53',
  '36964': '98.54',
  '36965': '98.54',
  '36966': '98.54',
  '36967': '98.54',
  '36968': '98.54',
  '36969': '98.54',
  '36970': '98.55',
  '36971': '98.55',
  '36972': '98.55',
  '36973': '98.55',
  '36974': '98.55',
  '36975': '98.56',
  '36976': '98.56',
  '36977': '98.56',
  '36978': '98.56',
  '36979': '98.56',
  '36980': '98.56',
  '36981': '98.57',
  '36982': '98.57',
  '36983': '98.57',
  '36984': '98.57',
  '36985': '98.57',
  '36986': '98.57',
  '36987': '98.58',
  '36988': '98.58',
  '36989': '98.58',
  '36990': '98.58',
  '36991': '98.58',
  '36992': '98.58',
  '36993': '98.59',
  '36994': '98.59',
  '36995': '98.59',
  '36996': '98.59',
  '36997': '98.59',
  '36998': '98.60',
  '36999': '98.60',
  '37000': '98.60',
  '37001': '98.60',
  '37002': '98.60',
  '37003': '98.61',
  '37004': '98.61',
  '37005': '98.61',
  '37006': '98.61',
  '37007': '98.61',
  '37008': '98.61',
  '37009': '98.62',
  '37010': '98.62',
  '37011': '98.62',
  '37012': '98.62',
  '37013': '98.62',
  '37014': '98.62',
  '37015': '98.63',
  '37016': '98.63',
  '37017': '98.63',
  '37018': '98.63',
  '37019': '98.63',
  '37020': '98.64',
  '37021': '98.64',
  '37022': '98.64',
  '37023': '98.64',
  '37024': '98.64',
  '37025': '98.64',
  '37026': '98.65',
  '37027': '98.65',
  '37028': '98.65',
  '37029': '98.65',
  '37030': '98.65',
  '37031': '98.65',
  '37032': '98.66',
  '37033': '98.66',
  '37034': '98.66',
  '37035': '98.66',
  '37036': '98.66',
  '37037': '98.67',
  '37038': '98.67',
  '37039': '98.67',
  '37040': '98.67',
  '37041': '98.67',
  '37042': '98.68',
  '37043': '98.68',
  '37044': '98.68',
  '37045': '98.68',
  '37046': '98.68',
  '37047': '98.68',
  '37048': '98.69',
  '37049': '98.69',
  '37050': '98.69',
  '37051': '98.69',
  '37052': '98.69',
  '37053': '98.69',
  '37054': '98.70',
  '37055': '98.70',
  '37056': '98.70',
  '37057': '98.70',
  '37058': '98.70',
  '37059': '98.71',
  '37060': '98.71',
  '37061': '98.71',
  '37062': '98.71',
  '37063': '98.71',
  '37064': '98.71',
  '37065': '98.72',
  '37066': '98.72',
  '37067': '98.72',
  '37068': '98.72',
  '37069': '98.72',
  '37070': '98.73',
  '37071': '98.73',
  '37072': '98.73',
  '37073': '98.73',
  '37074': '98.73',
  '37075': '98.74',
  '37076': '98.74',
  '37077': '98.74',
  '37078': '98.74',
  '37079': '98.74',
  '37080': '98.74',
  '37081': '98.75',
  '37082': '98.75',
  '37083': '98.75',
  '37084': '98.75',
  '37085': '98.75',
  '37086': '98.75',
  '37087': '98.76',
  '37088': '98.76',
  '37089': '98.76',
  '37090': '98.76',
  '37091': '98.76',
  '37092': '98.76',
  '37093': '98.77',
  '37094': '98.77',
  '37095': '98.77',
  '37096': '98.77',
  '37097': '98.77',
  '37098': '98.78',
  '37099': '98.78',
  '37100': '98.78',
  '37101': '98.78',
  '37102': '98.78',
  '37103': '98.79',
  '37104': '98.79',
  '37105': '98.79',
  '37106': '98.79',
  '37107': '98.79',
  '37108': '98.79',
  '37109': '98.80',
  '37110': '98.80',
  '37111': '98.80',
  '37112': '98.80',
  '37113': '98.80',
  '37114': '98.81',
  '37115': '98.81',
  '37116': '98.81',
  '37117': '98.81',
  '37118': '98.81',
  '37119': '98.81',
  '37120': '98.82',
  '37121': '98.82',
  '37122': '98.82',
  '37123': '98.82',
  '37124': '98.82',
  '37125': '98.82',
  '37126': '98.83',
  '37127': '98.83',
  '37128': '98.83',
  '37129': '98.83',
  '37130': '98.83',
  '37131': '98.83',
  '37132': '98.84',
  '37133': '98.84',
  '37134': '98.84',
  '37135': '98.84',
  '37136': '98.84',
  '37137': '98.85',
  '37138': '98.85',
  '37139': '98.85',
  '37140': '98.85',
  '37141': '98.85',
  '37142': '98.86',
  '37143': '98.86',
  '37144': '98.86',
  '37145': '98.86',
  '37146': '98.86',
  '37147': '98.86',
  '37148': '98.87',
  '37149': '98.87',
  '37150': '98.87',
  '37151': '98.87',
  '37152': '98.87',
  '37153': '98.88',
  '37154': '98.88',
  '37155': '98.88',
  '37156': '98.88',
  '37157': '98.88',
  '37158': '98.88',
  '37159': '98.89',
  '37160': '98.89',
  '37161': '98.89',
  '37162': '98.89',
  '37163': '98.89',
  '37164': '98.89',
  '37165': '98.90',
  '37166': '98.90',
  '37167': '98.90',
  '37168': '98.90',
  '37169': '98.90',
  '37170': '98.91',
  '37171': '98.91',
  '37172': '98.91',
  '37173': '98.91',
  '37174': '98.91',
  '37175': '98.92',
  '37176': '98.92',
  '37177': '98.92',
  '37178': '98.92',
  '37179': '98.92',
  '37180': '98.92',
  '37181': '98.93',
  '37182': '98.93',
  '37183': '98.93',
  '37184': '98.93',
  '37185': '98.93',
  '37186': '98.93',
  '37187': '98.94',
  '37188': '98.94',
  '37189': '98.94',
  '37190': '98.94',
  '37191': '98.94',
  '37192': '98.94',
  '37193': '98.95',
  '37194': '98.95',
  '37195': '98.95',
  '37196': '98.95',
  '37197': '98.95',
  '37198': '98.96',
  '37199': '98.96',
  '37200': '98.96',
  '37201': '98.96',
  '37202': '98.96',
  '37203': '98.96',
  '37204': '98.97',
  '37205': '98.97',
  '37206': '98.97',
  '37207': '98.97',
  '37208': '98.97',
  '37209': '98.98',
  '37210': '98.98',
  '37211': '98.98',
  '37212': '98.98',
  '37213': '98.98',
  '37214': '98.99',
  '37215': '98.99',
  '37216': '98.99',
  '37217': '98.99',
  '37218': '98.99',
  '37219': '98.99',
  '37220': '99.00',
  '37221': '99.00',
  '37222': '99.00',
  '37223': '99.00',
  '37224': '99.00',
  '37225': '99.00',
  '37226': '99.01',
  '37227': '99.01',
  '37228': '99.01',
  '37229': '99.01',
  '37230': '99.01',
  '37231': '99.01',
  '37232': '99.02',
  '37233': '99.02',
  '37234': '99.02',
  '37235': '99.02',
  '37236': '99.02',
  '37237': '99.03',
  '37238': '99.03',
  '37239': '99.03',
  '37240': '99.03',
  '37241': '99.03',
  '37242': '99.04',
  '37243': '99.04',
  '37244': '99.04',
  '37245': '99.04',
  '37246': '99.04',
  '37247': '99.04',
  '37248': '99.05',
  '37249': '99.05',
  '37250': '99.05',
  '37251': '99.05',
  '37252': '99.05',
  '37253': '99.06',
  '37254': '99.06',
  '37255': '99.06',
  '37256': '99.06',
  '37257': '99.06',
  '37258': '99.06',
  '37259': '99.07',
  '37260': '99.07',
  '37261': '99.07',
  '37262': '99.07',
  '37263': '99.07',
  '37264': '99.07',
  '37265': '99.08',
  '37266': '99.08',
  '37267': '99.08',
  '37268': '99.08',
  '37269': '99.08',
  '37270': '99.09',
  '37271': '99.09',
  '37272': '99.09',
  '37273': '99.09',
  '37274': '99.09',
  '37275': '99.10',
  '37276': '99.10',
  '37277': '99.10',
  '37278': '99.10',
  '37279': '99.10',
  '37280': '99.10',
  '37281': '99.11',
  '37282': '99.11',
  '37283': '99.11',
  '37284': '99.11',
  '37285': '99.11',
  '37286': '99.11',
  '37287': '99.12',
  '37288': '99.12',
  '37289': '99.12',
  '37290': '99.12',
  '37291': '99.12',
  '37292': '99.12',
  '37293': '99.13',
  '37294': '99.13',
  '37295': '99.13',
  '37296': '99.13',
  '37297': '99.13',
  '37298': '99.14',
  '37299': '99.14',
  '37300': '99.14',
  '37301': '99.14',
  '37302': '99.14',
  '37303': '99.14',
  '37304': '99.15',
  '37305': '99.15',
  '37306': '99.15',
  '37307': '99.15',
  '37308': '99.15',
  '37309': '99.16',
  '37310': '99.16',
  '37311': '99.16',
  '37312': '99.16',
  '37313': '99.16',
  '37314': '99.17',
  '37315': '99.17',
  '37316': '99.17',
  '37317': '99.17',
  '37318': '99.17',
  '37319': '99.17',
  '37320': '99.18',
  '37321': '99.18',
  '37322': '99.18',
  '37323': '99.18',
  '37324': '99.18',
  '37325': '99.18',
  '37326': '99.19',
  '37327': '99.19',
  '37328': '99.19',
  '37329': '99.19',
  '37330': '99.19',
  '37331': '99.19',
  '37332': '99.20',
  '37333': '99.20',
  '37334': '99.20',
  '37335': '99.20',
  '37336': '99.20',
  '37337': '99.21',
  '37338': '99.21',
  '37339': '99.21',
  '37340': '99.21',
  '37341': '99.21',
  '37342': '99.21',
  '37343': '99.22',
  '37344': '99.22',
  '37345': '99.22',
  '37346': '99.22',
  '37347': '99.22',
  '37348': '99.23',
  '37349': '99.23',
  '37350': '99.23',
  '37351': '99.23',
  '37352': '99.23',
  '37353': '99.24',
  '37354': '99.24',
  '37355': '99.24',
  '37356': '99.24',
  '37357': '99.24',
  '37358': '99.24',
  '37359': '99.25',
  '37360': '99.25',
  '37361': '99.25',
  '37362': '99.25',
  '37363': '99.25',
  '37364': '99.25',
  '37365': '99.26',
  '37366': '99.26',
  '37367': '99.26',
  '37368': '99.26',
  '37369': '99.26',
  '37370': '99.27',
  '37371': '99.27',
  '37372': '99.27',
  '37373': '99.27',
  '37374': '99.27',
  '37375': '99.28',
  '37376': '99.28',
  '37377': '99.28',
  '37378': '99.28',
  '37379': '99.28',
  '37380': '99.28',
  '37381': '99.29',
  '37382': '99.29',
  '37383': '99.29',
  '37384': '99.29',
  '37385': '99.29',
  '37386': '99.29',
  '37387': '99.30',
  '37388': '99.30',
  '37389': '99.30',
  '37390': '99.30',
  '37391': '99.30',
  '37392': '99.31',
  '37393': '99.31',
  '37394': '99.31',
  '37395': '99.31',
  '37396': '99.31',
  '37397': '99.31',
  '37398': '99.32',
  '37399': '99.32',
  '37400': '99.32',
  '37401': '99.32',
  '37402': '99.32',
  '37403': '99.32',
  '37404': '99.33',
  '37405': '99.33',
  '37406': '99.33',
  '37407': '99.33',
  '37408': '99.33',
  '37409': '99.34',
  '37410': '99.34',
  '37411': '99.34',
  '37412': '99.34',
  '37413': '99.34',
  '37414': '99.35',
  '37415': '99.35',
  '37416': '99.35',
  '37417': '99.35',
  '37418': '99.35',
  '37419': '99.35',
  '37420': '99.36',
  '37421': '99.36',
  '37422': '99.36',
  '37423': '99.36',
  '37424': '99.36',
  '37425': '99.36',
  '37426': '99.37',
  '37427': '99.37',
  '37428': '99.37',
  '37429': '99.37',
  '37430': '99.37',
  '37431': '99.38',
  '37432': '99.38',
  '37433': '99.38',
  '37434': '99.38',
  '37435': '99.38',
  '37436': '99.38',
  '37437': '99.39',
  '37438': '99.39',
  '37439': '99.39',
  '37440': '99.39',
  '37441': '99.39',
  '37442': '99.39',
  '37443': '99.40',
  '37444': '99.40',
  '37445': '99.40',
  '37446': '99.40',
  '37447': '99.40',
  '37448': '99.41',
  '37449': '99.41',
  '37450': '99.41',
  '37451': '99.41',
  '37452': '99.41',
  '37453': '99.42',
  '37454': '99.42',
  '37455': '99.42',
  '37456': '99.42',
  '37457': '99.42',
  '37458': '99.42',
  '37459': '99.43',
  '37460': '99.43',
  '37461': '99.43',
  '37462': '99.43',
  '37463': '99.43',
  '37464': '99.43',
  '37465': '99.44',
  '37466': '99.44',
  '37467': '99.44',
  '37468': '99.44',
  '37469': '99.44',
  '37470': '99.45',
  '37471': '99.45',
  '37472': '99.45',
  '37473': '99.45',
  '37474': '99.45',
  '37475': '99.46',
  '37476': '99.46',
  '37477': '99.46',
  '37478': '99.46',
  '37479': '99.46',
  '37480': '99.46',
  '37481': '99.46',
  '37482': '99.47',
  '37483': '99.47',
  '37484': '99.47',
  '37485': '99.47',
  '37486': '99.47',
  '37487': '99.48',
  '37488': '99.48',
  '37489': '99.48',
  '37490': '99.48',
  '37491': '99.48',
  '37492': '99.49',
  '37493': '99.49',
  '37494': '99.49',
  '37495': '99.49',
  '37496': '99.49',
  '37497': '99.49',
  '37498': '99.50',
  '37499': '99.50',
  '37500': '99.50',
  '37501': '99.50',
  '37502': '99.50',
  '37503': '99.50',
  '37504': '99.51',
  '37505': '99.51',
  '37506': '99.51',
  '37507': '99.51',
  '37508': '99.51',
  '37509': '99.52',
  '37510': '99.52',
  '37511': '99.52',
  '37512': '99.52',
  '37513': '99.52',
  '37514': '99.53',
  '37515': '99.53',
  '37516': '99.53',
  '37517': '99.53',
  '37518': '99.53',
  '37519': '99.53',
  '37520': '99.54',
  '37521': '99.54',
  '37522': '99.54',
  '37523': '99.54',
  '37524': '99.54',
  '37525': '99.54',
  '37526': '99.55',
  '37527': '99.55',
  '37528': '99.55',
  '37529': '99.55',
  '37530': '99.55',
  '37531': '99.56',
  '37532': '99.56',
  '37533': '99.56',
  '37534': '99.56',
  '37535': '99.56',
  '37536': '99.56',
  '37537': '99.57',
  '37538': '99.57',
  '37539': '99.57',
  '37540': '99.57',
  '37541': '99.57',
  '37542': '99.57',
  '37543': '99.58',
  '37544': '99.58',
  '37545': '99.58',
  '37546': '99.58',
  '37547': '99.58',
  '37548': '99.59',
  '37549': '99.59',
  '37550': '99.59',
  '37551': '99.59',
  '37552': '99.59',
  '37553': '99.60',
  '37554': '99.60',
  '37555': '99.60',
  '37556': '99.60',
  '37557': '99.60',
  '37558': '99.60',
  '37559': '99.61',
  '37560': '99.61',
  '37561': '99.61',
  '37562': '99.61',
  '37563': '99.61',
  '37564': '99.61',
  '37565': '99.62',
  '37566': '99.62',
  '37567': '99.62',
  '37568': '99.62',
  '37569': '99.62',
  '37570': '99.63',
  '37571': '99.63',
  '37572': '99.63',
  '37573': '99.63',
  '37574': '99.63',
  '37575': '99.64',
  '37576': '99.64',
  '37577': '99.64',
  '37578': '99.64',
  '37579': '99.64',
  '37580': '99.64',
  '37581': '99.64',
  '37582': '99.65',
  '37583': '99.65',
  '37584': '99.65',
  '37585': '99.65',
  '37586': '99.65',
  '37587': '99.66',
  '37588': '99.66',
  '37589': '99.66',
  '37590': '99.66',
  '37591': '99.66',
  '37592': '99.67',
  '37593': '99.67',
  '37594': '99.67',
  '37595': '99.67',
  '37596': '99.67',
  '37597': '99.67',
  '37598': '99.68',
  '37599': '99.68',
  '37600': '99.68',
  '37601': '99.68',
  '37602': '99.68',
  '37603': '99.68',
  '37604': '99.69',
  '37605': '99.69',
  '37606': '99.69',
  '37607': '99.69',
  '37608': '99.69',
  '37609': '99.70',
  '37610': '99.70',
  '37611': '99.70',
  '37612': '99.70',
  '37613': '99.70',
  '37614': '99.71',
  '37615': '99.71',
  '37616': '99.71',
  '37617': '99.71',
  '37618': '99.71',
  '37619': '99.71',
  '37620': '99.72',
  '37621': '99.72',
  '37622': '99.72',
  '37623': '99.72',
  '37624': '99.72',
  '37625': '99.72',
  '37626': '99.73',
  '37627': '99.73',
  '37628': '99.73',
  '37629': '99.73',
  '37630': '99.73',
  '37631': '99.74',
  '37632': '99.74',
  '37633': '99.74',
  '37634': '99.74',
  '37635': '99.74',
  '37636': '99.74',
  '37637': '99.75',
  '37638': '99.75',
  '37639': '99.75',
  '37640': '99.75',
  '37641': '99.75',
  '37642': '99.75',
  '37643': '99.76',
  '37644': '99.76',
  '37645': '99.76',
  '37646': '99.76',
  '37647': '99.76',
  '37648': '99.77',
  '37649': '99.77',
  '37650': '99.77',
  '37651': '99.77',
  '37652': '99.77',
  '37653': '99.78',
  '37654': '99.78',
  '37655': '99.78',
  '37656': '99.78',
  '37657': '99.78',
  '37658': '99.78',
  '37659': '99.79',
  '37660': '99.79',
  '37661': '99.79',
  '37662': '99.79',
  '37663': '99.79',
  '37664': '99.79',
  '37665': '99.80',
  '37666': '99.80',
  '37667': '99.80',
  '37668': '99.80',
  '37669': '99.80',
  '37670': '99.81',
  '37671': '99.81',
  '37672': '99.81',
  '37673': '99.81',
  '37674': '99.81',
  '37675': '99.82',
  '37676': '99.82',
  '37677': '99.82',
  '37678': '99.82',
  '37679': '99.82',
  '37680': '99.82',
  '37681': '99.82',
  '37682': '99.83',
  '37683': '99.83',
  '37684': '99.83',
  '37685': '99.83',
  '37686': '99.83',
  '37687': '99.84',
  '37688': '99.84',
  '37689': '99.84',
  '37690': '99.84',
  '37691': '99.84',
  '37692': '99.85',
  '37693': '99.85',
  '37694': '99.85',
  '37695': '99.85',
  '37696': '99.85',
  '37697': '99.85',
  '37698': '99.86',
  '37699': '99.86',
  '37700': '99.86',
  '37701': '99.86',
  '37702': '99.86',
  '37703': '99.86',
  '37704': '99.87',
  '37705': '99.87',
  '37706': '99.87',
  '37707': '99.87',
  '37708': '99.87',
  '37709': '99.88',
  '37710': '99.88',
  '37711': '99.88',
  '37712': '99.88',
  '37713': '99.88',
  '37714': '99.89',
  '37715': '99.89',
  '37716': '99.89',
  '37717': '99.89',
  '37718': '99.89',
  '37719': '99.89',
  '37720': '99.90',
  '37721': '99.90',
  '37722': '99.90',
  '37723': '99.90',
  '37724': '99.90',
  '37725': '99.90',
  '37726': '99.91',
  '37727': '99.91',
  '37728': '99.91',
  '37729': '99.91',
  '37730': '99.91',
  '37731': '99.92',
  '37732': '99.92',
  '37733': '99.92',
  '37734': '99.92',
  '37735': '99.92',
  '37736': '99.92',
  '37737': '99.93',
  '37738': '99.93',
  '37739': '99.93',
  '37740': '99.93',
  '37741': '99.93',
  '37742': '99.93',
  '37743': '99.94',
  '37744': '99.94',
  '37745': '99.94',
  '37746': '99.94',
  '37747': '99.94',
  '37748': '99.95',
  '37749': '99.95',
  '37750': '99.95',
  '37751': '99.95',
  '37752': '99.95',
  '37753': '99.96',
  '37754': '99.96',
  '37755': '99.96',
  '37756': '99.96',
  '37757': '99.96',
  '37758': '99.96',
  '37759': '99.97',
  '37760': '99.97',
  '37761': '99.97',
  '37762': '99.97',
  '37763': '99.97',
  '37764': '99.97',
  '37765': '99.98',
  '37766': '99.98',
  '37767': '99.98',
  '37768': '99.98',
  '37769': '99.98',
  '37770': '99.99',
  '37771': '99.99',
  '37772': '99.99',
  '37773': '99.99',
  '37774': '99.99',
  '37775': '100.00',
  '37776': '100.00',
  '37777': '100.00',
  '37778': '100.00',
  '37779': '100.00',
  '37780': '100.00',
  '37781': '100.00',
  '37782': '100.01',
  '37783': '100.01',
  '37784': '100.01',
  '37785': '100.01',
  '37786': '100.01',
  '37787': '100.02',
  '37788': '100.02',
  '37789': '100.02',
  '37790': '100.02',
  '37791': '100.02',
  '37792': '100.03',
  '37793': '100.03',
  '37794': '100.03',
  '37795': '100.03',
  '37796': '100.03',
  '37797': '100.03',
  '37798': '100.04',
  '37799': '100.04',
  '37800': '100.04',
  '37801': '100.04',
  '37802': '100.04',
  '37803': '100.04',
  '37804': '100.05',
  '37805': '100.05',
  '37806': '100.05',
  '37807': '100.05',
  '37808': '100.05',
  '37809': '100.06',
  '37810': '100.06',
  '37811': '100.06',
  '37812': '100.06',
  '37813': '100.06',
  '37814': '100.07',
  '37815': '100.07',
  '37816': '100.07',
  '37817': '100.07',
  '37818': '100.07',
  '37819': '100.07',
  '37820': '100.08',
  '37821': '100.08',
  '37822': '100.08',
  '37823': '100.08',
  '37824': '100.08',
  '37825': '100.08',
  '37826': '100.09',
  '37827': '100.09',
  '37828': '100.09',
  '37829': '100.09',
  '37830': '100.09',
  '37831': '100.10',
  '37832': '100.10',
  '37833': '100.10',
  '37834': '100.10',
  '37835': '100.10',
  '37836': '100.10',
  '37837': '100.11',
  '37838': '100.11',
  '37839': '100.11',
  '37840': '100.11',
  '37841': '100.11',
  '37842': '100.11',
  '37843': '100.12',
  '37844': '100.12',
  '37845': '100.12',
  '37846': '100.12',
  '37847': '100.12',
  '37848': '100.13',
  '37849': '100.13',
  '37850': '100.13',
  '37851': '100.13',
  '37852': '100.13',
  '37853': '100.14',
  '37854': '100.14',
  '37855': '100.14',
  '37856': '100.14',
  '37857': '100.14',
  '37858': '100.14',
  '37859': '100.15',
  '37860': '100.15',
  '37861': '100.15',
  '37862': '100.15',
  '37863': '100.15',
  '37864': '100.15',
  '37865': '100.16',
  '37866': '100.16',
  '37867': '100.16',
  '37868': '100.16',
  '37869': '100.16',
  '37870': '100.17',
  '37871': '100.17',
  '37872': '100.17',
  '37873': '100.17',
  '37874': '100.17',
  '37875': '100.18',
  '37876': '100.18',
  '37877': '100.18',
  '37878': '100.18',
  '37879': '100.18',
  '37880': '100.18',
  '37881': '100.18',
  '37882': '100.19',
  '37883': '100.19',
  '37884': '100.19',
  '37885': '100.19',
  '37886': '100.19',
  '37887': '100.20',
  '37888': '100.20',
  '37889': '100.20',
  '37890': '100.20',
  '37891': '100.20',
  '37892': '100.21',
  '37893': '100.21',
  '37894': '100.21',
  '37895': '100.21',
  '37896': '100.21',
  '37897': '100.21',
  '37898': '100.22',
  '37899': '100.22',
  '37900': '100.22',
  '37901': '100.22',
  '37902': '100.22',
  '37903': '100.22',
  '37904': '100.23',
  '37905': '100.23',
  '37906': '100.23',
  '37907': '100.23',
  '37908': '100.23',
  '37909': '100.24',
  '37910': '100.24',
  '37911': '100.24',
  '37912': '100.24',
  '37913': '100.24',
  '37914': '100.25',
  '37915': '100.25',
  '37916': '100.25',
  '37917': '100.25',
  '37918': '100.25',
  '37919': '100.25',
  '37920': '100.26',
  '37921': '100.26',
  '37922': '100.26',
  '37923': '100.26',
  '37924': '100.26',
  '37925': '100.26',
  '37926': '100.27',
  '37927': '100.27',
  '37928': '100.27',
  '37929': '100.27',
  '37930': '100.27',
  '37931': '100.28',
  '37932': '100.28',
  '37933': '100.28',
  '37934': '100.28',
  '37935': '100.28',
  '37936': '100.28',
  '37937': '100.29',
  '37938': '100.29',
  '37939': '100.29',
  '37940': '100.29',
  '37941': '100.29',
  '37942': '100.29',
  '37943': '100.30',
  '37944': '100.30',
  '37945': '100.30',
  '37946': '100.30',
  '37947': '100.30',
  '37948': '100.31',
  '37949': '100.31',
  '37950': '100.31',
  '37951': '100.31',
  '37952': '100.31',
  '37953': '100.32',
  '37954': '100.32',
  '37955': '100.32',
  '37956': '100.32',
  '37957': '100.32',
  '37958': '100.32',
  '37959': '100.33',
  '37960': '100.33',
  '37961': '100.33',
  '37962': '100.33',
  '37963': '100.33',
  '37964': '100.33',
  '37965': '100.34',
  '37966': '100.34',
  '37967': '100.34',
  '37968': '100.34',
  '37969': '100.34',
  '37970': '100.35',
  '37971': '100.35',
  '37972': '100.35',
  '37973': '100.35',
  '37974': '100.35',
  '37975': '100.36',
  '37976': '100.36',
  '37977': '100.36',
  '37978': '100.36',
  '37979': '100.36',
  '37980': '100.36',
  '37981': '100.36',
  '37982': '100.37',
  '37983': '100.37',
  '37984': '100.37',
  '37985': '100.37',
  '37986': '100.37',
  '37987': '100.38',
  '37988': '100.38',
  '37989': '100.38',
  '37990': '100.38',
  '37991': '100.38',
  '37992': '100.39',
  '37993': '100.39',
  '37994': '100.39',
  '37995': '100.39',
  '37996': '100.39',
  '37997': '100.39',
  '37998': '100.40',
  '37999': '100.40',
  '38000': '100.40',
  '38001': '100.40',
  '38002': '100.40',
  '38003': '100.40',
  '38004': '100.41',
  '38005': '100.41',
  '38006': '100.41',
  '38007': '100.41',
  '38008': '100.41',
  '38009': '100.42',
  '38010': '100.42',
  '38011': '100.42',
  '38012': '100.42',
  '38013': '100.42',
  '38014': '100.43',
  '38015': '100.43',
  '38016': '100.43',
  '38017': '100.43',
  '38018': '100.43',
  '38019': '100.43',
  '38020': '100.44',
  '38021': '100.44',
  '38022': '100.44',
  '38023': '100.44',
  '38024': '100.44',
  '38025': '100.44',
  '38026': '100.45',
  '38027': '100.45',
  '38028': '100.45',
  '38029': '100.45',
  '38030': '100.45',
  '38031': '100.46',
  '38032': '100.46',
  '38033': '100.46',
  '38034': '100.46',
  '38035': '100.46',
  '38036': '100.46',
  '38037': '100.47',
  '38038': '100.47',
  '38039': '100.47',
  '38040': '100.47',
  '38041': '100.47',
  '38042': '100.47',
  '38043': '100.48',
  '38044': '100.48',
  '38045': '100.48',
  '38046': '100.48',
  '38047': '100.48',
  '38048': '100.49',
  '38049': '100.49',
  '38050': '100.49',
  '38051': '100.49',
  '38052': '100.49',
  '38053': '100.50',
  '38054': '100.50',
  '38055': '100.50',
  '38056': '100.50',
  '38057': '100.50',
  '38058': '100.50',
  '38059': '100.51',
  '38060': '100.51',
  '38061': '100.51',
  '38062': '100.51',
  '38063': '100.51',
  '38064': '100.51',
  '38065': '100.52',
  '38066': '100.52',
  '38067': '100.52',
  '38068': '100.52',
  '38069': '100.52',
  '38070': '100.53',
  '38071': '100.53',
  '38072': '100.53',
  '38073': '100.53',
  '38074': '100.53',
  '38075': '100.54',
  '38076': '100.54',
  '38077': '100.54',
  '38078': '100.54',
  '38079': '100.54',
  '38080': '100.54',
  '38081': '100.54',
  '38082': '100.55',
  '38083': '100.55',
  '38084': '100.55',
  '38085': '100.55',
  '38086': '100.55',
  '38087': '100.56',
  '38088': '100.56',
  '38089': '100.56',
  '38090': '100.56',
  '38091': '100.56',
  '38092': '100.57',
  '38093': '100.57',
  '38094': '100.57',
  '38095': '100.57',
  '38096': '100.57',
  '38097': '100.57',
  '38098': '100.58',
  '38099': '100.58',
  '38100': '100.58',
  '38101': '100.58',
  '38102': '100.58',
  '38103': '100.58',
  '38104': '100.59',
  '38105': '100.59',
  '38106': '100.59',
  '38107': '100.59',
  '38108': '100.59',
  '38109': '100.60',
  '38110': '100.60',
  '38111': '100.60',
  '38112': '100.60',
  '38113': '100.60',
  '38114': '100.61',
  '38115': '100.61',
  '38116': '100.61',
  '38117': '100.61',
  '38118': '100.61',
  '38119': '100.61',
  '38120': '100.62',
  '38121': '100.62',
  '38122': '100.62',
  '38123': '100.62',
  '38124': '100.62',
  '38125': '100.62',
  '38126': '100.63',
  '38127': '100.63',
  '38128': '100.63',
  '38129': '100.63',
  '38130': '100.63',
  '38131': '100.64',
  '38132': '100.64',
  '38133': '100.64',
  '38134': '100.64',
  '38135': '100.64',
  '38136': '100.64',
  '38137': '100.65',
  '38138': '100.65',
  '38139': '100.65',
  '38140': '100.65',
  '38141': '100.65',
  '38142': '100.65',
  '38143': '100.66',
  '38144': '100.66',
  '38145': '100.66',
  '38146': '100.66',
  '38147': '100.66',
  '38148': '100.67',
  '38149': '100.67',
  '38150': '100.67',
  '38151': '100.67',
  '38152': '100.67',
  '38153': '100.68',
  '38154': '100.68',
  '38155': '100.68',
  '38156': '100.68',
  '38157': '100.68',
  '38158': '100.68',
  '38159': '100.69',
  '38160': '100.69',
  '38161': '100.69',
  '38162': '100.69',
  '38163': '100.69',
  '38164': '100.69',
  '38165': '100.70',
  '38166': '100.70',
  '38167': '100.70',
  '38168': '100.70',
  '38169': '100.70',
  '38170': '100.71',
  '38171': '100.71',
  '38172': '100.71',
  '38173': '100.71',
  '38174': '100.71',
  '38175': '100.71',
  '38176': '100.72',
  '38177': '100.72',
  '38178': '100.72',
  '38179': '100.72',
  '38180': '100.72',
  '38181': '100.72',
  '38182': '100.73',
  '38183': '100.73',
  '38184': '100.73',
  '38185': '100.73',
  '38186': '100.73',
  '38187': '100.74',
  '38188': '100.74',
  '38189': '100.74',
  '38190': '100.74',
  '38191': '100.74',
  '38192': '100.75',
  '38193': '100.75',
  '38194': '100.75',
  '38195': '100.75',
  '38196': '100.75',
  '38197': '100.75',
  '38198': '100.76',
  '38199': '100.76',
  '38200': '100.76',
  '38201': '100.76',
  '38202': '100.76',
  '38203': '100.76',
  '38204': '100.77',
  '38205': '100.77',
  '38206': '100.77',
  '38207': '100.77',
  '38208': '100.77',
  '38209': '100.78',
  '38210': '100.78',
  '38211': '100.78',
  '38212': '100.78',
  '38213': '100.78',
  '38214': '100.79',
  '38215': '100.79',
  '38216': '100.79',
  '38217': '100.79',
  '38218': '100.79',
  '38219': '100.79',
  '38220': '100.80',
  '38221': '100.80',
  '38222': '100.80',
  '38223': '100.80',
  '38224': '100.80',
  '38225': '100.81',
  '38226': '100.81',
  '38227': '100.81',
  '38228': '100.81',
  '38229': '100.81',
  '38230': '100.81',
  '38231': '100.82',
  '38232': '100.82',
  '38233': '100.82',
  '38234': '100.82',
  '38235': '100.82',
  '38236': '100.82',
  '38237': '100.83',
  '38238': '100.83',
  '38239': '100.83',
  '38240': '100.83',
  '38241': '100.83',
  '38242': '100.83',
  '38243': '100.84',
  '38244': '100.84',
  '38245': '100.84',
  '38246': '100.84',
  '38247': '100.84',
  '38248': '100.85',
  '38249': '100.85',
  '38250': '100.85',
  '38251': '100.85',
  '38252': '100.85',
  '38253': '100.86',
  '38254': '100.86',
  '38255': '100.86',
  '38256': '100.86',
  '38257': '100.86',
  '38258': '100.86',
  '38259': '100.87',
  '38260': '100.87',
  '38261': '100.87',
  '38262': '100.87',
  '38263': '100.87',
  '38264': '100.88',
  '38265': '100.88',
  '38266': '100.88',
  '38267': '100.88',
  '38268': '100.88',
  '38269': '100.88',
  '38270': '100.89',
  '38271': '100.89',
  '38272': '100.89',
  '38273': '100.89',
  '38274': '100.89',
  '38275': '100.89',
  '38276': '100.90',
  '38277': '100.90',
  '38278': '100.90',
  '38279': '100.90',
  '38280': '100.90',
  '38281': '100.90',
  '38282': '100.91',
  '38283': '100.91',
  '38284': '100.91',
  '38285': '100.91',
  '38286': '100.91',
  '38287': '100.92',
  '38288': '100.92',
  '38289': '100.92',
  '38290': '100.92',
  '38291': '100.92',
  '38292': '100.93',
  '38293': '100.93',
  '38294': '100.93',
  '38295': '100.93',
  '38296': '100.93',
  '38297': '100.93',
  '38298': '100.94',
  '38299': '100.94',
  '38300': '100.94',
  '38301': '100.94',
  '38302': '100.94',
  '38303': '100.94',
  '38304': '100.95',
  '38305': '100.95',
  '38306': '100.95',
  '38307': '100.95',
  '38308': '100.95',
  '38309': '100.96',
  '38310': '100.96',
  '38311': '100.96',
  '38312': '100.96',
  '38313': '100.96',
  '38314': '100.96',
  '38315': '100.97',
  '38316': '100.97',
  '38317': '100.97',
  '38318': '100.97',
  '38319': '100.97',
  '38320': '100.98',
  '38321': '100.98',
  '38322': '100.98',
  '38323': '100.98',
  '38324': '100.98',
  '38325': '100.99',
  '38326': '100.99',
  '38327': '100.99',
  '38328': '100.99',
  '38329': '100.99',
  '38330': '100.99',
  '38331': '101.00',
  '38332': '101.00',
  '38333': '101.00',
  '38334': '101.00',
  '38335': '101.00',
  '38336': '101.00',
  '38337': '101.01',
  '38338': '101.01',
  '38339': '101.01',
  '38340': '101.01',
  '38341': '101.01',
  '38342': '101.01',
  '38343': '101.02',
  '38344': '101.02',
  '38345': '101.02',
  '38346': '101.02',
  '38347': '101.02',
  '38348': '101.03',
  '38349': '101.03',
  '38350': '101.03',
  '38351': '101.03',
  '38352': '101.03',
  '38353': '101.04',
  '38354': '101.04',
  '38355': '101.04',
  '38356': '101.04',
  '38357': '101.04',
  '38358': '101.04',
  '38359': '101.05',
  '38360': '101.05',
  '38361': '101.05',
  '38362': '101.05',
  '38363': '101.05',
  '38364': '101.06',
  '38365': '101.06',
  '38366': '101.06',
  '38367': '101.06',
  '38368': '101.06',
  '38369': '101.06',
  '38370': '101.07',
  '38371': '101.07',
  '38372': '101.07',
  '38373': '101.07',
  '38374': '101.07',
  '38375': '101.07',
  '38376': '101.08',
  '38377': '101.08',
  '38378': '101.08',
  '38379': '101.08',
  '38380': '101.08',
  '38381': '101.08',
  '38382': '101.09',
  '38383': '101.09',
  '38384': '101.09',
  '38385': '101.09',
  '38386': '101.09',
  '38387': '101.10',
  '38388': '101.10',
  '38389': '101.10',
  '38390': '101.10',
  '38391': '101.10',
  '38392': '101.11',
  '38393': '101.11',
  '38394': '101.11',
  '38395': '101.11',
  '38396': '101.11',
  '38397': '101.11',
  '38398': '101.12',
  '38399': '101.12',
  '38400': '101.12',
  '38401': '101.12',
  '38402': '101.12',
  '38403': '101.12',
  '38404': '101.13',
  '38405': '101.13',
  '38406': '101.13',
  '38407': '101.13',
  '38408': '101.13',
  '38409': '101.14',
  '38410': '101.14',
  '38411': '101.14',
  '38412': '101.14',
  '38413': '101.14',
  '38414': '101.14',
  '38415': '101.15',
  '38416': '101.15',
  '38417': '101.15',
  '38418': '101.15',
  '38419': '101.15',
  '38420': '101.16',
  '38421': '101.16',
  '38422': '101.16',
  '38423': '101.16',
  '38424': '101.16',
  '38425': '101.17',
  '38426': '101.17',
  '38427': '101.17',
  '38428': '101.17',
  '38429': '101.17',
  '38430': '101.17',
  '38431': '101.18',
  '38432': '101.18',
  '38433': '101.18',
  '38434': '101.18',
  '38435': '101.18',
  '38436': '101.18',
  '38437': '101.19',
  '38438': '101.19',
  '38439': '101.19',
  '38440': '101.19',
  '38441': '101.19',
  '38442': '101.19',
  '38443': '101.20',
  '38444': '101.20',
  '38445': '101.20',
  '38446': '101.20',
  '38447': '101.20',
  '38448': '101.21',
  '38449': '101.21',
  '38450': '101.21',
  '38451': '101.21',
  '38452': '101.21',
  '38453': '101.21',
  '38454': '101.22',
  '38455': '101.22',
  '38456': '101.22',
  '38457': '101.22',
  '38458': '101.22',
  '38459': '101.23',
  '38460': '101.23',
  '38461': '101.23',
  '38462': '101.23',
  '38463': '101.23',
  '38464': '101.24',
  '38465': '101.24',
  '38466': '101.24',
  '38467': '101.24',
  '38468': '101.24',
  '38469': '101.24',
  '38470': '101.25',
  '38471': '101.25',
  '38472': '101.25',
  '38473': '101.25',
  '38474': '101.25',
  '38475': '101.25',
  '38476': '101.26',
  '38477': '101.26',
  '38478': '101.26',
  '38479': '101.26',
  '38480': '101.26',
  '38481': '101.26',
  '38482': '101.27',
  '38483': '101.27',
  '38484': '101.27',
  '38485': '101.27',
  '38486': '101.27',
  '38487': '101.28',
  '38488': '101.28',
  '38489': '101.28',
  '38490': '101.28',
  '38491': '101.28',
  '38492': '101.29',
  '38493': '101.29',
  '38494': '101.29',
  '38495': '101.29',
  '38496': '101.29',
  '38497': '101.29',
  '38498': '101.30',
  '38499': '101.30',
  '38500': '101.30',
  '38501': '101.30',
  '38502': '101.30',
  '38503': '101.31',
  '38504': '101.31',
  '38505': '101.31',
  '38506': '101.31',
  '38507': '101.31',
  '38508': '101.31',
  '38509': '101.32',
  '38510': '101.32',
  '38511': '101.32',
  '38512': '101.32',
  '38513': '101.32',
  '38514': '101.32',
  '38515': '101.33',
  '38516': '101.33',
  '38517': '101.33',
  '38518': '101.33',
  '38519': '101.33',
  '38520': '101.34',
  '38521': '101.34',
  '38522': '101.34',
  '38523': '101.34',
  '38524': '101.34',
  '38525': '101.35',
  '38526': '101.35',
  '38527': '101.35',
  '38528': '101.35',
  '38529': '101.35',
  '38530': '101.35',
  '38531': '101.36',
  '38532': '101.36',
  '38533': '101.36',
  '38534': '101.36',
  '38535': '101.36',
  '38536': '101.36',
  '38537': '101.37',
  '38538': '101.37',
  '38539': '101.37',
  '38540': '101.37',
  '38541': '101.37',
  '38542': '101.38',
  '38543': '101.38',
  '38544': '101.38',
  '38545': '101.38',
  '38546': '101.38',
  '38547': '101.38',
  '38548': '101.39',
  '38549': '101.39',
  '38550': '101.39',
  '38551': '101.39',
  '38552': '101.39',
  '38553': '101.39',
  '38554': '101.40',
  '38555': '101.40',
  '38556': '101.40',
  '38557': '101.40',
  '38558': '101.40',
  '38559': '101.41',
  '38560': '101.41',
  '38561': '101.41',
  '38562': '101.41',
  '38563': '101.41',
  '38564': '101.42',
  '38565': '101.42',
  '38566': '101.42',
  '38567': '101.42',
  '38568': '101.42',
  '38569': '101.42',
  '38570': '101.43',
  '38571': '101.43',
  '38572': '101.43',
  '38573': '101.43',
  '38574': '101.43',
  '38575': '101.43',
  '38576': '101.44',
  '38577': '101.44',
  '38578': '101.44',
  '38579': '101.44',
  '38580': '101.44',
  '38581': '101.44',
  '38582': '101.45',
  '38583': '101.45',
  '38584': '101.45',
  '38585': '101.45',
  '38586': '101.45',
  '38587': '101.46',
  '38588': '101.46',
  '38589': '101.46',
  '38590': '101.46',
  '38591': '101.46',
  '38592': '101.46',
  '38593': '101.47',
  '38594': '101.47',
  '38595': '101.47',
  '38596': '101.47',
  '38597': '101.47',
  '38598': '101.48',
  '38599': '101.48',
  '38600': '101.48',
  '38601': '101.48',
  '38602': '101.48',
  '38603': '101.49',
  '38604': '101.49',
  '38605': '101.49',
  '38606': '101.49',
  '38607': '101.49',
  '38608': '101.49',
  '38609': '101.50',
  '38610': '101.50',
  '38611': '101.50',
  '38612': '101.50',
  '38613': '101.50',
  '38614': '101.50',
  '38615': '101.51',
  '38616': '101.51',
  '38617': '101.51',
  '38618': '101.51',
  '38619': '101.51',
  '38620': '101.52',
  '38621': '101.52',
  '38622': '101.52',
  '38623': '101.52',
  '38624': '101.52',
  '38625': '101.53',
  '38626': '101.53',
  '38627': '101.53',
  '38628': '101.53',
  '38629': '101.53',
  '38630': '101.53',
  '38631': '101.54',
  '38632': '101.54',
  '38633': '101.54',
  '38634': '101.54',
  '38635': '101.54',
  '38636': '101.54',
  '38637': '101.55',
  '38638': '101.55',
  '38639': '101.55',
  '38640': '101.55',
  '38641': '101.55',
  '38642': '101.56',
  '38643': '101.56',
  '38644': '101.56',
  '38645': '101.56',
  '38646': '101.56',
  '38647': '101.56',
  '38648': '101.57',
  '38649': '101.57',
  '38650': '101.57',
  '38651': '101.57',
  '38652': '101.57',
  '38653': '101.57',
  '38654': '101.58',
  '38655': '101.58',
  '38656': '101.58',
  '38657': '101.58',
  '38658': '101.58',
  '38659': '101.59',
  '38660': '101.59',
  '38661': '101.59',
  '38662': '101.59',
  '38663': '101.59',
  '38664': '101.60',
  '38665': '101.60',
  '38666': '101.60',
  '38667': '101.60',
  '38668': '101.60',
  '38669': '101.60',
  '38670': '101.61',
  '38671': '101.61',
  '38672': '101.61',
  '38673': '101.61',
  '38674': '101.61',
  '38675': '101.61',
  '38676': '101.62',
  '38677': '101.62',
  '38678': '101.62',
  '38679': '101.62',
  '38680': '101.62',
  '38681': '101.62',
  '38682': '101.63',
  '38683': '101.63',
  '38684': '101.63',
  '38685': '101.63',
  '38686': '101.63',
  '38687': '101.64',
  '38688': '101.64',
  '38689': '101.64',
  '38690': '101.64',
  '38691': '101.64',
  '38692': '101.64',
  '38693': '101.65',
  '38694': '101.65',
  '38695': '101.65',
  '38696': '101.65',
  '38697': '101.65',
  '38698': '101.66',
  '38699': '101.66',
  '38700': '101.66',
  '38701': '101.66',
  '38702': '101.66',
  '38703': '101.67',
  '38704': '101.67',
  '38705': '101.67',
  '38706': '101.67',
  '38707': '101.67',
  '38708': '101.67',
  '38709': '101.68',
  '38710': '101.68',
  '38711': '101.68',
  '38712': '101.68',
  '38713': '101.68',
  '38714': '101.68',
  '38715': '101.69',
  '38716': '101.69',
  '38717': '101.69',
  '38718': '101.69',
  '38719': '101.69',
  '38720': '101.70',
  '38721': '101.70',
  '38722': '101.70',
  '38723': '101.70',
  '38724': '101.70',
  '38725': '101.71',
  '38726': '101.71',
  '38727': '101.71',
  '38728': '101.71',
  '38729': '101.71',
  '38730': '101.71',
  '38731': '101.71',
  '38732': '101.72',
  '38733': '101.72',
  '38734': '101.72',
  '38735': '101.72',
  '38736': '101.72',
  '38737': '101.73',
  '38738': '101.73',
  '38739': '101.73',
  '38740': '101.73',
  '38741': '101.73',
  '38742': '101.74',
  '38743': '101.74',
  '38744': '101.74',
  '38745': '101.74',
  '38746': '101.74',
  '38747': '101.74',
  '38748': '101.75',
  '38749': '101.75',
  '38750': '101.75',
  '38751': '101.75',
  '38752': '101.75',
  '38753': '101.75',
  '38754': '101.76',
  '38755': '101.76',
  '38756': '101.76',
  '38757': '101.76',
  '38758': '101.76',
  '38759': '101.77',
  '38760': '101.77',
  '38761': '101.77',
  '38762': '101.77',
  '38763': '101.77',
  '38764': '101.78',
  '38765': '101.78',
  '38766': '101.78',
  '38767': '101.78',
  '38768': '101.78',
  '38769': '101.78',
  '38770': '101.79',
  '38771': '101.79',
  '38772': '101.79',
  '38773': '101.79',
  '38774': '101.79',
  '38775': '101.79',
  '38776': '101.80',
  '38777': '101.80',
  '38778': '101.80',
  '38779': '101.80',
  '38780': '101.80',
  '38781': '101.81',
  '38782': '101.81',
  '38783': '101.81',
  '38784': '101.81',
  '38785': '101.81',
  '38786': '101.81',
  '38787': '101.82',
  '38788': '101.82',
  '38789': '101.82',
  '38790': '101.82',
  '38791': '101.82',
  '38792': '101.82',
  '38793': '101.83',
  '38794': '101.83',
  '38795': '101.83',
  '38796': '101.83',
  '38797': '101.83',
  '38798': '101.84',
  '38799': '101.84',
  '38800': '101.84',
  '38801': '101.84',
  '38802': '101.84',
  '38803': '101.85',
  '38804': '101.85',
  '38805': '101.85',
  '38806': '101.85',
  '38807': '101.85',
  '38808': '101.85',
  '38809': '101.86',
  '38810': '101.86',
  '38811': '101.86',
  '38812': '101.86',
  '38813': '101.86',
  '38814': '101.86',
  '38815': '101.87',
  '38816': '101.87',
  '38817': '101.87',
  '38818': '101.87',
  '38819': '101.87',
  '38820': '101.88',
  '38821': '101.88',
  '38822': '101.88',
  '38823': '101.88',
  '38824': '101.88',
  '38825': '101.89',
  '38826': '101.89',
  '38827': '101.89',
  '38828': '101.89',
  '38829': '101.89',
  '38830': '101.89',
  '38831': '101.89',
  '38832': '101.90',
  '38833': '101.90',
  '38834': '101.90',
  '38835': '101.90',
  '38836': '101.90',
  '38837': '101.91',
  '38838': '101.91',
  '38839': '101.91',
  '38840': '101.91',
  '38841': '101.91',
  '38842': '101.92',
  '38843': '101.92',
  '38844': '101.92',
  '38845': '101.92',
  '38846': '101.92',
  '38847': '101.92',
  '38848': '101.93',
  '38849': '101.93',
  '38850': '101.93',
  '38851': '101.93',
  '38852': '101.93',
  '38853': '101.93',
  '38854': '101.94',
  '38855': '101.94',
  '38856': '101.94',
  '38857': '101.94',
  '38858': '101.94',
  '38859': '101.95',
  '38860': '101.95',
  '38861': '101.95',
  '38862': '101.95',
  '38863': '101.95',
  '38864': '101.96',
  '38865': '101.96',
  '38866': '101.96',
  '38867': '101.96',
  '38868': '101.96',
  '38869': '101.96',
  '38870': '101.97',
  '38871': '101.97',
  '38872': '101.97',
  '38873': '101.97',
  '38874': '101.97',
  '38875': '101.97',
  '38876': '101.98',
  '38877': '101.98',
  '38878': '101.98',
  '38879': '101.98',
  '38880': '101.98',
  '38881': '101.99',
  '38882': '101.99',
  '38883': '101.99',
  '38884': '101.99',
  '38885': '101.99',
  '38886': '101.99',
  '38887': '102.00',
  '38888': '102.00',
  '38889': '102.00',
  '38890': '102.00',
  '38891': '102.00',
  '38892': '102.00',
  '38893': '102.01',
  '38894': '102.01',
  '38895': '102.01',
  '38896': '102.01',
  '38897': '102.01',
  '38898': '102.02',
  '38899': '102.02',
  '38900': '102.02',
  '38901': '102.02',
  '38902': '102.02',
  '38903': '102.03',
  '38904': '102.03',
  '38905': '102.03',
  '38906': '102.03',
  '38907': '102.03',
  '38908': '102.03',
  '38909': '102.04',
  '38910': '102.04',
  '38911': '102.04',
  '38912': '102.04',
  '38913': '102.04',
  '38914': '102.04',
  '38915': '102.05',
  '38916': '102.05',
  '38917': '102.05',
  '38918': '102.05',
  '38919': '102.05',
  '38920': '102.06',
  '38921': '102.06',
  '38922': '102.06',
  '38923': '102.06',
  '38924': '102.06',
  '38925': '102.07',
  '38926': '102.07',
  '38927': '102.07',
  '38928': '102.07',
  '38929': '102.07',
  '38930': '102.07',
  '38931': '102.07',
  '38932': '102.08',
  '38933': '102.08',
  '38934': '102.08',
  '38935': '102.08',
  '38936': '102.08',
  '38937': '102.09',
  '38938': '102.09',
  '38939': '102.09',
  '38940': '102.09',
  '38941': '102.09',
  '38942': '102.10',
  '38943': '102.10',
  '38944': '102.10',
  '38945': '102.10',
  '38946': '102.10',
  '38947': '102.10',
  '38948': '102.11',
  '38949': '102.11',
  '38950': '102.11',
  '38951': '102.11',
  '38952': '102.11',
  '38953': '102.11',
  '38954': '102.12',
  '38955': '102.12',
  '38956': '102.12',
  '38957': '102.12',
  '38958': '102.12',
  '38959': '102.13',
  '38960': '102.13',
  '38961': '102.13',
  '38962': '102.13',
  '38963': '102.13',
  '38964': '102.14',
  '38965': '102.14',
  '38966': '102.14',
  '38967': '102.14',
  '38968': '102.14',
  '38969': '102.14',
  '38970': '102.15',
  '38971': '102.15',
  '38972': '102.15',
  '38973': '102.15',
  '38974': '102.15',
  '38975': '102.15',
  '38976': '102.16',
  '38977': '102.16',
  '38978': '102.16',
  '38979': '102.16',
  '38980': '102.16',
  '38981': '102.17',
  '38982': '102.17',
  '38983': '102.17',
  '38984': '102.17',
  '38985': '102.17',
  '38986': '102.17',
  '38987': '102.18',
  '38988': '102.18',
  '38989': '102.18',
  '38990': '102.18',
  '38991': '102.18',
  '38992': '102.18',
  '38993': '102.19',
  '38994': '102.19',
  '38995': '102.19',
  '38996': '102.19',
  '38997': '102.19',
  '38998': '102.20',
  '38999': '102.20',
  '39000': '102.20',
  '39001': '102.20',
  '39002': '102.20',
  '39003': '102.21',
  '39004': '102.21',
  '39005': '102.21',
  '39006': '102.21',
  '39007': '102.21',
  '39008': '102.21',
  '39009': '102.22',
  '39010': '102.22',
  '39011': '102.22',
  '39012': '102.22',
  '39013': '102.22',
  '39014': '102.22',
  '39015': '102.23',
  '39016': '102.23',
  '39017': '102.23',
  '39018': '102.23',
  '39019': '102.23',
  '39020': '102.24',
  '39021': '102.24',
  '39022': '102.24',
  '39023': '102.24',
  '39024': '102.24',
  '39025': '102.25',
  '39026': '102.25',
  '39027': '102.25',
  '39028': '102.25',
  '39029': '102.25',
  '39030': '102.25',
  '39031': '102.25',
  '39032': '102.26',
  '39033': '102.26',
  '39034': '102.26',
  '39035': '102.26',
  '39036': '102.26',
  '39037': '102.27',
  '39038': '102.27',
  '39039': '102.27',
  '39040': '102.27',
  '39041': '102.27',
  '39042': '102.28',
  '39043': '102.28',
  '39044': '102.28',
  '39045': '102.28',
  '39046': '102.28',
  '39047': '102.28',
  '39048': '102.29',
  '39049': '102.29',
  '39050': '102.29',
  '39051': '102.29',
  '39052': '102.29',
  '39053': '102.29',
  '39054': '102.30',
  '39055': '102.30',
  '39056': '102.30',
  '39057': '102.30',
  '39058': '102.30',
  '39059': '102.31',
  '39060': '102.31',
  '39061': '102.31',
  '39062': '102.31',
  '39063': '102.31',
  '39064': '102.32',
  '39065': '102.32',
  '39066': '102.32',
  '39067': '102.32',
  '39068': '102.32',
  '39069': '102.32',
  '39070': '102.33',
  '39071': '102.33',
  '39072': '102.33',
  '39073': '102.33',
  '39074': '102.33',
  '39075': '102.33',
  '39076': '102.34',
  '39077': '102.34',
  '39078': '102.34',
  '39079': '102.34',
  '39080': '102.34',
  '39081': '102.35',
  '39082': '102.35',
  '39083': '102.35',
  '39084': '102.35',
  '39085': '102.35',
  '39086': '102.35',
  '39087': '102.36',
  '39088': '102.36',
  '39089': '102.36',
  '39090': '102.36',
  '39091': '102.36',
  '39092': '102.36',
  '39093': '102.37',
  '39094': '102.37',
  '39095': '102.37',
  '39096': '102.37',
  '39097': '102.37',
  '39098': '102.38',
  '39099': '102.38',
  '39100': '102.38',
  '39101': '102.38',
  '39102': '102.38',
  '39103': '102.39',
  '39104': '102.39',
  '39105': '102.39',
  '39106': '102.39',
  '39107': '102.39',
  '39108': '102.39',
  '39109': '102.40',
  '39110': '102.40',
  '39111': '102.40',
  '39112': '102.40',
  '39113': '102.40',
  '39114': '102.40',
  '39115': '102.41',
  '39116': '102.41',
  '39117': '102.41',
  '39118': '102.41',
  '39119': '102.41',
  '39120': '102.42',
  '39121': '102.42',
  '39122': '102.42',
  '39123': '102.42',
  '39124': '102.42',
  '39125': '102.43',
  '39126': '102.43',
  '39127': '102.43',
  '39128': '102.43',
  '39129': '102.43',
  '39130': '102.43',
  '39131': '102.43',
  '39132': '102.44',
  '39133': '102.44',
  '39134': '102.44',
  '39135': '102.44',
  '39136': '102.44',
  '39137': '102.45',
  '39138': '102.45',
  '39139': '102.45',
  '39140': '102.45',
  '39141': '102.45',
  '39142': '102.46',
  '39143': '102.46',
  '39144': '102.46',
  '39145': '102.46',
  '39146': '102.46',
  '39147': '102.46',
  '39148': '102.47',
  '39149': '102.47',
  '39150': '102.47',
  '39151': '102.47',
  '39152': '102.47',
  '39153': '102.47',
  '39154': '102.48',
  '39155': '102.48',
  '39156': '102.48',
  '39157': '102.48',
  '39158': '102.48',
  '39159': '102.49',
  '39160': '102.49',
  '39161': '102.49',
  '39162': '102.49',
  '39163': '102.49',
  '39164': '102.50',
  '39165': '102.50',
  '39166': '102.50',
  '39167': '102.50',
  '39168': '102.50',
  '39169': '102.50',
  '39170': '102.51',
  '39171': '102.51',
  '39172': '102.51',
  '39173': '102.51',
  '39174': '102.51',
  '39175': '102.51',
  '39176': '102.52',
  '39177': '102.52',
  '39178': '102.52',
  '39179': '102.52',
  '39180': '102.52',
  '39181': '102.53',
  '39182': '102.53',
  '39183': '102.53',
  '39184': '102.53',
  '39185': '102.53',
  '39186': '102.53',
  '39187': '102.54',
  '39188': '102.54',
  '39189': '102.54',
  '39190': '102.54',
  '39191': '102.54',
  '39192': '102.54',
  '39193': '102.55',
  '39194': '102.55',
  '39195': '102.55',
  '39196': '102.55',
  '39197': '102.55',
  '39198': '102.56',
  '39199': '102.56',
  '39200': '102.56',
  '39201': '102.56',
  '39202': '102.56',
  '39203': '102.57',
  '39204': '102.57',
  '39205': '102.57',
  '39206': '102.57',
  '39207': '102.57',
  '39208': '102.57',
  '39209': '102.58',
  '39210': '102.58',
  '39211': '102.58',
  '39212': '102.58',
  '39213': '102.58',
  '39214': '102.58',
  '39215': '102.59',
  '39216': '102.59',
  '39217': '102.59',
  '39218': '102.59',
  '39219': '102.59',
  '39220': '102.60',
  '39221': '102.60',
  '39222': '102.60',
  '39223': '102.60',
  '39224': '102.60',
  '39225': '102.61',
  '39226': '102.61',
  '39227': '102.61',
  '39228': '102.61',
  '39229': '102.61',
  '39230': '102.61',
  '39231': '102.61',
  '39232': '102.62',
  '39233': '102.62',
  '39234': '102.62',
  '39235': '102.62',
  '39236': '102.62',
  '39237': '102.63',
  '39238': '102.63',
  '39239': '102.63',
  '39240': '102.63',
  '39241': '102.63',
  '39242': '102.64',
  '39243': '102.64',
  '39244': '102.64',
  '39245': '102.64',
  '39246': '102.64',
  '39247': '102.64',
  '39248': '102.65',
  '39249': '102.65',
  '39250': '102.65',
  '39251': '102.65',
  '39252': '102.65',
  '39253': '102.65',
  '39254': '102.66',
  '39255': '102.66',
  '39256': '102.66',
  '39257': '102.66',
  '39258': '102.66',
  '39259': '102.67',
  '39260': '102.67',
  '39261': '102.67',
  '39262': '102.67',
  '39263': '102.67',
  '39264': '102.68',
  '39265': '102.68',
  '39266': '102.68',
  '39267': '102.68',
  '39268': '102.68',
  '39269': '102.68',
  '39270': '102.69',
  '39271': '102.69',
  '39272': '102.69',
  '39273': '102.69',
  '39274': '102.69',
  '39275': '102.69',
  '39276': '102.70',
  '39277': '102.70',
  '39278': '102.70',
  '39279': '102.70',
  '39280': '102.70',
  '39281': '102.71',
  '39282': '102.71',
  '39283': '102.71',
  '39284': '102.71',
  '39285': '102.71',
  '39286': '102.71',
  '39287': '102.72',
  '39288': '102.72',
  '39289': '102.72',
  '39290': '102.72',
  '39291': '102.72',
  '39292': '102.72',
  '39293': '102.73',
  '39294': '102.73',
  '39295': '102.73',
  '39296': '102.73',
  '39297': '102.73',
  '39298': '102.74',
  '39299': '102.74',
  '39300': '102.74',
  '39301': '102.74',
  '39302': '102.74',
  '39303': '102.75',
  '39304': '102.75',
  '39305': '102.75',
  '39306': '102.75',
  '39307': '102.75',
  '39308': '102.75',
  '39309': '102.76',
  '39310': '102.76',
  '39311': '102.76',
  '39312': '102.76',
  '39313': '102.76',
  '39314': '102.76',
  '39315': '102.77',
  '39316': '102.77',
  '39317': '102.77',
  '39318': '102.77',
  '39319': '102.77',
  '39320': '102.78',
  '39321': '102.78',
  '39322': '102.78',
  '39323': '102.78',
  '39324': '102.78',
  '39325': '102.79',
  '39326': '102.79',
  '39327': '102.79',
  '39328': '102.79',
  '39329': '102.79',
  '39330': '102.79',
  '39331': '102.79',
  '39332': '102.80',
  '39333': '102.80',
  '39334': '102.80',
  '39335': '102.80',
  '39336': '102.80',
  '39337': '102.81',
  '39338': '102.81',
  '39339': '102.81',
  '39340': '102.81',
  '39341': '102.81',
  '39342': '102.82',
  '39343': '102.82',
  '39344': '102.82',
  '39345': '102.82',
  '39346': '102.82',
  '39347': '102.82',
  '39348': '102.83',
  '39349': '102.83',
  '39350': '102.83',
  '39351': '102.83',
  '39352': '102.83',
  '39353': '102.83',
  '39354': '102.84',
  '39355': '102.84',
  '39356': '102.84',
  '39357': '102.84',
  '39358': '102.84',
  '39359': '102.85',
  '39360': '102.85',
  '39361': '102.85',
  '39362': '102.85',
  '39363': '102.85',
  '39364': '102.86',
  '39365': '102.86',
  '39366': '102.86',
  '39367': '102.86',
  '39368': '102.86',
  '39369': '102.86',
  '39370': '102.87',
  '39371': '102.87',
  '39372': '102.87',
  '39373': '102.87',
  '39374': '102.87',
  '39375': '102.88',
  '39376': '102.88',
  '39377': '102.88',
  '39378': '102.88',
  '39379': '102.88',
  '39380': '102.88',
  '39381': '102.89',
  '39382': '102.89',
  '39383': '102.89',
  '39384': '102.89',
  '39385': '102.89',
  '39386': '102.89',
  '39387': '102.90',
  '39388': '102.90',
  '39389': '102.90',
  '39390': '102.90',
  '39391': '102.90',
  '39392': '102.90',
  '39393': '102.91',
  '39394': '102.91',
  '39395': '102.91',
  '39396': '102.91',
  '39397': '102.91',
  '39398': '102.92',
  '39399': '102.92',
  '39400': '102.92',
  '39401': '102.92',
  '39402': '102.92',
  '39403': '102.93',
  '39404': '102.93',
  '39405': '102.93',
  '39406': '102.93',
  '39407': '102.93',
  '39408': '102.93',
  '39409': '102.94',
  '39410': '102.94',
  '39411': '102.94',
  '39412': '102.94',
  '39413': '102.94',
  '39414': '102.94',
  '39415': '102.95',
  '39416': '102.95',
  '39417': '102.95',
  '39418': '102.95',
  '39419': '102.95',
  '39420': '102.96',
  '39421': '102.96',
  '39422': '102.96',
  '39423': '102.96',
  '39424': '102.96',
  '39425': '102.96',
  '39426': '102.97',
  '39427': '102.97',
  '39428': '102.97',
  '39429': '102.97',
  '39430': '102.97',
  '39431': '102.97',
  '39432': '102.98',
  '39433': '102.98',
  '39434': '102.98',
  '39435': '102.98',
  '39436': '102.98',
  '39437': '102.99',
  '39438': '102.99',
  '39439': '102.99',
  '39440': '102.99',
  '39441': '102.99',
  '39442': '103.00',
  '39443': '103.00',
  '39444': '103.00',
  '39445': '103.00',
  '39446': '103.00',
  '39447': '103.00',
  '39448': '103.01',
  '39449': '103.01',
  '39450': '103.01',
  '39451': '103.01',
  '39452': '103.01',
  '39453': '103.01',
  '39454': '103.02',
  '39455': '103.02',
  '39456': '103.02',
  '39457': '103.02',
  '39458': '103.02',
  '39459': '103.03',
  '39460': '103.03',
  '39461': '103.03',
  '39462': '103.03',
  '39463': '103.03',
  '39464': '103.04',
  '39465': '103.04',
  '39466': '103.04',
  '39467': '103.04',
  '39468': '103.04',
  '39469': '103.04',
  '39470': '103.05',
  '39471': '103.05',
  '39472': '103.05',
  '39473': '103.05',
  '39474': '103.05',
  '39475': '103.06',
  '39476': '103.06',
  '39477': '103.06',
  '39478': '103.06',
  '39479': '103.06',
  '39480': '103.06',
  '39481': '103.07',
  '39482': '103.07',
  '39483': '103.07',
  '39484': '103.07',
  '39485': '103.07',
  '39486': '103.07',
  '39487': '103.08',
  '39488': '103.08',
  '39489': '103.08',
  '39490': '103.08',
  '39491': '103.08',
  '39492': '103.08',
  '39493': '103.09',
  '39494': '103.09',
  '39495': '103.09',
  '39496': '103.09',
  '39497': '103.09',
  '39498': '103.10',
  '39499': '103.10',
  '39500': '103.10',
  '39501': '103.10',
  '39502': '103.10',
  '39503': '103.11',
  '39504': '103.11',
  '39505': '103.11',
  '39506': '103.11',
  '39507': '103.11',
  '39508': '103.11',
  '39509': '103.12',
  '39510': '103.12',
  '39511': '103.12',
  '39512': '103.12',
  '39513': '103.12',
  '39514': '103.12',
  '39515': '103.13',
  '39516': '103.13',
  '39517': '103.13',
  '39518': '103.13',
  '39519': '103.13',
  '39520': '103.14',
  '39521': '103.14',
  '39522': '103.14',
  '39523': '103.14',
  '39524': '103.14',
  '39525': '103.14',
  '39526': '103.15',
  '39527': '103.15',
  '39528': '103.15',
  '39529': '103.15',
  '39530': '103.15',
  '39531': '103.15',
  '39532': '103.16',
  '39533': '103.16',
  '39534': '103.16',
  '39535': '103.16',
  '39536': '103.16',
  '39537': '103.17',
  '39538': '103.17',
  '39539': '103.17',
  '39540': '103.17',
  '39541': '103.17',
  '39542': '103.18',
  '39543': '103.18',
  '39544': '103.18',
  '39545': '103.18',
  '39546': '103.18',
  '39547': '103.18',
  '39548': '103.19',
  '39549': '103.19',
  '39550': '103.19',
  '39551': '103.19',
  '39552': '103.19',
  '39553': '103.19',
  '39554': '103.20',
  '39555': '103.20',
  '39556': '103.20',
  '39557': '103.20',
  '39558': '103.20',
  '39559': '103.21',
  '39560': '103.21',
  '39561': '103.21',
  '39562': '103.21',
  '39563': '103.21',
  '39564': '103.21',
  '39565': '103.22',
  '39566': '103.22',
  '39567': '103.22',
  '39568': '103.22',
  '39569': '103.22',
  '39570': '103.23',
  '39571': '103.23',
  '39572': '103.23',
  '39573': '103.23',
  '39574': '103.23',
  '39575': '103.24',
  '39576': '103.24',
  '39577': '103.24',
  '39578': '103.24',
  '39579': '103.24',
  '39580': '103.24',
  '39581': '103.25',
  '39582': '103.25',
  '39583': '103.25',
  '39584': '103.25',
  '39585': '103.25',
  '39586': '103.25',
  '39587': '103.26',
  '39588': '103.26',
  '39589': '103.26',
  '39590': '103.26',
  '39591': '103.26',
  '39592': '103.26',
  '39593': '103.27',
  '39594': '103.27',
  '39595': '103.27',
  '39596': '103.27',
  '39597': '103.27',
  '39598': '103.28',
  '39599': '103.28',
  '39600': '103.28',
  '39601': '103.28',
  '39602': '103.28',
  '39603': '103.29',
  '39604': '103.29',
  '39605': '103.29',
  '39606': '103.29',
  '39607': '103.29',
  '39608': '103.29',
  '39609': '103.30',
  '39610': '103.30',
  '39611': '103.30',
  '39612': '103.30',
  '39613': '103.30',
  '39614': '103.31',
  '39615': '103.31',
  '39616': '103.31',
  '39617': '103.31',
  '39618': '103.31',
  '39619': '103.31',
  '39620': '103.32',
  '39621': '103.32',
  '39622': '103.32',
  '39623': '103.32',
  '39624': '103.32',
  '39625': '103.32',
  '39626': '103.33',
  '39627': '103.33',
  '39628': '103.33',
  '39629': '103.33',
  '39630': '103.33',
  '39631': '103.33',
  '39632': '103.34',
  '39633': '103.34',
  '39634': '103.34',
  '39635': '103.34',
  '39636': '103.34',
  '39637': '103.35',
  '39638': '103.35',
  '39639': '103.35',
  '39640': '103.35',
  '39641': '103.35',
  '39642': '103.36',
  '39643': '103.36',
  '39644': '103.36',
  '39645': '103.36',
  '39646': '103.36',
  '39647': '103.36',
  '39648': '103.37',
  '39649': '103.37',
  '39650': '103.37',
  '39651': '103.37',
  '39652': '103.37',
  '39653': '103.38',
  '39654': '103.38',
  '39655': '103.38',
  '39656': '103.38',
  '39657': '103.38',
  '39658': '103.38',
  '39659': '103.39',
  '39660': '103.39',
  '39661': '103.39',
  '39662': '103.39',
  '39663': '103.39',
  '39664': '103.39',
  '39665': '103.40',
  '39666': '103.40',
  '39667': '103.40',
  '39668': '103.40',
  '39669': '103.40',
  '39670': '103.41',
  '39671': '103.41',
  '39672': '103.41',
  '39673': '103.41',
  '39674': '103.41',
  '39675': '103.42',
  '39676': '103.42',
  '39677': '103.42',
  '39678': '103.42',
  '39679': '103.42',
  '39680': '103.42',
  '39681': '103.43',
  '39682': '103.43',
  '39683': '103.43',
  '39684': '103.43',
  '39685': '103.43',
  '39686': '103.43',
  '39687': '103.44',
  '39688': '103.44',
  '39689': '103.44',
  '39690': '103.44',
  '39691': '103.44',
  '39692': '103.44',
  '39693': '103.45',
  '39694': '103.45',
  '39695': '103.45',
  '39696': '103.45',
  '39697': '103.45',
  '39698': '103.46',
  '39699': '103.46',
  '39700': '103.46',
  '39701': '103.46',
  '39702': '103.46',
  '39703': '103.46',
  '39704': '103.47',
  '39705': '103.47',
  '39706': '103.47',
  '39707': '103.47',
  '39708': '103.47',
  '39709': '103.48',
  '39710': '103.48',
  '39711': '103.48',
  '39712': '103.48',
  '39713': '103.48',
  '39714': '103.49',
  '39715': '103.49',
  '39716': '103.49',
  '39717': '103.49',
  '39718': '103.49',
  '39719': '103.49',
  '39720': '103.50',
  '39721': '103.50',
  '39722': '103.50',
  '39723': '103.50',
  '39724': '103.50',
  '39725': '103.50',
  '39726': '103.51',
  '39727': '103.51',
  '39728': '103.51',
  '39729': '103.51',
  '39730': '103.51',
  '39731': '103.51',
  '39732': '103.52',
  '39733': '103.52',
  '39734': '103.52',
  '39735': '103.52',
  '39736': '103.52',
  '39737': '103.53',
  '39738': '103.53',
  '39739': '103.53',
  '39740': '103.53',
  '39741': '103.53',
  '39742': '103.54',
  '39743': '103.54',
  '39744': '103.54',
  '39745': '103.54',
  '39746': '103.54',
  '39747': '103.54',
  '39748': '103.55',
  '39749': '103.55',
  '39750': '103.55',
  '39751': '103.55',
  '39752': '103.55',
  '39753': '103.56',
  '39754': '103.56',
  '39755': '103.56',
  '39756': '103.56',
  '39757': '103.56',
  '39758': '103.56',
  '39759': '103.57',
  '39760': '103.57',
  '39761': '103.57',
  '39762': '103.57',
  '39763': '103.57',
  '39764': '103.57',
  '39765': '103.58',
  '39766': '103.58',
  '39767': '103.58',
  '39768': '103.58',
  '39769': '103.58',
  '39770': '103.59',
  '39771': '103.59',
  '39772': '103.59',
  '39773': '103.59',
  '39774': '103.59',
  '39775': '103.60',
  '39776': '103.60',
  '39777': '103.60',
  '39778': '103.60',
  '39779': '103.60',
  '39780': '103.60',
  '39781': '103.61',
  '39782': '103.61',
  '39783': '103.61',
  '39784': '103.61',
  '39785': '103.61',
  '39786': '103.61',
  '39787': '103.62',
  '39788': '103.62',
  '39789': '103.62',
  '39790': '103.62',
  '39791': '103.62',
  '39792': '103.62',
  '39793': '103.63',
  '39794': '103.63',
  '39795': '103.63',
  '39796': '103.63',
  '39797': '103.63',
  '39798': '103.64',
  '39799': '103.64',
  '39800': '103.64',
  '39801': '103.64',
  '39802': '103.64',
  '39803': '103.64',
  '39804': '103.65',
  '39805': '103.65',
  '39806': '103.65',
  '39807': '103.65',
  '39808': '103.65',
  '39809': '103.66',
  '39810': '103.66',
  '39811': '103.66',
  '39812': '103.66',
  '39813': '103.66',
  '39814': '103.67',
  '39815': '103.67',
  '39816': '103.67',
  '39817': '103.67',
  '39818': '103.67',
  '39819': '103.67',
  '39820': '103.68',
  '39821': '103.68',
  '39822': '103.68',
  '39823': '103.68',
  '39824': '103.68',
  '39825': '103.68',
  '39826': '103.69',
  '39827': '103.69',
  '39828': '103.69',
  '39829': '103.69',
  '39830': '103.69',
  '39831': '103.69',
  '39832': '103.70',
  '39833': '103.70',
  '39834': '103.70',
  '39835': '103.70',
  '39836': '103.70',
  '39837': '103.71',
  '39838': '103.71',
  '39839': '103.71',
  '39840': '103.71',
  '39841': '103.71',
  '39842': '103.71',
  '39843': '103.72',
  '39844': '103.72',
  '39845': '103.72',
  '39846': '103.72',
  '39847': '103.72',
  '39848': '103.73',
  '39849': '103.73',
  '39850': '103.73',
  '39851': '103.73',
  '39852': '103.73',
  '39853': '103.74',
  '39854': '103.74',
  '39855': '103.74',
  '39856': '103.74',
  '39857': '103.74',
  '39858': '103.74',
  '39859': '103.75',
  '39860': '103.75',
  '39861': '103.75',
  '39862': '103.75',
  '39863': '103.75',
  '39864': '103.75',
  '39865': '103.76',
  '39866': '103.76',
  '39867': '103.76',
  '39868': '103.76',
  '39869': '103.76',
  '39870': '103.77',
  '39871': '103.77',
  '39872': '103.77',
  '39873': '103.77',
  '39874': '103.77',
  '39875': '103.78',
  '39876': '103.78',
  '39877': '103.78',
  '39878': '103.78',
  '39879': '103.78',
  '39880': '103.78',
  '39881': '103.79',
  '39882': '103.79',
  '39883': '103.79',
  '39884': '103.79',
  '39885': '103.79',
  '39886': '103.79',
  '39887': '103.80',
  '39888': '103.80',
  '39889': '103.80',
  '39890': '103.80',
  '39891': '103.80',
  '39892': '103.81',
  '39893': '103.81',
  '39894': '103.81',
  '39895': '103.81',
  '39896': '103.81',
  '39897': '103.81',
  '39898': '103.82',
  '39899': '103.82',
  '39900': '103.82',
  '39901': '103.82',
  '39902': '103.82',
  '39903': '103.82',
  '39904': '103.83',
  '39905': '103.83',
  '39906': '103.83',
  '39907': '103.83',
  '39908': '103.83',
  '39909': '103.84',
  '39910': '103.84',
  '39911': '103.84',
  '39912': '103.84',
  '39913': '103.84',
  '39914': '103.85',
  '39915': '103.85',
  '39916': '103.85',
  '39917': '103.85',
  '39918': '103.85',
  '39919': '103.85',
  '39920': '103.86',
  '39921': '103.86',
  '39922': '103.86',
  '39923': '103.86',
  '39924': '103.86',
  '39925': '103.86',
  '39926': '103.87',
  '39927': '103.87',
  '39928': '103.87',
  '39929': '103.87',
  '39930': '103.87',
  '39931': '103.88',
  '39932': '103.88',
  '39933': '103.88',
  '39934': '103.88',
  '39935': '103.88',
  '39936': '103.88',
  '39937': '103.89',
  '39938': '103.89',
  '39939': '103.89',
  '39940': '103.89',
  '39941': '103.89',
  '39942': '103.89',
  '39943': '103.90',
  '39944': '103.90',
  '39945': '103.90',
  '39946': '103.90',
  '39947': '103.90',
  '39948': '103.91',
  '39949': '103.91',
  '39950': '103.91',
  '39951': '103.91',
  '39952': '103.91',
  '39953': '103.92',
  '39954': '103.92',
  '39955': '103.92',
  '39956': '103.92',
  '39957': '103.92',
  '39958': '103.92',
  '39959': '103.93',
  '39960': '103.93',
  '39961': '103.93',
  '39962': '103.93',
  '39963': '103.93',
  '39964': '103.93',
  '39965': '103.94',
  '39966': '103.94',
  '39967': '103.94',
  '39968': '103.94',
  '39969': '103.94',
  '39970': '103.95',
  '39971': '103.95',
  '39972': '103.95',
  '39973': '103.95',
  '39974': '103.95',
  '39975': '103.96',
  '39976': '103.96',
  '39977': '103.96',
  '39978': '103.96',
  '39979': '103.96',
  '39980': '103.96',
  '39981': '103.96',
  '39982': '103.97',
  '39983': '103.97',
  '39984': '103.97',
  '39985': '103.97',
  '39986': '103.97',
  '39987': '103.98',
  '39988': '103.98',
  '39989': '103.98',
  '39990': '103.98',
  '39991': '103.98',
  '39992': '103.99',
  '39993': '103.99',
  '39994': '103.99',
  '39995': '103.99',
  '39996': '103.99',
  '39997': '103.99',
  '39998': '104.00',
  '39999': '104.00',
  '40000': '104.00',
  '40001': '104.00',
  '40002': '104.00',
  '40003': '104.00',
  '40004': '104.01',
  '40005': '104.01',
  '40006': '104.01',
  '40007': '104.01',
  '40008': '104.01',
  '40009': '104.02',
  '40010': '104.02',
  '40011': '104.02',
  '40012': '104.02',
  '40013': '104.02',
  '40014': '104.03',
  '40015': '104.03',
  '40016': '104.03',
  '40017': '104.03',
  '40018': '104.03',
  '40019': '104.03',
  '40020': '104.04',
  '40021': '104.04',
  '40022': '104.04',
  '40023': '104.04',
  '40024': '104.04',
  '40025': '104.04',
  '40026': '104.05',
  '40027': '104.05',
  '40028': '104.05',
  '40029': '104.05',
  '40030': '104.05',
  '40031': '104.06',
  '40032': '104.06',
  '40033': '104.06',
  '40034': '104.06',
  '40035': '104.06',
  '40036': '104.06',
  '40037': '104.07',
  '40038': '104.07',
  '40039': '104.07',
  '40040': '104.07',
  '40041': '104.07',
  '40042': '104.07',
  '40043': '104.08',
  '40044': '104.08',
  '40045': '104.08',
  '40046': '104.08',
  '40047': '104.08',
  '40048': '104.09',
  '40049': '104.09',
  '40050': '104.09',
  '40051': '104.09',
  '40052': '104.09',
  '40053': '104.10',
  '40054': '104.10',
  '40055': '104.10',
  '40056': '104.10',
  '40057': '104.10',
  '40058': '104.10',
  '40059': '104.11',
  '40060': '104.11',
  '40061': '104.11',
  '40062': '104.11',
  '40063': '104.11',
  '40064': '104.11',
  '40065': '104.12',
  '40066': '104.12',
  '40067': '104.12',
  '40068': '104.12',
  '40069': '104.12',
  '40070': '104.13',
  '40071': '104.13',
  '40072': '104.13',
  '40073': '104.13',
  '40074': '104.13',
  '40075': '104.14',
  '40076': '104.14',
  '40077': '104.14',
  '40078': '104.14',
  '40079': '104.14',
  '40080': '104.14',
  '40081': '104.14',
  '40082': '104.15',
  '40083': '104.15',
  '40084': '104.15',
  '40085': '104.15',
  '40086': '104.15',
  '40087': '104.16',
  '40088': '104.16',
  '40089': '104.16',
  '40090': '104.16',
  '40091': '104.16',
  '40092': '104.17',
  '40093': '104.17',
  '40094': '104.17',
  '40095': '104.17',
  '40096': '104.17',
  '40097': '104.17',
  '40098': '104.18',
  '40099': '104.18',
  '40100': '104.18',
  '40101': '104.18',
  '40102': '104.18',
  '40103': '104.18',
  '40104': '104.19',
  '40105': '104.19',
  '40106': '104.19',
  '40107': '104.19',
  '40108': '104.19',
  '40109': '104.20',
  '40110': '104.20',
  '40111': '104.20',
  '40112': '104.20',
  '40113': '104.20',
  '40114': '104.21',
  '40115': '104.21',
  '40116': '104.21',
  '40117': '104.21',
  '40118': '104.21',
  '40119': '104.21',
  '40120': '104.22',
  '40121': '104.22',
  '40122': '104.22',
  '40123': '104.22',
  '40124': '104.22',
  '40125': '104.22',
  '40126': '104.23',
  '40127': '104.23',
  '40128': '104.23',
  '40129': '104.23',
  '40130': '104.23',
  '40131': '104.24',
  '40132': '104.24',
  '40133': '104.24',
  '40134': '104.24',
  '40135': '104.24',
  '40136': '104.24',
  '40137': '104.25',
  '40138': '104.25',
  '40139': '104.25',
  '40140': '104.25',
  '40141': '104.25',
  '40142': '104.25',
  '40143': '104.26',
  '40144': '104.26',
  '40145': '104.26',
  '40146': '104.26',
  '40147': '104.26',
  '40148': '104.27',
  '40149': '104.27',
  '40150': '104.27',
  '40151': '104.27',
  '40152': '104.27',
  '40153': '104.28',
  '40154': '104.28',
  '40155': '104.28',
  '40156': '104.28',
  '40157': '104.28',
  '40158': '104.28',
  '40159': '104.29',
  '40160': '104.29',
  '40161': '104.29',
  '40162': '104.29',
  '40163': '104.29',
  '40164': '104.29',
  '40165': '104.30',
  '40166': '104.30',
  '40167': '104.30',
  '40168': '104.30',
  '40169': '104.30',
  '40170': '104.31',
  '40171': '104.31',
  '40172': '104.31',
  '40173': '104.31',
  '40174': '104.31',
  '40175': '104.32',
  '40176': '104.32',
  '40177': '104.32',
  '40178': '104.32',
  '40179': '104.32',
  '40180': '104.32',
  '40181': '104.32',
  '40182': '104.33',
  '40183': '104.33',
  '40184': '104.33',
  '40185': '104.33',
  '40186': '104.33',
  '40187': '104.34',
  '40188': '104.34',
  '40189': '104.34',
  '40190': '104.34',
  '40191': '104.34',
  '40192': '104.35',
  '40193': '104.35',
  '40194': '104.35',
  '40195': '104.35',
  '40196': '104.35',
  '40197': '104.35',
  '40198': '104.36',
  '40199': '104.36',
  '40200': '104.36',
  '40201': '104.36',
  '40202': '104.36',
  '40203': '104.36',
  '40204': '104.37',
  '40205': '104.37',
  '40206': '104.37',
  '40207': '104.37',
  '40208': '104.37',
  '40209': '104.38',
  '40210': '104.38',
  '40211': '104.38',
  '40212': '104.38',
  '40213': '104.38',
  '40214': '104.39',
  '40215': '104.39',
  '40216': '104.39',
  '40217': '104.39',
  '40218': '104.39',
  '40219': '104.39',
  '40220': '104.40',
  '40221': '104.40',
  '40222': '104.40',
  '40223': '104.40',
  '40224': '104.40',
  '40225': '104.40',
  '40226': '104.41',
  '40227': '104.41',
  '40228': '104.41',
  '40229': '104.41',
  '40230': '104.41',
  '40231': '104.42',
  '40232': '104.42',
  '40233': '104.42',
  '40234': '104.42',
  '40235': '104.42',
  '40236': '104.42',
  '40237': '104.43',
  '40238': '104.43',
  '40239': '104.43',
  '40240': '104.43',
  '40241': '104.43',
  '40242': '104.43',
  '40243': '104.44',
  '40244': '104.44',
  '40245': '104.44',
  '40246': '104.44',
  '40247': '104.44',
  '40248': '104.45',
  '40249': '104.45',
  '40250': '104.45',
  '40251': '104.45',
  '40252': '104.45',
  '40253': '104.46',
  '40254': '104.46',
  '40255': '104.46',
  '40256': '104.46',
  '40257': '104.46',
  '40258': '104.46',
  '40259': '104.47',
  '40260': '104.47',
  '40261': '104.47',
  '40262': '104.47',
  '40263': '104.47',
  '40264': '104.47',
  '40265': '104.48',
  '40266': '104.48',
  '40267': '104.48',
  '40268': '104.48',
  '40269': '104.48',
  '40270': '104.49',
  '40271': '104.49',
  '40272': '104.49',
  '40273': '104.49',
  '40274': '104.49',
  '40275': '104.50',
  '40276': '104.50',
  '40277': '104.50',
  '40278': '104.50',
  '40279': '104.50',
  '40280': '104.50',
  '40281': '104.50',
  '40282': '104.51',
  '40283': '104.51',
  '40284': '104.51',
  '40285': '104.51',
  '40286': '104.51',
  '40287': '104.52',
  '40288': '104.52',
  '40289': '104.52',
  '40290': '104.52',
  '40291': '104.52',
  '40292': '104.53',
  '40293': '104.53',
  '40294': '104.53',
  '40295': '104.53',
  '40296': '104.53',
  '40297': '104.53',
  '40298': '104.54',
  '40299': '104.54',
  '40300': '104.54',
  '40301': '104.54',
  '40302': '104.54',
  '40303': '104.54',
  '40304': '104.55',
  '40305': '104.55',
  '40306': '104.55',
  '40307': '104.55',
  '40308': '104.55',
  '40309': '104.56',
  '40310': '104.56',
  '40311': '104.56',
  '40312': '104.56',
  '40313': '104.56',
  '40314': '104.57',
  '40315': '104.57',
  '40316': '104.57',
  '40317': '104.57',
  '40318': '104.57',
  '40319': '104.57',
  '40320': '104.58',
  '40321': '104.58',
  '40322': '104.58',
  '40323': '104.58',
  '40324': '104.58',
  '40325': '104.58',
  '40326': '104.59',
  '40327': '104.59',
  '40328': '104.59',
  '40329': '104.59',
  '40330': '104.59',
  '40331': '104.60',
  '40332': '104.60',
  '40333': '104.60',
  '40334': '104.60',
  '40335': '104.60',
  '40336': '104.60',
  '40337': '104.61',
  '40338': '104.61',
  '40339': '104.61',
  '40340': '104.61',
  '40341': '104.61',
  '40342': '104.61',
  '40343': '104.62',
  '40344': '104.62',
  '40345': '104.62',
  '40346': '104.62',
  '40347': '104.62',
  '40348': '104.63',
  '40349': '104.63',
  '40350': '104.63',
  '40351': '104.63',
  '40352': '104.63',
  '40353': '104.64',
  '40354': '104.64',
  '40355': '104.64',
  '40356': '104.64',
  '40357': '104.64',
  '40358': '104.64',
  '40359': '104.65',
  '40360': '104.65',
  '40361': '104.65',
  '40362': '104.65',
  '40363': '104.65',
  '40364': '104.65',
  '40365': '104.66',
  '40366': '104.66',
  '40367': '104.66',
  '40368': '104.66',
  '40369': '104.66',
  '40370': '104.67',
  '40371': '104.67',
  '40372': '104.67',
  '40373': '104.67',
  '40374': '104.67',
  '40375': '104.68',
  '40376': '104.68',
  '40377': '104.68',
  '40378': '104.68',
  '40379': '104.68',
  '40380': '104.68',
  '40381': '104.68',
  '40382': '104.69',
  '40383': '104.69',
  '40384': '104.69',
  '40385': '104.69',
  '40386': '104.69',
  '40387': '104.70',
  '40388': '104.70',
  '40389': '104.70',
  '40390': '104.70',
  '40391': '104.70',
  '40392': '104.71',
  '40393': '104.71',
  '40394': '104.71',
  '40395': '104.71',
  '40396': '104.71',
  '40397': '104.71',
  '40398': '104.72',
  '40399': '104.72',
  '40400': '104.72',
  '40401': '104.72',
  '40402': '104.72',
  '40403': '104.72',
  '40404': '104.73',
  '40405': '104.73',
  '40406': '104.73',
  '40407': '104.73',
  '40408': '104.73',
  '40409': '104.74',
  '40410': '104.74',
  '40411': '104.74',
  '40412': '104.74',
  '40413': '104.74',
  '40414': '104.75',
  '40415': '104.75',
  '40416': '104.75',
  '40417': '104.75',
  '40418': '104.75',
  '40419': '104.75',
  '40420': '104.76',
  '40421': '104.76',
  '40422': '104.76',
  '40423': '104.76',
  '40424': '104.76',
  '40425': '104.76',
  '40426': '104.77',
  '40427': '104.77',
  '40428': '104.77',
  '40429': '104.77',
  '40430': '104.77',
  '40431': '104.78',
  '40432': '104.78',
  '40433': '104.78',
  '40434': '104.78',
  '40435': '104.78',
  '40436': '104.78',
  '40437': '104.79',
  '40438': '104.79',
  '40439': '104.79',
  '40440': '104.79',
  '40441': '104.79',
  '40442': '104.79',
  '40443': '104.80',
  '40444': '104.80',
  '40445': '104.80',
  '40446': '104.80',
  '40447': '104.80',
  '40448': '104.81',
  '40449': '104.81',
  '40450': '104.81',
  '40451': '104.81',
  '40452': '104.81',
  '40453': '104.82',
  '40454': '104.82',
  '40455': '104.82',
  '40456': '104.82',
  '40457': '104.82',
  '40458': '104.82',
  '40459': '104.83',
  '40460': '104.83',
  '40461': '104.83',
  '40462': '104.83',
  '40463': '104.83',
  '40464': '104.83',
  '40465': '104.84',
  '40466': '104.84',
  '40467': '104.84',
  '40468': '104.84',
  '40469': '104.84',
  '40470': '104.85',
  '40471': '104.85',
  '40472': '104.85',
  '40473': '104.85',
  '40474': '104.85',
  '40475': '104.86',
  '40476': '104.86',
  '40477': '104.86',
  '40478': '104.86',
  '40479': '104.86',
  '40480': '104.86',
  '40481': '104.86',
  '40482': '104.87',
  '40483': '104.87',
  '40484': '104.87',
  '40485': '104.87',
  '40486': '104.87',
  '40487': '104.88',
  '40488': '104.88',
  '40489': '104.88',
  '40490': '104.88',
  '40491': '104.88',
  '40492': '104.89',
  '40493': '104.89',
  '40494': '104.89',
  '40495': '104.89',
  '40496': '104.89',
  '40497': '104.89',
  '40498': '104.90',
  '40499': '104.90',
  '40500': '104.90',
  '40501': '104.90',
  '40502': '104.90',
  '40503': '104.90',
  '40504': '104.91',
  '40505': '104.91',
  '40506': '104.91',
  '40507': '104.91',
  '40508': '104.91',
  '40509': '104.92',
  '40510': '104.92',
  '40511': '104.92',
  '40512': '104.92',
  '40513': '104.92',
  '40514': '104.93',
  '40515': '104.93',
  '40516': '104.93',
  '40517': '104.93',
  '40518': '104.93',
  '40519': '104.93',
  '40520': '104.94',
  '40521': '104.94',
  '40522': '104.94',
  '40523': '104.94',
  '40524': '104.94',
  '40525': '104.94',
  '40526': '104.95',
  '40527': '104.95',
  '40528': '104.95',
  '40529': '104.95',
  '40530': '104.95',
  '40531': '104.96',
  '40532': '104.96',
  '40533': '104.96',
  '40534': '104.96',
  '40535': '104.96',
  '40536': '104.96',
  '40537': '104.97',
  '40538': '104.97',
  '40539': '104.97',
  '40540': '104.97',
  '40541': '104.97',
  '40542': '104.97',
  '40543': '104.98',
  '40544': '104.98',
  '40545': '104.98',
  '40546': '104.98',
  '40547': '104.98',
  '40548': '104.99',
  '40549': '104.99',
  '40550': '104.99',
  '40551': '104.99',
  '40552': '104.99',
  '40553': '105.00',
  '40554': '105.00',
  '40555': '105.00',
  '40556': '105.00',
  '40557': '105.00',
  '40558': '105.00',
  '40559': '105.01',
  '40560': '105.01',
  '40561': '105.01',
  '40562': '105.01',
  '40563': '105.01',
  '40564': '105.01',
  '40565': '105.02',
  '40566': '105.02',
  '40567': '105.02',
  '40568': '105.02',
  '40569': '105.02',
  '40570': '105.03',
  '40571': '105.03',
  '40572': '105.03',
  '40573': '105.03',
  '40574': '105.03',
  '40575': '105.04',
  '40576': '105.04',
  '40577': '105.04',
  '40578': '105.04',
  '40579': '105.04',
  '40580': '105.04',
  '40581': '105.04',
  '40582': '105.05',
  '40583': '105.05',
  '40584': '105.05',
  '40585': '105.05',
  '40586': '105.05',
  '40587': '105.06',
  '40588': '105.06',
  '40589': '105.06',
  '40590': '105.06',
  '40591': '105.06',
  '40592': '105.07',
  '40593': '105.07',
  '40594': '105.07',
  '40595': '105.07',
  '40596': '105.07',
  '40597': '105.07',
  '40598': '105.08',
  '40599': '105.08',
  '40600': '105.08',
  '40601': '105.08',
  '40602': '105.08',
  '40603': '105.08',
  '40604': '105.09',
  '40605': '105.09',
  '40606': '105.09',
  '40607': '105.09',
  '40608': '105.09',
  '40609': '105.10',
  '40610': '105.10',
  '40611': '105.10',
  '40612': '105.10',
  '40613': '105.10',
  '40614': '105.11',
  '40615': '105.11',
  '40616': '105.11',
  '40617': '105.11',
  '40618': '105.11',
  '40619': '105.11',
  '40620': '105.12',
  '40621': '105.12',
  '40622': '105.12',
  '40623': '105.12',
  '40624': '105.12',
  '40625': '105.12',
  '40626': '105.13',
  '40627': '105.13',
  '40628': '105.13',
  '40629': '105.13',
  '40630': '105.13',
  '40631': '105.14',
  '40632': '105.14',
  '40633': '105.14',
  '40634': '105.14',
  '40635': '105.14',
  '40636': '105.14',
  '40637': '105.15',
  '40638': '105.15',
  '40639': '105.15',
  '40640': '105.15',
  '40641': '105.15',
  '40642': '105.15',
  '40643': '105.16',
  '40644': '105.16',
  '40645': '105.16',
  '40646': '105.16',
  '40647': '105.16',
  '40648': '105.17',
  '40649': '105.17',
  '40650': '105.17',
  '40651': '105.17',
  '40652': '105.17',
  '40653': '105.18',
  '40654': '105.18',
  '40655': '105.18',
  '40656': '105.18',
  '40657': '105.18',
  '40658': '105.18',
  '40659': '105.19',
  '40660': '105.19',
  '40661': '105.19',
  '40662': '105.19',
  '40663': '105.19',
  '40664': '105.19',
  '40665': '105.20',
  '40666': '105.20',
  '40667': '105.20',
  '40668': '105.20',
  '40669': '105.20',
  '40670': '105.21',
  '40671': '105.21',
  '40672': '105.21',
  '40673': '105.21',
  '40674': '105.21',
  '40675': '105.21',
  '40676': '105.22',
  '40677': '105.22',
  '40678': '105.22',
  '40679': '105.22',
  '40680': '105.22',
  '40681': '105.22',
  '40682': '105.23',
  '40683': '105.23',
  '40684': '105.23',
  '40685': '105.23',
  '40686': '105.23',
  '40687': '105.24',
  '40688': '105.24',
  '40689': '105.24',
  '40690': '105.24',
  '40691': '105.24',
  '40692': '105.25',
  '40693': '105.25',
  '40694': '105.25',
  '40695': '105.25',
  '40696': '105.25',
  '40697': '105.25',
  '40698': '105.26',
  '40699': '105.26',
  '40700': '105.26',
  '40701': '105.26',
  '40702': '105.26',
  '40703': '105.26',
  '40704': '105.27',
  '40705': '105.27',
  '40706': '105.27',
  '40707': '105.27',
  '40708': '105.27',
  '40709': '105.28',
  '40710': '105.28',
  '40711': '105.28',
  '40712': '105.28',
  '40713': '105.28',
  '40714': '105.29',
  '40715': '105.29',
  '40716': '105.29',
  '40717': '105.29',
  '40718': '105.29',
  '40719': '105.29',
  '40720': '105.30',
  '40721': '105.30',
  '40722': '105.30',
  '40723': '105.30',
  '40724': '105.30',
  '40725': '105.31',
  '40726': '105.31',
  '40727': '105.31',
  '40728': '105.31',
  '40729': '105.31',
  '40730': '105.31',
  '40731': '105.32',
  '40732': '105.32',
  '40733': '105.32',
  '40734': '105.32',
  '40735': '105.32',
  '40736': '105.32',
  '40737': '105.33',
  '40738': '105.33',
  '40739': '105.33',
  '40740': '105.33',
  '40741': '105.33',
  '40742': '105.33',
  '40743': '105.34',
  '40744': '105.34',
  '40745': '105.34',
  '40746': '105.34',
  '40747': '105.34',
  '40748': '105.35',
  '40749': '105.35',
  '40750': '105.35',
  '40751': '105.35',
  '40752': '105.35',
  '40753': '105.36',
  '40754': '105.36',
  '40755': '105.36',
  '40756': '105.36',
  '40757': '105.36',
  '40758': '105.36',
  '40759': '105.37',
  '40760': '105.37',
  '40761': '105.37',
  '40762': '105.37',
  '40763': '105.37',
  '40764': '105.38',
  '40765': '105.38',
  '40766': '105.38',
  '40767': '105.38',
  '40768': '105.38',
  '40769': '105.38',
  '40770': '105.39',
  '40771': '105.39',
  '40772': '105.39',
  '40773': '105.39',
  '40774': '105.39',
  '40775': '105.39',
  '40776': '105.40',
  '40777': '105.40',
  '40778': '105.40',
  '40779': '105.40',
  '40780': '105.40',
  '40781': '105.40',
  '40782': '105.41',
  '40783': '105.41',
  '40784': '105.41',
  '40785': '105.41',
  '40786': '105.41',
  '40787': '105.42',
  '40788': '105.42',
  '40789': '105.42',
  '40790': '105.42',
  '40791': '105.42',
  '40792': '105.43',
  '40793': '105.43',
  '40794': '105.43',
  '40795': '105.43',
  '40796': '105.43',
  '40797': '105.43',
  '40798': '105.44',
  '40799': '105.44',
  '40800': '105.44',
  '40801': '105.44',
  '40802': '105.44',
  '40803': '105.44',
  '40804': '105.45',
  '40805': '105.45',
  '40806': '105.45',
  '40807': '105.45',
  '40808': '105.45',
  '40809': '105.46',
  '40810': '105.46',
  '40811': '105.46',
  '40812': '105.46',
  '40813': '105.46',
  '40814': '105.46',
  '40815': '105.47',
  '40816': '105.47',
  '40817': '105.47',
  '40818': '105.47',
  '40819': '105.47',
  '40820': '105.48',
  '40821': '105.48',
  '40822': '105.48',
  '40823': '105.48',
  '40824': '105.48',
  '40825': '105.49',
  '40826': '105.49',
  '40827': '105.49',
  '40828': '105.49',
  '40829': '105.49',
  '40830': '105.49',
  '40831': '105.50',
  '40832': '105.50',
  '40833': '105.50',
  '40834': '105.50',
  '40835': '105.50',
  '40836': '105.50',
  '40837': '105.51',
  '40838': '105.51',
  '40839': '105.51',
  '40840': '105.51',
  '40841': '105.51',
  '40842': '105.51',
  '40843': '105.52',
  '40844': '105.52',
  '40845': '105.52',
  '40846': '105.52',
  '40847': '105.52',
  '40848': '105.53',
  '40849': '105.53',
  '40850': '105.53',
  '40851': '105.53',
  '40852': '105.53',
  '40853': '105.54',
  '40854': '105.54',
  '40855': '105.54',
  '40856': '105.54',
  '40857': '105.54',
  '40858': '105.54',
  '40859': '105.55',
  '40860': '105.55',
  '40861': '105.55',
  '40862': '105.55',
  '40863': '105.55',
  '40864': '105.56',
  '40865': '105.56',
  '40866': '105.56',
  '40867': '105.56',
  '40868': '105.56',
  '40869': '105.56',
  '40870': '105.57',
  '40871': '105.57',
  '40872': '105.57',
  '40873': '105.57',
  '40874': '105.57',
  '40875': '105.57',
  '40876': '105.58',
  '40877': '105.58',
  '40878': '105.58',
  '40879': '105.58',
  '40880': '105.58',
  '40881': '105.58',
  '40882': '105.59',
  '40883': '105.59',
  '40884': '105.59',
  '40885': '105.59',
  '40886': '105.59',
  '40887': '105.60',
  '40888': '105.60',
  '40889': '105.60',
  '40890': '105.60',
  '40891': '105.60',
  '40892': '105.61',
  '40893': '105.61',
  '40894': '105.61',
  '40895': '105.61',
  '40896': '105.61',
  '40897': '105.61',
  '40898': '105.62',
  '40899': '105.62',
  '40900': '105.62',
  '40901': '105.62',
  '40902': '105.62',
  '40903': '105.62',
  '40904': '105.63',
  '40905': '105.63',
  '40906': '105.63',
  '40907': '105.63',
  '40908': '105.63',
  '40909': '105.64',
  '40910': '105.64',
  '40911': '105.64',
  '40912': '105.64',
  '40913': '105.64',
  '40914': '105.64',
  '40915': '105.65',
  '40916': '105.65',
  '40917': '105.65',
  '40918': '105.65',
  '40919': '105.65',
  '40920': '105.66',
  '40921': '105.66',
  '40922': '105.66',
  '40923': '105.66',
  '40924': '105.66',
  '40925': '105.67',
  '40926': '105.67',
  '40927': '105.67',
  '40928': '105.67',
  '40929': '105.67',
  '40930': '105.67',
  '40931': '105.68',
  '40932': '105.68',
  '40933': '105.68',
  '40934': '105.68',
  '40935': '105.68',
  '40936': '105.68',
  '40937': '105.69',
  '40938': '105.69',
  '40939': '105.69',
  '40940': '105.69',
  '40941': '105.69',
  '40942': '105.69',
  '40943': '105.70',
  '40944': '105.70',
  '40945': '105.70',
  '40946': '105.70',
  '40947': '105.70',
  '40948': '105.71',
  '40949': '105.71',
  '40950': '105.71',
  '40951': '105.71',
  '40952': '105.71',
  '40953': '105.71',
  '40954': '105.72',
  '40955': '105.72',
  '40956': '105.72',
  '40957': '105.72',
  '40958': '105.72',
  '40959': '105.73',
  '40960': '105.73',
  '40961': '105.73',
  '40962': '105.73',
  '40963': '105.73',
  '40964': '105.74',
  '40965': '105.74',
  '40966': '105.74',
  '40967': '105.74',
  '40968': '105.74',
  '40969': '105.74',
  '40970': '105.75',
  '40971': '105.75',
  '40972': '105.75',
  '40973': '105.75',
  '40974': '105.75',
  '40975': '105.75',
  '40976': '105.76',
  '40977': '105.76',
  '40978': '105.76',
  '40979': '105.76',
  '40980': '105.76',
  '40981': '105.76',
  '40982': '105.77',
  '40983': '105.77',
  '40984': '105.77',
  '40985': '105.77',
  '40986': '105.77',
  '40987': '105.78',
  '40988': '105.78',
  '40989': '105.78',
  '40990': '105.78',
  '40991': '105.78',
  '40992': '105.79',
  '40993': '105.79',
  '40994': '105.79',
  '40995': '105.79',
  '40996': '105.79',
  '40997': '105.79',
  '40998': '105.80',
  '40999': '105.80',
  '41000': '105.80',
  '41001': '105.80',
  '41002': '105.80',
  '41003': '105.81',
  '41004': '105.81',
  '41005': '105.81',
  '41006': '105.81',
  '41007': '105.81',
  '41008': '105.81',
  '41009': '105.82',
  '41010': '105.82',
  '41011': '105.82',
  '41012': '105.82',
  '41013': '105.82',
  '41014': '105.82',
  '41015': '105.83',
  '41016': '105.83',
  '41017': '105.83',
  '41018': '105.83',
  '41019': '105.83',
  '41020': '105.84',
  '41021': '105.84',
  '41022': '105.84',
  '41023': '105.84',
  '41024': '105.84',
  '41025': '105.85',
  '41026': '105.85',
  '41027': '105.85',
  '41028': '105.85',
  '41029': '105.85',
  '41030': '105.85',
  '41031': '105.86',
  '41032': '105.86',
  '41033': '105.86',
  '41034': '105.86',
  '41035': '105.86',
  '41036': '105.86',
  '41037': '105.87',
  '41038': '105.87',
  '41039': '105.87',
  '41040': '105.87',
  '41041': '105.87',
  '41042': '105.88',
  '41043': '105.88',
  '41044': '105.88',
  '41045': '105.88',
  '41046': '105.88',
  '41047': '105.88',
  '41048': '105.89',
  '41049': '105.89',
  '41050': '105.89',
  '41051': '105.89',
  '41052': '105.89',
  '41053': '105.89',
  '41054': '105.90',
  '41055': '105.90',
  '41056': '105.90',
  '41057': '105.90',
  '41058': '105.90',
  '41059': '105.91',
  '41060': '105.91',
  '41061': '105.91',
  '41062': '105.91',
  '41063': '105.91',
  '41064': '105.92',
  '41065': '105.92',
  '41066': '105.92',
  '41067': '105.92',
  '41068': '105.92',
  '41069': '105.92',
  '41070': '105.93',
  '41071': '105.93',
  '41072': '105.93',
  '41073': '105.93',
  '41074': '105.93',
  '41075': '105.93',
  '41076': '105.94',
  '41077': '105.94',
  '41078': '105.94',
  '41079': '105.94',
  '41080': '105.94',
  '41081': '105.94',
  '41082': '105.95',
  '41083': '105.95',
  '41084': '105.95',
  '41085': '105.95',
  '41086': '105.95',
  '41087': '105.96',
  '41088': '105.96',
  '41089': '105.96',
  '41090': '105.96',
  '41091': '105.96',
  '41092': '105.96',
  '41093': '105.97',
  '41094': '105.97',
  '41095': '105.97',
  '41096': '105.97',
  '41097': '105.97',
  '41098': '105.98',
  '41099': '105.98',
  '41100': '105.98',
  '41101': '105.98',
  '41102': '105.98',
  '41103': '105.99',
  '41104': '105.99',
  '41105': '105.99',
  '41106': '105.99',
  '41107': '105.99',
  '41108': '105.99',
  '41109': '106.00',
  '41110': '106.00',
  '41111': '106.00',
  '41112': '106.00',
  '41113': '106.00',
  '41114': '106.00',
  '41115': '106.01',
  '41116': '106.01',
  '41117': '106.01',
  '41118': '106.01',
  '41119': '106.01',
  '41120': '106.02',
  '41121': '106.02',
  '41122': '106.02',
  '41123': '106.02',
  '41124': '106.02',
  '41125': '106.03',
  '41126': '106.03',
  '41127': '106.03',
  '41128': '106.03',
  '41129': '106.03',
  '41130': '106.03',
  '41131': '106.04',
  '41132': '106.04',
  '41133': '106.04',
  '41134': '106.04',
  '41135': '106.04',
  '41136': '106.04',
  '41137': '106.05',
  '41138': '106.05',
  '41139': '106.05',
  '41140': '106.05',
  '41141': '106.05',
  '41142': '106.06',
  '41143': '106.06',
  '41144': '106.06',
  '41145': '106.06',
  '41146': '106.06',
  '41147': '106.06',
  '41148': '106.07',
  '41149': '106.07',
  '41150': '106.07',
  '41151': '106.07',
  '41152': '106.07',
  '41153': '106.07',
  '41154': '106.08',
  '41155': '106.08',
  '41156': '106.08',
  '41157': '106.08',
  '41158': '106.08',
  '41159': '106.09',
  '41160': '106.09',
  '41161': '106.09',
  '41162': '106.09',
  '41163': '106.09',
  '41164': '106.10',
  '41165': '106.10',
  '41166': '106.10',
  '41167': '106.10',
  '41168': '106.10',
  '41169': '106.10',
  '41170': '106.11',
  '41171': '106.11',
  '41172': '106.11',
  '41173': '106.11',
  '41174': '106.11',
  '41175': '106.11',
  '41176': '106.12',
  '41177': '106.12',
  '41178': '106.12',
  '41179': '106.12',
  '41180': '106.12',
  '41181': '106.12',
  '41182': '106.13',
  '41183': '106.13',
  '41184': '106.13',
  '41185': '106.13',
  '41186': '106.13',
  '41187': '106.14',
  '41188': '106.14',
  '41189': '106.14',
  '41190': '106.14',
  '41191': '106.14',
  '41192': '106.14',
  '41193': '106.15',
  '41194': '106.15',
  '41195': '106.15',
  '41196': '106.15',
  '41197': '106.15',
  '41198': '106.16',
  '41199': '106.16',
  '41200': '106.16',
  '41201': '106.16',
  '41202': '106.16',
  '41203': '106.17',
  '41204': '106.17',
  '41205': '106.17',
  '41206': '106.17',
  '41207': '106.17',
  '41208': '106.17',
  '41209': '106.18',
  '41210': '106.18',
  '41211': '106.18',
  '41212': '106.18',
  '41213': '106.18',
  '41214': '106.18',
  '41215': '106.19',
  '41216': '106.19',
  '41217': '106.19',
  '41218': '106.19',
  '41219': '106.19',
  '41220': '106.20',
  '41221': '106.20',
  '41222': '106.20',
  '41223': '106.20',
  '41224': '106.20',
  '41225': '106.21',
  '41226': '106.21',
  '41227': '106.21',
  '41228': '106.21',
  '41229': '106.21',
  '41230': '106.21',
  '41231': '106.21',
  '41232': '106.22',
  '41233': '106.22',
  '41234': '106.22',
  '41235': '106.22',
  '41236': '106.22',
  '41237': '106.23',
  '41238': '106.23',
  '41239': '106.23',
  '41240': '106.23',
  '41241': '106.23',
  '41242': '106.24',
  '41243': '106.24',
  '41244': '106.24',
  '41245': '106.24',
  '41246': '106.24',
  '41247': '106.24',
  '41248': '106.25',
  '41249': '106.25',
  '41250': '106.25',
  '41251': '106.25',
  '41252': '106.25',
  '41253': '106.25',
  '41254': '106.26',
  '41255': '106.26',
  '41256': '106.26',
  '41257': '106.26',
  '41258': '106.26',
  '41259': '106.27',
  '41260': '106.27',
  '41261': '106.27',
  '41262': '106.27',
  '41263': '106.27',
  '41264': '106.28',
  '41265': '106.28',
  '41266': '106.28',
  '41267': '106.28',
  '41268': '106.28',
  '41269': '106.28',
  '41270': '106.29',
  '41271': '106.29',
  '41272': '106.29',
  '41273': '106.29',
  '41274': '106.29',
  '41275': '106.29',
  '41276': '106.30',
  '41277': '106.30',
  '41278': '106.30',
  '41279': '106.30',
  '41280': '106.30',
  '41281': '106.31',
  '41282': '106.31',
  '41283': '106.31',
  '41284': '106.31',
  '41285': '106.31',
  '41286': '106.31',
  '41287': '106.32',
  '41288': '106.32',
  '41289': '106.32',
  '41290': '106.32',
  '41291': '106.32',
  '41292': '106.32',
  '41293': '106.33',
  '41294': '106.33',
  '41295': '106.33',
  '41296': '106.33',
  '41297': '106.33',
  '41298': '106.34',
  '41299': '106.34',
  '41300': '106.34',
  '41301': '106.34',
  '41302': '106.34',
  '41303': '106.35',
  '41304': '106.35',
  '41305': '106.35',
  '41306': '106.35',
  '41307': '106.35',
  '41308': '106.35',
  '41309': '106.36',
  '41310': '106.36',
  '41311': '106.36',
  '41312': '106.36',
  '41313': '106.36',
  '41314': '106.36',
  '41315': '106.37',
  '41316': '106.37',
  '41317': '106.37',
  '41318': '106.37',
  '41319': '106.37',
  '41320': '106.38',
  '41321': '106.38',
  '41322': '106.38',
  '41323': '106.38',
  '41324': '106.38',
  '41325': '106.39',
  '41326': '106.39',
  '41327': '106.39',
  '41328': '106.39',
  '41329': '106.39',
  '41330': '106.39',
  '41331': '106.39',
  '41332': '106.40',
  '41333': '106.40',
  '41334': '106.40',
  '41335': '106.40',
  '41336': '106.40',
  '41337': '106.41',
  '41338': '106.41',
  '41339': '106.41',
  '41340': '106.41',
  '41341': '106.41',
  '41342': '106.42',
  '41343': '106.42',
  '41344': '106.42',
  '41345': '106.42',
  '41346': '106.42',
  '41347': '106.42',
  '41348': '106.43',
  '41349': '106.43',
  '41350': '106.43',
  '41351': '106.43',
  '41352': '106.43',
  '41353': '106.43',
  '41354': '106.44',
  '41355': '106.44',
  '41356': '106.44',
  '41357': '106.44',
  '41358': '106.44',
  '41359': '106.45',
  '41360': '106.45',
  '41361': '106.45',
  '41362': '106.45',
  '41363': '106.45',
  '41364': '106.46',
  '41365': '106.46',
  '41366': '106.46',
  '41367': '106.46',
  '41368': '106.46',
  '41369': '106.46',
  '41370': '106.47',
  '41371': '106.47',
  '41372': '106.47',
  '41373': '106.47',
  '41374': '106.47',
  '41375': '106.47',
  '41376': '106.48',
  '41377': '106.48',
  '41378': '106.48',
  '41379': '106.48',
  '41380': '106.48',
  '41381': '106.49',
  '41382': '106.49',
  '41383': '106.49',
  '41384': '106.49',
  '41385': '106.49',
  '41386': '106.49',
  '41387': '106.50',
  '41388': '106.50',
  '41389': '106.50',
  '41390': '106.50',
  '41391': '106.50',
  '41392': '106.50',
  '41393': '106.51',
  '41394': '106.51',
  '41395': '106.51',
  '41396': '106.51',
  '41397': '106.51',
  '41398': '106.52',
  '41399': '106.52',
  '41400': '106.52',
  '41401': '106.52',
  '41402': '106.52',
  '41403': '106.53',
  '41404': '106.53',
  '41405': '106.53',
  '41406': '106.53',
  '41407': '106.53',
  '41408': '106.53',
  '41409': '106.54',
  '41410': '106.54',
  '41411': '106.54',
  '41412': '106.54',
  '41413': '106.54',
  '41414': '106.54',
  '41415': '106.55',
  '41416': '106.55',
  '41417': '106.55',
  '41418': '106.55',
  '41419': '106.55',
  '41420': '106.56',
  '41421': '106.56',
  '41422': '106.56',
  '41423': '106.56',
  '41424': '106.56',
  '41425': '106.57',
  '41426': '106.57',
  '41427': '106.57',
  '41428': '106.57',
  '41429': '106.57',
  '41430': '106.57',
  '41431': '106.57',
  '41432': '106.58',
  '41433': '106.58',
  '41434': '106.58',
  '41435': '106.58',
  '41436': '106.58',
  '41437': '106.59',
  '41438': '106.59',
  '41439': '106.59',
  '41440': '106.59',
  '41441': '106.59',
  '41442': '106.60',
  '41443': '106.60',
  '41444': '106.60',
  '41445': '106.60',
  '41446': '106.60',
  '41447': '106.60',
  '41448': '106.61',
  '41449': '106.61',
  '41450': '106.61',
  '41451': '106.61',
  '41452': '106.61',
  '41453': '106.61',
  '41454': '106.62',
  '41455': '106.62',
  '41456': '106.62',
  '41457': '106.62',
  '41458': '106.62',
  '41459': '106.63',
  '41460': '106.63',
  '41461': '106.63',
  '41462': '106.63',
  '41463': '106.63',
  '41464': '106.64',
  '41465': '106.64',
  '41466': '106.64',
  '41467': '106.64',
  '41468': '106.64',
  '41469': '106.64',
  '41470': '106.65',
  '41471': '106.65',
  '41472': '106.65',
  '41473': '106.65',
  '41474': '106.65',
  '41475': '106.65',
  '41476': '106.66',
  '41477': '106.66',
  '41478': '106.66',
  '41479': '106.66',
  '41480': '106.66',
  '41481': '106.67',
  '41482': '106.67',
  '41483': '106.67',
  '41484': '106.67',
  '41485': '106.67',
  '41486': '106.67',
  '41487': '106.68',
  '41488': '106.68',
  '41489': '106.68',
  '41490': '106.68',
  '41491': '106.68',
  '41492': '106.68',
  '41493': '106.69',
  '41494': '106.69',
  '41495': '106.69',
  '41496': '106.69',
  '41497': '106.69',
  '41498': '106.70',
  '41499': '106.70',
  '41500': '106.70',
  '41501': '106.70',
  '41502': '106.70',
  '41503': '106.71',
  '41504': '106.71',
  '41505': '106.71',
  '41506': '106.71',
  '41507': '106.71',
  '41508': '106.71',
  '41509': '106.72',
  '41510': '106.72',
  '41511': '106.72',
  '41512': '106.72',
  '41513': '106.72',
  '41514': '106.72',
  '41515': '106.73',
  '41516': '106.73',
  '41517': '106.73',
  '41518': '106.73',
  '41519': '106.73',
  '41520': '106.74',
  '41521': '106.74',
  '41522': '106.74',
  '41523': '106.74',
  '41524': '106.74',
  '41525': '106.75',
  '41526': '106.75',
  '41527': '106.75',
  '41528': '106.75',
  '41529': '106.75',
  '41530': '106.75',
  '41531': '106.75',
  '41532': '106.76',
  '41533': '106.76',
  '41534': '106.76',
  '41535': '106.76',
  '41536': '106.76',
  '41537': '106.77',
  '41538': '106.77',
  '41539': '106.77',
  '41540': '106.77',
  '41541': '106.77',
  '41542': '106.78',
  '41543': '106.78',
  '41544': '106.78',
  '41545': '106.78',
  '41546': '106.78',
  '41547': '106.78',
  '41548': '106.79',
  '41549': '106.79',
  '41550': '106.79',
  '41551': '106.79',
  '41552': '106.79',
  '41553': '106.79',
  '41554': '106.80',
  '41555': '106.80',
  '41556': '106.80',
  '41557': '106.80',
  '41558': '106.80',
  '41559': '106.81',
  '41560': '106.81',
  '41561': '106.81',
  '41562': '106.81',
  '41563': '106.81',
  '41564': '106.82',
  '41565': '106.82',
  '41566': '106.82',
  '41567': '106.82',
  '41568': '106.82',
  '41569': '106.82',
  '41570': '106.83',
  '41571': '106.83',
  '41572': '106.83',
  '41573': '106.83',
  '41574': '106.83',
  '41575': '106.83',
  '41576': '106.84',
  '41577': '106.84',
  '41578': '106.84',
  '41579': '106.84',
  '41580': '106.84',
  '41581': '106.85',
  '41582': '106.85',
  '41583': '106.85',
  '41584': '106.85',
  '41585': '106.85',
  '41586': '106.85',
  '41587': '106.86',
  '41588': '106.86',
  '41589': '106.86',
  '41590': '106.86',
  '41591': '106.86',
  '41592': '106.86',
  '41593': '106.87',
  '41594': '106.87',
  '41595': '106.87',
  '41596': '106.87',
  '41597': '106.87',
  '41598': '106.88',
  '41599': '106.88',
  '41600': '106.88',
  '41601': '106.88',
  '41602': '106.88',
  '41603': '106.89',
  '41604': '106.89',
  '41605': '106.89',
  '41606': '106.89',
  '41607': '106.89',
  '41608': '106.89',
  '41609': '106.90',
  '41610': '106.90',
  '41611': '106.90',
  '41612': '106.90',
  '41613': '106.90',
  '41614': '106.90',
  '41615': '106.91',
  '41616': '106.91',
  '41617': '106.91',
  '41618': '106.91',
  '41619': '106.91',
  '41620': '106.92',
  '41621': '106.92',
  '41622': '106.92',
  '41623': '106.92',
  '41624': '106.92',
  '41625': '106.93',
  '41626': '106.93',
  '41627': '106.93',
  '41628': '106.93',
  '41629': '106.93',
  '41630': '106.93',
  '41631': '106.93',
  '41632': '106.94',
  '41633': '106.94',
  '41634': '106.94',
  '41635': '106.94',
  '41636': '106.94',
  '41637': '106.95',
  '41638': '106.95',
  '41639': '106.95',
  '41640': '106.95',
  '41641': '106.95',
  '41642': '106.96',
  '41643': '106.96',
  '41644': '106.96',
  '41645': '106.96',
  '41646': '106.96',
  '41647': '106.96',
  '41648': '106.97',
  '41649': '106.97',
  '41650': '106.97',
  '41651': '106.97',
  '41652': '106.97',
  '41653': '106.97',
  '41654': '106.98',
  '41655': '106.98',
  '41656': '106.98',
  '41657': '106.98',
  '41658': '106.98',
  '41659': '106.99',
  '41660': '106.99',
  '41661': '106.99',
  '41662': '106.99',
  '41663': '106.99',
  '41664': '107.00',
  '41665': '107.00',
  '41666': '107.00',
  '41667': '107.00',
  '41668': '107.00',
  '41669': '107.00',
  '41670': '107.01',
  '41671': '107.01',
  '41672': '107.01',
  '41673': '107.01',
  '41674': '107.01',
  '41675': '107.01',
  '41676': '107.02',
  '41677': '107.02',
  '41678': '107.02',
  '41679': '107.02',
  '41680': '107.02',
  '41681': '107.03',
  '41682': '107.03',
  '41683': '107.03',
  '41684': '107.03',
  '41685': '107.03',
  '41686': '107.03',
  '41687': '107.04',
  '41688': '107.04',
  '41689': '107.04',
  '41690': '107.04',
  '41691': '107.04',
  '41692': '107.04',
  '41693': '107.05',
  '41694': '107.05',
  '41695': '107.05',
  '41696': '107.05',
  '41697': '107.05',
  '41698': '107.06',
  '41699': '107.06',
  '41700': '107.06',
  '41701': '107.06',
  '41702': '107.06',
  '41703': '107.07',
  '41704': '107.07',
  '41705': '107.07',
  '41706': '107.07',
  '41707': '107.07',
  '41708': '107.07',
  '41709': '107.08',
  '41710': '107.08',
  '41711': '107.08',
  '41712': '107.08',
  '41713': '107.08',
  '41714': '107.08',
  '41715': '107.09',
  '41716': '107.09',
  '41717': '107.09',
  '41718': '107.09',
  '41719': '107.09',
  '41720': '107.10',
  '41721': '107.10',
  '41722': '107.10',
  '41723': '107.10',
  '41724': '107.10',
  '41725': '107.11',
  '41726': '107.11',
  '41727': '107.11',
  '41728': '107.11',
  '41729': '107.11',
  '41730': '107.11',
  '41731': '107.11',
  '41732': '107.12',
  '41733': '107.12',
  '41734': '107.12',
  '41735': '107.12',
  '41736': '107.12',
  '41737': '107.13',
  '41738': '107.13',
  '41739': '107.13',
  '41740': '107.13',
  '41741': '107.13',
  '41742': '107.14',
  '41743': '107.14',
  '41744': '107.14',
  '41745': '107.14',
  '41746': '107.14',
  '41747': '107.14',
  '41748': '107.15',
  '41749': '107.15',
  '41750': '107.15',
  '41751': '107.15',
  '41752': '107.15',
  '41753': '107.15',
  '41754': '107.16',
  '41755': '107.16',
  '41756': '107.16',
  '41757': '107.16',
  '41758': '107.16',
  '41759': '107.17',
  '41760': '107.17',
  '41761': '107.17',
  '41762': '107.17',
  '41763': '107.17',
  '41764': '107.18',
  '41765': '107.18',
  '41766': '107.18',
  '41767': '107.18',
  '41768': '107.18',
  '41769': '107.18',
  '41770': '107.19',
  '41771': '107.19',
  '41772': '107.19',
  '41773': '107.19',
  '41774': '107.19',
  '41775': '107.19',
  '41776': '107.20',
  '41777': '107.20',
  '41778': '107.20',
  '41779': '107.20',
  '41780': '107.20',
  '41781': '107.21',
  '41782': '107.21',
  '41783': '107.21',
  '41784': '107.21',
  '41785': '107.21',
  '41786': '107.21',
  '41787': '107.22',
  '41788': '107.22',
  '41789': '107.22',
  '41790': '107.22',
  '41791': '107.22',
  '41792': '107.22',
  '41793': '107.23',
  '41794': '107.23',
  '41795': '107.23',
  '41796': '107.23',
  '41797': '107.23',
  '41798': '107.24',
  '41799': '107.24',
  '41800': '107.24',
  '41801': '107.24',
  '41802': '107.24',
  '41803': '107.25',
  '41804': '107.25',
  '41805': '107.25',
  '41806': '107.25',
  '41807': '107.25',
  '41808': '107.25',
  '41809': '107.26',
  '41810': '107.26',
  '41811': '107.26',
  '41812': '107.26',
  '41813': '107.26',
  '41814': '107.26',
  '41815': '107.27',
  '41816': '107.27',
  '41817': '107.27',
  '41818': '107.27',
  '41819': '107.27',
  '41820': '107.28',
  '41821': '107.28',
  '41822': '107.28',
  '41823': '107.28',
  '41824': '107.28',
  '41825': '107.29',
  '41826': '107.29',
  '41827': '107.29',
  '41828': '107.29',
  '41829': '107.29',
  '41830': '107.29',
  '41831': '107.29',
  '41832': '107.30',
  '41833': '107.30',
  '41834': '107.30',
  '41835': '107.30',
  '41836': '107.30',
  '41837': '107.31',
  '41838': '107.31',
  '41839': '107.31',
  '41840': '107.31',
  '41841': '107.31',
  '41842': '107.32',
  '41843': '107.32',
  '41844': '107.32',
  '41845': '107.32',
  '41846': '107.32',
  '41847': '107.32',
  '41848': '107.33',
  '41849': '107.33',
  '41850': '107.33',
  '41851': '107.33',
  '41852': '107.33',
  '41853': '107.33',
  '41854': '107.34',
  '41855': '107.34',
  '41856': '107.34',
  '41857': '107.34',
  '41858': '107.34',
  '41859': '107.35',
  '41860': '107.35',
  '41861': '107.35',
  '41862': '107.35',
  '41863': '107.35',
  '41864': '107.36',
  '41865': '107.36',
  '41866': '107.36',
  '41867': '107.36',
  '41868': '107.36',
  '41869': '107.36',
  '41870': '107.37',
  '41871': '107.37',
  '41872': '107.37',
  '41873': '107.37',
  '41874': '107.37',
  '41875': '107.38',
  '41876': '107.38',
  '41877': '107.38',
  '41878': '107.38',
  '41879': '107.38',
  '41880': '107.38',
  '41881': '107.39',
  '41882': '107.39',
  '41883': '107.39',
  '41884': '107.39',
  '41885': '107.39',
  '41886': '107.39',
  '41887': '107.40',
  '41888': '107.40',
  '41889': '107.40',
  '41890': '107.40',
  '41891': '107.40',
  '41892': '107.40',
  '41893': '107.41',
  '41894': '107.41',
  '41895': '107.41',
  '41896': '107.41',
  '41897': '107.41',
  '41898': '107.42',
  '41899': '107.42',
  '41900': '107.42',
  '41901': '107.42',
  '41902': '107.42',
  '41903': '107.43',
  '41904': '107.43',
  '41905': '107.43',
  '41906': '107.43',
  '41907': '107.43',
  '41908': '107.43',
  '41909': '107.44',
  '41910': '107.44',
  '41911': '107.44',
  '41912': '107.44',
  '41913': '107.44',
  '41914': '107.44',
  '41915': '107.45',
  '41916': '107.45',
  '41917': '107.45',
  '41918': '107.45',
  '41919': '107.45',
  '41920': '107.46',
  '41921': '107.46',
  '41922': '107.46',
  '41923': '107.46',
  '41924': '107.46',
  '41925': '107.46',
  '41926': '107.47',
  '41927': '107.47',
  '41928': '107.47',
  '41929': '107.47',
  '41930': '107.47',
  '41931': '107.47',
  '41932': '107.48',
  '41933': '107.48',
  '41934': '107.48',
  '41935': '107.48',
  '41936': '107.48',
  '41937': '107.49',
  '41938': '107.49',
  '41939': '107.49',
  '41940': '107.49',
  '41941': '107.49',
  '41942': '107.50',
  '41943': '107.50',
  '41944': '107.50',
  '41945': '107.50',
  '41946': '107.50',
  '41947': '107.50',
  '41948': '107.51',
  '41949': '107.51',
  '41950': '107.51',
  '41951': '107.51',
  '41952': '107.51',
  '41953': '107.51',
  '41954': '107.52',
  '41955': '107.52',
  '41956': '107.52',
  '41957': '107.52',
  '41958': '107.52',
  '41959': '107.53',
  '41960': '107.53',
  '41961': '107.53',
  '41962': '107.53',
  '41963': '107.53',
  '41964': '107.54',
  '41965': '107.54',
  '41966': '107.54',
  '41967': '107.54',
  '41968': '107.54',
  '41969': '107.54',
  '41970': '107.55',
  '41971': '107.55',
  '41972': '107.55',
  '41973': '107.55',
  '41974': '107.55',
  '41975': '107.56',
  '41976': '107.56',
  '41977': '107.56',
  '41978': '107.56',
  '41979': '107.56',
  '41980': '107.56',
  '41981': '107.57',
  '41982': '107.57',
  '41983': '107.57',
  '41984': '107.57',
  '41985': '107.57',
  '41986': '107.57',
  '41987': '107.58',
  '41988': '107.58',
  '41989': '107.58',
  '41990': '107.58',
  '41991': '107.58',
  '41992': '107.58',
  '41993': '107.59',
  '41994': '107.59',
  '41995': '107.59',
  '41996': '107.59',
  '41997': '107.59',
  '41998': '107.60',
  '41999': '107.60',
  '42000': '107.60',
}
