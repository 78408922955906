import { inject, Injectable } from '@angular/core'
import { PageGuard } from '@app/guards/page.guard'
import { ProductType } from '@naturalcycles/shared'
import { CartService } from '@src/app/srv/cart.service'

@Injectable({
  providedIn: 'root',
})
export class PaymentPageGuard extends PageGuard {
  className = 'PaymentPageGuard'

  private cartService = inject(CartService)

  get requirements(): boolean[] {
    const hasToPay = this.hasToPay()
    // 'manage' mode
    if (!this.isDemoModePlan() && this.hasAccountCompleteDate() && !hasToPay) return [true]

    // 'list' or 'add' mode
    const hasAccount = this.hasAccountId()
    const hasCartItems = this.hasCartItems()
    const hasShopItem = this.cartContainsType(ProductType.SHOP)

    const hasShopItemAndDeliveryDetails = hasShopItem && this.hasAddress()
    const hasCartItemsWithoutShopItem = !hasShopItem && hasCartItems

    const requirements = [
      !this.requiresAppConsent(),
      hasAccount,
      hasToPay,
      hasShopItemAndDeliveryDetails || hasCartItemsWithoutShopItem,
      this.cartService.hasValidThermometerSelection(),
    ]

    return requirements
  }
}
