import { _filterFalsyValues, _last, _objectKeys, _pick, localDate } from '@naturalcycles/js-lib'
import {
  Goal,
  HardwareId,
  HormoneType,
  RecentlyUsedHormones,
  SleepingPattern,
} from '@naturalcycles/shared'
import { LINK } from '@src/app/cnst/links.cnst'
import { TRACKERS_BY_GOAL } from '@src/app/srv/appSettings.cnst'
import { CalendarTemplate } from '../_templates/calendar/flow-calendar.page'
import { CheckboxGroupTemplate } from '../_templates/checkbox-group/flow-checkbox-group.page'
import { DateInputTemplate } from '../_templates/date-input/flow-date-input.page'
import { HeightAndWeightTemplate } from '../_templates/height-and-weight/flow-height-and-weight.page'
import { InfoBox, InfoTemplate } from '../_templates/info/flow-info.page'
import { PickerTemplate } from '../_templates/picker/flow-picker.page'
import { RadioGroup, RadioGroupTemplate } from '../_templates/radio-group/flow-radio-group.page'
import { RemindersTemplate } from '../_templates/reminders/flow-reminders.page'
import { SingleSelectTemplate } from '../_templates/single-select/flow-single-select.page'
import { StepperTemplate } from '../_templates/stepper/flow-stepper.page'
import { TextInputTemplate } from '../_templates/text-input/flow-text-input.page'
import { FlowId, FlowInputData, FlowTemplate } from '../flow.cnst'
import { OnboardingInputData } from './onboarding.service'

type InputType = FlowInputData<OnboardingInputData>

type GetOnboardingConfigFn<T> = (input: InputType) => T
type GetInfoScreenFn = (input: InputType) => InfoTemplate | undefined

const birthday: GetOnboardingConfigFn<DateInputTemplate> = input => {
  const preselectedDate = input.birthday
    ? localDate(input.birthday)
    : localDate.today().minus(30, 'year')

  return {
    analyticsPageName: 'DobPage',
    template: FlowTemplate.DATE_INPUT,
    title: 'onboarding-enter-dob',
    body: 'txt-must-be-eighteen',
    input: {
      date: preselectedDate.toISODate(),
      minDate: localDate.today().minus(60, 'year').toISODate(),
      maxDate: localDate.today().minus(18, 'year').toISODate(),
      title: 'settings-title-dateOfBirth',
      description: 'txt-must-be-eighteen',
    },
    saveButton: {
      label: 'txt-next',
      uid: 'onboarding__dob__buttonNext',
    },
  }
}

const birthDate: GetOnboardingConfigFn<CalendarTemplate> = input => {
  const { goal } = input

  const today = localDate.todayString()
  return {
    analyticsPageName: 'PregEndPage',
    template: FlowTemplate.CALENDAR,
    title:
      goal === Goal.POSTPARTUM ? 'txt-pregnancy-end-birthdate-title' : 'onboarding-pregnancy-end',
    body: goal === Goal.POSTPARTUM ? undefined : 'onboarding-pregnancy-end-body',
    minDate: localDate.today().minus(1, 'year').toISODate(),
    maxDate: today,
    sideEffects: (input: InputType) => {
      const { birthDate, goal, hwId } = input

      if (goal !== Goal.POSTPARTUM) return

      const daysSinceBirth = birthDate ? localDate(birthDate).getAgeInDays() : -1

      // ask if period is back after pregnancy if birth was more than 6 weeks ago
      if (daysSinceBirth > 6 * 7) {
        return {
          periodSincePregnancy: undefined,
          lastPeriodDate: undefined,
          avgPeriodLength: hwId === HardwareId.OURA ? undefined : null,
          cyclesAfterBirth: undefined,
        }
      }

      // otherwise - skip period questions
      return {
        periodSincePregnancy: false,
        lastPeriodDate: null,
        avgPeriodLength: null,
        cyclesAfterBirth: null,
      }
    },
  }
}

const conditions: GetOnboardingConfigFn<CheckboxGroupTemplate> = _input => ({
  analyticsPageName: 'MedicalConditionPage',
  template: FlowTemplate.CHECKBOX,
  title: 'onboarding-medical-conditions-title',
  body: 'onboarding-medical-conditions-txt',
  saveButton: {
    label: 'txt-next',
    uid: 'onboarding__medicalCondition__buttonNext',
  },
  checkboxes: [
    {
      title: 'txt-medical-condition--conditionPcos',
      name: 'conditionPcos',
      uid: 'onboarding__medicalCondition__pcos',
    },
    {
      title: 'txt-medical-condition--conditionEndometriosis',
      name: 'conditionEndometriosis',
      uid: 'onboarding__medicalCondition__endometriosis',
    },
    {
      title: 'txt-medical-condition--conditionThyroid',
      name: 'conditionThyroid',
      uid: 'onboarding__medicalCondition__thyroid',
    },
  ],
})

const conditionMenopause: GetOnboardingConfigFn<SingleSelectTemplate<boolean>> = _input => ({
  analyticsPageName: 'MenopausePage',
  template: FlowTemplate.SINGLE_SELECT,
  title: 'onboarding-menopause-title',
  body: 'onboarding-menopause-txt',
  buttons: [
    {
      uid: 'menopause__yes__btn',
      label: 'txt-yes',
      value: true,
    },
    {
      uid: 'menopause__no__btn',
      label: 'txt-no',
      value: false,
    },
  ],
})

const recentlyUsedHormones: GetOnboardingConfigFn<
  SingleSelectTemplate<RecentlyUsedHormones>
> = input => {
  let buttons = [
    {
      uid: 'onboarding__hormonal__buttonYes12Months',
      label: 'onboarding-yes-twelve',
      value: RecentlyUsedHormones.YES_LAST12M,
    },
    {
      uid: 'onboarding__hormonal__buttonYesStill',
      label: 'onboarding-yes-still',
      value: RecentlyUsedHormones.YES_STILL,
    },
    {
      uid: 'onboarding__hormonal__buttonNo12Months',
      label: 'onboarding-no-twelve',
      value: RecentlyUsedHormones.YES_NOT_IN_LAST12M,
    },
    {
      uid: 'onboarding__hormonal__buttonNoNever',
      label: 'onboarding-no-never',
      value: RecentlyUsedHormones.NEVER,
    },
  ]

  if (input.redoingOnboarding) {
    buttons = buttons.filter(b => b.value !== RecentlyUsedHormones.YES_STILL)
  }

  return {
    analyticsPageName: 'HormonalPage',
    template: FlowTemplate.SINGLE_SELECT,
    title: 'onboarding-recent-hormones',
    body: 'onboarding-recent-hormones-txt',
    buttons,
    sideEffects: (input: InputType) => {
      const { recentlyUsedHormones, hwId, sleepingPattern } = input

      switch (recentlyUsedHormones) {
        case undefined:
          // clear all data that's defined in cases below
          return {
            usedHormonesFor: undefined,
            pastHormoneType: undefined,
            pastHormoneTypeOther: undefined,
            hormonesQuitDate: undefined,
            expectedHormonesQuitDate: undefined,
            periodSinceHormones: undefined,
            cyclesAfterPill: undefined,
            hadWithdrawalBleed: undefined,
            withdrawalBleedStartDate: undefined,

            recentlyPregnant: undefined,
            periodSincePregnancy: undefined,
            birthDate: undefined,
            cyclesAfterBirth: undefined,
            avgCycleLength: undefined,
            cycleVariation: undefined,
            avgPeriodLength: hwId === HardwareId.OURA ? undefined : null,

            sleepingPattern: undefined,
            measureReminders: undefined,
            trackers: undefined,
          }

        case RecentlyUsedHormones.YES_LAST12M:
          return {
            recentlyPregnant: null,
            periodSincePregnancy: null,
            birthDate: null,
            cyclesAfterBirth: null,
            avgCycleLength: null,
            cycleVariation: null,
            avgPeriodLength: null,
            expectedHormonesQuitDate: null,
          }

        case RecentlyUsedHormones.YES_STILL:
          return {
            recentlyPregnant: null,
            periodSincePregnancy: null,
            birthDate: null,
            cyclesAfterBirth: null,
            avgCycleLength: null,
            cycleVariation: null,
            avgPeriodLength: null,
            hormonesQuitDate: null,
            cyclesAfterPill: null,
            hadWithdrawalBleed: null,
            periodSinceHormones: null,
            withdrawalBleedStartDate: null,
            lastPeriodDate: null,
            sleepingPattern: sleepingPattern || null,
            measureReminders: null,
            trackers: null,
          }

        case RecentlyUsedHormones.YES_NOT_IN_LAST12M:
        case RecentlyUsedHormones.NEVER:
          return {
            usedHormonesFor: null,
            pastHormoneType: null,
            pastHormoneTypeOther: null,
            hormonesQuitDate: null,
            expectedHormonesQuitDate: null,
            periodSinceHormones: null,
            cyclesAfterPill: null,
            hadWithdrawalBleed: null,
            withdrawalBleedStartDate: null,
          }
      }
    },
  }
}

const usedHormonesFor: GetOnboardingConfigFn<SingleSelectTemplate<number>> = input => {
  const { recentlyUsedHormones, pastHormoneType } = input

  let title = 'onboarding-length-hormones--' + RecentlyUsedHormones[recentlyUsedHormones!] // should always be defined
  if (pastHormoneType) title += `--${HormoneType[pastHormoneType]}`

  return {
    analyticsPageName: 'HormonalLengthPage',
    template: FlowTemplate.SINGLE_SELECT,
    title,
    body: 'onboarding-hormonal-info',
    buttons: [
      {
        uid: 'onboarding__lengthHormones__buttonOneYear',
        label: 'onboarding-used-hormones-for-1',
        value: 1,
      },
      {
        uid: 'onboarding__lengthHormones__buttonFiveYear',
        label: 'onboarding-used-hormones-for-3',
        value: 3,
      },
      {
        uid: 'onboarding__lengthHormones__buttonFivePlus',
        label: 'onboarding-used-hormones-for-10',
        value: 10,
      },
    ],
  }
}

const pastHormoneType: GetOnboardingConfigFn<SingleSelectTemplate<HormoneType>> = input => ({
  analyticsPageName: 'HormonalTypePage',
  template: FlowTemplate.SINGLE_SELECT,
  title: 'onboarding-hormonal-type-title--' + RecentlyUsedHormones[input.recentlyUsedHormones!], // should always be defined
  body: 'onboarding-hormonal-type-info--' + RecentlyUsedHormones[input.recentlyUsedHormones!],
  buttons: [
    HormoneType.PILL,
    HormoneType.MINI_PILL,
    HormoneType.HORMONAL_IUD,
    HormoneType.IMPLANT,
    HormoneType.VAGINAL_RING,
    HormoneType.SHOT,
    HormoneType.PATCH,
    HormoneType.OTHER,
  ].map(type => {
    return {
      uid: `onboarding__hormonalType__button${HormoneType[type]}`,
      label: `hormonal-type--${HormoneType[type]}`,
      value: type,
      outline: type === HormoneType.OTHER,
    }
  }),
  sideEffects: (input: InputType) => {
    const { pastHormoneType } = input

    switch (pastHormoneType) {
      case undefined:
      case HormoneType.OTHER:
        return {
          pastHormoneTypeOther: undefined,
        }

      default:
        return {
          pastHormoneTypeOther: null,
        }
    }
  },
})

const pastHormoneTypeOther: GetOnboardingConfigFn<TextInputTemplate> = input => ({
  analyticsPageName: 'HormonalTypeOtherPage',
  template: FlowTemplate.TEXT_INPUT,
  title: 'onboarding-hormonal-type-title--' + RecentlyUsedHormones[input.recentlyUsedHormones!], // should always be defined
  body: 'onboarding-hormonal-type-info--' + RecentlyUsedHormones[input.recentlyUsedHormones!],
  inputs: [
    {
      key: 'pastHormoneTypeOther',
      value: '',
      placeholder: 'onboarding-hormonal-other-label',
      required: true,
    },
  ],
  saveButton: {
    label: 'txt-next',
    uid: 'onboarding__hormonesOther__buttonNext',
  },
})

const name: GetOnboardingConfigFn<TextInputTemplate> = input => ({
  analyticsPageName: 'NamePage',
  template: FlowTemplate.TEXT_INPUT,
  title: 'onboarding-enter-name',
  inputs: [
    {
      key: 'name1',
      value: input.name1 || '',
      placeholder: 'form-label-name1',
      required: true,
    },
    {
      key: 'name2',
      value: input.name2 || '',
      placeholder: 'form-label-name2',
      required: true,
    },
  ],
  saveButton: {
    label: 'txt-next',
    uid: 'onboarding__name__buttonNext',
  },
})

const recentlyPregnant: GetOnboardingConfigFn<SingleSelectTemplate<boolean>> = _input => ({
  template: FlowTemplate.SINGLE_SELECT,
  analyticsPageName: 'PregnantPage',
  title: 'onboarding-pregnant-last-year',
  body: 'onboarding-pregnant-last-year-info',
  buttons: [
    {
      uid: 'onboarding__pregnantLastYear__buttonYes',
      label: 'txt-yes',
      value: true,
    },
    {
      uid: 'onboarding__pregnantLastYear__buttonNo',
      label: 'txt-no',
      value: false,
    },
  ],
  sideEffects: (input: InputType) => {
    const { recentlyPregnant, goal } = input

    switch (recentlyPregnant) {
      // clear all data that's defined in cases below
      case undefined:
        return {
          avgCycleLength: undefined,
          cycleVariation: undefined,
          periodSincePregnancy: undefined,
          birthDate: undefined,
          cyclesAfterBirth: undefined,
        }

      case true:
        return {
          avgCycleLength: null,
          cycleVariation: null,
          birthDate: goal === Goal.POSTPARTUM ? undefined : null,
        }

      case false:
        return {
          periodSincePregnancy: null,
          birthDate: null,
          cyclesAfterBirth: null,
        }
    }
  },
})

const periodSincePregnancy: GetOnboardingConfigFn<SingleSelectTemplate<boolean>> = _input => ({
  template: FlowTemplate.SINGLE_SELECT,
  analyticsPageName: 'PeriodSincePregPage',
  title: 'onboarding-period-since-pregnancy',
  body: 'onboarding-period-since-pregnancy-info',
  buttons: [
    {
      uid: 'onboarding__periodSincePreg__yes__btn',
      label: 'txt-yes',
      value: true,
    },
    {
      uid: 'onboarding__periodSincePreg__no__btn',
      label: 'txt-no',
      value: false,
    },
  ],
  sideEffects: (input: InputType) => {
    const { periodSincePregnancy, hwId, birthDate } = input

    switch (periodSincePregnancy) {
      // clear all data that's defined in cases below
      case undefined:
        return {
          lastPeriodDate: undefined,
          avgPeriodLength: hwId === HardwareId.OURA ? undefined : null,
          cyclesAfterBirth: undefined,
        }
      case true:
        return {
          birthDate: birthDate || null,
        }

      case false:
        return {
          lastPeriodDate: null,
          avgPeriodLength: null,
          cyclesAfterBirth: null,
          birthDate: birthDate || undefined,
        }
    }
  },
})

const cycleVariation: GetOnboardingConfigFn<SingleSelectTemplate<number | null>> = _input => ({
  analyticsPageName: 'VariancePage',
  template: FlowTemplate.SINGLE_SELECT,
  title: 'onboarding-cycle-variance',
  body: 'onboarding-cycle-variance-body',
  buttons: [
    {
      uid: 'onboarding__cycleVariance__button1to4',
      label: 'onboarding-cycle-variance-4',
      value: 4,
    },
    {
      uid: 'onboarding__cycleVariance__button5to9',
      label: 'onboarding-cycle-variance-9',
      value: 9,
    },
    {
      uid: 'onboarding__cycleVariance__button10more',
      label: 'onboarding-cycle-variance-10',
      value: 10,
    },
    {
      uid: 'onboarding__cycleVariance__buttonDontKnow',
      label: 'txt-dont-know',
      value: null,
      outline: true,
    },
  ],
})

const lastPeriodDate: GetOnboardingConfigFn<CalendarTemplate> = input => {
  const { hormonesQuitDate, hwId, lastPeriodDates, birthDate } = input
  const periodIndex = lastPeriodDates?.length && lastPeriodDates?.length + 1

  let maxDate = localDate.todayString()
  let minDate = localDate.today().minus(1, 'year').toISODate()

  if (hormonesQuitDate) {
    minDate = localDate(hormonesQuitDate).minus(7, 'day').toISODate()
  }

  if (birthDate) {
    minDate = localDate(birthDate).plus(6, 'week').toISODate()
  }

  if (hwId === HardwareId.OURA && lastPeriodDates && periodIndex) {
    maxDate = localDate
      .orToday(lastPeriodDates[periodIndex - 2])
      .minus(14, 'day')
      .toISODate()
  }

  const title =
    periodIndex && hwId === HardwareId.OURA
      ? 'onboarding-period-start--' + periodIndex
      : 'onboarding-period-start'

  const btnLabel =
    periodIndex && periodIndex > 1 && hwId === HardwareId.OURA
      ? 'onboarding-period-start-skip-btn'
      : 'txt-dont-know'

  return {
    template: FlowTemplate.CALENDAR,
    analyticsPageName: 'PeriodStartPage',
    title,
    minDate,
    maxDate,
    skipButton: {
      uid: 'onboarding__period__buttonDontKnow',
      label: btnLabel,
    },
    sideEffects: (input: InputType) => {
      const { lastPeriodDate, hwId, lastPeriodDates } = input

      if (hwId !== HardwareId.OURA || lastPeriodDate === null || lastPeriodDates === null) return

      const periodArray = lastPeriodDates || []
      const MAX_PERIODS = 6

      if (lastPeriodDate) {
        periodArray.push(lastPeriodDate)
      }

      const periodNumber = Number(_last(window.location.href.split('/')))
      if (lastPeriodDate === undefined && periodArray.length === periodNumber) {
        periodArray.pop()
      }

      if (periodArray.length === MAX_PERIODS) {
        return { lastPeriodDate: null, lastPeriodDates: periodArray }
      }

      return {
        lastPeriodDate: undefined,
        lastPeriodDates: periodArray,
      }
    },
  }
}

const withdrawalBleedStartDate: GetOnboardingConfigFn<CalendarTemplate> = input => {
  const { hormonesQuitDate } = input

  const maxDate = localDate.todayString()
  let minDate = localDate.today().minus(1, 'year').toISODate()

  if (hormonesQuitDate) {
    minDate = localDate(hormonesQuitDate).minus(7, 'day').toISODate()
  }

  return {
    template: FlowTemplate.CALENDAR,
    analyticsPageName: 'PeriodStartPage',
    title: 'onboarding-period-start--WITHDRAWAL',
    minDate,
    maxDate,
    skipButton: {
      uid: 'onboarding__period__buttonDontKnow',
      label: 'txt-dont-know',
    },
  }
}

const hormonesQuitDate: GetOnboardingConfigFn<CalendarTemplate> = input => {
  const { recentlyUsedHormones, pastHormoneType } = input
  return {
    template: FlowTemplate.CALENDAR,
    analyticsPageName: 'HormonalQuitPage',
    title: `onboarding-when-quit-hormones--${RecentlyUsedHormones[recentlyUsedHormones!]}--${HormoneType[pastHormoneType || HormoneType.OTHER]}`,
    minDate: localDate.today().minus(1, 'year').toISODate(),
    maxDate: localDate.todayString(),
    skipButton: {
      uid: 'onboarding__whenQuitHormones__buttonDontKnow',
      label: 'txt-dont-know',
    },
  }
}

const expectedHormonesQuitDate: GetOnboardingConfigFn<CalendarTemplate> = input => {
  const { recentlyUsedHormones, pastHormoneType } = input

  return {
    template: FlowTemplate.CALENDAR,
    analyticsPageName: 'HormonalQuitPage',
    title: `onboarding-when-quit-hormones--${RecentlyUsedHormones[recentlyUsedHormones!]}--${HormoneType[pastHormoneType || HormoneType.OTHER]}`,
    body: `onboarding-when-quit-hormones-body--${RecentlyUsedHormones[recentlyUsedHormones!]}`,
    minDate: localDate.todayString(),
    maxDate: localDate.today().plus(1, 'year').toISODate(),
    displayDate: localDate.todayString(),
    skipButton: {
      uid: 'onboarding__whenQuitHormones__buttonDontKnow',
      label: 'txt-dont-know',
    },
  }
}

const avgCycleLength: GetOnboardingConfigFn<PickerTemplate> = _input => ({
  template: FlowTemplate.PICKER,
  analyticsPageName: 'CycleLengthPage',
  title: 'onboarding-cycle-length',
  body: 'cycle-description',
  valuePickerUid: 'onboarding__cycleLength',
  valuePickerLabel: 'onboarding-select-days',
  minValue: 16,
  maxValue: 50,
  scrollToValue: 30,
  saveButton: {
    uid: 'onboarding__cycleLength__buttonOk',
    label: 'txt-ok',
  },
  skipButton: {
    uid: 'onboarding__cycleLength__buttonDontKnow',
    label: 'txt-dont-know',
  },
})

const cyclesAfterBirth: GetOnboardingConfigFn<PickerTemplate> = _input => ({
  template: FlowTemplate.PICKER,
  analyticsPageName: 'CycleSinceBirthPage',
  title: 'onboarding-cycles-since-birth',
  body: 'cycle-description',
  valuePickerUid: 'onboarding__cyclesAfterBirth',
  valuePickerLabel: 'onboarding-select-cycles',
  minValue: 1,
  maxValue: 20,
  saveButton: {
    uid: 'onboarding__cycleSinceBirth__buttonOk',
    label: 'txt-ok',
  },
})

const cyclesAfterPill: GetOnboardingConfigFn<PickerTemplate> = input => {
  const { hormonesQuitDate } = input

  return {
    template: FlowTemplate.PICKER,
    analyticsPageName: 'CycleSinceHorPage',
    title: 'onboarding-cycles-since-hormones',
    body: 'onboarding-cycles-since-hormones-info',
    valuePickerUid: 'onboarding__cyclesAfterPill',
    valuePickerLabel: 'onboarding-select-periods',
    minValue: 1,
    maxValue: !hormonesQuitDate
      ? 20
      : Math.ceil(localDate.today().diff(hormonesQuitDate, 'day') / 14) || 1,
    saveButton: {
      uid: 'onboarding__cycleSinceHor__buttonNext',
      label: 'txt-next',
    },
    sideEffects: (input: InputType) => {
      const { cyclesAfterPill } = input

      switch (cyclesAfterPill) {
        case undefined:
          return {
            cyclesAfterPill: undefined,
            periodSinceHormones: undefined,
            lastPeriodDate: undefined,
            withdrawalBleedStartDate: undefined,
          }
        case null:
          return {}

        default:
          return {
            cyclesAfterPill: Math.max(cyclesAfterPill - 2, 0),
            periodSinceHormones: cyclesAfterPill > 1,

            lastPeriodDate: cyclesAfterPill === 1 ? null : undefined,
            withdrawalBleedStartDate: cyclesAfterPill > 1 ? null : undefined,
          }
      }
    },
  }
}

const heightAndWeight: GetOnboardingConfigFn<HeightAndWeightTemplate> = _input => ({
  template: FlowTemplate.HEIGHT_AND_WEIGHT,
  analyticsPageName: 'WeightPage',
  title: 'onboarding-height-weight',
  body: 'onboarding-height-weight-info',
})

const goal: GetOnboardingConfigFn<SingleSelectTemplate<Goal>> = _input => ({
  analyticsPageName: 'GoalPage',
  template: FlowTemplate.SINGLE_SELECT,
  title: 'txt-goal-select',
  buttons: [
    {
      uid: 'goalPrevent__button',
      label: 'btn-goal--PREVENT',
      value: Goal.PREVENT,
    },
    {
      uid: 'goalPlan__button',
      label: 'btn-goal--PLAN',
      value: Goal.PLAN,
    },
    {
      uid: 'goalPregnant__button',
      label: 'btn-goal--PREGNANT',
      value: Goal.PREGNANT,
    },
    {
      uid: 'goalPostpartum__button',
      label: 'btn-goal--POSTPARTUM',
      value: Goal.POSTPARTUM,
    },
  ],
})

const units: GetOnboardingConfigFn<RadioGroupTemplate<boolean>> = input => {
  const { fahrenheit, imperialUnits, userLocale } = input
  const { country } = userLocale || {}

  const radioGroups: RadioGroup<boolean>[] = [
    {
      key: 'imperialUnits',
      title: fahrenheit === undefined ? 'settings-title-bmi' : undefined,
      value: imperialUnits === undefined ? country === 'US' || country === 'GB' : !!imperialUnits,
      items: [
        {
          title: 'txt-metric',
          value: false,
          uid: 'unitsPage__weight__metric__radio',
        },
        {
          title: 'txt-imperial',
          value: true,
          uid: 'unitsPage__weight__imperial__radio',
        },
      ],
    },
  ]

  if (fahrenheit === undefined) {
    radioGroups.push({
      key: 'fahrenheit',
      title: 'me-txt-temperature',
      value: fahrenheit === undefined ? country === 'US' : !!fahrenheit,
      items: [
        {
          title: 'txt-celsius',
          value: false,
          uid: 'unitsPage__temperature__celsius__radio',
        },
        {
          title: 'txt-fahrenheit',
          value: true,
          uid: 'unitsPage__temperature__fahrenheit__radio',
        },
      ],
    })
  }

  return {
    analyticsPageName: 'UnitsPage',
    template: FlowTemplate.RADIO,
    title: 'onboarding-select-units',
    saveButton: {
      label: 'txt-next',
      uid: 'onboarding__units__buttonSave',
    },
    radioGroups,
  }
}

const sleepingPattern: GetOnboardingConfigFn<RadioGroupTemplate<SleepingPattern>> = _input => ({
  analyticsPageName: 'SleepPatternPage',
  template: FlowTemplate.RADIO,
  title: 'sleep-pattern-title',
  body: 'sleep-pattern-txt',
  saveButton: {
    label: 'txt-next',
    uid: 'sleepPattern__next__btn',
  },
  radioGroups: [
    {
      key: 'sleepingPattern',
      value: SleepingPattern.REGULAR,
      items: [
        {
          title: 'txt-sleeping-pattern--REGULAR',
          subtitle: 'txt-sleeping-pattern-txt--REGULAR',
          value: SleepingPattern.REGULAR,
          uid: 'sleepPattern__smallVariation__radio',
        },
        {
          title: 'txt-sleeping-pattern--IRREGULAR',
          subtitle: 'txt-sleeping-pattern-txt--IRREGULAR',
          value: SleepingPattern.IRREGULAR,
          uid: 'sleepPattern__bigVariation__radio',
        },
        {
          title: 'txt-sleeping-pattern--SHIFTS',
          subtitle: 'txt-sleeping-pattern-txt--SHIFTS',
          value: SleepingPattern.SHIFTS,
          uid: 'sleepPattern__shifts__radio',
        },
      ],
    },
  ],
})

const measuringReminders: GetOnboardingConfigFn<RemindersTemplate> = _input => ({
  analyticsPageName: 'MeasureRemindersPage',
  template: FlowTemplate.REMINDERS,
  title: 'onboarding-notifications-title',
})

const trackers: GetOnboardingConfigFn<CheckboxGroupTemplate> = input => {
  const goal = input.goal || Goal.PREVENT

  return {
    analyticsPageName: 'TrackersPage',
    template: FlowTemplate.CHECKBOX,
    title: 'onboarding-trackers-title',
    body: 'onboarding-trackers-body',
    saveButton: {
      uid: 'onboarding__trackers__buttonSave',
      label: 'txt-next',
    },
    checkboxes: TRACKERS_BY_GOAL[goal],
  }
}

const periodSinceHormones: GetOnboardingConfigFn<SingleSelectTemplate<boolean>> = input => ({
  analyticsPageName: 'PeriodSinceHorPage',
  template: FlowTemplate.SINGLE_SELECT,
  title:
    'onboarding-period-since-hor-title--' + HormoneType[input.pastHormoneType || HormoneType.OTHER],
  body: 'onboarding-period-since-hor-info--WITHDRAWAL',
  buttons: [
    {
      uid: 'onboarding__periodSinceHor__buttonYes',
      label: 'txt-yes',
      value: true,
    },
    {
      uid: 'onboarding__periodSinceHor__buttonNo',
      label: 'txt-no',
      value: false,
    },
  ],
  sideEffects: (input: InputType) => {
    const { periodSinceHormones } = input

    switch (periodSinceHormones) {
      case true:
        return {
          hadWithdrawalBleed: true,
        }
      case false:
        return {
          hadWithdrawalBleed: false,
          cyclesAfterPill: null,
          lastPeriodDate: null,
          withdrawalBleedStartDate: null,
        }
    }
  },
})

const avgPeriodLength: GetOnboardingConfigFn<StepperTemplate> = _input => ({
  analyticsPageName: 'PeriodLengthPage',
  template: FlowTemplate.STEPPER,
  title: 'onboarding-period-length-title',
  body: 'onboarding-period-length-body',
  minValue: 2,
  maxValue: 10,
  value: 4,
  saveButton: {
    uid: 'onboarding__periodLength__buttonOk',
    label: 'txt-ok',
  },
  skipButton: {
    uid: 'onboarding__periodLength__buttonDontKnow',
    label: 'txt-dont-know',
  },
})

const medicalConditionInfo: GetInfoScreenFn = input => {
  const conditions = _filterFalsyValues(
    _pick(input, ['conditionMenopause', 'conditionPcos', 'conditionThyroid']),
  )
  const keys = _objectKeys(conditions)

  if (!keys.length) return

  return {
    analyticsPageName: 'MedicalConditionWarningPage',
    template: FlowTemplate.INFO,
    title:
      keys.length === 1
        ? `onboarding-medical-condition-warning-title--${keys[0]}`
        : 'onboarding-medical-condition-warning-title',
    body:
      keys.length === 1
        ? `onboarding-medical-condition-warning-txt--${keys[0]}`
        : 'onboarding-medical-condition-warning-txt',
    buttons: [
      {
        uid: 'medicalConditionWarning__button',
        label: 'txt-understand',
      },
    ],
  }
}

const hormonalDisclaimerInfo: GetInfoScreenFn = input => {
  const { periodSinceHormones, pastHormoneType, hadWithdrawalBleed, goal, cyclesAfterPill } = input

  // not HBC flow
  if (!pastHormoneType) return

  // show info sceen only if no period since hormones or after entering cycles after hormones
  if (
    !(periodSinceHormones === false || (periodSinceHormones && Number.isInteger(cyclesAfterPill)))
  ) {
    return
  }

  let body: string
  let tutorialBoxes: InfoBox[] | undefined

  if (goal === Goal.PREVENT) {
    tutorialBoxes = [
      {
        title: 'onboarding-hbc-disclaimer-red-days-title',
        body: 'onboarding-hbc-disclaimer-red-days-txt',
      },
    ]

    if (!periodSinceHormones && !hadWithdrawalBleed) {
      tutorialBoxes.push({
        title: 'onboarding-hbc-disclaimer-bleeding-title',
        body: 'onboarding-hbc-disclaimer-bleeding-txt',
      })
    }
  }

  if (periodSinceHormones) {
    body = 'onboarding-hbc-disclaimer-txt-had-period'
  } else if (hadWithdrawalBleed) {
    body = 'onboarding-hbc-disclaimer-txt-had-withdrawal'
  } else {
    body = 'onboarding-hbc-disclaimer-txt-will-withdrawal'
  }

  return {
    analyticsPageName: 'HormonalDisclaimerPage',
    template: FlowTemplate.INFO,
    title: 'onboarding-hbc-disclaimer-title--' + HormoneType[pastHormoneType],
    body,
    buttons: [
      {
        uid: 'onboarding__hbcDisclaimer__buttonIUnderstand',
        label: 'txt-understand',
      },
    ],
    tutorialBoxes,
  }
}

const breastfeedingInfo: GetInfoScreenFn = input => {
  const { birthDate, periodSincePregnancy } = input

  if (!birthDate) return

  const daysSinceBirth = localDate(birthDate).getAgeInDays()

  if (periodSincePregnancy !== false && daysSinceBirth > 6 * 7) return

  return {
    analyticsPageName: 'BreastInfoPage',
    template: FlowTemplate.INFO,
    title: 'onboarding-breast-feeding',
    body: 'onboarding-breast-feeding-txt',
    buttons: [
      {
        uid: 'breastInfoPage__btnUnderstand',
        label: 'txt-understand',
      },
      {
        uid: 'breastInfoPage__btnLearnMore',
        label: 'learn-more',
        outline: true,
        url: LINK.BREASTFEED,
      },
    ],
  }
}

const hormonalDemoInfo: GetInfoScreenFn = input => {
  if (input.recentlyUsedHormones !== RecentlyUsedHormones.YES_STILL) return

  return {
    analyticsPageName: 'DemoPage',
    template: FlowTemplate.INFO,
    title: 'onboarding-still-hormones',
    body: 'onboarding-still-hormones-txt',
    buttons: [
      {
        uid: 'onboarding__stillHormones__buttonEnterDemo',
        label: 'intro-btn-get-started',
      },
    ],
  }
}

const followInfo: GetInfoScreenFn = input => {
  if (input.goal !== Goal.PREGNANT) return

  return {
    analyticsPageName: 'FollowPage',
    template: FlowTemplate.INFO,
    title: 'txt-goal--3',
    body: 'onboarding-follow-info',
    buttons: [
      {
        uid: 'onboarding__followInfo__ok',
        label: 'txt-understand',
      },
    ],
  }
}

/**
 * Map of functions that can return an info screen based on the previous page and data.
 */
export const ONBOARDING_INFO_SCREEN = new Map<keyof OnboardingInputData, GetInfoScreenFn>([
  ['conditionMenopause', medicalConditionInfo],
  ['periodSinceHormones', hormonalDisclaimerInfo],
  ['birthDate', breastfeedingInfo],
  ['periodSincePregnancy', breastfeedingInfo],
  ['lastPeriodDate', hormonalDisclaimerInfo],
  ['withdrawalBleedStartDate', hormonalDisclaimerInfo],
  ['expectedHormonesQuitDate', hormonalDemoInfo],
  ['imperialUnits', followInfo],
])

/**
 * Keys that are used for everyone regardless of goal or flow
 */
export const flowAgnosticOnboardingKeys: (keyof OnboardingInputData)[] = [
  'name1',
  'name2',
  'birthday',
  'goal',
  'fahrenheit',
  'imperialUnits',
  'conditionEndometriosis',
  'conditionPcos',
  'conditionThyroid',
  'conditionMenopause',
]

export const appSettingsKeys: (keyof OnboardingInputData)[] = [
  'sleepingPattern',
  'measureReminders',
  'trackers',
]

// Order of this map is important, it decides which page comes next
export const ONBOARDING_CONFIG = new Map<keyof OnboardingInputData, GetOnboardingConfigFn<any>>([
  ['name1', name],
  ['name2', name],
  ['birthday', birthday],
  ['goal', goal],

  ['fahrenheit', units],
  ['imperialUnits', units],

  ['conditionEndometriosis', conditions],
  ['conditionPcos', conditions],
  ['conditionThyroid', conditions],
  ['conditionMenopause', conditionMenopause],

  ['recentlyUsedHormones', recentlyUsedHormones],
  ['usedHormonesFor', usedHormonesFor],
  ['pastHormoneType', pastHormoneType],
  ['pastHormoneTypeOther', pastHormoneTypeOther],

  ['hormonesQuitDate', hormonesQuitDate],
  ['expectedHormonesQuitDate', expectedHormonesQuitDate],
  ['periodSinceHormones', periodSinceHormones],
  ['cyclesAfterPill', cyclesAfterPill],
  ['withdrawalBleedStartDate', withdrawalBleedStartDate],

  ['recentlyPregnant', recentlyPregnant],
  ['birthDate', birthDate],
  ['periodSincePregnancy', periodSincePregnancy],
  ['lastPeriodDate', lastPeriodDate],
  ['avgPeriodLength', avgPeriodLength],
  ['cyclesAfterBirth', cyclesAfterBirth],

  ['avgCycleLength', avgCycleLength],
  ['cycleVariation', cycleVariation],

  ['height', heightAndWeight],
  ['weight', heightAndWeight],

  ['sleepingPattern', sleepingPattern],
  ['measureReminders', measuringReminders],
  ['trackers', trackers],
])

export const ONBOARDING_PAGES_BY_ROUTE = [...ONBOARDING_CONFIG.entries()].map(([key, value]) => {
  const config = value({}) || {}
  return {
    key: `/flow/${FlowId.ONBOARDING}/${config?.template}/${key}`,
    value: config.analyticsPageName,
  }
})
