import { Injectable } from '@angular/core'
import { isIOSApp } from '@src/app/cnst/userDevice.cnst'
import { getState } from '@src/app/srv/store.service'
import { PageGuard } from '../../guards/page.guard'

@Injectable({
  providedIn: 'root',
})
export class IntroAuthPageGuard extends PageGuard {
  className = 'IntroAuthPageGuard'

  get hasPendingIapTransaction(): boolean {
    const { pendingIapTransaction } = getState().ui
    return !!pendingIapTransaction?.jwsRepresentation
  }

  get requirements(): boolean[] {
    const requirements = [!this.hasAccountId(), this.hasPendingIapTransaction, isIOSApp]

    return requirements
  }
}
