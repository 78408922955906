import { PublicAndPrivateKeyTransport } from '@naturalcycles/shared'
import { Action, Reducer } from 'redux'

type State = PublicAndPrivateKeyTransport | null

const initialState: State = null

const actions: any = {
  setSessionEncryptionKeys(
    _state: State,
    action: { type: string; payload: PublicAndPrivateKeyTransport | null },
  ): State {
    return action.payload
  },
}

export const sessionEncryptionKeysReducer: Reducer<State> = (
  // biome-ignore lint/style/useDefaultParameterLast: ok
  state = initialState,
  action: Action,
) => {
  if (actions[action.type]) return actions[action.type](state, action)
  return state
}
