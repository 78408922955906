import { Injectable } from '@angular/core'
import { PageGuard } from '@app/guards/page.guard'

@Injectable({
  providedIn: 'root',
})
export class ConsentPageGuard extends PageGuard {
  className = 'ConsentPageGuard'

  get requirements(): boolean[] {
    const requirements = [this.requiresAppConsent()]

    return requirements
  }
}
