import {
  _invert,
  _mapValues,
  NumberOfSeconds,
  StringMap,
  UnixTimestamp,
} from '@naturalcycles/js-lib'
import {
  CervicalMucusConsistency,
  DailyEntryBM,
  DataQuantity,
  HardwareId,
  HKCategoryCervicalMucusValue,
  HKCategoryLHTestValue,
  HKCategoryMensValue,
  HKDevice,
  Mens,
  TestResult,
} from '@naturalcycles/shared'

// https://developer.apple.com/documentation/healthkit/data_types
export enum HKDataIdentifier {
  // Values match rawValues in HKCategoryTypeIdentifiers/HKQuantityTypeIdentifiers
  WRIST_TEMPERATURE = 'HKQuantityTypeIdentifierAppleSleepingWristTemperature',
  HEART_RATE = 'HKQuantityTypeIdentifierHeartRate',
  BASAL_BODY_TEMPERATURE = 'HKQuantityTypeIdentifierBasalBodyTemperature',
  MENS = 'HKCategoryTypeIdentifierMenstrualFlow',
  SPOTTING = 'HKCategoryTypeIdentifierIntermenstrualBleeding',
  LHTEST = 'HKCategoryTypeIdentifierOvulationTestResult',
  SEX = 'HKCategoryTypeIdentifierSexualActivity',
  CERVICAL_MUCUS = 'HKCategoryTypeIdentifierCervicalMucusQuality',
}

/**
 Matching enum exists in HealthKit.swift, they need to be kept in sync
 */
export enum HKIdentifierDataType {
  CATEGORY = 1,
  QUANTITY = 2,
}

// https://developer.apple.com/documentation/healthkit/hkunit/1615733-unitfromstring#discussion

export enum HKUnit {
  CELSIUS = 'degC',
  COUNT = 'count',
  COUNT_PER_SECOND = 'count/s',
}

/**
 * https://developer.apple.com/documentation/healthkit/samples/metadata_keys
 * Don't get fooled by the docs. Omit `MetadataKey` from all keys.
 * E.g., HKMetadataKeyExternalUUID should be simply HKExternalUUID
 */
export interface HKWriteMetadata {
  // TODO: add more known keys
  HKSexualActivityProtectionUsed?: boolean
  HKExternalUUID?: string
  HKMenstrualCycleStart?: boolean // required for HKDataIdentifier.MENS
  [key: string]: any
}

export interface HKDataIdentifierInput {
  identifier: HKDataIdentifier
  dataType: HKIdentifierDataType
  /**
   * Must be defined when reading data
   */
  unit?: HKUnit
  aggregationPeriodSeconds?: NumberOfSeconds
}

export interface HKWriteInput extends HKDataIdentifierInput {
  unit?: HKUnit
  startTimestamp: UnixTimestamp
  endTimestamp: UnixTimestamp
  hkDevice?: HKDevice
  metadata?: HKWriteMetadata
}

export interface HKQuantityWriteInput extends HKWriteInput {
  quantity: number
}

export interface HKCategoryWriteInput<
  T extends
    | number
    | HKCategoryValueNotApplicable
    | HKCategoryMensValue
    | HKCategoryLHTestValue
    | HKCategoryCervicalMucusValue = number,
> extends HKWriteInput {
  value: T
}

export const BASE_HK_EXPORTABLE_DAILY_ENTRY_PROPS: (keyof DailyEntryBM)[] = [
  'mens', // mensQuantity can also be exported, but depends on mens being defined
  'sex',
  'lhTest',
  'cervicalMucusConsistency',
  'cervicalMucusQuantity',
]

export const THERM_HK_EXPORTABLE_DAILY_ENTRY_PROPS: (keyof DailyEntryBM)[] = [
  ...BASE_HK_EXPORTABLE_DAILY_ENTRY_PROPS,
  'temperature',
]

export const HW_ID_TO_EXPORTABLE_ENTRY_PROPS: Record<HardwareId, (keyof DailyEntryBM)[]> = {
  [HardwareId.APPLE_WATCH]: BASE_HK_EXPORTABLE_DAILY_ENTRY_PROPS,
  [HardwareId.B1]: BASE_HK_EXPORTABLE_DAILY_ENTRY_PROPS,
  [HardwareId.OURA]: BASE_HK_EXPORTABLE_DAILY_ENTRY_PROPS,
  [HardwareId.SAMSUNG_RING]: BASE_HK_EXPORTABLE_DAILY_ENTRY_PROPS,
  [HardwareId.ORAL_THERMOMETER]: THERM_HK_EXPORTABLE_DAILY_ENTRY_PROPS,
  [HardwareId.UEBE_THERMOMETER]: THERM_HK_EXPORTABLE_DAILY_ENTRY_PROPS,
  [HardwareId.T3_THERMOMETER]: THERM_HK_EXPORTABLE_DAILY_ENTRY_PROPS,
}

export type HKExportableDailyEntry = Pick<
  DailyEntryBM,
  'mens' | 'sex' | 'lhTest' | 'cervicalMucusConsistency' | 'cervicalMucusQuantity' | 'temperature'
>

const invertMap = <T>(map: StringMap<T>): unknown =>
  _mapValues(_invert(map), (_key, value) => Number(value))

export type HKCategoryValueNotApplicable = 0

export const MENS_QUANTITY_TO_HK: Partial<Record<DataQuantity, HKCategoryMensValue>> = {
  [DataQuantity.LIGHT]: HKCategoryMensValue.LIGHT,
  [DataQuantity.MEDIUM]: HKCategoryMensValue.MEDIUM,
  [DataQuantity.HEAVY]: HKCategoryMensValue.HEAVY,
}

export const HK_TO_MENS_QUANTITY = invertMap(MENS_QUANTITY_TO_HK) as Partial<
  Record<HKCategoryMensValue, DataQuantity>
>

export const HK_TO_MENS = {
  [HKCategoryMensValue.UNSPECIFIED]: Mens.MENSTRUATION,
  [HKCategoryMensValue.LIGHT]: Mens.MENSTRUATION,
  [HKCategoryMensValue.MEDIUM]: Mens.MENSTRUATION,
  [HKCategoryMensValue.HEAVY]: Mens.MENSTRUATION,
  [HKCategoryMensValue.NO_FLOW]: undefined,
}

export const MUCUS_TO_HK: Record<CervicalMucusConsistency, HKCategoryCervicalMucusValue> = {
  [CervicalMucusConsistency.STICKY]: HKCategoryCervicalMucusValue.STICKY,
  [CervicalMucusConsistency.CREAMY]: HKCategoryCervicalMucusValue.CREAMY,
  [CervicalMucusConsistency.WATERY]: HKCategoryCervicalMucusValue.WATERY,
  [CervicalMucusConsistency.EGGWHITE]: HKCategoryCervicalMucusValue.EGGWHITE,
}

export const HK_TO_MUCUS: Record<HKCategoryCervicalMucusValue, CervicalMucusConsistency> =
  _mapValues(_invert(MUCUS_TO_HK), (_key, value) => Number(value))

export const MUCUS_QUANTITY_TO_HK: Partial<Record<DataQuantity, HKCategoryCervicalMucusValue>> = {
  [DataQuantity.NONE]: HKCategoryCervicalMucusValue.DRY,
}

export const HK_TO_MUCUS_QUANTITY = invertMap(MUCUS_QUANTITY_TO_HK) as Partial<
  Record<HKCategoryCervicalMucusValue, DataQuantity>
>

export const LH_TO_HK: Record<TestResult, HKCategoryLHTestValue> = {
  [TestResult.NO]: HKCategoryLHTestValue.NEGATIVE,
  [TestResult.YES]: HKCategoryLHTestValue.POSITIVE,
}

export const HK_TO_LH = invertMap(LH_TO_HK) as Record<HKCategoryLHTestValue, TestResult>

export const BASE_FERTILITY_DATA_IDENTIFIER_INPUTS: HKDataIdentifierInput[] = [
  {
    identifier: HKDataIdentifier.MENS,
    dataType: HKIdentifierDataType.CATEGORY,
  },
  {
    identifier: HKDataIdentifier.SPOTTING,
    dataType: HKIdentifierDataType.CATEGORY,
  },
  {
    identifier: HKDataIdentifier.LHTEST,
    dataType: HKIdentifierDataType.CATEGORY,
  },
  {
    identifier: HKDataIdentifier.SEX,
    dataType: HKIdentifierDataType.CATEGORY,
  },
  {
    identifier: HKDataIdentifier.CERVICAL_MUCUS,
    dataType: HKIdentifierDataType.CATEGORY,
  },
]

export const THERM_FERTILITY_DATA_IDENTIFIER_INPUTS: HKDataIdentifierInput[] = [
  ...BASE_FERTILITY_DATA_IDENTIFIER_INPUTS,
  {
    identifier: HKDataIdentifier.BASAL_BODY_TEMPERATURE,
    dataType: HKIdentifierDataType.QUANTITY,
    unit: HKUnit.CELSIUS,
  },
]

export const HW_ID_TO_IDENTIFIER_INPUTS: Record<HardwareId, HKDataIdentifierInput[]> = {
  [HardwareId.APPLE_WATCH]: BASE_FERTILITY_DATA_IDENTIFIER_INPUTS,
  [HardwareId.B1]: BASE_FERTILITY_DATA_IDENTIFIER_INPUTS,
  [HardwareId.OURA]: BASE_FERTILITY_DATA_IDENTIFIER_INPUTS,
  [HardwareId.SAMSUNG_RING]: BASE_FERTILITY_DATA_IDENTIFIER_INPUTS,
  [HardwareId.ORAL_THERMOMETER]: THERM_FERTILITY_DATA_IDENTIFIER_INPUTS,
  [HardwareId.UEBE_THERMOMETER]: THERM_FERTILITY_DATA_IDENTIFIER_INPUTS,
  [HardwareId.T3_THERMOMETER]: THERM_FERTILITY_DATA_IDENTIFIER_INPUTS,
}

export const FERTILITY_DATA: HKDataIdentifierInput[] = [
  { dataType: HKIdentifierDataType.CATEGORY, identifier: HKDataIdentifier.SPOTTING },
  { dataType: HKIdentifierDataType.CATEGORY, identifier: HKDataIdentifier.MENS },
  { dataType: HKIdentifierDataType.CATEGORY, identifier: HKDataIdentifier.SEX },
  { dataType: HKIdentifierDataType.CATEGORY, identifier: HKDataIdentifier.LHTEST },
  {
    dataType: HKIdentifierDataType.QUANTITY,
    identifier: HKDataIdentifier.BASAL_BODY_TEMPERATURE,
  },
  { dataType: HKIdentifierDataType.CATEGORY, identifier: HKDataIdentifier.CERVICAL_MUCUS },
]

export const AW_TEMPS_AND_HR_IDENTIFIERS: HKDataIdentifierInput[] = [
  { identifier: HKDataIdentifier.WRIST_TEMPERATURE, dataType: HKIdentifierDataType.QUANTITY },
  { identifier: HKDataIdentifier.HEART_RATE, dataType: HKIdentifierDataType.QUANTITY },
]

export function isCategoryWriteInput(
  input: HKCategoryWriteInput | HKQuantityWriteInput,
): input is HKCategoryWriteInput {
  return (input as HKCategoryWriteInput).value !== undefined
}
