import { BackendResponseFM, OuraState } from '@naturalcycles/shared'
import { Action, Reducer } from 'redux'

type State = OuraState | null

const initialState: State = null

const actions: any = {
  onBackendResponse(state: State, action: { type: string; payload: BackendResponseFM }): State {
    const { oura } = action.payload
    if (!oura) return state

    return {
      ...state,
      ...oura,
    }
  },

  extendOura(state: State, action: { type: string; payload: OuraState }): State {
    if (!state) return action.payload

    return {
      ...state,
      ...action.payload,
    }
  },
}

// biome-ignore lint/style/useDefaultParameterLast: ok
export const ouraReducer: Reducer<State> = (state = initialState, action: Action) => {
  if (actions[action.type]) return actions[action.type](state, action)
  return state
}
