import { BackendResponseFM, FWVersion } from '@naturalcycles/shared'
import { Action, Reducer } from 'redux'

type State = FWVersion | null

const initialState: State = null

const actions: any = {
  mockAvailableFWVersion(_state: State, action: { type: string; payload: FWVersion }): State {
    return action.payload
  },

  onBackendResponse(state: State, action: { type: string; payload: BackendResponseFM }): State {
    if (action.payload.latestHWDeviceFWVersion === null) return null

    if (action.payload.latestHWDeviceFWVersion) {
      return {
        ...action.payload.latestHWDeviceFWVersion,
      }
    }
    return state
  },
}

export const latestHWDeviceFWVersionReducer: Reducer<State> = (
  // biome-ignore lint/style/useDefaultParameterLast: ok
  state = initialState,
  action: Action,
) => {
  if (actions[action.type]) return actions[action.type](state, action)
  return state
}
