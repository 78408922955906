import { BackendResponseFM, WidgetData } from '@naturalcycles/shared'
import { Action, Reducer } from 'redux'

type State = WidgetData | null
const initialState: State = null

const actions: any = {
  extendWidgetData(state: State, action: { type: string; payload: WidgetData }): State {
    return {
      ...state,
      ...action.payload,
    }
  },

  onBackendResponse(state: State, action: { type: string; payload: BackendResponseFM }): State {
    // replace!
    if (action.payload.widgetData) {
      return {
        ...action.payload.widgetData,
      }
    }

    return state
  },
}

// biome-ignore lint/style/useDefaultParameterLast: ok
export const widgetDataReducer: Reducer<State> = (state = initialState, action: Action) => {
  if (actions[action.type]) return actions[action.type](state, action)
  return state
}
