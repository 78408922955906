import { buildInfo } from '@app/util/buildInfo.util'
import { BackendResponseFM } from '@naturalcycles/shared'
import { Action } from 'redux'
import { BlockingLoader } from '../srv/blockingLoader.service'
import { IAPTransaction } from '../srv/iap.service'

/**
 * In the state.UI we put everything that doesn't fit in other places.
 * It's NOT persistent (so, temporary state).
 */
export interface UI {
  /**
   * Controlled by Cordova network plugin. Default is "true".
   */
  online: boolean
  blockingLoader?: BlockingLoader
  ghostLoader?: boolean
  buttonLoader?: boolean
  admin?: boolean

  // Current language. This should be used as PRIMARY source of truth for UI language, not Account.lang
  // Reason 1: we should support qs.lang (for users coming from WebSignup)
  // Reason 2: it's cleaner to only depend on state, not to inject LangService everywhere
  lang: string
  locale: string
  buildVersion?: string
  blockAutoOpen?: boolean
  /**
   * Identify if user owns or not certain HW device at signup
   */
  ownsHw?: boolean
  clickedAwConnect?: boolean
  /**
   * The token id used to report out-of-store earnings in EU storefronts.
   * (We still won't report exempted transactions like thermometer bundles)
   */
  externalPurchaseTokenId?: string

  pendingIapTransaction?: IAPTransaction
  /**
   * If true, email verification email was sent with redirect parameter
   * to redirect user to WebSignup to finish the signup there
   */
  sentEmailVerificationWithRedirect: boolean
}

type State = UI

export const uiInitialState: State = {
  online: true,
  lang: 'en', // default
  locale: 'en', // default
  buildVersion: buildInfo.ver,
  sentEmailVerificationWithRedirect: false,
}

export const uiReducerActions: any = {
  extendUI(state: State, action: { payload: Partial<State> }): State {
    return {
      ...state,
      ...action.payload,
    }
  },

  setOwnsHw(state: State, action: { type: string; payload: boolean }): State {
    return {
      ...state,
      ownsHw: action.payload,
    }
  },

  setOnline(state: State, action: { type: string; payload: boolean }): State {
    return {
      ...state,
      online: action.payload,
    }
  },

  setPendingIapTransaction(state: State, action: { type: string; payload: IAPTransaction }): State {
    return {
      ...state,
      pendingIapTransaction: action.payload,
    }
  },

  setGhostLoader(state: State, action: { type: string; payload: boolean }): State {
    return {
      ...state,
      ghostLoader: action.payload,
    }
  },

  setBlockingLoader(state: State, action: { type: string; payload: BlockingLoader }): State {
    return {
      ...state,
      blockingLoader: action.payload,
    }
  },

  setButtonLoader(state: State, action: { type: string; payload: boolean }): State {
    return {
      ...state,
      buttonLoader: action.payload,
    }
  },

  setLocale(
    state: State,
    action: { type: string; payload: { lang: string; locale: string } },
  ): State {
    return {
      ...state,
      lang: action.payload.lang,
      locale: action.payload.locale,
    }
  },

  toggleAdminMode(state: State): State {
    return {
      ...state,
      admin: !state.admin,
    }
  },

  setBlockAutoOpen(state: State, action: { type: string; payload: boolean }): State {
    return {
      ...state,
      blockAutoOpen: action.payload,
    }
  },

  onBackendResponse(state: State, action: { type: string; payload: BackendResponseFM }): State {
    if (action.payload?.admin !== undefined) {
      return {
        ...state,
        admin: action.payload.admin,
      }
    }

    return state
  },
}

// biome-ignore lint/style/useDefaultParameterLast: ok
export function uiReducer(state = uiInitialState, action: Action): State {
  if (uiReducerActions[action.type]) {
    return uiReducerActions[action.type](state, action)
  }
  return state
}
