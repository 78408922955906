import { BackendResponseFM, ShippingItemFM } from '@naturalcycles/shared'
import { Action, Reducer } from 'redux'

type State = ShippingItemFM | null

const initialState: State = null

const actions: any = {
  onBackendResponse(state: State, action: { type: string; payload: BackendResponseFM }): State {
    if (action.payload.replacementShippingItem === null) return null
    if (action.payload.replacementShippingItem) {
      return {
        ...action.payload.replacementShippingItem,
      }
    }

    return state
  },
}

export const replacementShippingItemReducer: Reducer<State> = (
  // biome-ignore lint/style/useDefaultParameterLast: ok
  state = initialState,
  action: Action,
) => {
  if (actions[action.type]) return actions[action.type](state, action)
  return state
}
