import { inject, Injectable } from '@angular/core'
import { EVENT } from '@app/analytics/analytics.cnst'
import { AnalyticsService } from '@app/analytics/analytics.service'
import { decorate, ErrorHandlerType, LoaderType } from '@app/decorators/decorators'
import { api } from '@app/srv/api.service'
import { _filterNullishValues, JWTString } from '@naturalcycles/js-lib'
import {
  AccountChangeGoalInput,
  AccountChangeHardwareInput,
  AccountChangeUnitsInput,
  AccountCompleteInput,
  AccountDemoModeEnterInput,
  AccountPatchInput,
  AnonymizeAccountInput,
  AnonymousModeEnterInput,
  AnonymousModeExitInput,
  BackendResponseFM,
  BackendResponseFMResp,
  ContactCsInput,
  EMAIL_VERIFICATION_ON_WEB_QP,
  EmailInput,
  HardwareId,
  LoginOrRegisterInput,
  ShouldLoginInput,
  SocialAuthInput,
  VerifyCredentialsInput,
  VerifyCredentialsResponse,
  WebSignupQueryParam,
} from '@naturalcycles/shared'
import { dispatch, getState } from './store.service'

export interface ChangePasswordReq {
  pwOld?: string
  pwNew: string
}

export const MIN_LOGIN_PASSWORD_LENGTH = 6
export const MIN_NEW_PASSWORD_LENGTH = 8

export enum PasswordLengthIndicator {
  WEAK = 'WEAK',
  MEDIUM = 'MEDIUM',
  STRONG = 'STRONG',
}

@Injectable({ providedIn: 'root' })
export class AccountService {
  private analyticsService = inject(AnalyticsService)

  shouldLoginRequests: { [email: string]: Promise<boolean> } = {}
  verifyEmailRequests: { [email: string]: Promise<boolean> } = {}

  async create(input: LoginOrRegisterInput): Promise<BackendResponseFM> {
    return await api
      .post<BackendResponseFMResp>('accounts', {
        json: input,
      })
      .then(r => r.backendResponse)
  }

  async createOrLogin(input: LoginOrRegisterInput): Promise<BackendResponseFM> {
    return await api
      .post<BackendResponseFMResp>('sessions/auto', {
        json: input,
      })
      .then(r => r.backendResponse)
  }

  async appleAuth(input: SocialAuthInput): Promise<BackendResponseFM> {
    return await api
      .put<BackendResponseFMResp>('sessions/appleAuth', {
        json: input,
      })
      .then(r => r.backendResponse)
  }

  async googleAuth(input: SocialAuthInput): Promise<BackendResponseFM> {
    return await api
      .put<BackendResponseFMResp>('sessions/googleAuth', {
        json: input,
      })
      .then(r => r.backendResponse)
  }

  async shouldLoginUser(input: ShouldLoginInput): Promise<boolean> {
    return await api
      .put<BackendResponseFMResp>('accounts/shouldLogin', {
        json: input,
      })
      .then(r => r.backendResponse.res)
  }

  async patch(input: AccountPatchInput): Promise<BackendResponseFM> {
    return await api
      .patch<BackendResponseFMResp>('accounts/my', {
        json: input,
      })
      .then(r => r.backendResponse)
  }

  async partnersEnable(): Promise<BackendResponseFM> {
    return await api
      .put<BackendResponseFMResp>('accounts/partnersEnable')
      .then(r => r.backendResponse)
  }

  async partnersDisable(): Promise<BackendResponseFM> {
    return await api
      .put<BackendResponseFMResp>('accounts/partnersDisable')
      .then(r => r.backendResponse)
  }

  async login(input: LoginOrRegisterInput): Promise<BackendResponseFM> {
    return await api
      .post<BackendResponseFMResp>('sessions', {
        json: input,
      })
      .then(r => r.backendResponse)
  }

  async getChatbotToken(): Promise<JWTString> {
    return await api
      .put<BackendResponseFMResp>('accounts/createChatbotAuthToken')
      .then(r => r.backendResponse.res)
  }

  async enterAnonymousMode(email: string, pw: string): Promise<BackendResponseFM> {
    return await api
      .post<BackendResponseFMResp>(`accounts/anonymousModeEnter`, {
        json: { email, pw } satisfies AnonymousModeEnterInput,
      })
      .then(r => r.backendResponse)
  }

  async exitAnonymousMode(email: string, pw: string): Promise<BackendResponseFM> {
    return await api
      .post<BackendResponseFMResp>(`accounts/anonymousModeExit`, {
        json: { email, pw } satisfies AnonymousModeExitInput,
      })
      .then(r => r.backendResponse)
  }

  async verifyCredentials(email: string, pw: string): Promise<VerifyCredentialsResponse> {
    return await api
      .put<BackendResponseFMResp>(`accounts/verifyCredentials`, {
        json: { email, pw } satisfies VerifyCredentialsInput,
      })
      .then(r => r.backendResponse.res)
  }

  async changeEmail(email: string): Promise<void> {
    const input: EmailInput = { email }

    await api.post('accounts/changeEmail', {
      json: input,
    })
  }

  async sendVerificationEmail(verifyOnWeb?: boolean, ownsHw?: boolean): Promise<void> {
    const params = new URLSearchParams({
      redirect: verifyOnWeb ? EMAIL_VERIFICATION_ON_WEB_QP : '',
      [WebSignupQueryParam.od]: ownsHw ? 'true' : 'false',
    }).toString()

    await api.get(`accounts/sendVerificationEmail?${params}`)
  }

  async resetPassword(input: EmailInput): Promise<void> {
    await api.post('accounts/resetPassword', {
      json: input,
    })
  }

  getPasswordLengthIndicator(pw: string): PasswordLengthIndicator {
    if (pw.length <= 7) {
      return PasswordLengthIndicator.WEAK
    }
    if (pw.length <= 11) {
      return PasswordLengthIndicator.MEDIUM
    }
    return PasswordLengthIndicator.STRONG
  }

  public async accountComplete(input: AccountCompleteInput): Promise<void> {
    await api.post('accounts/complete', {
      json: input,
    })
  }

  // Demo on Plans Page
  isInDemoModeBeforeSelectingPlan(): boolean {
    const { account, subscriptions } = getState()
    const hasNoPlan = !account.plan
    const isDemoMode = !!account.demoMode
    return hasNoPlan && isDemoMode && !subscriptions.current
  }

  async demoModeEnter(input: AccountDemoModeEnterInput): Promise<void> {
    await api.post('accounts/demoModeEnter', {
      json: input,
    })

    void this.analyticsService.trackEvent(EVENT.DEMO_ACCESS)
  }

  async demoModeExit(): Promise<void> {
    dispatch('extendUserSettings', {
      showPredictions: false,
    })

    await api.post('accounts/demoModeExit')
  }

  public async changeGoal(input: AccountChangeGoalInput): Promise<void> {
    await api.post('accounts/changeGoal', {
      json: input,
    })
  }

  public async changePassword(input: ChangePasswordReq): Promise<void> {
    await api.post('accounts/changePw', {
      json: input,
    })
  }

  /**
   * if undefined is passed for `fahrenheit` or `imperial` - that field will not be changed (will be left as is in DB).
   * Passing `false` or `true` will set it to `false` or `true` respectfully in the DB.
   */
  async changeUnits(fahrenheit?: boolean, imperial?: boolean): Promise<void> {
    const json: AccountChangeUnitsInput = {
      fahrenheit,
      imperial,
    }

    // if both are undefined, we dont need to save anything
    if (!Object.keys(_filterNullishValues(json)).length) return

    await api.put('accounts/changeUnits', {
      json,
    })
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  async postDataConsent(appConsent: boolean, password?: string): Promise<BackendResponseFM> {
    return await this.patch({
      appConsent,
      _pw: password,
    })
  }

  async anonymizeAccount(input: AnonymizeAccountInput): Promise<void> {
    await api.put('accounts/anonymize', {
      json: input,
    })
  }

  public async changeHardwareId(hwId: HardwareId): Promise<BackendResponseFM> {
    return await api
      .put<BackendResponseFMResp>('accounts/changeHardware', {
        json: { hwId } satisfies AccountChangeHardwareInput,
      })
      .then(r => r.backendResponse)
  }

  public async changeHardwareToRegHWId(): Promise<BackendResponseFM> {
    return await api
      .put<BackendResponseFMResp>('accounts/changeHardware', {
        json: { setToRegHWId: true } satisfies AccountChangeHardwareInput,
      })
      .then(r => r.backendResponse)
  }

  @decorate({
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  public async submitSupportTicket(input: ContactCsInput): Promise<BackendResponseFM> {
    return await api
      .post<BackendResponseFMResp>('accounts/contactCs', {
        json: input,
      })
      .then(r => r.backendResponse)
  }
}
