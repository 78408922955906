import { inject, Injectable } from '@angular/core'
import { EVENT } from '@app/analytics/analytics.cnst'
import { AnalyticsService, getAnalyticsProps } from '@app/analytics/analytics.service'
import { di } from '@app/srv/di.service'
import { storageService } from '@app/srv/storage.service'
import {
  ActionSheetController,
  AlertController,
  ModalController,
  ToastController,
} from '@ionic/angular/standalone'
import {
  ActionSheetOptions,
  AlertOptions,
  ModalOptions,
  OverlayEventDetail,
  ToastOptions,
} from '@ionic/core'

export enum Priority {
  NONE = 0, // default for all popups
  LOW = 1,
  MEDIUM = 2,
  HIGH = 3, // HIGH+ is allowed when _blockPopups === true
  VERY_HIGH = 4,
  IMMEDIATE = 5, // should only be used for user interactions!
}

interface ActionSheet
  extends Omit<HTMLIonActionSheetElement, 'addEventListener' | 'removeEventListener'>,
    Popup {}

interface Alert
  extends Omit<HTMLIonAlertElement, 'addEventListener' | 'removeEventListener'>,
    Popup {}

interface Modal
  extends Omit<HTMLIonModalElement, 'addEventListener' | 'removeEventListener'>,
    Popup {}

interface Snackbar
  extends Omit<HTMLIonToastElement, 'addEventListener' | 'removeEventListener'>,
    Popup {}

export interface Popup extends HTMLElement {
  id: string
  priority: number
  delay: number
  present: () => Promise<void>
  onWillDismiss: <T = any>() => Promise<OverlayEventDetail<T>>
  onDidDismiss: <T = any>() => Promise<OverlayEventDetail<T>>
}

const blockPopups = storageService.get('blockPopups')

@Injectable({ providedIn: 'root' })
export class PopupController {
  private alertController = inject(AlertController)
  private modalController = inject(ModalController)
  private toastController = inject(ToastController)
  private actionSheetController = inject(ActionSheetController)
  private popupQueue: Popup[] = []
  private _blockPopups = false

  public async presentAlert(
    options: AlertOptions,
    id: string,
    priority?: Priority,
    delay?: number,
  ): Promise<Popup> {
    const popup = await this._createAlert(options, id, priority, delay)

    const observer = new MutationObserver((_, observer: MutationObserver) => {
      // Disconnect so the observer is only triggered once
      observer?.disconnect()

      const msgEl = popup.getElementsByClassName('alert-message')[0]
      if (!msgEl || msgEl.scrollHeight <= msgEl.clientHeight) return

      popup.classList.add('alert--scrollable')
    })

    observer.observe(popup, { attributes: true, childList: false, subtree: false })

    void di.get(AnalyticsService).trackView(EVENT.INFOMODAL, {
      text: id,
      ...getAnalyticsProps(),
    })

    return await this._present(popup)
  }

  public async presentModal(
    options: ModalOptions,
    id: string,
    priority?: Priority,
    delay?: number,
  ): Promise<Popup> {
    const popup = await this._createModal(options, id, priority, delay)
    return await this._present(popup)
  }

  public async presentSnackbar(
    options: ToastOptions,
    id: string,
    priority?: Priority,
    delay?: number,
  ): Promise<Popup> {
    const popup = await this._createSnackbar(options, id, priority, delay)
    return await this._present(popup)
  }

  public async presentActionSheet(
    options: ActionSheetOptions,
    id: string,
    priority?: Priority,
    delay?: number,
  ): Promise<Popup> {
    const popup = await this._createActionSheet(options, id, priority, delay)
    return await this._present(popup)
  }

  private async _present(popup: Popup): Promise<Popup> {
    if (popup.priority === Priority.IMMEDIATE) {
      this.popupQueue.unshift(popup)
      this.displayNext()
    } else {
      // Duplicate popups are not allowed
      if (this.popupQueue.some(p => p.id === popup.id)) {
        popup.remove()
        return popup
      }

      this.popupQueue.push(popup)
      this.popupQueue.sort((a, b) => b.priority - a.priority)

      if (
        this.popupQueue.length === 1 ||
        (popup === this.popupQueue[0] && popup.priority >= Priority.HIGH)
      ) {
        // Wait for other (higher priority) popups to get in the queue
        setTimeout(() => this.displayNext(), 150)
      }
    }

    return popup
  }

  public async dismissActive(data?: any): Promise<void> {
    const activePopup = this.getActivePopup()
    if (!activePopup) return
    this.deleteFromQueue(activePopup)

    const { id, nodeName } = activePopup
    if (nodeName === 'ION-MODAL') {
      await this.modalController.dismiss(data, undefined, id)
    } else if (nodeName === 'ION-ALERT') {
      await this.alertController.dismiss(data, undefined, id)
    } else {
      await this.toastController.dismiss(data, undefined, id)
    }
  }

  public blockPopups(): void {
    this._blockPopups = true
  }

  public unblockPopups(leavingEl?: any): void {
    this._blockPopups = false
    const activePopup = this.getActivePopup()

    /*
     * Delete from queue if the leaving popup is the same element that triggered unblockPopups from basePage._ionViewWillLeave()
     * Necessary to make the leaving animation work on backdrop/'esc' click and to avoid "can't close modal" issues
     */
    if (activePopup && leavingEl?.modal?.id === activePopup?.id) {
      this.deleteFromQueue(activePopup)
    }

    this.displayNext()
  }

  public async hasActivePopup(): Promise<boolean> {
    return !!(
      (await this.alertController.getTop()) ||
      (await this.modalController.getTop()) ||
      (await this.toastController.getTop()) ||
      (await this.actionSheetController.getTop())
    )
  }

  private displayNext(): void {
    if (!this.popupQueue.length) return

    const popup = this.popupQueue[0]!
    if (blockPopups && popup.priority < parseInt(blockPopups)) return

    setTimeout(() => {
      // only allow Priority.HIGH and above to open from modals, block the rest
      if (this._blockPopups && popup.priority < Priority.HIGH) return

      // Popup already visible (happens when two popups are stacked on top of each other)
      if (!popup.classList.contains('overlay-hidden')) return

      // Open new popups on top of existing ones
      const activePopup = this.getActivePopup()
      if (activePopup) {
        popup.style.zIndex = `${Number(activePopup.style.zIndex) + 1}`
      }

      void popup.present()
    }, popup.delay)

    void popup.onWillDismiss().then(() => {
      this.deleteFromQueue(popup)
      this.displayNext()
    })
  }

  private deleteFromQueue(popup: Popup): void {
    const popupIndex = this.popupQueue.indexOf(popup)
    if (popupIndex !== -1) {
      this.popupQueue.splice(popupIndex, 1)
    }
  }

  public getActivePopup(): Popup | undefined {
    return this.popupQueue.find(popup => !popup.classList.contains('overlay-hidden'))
  }

  public getActivePopupById(popupId: string): Popup | undefined {
    return this.popupQueue.find(
      popup => !popup.classList.contains('overlay-hidden') && popup.id === popupId,
    )
  }

  private async _createAlert(
    options: AlertOptions,
    id: string,
    priority?: Priority,
    delay?: number,
  ): Promise<Popup> {
    const alert = (await this.alertController.create(options)) as Alert
    return this._createPopup(alert, id, priority, delay)
  }

  private async _createModal(
    options: ModalOptions,
    id: string,
    priority?: Priority,
    delay?: number,
  ): Promise<Popup> {
    const modal = (await this.modalController.create(options)) as Modal
    return this._createPopup(modal, id, priority, delay)
  }

  private async _createSnackbar(
    options: ToastOptions,
    id: string,
    priority?: Priority,
    delay?: number,
  ): Promise<Popup> {
    const snackbar = (await this.toastController.create(options)) as Snackbar
    return this._createPopup(snackbar, id, priority, delay)
  }

  private async _createActionSheet(
    options: ActionSheetOptions,
    id: string,
    priority?: Priority,
    delay?: number,
  ): Promise<Popup> {
    const actionSheet = (await this.actionSheetController.create(options)) as ActionSheet
    return this._createPopup(actionSheet, id, priority, delay)
  }

  private _createPopup(popup: Popup, id: string, priority = Priority.NONE, delay = 0): Popup {
    popup.id = id
    popup.priority = priority
    popup.delay = delay
    return popup
  }
}
