import { Component, Input } from '@angular/core'
import { ICON } from '@app/cnst/icons.cnst'
import { BaseModal } from '@app/pages/base.modal'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonLabel,
  IonToolbar,
} from '@ionic/angular/standalone'
import { TrPipe } from '../../pipes/tr.pipe'

@Component({
  selector: 'page-notifications-perm-modal',
  templateUrl: 'notifications-permission.modal.html',
  styleUrls: ['notifications-permission.modal.scss'],
  standalone: true,
  imports: [
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
    IonContent,
    IonFooter,
    IonLabel,
    TrPipe,
  ],
})
export class NotificationsPermissionModal extends BaseModal {
  className = 'NotificationPermissionModal'

  @Input()
  title = 'notifications-popup-title'

  @Input()
  body = 'notifications-popup-text'

  @Input()
  ctaYes = 'btn-allow'

  @Input()
  ctaNo = 'txt-decline'

  public ICON = ICON
}
