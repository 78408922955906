import { Storage3Adapter } from '@app/srv/storage3/storage3.service'
import { logUtil } from '@app/util/log.util'

export class Storage3LocalStorageAdapter implements Storage3Adapter {
  constructor() {
    try {
      if ('localStorage' in window && window.localStorage) {
        this.ls = window.localStorage
        this.ls.setItem('testKey', '1')
        this.ls.removeItem('testKey')
      }
    } catch {
      this.ls = undefined
    }

    if (!this.ls) logUtil.warn('window.localStorage is not available!')
  }

  private readonly ls?: Storage

  async getItem(key: string): Promise<string | undefined> {
    if (!this.ls) return
    return this.ls.getItem(key) || undefined // cast null to undefined
  }

  async setItem(key: string, value: string): Promise<void> {
    if (!this.ls) return
    this.ls.setItem(key, value)
  }

  async removeItem(key: string): Promise<void> {
    if (!this.ls) return
    this.ls.removeItem(key)
  }

  async clear(): Promise<void> {
    if (!this.ls) return
    this.ls.clear()
  }

  async keys(): Promise<string[]> {
    if (!this.ls) return []

    const keys: string[] = []
    const length = this.ls.length
    for (let i = 0; i < length; i++) {
      keys.push(this.ls.key(i)!)
    }

    return keys
  }
}
