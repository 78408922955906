import { di } from '@app/srv/di.service'
import { EventService } from '@app/srv/event.service'
import { AnimationController } from '@ionic/angular/standalone'
import { Animation } from '@ionic/core'

export const DURATION = 400
export const EASING = 'cubic-bezier(0.36, 0.66, 0.04, 1)'
export const EASING_REVERSED = 'cubic-bezier(0.96, 0, 0.64, 0.34)'

function animation(
  reverse: boolean,
  enteringEl?: HTMLElement | null,
  leavingEl?: HTMLElement | null,
  backdrop?: HTMLElement | null,
): Animation {
  const animationController = di.get(AnimationController)
  const el = reverse ? leavingEl : enteringEl

  const wrapperAnimation = animationController
    .create()
    .addElement(el || [])
    .beforeStyles({ opacity: 1 })
    .fromTo('transform', 'translateY(calc(50vh + 50%))', 'translateY(0%)')
    .beforeAddClass('show-close-button')
    .onFinish(() => di.get(EventService).transitionDone$.next())

  const baseAnimation = animationController
    .create()
    .easing(reverse ? EASING_REVERSED : EASING)
    .duration(DURATION)
    .direction(reverse ? 'reverse' : 'normal')
    .addAnimation(wrapperAnimation)
    .afterAddWrite(() => {
      if (!reverse || baseAnimation.getDirection() !== 'reverse') return

      // hide leaving element
      leavingEl?.style.setProperty('display', 'none')
    })

  if (backdrop) {
    const backdropAnimation = animationController
      .create()
      .addElement(backdrop)
      .fromTo('opacity', 0, 0.4)

    baseAnimation.addAnimation(backdropAnimation)
  } else {
    // Make entering element visible before animation start
    baseAnimation.addElement(enteringEl || []).beforeRemoveClass('ion-page-invisible')
  }

  return baseAnimation
}

export function verticalSlideAnimation(_baseEl: HTMLElement, opts?: any): Animation {
  const reverse = opts?.direction === 'back'
  return animation(reverse, opts.enteringEl, opts.leavingEl)
}

export function modalEnterAnimation(baseEl: HTMLElement): Animation {
  const enteringEl = baseEl.shadowRoot?.querySelector<HTMLElement>('.modal-wrapper')
  const backdrop = baseEl.shadowRoot?.querySelector<HTMLElement>('ion-backdrop')

  return animation(false, enteringEl, undefined, backdrop)
}

export function modalLeaveAnimation(baseEl: HTMLElement): Animation {
  const leavingEl = baseEl.shadowRoot?.querySelector<HTMLElement>('.modal-wrapper')
  const backdrop = baseEl.shadowRoot?.querySelector<HTMLElement>('ion-backdrop')

  return animation(true, undefined, leavingEl, backdrop)
}
