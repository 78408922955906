<ion-header>
  <ion-toolbar mode="ios">
    <ion-buttons slot="start">
      <ion-button mode="md" (click)="dismissModal(false)">
        <ion-icon slot="icon-only" name="close" />
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-text-center ion-padding-horizontal">
  <div class="notificationsModal__imageContainer">
    <div class="notificationsModal__image">
      <ion-icon [src]="ICON.MESSAGES" color="white" />
    </div>
  </div>

  <div class="ion-padding-horizontal">
    <h2>{{ title | tr }}</h2>
    <p>{{ body | tr }}</p>
  </div>
</ion-content>

<ion-footer>
  <ion-button
    expand="block"
    shape="round"
    uid="notificationsPermission__button__allow"
    (click)="dismissModal(true)"
  >
    <ion-label>{{ ctaYes | tr }}</ion-label>
  </ion-button>

  <ion-button
    expand="block"
    fill="outline"
    shape="round"
    uid="notificationsPermission__button__decline"
    (click)="dismissModal(false)"
  >
    <ion-label>{{ ctaNo | tr }}</ion-label>
  </ion-button>
</ion-footer>
