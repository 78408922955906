import { StringMap } from '@naturalcycles/js-lib'
import { toSupportedLanguage } from '@naturalcycles/shared'
import { TranslateLoader } from '@ngx-translate/core'
import { from, Observable } from 'rxjs'

// Bundle/preload en-US.autogenerated.json
const _enUs = require('../../assets/lang/en-US.autogenerated.json')

export class AppTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<StringMap> {
    return from(this.loadTranslation(lang))
  }

  async loadTranslation(lang: string): Promise<StringMap> {
    return await this.fetchTranslation(lang)
  }

  protected async fetchTranslation(lang: string): Promise<StringMap> {
    lang = toSupportedLanguage(lang)

    return await import(`../../assets/lang/${lang}.autogenerated.json`).catch(err => {
      // Show alert after timeout, to allow Sentry to report the error
      setTimeout(() => {
        alert(
          `Oops! Something went wrong.\nWe cannot load translation file. Click OK to restart the app. If the error still remains, check in your app store that you have the latest version of the app installed. If you are still having problems, please contact our support team and include your registered Natural Cycles email as a reference. Thanks!`,
        )
        window.location.reload()
      }, 2000)
      throw err // propagate error
    })
  }
}
