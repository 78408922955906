import { BackendResponseFM, Order } from '@naturalcycles/shared'
import { Action, Reducer } from 'redux'

const initialState: Order = {
  items: [],
}

const actions: any = {
  onBackendResponse(
    state: Order,
    action: { type: string; payload: BackendResponseFM }, // Todo: payload: BackendResponse
  ): Order {
    // replace!
    if (action.payload.order?.items) {
      return {
        ...action.payload.order,
      }
    }

    return state
  },
}

// biome-ignore lint/style/useDefaultParameterLast: ok
export const orderReducer: Reducer<Order> = (state = initialState, action: Action) => {
  if (actions[action.type]) return actions[action.type](state, action)
  return state
}
