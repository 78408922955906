import { Injectable } from '@angular/core'
import { PageGuard } from '@app/guards/page.guard'

@Injectable({
  providedIn: 'root',
})
export class AddressPageGuard extends PageGuard {
  className = 'AddressPageGuard'
  get requirements(): boolean[] {
    const requirements = [this.hasSession(), !this.requiresAppConsent()]
    return requirements
  }
}
