import { Injectable } from '@angular/core'
import { Directory, Encoding, Filesystem } from '@capacitor/filesystem'
import { AppError, StringMap } from '@naturalcycles/js-lib'
import { AccountId } from '@naturalcycles/shared'
import { sentryService } from './sentry.service'

const directory = Directory.Data
const encoding = Encoding.UTF8

@Injectable({ providedIn: 'root' })
export class FileService {
  public async saveHashFile(
    map: StringMap,
    cacheFolder: string,
    cacheFile: string,
    accId: AccountId,
  ): Promise<void> {
    const data = JSON.stringify(map)
    await Filesystem.writeFile({
      path: `${cacheFolder}/${cacheFile + accId}`,
      data,
      directory,
      encoding,
    })
  }

  public async deleteHashFile(
    cacheFolder: string,
    cacheFile: string,
    accId: AccountId,
  ): Promise<void> {
    try {
      await Filesystem.deleteFile({
        path: `${cacheFolder}/${cacheFile + accId}`,
        directory,
      })
    } catch {
      console.warn(
        `Can't remove hash file at ${cacheFolder}/${cacheFile} (probably it doesn't exist)`,
      )
      sentryService.captureException(
        new AppError(`Can't delete change detection cache file`, {
          cacheFolder,
          cacheFile,
        }),
      )
    }
  }

  public async getHashCache(
    cacheFolder: string,
    cacheFile: string,
    accId: AccountId,
  ): Promise<StringMap | undefined> {
    const { files } = await Filesystem.readdir({
      path: cacheFolder,
      directory,
    }).catch(() => {
      // create folder if does not exist
      void Filesystem.mkdir({
        directory,
        path: cacheFolder,
      })

      return { files: [] }
    })

    // no such file
    if (!files.some(f => f.name === cacheFile + accId)) return

    const { data } = await Filesystem.readFile({
      directory,
      path: `${cacheFolder}/${cacheFile + accId}`,
      encoding,
    })

    const oldHashMap = JSON.parse(data as string) as StringMap
    return oldHashMap
  }
}
