import { WebPlugin } from '@capacitor/core'
import { localDate } from '@naturalcycles/js-lib'
import { FOTAUpdateInput, T3DeviceStatus, T3Plugin } from '@src/typings/capacitor'
import { getBleDeviceMock } from '../mocks/BleClient.mock'
import { T3BatteryLevel, T3Command, T3DeviceState } from '../srv/t3.cnst'

class T3Web extends WebPlugin implements T3Plugin {
  // TODO: Values "*String" are not completely lowercased. Check firmware and confirm the right type.
  private _deviceStatus: T3DeviceStatus = {
    timestamp: localDate.today().unix,
    state: T3DeviceState.STANDBY,
    stateString: 'Standby',
    deviceMode: 5,
    deviceModeString: 'User',
    batteryLevel: T3BatteryLevel.GOOD, // TODO: why they are two? What are the differences?
    batteryState: T3BatteryLevel.GOOD,
    batteryStateString: 'Good',
    unSyncedLogs: false,
    PSUEnable: false,
    measureInProgress: false,
  }

  set deviceStatus(status: Partial<T3DeviceStatus>) {
    this._deviceStatus = { ...this._deviceStatus, ...status }
  }

  get deviceStatus(): T3DeviceStatus {
    return this._deviceStatus
  }

  async abortFOTA(): Promise<void> {
    console.warn('T3PluginMock.abortFOTA called!')
    console.warn('T3PluginMock.abortFOTA returned nothing')
    return
  }

  /**
   * increment progress every 50ms until it reaches 100
   * TODO: resolve 'fotaDidFail' somehow
   * @param input
   */
  async performFOTA(input: FOTAUpdateInput): Promise<void> {
    console.warn('T3PluginMock.performFOTA called!', input)

    let progress = 0
    const interval = setInterval(() => {
      this.notifyListeners('fotaDidProgress', { progress: progress++ })

      if (progress > 100) {
        this.notifyListeners('fotaDidSucceed', {})
        clearInterval(interval)

        setTimeout(() => {
          this.deviceStatus = { state: T3DeviceState.FOTA_SUCCEED }
        }, 500)
        console.warn('T3PluginMock.performFOTA notified fotaDidSucceed')
        return
      }
    }, 50)
  }

  async connect(args: { toDevice: string }): Promise<void> {
    console.warn('T3PluginMock.connect called!', args.toDevice)
    console.warn('T3PluginMock.connect returned nothing (connection succeeded)')
  }

  async initialize(): Promise<void> {
    console.warn('T3PluginMock.initialize called!')
    this.deviceStatus = { state: T3DeviceState.HOME }
    console.warn('T3PluginMock.initialize returned nothing (initialization succeeded)')
  }

  async scan(): Promise<void> {
    console.warn('T3PluginMock.scan called!')
    console.warn('T3PluginMock.scan returned nothing (scan succeeded)')
  }

  async stopScan(): Promise<void> {
    console.warn('T3PluginMock.stopScan called!')
    console.warn('T3PluginMock.stopScan returned nothing (scan stopped)')
  }

  async getDevices(): Promise<{ devices: any[] }> {
    console.warn('T3PluginMock.getDevices called!')
    const mockedDevices = { devices: [getBleDeviceMock().deviceId] }
    console.warn('T3PluginMock.getDevices returned', mockedDevices)
    return mockedDevices
  }

  async getDeviceStatus(): Promise<T3DeviceStatus> {
    console.warn('T3PluginMock.getDeviceStatus called!')
    console.warn('T3PluginMock.getDeviceStatus returned', this.deviceStatus)
    return this.deviceStatus
  }

  async sendCommand(args: { command: number; data: number[] }): Promise<void> {
    console.warn(`T3PluginMock.sendCommand called! command: ${args.command}, data: ${args.data}`)
    if (args.command === T3Command.FOTA) {
      this.deviceStatus = { state: T3DeviceState.FOTA }
      console.warn('T3PluginMock.sendCommand returned nothing (command sent)')
      return
    }
    console.warn('T3PluginMock.sendCommand resolved (no command sent)')
  }

  async enableWakeLock(): Promise<void> {
    console.warn('T3PluginMock.enableWakeLock called!')
    console.warn('T3PluginMock.enableWakeLock returned nothing (wake lock enabled)')
  }

  async disableWakeLock(): Promise<void> {
    console.warn('T3PluginMock.disableWakeLock called')
    console.warn('T3PluginMock.disableWakeLock returned nothing (wake lock disabled)')
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const T3PluginMock = new T3Web()
