import { inject, Injectable } from '@angular/core'
import { select } from '@app/srv/store.service'
import { AccountBM } from '@naturalcycles/shared'
import { BehaviorSubject, Observable } from 'rxjs'
import { combineLatestWith, map } from 'rxjs/operators'
import { UserSettings } from '../reducers/userSettings.reducer'
import { StoreService } from './store.service'

export interface Announcement {
  id: string
  steps: AnnouncementStep[]
  ctaTitle?: string
  ctaLink?: string
}

export interface AnnouncementStep {
  title: string
  body: string
  image: string
  ctaTitle?: string
  ctaLink?: string
}

export const CURRENT_ANNOUNCEMENT: Announcement | undefined = undefined

@Injectable({ providedIn: 'root' })
export class AnnouncementService {
  private storeService = inject(StoreService)
  @select(['account'])
  private account$!: Observable<AccountBM>

  @select(['userFertility', 'pregnantNow'])
  public pregnantNow$!: Observable<boolean>

  @select(['userDevice', 'appVer'])
  private appVer$!: Observable<string>

  public announcement$ =
    (CURRENT_ANNOUNCEMENT &&
      this.account$.pipe(
        combineLatestWith(
          this.storeService.select<boolean | undefined>(
            s =>
              !!s.userSettings[
                `announcementRead--${CURRENT_ANNOUNCEMENT.id}` as keyof UserSettings
              ],
          ),
          this.appVer$,
        ),
        map(([{ completeDate, demoMode }, announcementRead]) => {
          if (!completeDate) return false
          if (announcementRead) return false
          if (demoMode) return false

          return CURRENT_ANNOUNCEMENT
        }),
      )) ||
    new BehaviorSubject(false)
}
