import { Injectable } from '@angular/core'
import { api } from '@app/srv/api.service'
import { getState } from '@app/srv/store.service'
import {
  AppInitInput,
  BackendResponseFM,
  BackendResponseFMResp,
  PostInitInput,
} from '@naturalcycles/shared'
import { postInitDone, userDeviceDone } from './milestones'

@Injectable({ providedIn: 'root' })
export class AppService {
  async appInit(runAlgo = false, appStart = false): Promise<BackendResponseFM> {
    // kept as "post" (not "put") to be able to qualify as CORS "Simple request" https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS#Simple_requests
    // UPD: nope. Because we have custom headers - it cannot qualify as "simple request". Hence the `put`
    return await api
      .put<BackendResponseFMResp>('app/appInit', {
        json: {
          appStart,
          runAlgo,
        } satisfies AppInitInput,
      })
      .then(r => r?.backendResponse)
  }

  async postInit(): Promise<void> {
    await userDeviceDone

    const { userDevice, glossary, guides, quizzes, sessionId, partnerAccount } = getState()

    if (partnerAccount) {
      return postInitDone.resolve()
    }

    if (sessionId) {
      await api.put<void>('app/postInit', {
        json: {
          userDevice,
          glossaryHash: glossary?.glossaryHash,
          guideHash: guides?.guideHash,
          quizHash: quizzes?.quizHash,
        } satisfies PostInitInput,
      })
    }

    postInitDone.resolve()
  }
}
