import { AppAction } from '@app/cnst/action.cnst'
import { ROUTES } from '@app/cnst/nav.cnst'
import { Priority } from '@app/srv/popup.controller'
import { SnackbarOptions } from '@app/srv/snackbar.service'
import { GuideId } from '@naturalcycles/shared'

const DEVIATION_SLEEP: Readonly<SnackbarOptions> = {
  id: 'deviation-sleep',
  message: 'snackbar-deviated-temperature-sleep',
  button: {
    label: 'learn-more',
    action: {
      type: AppAction.INTERNAL_LINK,
      path: `${ROUTES.Glossary}/deviatedTempSleep`,
    },
  },
  stateProperty: 'deviationSleepSnackbarShown',
  priority: Priority.HIGH,
}

const DEVIATION_ALCOHOL: Readonly<SnackbarOptions> = {
  id: 'deviation-alcohol',
  message: 'snackbar-deviated-temperature',
  button: {
    label: 'learn-why',
    action: {
      type: AppAction.INTERNAL_LINK,
      path: `${ROUTES.Glossary}/deviatedTempHungover`,
    },
  },
  stateProperty: 'deviationAlcoholSnackbarShown',
  priority: Priority.HIGH,
}

const DEVIATION_SICK: Readonly<SnackbarOptions> = {
  id: 'deviation-sick',
  message: 'snackbar-deviated-temperature',
  button: {
    label: 'learn-why',
    action: {
      type: AppAction.INTERNAL_LINK,
      path: `${ROUTES.Glossary}/deviatedTempMedication`,
    },
  },
  stateProperty: 'deviationSickSnackbarShown',
  priority: Priority.HIGH,
}

const DEVIATION_ALGO: Readonly<SnackbarOptions> = {
  id: 'deviation-algo',
  message: 'snackbar-deviated-temperature',
  button: {
    label: 'learn-why',
    action: {
      type: AppAction.INTERNAL_LINK,
      path: `${ROUTES.Glossary}/deviatedTempAlgo`,
    },
  },
  priority: Priority.HIGH,
}

const SPOTTING: Readonly<SnackbarOptions> = {
  id: 'spotting',
  message: 'snackbar-spotting',
  button: {
    label: 'learn-more',
    action: {
      type: AppAction.INTERNAL_LINK,
      path: `${ROUTES.Glossary}/spotting`,
    },
  },
  stateProperty: 'spottingSnackbarShown',
  priority: Priority.HIGH,
}

const SPOTTING_PREGNANT: Readonly<SnackbarOptions> = {
  id: 'spotting-pregnant',
  message: 'snackbar-spotting-pregnant',
  button: {
    label: 'learn-more',
    action: {
      type: AppAction.INTERNAL_LINK,
      path: `${ROUTES.Glossary}/spottingFollow`,
    },
  },
  stateProperty: 'spottingPregnantSnackbarShown',
  priority: Priority.HIGH,
}

const WITHDRAWAL_BLEED: Readonly<SnackbarOptions> = {
  id: 'withdrawal-bleed',
  message: 'snackbar-withdrawal-bleed',
  button: {
    label: 'learn-more',
    action: {
      type: AppAction.INTERNAL_LINK,
      path: `${ROUTES.Glossary}/withdrawalBleed`,
    },
  },
  stateProperty: 'withdrawalBleedSnackbarShown',
  priority: Priority.HIGH,
  duration: 7000,
}

const MISCARRIAGE_BLEED: Readonly<SnackbarOptions> = {
  id: 'miscarriage-bleed',
  message: 'snackbar-miscarriage-bleed',
  button: {
    label: 'learn-more',
    action: {
      type: AppAction.INTERNAL_LINK,
      path: `${ROUTES.Glossary}/miscarriageBleed`,
    },
  },
  stateProperty: 'miscarriageBleedSnackbarShown',
  priority: Priority.HIGH,
  duration: 7000,
}

const LH_TEST: Readonly<SnackbarOptions> = {
  id: 'lh-test',
  message: 'snackbar-test-positive',
  button: {
    label: 'txt-read-about-lh',
    action: {
      type: AppAction.INTERNAL_LINK,
      path: `${ROUTES.Guide}/${GuideId.LH}`,
    },
  },
  stateProperty: 'lhTestSnackbarShown',
  priority: Priority.HIGH,
}

const PREG_TEST: Readonly<SnackbarOptions> = {
  id: 'preg-test',
  message: 'snackbar-preg-test',
  button: {
    label: 'learn-more',
    action: {
      type: AppAction.INTERNAL_LINK,
      path: `${ROUTES.Glossary}/pregTest`,
    },
  },
  stateProperty: 'pregTestSnackbarShown',
  priority: Priority.HIGH,
}

const SEX_PROTECTED: Readonly<SnackbarOptions> = {
  id: 'sex-protected',
  message: 'snackbar-sex-protected',
  button: {
    label: 'learn-more',
    action: {
      type: AppAction.INTERNAL_LINK,
      path: `${ROUTES.Glossary}/sexProtected`,
    },
  },
  stateProperty: 'sexProtectedSnackbarShown',
  priority: Priority.HIGH,
}

const SEX_UNPROTECTED: Readonly<SnackbarOptions> = {
  id: 'sex-unprotected',
  message: 'snackbar-sex-unprotected',
  button: {
    label: 'learn-more',
    action: {
      type: AppAction.INTERNAL_LINK,
      path: `${ROUTES.Glossary}/sexUnprotected`,
    },
  },
  stateProperty: 'sexUnprotectedSnackbarShown',
  priority: Priority.HIGH,
}

const INCOMPLETE_TEMP: Readonly<SnackbarOptions> = {
  id: 'incomplete-temp',
  message: 'snackbar-incomplete-temp',
  color: 'warning',
  textColor: 'dark',
  priority: Priority.HIGH,
}

const GRAPH_OPEN: Readonly<SnackbarOptions> = {
  id: 'graph-open',
  message: 'txt-graph-cant-open',
  duration: 2000,
}

const GRAPH_COMPARE: Readonly<SnackbarOptions> = {
  id: 'graph-compare',
  message: 'txt-graph-cant-compare',
  priority: Priority.HIGH,
}

const REFER_FRIEND: Readonly<SnackbarOptions> = {
  id: 'refer-friend',
  message: 'link-copy',
  duration: 800,
  priority: Priority.IMMEDIATE, // breaks the rules :(
}

const SUCCESS: Readonly<SnackbarOptions> = {
  id: 'success',
  message: 'msg-success',
  duration: 1000,
}

const RESET_PASSWORD_EMAIL: Readonly<SnackbarOptions> = {
  id: 'reset-password-email',
  message: 'reset-password-email-sent',
}

const AUTH_WARN_EMAIL: Readonly<SnackbarOptions> = {
  id: 'auth-warn-email',
  message: 'auth-warn-email',
  duration: 2000,
}

const AUTH_WARN_PASSWORD: Readonly<SnackbarOptions> = {
  id: 'auth-warn-password',
  message: 'auth-warn-password',
  duration: 2000,
}

const CERVICAL_MUCUS: Readonly<SnackbarOptions> = {
  id: 'cervical-mucus',
  message: 'snackbar-cervical-mucus',
  button: {
    label: 'learn-more',
    action: {
      type: AppAction.INTERNAL_LINK,
      path: `${ROUTES.Guide}/${GuideId.CERVICAL_MUCUS}`,
    },
  },
  stateProperty: 'cervicalMucusSnackbarShown',
  priority: Priority.HIGH,
}

const CERVICAL_MUCUS_PREGNANT: Readonly<SnackbarOptions> = {
  id: 'cervical-mucus-pregnant',
  message: 'snackbar-cervical-mucus-follow',
  button: {
    label: 'learn-more',
    action: {
      type: AppAction.INTERNAL_LINK,
      path: `${ROUTES.Guide}/${GuideId.CERVICAL_MUCUS_PREGNANT}`,
    },
  },
  stateProperty: 'cervicalMucusPregnantSnackbarShown',
  priority: Priority.HIGH,
}

const OURA_SYNC_DELAY: Readonly<SnackbarOptions> = {
  id: 'oura-sync-delay',
  message: 'oura-snackbar-sync-delay',
  button: {
    label: 'learn-more',
    action: {
      type: AppAction.INFO_MODAL,
      path: `tempDataState--NOT_SYNCED-`,
    },
  },
  stateProperty: 'ouraSyncDelaySnackbarShown',
  delay: 2000,
  priority: Priority.HIGH,
}

const OURA_EXCLUDED_ALGO: Readonly<SnackbarOptions> = {
  id: 'oura-excluded-algo',
  message: 'oura-snackbar-excluded-algo',
  button: {
    label: 'learn-more',
    action: {
      type: AppAction.INTERNAL_LINK,
      path: `${ROUTES.Glossary}/deviatedTempAlgo`,
    },
  },
  stateProperty: 'deviationAlgoSnackbarShown',
  delay: 2000,
  priority: Priority.HIGH,
}

const OURA_EXCLUDED_SLEEP: Readonly<SnackbarOptions> = {
  id: 'oura-excluded-sleep',
  message: 'oura-snackbar-excluded-algo',
  button: {
    label: 'learn-more',
    action: {
      type: AppAction.INFO_MODAL,
      path: `tempDataState--EXCLUDED_SLEEP-`,
    },
  },
  stateProperty: 'ouraExcludedSleepSnackbarShown',
  delay: 2000,
  priority: Priority.HIGH,
}

const OURA_ADJUSTED_TEMP: Readonly<SnackbarOptions> = {
  id: 'oura-adjusted-temp',
  message: 'oura-snackbar-adjusted-temp',
  button: {
    label: 'learn-more',
    action: {
      type: AppAction.INFO_MODAL,
      path: `tempDataState--ADJUSTED_TEMPERATURE-`,
    },
  },
  stateProperty: 'ouraAdjustedTempSnackbarShown',
  delay: 2000,
  priority: Priority.HIGH,
}

const OURA_INCOMPLETE_DATA: Readonly<SnackbarOptions> = {
  id: 'oura-incomplete-data',
  message: 'oura-snackbar-incomplete-data',
  button: {
    label: 'learn-more',
    action: {
      type: AppAction.INFO_MODAL,
      path: `tempDataState--INCOMPLETE_DATA-`,
    },
  },
  stateProperty: 'ouraIncompleteDataSnackbarShown',
  delay: 2000,
  priority: Priority.HIGH,
}

const OURA_SHORT_SLEEP: Readonly<SnackbarOptions> = {
  id: 'oura-short-sleep',
  message: 'oura-snackbar-short-sleep',
  button: {
    label: 'learn-more',
    action: {
      type: AppAction.INFO_MODAL,
      path: `tempDataState--SHORT_SLEEP-`,
    },
  },
  stateProperty: 'ouraShortSleepSnackbarShown',
  delay: 2000,
  priority: Priority.HIGH,
}

const UEBE_BLUETOOTH_OFF: Readonly<SnackbarOptions> = {
  id: 'uebe-bluetooth-off',
  message: 'uebe-bluetooth-off',
  priority: Priority.VERY_HIGH,
}

const INVALID_EMAIL: Readonly<SnackbarOptions> = {
  id: 'invalid-email',
  message: 'txt-email-invalid-warning',
  color: 'warning',
  textColor: 'dark',
  priority: Priority.VERY_HIGH,
}

const UEBE_TEMPERATURE_SYNCED: Readonly<SnackbarOptions> = {
  id: 'uebe-temperature-synced',
  message: 'uebe-temperature-synced-msg--1', // default message
  button: {
    label: 'txt-close',
    role: 'cancel',
  },
  priority: Priority.VERY_HIGH,
}

const BLUETOOTH_SETTINGS_SAVED: Readonly<SnackbarOptions> = {
  id: 'bluetooth-settings-saved',
  message: 'txt-settings-saved',
  duration: 2000,
  cssClass: ['snackbar--noFooter'],
}

const LH_SCANNER_REPORT: Readonly<SnackbarOptions> = {
  id: 'lhScanner-report',
  message: 'txt-thank-you-report',
  duration: 2000,
  priority: Priority.IMMEDIATE,
}

const CRITICAL_FIRMWARE_UPDATE: Readonly<SnackbarOptions> = {
  id: 'critical-firmware-update',
  message: 'snackbar-critical-firmware-update',
  button: {
    label: 'txt-update',
    action: {
      type: AppAction.INTERNAL_LINK,
      path: ROUTES.FirmwareUpdatePage,
    },
  },
  priority: Priority.HIGH,
}

const PARTNER_INVITE_RESENT: Readonly<SnackbarOptions> = {
  id: 'partner-invite-resent',
  message: 'settings-partner-invite-resent',
  priority: Priority.IMMEDIATE,
}

const PARTNER_REMOVED: Readonly<SnackbarOptions> = {
  id: 'partner-removed',
  message: 'settings-partner-removed',
  priority: Priority.IMMEDIATE,
  duration: 2000,
}

export const SNACKBAR = {
  LH_TEST,
  PREG_TEST,
  SPOTTING,
  SPOTTING_PREGNANT,
  WITHDRAWAL_BLEED,
  MISCARRIAGE_BLEED,
  DEVIATION_SLEEP,
  DEVIATION_SICK,
  DEVIATION_ALCOHOL,
  DEVIATION_ALGO,
  INCOMPLETE_TEMP,
  REFER_FRIEND,
  SUCCESS,
  GRAPH_OPEN,
  GRAPH_COMPARE,
  RESET_PASSWORD_EMAIL,
  AUTH_WARN_EMAIL,
  AUTH_WARN_PASSWORD,
  SEX_PROTECTED,
  SEX_UNPROTECTED,
  CERVICAL_MUCUS,
  CERVICAL_MUCUS_PREGNANT,
  OURA_SYNC_DELAY,
  OURA_EXCLUDED_ALGO,
  OURA_EXCLUDED_SLEEP,
  OURA_ADJUSTED_TEMP,
  OURA_INCOMPLETE_DATA,
  OURA_SHORT_SLEEP,
  UEBE_BLUETOOTH_OFF,
  INVALID_EMAIL,
  UEBE_TEMPERATURE_SYNCED,
  BLUETOOTH_SETTINGS_SAVED,
  LH_SCANNER_REPORT,
  CRITICAL_FIRMWARE_UPDATE,
  PARTNER_INVITE_RESENT,
  PARTNER_REMOVED,
}
