import { ChangeDetectionStrategy, Component, inject, Input, output } from '@angular/core'
import { ICON } from '@app/cnst/icons.cnst'
import { InfoModal } from '@app/modals/info/info.modal'
import { MarkdownPipe } from '@app/pipes/markdown.pipe'
import { StripTagsPipe } from '@app/pipes/strip-tags.pipe'
import { PopupController, Priority } from '@app/srv/popup.controller'
import { IonButton, IonIcon, IonLabel } from '@ionic/angular/standalone'
import { StringMap } from '@naturalcycles/js-lib'

@Component({
  selector: 'app-info-button',
  templateUrl: './info-button.component.html',
  styleUrls: ['./info-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [InfoModal, MarkdownPipe, StripTagsPipe, IonButton, IonIcon, IonLabel],
})
export class InfoButtonComponent {
  private popupController = inject(PopupController)

  @Input()
  protected buttonTitle?: string

  @Input()
  protected title?: string

  @Input()
  protected body?: string

  @Input()
  protected html?: string

  @Input()
  protected ctaTitle?: string

  @Input()
  protected isLogoDisplayed?: boolean

  @Input()
  protected infoBoxBody?: string

  @Input()
  protected iconColor = 'text'

  @Input()
  protected ariaLabel?: string

  @Input()
  protected small?: boolean

  protected infoModalOpened = output<void>()

  protected infoModalDismissed = output<void>()

  protected ICON = ICON

  public async openInfoModal(event?: Event, overrideProps?: StringMap): Promise<void> {
    if (!this.title && !this.body && !this.html) return

    this.infoModalOpened.emit()

    event?.stopPropagation()

    const modal = await this.popupController.presentModal(
      {
        component: InfoModal,
        componentProps: {
          title: this.title,
          body: this.body,
          html: this.html,
          ctaTitle: this.ctaTitle,
          isLogoDisplayed: this.isLogoDisplayed,
          infoBoxBody: this.infoBoxBody,
          ...overrideProps,
        },
      },
      `modal-info-${this.title}`,
      Priority.IMMEDIATE,
    )

    await modal.onDidDismiss()

    this.infoModalDismissed.emit()
  }
}
