import { Injectable } from '@angular/core'
import { NavigationParams } from '@app/cnst/nav.params.cnst'
import { PageGuard } from '@app/guards/page.guard'
import { getNavigationState } from '@app/srv/nav.service'

@Injectable({
  providedIn: 'root',
})
export class AuthPageGuard extends PageGuard {
  className = 'AuthPageGuard'

  get requirements(): boolean[] {
    const requirements = [!this.hasSession(), this._hasAuthMode()]

    return requirements
  }

  _hasAuthMode(): boolean {
    return !!getNavigationState()[NavigationParams.AUTH_PAGE_MODE]
  }
}
