<ion-header>
  <ion-toolbar mode="ios" color="transparent">
    <ion-buttons slot="start">
      <ion-button mode="md" (click)="dismissModal()" uid="confettiModal__close__button">
        <ion-icon slot="icon-only" name="close" />
      </ion-button>
    </ion-buttons>

    <ion-title>
      <ion-icon class="confettiModal__logo" [src]="ICON.NC_LOGO" color="logo" />
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-text-center">
  <div class="confettiModal__content">
    @if (image) {
      <ion-img
        class="confettiModal__image"
        [src]="image"
        [class.confettiModal__image--round]="roundImage"
      />
    }

    <div class="ion-padding-horizontal">
      @if (title) {
        <h1 class="ion-margin-bottom">{{ title | tr }}</h1>
      }

      @if (body) {
        <p class="ion-padding-horizontal">{{ body | tr }}</p>
      }
    </div>
  </div>
</ion-content>

<ion-footer>
  @for (button of ctas; track button) {
    <ion-button
      [attr.uid]="button.uid"
      expand="block"
      shape="round"
      [fill]="button.outline ? 'outline' : 'solid'"
      (click)="dismissModal(button.uid)"
    >
      <ion-label>{{ button.title | tr }}</ion-label>
    </ion-button>
  }
</ion-footer>
