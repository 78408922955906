import type { Storage3Adapter } from '@app/srv/storage3/storage3.service'
import { logUtil } from '@app/util/log.util'
import { Directory, Encoding, Filesystem } from '@capacitor/filesystem'
import { ErrorMode, pMap } from '@naturalcycles/js-lib'

const directory = Directory.Data
const encoding = Encoding.UTF8

export class Storage3CordovaFileAdapter implements Storage3Adapter {
  async getItem(path: string): Promise<string | undefined> {
    try {
      const { files } = await Filesystem.readdir({
        path: '',
        directory,
      })

      // no such file
      if (!files.some(f => f.name === path)) return

      const { data } = await Filesystem.readFile({
        path,
        directory,
        encoding,
      })

      return data as string
    } catch (err) {
      logUtil.log('Storage3CordovaFileAdapter')
      logUtil.error(err)
    }
  }

  async setItem(path: string, data: string): Promise<void> {
    await Filesystem.writeFile({
      path,
      data,
      directory,
      encoding,
    })
  }

  async removeItem(path: string): Promise<void> {
    try {
      await Filesystem.deleteFile({
        path,
        directory,
      })
    } catch (err) {
      logUtil.log('Storage3CordovaFileAdapter')
      logUtil.error(err)
    }
  }

  async keys(): Promise<string[]> {
    const { files } = await Filesystem.readdir({
      path: '',
      directory,
    })

    return files.map(f => f.name)
  }

  async clear(keepKeys: string[] = []): Promise<void> {
    const keys = await this.keys()

    await pMap(
      keys.filter(k => !keepKeys.includes(k)),
      async path => {
        await Filesystem.deleteFile({
          path,
          directory,
        })
      },
      { errorMode: ErrorMode.SUPPRESS },
    )
  }
}
