import { Injectable } from '@angular/core'
import { PreloadingStrategy, Route } from '@angular/router'
import { pDelay } from '@naturalcycles/js-lib'
import { Observable, of } from 'rxjs'

const manualPreloadDisabled = false
// const preloadAll = false
//
// const preloadWhitelist = new Set<string>([
//   // 'home',
//   // 'calendar',
//   // 'statistics',
//   // 'messages',
//   // 'more',
//   // 'profile',
// ])

// const defaultPreloadDelay = 15000

/**
 * Same as NoPreloadingStrategy
 * unless Route provides data.preload = true - then that route will be preloaded.
 *
 * Based on:
 * https://ionicframework.com/blog/how-to-lazy-load-in-ionic-angular/
 * https://coryrylan.com/blog/custom-preloading-and-lazy-loading-strategies-with-angular
 * https://medium.com/@adrianfaciu/custom-preloading-strategy-for-angular-modules-b3b5c873681a
 */
@Injectable({ providedIn: 'root' })
export class AppPreloadingStrategy implements PreloadingStrategy {
  routes: Record<string, { route: Route; load: () => Observable<any> }> = {}

  preload(route: Route, load: () => Observable<any>): Observable<any> {
    const { path } = route
    // log(`[prf] [preload] ${route.path} check`, {route})

    this.routes[path!] = {
      route,
      load,
    }
    return of(null)
  }

  async preloadRoutes(paths: string[], delay = 0): Promise<void> {
    await pDelay(delay)
    paths.forEach(path => this.preloadRoute(path))
  }

  async preloadRoute(path: string, delay = 0): Promise<boolean> {
    const route = this.routes[path]

    if (route && !manualPreloadDisabled) {
      await pDelay(delay)

      route.load()
      // logUtil.log(`[prf] [preload] ${path} YES (preloadRoute)`)

      return true
    }

    return false
  }
}
