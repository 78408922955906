import { COLOR } from '@app/cnst/color.cnst'
import { setTimeoutNoZone } from '@app/util/zone.util'
import type { Origin } from 'canvas-confetti'
import { create, CreateTypes } from 'canvas-confetti'

class ConfettiService {
  private confetti?: CreateTypes

  // For more options see https://catdad.github.io/canvas-confetti/
  public async addConfetti(
    container: HTMLElement = document.body,
    origin: Origin = { x: 0.5, y: 0.75 },
    delay = 150,
    particleCount = 200,
  ): Promise<void> {
    const canvas = document.createElement('canvas')
    canvas.classList.add('confettiCanvas')
    container.append(canvas)

    setTimeoutNoZone(async () => {
      this.confetti = create(canvas, {
        resize: true,
        useWorker: true,
        disableForReducedMotion: true,
      })

      await this.confetti({
        particleCount,
        ticks: 250,
        origin,
        colors: [COLOR.PINK, COLOR.PURPLE, COLOR.MONA_LISA, COLOR.CHAMPAGNE, COLOR.MEDIUM],
      })

      canvas.remove()
      this.reset()
    }, delay)
  }

  public reset(): void {
    this.confetti?.reset()
  }
}

export const confettiService = new ConfettiService()
