import { api } from '@app/srv/api.service'
import { ProductKey } from '@naturalcycles/shared'
import { env } from '@src/environments/environment'
import { OptimoveWebSDK, OptimoveWebSDKCfg } from 'optimove-websdk'

export const optimoveWebSdk = new OptimoveWebSDK({
  ...env.optimoveCfg,
  tenantToken: '22931d6ebcd5134152d42c8bf624cc6cddf25ecad59894bfd9ddddf7f52aee51', // same for all envs
} as OptimoveWebSDKCfg)

interface OptiPushData {
  optimoveCampaignId: string
  optimoveTemplateId: string
}

interface OptimovePushOpenInput {
  campaignId: number
  templateId: number
}

export interface RegistrationData {
  plan?: ProductKey
  hwId?: string // We send human readable hwId to Optimove
  goal?: string // We send human readable goal to Optimove
  ownsHw?: boolean
}

class OptimoveService {
  async trackPushOpen(data: any): Promise<void> {
    const { optimoveCampaignId, optimoveTemplateId } = (data || {}) as OptiPushData
    if (!optimoveCampaignId || !optimoveTemplateId) return // not an OptiPush

    await api.put(`optimove/pushOpen`, {
      json: {
        campaignId: Number(optimoveCampaignId),
        templateId: Number(optimoveTemplateId),
      } satisfies OptimovePushOpenInput,
    })
  }
}

export const optimoveService = new OptimoveService()
