import { logUtil } from '@app/util/log.util'
import { backendResponseReviver } from '@naturalcycles/shared'

/**
 * Features on top of bare window.localStorage:
 *
 * 1. Resilient to errors (returns undefined). Applicable to old browsers, Safari Private mode (LS doesn't fully work there).
 * 2. Convenience methods to get/set JSON.
 * 3. Uses backendResponseReviver (revives Price)
 */
class StorageService {
  constructor() {
    let g: any

    try {
      g = window
    } catch {}

    try {
      g = global
    } catch {}

    try {
      if ('localStorage' in g && g.localStorage !== undefined) {
        this.ls = g.localStorage
        this.ls.setItem('testKey', '1')
        this.ls.removeItem('testKey')
      }
    } catch (err) {
      logUtil.log('error ls storage.service')
      logUtil.error(err)
    }

    if (!this.ls) logUtil.warn('localStorage is not supported!')
  }

  private readonly ls!: Storage

  has(k: string): boolean {
    return !!this.get(k)
  }

  get(k: string): string | undefined {
    if (!this.ls) return undefined
    return this.ls.getItem(k) || undefined
  }

  getJson<T>(k: string): T | undefined {
    const v = this.get(k)
    if (!v) return undefined
    return JSON.parse(v, backendResponseReviver)
  }

  put(k: string, v: string): string | undefined {
    if (!this.ls) return undefined

    try {
      this.ls.setItem(k, v)
      return v
    } catch (err) {
      logUtil.log(`localStorage error (k=${k}, v=${v})`)
      logUtil.error(err)
    }
  }

  putJson(k: string, v: any): void {
    this.put(k, JSON.stringify(v))
  }

  remove(k: string): string | undefined {
    if (!this.ls) return undefined

    this.ls.removeItem(k)
    return k
  }

  clear(): boolean {
    if (!this.ls) return false

    this.ls.clear()
    return true
  }

  keys(): string[] {
    if (!this.ls) return []

    try {
      return Object.keys(this.ls)
    } catch (err) {
      logUtil.log('storage.service error')
      logUtil.error(err)

      return []
    }
  }
}

export const storageService = new StorageService()
