<ion-button
  mode="md"
  class="infoButton infoButton--block"
  [class.infoButton--block]="!!buttonTitle"
  [class.infoButton--icononly]="!buttonTitle"
  [class.infoButton--small]="small"
  expand="block"
  fill="clear"
  shape="round"
  slot="start"
  [color]="iconColor"
  [attr.aria-label]="ariaLabel"
  (click)="openInfoModal($event)"
>
  <ion-icon [src]="ICON.INFO" [slot]="!!buttonTitle ? '' : 'icon-only'" />
  <ion-label> {{ buttonTitle | tmd | stripTags }}</ion-label>
</ion-button>
