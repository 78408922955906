import { NavigationParams } from '@app/cnst/nav.params.cnst'
import { BaseGuardService } from '@app/srv/base.guard.service'
import { getNavigationState } from '@app/srv/nav.service'
import { RecentlyUsedHormones } from '@naturalcycles/shared'
import { measuringDeviceList } from '../util/product.util'
import { getState } from './store.service'

export class RouteGuardService extends BaseGuardService {
  public hasSession(): boolean {
    return !!getState().sessionId
  }

  public hasAccountCompleteDate(): boolean {
    return !!getState().account.completeDate
  }

  public isInDemoMode(): boolean {
    return !!getState().account.demoMode
  }

  public hasUserFertilityTodayDate(): boolean {
    return !!getState().userFertility.todayDate
  }

  public cartTotalPriceNoAmount(): boolean {
    const { totalPrice } = getState().cart
    return !!totalPrice && !totalPrice.amount
  }

  public cartHasNothingLeftToPay(): boolean {
    const { cart } = getState()
    return !cart.totalPrice?.amount && !cart.totalPriceWithoutDiscount?.amount
  }

  public isDemoModePlan(): boolean {
    const params = getNavigationState() || {}
    return !!params[NavigationParams.IS_DEMO_MODE_PLAN]
  }

  public isHBCDemo(): boolean {
    const { account } = getState()
    return (
      !!account.demoMode &&
      account.onboardingData?.recentlyUsedHormones === RecentlyUsedHormones.YES_STILL
    )
  }

  public hasPartnerAccount(): boolean {
    return !!getState().partnerAccount
  }

  public hasAvailableDevices(): boolean {
    const availableDevices = getState().product.items.filter(item =>
      measuringDeviceList.includes(item.key),
    )
    return !!availableDevices.length
  }
}
