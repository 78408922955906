import { Injectable } from '@angular/core'
import { api } from '@app/srv/api.service'
import { storageService } from '@app/srv/storage.service'
import { select2 } from '@app/srv/store.service'
import { logUtil } from '@app/util/log.util'
import { _isObject, _objectEntries } from '@naturalcycles/js-lib'
import { Assignment, Experiment, ExperimentState, getBucket, NO_AB } from '@naturalcycles/shared'
import { firstValueFrom } from 'rxjs'

// For setting localStorage value to force an assignment
export const forceExperimentPrefix = 'EXPERIMENT_'

@Injectable({ providedIn: 'root' })
export class ExperimentService {
  private assignments$ = select2(s => s.experiment.assignments)

  public lastRequest?: Promise<ExperimentState>

  private filterAssignment(
    experiment: Experiment,
    assignment: Assignment | undefined,
  ): Assignment | undefined {
    const assignmentFromStorage = this.getAssignmentFromStorage(experiment)
    const forceAbKeys = storageService.get('forceAbKeys')

    if (assignmentFromStorage !== undefined && forceAbKeys === 'true') {
      return assignmentFromStorage
    }

    // Force an ABTest from the localstorage but after the conditions
    if (assignmentFromStorage !== undefined) {
      return assignmentFromStorage
    }

    return assignment
  }

  public async getAssignment(experiment: Experiment): Promise<Assignment | undefined> {
    const assignments = await firstValueFrom(this.assignments$)
    const assignment = assignments[experiment]
    if (assignment) {
      logUtil.log(`ExperimentService: Assignment to ${experiment} is ${getBucket(assignment)}`)
    }
    return this.filterAssignment(experiment, assignment)
  }

  public async logImpression(experiment: Experiment): Promise<void> {
    return await this.logEvent(experiment)
  }

  public async logImpressionFromUrl(url: string): Promise<void> {
    const assignments = await firstValueFrom(this.assignments$)
    _objectEntries(assignments).forEach(([experiment, assignment]) => {
      if (!_isObject(assignment)) {
        return
      }
      const impressionPage = assignment.data?.impressionPage
      if (!impressionPage) {
        return
      }
      const impressionPageRegex = new RegExp(impressionPage)
      if (impressionPageRegex.test(url)) {
        void this.logImpression(experiment as Experiment)
      }
    })
  }

  public async logEvent(experiment: Experiment, event?: string, payload?: string): Promise<void> {
    const assignment = await this.getAssignment(experiment)
    if (assignment === undefined) {
      return
    }

    let url = `experiments/${experiment}`

    if (event) {
      url = `${url}/${event}`
    }

    if (assignment) {
      return await api.post<void>(url, {
        json: {
          payload,
        },
      })
    }
    return
  }

  public getAssignmentFromStorage(experiment: Experiment): Assignment | undefined {
    // QA local storage values
    // removeAllAbtest = will return '' for all the ab test that are currently running
    const removeAllAbTest = storageService.get(NO_AB)
    if (removeAllAbTest === 'true') {
      return { bucket: '', data: null }
    }

    // Check if the assignment is set inside localStorage for testing reasons
    // If the assignment exist, we return the bucket from there
    const assignmentForTesting = storageService.get(experiment)
    let parsed: Assignment | undefined
    // The assignment could be a string, so JSON.parse will throw an error
    try {
      if (assignmentForTesting) {
        parsed = JSON.parse(assignmentForTesting)
      }
    } catch {
      parsed = { bucket: assignmentForTesting || '', data: null }
    }
    return parsed
  }
}
