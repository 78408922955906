<div class="modal">
  <h2 class="content__title">{{ 'aw-multiple-watches-detected-alert-title' | tr }}</h2>
  <p>{{ 'aw-multiple-watches-detected-alert-body' | tr }}</p>

  <app-radio-group [items]="deviceOptions" (valueChange)="selectPreferredHKDevice($event)" />

  <div class="modal__button">
    <ion-button
      fill="clear"
      slot="end"
      (click)="confirm()"
      [disabled]="selectedHKDeviceId === undefined"
    >
      {{ 'txt-done-only' | tr }}
    </ion-button>
  </div>
</div>
