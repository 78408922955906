import { Injectable } from '@angular/core'
import { PageGuard } from '../../guards/page.guard'

@Injectable({
  providedIn: 'root',
})
export class IntroPageGuard extends PageGuard {
  className = 'IntroPageGuard'

  get requirements(): boolean[] {
    const requirements = [!this.hasAccountId()]

    return requirements
  }
}
