<div class="notes__overlay">
  <h3 class="notes__title" tmd="txt-notes"></h3>

  <div #content class="notes__container">
    <ion-textarea
      uid="addData__notes__textArea"
      [rows]="2"
      autoGrow
      class="notes__input"
      autocapitalize="sentences"
      [maxlength]="4000"
      [value]="value"
      [debounce]="100"
      (ionChange)="onChange($any($event))"
      [readonly]="readonly"
    />
  </div>
</div>

<ion-footer class="notes__footer">
  <ion-toolbar color="transparent">
    <ion-buttons slot="end">
      <ion-button uid="addData__notes__dismiss" (click)="dismissModal()" color="primary">
        <ion-label tmd="txt-done-only" />
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
