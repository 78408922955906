import { clickUid } from '@app/perf/dom.util'
import { nextIdle } from '@app/perf/idle.util'
import { SELECTORS } from '@app/perf/selectors'

const TIMEOUT = 10_000

export async function scenario04Graph(): Promise<void> {
  await window.lagMon.run('graphOpenPreviousClose', async () => {
    await clickUid(SELECTORS.graphBtn)
    await nextIdle(undefined, TIMEOUT)
    await clickUid(SELECTORS.graphPreviousBtn)
    await nextIdle(1000, TIMEOUT)
    await clickUid(SELECTORS.graphCloseBtn)
    await nextIdle(undefined, TIMEOUT)
  })
}
