import { BackendResponseFM, ProductState } from '@naturalcycles/shared'
import { Action, Reducer } from 'redux'

const initialState: ProductState = {
  items: [],
}

const actions: any = {
  replaceProduct(
    _state: ProductState,
    action: { type: string; payload: ProductState },
  ): ProductState {
    return {
      ...action.payload,
    }
  },

  extendProduct(
    state: ProductState,
    action: { type: string; payload: ProductState },
  ): ProductState {
    return {
      ...state,
      ...action.payload,
    }
  },

  onBackendResponse(
    state: ProductState,
    action: { type: string; payload: BackendResponseFM },
  ): ProductState {
    if (action.payload.product) {
      return {
        ...action.payload.product,
      }
    }

    return state
  },
}

export const productReducer: Reducer<ProductState> = (
  // biome-ignore lint/style/useDefaultParameterLast: ok
  state = initialState,
  action: Action,
) => {
  if (actions[action.type]) return actions[action.type](state, action)
  return state
}
