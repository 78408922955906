import { inject, Injectable } from '@angular/core'
import { EVENT } from '@app/analytics/analytics.cnst'
import { AnalyticsService } from '@app/analytics/analytics.service'
import { COLOR } from '@app/cnst/color.cnst'
import { select2 } from '@app/srv/store.service'
import { App, AppLaunchUrl, URLOpenListenerEvent } from '@capacitor/app'
import { NavController } from '@ionic/angular/standalone'
import { _range, localDate } from '@naturalcycles/js-lib'
import { FertilityColor, WidgetDataForToday, WidgetDay } from '@naturalcycles/shared'
import { NCWidget } from '@src/typings/capacitor'
import { combineLatestWith, distinctUntilChanged, map } from 'rxjs/operators'
import { verticalSlideAnimation } from '../animations/vertical-slide'
import { ROUTES } from '../cnst/nav.cnst'
import { isAndroidApp } from '../cnst/userDevice.cnst'
import { WidgetDeeplinks } from '../cnst/widgets.cnst'
import { runInsideAngular } from './di.service'
import { tr } from './translation.util'
import { UFService } from './uf.service'

const COLOR_OUTDATED = COLOR.GRAY
const USER_LOGGED_OUT_STATUS = 'User logged out'

@Injectable({ providedIn: 'root' })
export class WidgetService {
  private ufService = inject(UFService)
  private analyticsService = inject(AnalyticsService)
  private navController = inject(NavController)
  private widgetData$ = select2(s => s.widgetData)
  private showPredictions$ = select2(s => s.userSettings.showPredictions)
  private clickedMensCardDate$ = select2(s => s.userSettings.clickedMensCardDate)

  public init(): void {
    if (!window.Capacitor.isNativePlatform()) return

    void App.getLaunchUrl().then(data => {
      this.handleDeeplink(data)
    })

    void App.addListener('appUrlOpen', (data: URLOpenListenerEvent) => this.handleDeeplink(data))

    const outdated: WidgetDataForToday = {
      label: tr('txt-today'),
      color: COLOR_OUTDATED as FertilityColor,
      status: tr('txt-widget-open-app'),
    }

    // outdated after midnight
    NCWidget.initWidget({
      input: {
        outdated,
      },
    })

    // update on the logout
    this.update(
      _range(isAndroidApp ? 5 : 3).map(() => ({
        color: COLOR_OUTDATED as FertilityColor,
        label: '',
      })),
      {
        color: COLOR_OUTDATED as FertilityColor,
        label: '',
        status: USER_LOGGED_OUT_STATUS,
      },
    )

    this.widgetData$
      .pipe(
        map(data => data),
        combineLatestWith(
          this.ufService.showFertilityStatus$.pipe(distinctUntilChanged()),
          this.ufService.todayEntry$,
          this.showPredictions$,
          this.clickedMensCardDate$,
        ),
      )
      .subscribe(
        ([data, showFertilityStatus, todayEntry, showPredictions, clickedMensCardDate]) => {
          if (!data) return

          const clickedMensCardDateToday = clickedMensCardDate === localDate.todayString()
          const today = clickedMensCardDateToday
            ? { ...data.today, buttonLabel: undefined }
            : data.today
          const predictions = data.predictions.slice(0, isAndroidApp ? 5 : 3)

          this.update(
            predictions,
            today,
            showFertilityStatus,
            !!todayEntry?.offline,
            showPredictions,
          )
        },
      )
  }

  public update(
    predictions: WidgetDay[],
    today: WidgetDataForToday,
    showFertilityStatus = true,
    offline = false,
    showPredictions = false,
  ): void {
    if (!window.Capacitor.isNativePlatform()) return

    if (!showFertilityStatus || offline || today.status === USER_LOGGED_OUT_STATUS) {
      predictions = predictions.map(() => {
        return {
          color: COLOR_OUTDATED as FertilityColor,
          label: '',
        }
      })

      today = {
        label: tr('txt-today'),
        color: COLOR_OUTDATED as FertilityColor,
        status: tr('txt-widget-open-app'),
      }
    }

    if (!showPredictions) {
      predictions = predictions.map(prediction => {
        return {
          ...prediction,
          color: COLOR_OUTDATED as FertilityColor,
        }
      })
    }

    NCWidget.update({
      input: {
        today,
        predictions,
      },
    })
  }

  private handleDeeplink(data?: AppLaunchUrl): void {
    if (!data) return

    if (data.url === WidgetDeeplinks.WIDGET_SMALL || data.url === WidgetDeeplinks.WIDGET_MEDIUM) {
      void this.analyticsService.trackEvent(EVENT.WIDGET_CLICK, {
        size: data.url,
      })
    }

    if (
      data.url === WidgetDeeplinks.MENS_CALENDAR_SMALL_WIDGET ||
      data.url === WidgetDeeplinks.MENS_CALENDAR_MEDIUM_WIDGET
    ) {
      void this.analyticsService.trackEvent(EVENT.MENS_BUTTON_WIDGET_CLICK, {
        size:
          data.url === WidgetDeeplinks.MENS_CALENDAR_SMALL_WIDGET
            ? WidgetDeeplinks.WIDGET_SMALL
            : WidgetDeeplinks.WIDGET_MEDIUM,
      })

      runInsideAngular(() => {
        void this.navController.navigateForward(ROUTES.MensCalendarPage, {
          animated: true,
          animation: verticalSlideAnimation,
        })
      })
    }
  }
}
