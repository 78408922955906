import { di } from '@app/srv/di.service'
import { StringMap } from '@naturalcycles/js-lib'
import { LANG_DEFAULT } from '@naturalcycles/shared'
import { TranslateService } from '@ngx-translate/core'

export function tr(key?: string, interpolateParams?: any): string {
  if (!key) return undefined as any
  return di.get(TranslateService).instant(key, interpolateParams)
}

export function trIfExists(key: string, interpolateParams?: StringMap<any>): string | undefined {
  const translateService = di.get(TranslateService)
  const exists = !!translateService.translations?.[LANG_DEFAULT]?.[key]

  if (!exists) return

  return tr(key, interpolateParams)
}
