import { HKDevice } from '@naturalcycles/shared'
import { Action, Reducer } from 'redux'

export interface HKDeviceFM extends HKDevice {
  isPreferred?: boolean
}

type State = HKDevice | null

const initialState: State = null

const actions: any = {
  setHKDevice(_state: State, action: { type: string; payload: HKDeviceFM }): State {
    return {
      ...action.payload,
    }
  },
}

// biome-ignore lint/style/useDefaultParameterLast: ok
export const hkDeviceReducer: Reducer<State> = (state = initialState, action: Action) => {
  if (actions[action.type]) return actions[action.type](state, action)
  return state
}
