import { clickUid } from '@app/perf/dom.util'
import { nextIdle } from '@app/perf/idle.util'
import { SELECTORS } from '@app/perf/selectors'

export async function scenario02CalendarTabs(): Promise<void> {
  await window.lagMon.run('todayToMonth', async () => {
    await clickUid(SELECTORS.monthSupertabBtn)
    await nextIdle()
  })

  await window.lagMon.run('monthToToday', async () => {
    await clickUid(SELECTORS.todaySupertabBtn)
    await nextIdle()
  })
}
