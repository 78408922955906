import { ErrorHandler, Injectable } from '@angular/core'
import { sentryService } from '@app/srv/sentry.service'
import { env } from '../environments/environment'

@Injectable({ providedIn: 'root' })
export class AppErrorHandler extends ErrorHandler {
  override handleError(err: any): void {
    // handle all "Uncaught (in promise):"
    // console.error(err)
    err = err.rejection || err

    sentryService.captureException(err)

    // Delegate to default Ionic's ErrorHandler
    super.handleError(err)

    if (env.alertOnJSError) {
      try {
        alert(JSON.stringify(err.message || err, undefined, 2))
      } catch {}
    }
  }
}
