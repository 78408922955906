import { localTime } from '@naturalcycles/js-lib'
import { UserDeviceFM } from '@naturalcycles/shared'
import { Action } from 'redux'

type State = UserDeviceFM

const initialState: State = {
  res: [window.screen.width, window.screen.height].join('x'),
  uuid: '',
  utcOffset: localTime.now().getUTCOffsetMinutes(),
}

const actions: any = {
  setUserDevice(state: State, action: { type: string; payload: UserDeviceFM }): State {
    return {
      ...state,
      ...action.payload,
    }
  },

  resetUserDevice(): State {
    return initialState
  },
}

// biome-ignore lint/style/useDefaultParameterLast: ok
export function userDeviceReducer(state = initialState, action: Action): State {
  if (actions[action.type]) return actions[action.type](state, action)
  return state
}
