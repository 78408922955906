import { pDefer } from '@naturalcycles/js-lib'

// 160ms corresponds to ~6fps (160 * 6 ~= 1000), so, it's considered "laggy" if it drops below that
// const LAG_THRESHOLD = 160
// const LAG_IDEAL = 16 // 16ms, which corresponds to the ideal world 60fps
// These thresholds are now aligned with Lighthouse/Google ones,
// everything above 50ms is considered a "long task"
const LAG_THRESHOLD = 50
const LAG_IDEAL = 50

/**
 * @param idleLength - It should be idle for AT LEAST this number of milliseconds before returning
 * @param timeout - To give up and return after this number of milliseconds regardless if it's idle or not
 */
export async function nextIdle(idleLength = 3000, timeout?: number): Promise<number> {
  const started = Date.now()
  let now = started
  let delta: number
  let lastNow = started
  let lastLag = started
  let totalLag = 0
  const defer = pDefer<number>()

  // Zone.js is configured to NOT patch RAF now
  requestAnimationFrame(animate)

  function animate(): void {
    now = Date.now()
    delta = now - lastNow

    if (delta > LAG_THRESHOLD) {
      // LAGGY
      totalLag += delta - LAG_IDEAL
      lastLag = now
      console.log(`[idle] lag ${delta}, total ${totalLag}`)
    } else {
      // NOT LAGGY

      if (now > lastLag + idleLength) {
        console.log(`[idle] return after ${now - started} ms (${totalLag} lag)`)
        defer.resolve(totalLag)
        return
      }
    }

    if (timeout && now > started + timeout) {
      console.log(`[idle] return after timeout ${timeout} ms (${totalLag} lag)`)
      defer.resolve(totalLag)
      return
    }

    lastNow = now

    requestAnimationFrame(animate)
  }

  return await defer
}

export function runOnIdle(job: (lag: number) => any, idleLength = 3000, timeout?: number): void {
  void nextIdle(idleLength, timeout).then(lag => job(lag))
}
