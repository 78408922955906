import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router'
import { PageGuard } from '@app/guards/page.guard'
import { appInitDone, bootstrapDone } from '@app/srv/milestones'

@Injectable({
  providedIn: 'root',
})
export class HomePageGuard extends PageGuard {
  className = 'HomePageGuard'

  override async canActivate(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean | UrlTree> {
    this.route = _route

    await bootstrapDone

    if (this.requirements.every(Boolean)) {
      return true
    }

    await appInitDone

    return await super.canActivate(_route, state)
  }

  get requirements(): boolean[] {
    const requirements = [
      !this.requiresAppConsent(),
      this.hasSession(),
      this.hasAccountCompleteDate(),
      this.hasUserFertilityTodayDate(),
      !this.hasPartnerAccount(),
    ]

    return requirements
  }
}
