import { inject, Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { AddDataSource } from '@app/cnst/add-data.cnst'
import { usesBluetoothDevice } from '@app/cnst/hardware.cnst'
import { ROUTES } from '@app/cnst/nav.cnst'
import { AddDataPageService } from '@app/pages/add-data/add-data.page.service'
import { select2 } from '@app/srv/store.service'
import { distinctUntilDeeplyChanged } from '@app/util/distinctUntilDeeplyChanged'
import { App } from '@capacitor/app'
import { HardwareId } from '@naturalcycles/shared'
import { BehaviorSubject, combineLatestWith, startWith, Subscription } from 'rxjs'
import { BluetoothService, BluetoothStatus } from './bluetooth.service'
import { EventService } from './event.service'
import { T3Service } from './t3.service'
import { UebeService } from './uebe.service'

@Injectable({ providedIn: 'root' })
export class BluetoothConnectService {
  private addDataPageService = inject(AddDataPageService)
  private bluetoothService = inject(BluetoothService)
  private eventService = inject(EventService)
  private t3Service = inject(T3Service)
  private uebeService = inject(UebeService)
  private router = inject(Router)

  private hwId$ = select2(s => s.account.hwId)
  private hardwareDeviceId$ = select2(s => s.hwDevice?.mac)
  private lastActive$ = select2(s => s.userSettings.lastActive)

  private subscriptions: Subscription[] = []

  private scanningScheduled$ = new BehaviorSubject<boolean>(false)

  public init(): void {
    this.hwId$
      .pipe(combineLatestWith(this.bluetoothService.inPairingFlow$))
      .subscribe(([hwId, inPairingFlow]) => {
        this.resetSubscriptions()

        // if we are in pairing flow we don't want to connect to the currently paired device
        if (inPairingFlow || !usesBluetoothDevice(hwId)) return

        this.setupSubscriptions()
      })
  }

  private setupSubscriptions(): void {
    this.subscriptions.push(
      this.hardwareDeviceId$
        .pipe(
          combineLatestWith(this.bluetoothService.bluetoothStatus$, this.lastActive$),
          distinctUntilDeeplyChanged(),
          combineLatestWith(this.eventService.onResume$.pipe(startWith(1))),
        )
        .subscribe(([[deviceId, status, _lastActive], _onResume]) => {
          if (!deviceId) return

          if (status === BluetoothStatus.FIRMWARE_UPDATE) {
            void this.bluetoothService.disconnect(deviceId)
            void this.bluetoothService.stopScan('firmware update')
            return
          }

          if (status === BluetoothStatus.DISCONNECTED && !this.scanningScheduled$.value) {
            this.scanningScheduled$.next(true)
            // wait before starting scanning again after disconnect
            setTimeout(async () => {
              this.scanningScheduled$.next(false)
              const { isActive } = await App.getState()
              if (!isActive) return
              void this.bluetoothService.scanAndConnect(deviceId)
            }, 2000)
          }
        }),

      this.eventService.onPause$
        .pipe(combineLatestWith(this.hardwareDeviceId$))
        .subscribe(([_onPause, deviceId]) => {
          if (!deviceId) return

          if (this.bluetoothService.bluetoothStatus$.value === BluetoothStatus.CONNECTED) {
            void this.bluetoothService.disconnect(deviceId)
          } else if (this.bluetoothService.bluetoothStatus$.value === BluetoothStatus.SCANNING) {
            void this.bluetoothService.stopScan('App pause')
          }
        }),

      // on connection
      this.bluetoothService.connected$
        .pipe(combineLatestWith(this.hardwareDeviceId$, this.hwId$))
        .subscribe(([connected, deviceId, hwId]) => {
          if (!connected || !deviceId || !hwId) return

          void this.onConnected(hwId, deviceId)
        }),

      // on temperatures
      this.bluetoothService.temperatures$.subscribe(temperatures => {
        if (!temperatures.length || this.shouldNotOpenAddData) return

        void this.addDataPageService.openAddData({
          source: AddDataSource.BLUETOOTH,
          temperatures,
          skipPreview: true,
        })
      }),
    )
  }

  private resetSubscriptions(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe())
    this.subscriptions.length = 0 // this clears the array
  }

  private get shouldNotGetTemperatures(): boolean {
    const route = this.router.url

    if ([ROUTES.T3WalkthroughPage, ROUTES.UebeWalkthroughPage].includes(route)) return false
    if (route.startsWith(ROUTES.MyDevicePage)) return true

    return false
  }

  private get shouldNotOpenAddData(): boolean {
    const route = this.router.url

    if (
      // Places where we expect the user to measure and sync temperatures that should not go to Add data
      [ROUTES.CalibratePage, ROUTES.T3WalkthroughPage, ROUTES.UebeWalkthroughPage].includes(route)
    ) {
      return true
    }

    return false
  }

  private onConnected(hwId: HardwareId, deviceId: string): void {
    if (this.shouldNotGetTemperatures) {
      // we need to start notifications for uebe, otherwise the thermometer will go er.Bt
      if (hwId === HardwareId.UEBE_THERMOMETER) {
        void this.uebeService.startNotifications(deviceId, false)
      } else if (hwId === HardwareId.T3_THERMOMETER) {
        void this.t3Service.getDeviceStatus(deviceId)
      }
      return
    }

    void this.getTemperatureData(hwId, deviceId)
  }

  private async getTemperatureData(hwId: HardwareId, deviceId: string): Promise<void> {
    if (hwId === HardwareId.UEBE_THERMOMETER) {
      await this.uebeService.startNotifications(deviceId, true)

      return
    }

    if (hwId === HardwareId.T3_THERMOMETER) {
      await this.t3Service.getTemperatureLogs(deviceId)

      return
    }
  }
}
