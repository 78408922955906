import { Injectable } from '@angular/core'
import { Thumbnail } from '@app/cmp/thumb-slides/thumb-slides.component'
import { usesConnectedThermometer } from '@app/cnst/hardware.cnst'
import { select2 } from '@app/srv/store.service'
import { AccountTM, Goal, Guide, GuideId, GuideStyle } from '@naturalcycles/shared'
import { combineLatestWith, map } from 'rxjs/operators'
import { tr } from './translation.util'

export const APP_TOUR_ID = 'APP_TOUR'

@Injectable({ providedIn: 'root' })
export class GuideService {
  private hardware$ = select2(s => s.hwDevice)

  private hwId$ = select2(s => s.account.hwId)

  private account$ = select2(s => s.account)

  private educationalContent$ = select2(s => s.notifications.settings?.educationalContent)

  private guides$ = select2(s => s.guides.items).pipe(
    combineLatestWith(this.hardware$, this.hwId$),
    map(([guides, hardware, hwId]) => {
      return [...guides].map(guide => {
        if (guide.id !== GuideId.UEBE && guide.id !== GuideId.T3) return guide

        // mark Uebe and T3 guide as unread only if the user has paired a thermometer
        return {
          ...guide,
          read: !(hardware?.mac && usesConnectedThermometer(hwId)) || guide.read,
        }
      })
    }),
  )

  public guideThumbnails$ = this.account$.pipe(
    combineLatestWith(this.guides$, this.educationalContent$),
    map(([account, guides, educationalContent]) =>
      this.getGuideThumbnails(account, guides, educationalContent),
    ),
  )

  private getGuideThumbnails(
    account: AccountTM,
    guides: Guide[],
    showBadges?: boolean,
  ): Thumbnail[] {
    const { goal, demoMode } = account

    const items: Thumbnail[] = guides.map(guide => ({
      id: guide.id as string,
      thumbnailUrl: guide.thumbnailUrl,
      title: guide.title,
      badge: showBadges && !guide.read,
      alt: guide.title,
      isCards: guide.style === GuideStyle.CARD,
    }))

    if (goal !== Goal.PREGNANT && !demoMode) {
      const title = tr('more-link-app-tour')
      const tour: Thumbnail = {
        id: APP_TOUR_ID,
        title,
        alt: title,
        thumbnailUrl: './assets/img/app_tour.svg',
      }

      items.push(tour)
    }

    const filteredItems = items.filter(item => item.id !== GuideId.MEASURING_OVERNIGHT)

    return filteredItems.sort((a, b) => {
      if (a.badge && !b.badge) return -1 // Items with badge before those without
      if (!a.badge && b.badge) return 1 // Items without badge after those with
      return 0 // Keep the original order if both items have or don't have badges
    })
  }
}
