import { Injectable } from '@angular/core'
import { _numberEnumKey } from '@naturalcycles/js-lib'
import { AnalyticsEventType } from '@naturalcycles/shared'
import { api } from '../srv/api.service'
import { logUtil } from '../util/log.util'

@Injectable({ providedIn: 'root' })
export class AnalyticsEventService {
  public async addEvent(eventType: AnalyticsEventType): Promise<void> {
    logUtil.log('[analytics] analyticsEvent', _numberEnumKey(AnalyticsEventType, eventType))

    await api.post('analytics/event', {
      json: { eventType },
    })
  }
}
