<ion-footer [@footerState]="footerState">
  <ion-button
    uid="scroll_footer__button"
    shape="round"
    size="small"
    [color]="color"
    class="scrollFooter__btn"
    [class.scrollFooter__btn--hidden]="!showScrollButton"
    (click)="scrollToBottom()"
  >
    <ion-icon [src]="ICON.ARROW_DOWN" />
  </ion-button>

  @if (!showScrollButton) {
    <ng-content />
  }
</ion-footer>
