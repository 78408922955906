import { getState } from '@app/srv/store.service'
import { logUtil } from '@app/util/log.util'
import { HardwareId, ProductKey, ProductType } from '@naturalcycles/shared'

export class BaseGuardService {
  hasAccountId(): boolean {
    return !!getState().account.id
  }

  hasAccountPlan(): boolean {
    return !!getState().account.plan
  }

  hasAccountPlanTrial(): boolean {
    return getState().account.plan === ProductKey.TRIAL
  }

  hasAccountNextPaymentDate(): boolean {
    return !!getState().account.nextPaymentDate
  }

  requiresAppConsent(): boolean {
    return this.hasAccountId() && !getState().account.appConsent
  }

  hasCartItems(): boolean {
    return !!getState().cart.items.length
  }

  hasToPay(): boolean {
    return !!getState().cart.totalPrice.amount || this.isRollingTrialWithWearable()
  }

  isRollingTrialWithWearable(): boolean {
    const cartdHwId = getState().cart.hwId
    return (
      getState().cart.items.some(item => item.key === ProductKey.ROLLING_TRIAL_7DAYS) &&
      !getState().cart.items.some(item => item.type === ProductType.SHOP) &&
      !!cartdHwId &&
      [HardwareId.OURA, HardwareId.APPLE_WATCH].includes(cartdHwId)
    )
  }

  hasCartDiscountCode(): boolean {
    return !!getState().cart.discount?.code
  }

  hasAddress(): boolean {
    return !getState().cart.needsBillingAddress && !getState().cart.needsShippingAddress
  }

  cartContains(productKeys: ProductKey[]): boolean {
    return getState().cart.items.some(item => productKeys.includes(item.key))
  }

  cartContainsType(productType: ProductType): boolean {
    return getState().cart.items.some(item => item.type === productType)
  }

  hasSubscription(): boolean {
    return !!getState().subscriptions.current
  }

  orderContainsType(productType: ProductType): boolean {
    try {
      if (getState().order.items) {
        return getState().order.items!.some(item => item.productType === productType)
      }
      return false
    } catch (err) {
      logUtil.log('base.guard.service orderContainsType error')
      logUtil.error(err)
      return false
    }
  }

  productListContains(productKey: ProductKey): boolean {
    return getState().product.items.some(product => product.key === productKey)
  }
}
