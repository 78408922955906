import { Injectable } from '@angular/core'
import { usesConnectedThermometer } from '@app/cnst/hardware.cnst'
import { PageGuard } from '@app/guards/page.guard'

@Injectable({
  providedIn: 'root',
})
export class ConnectedThermometerGuard extends PageGuard {
  className = 'ConnectedThermometerGuard'

  get requirements(): boolean[] {
    return [usesConnectedThermometer()]
  }
}
