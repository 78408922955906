import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class UIService {
  public swipeGesture$ = new BehaviorSubject(true)

  public getCurrentSwiperId(pageId: string): string | undefined {
    const pageElement = document.getElementById(pageId)
    if (!pageElement) return

    const element = pageElement.querySelector('.swiper-slide-active .swiper-slide')

    return element?.id
  }
}
