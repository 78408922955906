import { COLOR, HEX_OPACITY } from '@app/cnst/color.cnst'
import { ICON } from '@app/cnst/icons.cnst'
import { StringMap } from '@naturalcycles/js-lib'
import {
  AppTracker,
  CervicalMucusConsistency,
  DataFlag,
  DataQuantity,
  HadSex,
  Libido,
  OvulationStatus,
  Sleep,
  TestResult,
} from '@naturalcycles/shared'
import { GradientDrawing, GraphIcon, HolisticGraphEntry, MensStatus } from './graph.model'

export enum GraphSource {
  HOME_HEADER_BUTTON = 'HomeHeaderButton',
  MY_CYCLE = 'MyCycle',
  MY_TEMPERATURES = 'MyTemperatures',
  DEEPLINK = 'Deeplink',
  OVULATION_ACHIEVEMENT = 'OvulationAchievement',
  ADD_DATA_CLOSE = 'AddDataClose',
  ALGO_BOX = 'AlgoBox',
  ALGO_BOX_BUTTON = 'AlgoBoxButton',
  TRACKERS_TODAY = 'TrackersToday',
  TRACKERS_CARD_TODAY_AB_TEST = 'TrackersCardTodayABTest',
}

export const GRAPH_OVULATION_TEXT: Record<OvulationStatus, string> = {
  [OvulationStatus.OVU_CONFIRMED]: 'txt-ovulation',
  [OvulationStatus.OVU_DAY]: 'txt-ovulation-predicted',
  [OvulationStatus.OVU_PREDICTION]: 'txt-ovulation-predicted',
  [OvulationStatus.OVU_WAITING]: 'txt-ovulation-waiting',
  [OvulationStatus.OVU_NOT_CONFIRMED]: 'txt-ovulation-not-confirmed',
  [OvulationStatus.ANOVULATORY]: 'txt-ovulation-no',
}

// Icons
// Remember to add all these maps to `GraphService.loadImages()`

export const GRAPH_ICON: StringMap<GraphIcon> = {
  PLUS: { icon: ICON.ADD, color: COLOR.PURPLE },
  PMS_PREDICTION: { icon: ICON.MOOD_PMS, color: COLOR.PREDICTION },
  MORE: { icon: ICON.MORE_HORIZONTAL, color: COLOR.WHITE },
  NOTES: { icon: ICON.NOTES },
  THERMOMETER: { icon: ICON.THERMOMETER, color: COLOR.WHITE },
  OURA: { icon: ICON.OURA, color: COLOR.WHITE },
  APPLE_WATCH: { icon: ICON.APPLE_WATCH },
}

export const GRAPH_ICON_BY_MENS: Record<MensStatus, GraphIcon> = {
  [MensStatus.CONFIRMED]: { icon: ICON.MENSTRUATION, color: COLOR.BLACK },
  [MensStatus.PREDICTION]: { icon: ICON.MENSTRUATION, color: COLOR.PREDICTION },
  [MensStatus.SPOTTING]: { icon: ICON.SPOTTING, color: COLOR.WHITE },
  [MensStatus.WITHDRAWAL]: { icon: ICON.WITHDRAWAL_MEDIUM, color: COLOR.BLACK },
  [MensStatus.MISCARRIAGE]: { icon: ICON.MISCARRIAGE_MEDIUM, color: COLOR.BLACK },
  [MensStatus.POSTPARTUM]: { icon: ICON.POSTPARTUM_MEDIUM, color: COLOR.BLACK },
}

export const GRAPH_ICON_BY_MENSFLOW: Record<DataQuantity, GraphIcon | undefined> = {
  [DataQuantity.NONE]: undefined,
  [DataQuantity.LIGHT]: { icon: ICON.MENSTRUATION_LIGHT, color: COLOR.WHITE },
  [DataQuantity.MEDIUM]: { icon: ICON.MENSTRUATION_MEDIUM, color: COLOR.WHITE },
  [DataQuantity.HEAVY]: { icon: ICON.MENSTRUATION_HEAVY, color: COLOR.WHITE },
}

export const GRAPH_ICON_BY_MISCARRIAGEFLOW: Record<DataQuantity, GraphIcon | undefined> = {
  [DataQuantity.NONE]: undefined,
  [DataQuantity.LIGHT]: { icon: ICON.MISCARRIAGE_LIGHT, color: COLOR.WHITE },
  [DataQuantity.MEDIUM]: { icon: ICON.MISCARRIAGE_MEDIUM, color: COLOR.WHITE },
  [DataQuantity.HEAVY]: { icon: ICON.MISCARRIAGE_HEAVY, color: COLOR.WHITE },
}

export const GRAPH_ICON_BY_WITHDRAWALFLOW: Record<DataQuantity, GraphIcon | undefined> = {
  [DataQuantity.NONE]: undefined,
  [DataQuantity.LIGHT]: { icon: ICON.WITHDRAWAL_LIGHT, color: COLOR.WHITE },
  [DataQuantity.MEDIUM]: { icon: ICON.WITHDRAWAL_MEDIUM, color: COLOR.WHITE },
  [DataQuantity.HEAVY]: { icon: ICON.WITHDRAWAL_HEAVY, color: COLOR.WHITE },
}

export const GRAPH_ICON_BY_MENSFLOW_PREDICTION: Record<DataQuantity, GraphIcon | undefined> = {
  [DataQuantity.NONE]: undefined,
  [DataQuantity.LIGHT]: { icon: ICON.MENSTRUATION_LIGHT, color: COLOR.PREDICTION },
  [DataQuantity.MEDIUM]: { icon: ICON.MENSTRUATION_MEDIUM, color: COLOR.PREDICTION },
  [DataQuantity.HEAVY]: { icon: ICON.MENSTRUATION_HEAVY, color: COLOR.PREDICTION },
}

export const GRAPH_ICON_BY_PREGTEST: Record<TestResult, GraphIcon> = {
  [TestResult.YES]: { icon: ICON.PREG_TEST_POS, color: COLOR.WHITE, colorDark: COLOR.BLACK },
  [TestResult.NO]: { icon: ICON.PREG_TEST_NEG, color: COLOR.WHITE, colorDark: COLOR.BLACK },
}

export const GRAPH_ICON_BY_PREGTEST_PREVENT: Record<TestResult, GraphIcon> = {
  [TestResult.YES]: { icon: ICON.PREG_TEST_POS, color: COLOR.BLACK, colorDark: COLOR.BLACK },
  [TestResult.NO]: { icon: ICON.PREG_TEST_NEG, color: COLOR.BLACK, colorDark: COLOR.BLACK },
}

export const GRAPH_ICON_BY_LH: Record<TestResult, GraphIcon> = {
  [TestResult.YES]: { icon: ICON.LH_POS, color: COLOR.WHITE },
  [TestResult.NO]: { icon: ICON.LH_NEG, color: COLOR.WHITE },
}

export const GRAPH_ICON_BY_SEX: Record<HadSex, GraphIcon | undefined> = {
  [HadSex.YES]: { icon: ICON.SEX, color: COLOR.WHITE },
  [HadSex.YES_PROTECTED]: { icon: ICON.SEX_SAFE, color: COLOR.WHITE },
  [HadSex.NO]: undefined,
}

export const GRAPH_ICON_BY_LIBIDO: Record<Libido, GraphIcon> = {
  [Libido.LOW]: { icon: ICON.LIBIDO_LOW, color: COLOR.WHITE },
  [Libido.MEDIUM]: { icon: ICON.LIBIDO_MEDIUM, color: COLOR.WHITE },
  [Libido.HIGH]: { icon: ICON.LIBIDO_HIGH, color: COLOR.WHITE },
}

export const GRAPH_ICON_BY_SLEEP: Record<Sleep, GraphIcon> = {
  [Sleep.POOR]: { icon: ICON.SLEEP_POOR, color: COLOR.WHITE },
  [Sleep.OKAY]: { icon: ICON.SLEEP_OKAY, color: COLOR.WHITE },
  [Sleep.RESTFUL]: { icon: ICON.SLEEP_RESTFUL, color: COLOR.WHITE },
}

export const GRAPH_ICON_BY_CM_QUANTITY: Record<DataQuantity, GraphIcon> = {
  [DataQuantity.NONE]: { icon: ICON.CM_QUANTITY_NONE, color: COLOR.WHITE },
  [DataQuantity.LIGHT]: { icon: ICON.CM_QUANTITY_LIGHT, color: COLOR.WHITE },
  [DataQuantity.MEDIUM]: { icon: ICON.CM_QUANTITY_MEDIUM, color: COLOR.WHITE },
  [DataQuantity.HEAVY]: { icon: ICON.CM_QUANTITY_HEAVY, color: COLOR.WHITE },
}

export const GRAPH_ICON_BY_CM_CONSISTENCY: Record<CervicalMucusConsistency, GraphIcon> = {
  [CervicalMucusConsistency.CREAMY]: { icon: ICON.CM_CONSISTENCY_CREAMY, color: COLOR.WHITE },
  [CervicalMucusConsistency.EGGWHITE]: { icon: ICON.CM_CONSISTENCY_EGGWHITE, color: COLOR.WHITE },
  [CervicalMucusConsistency.STICKY]: { icon: ICON.CM_CONSISTENCY_STICKY, color: COLOR.WHITE },
  [CervicalMucusConsistency.WATERY]: { icon: ICON.CM_CONSISTENCY_WATERY, color: COLOR.WHITE },
}

export const GRAPH_ICON_BY_DATAFLAG: Record<DataFlag, GraphIcon | undefined> = {
  [DataFlag.MOOD_HAPPY]: { icon: ICON.MOOD_HAPPY },
  [DataFlag.MOOD_CONFIDENT]: { icon: ICON.MOOD_CONFIDENT },
  [DataFlag.MOOD_CALM]: { icon: ICON.MOOD_CALM },
  [DataFlag.MOOD_ENERGETIC]: { icon: ICON.MOOD_ENERGETIC },
  [DataFlag.MOOD_EXCITED]: { icon: ICON.MOOD_EXCITED },
  [DataFlag.MOOD_PMS]: {
    icon: ICON.MOOD_PMS,
    color: COLOR.PREDICTION,
    colorDark: COLOR.AUBERGINE_PURPLE,
  },
  [DataFlag.MOOD_SWINGS]: { icon: ICON.MOOD_SWINGS },
  [DataFlag.MOOD_IRRITABLE]: { icon: ICON.MOOD_IRRITABLE },
  [DataFlag.MOOD_ANXIOUS]: { icon: ICON.MOOD_ANXIOUS },
  [DataFlag.MOOD_STRESSED]: { icon: ICON.MOOD_STRESSED },
  [DataFlag.MOOD_TIRED]: { icon: ICON.MOOD_TIRED },
  [DataFlag.MOOD_SENSITIVE]: { icon: ICON.MOOD_SENSITIVE },
  [DataFlag.MOOD_NUMB]: { icon: ICON.MOOD_NUMB },
  [DataFlag.MOOD_SAD]: { icon: ICON.MOOD_SAD },
  [DataFlag.MOOD_ANGRY]: { icon: ICON.MOOD_ANGRY },
  [DataFlag.MOOD_FORGETFUL]: { icon: ICON.MOOD_FORGETFUL },
  [DataFlag.MOOD_SICK]: { icon: ICON.MOOD_SICK },
  [DataFlag.MOOD_SENSITIVE_TO_SMELL]: { icon: ICON.MOOD_SENSITIVE_SMELL },
  [DataFlag.MOOD_EXHAUSTED]: { icon: ICON.MOOD_EXHAUSTED },
  [DataFlag.MOOD_UNFOCUSED]: { icon: ICON.MOOD_UNFOCUSED },
  [DataFlag.MOOD_SELF_CRITICAL]: { icon: ICON.MOOD_SELF_CRITICAL },
  [DataFlag.MOOD_FEELING_GUILTY]: { icon: ICON.MOOD_FEELING_GUILTY },
  [DataFlag.MOOD_OBSESSIVE_THOUGHTS]: { icon: ICON.MOOD_OBSESSIVE_THOUGHTS },
  [DataFlag.MOOD_CONFUSED]: { icon: ICON.MOOD_CONFUSED },
  [DataFlag.MOOD_ISOLATED]: { icon: ICON.MOOD_ISOLATED },
  [DataFlag.MOOD_WITHDRAWN]: { icon: ICON.MOOD_WITHDRAWN },
  [DataFlag.MOOD_SOCIABLE]: { icon: ICON.MOOD_SOCIABLE },
  [DataFlag.PAIN_CRAMPS]: { icon: ICON.PAIN_CRAMPS, color: COLOR.WHITE },
  [DataFlag.PAIN_BACKACHE]: { icon: ICON.PAIN_BACKACHE, color: COLOR.WHITE },
  [DataFlag.PAIN_SORE_BREASTS]: { icon: ICON.PAIN_SORE_BREASTS, color: COLOR.WHITE },
  [DataFlag.PAIN_OVULATION]: { icon: ICON.PAIN_OVULATION, color: COLOR.WHITE },
  [DataFlag.PAIN_HEADACHE]: { icon: ICON.PAIN_HEADACHE, color: COLOR.WHITE },
  [DataFlag.PAIN_OTHER]: { icon: ICON.PAIN_OTHER, color: COLOR.WHITE },
  [DataFlag.PAIN_HEARTBURN]: { icon: ICON.PAIN_HEARTBURN, color: COLOR.WHITE },
  [DataFlag.PAIN_PELVIC]: { icon: ICON.PAIN_PELVIC, color: COLOR.WHITE },
  [DataFlag.PAIN_SWELLING]: { icon: ICON.PAIN_SWELLING, color: COLOR.WHITE },
  [DataFlag.PAIN_CONTRACTIONS]: { icon: ICON.PAIN_CONTRACTIONS, color: COLOR.WHITE },
  [DataFlag.PAIN_CRAVINGS]: { icon: ICON.PAIN_CRAVINGS, color: COLOR.WHITE },
  [DataFlag.PAIN_NAUSEOUS]: { icon: ICON.PAIN_NAUSEA, color: COLOR.WHITE },
  [DataFlag.PAIN_DIARRHEA]: { icon: ICON.PAIN_DIARRHEA, color: COLOR.WHITE },
  [DataFlag.PAIN_CONSTIPATION]: { icon: ICON.PAIN_CONSTIPATION, color: COLOR.WHITE },
  [DataFlag.PAIN_BLOATING]: { icon: ICON.PAIN_BLOATING, color: COLOR.WHITE },
  [DataFlag.PAIN_MOVEMENTS]: { icon: ICON.PAIN_MOVEMENTS, color: COLOR.WHITE },
  [DataFlag.PAIN_VAGINAL]: { icon: ICON.PAIN_VAGINAL, color: COLOR.WHITE },
  [DataFlag.PAIN_STITCHES]: { icon: ICON.PAIN_STITCHES, color: COLOR.WHITE },
  [DataFlag.PAIN_SORE_NIPPLES]: { icon: ICON.PAIN_SORE_NIPPLES, color: COLOR.WHITE },
  [DataFlag.PAIN_HEMORRHOIDS]: { icon: ICON.PAIN_HEMORRHOIDS, color: COLOR.WHITE },
  [DataFlag.MORE_EMERGENCY_PILL]: {
    icon: ICON.MORE_EMERGENCY_PILL,
    color: COLOR.BLACK,
    colorDark: COLOR.DARK_PURPLE,
  },
  [DataFlag.MORE_EMERGENCY_IUD]: {
    icon: ICON.MORE_EMERGENCY_IUD,
    color: COLOR.BLACK,
    colorDark: COLOR.DARK_PURPLE,
  },
  [DataFlag.DEVIATION_REASON_OTHER]: undefined,
  [DataFlag.DEVIATION_REASON_SLEEP]: { icon: ICON.DEVIATION_SLEEP, color: COLOR.WHITE },
  [DataFlag.DEVIATION_REASON_ALCOHOL]: { icon: ICON.DEVIATION_ALCOHOL, color: COLOR.WHITE },
  [DataFlag.DEVIATION_REASON_SICK]: { icon: ICON.DEVIATION_SICK, color: COLOR.WHITE },
  [DataFlag.DEVIATION_REASON_ALGO]: { icon: ICON.DEVIATION_ALGO, color: COLOR.WHITE },
  [DataFlag.COVID_DRY_COUGH]: undefined,
  [DataFlag.COVID_SHORT_BREATH]: undefined,
  [DataFlag.COVID_FATIGUE]: undefined,
  [DataFlag.COVID_SORE_THROAT]: undefined,
  [DataFlag.COVID_DIARRHEA]: undefined,
  [DataFlag.COVID_MUSCLE_PAIN]: undefined,
  [DataFlag.COVID_HEADACHE]: undefined,
  [DataFlag.COVID_LOSS_TASTE_SMELL]: undefined,
  [DataFlag.OURA_ADJUSTED_TEMPERATURE]: { icon: ICON.ADJUSTED_TEMP, color: COLOR.WHITE },
  [DataFlag.OURA_IRREGULAR_SLEEP]: { icon: ICON.DEVIATION_SLEEP, color: COLOR.WHITE },
  [DataFlag.OURA_INCOMPLETE_DATA]: undefined,
  [DataFlag.OURA_ALCOHOL]: { icon: ICON.DEVIATION_ALCOHOL, color: COLOR.WHITE },
  [DataFlag.OURA_SICK]: { icon: ICON.DEVIATION_SICK, color: COLOR.WHITE },
  [DataFlag.OURA_DEVIATION_ALGO]: { icon: ICON.DEVIATION_ALGO, color: COLOR.WHITE },
  [DataFlag.OURA_SHORT_SLEEP]: undefined,
  [DataFlag.SKIN_DRY]: { icon: ICON.SKIN_DRY, color: COLOR.WHITE },
  [DataFlag.SKIN_OILY]: { icon: ICON.SKIN_OILY, color: COLOR.WHITE },
  [DataFlag.SKIN_PUFFY]: { icon: ICON.SKIN_PUFFY, color: COLOR.WHITE },
  [DataFlag.SKIN_ACNE]: { icon: ICON.SKIN_ACNE, color: COLOR.WHITE },
  [DataFlag.SKIN_GLOWING]: { icon: ICON.SKIN_GLOWING, color: COLOR.WHITE },
  [DataFlag.SKIN_CHLOASMA]: { icon: ICON.SKIN_CHLOASMA, color: COLOR.WHITE },
  [DataFlag.SKIN_OTHER]: { icon: ICON.SKIN_OTHER, color: COLOR.WHITE },
  [DataFlag.SKIN_HAIR_LOSS]: { icon: ICON.SKIN_HAIR_LOSS, color: COLOR.WHITE },
  [DataFlag.SKIN_STRETCH_MARKS]: { icon: ICON.SKIN_STRETCH_MARKS, color: COLOR.WHITE },
  [DataFlag.SEX_MASTURBATION]: { icon: ICON.SEX_OTHER, color: COLOR.WHITE },
  [DataFlag.SEX_TOUCHING]: { icon: ICON.SEX_OTHER, color: COLOR.WHITE },
  [DataFlag.SEX_TOYS]: { icon: ICON.SEX_OTHER, color: COLOR.WHITE },
  [DataFlag.SEX_ORAL]: { icon: ICON.SEX_OTHER, color: COLOR.WHITE },
  [DataFlag.SEX_ANAL]: { icon: ICON.SEX_OTHER, color: COLOR.WHITE },
  // something for samsung only
  [DataFlag.ORIGINAL_PERIOD_PREDICTION]: undefined,
  [DataFlag.SAMSUNG_DISCARDED_DUE_TO_FLAT_SPECTRUM]: undefined,
  [DataFlag.SAMSUNG_DISCARDED_DUE_TO_HOLES]: undefined,
  [DataFlag.SAMSUNG_DISCARDED_DUE_TO_NO_STABLE_POINTS]: undefined,
  [DataFlag.SAMSUNG_INSUFFICIENT_SLEEP]: undefined,
  [DataFlag.SAMSUNG_TEMP_CORRECTED]: undefined,
}

export const GRAPH_ICON_BY_OVULATION: Record<OvulationStatus, GraphIcon> = {
  [OvulationStatus.OVU_CONFIRMED]: { icon: ICON.OVULATION, color: COLOR.BLACK },
  [OvulationStatus.OVU_DAY]: { icon: ICON.OVULATION, color: COLOR.PREDICTION },
  [OvulationStatus.OVU_PREDICTION]: { icon: ICON.OVULATION, color: COLOR.PREDICTION },
  [OvulationStatus.OVU_WAITING]: { icon: ICON.CLOCK, color: COLOR.PREDICTION },
  [OvulationStatus.OVU_NOT_CONFIRMED]: { icon: ICON.INFO, color: COLOR.BLACK },
  [OvulationStatus.ANOVULATORY]: { icon: ICON.INFO, color: COLOR.BLACK },
}

export const GRAPH_ICON_BY_TRACKER: Record<AppTracker, GraphIcon | undefined> = {
  [AppTracker.MENS_QUANTITY]: { icon: ICON.MENSTRUATION, color: COLOR.PURPLE },
  [AppTracker.CM_QUANTITY]: { icon: ICON.CM_QUANTITY_LIGHT, color: COLOR.PURPLE },
  [AppTracker.CM_CONSISTENCY]: undefined, // same as quantity
  [AppTracker.LIBIDO]: { icon: ICON.LIBIDO_LOW, color: COLOR.PURPLE },
  [AppTracker.SLEEP]: { icon: ICON.SLEEP_OKAY, color: COLOR.PURPLE }, // todo
  [AppTracker.PAIN]: { icon: ICON.PAIN_OTHER, color: COLOR.PURPLE },
  [AppTracker.MOOD]: { icon: ICON.MOOD_CALM },
  [AppTracker.SKIN]: { icon: ICON.SKIN_OTHER, color: COLOR.PURPLE },
  [AppTracker.MORE_SEX]: undefined, // not in holistic graph
  [AppTracker.NOTES]: undefined, // only for partner settings
  [AppTracker.SEX]: undefined, // only for partner settings
  [AppTracker.LH_TEST]: undefined, // only for partner settings
  [AppTracker.PREG_TEST]: undefined, // only for partner settings
  [AppTracker.BOTTLE_FEEDING]: undefined,
  [AppTracker.BREASTFEEDING]: undefined,
  [AppTracker.PUMPING]: undefined,
}

export const LABEL_BY_MENS: Partial<Record<MensStatus, string>> = {
  [MensStatus.CONFIRMED]: 'txt-period',
  [MensStatus.MISCARRIAGE]: 'txt-menstruation-miscarriage',
  [MensStatus.POSTPARTUM]: 'txt-menstruation-postpartum',
}

export const TRACKER_KEYS: (keyof HolisticGraphEntry)[] = [
  'ovulationStatus',
  'cervicalMucus',
  'libido',
  'pain',
  'skin',
  'sleep',
  'mood',
  'notes',
]

export const PAIN_ORDER = [
  DataFlag.PAIN_CRAMPS,
  DataFlag.PAIN_SORE_BREASTS,
  DataFlag.PAIN_HEADACHE,
  DataFlag.PAIN_BACKACHE,
  DataFlag.PAIN_OVULATION,
  DataFlag.PAIN_OTHER,
  DataFlag.PAIN_PELVIC,
  DataFlag.PAIN_HEARTBURN,
  DataFlag.PAIN_SWELLING,
  DataFlag.PAIN_CONTRACTIONS,
  DataFlag.PAIN_NAUSEOUS,
  DataFlag.PAIN_DIARRHEA,
  DataFlag.PAIN_CONSTIPATION,
  DataFlag.PAIN_BLOATING,
  DataFlag.PAIN_CRAVINGS,
]

export const SKIN_ORDER = [
  DataFlag.SKIN_DRY,
  DataFlag.SKIN_OILY,
  DataFlag.SKIN_PUFFY,
  DataFlag.SKIN_ACNE,
  DataFlag.SKIN_GLOWING,
  DataFlag.SKIN_CHLOASMA,
  DataFlag.SKIN_OTHER,
]

export const MOOD_ORDER = [
  DataFlag.MOOD_PMS,
  DataFlag.MOOD_TIRED,
  DataFlag.MOOD_CALM,
  DataFlag.MOOD_HAPPY,
  DataFlag.MOOD_SENSITIVE,
  DataFlag.MOOD_ANXIOUS,
  DataFlag.MOOD_IRRITABLE,
  DataFlag.MOOD_STRESSED,
  DataFlag.MOOD_SAD,
  DataFlag.MOOD_ENERGETIC,
  DataFlag.MOOD_SWINGS,
  DataFlag.MOOD_CONFIDENT,
  DataFlag.MOOD_EXCITED,
  DataFlag.MOOD_NUMB,
  DataFlag.MOOD_ANGRY,
  DataFlag.MOOD_EXHAUSTED,
  DataFlag.MOOD_SENSITIVE_TO_SMELL,
  DataFlag.MOOD_SICK,
  DataFlag.MOOD_FORGETFUL,
  DataFlag.MOOD_UNFOCUSED,
  DataFlag.MOOD_SELF_CRITICAL,
  DataFlag.MOOD_FEELING_GUILTY,
  DataFlag.MOOD_OBSESSIVE_THOUGHTS,
  DataFlag.MOOD_CONFUSED,
]

export interface GraphTheme {
  backgroundGradient: GradientDrawing
  backgroundGradientOpaque: GradientDrawing
  backgroundColor: string // --ion-background-color
  fertileGradient: GradientDrawing
  textColor: string // --ion-text-color
  textColorContrast: string // --ion-text-color-contrast
  lineColor: string
  lineColorOpaque: string
  mensGradient: GradientDrawing
  mensPredictionGradient: GradientDrawing
  ovulationGradientConfirmed: GradientDrawing
  ovulationGradientPrediction: GradientDrawing
  ovulationGradientConfirmedWindow: GradientDrawing
  ovulationGradientPredictionWindow: GradientDrawing
  shadowGradient: GradientDrawing
  logoColor: string // --ion-color-logo
  predictionColor: string
}

export const graphThemeLight: GraphTheme = {
  backgroundGradient: {
    startColor: COLOR.GHOST + HEX_OPACITY[35],
    stopColor: COLOR.GHOST + HEX_OPACITY[35],
  },
  backgroundGradientOpaque: {
    startColor: COLOR.LIGHT_GRAY,
    stopColor: COLOR.LIGHT_GRAY,
  },
  backgroundColor: COLOR.WHITE,
  fertileGradient: {
    startColor: COLOR.ACCENT + HEX_OPACITY[80],
    stopColor: COLOR.ACCENT + HEX_OPACITY[0],
  },
  textColor: COLOR.BLACK,
  textColorContrast: COLOR.WHITE,
  lineColor: COLOR.MEDIUM + HEX_OPACITY[80],
  lineColorOpaque: COLOR.MEDIUM,
  mensGradient: {
    startColor: COLOR.PURPLE + HEX_OPACITY[30],
    stopColor: COLOR.PURPLE + HEX_OPACITY[0],
  },
  mensPredictionGradient: {
    startColor: COLOR.MEDIUM + HEX_OPACITY[100],
    stopColor: COLOR.MEDIUM + HEX_OPACITY[0],
  },
  ovulationGradientConfirmed: {
    startColor: '#8A1568' + HEX_OPACITY[0],
    stopColor: '#8A1568' + HEX_OPACITY[25],
  },
  ovulationGradientPrediction: {
    startColor: COLOR.MEDIUM + HEX_OPACITY[0],
    stopColor: COLOR.MEDIUM + HEX_OPACITY[100],
  },
  ovulationGradientConfirmedWindow: {
    startColor: '#8A1568' + HEX_OPACITY[0],
    stopColor: '#8A1568' + HEX_OPACITY[20],
  },
  ovulationGradientPredictionWindow: {
    startColor: COLOR.MEDIUM + HEX_OPACITY[0],
    stopColor: COLOR.MEDIUM + HEX_OPACITY[60],
  },
  shadowGradient: {
    startColor: COLOR.BLACK + HEX_OPACITY[0],
    stopColor: COLOR.BLACK + HEX_OPACITY[10],
  },
  logoColor: COLOR.PURPLE,
  predictionColor: COLOR.MEDIUM,
}

export const graphThemeDark: GraphTheme = {
  backgroundGradient: {
    startColor: COLOR.GHOST + HEX_OPACITY[5],
    stopColor: COLOR.GHOST + HEX_OPACITY[5],
  },
  backgroundGradientOpaque: {
    startColor: '#502C49',
    stopColor: '#502C49',
  },
  backgroundColor: '#310629',
  fertileGradient: {
    startColor: COLOR.ACCENT + HEX_OPACITY[24],
    stopColor: COLOR.ACCENT + HEX_OPACITY[0],
  },
  textColor: COLOR.MEDIUM,
  textColorContrast: COLOR.DARK_PURPLE,
  lineColor: COLOR.MEDIUM + HEX_OPACITY[45],
  lineColorOpaque: COLOR.MEDIUM,
  mensGradient: {
    startColor: '#FCBBF0' + HEX_OPACITY[30],
    stopColor: COLOR.PURPLE + HEX_OPACITY[0],
  },
  mensPredictionGradient: {
    startColor: '#D3BFBF' + HEX_OPACITY[50],
    stopColor: '#D3BFBF' + HEX_OPACITY[0],
  },
  ovulationGradientConfirmed: {
    startColor: '#8A1568' + HEX_OPACITY[0],
    stopColor: COLOR.TEMPERATURE + HEX_OPACITY[100],
  },
  ovulationGradientPrediction: {
    startColor: COLOR.MEDIUM + HEX_OPACITY[0],
    stopColor: COLOR.MEDIUM + HEX_OPACITY[50],
  },
  ovulationGradientConfirmedWindow: {
    startColor: '#8A1568' + HEX_OPACITY[0],
    stopColor: COLOR.TEMPERATURE + HEX_OPACITY[20],
  },
  ovulationGradientPredictionWindow: {
    startColor: COLOR.MEDIUM + HEX_OPACITY[0],
    stopColor: COLOR.MEDIUM + HEX_OPACITY[30],
  },
  shadowGradient: {
    startColor: COLOR.BLACK + HEX_OPACITY[0],
    stopColor: COLOR.BLACK + HEX_OPACITY[10],
  },
  logoColor: COLOR.MEDIUM,
  predictionColor: COLOR.SAPHIRE_PINK,
}

export const ovulationGradientKeyByStatus: Record<OvulationStatus, keyof GraphTheme | undefined> = {
  [OvulationStatus.OVU_CONFIRMED]: 'ovulationGradientConfirmed',
  [OvulationStatus.OVU_DAY]: 'ovulationGradientPrediction',
  [OvulationStatus.OVU_PREDICTION]: 'ovulationGradientPrediction',
  [OvulationStatus.OVU_WAITING]: 'ovulationGradientPrediction',
  [OvulationStatus.OVU_NOT_CONFIRMED]: 'ovulationGradientPrediction',
  [OvulationStatus.ANOVULATORY]: undefined,
}

export const windowGradientKeyByOvulationStatus: Record<
  OvulationStatus,
  keyof GraphTheme | undefined
> = {
  [OvulationStatus.OVU_CONFIRMED]: 'ovulationGradientConfirmedWindow',
  [OvulationStatus.OVU_DAY]: 'ovulationGradientPredictionWindow',
  [OvulationStatus.OVU_PREDICTION]: 'ovulationGradientPredictionWindow',
  [OvulationStatus.OVU_WAITING]: 'ovulationGradientPredictionWindow',
  [OvulationStatus.OVU_NOT_CONFIRMED]: 'ovulationGradientPredictionWindow',
  [OvulationStatus.ANOVULATORY]: undefined,
}

export const DARK_COLOR_MAP = {
  [COLOR.BLACK]: COLOR.WHITE,
  [COLOR.WHITE]: '#310629',
  [COLOR.PREDICTION]: COLOR.MEDIUM,
  [COLOR.PURPLE]: COLOR.MEDIUM,
}
