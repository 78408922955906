import {
  Experiment,
  getBucket,
  GoalBreakpointOption,
  QuizDataInput,
  QuizEngine,
  QuizGuardData,
} from '@naturalcycles/shared'
import {
  Breakpoint,
  breakpointActions,
  commonQuizFlow,
  guardQuizData,
  Section,
} from '@src/app/cnst/quiz.cnst'
import { QuizPage } from '@src/app/cnst/quiz-pages.cnst'
import { BreakpointOption } from '@src/app/srv/quiz/quiz.service'
import { getState } from '@src/app/srv/store.service'
import { medicalConditionsAB281Test } from './medical-conditions-ab281'

export const isAB281Test = (): boolean => {
  const assignment = getState().experiment.assignments[Experiment.COMPARE_BIRTH_CONTROL]
  if (!assignment) return false
  return getBucket(assignment) === 'test'
}

const getGuardQuizDataAb281 = (): QuizGuardData<QuizPage, QuizDataInput> => {
  const updated = { ...guardQuizData }
  updated[QuizPage.compareBirthControlAB281] = state => !!state?.medicalConditions
  return updated
}

const breakpointActionsAb281 = {
  ...breakpointActions,
  [Breakpoint.medicalConditions]: medicalConditionsAB281Test,
}

export const quizEngineAB281Test = new QuizEngine<
  Breakpoint,
  BreakpointOption,
  QuizPage,
  Section,
  QuizDataInput
>({
  breakpointActions: breakpointActionsAb281,
  guardData: getGuardQuizDataAb281(),
  initialFlow: commonQuizFlow,
})

interface ColumnContent {
  img: string
  title: string
  comparisonPoints: string[]
}
export interface AB281TestContent {
  columnNC: ColumnContent
  columnAlternative: ColumnContent
  bottomText: string
}

const planContent: AB281TestContent = {
  columnNC: {
    img: 'assets/img/ab281/ab281.nc.svg',
    title: 'Natural Cycles°',
    comparisonPoints: [
      'Pinpoints your peak fertility',
      'Built around your unique cycle',
      'Predicts and detects ovulation',
    ],
  },
  columnAlternative: {
    img: 'assets/img/ab281/ab281.alt.svg',
    title: 'Period trackers',
    comparisonPoints: [
      'Estimates fertile window',
      'Based on 28-day calendar cycle',
      'Predicts periods',
    ],
  },
  bottomText: 'The NC° app is trusted by millions for fertility tracking',
}
const preventContent: AB281TestContent = {
  columnNC: {
    img: 'assets/img/ab281/ab281.nc.svg',
    title: 'Natural Cycles°',
    comparisonPoints: [
      'FDA Cleared birth control',
      'Built around your unique cycle',
      'Predicts and detects ovulation',
    ],
  },
  columnAlternative: {
    img: 'assets/img/ab281/ab281.alt.svg',
    title: 'Period trackers',
    comparisonPoints: [
      'Can’t be used as birth control',
      'Based on 28-day calendar cycle',
      'Predicts periods',
    ],
  },
  bottomText: 'Natural Cycles is the #1 birth control and cycle-tracking app',
}

export const getAB281TestContent = (goal: GoalBreakpointOption): AB281TestContent => {
  if (goal === GoalBreakpointOption.plan) return planContent

  return preventContent
}
