import { Injectable } from '@angular/core'
import { PageGuard } from '@app/guards/page.guard'
import { getState } from '@app/srv/store.service'
import { ActivationChannel, HardwareId } from '@naturalcycles/shared'

/**
 * Indicates whether user uses a thermometer which can be calibrated and used to measure absolute values
 */
@Injectable({
  providedIn: 'root',
})
export class CalibratePageGuard extends PageGuard {
  className = 'CalibratePageGuard'

  get requirements(): boolean[] {
    const { hwId, activationChannel } = getState().account

    if (activationChannel === ActivationChannel.CLEARBLUE && hwId === HardwareId.ORAL_THERMOMETER) {
      return [false]
    }

    return [[HardwareId.ORAL_THERMOMETER, HardwareId.UEBE_THERMOMETER].includes(hwId)]
  }
}
