import { EU_COUNTRIES, ProductKey, thermometerKeys } from '@naturalcycles/shared'

export const getOuraShippingDisclaimerRange = (country: string): string => {
  if (country === 'US' || EU_COUNTRIES.includes(country)) return '4-14'
  return '5-16'
}

export const measuringDeviceList = [
  ProductKey.APPLE_WATCH,
  ...thermometerKeys,
  ProductKey.OURA_RING_DISCOUNT,
]
