import { DURATION, EASING } from '@app/animations/vertical-slide'
import { di } from '@app/srv/di.service'
import { AnimationController } from '@ionic/angular/standalone'
import { Animation } from '@ionic/core'

function snackbarAnimation(baseEl: HTMLElement, reverse = false): Animation {
  const animationController = di.get(AnimationController)

  const wrapperAnimation = animationController
    .create()
    .addElement(baseEl.shadowRoot?.querySelector('.toast-wrapper')!)
    .beforeStyles({
      opacity: 0,
      bottom: `calc(24px + var(--footer-height) + var(--ion-safe-area-bottom))`,
    })
    .fromTo(
      'transform',
      `translateY(calc(100% + 24px + var(--footer-height) + var(--ion-safe-area-bottom)))`,
      'translateY(0)',
    )
    .fromTo('opacity', 0, 1)

  const baseAnimation = animationController
    .create()
    .easing(EASING)
    .direction(reverse ? 'reverse' : 'normal')
    .duration(DURATION)
    .addAnimation(wrapperAnimation)

  return baseAnimation
}

export function snackbarEnterAnimation(baseEl: HTMLElement): Animation {
  return snackbarAnimation(baseEl)
}

export function snackbarLeaveAnimation(baseEl: HTMLElement): Animation {
  return snackbarAnimation(baseEl, true)
}
