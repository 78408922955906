import { _omit, StringMap } from '@naturalcycles/js-lib'
import { DailyEntryBM, T3MeasurementMetadata } from '@naturalcycles/shared'
import { Action } from 'redux'

export interface EntryMap extends StringMap<DailyEntryBM> {}
export interface AddDataState {
  lastAutoOpen?: string
  modifiedDailyEntries: EntryMap
  // YYYY-MM-DD HH:mm:ss
  lastSynchronized?: string
  sinceLastSync: { amount: number; type: string; inMinutes: number }
  t3Meta?: T3MeasurementMetadata[]
  // Meant to stash entries for current active Add data page
  entryStash?: DailyEntryBM
}

type State = AddDataState

const initialState: State = {
  modifiedDailyEntries: {},
  lastAutoOpen: undefined,
  sinceLastSync: {
    amount: 0,
    type: '',
    inMinutes: 0,
  },
}

const actions: any = {
  setLastAutoOpen(state: State, action: { type: string; payload: string }): State {
    return {
      ...state,
      lastAutoOpen: action.payload,
    }
  },

  extendModifiedDailyEntries(state: State, action: { type: string; payload: EntryMap }): State {
    return {
      ...state,
      modifiedDailyEntries: {
        ...state.modifiedDailyEntries,
        ...action.payload,
      },
    }
  },

  deleteDailyEntryFromModifiedDailyEntries(
    state: State,
    action: { type: string; payload: string },
  ): State {
    const entryMap: EntryMap = {
      ...state.modifiedDailyEntries,
    }
    delete entryMap[action.payload]
    return {
      ...state,
      modifiedDailyEntries: entryMap,
    }
  },

  clearModifiedDailyEntries(state: State): State {
    return {
      ...state,
      modifiedDailyEntries: {},
    }
  },

  extendT3Metadata(state: State, action: { type: string; payload: T3MeasurementMetadata }): State {
    return {
      ...state,
      t3Meta: [...(state.t3Meta ?? []), action.payload],
    }
  },

  clearT3Metadata(state: State): State {
    return {
      ...state,
      t3Meta: undefined,
    }
  },

  resetAddData(): State {
    return initialState
  },

  setLastSynchronized(state: State, action: { type: string; payload: string }): State {
    return {
      ...state,
      lastSynchronized: action.payload,
    }
  },

  setSinceLastSync(
    state: State,
    action: {
      type: string
      payload: { amount: number; type: string; inMinutes: number }
    },
  ): State {
    return {
      ...state,
      sinceLastSync: action.payload,
    }
  },

  setEntryStash(
    state: State,
    action: {
      type: string
      payload: DailyEntryBM
    },
  ): State {
    return {
      ...state,
      entryStash: action.payload,
    }
  },

  omitEntryStashPregnancyTest(state: State): State {
    if (!state.entryStash) return state

    return {
      ...state,
      entryStash: _omit(state.entryStash, ['pregTest']),
    }
  },

  clearEntryStash(state: State): State {
    return {
      ...state,
      entryStash: undefined,
    }
  },
}

// biome-ignore lint/style/useDefaultParameterLast: ok
export function addDataReducer(state = initialState, action: Action): State {
  if (actions[action.type]) return actions[action.type](state, action)
  return state
}
