import { StringMap } from '@naturalcycles/js-lib'
import { Action, Reducer } from 'redux'

type State = StringMap<string | number>

const initialState: State = {}

const actions: any = {
  resetFlowData(): State {
    return initialState
  },

  setFlowData(_state: State, action: { type: string; payload: State }): State {
    return action.payload
  },

  extendFlowData(state: State, action: { type: string; payload: Partial<State> }): State {
    return {
      ...state,
      ...action.payload,
    }
  },
}

// biome-ignore lint/style/useDefaultParameterLast: ok
export const flowDataReducer: Reducer<State> = (state = initialState, action: Action) => {
  if (actions[action.type]) return actions[action.type](state, action)
  return state
}
