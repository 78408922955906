import {
  BreakpointAction,
  ChoiceFunction,
  QuizDataInput,
  SideEffectsBreakpointOption,
} from '@naturalcycles/shared'
import { Section } from '@src/app/cnst/quiz.cnst'
import { QuizPage } from '@src/app/cnst/quiz-pages.cnst'
import { addPageToFlowAfterCurrent, removePageFromFlow } from '@src/app/util/quiz.util'

const yesTransformFunction: ChoiceFunction<QuizPage, QuizDataInput> = flow => {
  return addPageToFlowAfterCurrent(
    flow,
    Section.goal,
    QuizPage.sideEffects,
    QuizPage.sideEffectsInfo,
  )
}

const noTransformFunction: ChoiceFunction<QuizPage, QuizDataInput> = flow => {
  return removePageFromFlow(flow, Section.goal, QuizPage.sideEffectsInfo)
}

export const sideEffects: BreakpointAction<SideEffectsBreakpointOption, QuizPage, QuizDataInput> = {
  [SideEffectsBreakpointOption.depression]: yesTransformFunction,
  [SideEffectsBreakpointOption.heavierPeriods]: yesTransformFunction,
  [SideEffectsBreakpointOption.lossOfLibido]: yesTransformFunction,
  [SideEffectsBreakpointOption.headaches]: yesTransformFunction,
  [SideEffectsBreakpointOption.skin]: yesTransformFunction,
  [SideEffectsBreakpointOption.tenderBreasts]: yesTransformFunction,
  [SideEffectsBreakpointOption.moodSwings]: yesTransformFunction,
  [SideEffectsBreakpointOption.weightGain]: yesTransformFunction,
  [SideEffectsBreakpointOption.irregularBleeding]: yesTransformFunction,
  [SideEffectsBreakpointOption.skip]: noTransformFunction,
}
