import {
  BreakpointAction,
  ChoiceFunction,
  GoalBreakpointOption,
  HormonalBirthControlHowLongBreakpointOption,
  QuizDataInput,
} from '@naturalcycles/shared'
import { Section, sectionSexQuestions } from '../quiz.cnst'
import { QuizPage } from '../quiz-pages.cnst'

const commonTransformFunction: ChoiceFunction<QuizPage, QuizDataInput> = (flow, data) => {
  const commonPages = [
    QuizPage.cycleLength,
    QuizPage.periodRegularity,
    QuizPage.periodRegularityInfo,
    QuizPage.cycleStartDate,
  ]
  if (data?.fertilityGoal === GoalBreakpointOption.plan) {
    flow[Section.cycle] = [
      ...sectionSexQuestions,
      QuizPage.hormonalBirthControlUsage,
      QuizPage.hormonalBirthControlHowLongPlan,
      QuizPage.plannedPregnancyInfo,

      ...commonPages,
      QuizPage.medicalConditionsPlan,
    ]
    return flow
  }

  flow[Section.cycle] = [
    QuizPage.hormonalBirthControlHowLongPrevent,
    ...commonPages,
    QuizPage.medicalConditionsPrevent,
  ]
  return flow
}

const moreThanAYearTransformFunction: ChoiceFunction<QuizPage, QuizDataInput> = (flow, data) => {
  if (data?.fertilityGoal === GoalBreakpointOption.plan) {
    flow[Section.cycle] = [
      ...sectionSexQuestions,
      QuizPage.hormonalBirthControlUsage,
      QuizPage.hormonalBirthControlHowLongPlan,
      QuizPage.plannedPregnancyInfo,
      QuizPage.medicalConditionsPlan,
    ]
    return flow
  }

  /**
   * If the users has been using hormonal birth control for more than a year,
   * then we skip asking for cycle length (QuizPage.cycleLength), period regularity (QuizPage.periodRegularity) and period start date (QuizPage.cycleStartDate).
   * All these three pages are included instead if the user has been using hormonal birth control for less than a year (upToAMonth OR fewMonths)
   */
  flow[Section.cycle] = [
    QuizPage.hormonalBirthControlHowLongPrevent,
    QuizPage.periodRegularityInfo,
    QuizPage.medicalConditionsPrevent,
  ]
  return flow
}

export const hormonalBirthControlHowLong: BreakpointAction<
  HormonalBirthControlHowLongBreakpointOption,
  QuizPage,
  QuizDataInput
> = {
  [HormonalBirthControlHowLongBreakpointOption.lessThanAMonth]: commonTransformFunction,
  [HormonalBirthControlHowLongBreakpointOption.fewMonths]: commonTransformFunction,
  [HormonalBirthControlHowLongBreakpointOption.moreThanAYear]: moreThanAYearTransformFunction,
}
