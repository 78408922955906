import { CanDeactivateFn } from '@angular/router'
import { NavigationParams } from '@src/app/cnst/nav.params.cnst'
import { di } from '@src/app/srv/di.service'
import { EventService } from '@src/app/srv/event.service'
import { getState, StoreService } from '@src/app/srv/store.service'
import { BasePage } from '../base.page'
import { FlowId } from './flow.cnst'

export const canDeactivateFlowGuard: CanDeactivateFn<BasePage> = (_, snapshot) => {
  const id = snapshot.params[NavigationParams.FLOW] as FlowId

  const { flowData } = getState()

  if (flowData['flowId'] !== id) return true

  di.get(EventService).onFlowDeactivation$.next(id)
  di.get(StoreService).dispatch('resetFlowData')

  return true
}
