import { Experiment, getBucket, GoalBreakpointOption } from '@naturalcycles/shared'
import { QuizSelectOption } from '@src/app/cnst/quiz.cnst'
import { getState } from '@src/app/srv/store.service'

export const isAb272Test = (): boolean => {
  return getBucket(getState().experiment.assignments[Experiment.PERIMENOPAUSE]) === 'test'
}

export const isAnyAb272Assignment = (): boolean => {
  return !!getState().experiment.assignments[Experiment.PERIMENOPAUSE]
}

export enum ExtraGoalBreakpointOption {
  perimenopause = 10,
}

export const perimenopauseAsset = '/assets/img/ab272/perimenopause.svg'

export const perimenopauseOption: QuizSelectOption = {
  key: ExtraGoalBreakpointOption.perimenopause,
  textKey: 'txt-quiz-GoalBreakpointOption--perimenopause-new',
  img: perimenopauseAsset,
  imgPosition: 'right',
}

export const nonModeOptions = [
  GoalBreakpointOption.improveWellbeing,
  GoalBreakpointOption.manageSymptoms,
  GoalBreakpointOption.trackCycle,
  GoalBreakpointOption.undecided,
  ExtraGoalBreakpointOption.perimenopause,
]

export type ExtendedGoalBreakpointOption = GoalBreakpointOption | ExtraGoalBreakpointOption
