export const SELECTORS = {
  todayAddDataButton: 'today__addData__button',
  numpadDismisserArea: 'numpad__dismisser_area',
  addDataClose: 'addData__close',
  addDataFooterSaveBtn: 'addData__footer__save__button',
  todaySupertabBtn: 'todayPage__superTabButton',
  monthSupertabBtn: 'monthPage__superTabButton',
  homeTabCalendarBtn: 'homePage__bottomTabs__calendarButton',
  homeTabMyCycleBtn: 'homePage__bottomTabs__myCycleButton',
  homeTabMyMessagesBtn: 'homePage__bottomTabs__messagesButton',
  graphBtn: 'calendar__graphButton',
  graphCloseBtn: 'graph_closeBtn',
  graphNextBtn: 'graphPage__nextBtn',
  graphPreviousBtn: 'graphPage__previousBtn',
}
