import { inject, Injectable } from '@angular/core'
import { addAnalyticsProps } from '@app/analytics/analytics.service'
import { fadeAnimation } from '@app/animations/fade'
import { ICON } from '@app/cnst/icons.cnst'
import { ROUTES } from '@app/cnst/nav.cnst'
import { SNACKBAR } from '@app/cnst/snackbars.cnst'
import { isAndroidApp } from '@app/cnst/userDevice.cnst'
import { AppearanceService, AppearanceSettings } from '@app/srv/appearance.service'
import { SnackbarService } from '@app/srv/snackbar.service'
import { getState } from '@app/srv/store.service'
import { logUtil } from '@app/util/log.util'
import { NavController, Platform } from '@ionic/angular/standalone'
import { _stringMapValues, pDelay } from '@naturalcycles/js-lib'
import { di } from '@src/app/srv/di.service'
import { AndroidFileSystem } from '@src/typings/capacitor'
import { BehaviorSubject, Subject } from 'rxjs'
import {
  DARK_COLOR_MAP,
  GRAPH_ICON,
  GRAPH_ICON_BY_CM_CONSISTENCY,
  GRAPH_ICON_BY_CM_QUANTITY,
  GRAPH_ICON_BY_DATAFLAG,
  GRAPH_ICON_BY_LH,
  GRAPH_ICON_BY_LIBIDO,
  GRAPH_ICON_BY_MENS,
  GRAPH_ICON_BY_MENSFLOW,
  GRAPH_ICON_BY_MENSFLOW_PREDICTION,
  GRAPH_ICON_BY_MISCARRIAGEFLOW,
  GRAPH_ICON_BY_OVULATION,
  GRAPH_ICON_BY_PREGTEST,
  GRAPH_ICON_BY_PREGTEST_PREVENT,
  GRAPH_ICON_BY_SEX,
  GRAPH_ICON_BY_SLEEP,
  GRAPH_ICON_BY_TRACKER,
  GRAPH_ICON_BY_WITHDRAWALFLOW,
  GraphSource,
  graphThemeDark,
  graphThemeLight,
} from './graph.cnst'
import { GraphCanvasEntry, GraphIcon } from './graph.model'

interface ImageFetchingResult {
  path: string
  success: boolean
  err?: string
}

/**
 * @description Everything for communication between different pages
 */
@Injectable({ providedIn: 'root' })
export class GraphService {
  private appearanceService = inject(AppearanceService)
  private navController = inject(NavController)
  public entriesInView$ = new BehaviorSubject<[GraphCanvasEntry, GraphCanvasEntry] | undefined>(
    undefined,
  )

  public selectedTab$ = new BehaviorSubject<number>(0)
  public clickedNext$ = new Subject<void>()
  public clickedPrevious$ = new Subject<void>()
  public clickedZoomIn$ = new Subject<void>()
  public clickedZoomOut$ = new Subject<void>()
  public nextEnabled$ = new BehaviorSubject<boolean>(false)
  public previousEnabled$ = new BehaviorSubject<boolean>(false)
  public zoomInEnabled$ = new BehaviorSubject<boolean>(false)
  public zoomOutEnabled$ = new BehaviorSubject<boolean>(false)
  public iconsLoaded$ = new BehaviorSubject<boolean>(false)
  public enableGraphOpening$ = new Subject<void>()

  public theme = graphThemeLight

  private sourcePath?: string
  private loadedImagesCount = 0

  public init(): void {
    this.appearanceService.appearance$.subscribe(async appearance => {
      this.theme = appearance === AppearanceSettings.DARK ? graphThemeDark : graphThemeLight

      void this.loadImages(appearance)
    })
  }

  public async openGraph(source: GraphSource, route = ROUTES.GraphCyclePage): Promise<void> {
    window.graphOpenStarted = Date.now()

    if (di.get(Platform).is('mobileweb') && getState().account.personaId !== 'PERCY') {
      di.get(SnackbarService).showSnackbar(SNACKBAR.GRAPH_OPEN)
      return
    }

    if (source !== GraphSource.ADD_DATA_CLOSE) {
      this.sourcePath = location.pathname
    }

    await pDelay(100)

    addAnalyticsProps({ source })

    // in case of invalid route
    if (!Object.values(ROUTES).includes(route)) route = ROUTES.GraphCyclePage

    await this.navController.navigateForward(route, {
      animation: fadeAnimation,
    })
  }

  async closeGraph(): Promise<void> {
    window.graphCloseStarted = Date.now()

    await pDelay(100)

    await this.navController.navigateBack(this.sourcePath || ROUTES.HomePage, {
      animation: fadeAnimation,
      animationDirection: 'forward',
    })
  }

  private async loadImages(appearance?: AppearanceSettings): Promise<void> {
    this.loadedImagesCount = 0
    appearance ||= AppearanceSettings.LIGHT

    this.iconsLoaded$.next(false)

    const loadImagePromises: Promise<ImageFetchingResult>[] = []

    const graphIcons: GraphIcon[] = [
      ..._stringMapValues(GRAPH_ICON),
      ..._stringMapValues(GRAPH_ICON_BY_MENS),
      ..._stringMapValues(GRAPH_ICON_BY_MENSFLOW),
      ..._stringMapValues(GRAPH_ICON_BY_MENSFLOW_PREDICTION),
      ..._stringMapValues(GRAPH_ICON_BY_MISCARRIAGEFLOW),
      ..._stringMapValues(GRAPH_ICON_BY_WITHDRAWALFLOW),
      ..._stringMapValues(GRAPH_ICON_BY_OVULATION),
      ..._stringMapValues(GRAPH_ICON_BY_LH),
      ..._stringMapValues(GRAPH_ICON_BY_PREGTEST),
      ..._stringMapValues(GRAPH_ICON_BY_PREGTEST_PREVENT),
      ..._stringMapValues(GRAPH_ICON_BY_SEX),
      ..._stringMapValues(GRAPH_ICON_BY_LIBIDO),
      ..._stringMapValues(GRAPH_ICON_BY_SLEEP),
      ..._stringMapValues(GRAPH_ICON_BY_CM_QUANTITY),
      ..._stringMapValues(GRAPH_ICON_BY_CM_CONSISTENCY),
      ..._stringMapValues(GRAPH_ICON_BY_DATAFLAG),
      ..._stringMapValues(GRAPH_ICON_BY_TRACKER),
    ].filter(Boolean)

    const imgCount = graphIcons.length

    graphIcons.forEach(async graphIcon => {
      const { icon, color: _color } = graphIcon

      const color =
        _color && appearance === AppearanceSettings.DARK
          ? graphIcon.colorDark || DARK_COLOR_MAP[graphIcon.color!]
          : graphIcon.color

      const path = icon.endsWith('.svg') ? icon : `./assets/img/${icon}.svg`

      // need to attach color
      if (color) {
        loadImagePromises.push(this.fetchImage(icon, path, graphIcon, color, imgCount))
      } else {
        graphIcon.img = new Image()

        graphIcon.img.src = path

        graphIcon.img.onload = () => {
          if (++this.loadedImagesCount === imgCount) this.iconsLoaded$.next(true)
        }
      }
    })

    const failedPaths = await Promise.all(loadImagePromises)

    if (failedPaths.some(path => !path.success) && isAndroidApp) {
      const { imageFound } = await AndroidFileSystem.checkImagesFolder()

      logUtil.error(`Image loading failed. AndroidFSworks: ${imageFound}`)
    }
  }

  private async fetchImage(
    icon: ICON,
    path: string,
    graphIcon: GraphIcon,
    color: string,
    imgCount: number,
    isAbsolutePath?: boolean,
  ): Promise<ImageFetchingResult> {
    return await fetch(path)
      .then(response => {
        if (!response.ok) throw new Error(response.statusText)
        return response.text()
      })
      .then(data => {
        graphIcon.img = new Image()

        const _color = color.replace('#', '%23') // need to escape hex colors

        const coloredSvgXml = data.replace(/<svg/g, `<svg fill="${_color}"`) // add fill color to svg

        graphIcon.img.src = 'data:image/svg+xml;utf8,' + coloredSvgXml

        graphIcon.img.onload = () => {
          if (++this.loadedImagesCount === imgCount) this.iconsLoaded$.next(true)
        }
        return { path, success: true }
      })
      .catch(async err => {
        if (!isAbsolutePath) {
          // Trying absolute path
          const newPath = icon.endsWith('.svg') ? icon.substring(2) : `assets/img/${icon}.svg`
          await this.fetchImage(icon, newPath, graphIcon, color, imgCount, true)
        }
        return { path, success: false, err }
      })
  }
}
