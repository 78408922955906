import { inject, Injectable } from '@angular/core'
import { isIOSApp } from '@app/cnst/userDevice.cnst'
import { DeviceService } from '@app/srv/device.service'
import { Platform } from '@ionic/angular/standalone'
import { ScreenOrientation } from '@src/typings/capacitor'
import { BehaviorSubject } from 'rxjs'
import { di } from './di.service'
import { EventService } from './event.service'
import { Orientation } from './orientation.model'

@Injectable({ providedIn: 'root' })
export class OrientationService {
  private platform = inject(Platform)
  private deviceService = inject(DeviceService)
  // todo: cleanup the differences between Orientation enum and OrientationType (ex-OrientationLockType)
  public orientation$ = new BehaviorSubject<Orientation>(Orientation.portrait)

  public init(): void {
    void this.lock(Orientation.portrait)

    if (this.deviceService.inFrame()) return

    if (window.screen?.orientation) {
      // Android
      window.screen.orientation.addEventListener('change', () => this.onOrientationChange())
    } else if (isIOSApp) {
      // iOS
      void ScreenOrientation.addListener('orientationChange', ({ orientation }) => {
        this.onOrientationChange(orientation)
      })
    }

    // Subscribing to onResume because on Android onChange doesn't trigger after pausing the app on Graph page
    di.get(EventService).onResume$.subscribe(() => this.onOrientationChange())
  }

  private onOrientationChange(_orientation?: Orientation): void {
    const orientation = (_orientation ||
      window.screen?.orientation?.type ||
      'natural') as Orientation

    if (
      this.orientation$.value === orientation ||
      orientation.split('-')[0] === this.orientation$.value.split('-')[0] || // for example: landscape-primary to landscape-secondary or portrait to portrait-secondary
      orientation === ('any' as Orientation) ||
      orientation === ('natural' as Orientation)
    ) {
      return
    }

    this.orientation$.next(orientation)
  }

  public async lock(orientation: Orientation): Promise<void> {
    if (!this.platform.is('hybrid')) return

    await ScreenOrientation.lock({ orientation })
  }

  public unlock(): void {
    if (!this.platform.is('hybrid')) return

    void ScreenOrientation.unlock()
  }
}
