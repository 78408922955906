// TODO: go through these params and make sure that we clear them at some point

export enum NavigationParams {
  DATE = 'date', // ok
  FLOW = 'flow', // ok
  ERROR = 'error',
  IS_DEMO_MODE_PLAN = 'isDemoModePlan', // ok
  MODE = 'mode',
  PAYMENT_METHOD = 'paymentMethod',
  NOTES = 'notes',
  ID = 'id',
  HW_ID = 'hwid', // ok
  SOURCE_PATH = 'sourcePath',
  TRACKERS = 'trackers',
  ADD_DATA_GHOST = 'addDataGhost',
  ADD_DATA_DATE = 'addDataDate',
  BLOCK_NUMPAD = 'blockNumpad',
  RESEARCH = 'research',
  PREGNANCY_SOURCE = 'pregnancySource',
  AUTH_PAGE_MODE = 'authPageMode', // ok
  TRACK_THERM_BUY_SOURCE = 'trackThermBuySource', // ok
  SHOW_PREG_END_TOOLTIP = 'showPregEndTooltip',
  CHANGE_DEVICE_FLOW = 'changeDeviceFlow', // ok
  HIDE_BUTTONS = 'hideButtons', // ok
  CHANGE_DEVICE_FREE_FLOW = 'changeDeviceFreeFlow', // ok
  BLUETOOTH_TEMPERATURES = 'bluetoothTemperatures', // ok
  HK_IMPORT_ENABLED = 'hkImportEnabled', // ok
  NO_AW_TEMP_SENSOR = 'noAwTempSensor',
}

export enum NavigationParamsFlow {
  /**
   * A flag that determines whether to call `POST /account/demoModeExit` endpoint after payment for the user who have passed through the demo mode.
   */
  AFTER_DEMO_PLAN = 'AFTER_DEMO_PLAN',
  /**
   * A flag that determines whether to start onboarding or navigate back to the previous page after payment.
   */
  PURCHASE = 'PURCHASE',
}
