import { inject, Injectable } from '@angular/core'
import { COLOR } from '@app/cnst/color.cnst'
import { api } from '@app/srv/api.service'
import { EventService } from '@app/srv/event.service'
import { getState } from '@app/srv/store.service'
import { AppLauncher } from '@capacitor/app-launcher'
import { Browser } from '@capacitor/browser'
import { _Memo, _Throttle } from '@naturalcycles/js-lib'
import { ACHIEVEMENT_BY_QUIZ, DynamicUrlResponse } from '@naturalcycles/shared'
import { isNativeApp } from '@src/app/cnst/userDevice.cnst'
import { DeviceService } from '@src/app/srv/device.service'
import { EVENT } from '../analytics/analytics.cnst'
import { AnalyticsService } from '../analytics/analytics.service'
import { DynamicLink, LINK, TRANSLATED_LINKS } from '../cnst/links.cnst'
import { AchievementsService } from './achievements.service'

@Injectable({ providedIn: 'root' })
export class InAppBrowserService {
  private analyticsService = inject(AnalyticsService)
  private eventService = inject(EventService)
  private achievementService = inject(AchievementsService)
  private deviceService = inject(DeviceService)

  @_Throttle(1000)
  async open(
    url: string | LINK | DynamicLink,
    systemBrowser = false,
    source?: string,
    params?: Record<string, string>,
  ): Promise<void> {
    // Dont open play store links in the In app browser on Android
    if (url.startsWith('https://play.google.com') && this.deviceService.isAndroidDevice) {
      systemBrowser = true
    }

    const { ui, quizzes } = getState()

    url = await this.getLocalizedLink(url, ui.lang)

    url = this.addParams(url, params)

    const analyticsProps = { link: url, iab: isNativeApp, source, systemBrowser }

    if (systemBrowser) {
      this.analyticsService.trackEvent(EVENT.OUTBOUND_LINK, analyticsProps)
      void AppLauncher.openUrl({ url })
      return
    }

    void Browser.open({ url, toolbarColor: COLOR.MEDIUM_GRAY, presentationStyle: 'popover' })

    if (isNativeApp) {
      void Browser.addListener('browserFinished', () => {
        this.eventService.inAppBrowserClosed$.next()
        void this.analyticsService.trackEvent(EVENT.IAB_EXIT)
        void Browser.removeAllListeners()
      })
    }

    void this.analyticsService.trackEvent(EVENT.OUTBOUND_LINK, analyticsProps)

    // check if the url is a quiz, then achieve the achievement!
    quizzes.items.forEach(q => {
      if (!ACHIEVEMENT_BY_QUIZ[q.id]) return
      if (!url.includes(q.url)) return

      void this.achievementService.completeAchievement(ACHIEVEMENT_BY_QUIZ[q.id])
    })
  }

  private addParams(url: string, params: Record<string, string> | undefined): string {
    if (!params) {
      return url
    }

    url += (url.includes('?') ? '&' : '?') + new URLSearchParams(params).toString()
    return url
  }

  public async getLocalizedLink(url: string | LINK | DynamicLink, lang: string): Promise<string> {
    if (url === DynamicLink.IFU) {
      return await this.getLinkFromBackend(lang)
    }
    const translated = TRANSLATED_LINKS[lang]?.[url]
    return translated || url
  }

  @_Memo()
  private async getLinkFromBackend(lang: string): Promise<string> {
    const { url } = await api.get<DynamicUrlResponse>(`consent/ifu`, {
      searchParams: {
        lang,
      },
    })
    return url
  }

  public getBrowserVersion(): string {
    const userAgent = navigator.userAgent
    const noBrowserVersionFound = 'unknown'

    function getVersionByPattern(browser: string, versionPattern?: string): string {
      const regex = new RegExp(`${versionPattern || browser}/(\\d+(\\.\\d+)?)`)
      const version = userAgent.match(regex)?.[1] || noBrowserVersionFound
      return `${browser} ${version}`
    }

    if (userAgent.includes('Chrome')) {
      return getVersionByPattern('Chrome')
    }
    if (userAgent.includes('Firefox')) {
      return getVersionByPattern('Firefox')
    }
    if (userAgent.includes('Safari')) {
      return getVersionByPattern('Safari', 'Version') // Safari's version is under `Version`
    }
    if (userAgent.includes('AppleWebKit')) {
      return getVersionByPattern('AppleWebKit') // Inapp-browser on iOS
    }

    return noBrowserVersionFound
  }
}
