import { splitStringInChunks } from '@app/util/string.util'
import { AppError, Reviver } from '@naturalcycles/js-lib'
import { logUtil } from './log.util'

class JsonUtil {
  /**
   * Safe version of JSON.parse()
   * In case of error - returns `undefined` and reports to Sentry.
   */
  parseSafe<T = any>(s = '', reviver?: Reviver, name?: string): T {
    if (!s) return undefined as any

    try {
      return JSON.parse(s, reviver)
    } catch {
      if (s.length <= 1000) {
        // fits in Sentry limit
        logUtil.log(s)
      } else {
        // Because Sentry has a max limit of string size to display - we split the string in chunks
        const chunks = splitStringInChunks(s, 1000)
        chunks.forEach(c => logUtil.log(c))
      }

      logUtil.error(
        new AppError(`json.util parseSafe error${name ? ', name=' + name : ''}`, {
          name,
          fingerprint: `json_util_parseSafe_${name}`,
        }),
      )

      return undefined as any
    }
  }
}

export const jsonUtil = new JsonUtil()
