<ion-header>
  <ion-toolbar mode="ios">
    <ion-buttons slot="start">
      <ion-button mode="md" (click)="dismissModal()" uid="infoModal__closeButton" color="text">
        <ion-icon slot="icon-only" name="close" />
      </ion-button>
    </ion-buttons>

    @if (isLogoDisplayed) {
      <ion-title>
        <ion-icon [src]="ICON.NC_LOGO_SECURE" color="purple" class="logoIcon" />
      </ion-title>
    }
  </ion-toolbar>
</ion-header>

<ion-content
  class="infoModal ion-padding-horizontal"
  uid="infoModal__content"
  [class.infoModal--hasFooter]="ctaTitle"
>
  @if (title) {
    <h1 class="infoModal__title">{{ title | tmd | stripTags }}</h1>
  }

  @if (infoBoxBody) {
    <div class="infoBox">
      <div>
        <ion-icon [src]="ICON.INFO" />
      </div>
      <span>{{ infoBoxBody | tmd | stripTags }}</span>
    </div>
  }

  @if (!html && body) {
    <div [innerHTML]="body | tmd"></div>
  }

  @if (html) {
    <div class="infoModal__html" [innerHTML]="html | safe"></div>
  }
</ion-content>

@if (ctaTitle) {
  <app-scroll-footer [content]="content">
    <ion-button class="infoModal__cta" expand="block" shape="round" (click)="dismissModal()">
      <ion-label>{{ ctaTitle | tr }}</ion-label>
    </ion-button>
  </app-scroll-footer>
}
