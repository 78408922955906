<ion-content class="ion-padding bioAuthBlock__content">
  @if (authState === BioAuthBlockState.AuthNeeded) {
    <ion-title class="bioAuthBlock__title">
      {{ 'biometric-auth-block-title' | tmd: { biometryType } | stripTags }}
    </ion-title>
    <p class="bioAuthBlock__description">{{ 'biometric-auth-block-body' | tr }}</p>
    <ion-img
      class="bioAuthBlock__img bioAuthBlock__img--faceId"
      src="../assets/img/biometric-auth/faceId.svg"
    />
  }

  @if (authState === BioAuthBlockState.Failed) {
    <ion-img
      class="bioAuthBlock__img bioAuthBlock__img--failed"
      src="../assets/img/illustration_face_failure.svg"
    />
    <ion-title class="bioAuthBlock__title">
      {{ 'biometric-auth-block-failed-title' | tmd: { biometryType } | stripTags }}
    </ion-title>
    <p class="bioAuthBlock__description">
      {{
        (biometryLocked ? 'biometric-auth-block-locked-body' : 'biometric-auth-block-failed-body')
          | tr
      }}
    </p>
  }
</ion-content>

@if (authState === BioAuthBlockState.Failed) {
  <ion-footer>
    <ion-button
      uid="biometricAuthBlock__btn__tryAgain"
      color="white"
      expand="block"
      shape="round"
      (click)="tryAgain()"
    >
      <ion-label color="purple">{{ 'txt-try-again' | tr }}</ion-label>
    </ion-button>
    @if (biometryLocked) {
      <ion-button
        uid="biometricAuthBlock__btn__logout"
        lines="none"
        expand="block"
        fill="clear"
        color="white"
        shape="round"
        (click)="logout()"
      >
        <ion-label>{{ 'biometric-auth-block-locked-log-out' | tr }}</ion-label>
      </ion-button>
    }
  </ion-footer>
}
