import { Injectable } from '@angular/core'
import { tr } from '@app/srv/translation.util'
import { IsoDateTime, localTime } from '@naturalcycles/js-lib'
import { dispatch, getState } from './store.service'

// This file exists to resolve circular dep between BootstrapService and NetworkService
@Injectable({ providedIn: 'root' })
export class Network2Service {
  getHoursSinceLastSync(): { amount: number; type: string } {
    const lastSyncTimestamp = getState().addData.lastSynchronized as IsoDateTime

    const now = localTime.now()

    let hours: number = now.diff(lastSyncTimestamp, 'hour')
    let days: number | undefined

    if (hours > 24) {
      days = now.diff(lastSyncTimestamp, 'day')
    } else hours ||= 0

    const amount = days || hours

    const type = days ? tr('txt-days') : tr('txt-hours')

    const inMinutes = now.diff(lastSyncTimestamp, 'minute')

    dispatch('setSinceLastSync', { amount, type, inMinutes })

    return { amount, type }
  }
}
