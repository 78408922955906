import { BackendResponseFM, FriendReferral } from '@naturalcycles/shared'
import { Action, Reducer } from 'redux'

type State = Partial<FriendReferral>

const initialState: State = {}

const actions: any = {
  extendFriendReferral(
    state: State,
    action: { type: string; payload: Partial<FriendReferral> },
  ): State {
    return {
      ...state,
      ...action.payload,
    }
  },

  onBackendResponse(state: State, action: { type: string; payload: BackendResponseFM }): State {
    // replace!
    if (action.payload.friendReferral) {
      return {
        ...action.payload.friendReferral,
      }
    }

    return state
  },
}

// biome-ignore lint/style/useDefaultParameterLast: ok
export const friendReferralReducer: Reducer<State> = (state = initialState, action: Action) => {
  if (actions[action.type]) return actions[action.type](state, action)
  return state
}
