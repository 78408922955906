import { inject, Injectable } from '@angular/core'
import { AppPreloadingStrategy } from '@app/app.preloading.strategy'
import { appInitDone, firstIdle } from '@app/srv/milestones'

const PRELOAD_PATHS = [
  'home',
  'calendar',
  'statistics',
  'messages',
  'my-data',
  'learn',
  'graph',
  'history',
  'add-data/:date',
]

@Injectable({ providedIn: 'root' })
export class PreloadService {
  private appPreloadingStrategy = inject(AppPreloadingStrategy)

  init(): void {
    void firstIdle.then(() => this.preloadRoutes())
  }

  async preloadRoutes(): Promise<void> {
    await appInitDone

    // Preload Angular routes
    void this.appPreloadingStrategy.preloadRoutes(PRELOAD_PATHS)

    // await nextIdle()
    // prf(`idleAfterPreload`)
    // idleAfterPreloadDefer.resolve()
  }
}
