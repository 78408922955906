import { BackendResponseFM, Hardware } from '@naturalcycles/shared'
import { Action, Reducer } from 'redux'

const initialState: Hardware[] = []

const actions: any = {
  onBackendResponse(
    state: Hardware[],
    action: { type: string; payload: BackendResponseFM },
  ): Hardware[] {
    if (action.payload.availableHardwares) {
      return [...action.payload.availableHardwares]
    }

    return state
  },
}

export const availableHardwaresReducer: Reducer<Hardware[]> = (
  // biome-ignore lint/style/useDefaultParameterLast: ok
  state = initialState,
  action: Action,
) => {
  if (actions[action.type]) return actions[action.type](state, action)
  return state
}
