import { Pipe, PipeTransform } from '@angular/core'
import { DateFormat, DateService } from '@app/srv/date.service'
import { di } from '@app/srv/di.service'
import { localDate, localTime } from '@naturalcycles/js-lib'

@Pipe({
  name: 'nc_date',
  standalone: true,
})
export class DatePipe implements PipeTransform {
  /**
   * Input date as string or numbers (timestamp in seconds)
   */
  public transform(
    date: string | number | null | undefined, // todo: should be LocalDateInputNullable
    format: string = DateFormat.MEDIUM,
  ): string {
    if (date === null || date === undefined) return '' // safely pipe after `| async` which is typed as ` | null` in Angular

    if (typeof date === 'number') {
      return di.get(DateService).localizeDateTime(localTime(date as any), format as DateFormat)
    }

    return di.get(DateService).localizeDate(localDate(date as any), format as DateFormat)
  }
}
