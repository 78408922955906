import { pDefer } from '@naturalcycles/js-lib'
import { UserDeviceFM } from '@naturalcycles/shared'

/**
 * This promise is resolved when BootstrapService has done it's work and we're ready
 * to display a page to the user.
 * Before this promise is resolved user only can see a SplashScreen.
 * No page can be entered before!
 * This is enforced by Ionic's page guard 'ionicCanEnter' in every page.
 */
export const bootstrapDone = pDefer()

export const appInitDone = pDefer()
export const postInitDone = pDefer()

export const userDeviceDone = pDefer<UserDeviceFM>()

/**
 * Resolves with page name
 */
export const firstPageOpened = pDefer<string>()

export const firstIdle = pDefer()

export const todayLoaded = pDefer()

export const storeServiceInitialized = pDefer()

// export const idleAfterPreloadDefer = pDefer<void>()
// export const idleAfterPreload = idleAfterPreloadDefer.promise

declare global {
  interface Window {
    appInitDone: typeof appInitDone
    todayLoaded: typeof todayLoaded
  }
}

window.appInitDone = appInitDone
window.todayLoaded = todayLoaded
