import { Component, inject } from '@angular/core'
import { MarkdownDirective } from '@app/dir/markdown/markdown.directive'
import { BaseModal } from '@app/pages/base.modal'
import { MarkdownPipe } from '@app/pipes/markdown.pipe'
import { StripTagsPipe } from '@app/pipes/strip-tags.pipe'
import { TrPipe } from '@app/pipes/tr.pipe'
import { BiometricAuthService, biometryType } from '@app/srv/biometricAuth.service'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone'

@Component({
  standalone: true,
  imports: [
    MarkdownDirective,
    MarkdownPipe,
    StripTagsPipe,
    TrPipe,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
    IonTitle,
    IonContent,
    IonFooter,
  ],
  selector: 'app-biometric-auth-permission-revoked-modal',
  templateUrl: './biometric-auth-permission-revoked.modal.html',
})
export class BiometricAuthPermissionRevokedModal extends BaseModal {
  public className = 'BiometricAuthPermissionRevokedModal'
  public biometryType = biometryType

  private biometricAuthService = inject(BiometricAuthService)

  public async continue(): Promise<void> {
    await this.dismissModal(true)
    this.biometricAuthService.onResume$.next()
  }
}
