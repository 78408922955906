@if (testResult) {
  <ion-chip color="lh">
    @if (timestamp) {
      <ion-label>{{ timestamp | nc_date: 'TIME' }}</ion-label>
    }
    <ion-icon [src]="ICON_BY_LH[testResult]" />
  </ion-chip>
}

<ion-img />
