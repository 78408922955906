import { Action, Reducer } from 'redux'

export interface BluetoothPluginMockState {
  thermometerAdvertising: boolean
  hasUnsyncedTemp: 1 | 2 // 1 === has temp, 2 === no temp
  mockFWVersion: boolean
  fwVersion: string
}

type State = BluetoothPluginMockState

const initialState: BluetoothPluginMockState = {
  thermometerAdvertising: false,
  hasUnsyncedTemp: 2,
  mockFWVersion: false,
  fwVersion: '4.12.1',
}

const actions: any = {
  extendBluetoothPluginMock(
    state: State,
    action: { type: string; payload: Partial<BluetoothPluginMockState> },
  ): State {
    return {
      ...state,
      ...action.payload,
    }
  },
}

export const bluetoothPluginMockReducer: Reducer<State> = (
  // biome-ignore lint/style/useDefaultParameterLast: ok
  state = initialState,
  action: Action,
) => {
  if (actions[action.type]) return actions[action.type](state, action)
  return state
}
