import { inject, Injectable } from '@angular/core'
import {
  _groupBy,
  _pick,
  _sortBy,
  _sortDescBy,
  _stringMapValues,
  IsoDate,
  UnixTimestamp,
} from '@naturalcycles/js-lib'
import { DailyEntryBM, DataFlag, DEVIATION_REASON_FLAGS } from '@naturalcycles/shared'
import { ICON, ICON_BY_DATAFLAG } from '@src/app/cnst/icons.cnst'
import { PopupController, Priority } from '@src/app/srv/popup.controller'
import { MultipleEntriesModal } from './multiple-entries.modal'

export interface MultipleEntriesModalInput {
  title?: string
  body?: string
  dismissTitle?: string
  editable?: boolean
  entries: DailyEntryBM[]
}

export interface MultipleEntryItems {
  entries: MultipleEntryItem[]
  date: IsoDate
  hasSavedTemps: boolean
}

export interface MultipleEntryItem {
  date: IsoDate
  temperature?: number
  temperatureUpdatedTimestamp?: UnixTimestamp
  temperatureMeasuredTimestamp?: UnixTimestamp
  deviating?: boolean
  icon?: EntryIcon
  updated?: UnixTimestamp
}

interface EntryIcon {
  icon: ICON
  color: string
}

@Injectable({ providedIn: 'root' })
export class MultipleEntriesService {
  private popupController = inject(PopupController)

  public async showMultipleEntriesModal(input: MultipleEntriesModalInput): Promise<DailyEntryBM[]> {
    const entryItems = this.entriesToMultipleEntryItems(input.entries)

    const modal = await this.popupController.presentModal(
      {
        component: MultipleEntriesModal,
        componentProps: {
          ...input,
          entryItems,
        },
        cssClass: ['modal--transparent', 'modal--alert'],
        backdropDismiss: false,
      },
      'modal-multipleEntries',
      Priority.VERY_HIGH,
    )

    const { data } = await modal.onWillDismiss()

    return data || []
  }

  public entriesToMultipleEntryItems(entries: DailyEntryBM[]): MultipleEntryItems[] {
    const multipleEntries = entries.map(entry => this.entryToMultipleEntryItem(entry))
    // days should be sorted in descending order
    _sortDescBy(multipleEntries, de => de.date, true)
    const entriesByDate = _groupBy(multipleEntries, de => de.date)

    return _stringMapValues(entriesByDate).map(daysEntries => ({
      // show the earliest temperature for each day first; manual temp always on top
      entries: _sortBy(daysEntries, de => de.temperatureMeasuredTimestamp || 0),
      hasSavedTemps: daysEntries.some(entry => !!entry.updated),
      date: daysEntries[0]!.date,
    }))
  }

  private entryToMultipleEntryItem(entry: DailyEntryBM): MultipleEntryItem {
    const deviationReason = entry.dataFlags.find(f => DataFlag[f]?.startsWith('DEVIATION_REASON_'))
    const ouraFlag = entry.dataFlags.find(f => DataFlag[f]?.startsWith('OURA_'))

    const iconName = ICON_BY_DATAFLAG[deviationReason || ouraFlag!]
    const icon = iconName || (entry.temperature ? undefined : ICON.CLOSE)

    const color = iconName ? 'white' : 'black'

    return {
      ..._pick(entry, [
        'date',
        'temperature',
        'temperatureMeasuredTimestamp',
        'temperatureUpdatedTimestamp',
        'updated',
      ]),
      deviating: entry.dataFlags.some(flag => DEVIATION_REASON_FLAGS.has(flag)),
      icon: icon && {
        icon,
        color,
      },
    }
  }
}
