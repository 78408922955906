import { BackendResponseFM, PartnerAccountFM, Reminders } from '@naturalcycles/shared'
import { Action, Reducer } from 'redux'

type State = PartnerAccountFM | null

const initialState: State = null

const actions: any = {
  extendPartnerAccountReminders(state: State, action: { type: string; payload: Reminders }): State {
    if (state === null) return null

    return {
      ...state,
      reminders: {
        ...state?.reminders,
        ...action.payload,
      },
    }
  },

  onBackendResponse(state: State, action: { type: string; payload: BackendResponseFM }): State {
    // replace!
    if (action.payload.partnerAccount) {
      return {
        ...action.payload.partnerAccount,
      }
    }

    return state
  },
}

// biome-ignore lint/style/useDefaultParameterLast: ok
export const partnerAccountReducer: Reducer<State> = (state = initialState, action: Action) => {
  if (actions[action.type]) return actions[action.type](state, action)
  return state
}
