import { sentryService } from '@app/srv/sentry.service'
import { _createPromiseDecorator, _stringify } from '@naturalcycles/js-lib'

interface TryCatchDecoratorParams {
  /**
   * If onError returns void or true the following steps are executed. They're skipped if onError returns false
   *
   * - console.error(err) the error
   * - SentryService.captureException(err)
   */
  onError?: (err: any) => boolean | void
  /**
   * @default true
   */
  alert?: boolean
}

/**
 * Wraps a promise-returning method with try/catch.
 *
 * On success - does nothing.
 *
 * On catch:
 * 1. if (params.alert) - alerts the error (default: true!)
 * 2. if (params.onError) - calls it
 *
 * If params.onError returns void or true the following steps are executed. They're skipped if onError returns false
 *
 * - console.error(err) the error
 * - SentryService.captureException(err)
 */
export const tryCatch = (params: TryCatchDecoratorParams = { alert: true }): MethodDecorator =>
  _createPromiseDecorator(
    {
      decoratorName: 'tryCatch',
      catchFn: ({ err, target, key }) => {
        const msg = _stringify(err)
        const methodSignature = _getTargetMethodSignature(target, key)

        if (params.alert) {
          alert(`@tryCatch error (${methodSignature}):\n${msg}`)
        }

        let reportError: boolean | void = true
        // cause we don't want error in error handling code
        try {
          reportError = params.onError?.(err)
        } catch {}

        // skip reporting to Sentry if params.onError returns false
        if (reportError === false) return

        sentryService.captureException(err)
      },
    },
    params,
  )

// copied from js-lib
function _getTargetMethodSignature(target: any, keyStr: string): string {
  return `${target.constructor.name}.${keyStr}`
}
