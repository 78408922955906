import { registerLocaleData } from '@angular/common'
import localeEn from '@angular/common/locales/en'
import localeEnGb from '@angular/common/locales/en-GB'
import localeEs from '@angular/common/locales/es'
import localePt from '@angular/common/locales/pt'
import localeSv from '@angular/common/locales/sv'
import { inject, Injectable } from '@angular/core'
import { ICON, ICON_BY_DATAFLAG, ICON_PATH } from '@app/cnst/icons.cnst'
import { select } from '@app/srv/store.service'
import { urlUtil } from '@app/util/url.util'
import { _mapValues } from '@naturalcycles/js-lib'
import { LANG, LANG_DEFAULT, toSupportedLanguageOrUndefined } from '@naturalcycles/shared'
import { dayjs } from '@naturalcycles/time-lib'
import { TranslateService } from '@ngx-translate/core'
import { GRAPH_ICON_BY_DATAFLAG } from '@src/app/pages/graph/srv/graph.cnst'
import { Observable } from 'rxjs'
import { dispatch, getState } from './store.service'

// These are only used for getting date formats, ex getLocaleDateFormat in DateService
registerLocaleData(localeEn)
registerLocaleData(localeEnGb)
registerLocaleData(localeEs)
registerLocaleData(localePt)
registerLocaleData(localeSv)

@Injectable({ providedIn: 'root' })
export class LangService {
  private translateService = inject(TranslateService)
  @select(['account', 'firstDayOfWeek'])
  private fdow$!: Observable<number>

  @select(['account', 'lang'])
  private lang$!: Observable<LANG>

  private _locale!: string
  get locale(): string {
    return this._locale || LANG_DEFAULT
  }

  set locale(locale: string) {
    this._locale = locale
  }

  async init(): Promise<{ lang: LANG; locale: string }> {
    const { account, userLocale } = getState()
    const accountLang = account.lang
    const qsLang = urlUtil.getLocationQueryString()['lang']
    const browserLocale = this.getDeviceLanguage()
    const userCountry = userLocale.country

    const lang = this.detectLang(accountLang, qsLang, browserLocale)

    const locale = this.detectLocale(lang, browserLocale, userCountry)

    this.setLocale(lang, locale)

    this.fdow$.subscribe(fdow => {
      this.updateFirstDayOfWeek(fdow)
    })

    this.lang$.subscribe(lang => {
      this.updateLocalizedIcons(lang)
    })

    await this.translateService.get('day').toPromise() // wait for translations to be ready

    document.documentElement.setAttribute('lang', lang)

    return { lang, locale }
  }

  getDeviceLanguage(): string | undefined {
    return this.translateService.getBrowserCultureLang()
  }

  private detectLang(_accountLang?: string, _qsLang?: string, _browserLang?: string): LANG {
    const accountLang = toSupportedLanguageOrUndefined(_accountLang)
    const qsLang = toSupportedLanguageOrUndefined(_qsLang)
    const browserLang = toSupportedLanguageOrUndefined(_browserLang)

    return accountLang || qsLang || browserLang || LANG_DEFAULT
  }

  /**
   * Takes "left part" from the Language and "right part" from the country.
   * E.g lang=en-US plus country=SE will make it into `en-SE`.
   *
   * This is to be able to support locale-aware date formatting, while still keeping user's preferred Language.
   */
  private detectLocale(lang: LANG, browserLocale: string | undefined, country: string): string {
    const langPart = lang.substr(0, 2)

    // 2. Get locale from browser
    if (browserLocale?.includes('-')) {
      return `${langPart}-${browserLocale.split('-')[1]}`
    }

    // 3. Get locale from userLocale.country
    if (country) {
      return `${langPart}-${country}`
    }

    // 4. Use lang
    return lang
  }

  private setLocale(lang: LANG, locale: string): void {
    this.locale = locale

    this.translateService.use(lang)
    dispatch('setLocale', { lang, locale })
  }

  private updateFirstDayOfWeek(firstDayOfWeek = 1): void {
    // treat 7 as 0, cause both Moment and Dayjs don't support 7
    firstDayOfWeek = firstDayOfWeek === 7 ? 0 : firstDayOfWeek

    // Needed for calendar.component
    dayjs.updateLocale(dayjs.locale()[0]!, {
      week: {
        dow: firstDayOfWeek,
        doy: firstDayOfWeek,
      },
    })

    dayjs.updateLocale(dayjs.locale(), {
      weekStart: firstDayOfWeek,
    })
  }

  private updateLocalizedIcons(lang: LANG): void {
    _mapValues(ICON_BY_DATAFLAG, (_, value) => ICON_BY_LANG[lang]?.[value] || value, true)
    _mapValues(ICON, (_, value) => ICON_BY_LANG[lang]?.[value] || value, true)
    _mapValues(
      GRAPH_ICON_BY_DATAFLAG,
      (_, value) => {
        const icon = ICON_BY_LANG[lang]?.[value?.icon]

        if (icon) {
          return {
            ...value,
            icon,
          }
        }

        return value
      },
      true,
    )
  }
}

const ICON_BY_LANG: Partial<Record<LANG, Partial<Record<ICON, string>>>> = {
  [LANG.es_US]: {
    [ICON.MOOD_PMS]: `${ICON_PATH}mood-pms-es.svg`,
  },
  [LANG.pt_BR]: {
    [ICON.MOOD_PMS]: `${ICON_PATH}mood-pms-pt.svg`,
  },
}
