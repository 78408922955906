import { Storage3LocalStorageAdapter } from '@app/srv/storage3/storage3.localStorage.adapter'
import { jsonUtil } from '@app/util/json.util'
import { StringMap } from '@naturalcycles/js-lib'
import { backendResponseReviver } from '@naturalcycles/shared'

/**
 * Interface resemble the interface of window.localStorage.
 * But uses Promises.
 */
export interface Storage3Adapter {
  getItem: (key: string) => Promise<string | undefined>
  setItem: (key: string, value: string) => Promise<void>
  removeItem: (key: string) => Promise<void>
  keys: () => Promise<string[]>
  clear: (keepKeys?: string[]) => Promise<void>
}

class Storage3Service {
  adapter: Storage3Adapter = new Storage3LocalStorageAdapter() // default, to make tests pass

  init(_adapter: Storage3Adapter): void {
    this.adapter = _adapter
  }

  async hasItem(k: string): Promise<boolean> {
    return !!(await this.getItem(k))
  }

  async getItem<T = any>(key: string, json = false, skipReviver = false): Promise<T | undefined> {
    // cast to null to undefined
    const item = (await this.adapter.getItem(key)) || undefined

    if (item && json) {
      return jsonUtil.parseSafe<T>(item, skipReviver ? undefined : backendResponseReviver, key)
    }
    return item as any
  }

  /**
   * If string is passed - saves as string.
   * If undefined/null value is passed - REMOVES the item.
   * Otherwise - saves as JSON.stringify(value)
   */
  async setItem(key: string, value: any): Promise<void> {
    // auto-detect if we need to JSON.stringify the value
    if (typeof value === 'string') {
      await this.adapter.setItem(key, value)
    } else {
      if (value === undefined || value === null) {
        // If value is empty - we remove item instead of saving `undefined`/`null`/``
        await this.adapter.removeItem(key)
      } else {
        await this.adapter.setItem(key, JSON.stringify(value))
      }
    }
  }

  async removeItem(key: string): Promise<void> {
    await this.adapter.removeItem(key)
  }

  async keys(): Promise<string[]> {
    return await this.adapter.keys()
  }

  async clear(keepKeys: string[] = []): Promise<void> {
    const keep: StringMap<boolean> = {}
    for (const k of keepKeys) {
      keep[k] = true
    }

    const keys = await this.adapter.keys()
    const promises = keys.filter(k => !keep[k]).map(k => this.adapter.removeItem(k))
    await Promise.all(promises)
  }
}

export const storage3Service = new Storage3Service()
