import { Injectable } from '@angular/core'
import { PageGuard } from '@app/guards/page.guard'

@Injectable({
  providedIn: 'root',
})
export class DeleteAccountPageGuard extends PageGuard {
  className = 'DeleteAccountPageGuard'

  get requirements(): boolean[] {
    const requirements = [this.hasAccountId(), this.hasSession()]

    return requirements
  }
}
