import { Injectable } from '@angular/core'
import { NavigationParams } from '@app/cnst/nav.params.cnst'
import { PageGuard } from '@app/guards/page.guard'
import { getNavParams } from '@app/srv/nav.service'

@Injectable({
  providedIn: 'root',
})
export class ErrorPageGuard extends PageGuard {
  className = 'ErrorPageGuard'

  get requirements(): boolean[] {
    const params = getNavParams()
    const requirements = [!!params[NavigationParams.ERROR]]

    return requirements
  }
}
