import { DeferredPromise, pDefer, UnixTimestamp } from '@naturalcycles/js-lib'
import { PublicKeyTransport, SigningKeyVault, signingKeyVault } from '@naturalcycles/shared'
import { prf } from '../util/perf.util'
import { dispatch, getState } from './store.service'

class SessionSigningService {
  private keyVault: SigningKeyVault | null = null
  private initCache: DeferredPromise | null = null

  public async init(): Promise<void> {
    if (!getState().remoteConfig.sessionSignaturesEnabled) return

    if (this.initCache) return await this.initCache
    this.initCache = pDefer()

    const initStarted = Date.now()

    if (this.keyVault) {
      this.initCache.resolve()
      this.initCache = null
      return
    }

    const { sessionEncryptionKeys } = getState()
    if (sessionEncryptionKeys) {
      this.keyVault = await signingKeyVault(sessionEncryptionKeys)
      this.initCache.resolve()
      this.initCache = null
      return
    }

    const keyGenerationStarted = Date.now()
    this.keyVault = await signingKeyVault.generateKeyPair()
    prf('SessionSigningService.init.generateKeyPair', keyGenerationStarted)

    const newKeyPair = await this.keyVault.exportKeyPair()
    dispatch('setSessionEncryptionKeys', newKeyPair)

    prf('SessionSigningService.init', initStarted)

    this.initCache.resolve()
    this.initCache = null
  }

  public async sign(sessionId: string, ts: UnixTimestamp): Promise<string | undefined> {
    if (!getState().remoteConfig.sessionSignaturesEnabled) return

    const signStarted = Date.now()

    await this.init()
    const signature = await this.keyVault!.sign(`${sessionId}|${ts}`)

    prf('SessionSigningService.sign', signStarted)

    return signature
  }

  public async getPublicKey(): Promise<PublicKeyTransport | undefined> {
    if (!getState().remoteConfig.sessionSignaturesEnabled) return

    const getPublicKeyStarted = Date.now()

    await this.init()
    const publicKey = await this.keyVault!.exportPublicKey()

    prf('SessionSigningService.getPublicKey', getPublicKeyStarted)
    return publicKey
  }

  public removeKeyPair(): void {
    if (!getState().remoteConfig.sessionSignaturesEnabled) return

    dispatch('setSessionEncryptionKeys', null)
    this.keyVault = null
  }
}

export const sessionSigningService = new SessionSigningService()
