import { inject, Injectable } from '@angular/core'
import { MixPanelService } from '@app/analytics/mixpanel.service'
import { isAndroidApp } from '@app/cnst/userDevice.cnst'
import { decorate, ErrorHandlerType, LoaderType } from '@app/decorators/decorators'
import { api } from '@app/srv/api.service'
import { storage3Service } from '@app/srv/storage3/storage3.service'
import { logUtil } from '@app/util/log.util'
import { pDelay, pHang } from '@naturalcycles/js-lib'
import { BackendResponseFMResp, GetMySessionsResponse } from '@naturalcycles/shared'
import { PopupController, Priority } from '@src/app/srv/popup.controller'
import { tr } from '@src/app/srv/translation.util'
import { GoogleAuth } from '@src/typings/capacitor'
import { MoeService } from '../analytics/moe.service'
import { BadgeService } from './badge.service'
import { LocalNotificationService } from './notification.local.service'
import { sessionSigningService } from './sessionSigning.service'
import { dispatch, StoreService, USER_DEVICE, USER_SETTINGS } from './store.service'

@Injectable({ providedIn: 'root' })
export class SessionService {
  private storeService = inject(StoreService)
  private mixpanelService = inject(MixPanelService)
  private popupController = inject(PopupController)
  private badgeService = inject(BadgeService)
  private localNotificationService = inject(LocalNotificationService)
  private moeService = inject(MoeService)

  async handleInvalidSession(): Promise<void> {
    const alert = await this.popupController.presentAlert(
      {
        header: tr('alert-session-invalid-title'),
        message: tr('alert-session-invalid-body'),
        buttons: ['OK'],
      },
      'alert-sessionInvalid',
      Priority.HIGH,
      0,
    )
    await alert.onDidDismiss()

    await this.logout() // this reloads and awaits
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  public async logout(reloadBrowser = true): Promise<void> {
    try {
      await this.deleteSession().catch(() => {})

      this.storeService.persistenceEnabled = false

      await Promise.all([
        isAndroidApp && GoogleAuth.signOut(),
        this.moeService.resetUser(),
        this.mixpanelService.reset(),
        this.badgeService.clearBadge(),
        this.localNotificationService.cancelAll(),
      ])
    } catch (err) {
      // log it and continue
      logUtil.log('Error logging out')
      logUtil.error(err)
    }

    if (reloadBrowser) {
      await storage3Service.clear([USER_SETTINGS, USER_DEVICE])
      location.href = location.href.split('#')[0]!
      return await pHang()
    }

    sessionSigningService.removeKeyPair()
    this.storeService.dispatch('logout')
    await storage3Service.clear([USER_SETTINGS, USER_DEVICE])

    return await pDelay(100)
  }

  public async deleteSession(): Promise<void> {
    await api.delete('sessions')
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  public async deleteOtherSessions(): Promise<void> {
    await api.delete('sessions/other')

    dispatch('extendUserSettings', { multipleSessions: false })
  }

  public async getSessions(): Promise<void> {
    const response = await api.get<GetMySessionsResponse>('sessions/my')

    dispatch('extendUserSettings', { multipleSessions: response.sessions.length > 1 })
  }

  /**
   * Returns `sessionId` or undefined if login failed
   */
  public async consumeToken(token: string): Promise<string | undefined> {
    try {
      const r = await api.post<BackendResponseFMResp>('sessions/token', {
        json: { token }, // interface TokenInput is not shared
      })

      return r.backendResponse.res || ''
    } catch (err) {
      logUtil.log('Error consuming token')
      logUtil.error(err)

      return // undefined
    }
  }
}
