import {
  BreakpointAction,
  ChoiceFunction,
  HormonalBirthControlUsageBreakpointOption,
  QuizDataInput,
} from '@naturalcycles/shared'
import { Section, sectionSexQuestions } from '@src/app/cnst/quiz.cnst'
import { QuizPage } from '../quiz-pages.cnst'

const commonTransformFunction: ChoiceFunction<QuizPage, QuizDataInput> = flow => {
  flow[Section.cycle] = [
    ...sectionSexQuestions,
    QuizPage.hormonalBirthControlUsage,
    QuizPage.plannedPregnancyInfo,
    QuizPage.cycleLength,
    QuizPage.periodRegularity,
    QuizPage.periodRegularityInfo,
    QuizPage.cycleStartDate,
    QuizPage.medicalConditionsPlan,
  ]
  return flow
}

export const hormonalBirthControlUsage: BreakpointAction<
  HormonalBirthControlUsageBreakpointOption,
  QuizPage,
  QuizDataInput
> = {
  [HormonalBirthControlUsageBreakpointOption.lastMonth]: flow => {
    flow[Section.cycle] = [
      ...sectionSexQuestions,
      QuizPage.hormonalBirthControlUsage,
      QuizPage.hormonalBirthControlHowLongPlan,
    ]
    return flow
  },
  [HormonalBirthControlUsageBreakpointOption.monthsAgo]: commonTransformFunction,
  [HormonalBirthControlUsageBreakpointOption.no]: commonTransformFunction,
}
