import { Capacitor } from '@capacitor/core'

/**
 * Returns true if running on native iOS app
 */
export const isIOSApp = Capacitor.getPlatform() === 'ios'
/**
 * Returns true if running on native Android app
 */
export const isAndroidApp = Capacitor.getPlatform() === 'android'
/**
 * Returns true if running on the web
 */
export const isWebApp = Capacitor.getPlatform() === 'web'
/**
 * Is a native app. Returns true for both iOS and Android
 */
export const isNativeApp = Capacitor.isNativePlatform()
