import { Injectable } from '@angular/core'
import { Goal } from '@naturalcycles/shared'
import { NavigationParams } from '@src/app/cnst/nav.params.cnst'
import { di } from '@src/app/srv/di.service'
import { getState } from '@src/app/srv/store.service'
import { UFService } from '@src/app/srv/uf.service'
import { PageGuard } from '../../guards/page.guard'
import { FlowId } from './flow.cnst'
import { PregnancyEndTrigger } from './pregnancy-end/pregnancy-end.cnst'

@Injectable({
  providedIn: 'root',
})
export class FlowGuard extends PageGuard {
  className = 'FlowGuard'

  get requirements(): boolean[] {
    const id = this.route.params[NavigationParams.FLOW] as FlowId

    switch (id) {
      case FlowId.ONBOARDING:
        return this.onboardingRequirements

      case FlowId.PREGNANCY_END:
        return this.pregnancyEndRequirements

      case FlowId.UNPLANNED_PREGNANCY:
        return this.unplannedPregnancyRequirements

      default:
        return [false]
    }
  }

  private get onboardingRequirements(): boolean[] {
    const {
      userFertility: { entryMap },
      account,
    } = getState()

    const isNotCompletedUser = !account.completeDate
    const isDemoMode = !!account.demoMode

    const requirements = [
      this.hasAccountId(),
      isNotCompletedUser || isDemoMode || di.get(UFService).canRedoOnboarding(entryMap, account),
    ]

    return requirements
  }

  private get pregnancyEndRequirements(): boolean[] {
    const { flowData, account } = getState()

    const trigger = flowData['trigger']

    return [!!trigger, account.pregnantNow || trigger === PregnancyEndTrigger.ONBOARDING]
  }

  private get unplannedPregnancyRequirements(): boolean[] {
    const { flowData, account } = getState()

    const isPreventingOrPostpartuming =
      account.goal === Goal.PREVENT || account.goal === Goal.POSTPARTUM

    return [isPreventingOrPostpartuming, !!flowData['trigger'], !account.pregnantNow]
  }
}
