import { CalendarTemplate } from './_templates/calendar/flow-calendar.page'
import { CheckboxGroupTemplate } from './_templates/checkbox-group/flow-checkbox-group.page'
import { DateInputTemplate } from './_templates/date-input/flow-date-input.page'
import { RadioGroupTemplate } from './_templates/radio-group/flow-radio-group.page'
import { SingleSelectTemplate } from './_templates/single-select/flow-single-select.page'
import { TextInputTemplate } from './_templates/text-input/flow-text-input.page'

type OrNull<T> = { [K in keyof T]: T[K] | null }

export enum FlowId {
  ONBOARDING = 'onboarding',
  PREGNANCY_END = 'pregnancy-end',
  UNPLANNED_PREGNANCY = 'unplanned-pregnancy',
}

export type FlowInputData<T> = Partial<OrNull<T>>

export type GetSideEffectsFn = (input: FlowInputData<any>) => FlowInputData<any> | undefined

export interface FlowBasePageTemplate {
  template: FlowTemplate
  analyticsPageName: string
  title: string
  body?: string
  /**
   * Creates FlowInputData based on the input and returns the side effects.
   * Side effect can be:
   * null: page is not applicable due to previous answers - page will be skipped
   * undefined: page is applicable but not filled yet
   * any other value: current input data is also filling other data
   */
  sideEffects?: GetSideEffectsFn
}

export enum FlowTemplate {
  /**
   * List of buttons where you pick one answer.
   *
   * Template: {@link SingleSelectTemplate}
   */
  SINGLE_SELECT = 'single-select',

  /**
   * List of text input fields.
   *
   * Template: {@link TextInputTemplate}
   */
  TEXT_INPUT = 'text-input',

  /**
   * An input field which opens a date picker.
   *
   * Template: {@link DateInputTemplate}
   */
  DATE_INPUT = 'date-input',

  /**
   * A group of checkboxes where you can pick multiple answers.
   *
   * Template: {@link CheckboxGroupTemplate}
   */
  CHECKBOX = 'checkbox',

  /**
   * One or multiple radio groups where you can pick one answer in each group.
   *
   * Template: {@link RadioGroupTemplate}
   */
  RADIO = 'radio',

  /**
   * Calendar where you pick one date.
   *
   * Template: {@link CalendarTemplate}
   */
  CALENDAR = 'calendar',

  /**
   * An input field which opens a picker with values.
   *
   * Template: {@link PickerTemplate}
   */
  PICKER = 'picker',

  /**
   * Two input fields to add height and weight
   *
   * Template: {@link HeightAndWeightTemplate}
   */
  HEIGHT_AND_WEIGHT = 'height-and-weight',

  /**
   * An info screen that doesn't input any data.
   *
   * Template: {@link InfoTemplate}
   */
  INFO = 'info',

  /**
   * An input where you select a number
   *
   * Template: {@link StepperTemplate}
   */
  STEPPER = 'stepper',

  /**
   * An page where you add notes in a textarea
   *
   * Template: {@link NotesTemplate}
   */
  NOTES = 'notes',

  /**
   * A page where you can edit your measuring reminders
   *
   * Template: {@link RemindersTemplate}
   */
  REMINDERS = 'reminders',

  /**
   * A page where you can edit your push notifications
   *
   * Template: {@link NotificationsTemplate}
   */
  NOTIFICATIONS = 'notifications',
}

export interface FlowButton {
  uid: string
  label: string
}

export type FlowConfig =
  | FlowBasePageTemplate
  | SingleSelectTemplate<any>
  | CalendarTemplate
  | CheckboxGroupTemplate
  | RadioGroupTemplate<any>
  | TextInputTemplate
  | DateInputTemplate

export interface FlowPage {
  route: string
  config: FlowBasePageTemplate
  key?: string
}
