import { inject, Injectable } from '@angular/core'
import { dispatch } from '@app/srv/store.service'
import { _findLast, _sortDescBy, localDate } from '@naturalcycles/js-lib'
import { DataFlag } from '@naturalcycles/shared'
import { map } from 'rxjs/operators'
import { UFEntry } from '../model/uf.model'
import { UFService } from './uf.service'

@Injectable({ providedIn: 'root' })
export class MeasureStreakService {
  private ufService = inject(UFService)

  public init(): void {
    this.ufService.ufEntries$
      .pipe(map(entries => entries.filter(e => !e.prediction)))
      .subscribe(entries => this.handleMeasureStreak(_sortDescBy(entries, e => e.date)))
  }

  private handleMeasureStreak(entries: UFEntry[]): void {
    const current = this._getCurrentMeasureStreak(entries)
    const streaks = this.getMeasureStreaks(entries)
    const numberOfStreaks = this._getNumberOfMeasureStreaks(streaks)
    const daysSinceTemp = this._getDaysSinceLastTemp(entries)
    const lastStreakLength = this._getLastStreakLength(entries)
    const longest = this.getLongestMeasureStreak(streaks)

    dispatch('extendMeasureStreak', {
      current,
      numberOfStreaks,
      longest,
      daysSinceTemp,
      lastStreakLength,
    })
  }

  public _getCurrentMeasureStreak(entries: UFEntry[]): number {
    let count = 0

    for (const entry of entries) {
      if (this.isMatchingStreakCriteria(entry)) count++
      else break
    }

    return count
  }

  public _getLastStreakLength(entries: UFEntry[]): number {
    let count = 0
    let endOfStreakFound = false

    for (const entry of entries) {
      if (this.isMatchingStreakCriteria(entry)) count++
      else {
        if (endOfStreakFound && count > 0) return count

        count = 0
        endOfStreakFound = true
      }
    }

    return count
  }

  public _getNumberOfMeasureStreaks(streaks: number[]): number {
    return streaks.length
  }

  public getLongestMeasureStreak(streaks: number[]): number {
    return Math.max(0, ...streaks)
  }

  public getMeasureStreaks(entries: UFEntry[]): number[] {
    const streaks: number[] = []
    let currentStreak = 0

    for (const entry of entries) {
      if (this.isMatchingStreakCriteria(entry)) {
        currentStreak++
      } else {
        if (currentStreak > 1) streaks.push(currentStreak)

        currentStreak = 0
      }
    }

    if (currentStreak > 1) streaks.push(currentStreak)

    return streaks
  }

  public _getDaysSinceLastTemp(entries: UFEntry[]): number {
    const lastValidEntry = _findLast(entries, entry => this.isMatchingStreakCriteria(entry))

    if (!lastValidEntry) return -1

    const todayDate = entries.find(e => e.today)?.date

    return localDate.orToday(todayDate).diff(lastValidEntry.date, 'day')
  }

  private isMatchingStreakCriteria(entry: UFEntry): boolean {
    const { temperature, deviationReasons } = entry

    return !!temperature || !!deviationReasons?.includes(DataFlag.OURA_DEVIATION_ALGO)
  }
}
