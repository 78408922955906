import { inject, Injectable } from '@angular/core'
import { LinkSource } from '@app/cnst/nav.cnst'
import { NavService } from '@app/srv/nav.service'
import { App, URLOpenListenerEvent } from '@capacitor/app'
import { DynamicLinks } from '@src/typings/capacitor'
import { urlUtil } from '../util/url.util'
import { firstPageOpened, storeServiceInitialized } from './milestones'
import { dispatch } from './store.service'

const HOST = 'app.naturalcycles.com'

@Injectable({ providedIn: 'root' })
export class DeeplinkService {
  private navService = inject(NavService)

  init(): void {
    void App.addListener(
      'appUrlOpen',
      (data: URLOpenListenerEvent) => void this.handleDeeplink(data.url),
    )

    void DynamicLinks.addListener('dynamicLinkOpen', data => {
      void this.handleDeeplink(data.url)
    })

    // const url = 'https://naturalcycles.page.link/?link=https%3A%2F%2Fapp.naturalcycles.com%2Fpartner-login%3Fname%3DStina%26email%3Dstina%40naturalcycles.com%26token%3Dxxxx&apn=com.naturalcycles.cordova&amv=404000&ibi=com.naturalcycles.cordova&isi=765535549&ius=nc&efr=1'
    // this.handleDeeplink(url)
  }

  private async handleDeeplink(url: string): Promise<void> {
    // firebase dynamic link
    if (url.startsWith('https://naturalcycles.page.link')) {
      const search = urlUtil.parseUrl(url).search

      const params = urlUtil.getQueryString(search)

      url = params['deep_link_id'] || params['link'] || url
    }

    const link = url.split(HOST)[1]

    if (!link) return

    await storeServiceInitialized

    dispatch('setBlockAutoOpen', true)

    await firstPageOpened

    await this.navService.processInternalLink(link, LinkSource.DEEPLINK)
  }
}
