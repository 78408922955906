import { _deepCopy } from '@naturalcycles/js-lib'
import {
  BreakpointAction,
  CycleChangesBreakpointOption,
  QuizDataInput,
} from '@naturalcycles/shared'
import {
  AppQuizFlow,
  Section,
  sectionCycleChangesEnd,
  sectionCycleChangesStart,
} from '@src/app/cnst/quiz.cnst'
import { QuizPage } from '../quiz-pages.cnst'

const mapOptionToPage: Partial<Record<CycleChangesBreakpointOption, QuizPage>> = {
  [CycleChangesBreakpointOption.emotions]: QuizPage.cycleChangesEmotions,
  [CycleChangesBreakpointOption.bleeding]: QuizPage.cycleChangesBleeding,
  [CycleChangesBreakpointOption.skinChanges]: QuizPage.cycleChangesSkin,
  [CycleChangesBreakpointOption.sexAndLibido]: QuizPage.cycleChangesSexAndLibido,
  [CycleChangesBreakpointOption.cervicalMucus]: QuizPage.cycleChangesCervicalMucus,
  [CycleChangesBreakpointOption.pain]: QuizPage.cycleChangesPain,
}

const commonTransformFunction = (
  flow: AppQuizFlow,
  choice: CycleChangesBreakpointOption,
): AppQuizFlow => {
  const page = mapOptionToPage[choice]!
  const f = _deepCopy(flow)

  if (f[Section.cycleChanges]?.includes(page)) {
    /**
     * Delete page from the section
     */
    const pageIndex = f[Section.cycleChanges].indexOf(page)
    f[Section.cycleChanges] = f[Section.cycleChanges].filter((_value, index) => {
      return index !== pageIndex
    })
    return f
  }

  /**
   * Add page in the section
   */
  const startIndex = sectionCycleChangesStart.length
  f[Section.cycleChanges] ||= [...sectionCycleChangesStart, ...sectionCycleChangesEnd]
  f[Section.cycleChanges].splice(startIndex, 0, page)
  return f
}

export const cycleChanges: BreakpointAction<CycleChangesBreakpointOption, QuizPage, QuizDataInput> =
  {
    [CycleChangesBreakpointOption.emotions]: flow => {
      return commonTransformFunction(flow, CycleChangesBreakpointOption.emotions)
    },
    [CycleChangesBreakpointOption.bleeding]: flow => {
      return commonTransformFunction(flow, CycleChangesBreakpointOption.bleeding)
    },
    [CycleChangesBreakpointOption.skinChanges]: flow => {
      return commonTransformFunction(flow, CycleChangesBreakpointOption.skinChanges)
    },
    [CycleChangesBreakpointOption.sexAndLibido]: flow => {
      return commonTransformFunction(flow, CycleChangesBreakpointOption.sexAndLibido)
    },
    [CycleChangesBreakpointOption.cervicalMucus]: flow => {
      return commonTransformFunction(flow, CycleChangesBreakpointOption.cervicalMucus)
    },
    [CycleChangesBreakpointOption.pain]: flow => {
      return commonTransformFunction(flow, CycleChangesBreakpointOption.pain)
    },
    [CycleChangesBreakpointOption.skip]: flow => {
      flow[Section.cycleChanges] = [...sectionCycleChangesStart, ...sectionCycleChangesEnd]
      return flow
    },
  }
