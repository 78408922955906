import { inject, Injectable } from '@angular/core'
import { EVENT } from '@app/analytics/analytics.cnst'
import { AnalyticsService } from '@app/analytics/analytics.service'
import { decorate, ErrorHandlerType } from '@app/decorators/decorators'
import { MeasureReminder } from '@app/reducers/notification.reducer'
import { AccountService } from '@app/srv/account.service'
import { di } from '@app/srv/di.service'
import { NotificationService } from '@app/srv/notification.service'
import { PartnerService } from '@app/srv/partner.service'
import { dispatch, getState, select2 } from '@app/srv/store.service'
import { tr, trIfExists } from '@app/srv/translation.util'
import { distinctUntilDeeplyChanged } from '@app/util/distinctUntilDeeplyChanged'
import {
  _lastOrUndefined,
  _objectAssign,
  _objectKeys,
  _range,
  _shuffle,
  _stringMapValues,
  localDate,
  StringMap,
} from '@naturalcycles/js-lib'
import {
  AccountTM,
  appTrackerByReminder,
  HardwareId,
  PartnerAccountFM,
  ReminderKey,
  Reminders,
} from '@naturalcycles/shared'
import { dayjs, IDayjs } from '@naturalcycles/time-lib'
import { combineLatestWith, distinctUntilChanged, map } from 'rxjs/operators'
import {
  numberOfReminderCopys,
  partnerReminderKeysByGoal,
  reminderKeyAllowListByHwId,
  ReminderKeys,
  reminderKeysByGoal,
  ReminderSetting,
  ReminderType,
} from './reminders.cnst'

@Injectable({ providedIn: 'root' })
export class RemindersService {
  public remindersOn$ = select2(s => s.account).pipe(
    combineLatestWith(select2(s => s.partnerAccount)),
    map(([account, partnerAccount]) => {
      return this.hasAnyReminderEnabled(account, partnerAccount)
    }),
  )

  public showMeasuringReminder$ = select2(s => s.account).pipe(
    map(account => !!numberOfReminderCopys[account.hwId].MEASURING),
  )

  public showPreparationReminder$ = select2(s => s.account).pipe(
    map(account => !!numberOfReminderCopys[account.hwId].PREPARATION),
  )

  private localNotifications$ = select2(s => s.notifications.local.items)
  private account$ = select2(s => s.account)
  private todayDate$ = select2(s => s.userFertility.todayDate)

  readonly TIME_FORMAT = 'HH:mm'

  private accountService = inject(AccountService)
  private partnerService = inject(PartnerService)
  private notificationService = inject(NotificationService)

  public async init(): Promise<void> {
    const available = await this.notificationService.available()
    if (!available) return

    const { account, partnerAccount } = getState()
    const isPartner = !!partnerAccount

    this.setupSubscriptions(isPartner)

    if (isPartner) return

    await this.fillUndefinedLocalMeasuringReminder(account)
  }

  public toggleLocalNotification(isEnabled: boolean, notificationId: number): void {
    dispatch('enableLocalNotification', {
      isEnabled,
      notificationId,
    })
  }

  public toggleLocalNotificationDay(day: number, enabled: boolean, notificationId: number): void {
    dispatch('toggleLocalNotificationDay', {
      notificationId,
      weekday: day,
      enabled,
    })
  }

  public toggleLocalNotificationsAllDays(enabled: boolean, notificationId: number): void {
    const notification = getState().notifications.local.items.find(
      notification => notification.id === notificationId,
    )!

    const days = {} as MeasureReminder['days']

    _objectKeys(notification.days).forEach((_, index) => {
      _objectAssign(days, {
        [index + 1]: {
          ...notification.days[index + 1],
          enabled,
        },
      })
    })

    dispatch('updateNotification', {
      ...notification,
      days,
    })
  }

  public updateLocalNotificationTime(dayjs: IDayjs, notificationId: number): void {
    const time = dayjs.format(this.TIME_FORMAT)

    dispatch('updateLocalNotificationTime', {
      notificationId,
      time,
    })
  }

  public removeNotificationsOfType(type: ReminderType): void {
    const { notifications } = getState()
    const newNotifications = notifications.local.items.filter(
      notification => notification.type !== type,
    )
    dispatch('extendNotifications', {
      ...notifications,
      local: {
        ...notifications.local,
        items: newNotifications,
      },
    })
  }

  public getCycleReminders(account: AccountTM): ReminderSetting[] {
    const { reminders, hwId, goal } = account
    const keysByGoal = reminderKeysByGoal[goal!] || []

    const keys = [...keysByGoal]
    const filteredKeys = keys.filter(k => {
      const allowedHwIds = reminderKeyAllowListByHwId[k]

      if (!allowedHwIds) return true // no restriction

      return allowedHwIds.includes(hwId)
    })

    return filteredKeys.map(key => ({ key, enabled: !!reminders[key] }))
  }

  public getPartnerCycleReminders(
    account: AccountTM,
    reminders: Reminders = {},
  ): ReminderSetting[] {
    const {
      goal,
      appSettings: { enabledPartnerTrackers },
    } = account
    const keysByGoal = partnerReminderKeysByGoal[goal!] || []

    const keys = [...keysByGoal].filter(k => {
      const reminder = ReminderKey[k]
      const tracker = appTrackerByReminder[reminder]

      if (!tracker) return true

      return enabledPartnerTrackers?.includes(tracker)
    })

    return keys.map(key => ({ key, enabled: !!reminders[key] }))
  }

  public addOneMoreReminder(
    type: ReminderType,
    enabled = false,
    time?: string,
    id?: number,
  ): MeasureReminder {
    const reminder = this.createNewReminder(type, enabled, time, id)

    dispatch('addReminder', reminder)
    return reminder
  }

  public removeReminder(notificationId: number): void {
    dispatch('removeReminder', { notificationId })
  }

  public async checkPermission(): Promise<boolean> {
    const state = await this.notificationService.getPermissionState()

    if (state === 'granted') return true

    if (state === 'denied') {
      const accepted = await this.notificationService.showNotificationsDisabledAlert()

      return accepted
    }

    if (state === 'prompt' || state === 'prompt-with-rationale') {
      const permission = await this.notificationService.showEnableNotificationsAlert()

      return permission
    }

    return false
  }

  private hasAnyReminderEnabled(
    account: AccountTM,
    partnerAccount: PartnerAccountFM | null,
  ): boolean {
    const reminders = partnerAccount ? partnerAccount.reminders : account.reminders

    return Object.values(reminders).some(Boolean)
  }

  /**
   * This "reminder" was added in v5.3.12, so for old users it might be undefined. If so -> save it to account
   */
  private async fillUndefinedLocalMeasuringReminder(account: AccountTM): Promise<void> {
    const { localMeasuringReminder } = account.reminders

    if (localMeasuringReminder === undefined) {
      const enabled = this.hasLocalNotificationEnabled()

      await this.saveAccountReminders({
        ...account.reminders,
        localMeasuringReminder: enabled,
      })
    }
  }

  private hasLocalNotificationEnabled(): boolean {
    const { notifications } = getState()

    return notifications.local.items.some(n => n.enabled)
  }

  private updateNotificationCopy(reminder: MeasureReminder): void {
    const {
      account: { hwId, lang },
      notifications,
    } = getState()

    const notificationId = reminder.id
    let type = reminder.type

    if (!ReminderType[type]) {
      type =
        (reminder.type as string) === 'MORNING' ? ReminderType.MEASURING : ReminderType.PREPARATION
    }

    const copy = this._getRandomReminderTexts(type, hwId)

    const notification = notifications.local.items.find(
      notification => notification.id === notificationId,
    )

    const days = {} as MeasureReminder['days']

    _range(7).forEach(index => {
      const reminderKeys = copy[index]

      const day = notification?.days[index + 1]

      _objectAssign(days, {
        [index + 1]: {
          title: reminderKeys?.title || '',
          text: reminderKeys?.text || '',
          enabled: reminderKeys && day ? day.enabled : false,
        },
      })
    })

    dispatch('updateNotification', {
      ...notification,
      days,
      lastUpdate: localDate.todayString(),
      lang,
      utcOffset: dayjs().utcOffset(),
      hwId,
      type,
    })
  }

  public checkForOldCopy(
    measureReminders: MeasureReminder[],
    lang: string,
    hwId: HardwareId,
    forceUpdate?: boolean,
  ): void {
    for (const reminder of measureReminders) {
      const lastUpdate = dayjs(reminder.lastUpdate)
      const now = dayjs()

      if (
        !reminder.lastUpdate || // notification was never updated
        now.diff(lastUpdate, 'day') > 6 || // If is has gone more than 6 days
        now.utcOffset() !== reminder.utcOffset || // If utcOffset changed
        reminder.lang !== lang || // If language changed
        reminder.hwId !== hwId || // If hardware changes
        !ReminderType[reminder.type] || // if old types
        forceUpdate // if param is passed
      ) {
        this.updateNotificationCopy(reminder)
      }
    }
  }

  private createNewReminder(
    type: ReminderType,
    enabled = false,
    time?: string,
    id?: number,
  ): MeasureReminder {
    const { account, notifications } = getState()
    const { hwId, lang } = account
    const localNotifications = notifications.local.items

    const newId = (_lastOrUndefined(localNotifications)?.id || 0) + 1

    const notification: MeasureReminder = {
      id: id || newId,
      enabled,
      days: {
        1: { enabled },
        2: { enabled },
        3: { enabled },
        4: { enabled },
        5: { enabled },
        6: { enabled },
        7: { enabled },
      },
      time,
      type,
      hwId,
      lastUpdate: dayjs().toISODate(),
      lang,
      utcOffset: dayjs().utcOffset(),
    }

    const copy = this._getRandomReminderTexts(type, hwId)

    const days = {} as MeasureReminder['days']

    copy.forEach((copy, index) => {
      _objectAssign(days, {
        [index + 1]: {
          title: copy.title,
          text: copy.text,
          enabled,
        },
      })
    })

    return { ...notification, days }
  }

  public trackReminderSettings(onboarding = false): void {
    const reminders = getState().notifications.local.items
    const enabledNotifications = reminders.filter(({ enabled }) => enabled)

    // parse local notifications and build reminderToMeasure1: 18:35, reminderToMeasure2: 09:45, etc
    if (!enabledNotifications.length) return

    const remindersEventProperties: StringMap<string | boolean | number[]> = { onboarding }

    enabledNotifications.forEach((notification, index) => {
      const reminderIndex = index + 1
      const reminderPropertyName = `reminderToMeasure${reminderIndex}`
      const reminderTypePropertyName = `reminderToMeasure${reminderIndex}Type`
      const reminderDaysPropertyName = `reminderToMeasure${reminderIndex}Weekdays`
      const days: number[] = []

      remindersEventProperties[reminderPropertyName] = notification.time
      remindersEventProperties[reminderTypePropertyName] = notification.type

      _stringMapValues(notification.days).forEach(day => days.push(day.enabled ? 1 : 0))
      remindersEventProperties[reminderDaysPropertyName] = days
    })

    void di
      .get(AnalyticsService)
      .trackEvent(EVENT.UPDATE_MEASURING_REMINDERS, remindersEventProperties)
  }

  public trackCycleReminders(reminders: Reminders): void {
    di.get(AnalyticsService).trackEvent(EVENT.UPDATE_CYCLE_REMINDERS, reminders)
  }

  private usesInnerWrist(hwId: HardwareId): boolean | undefined {
    if (hwId !== HardwareId.APPLE_WATCH) return

    return getState()
      .messages.messages.find(m => m.msgKey === 'msg-QA-watchWrist-Apple')
      ?.selectedAnswers?.some(m => m.key === 'msg-QA-watchWrist-Apple-ans2' && m.selected)
  }

  public _getRandomReminderTexts(type: ReminderType, hwId: HardwareId): ReminderKeys[] {
    const copyArr = _range(numberOfReminderCopys[hwId][type])

    if (copyArr.length === 0) {
      return []
    }

    // ideally we should have minimum 7 different copies, but oura doesnt right now, so we'll use the same keys twice
    do {
      copyArr.push(...copyArr)
    } while (copyArr.length < 7)

    const keys: ReminderKeys[] = []
    let titleKey = `reminders-measure--${HardwareId[hwId]}--${type}--title`
    let textKey = `reminders-measure--${HardwareId[hwId]}--${type}--text`
    if (this.usesInnerWrist(hwId)) {
      titleKey = `${titleKey}--innerWrist`
      textKey = `${textKey}--innerWrist`
    }

    _shuffle(copyArr)
      .slice(0, 7)
      .forEach(num => {
        const item = {
          title:
            trIfExists(hwId === HardwareId.APPLE_WATCH ? `${titleKey}--${num + 1}` : titleKey) ||
            tr(`reminders-measure--ORAL_THERMOMETER--${type}--title`),
          text:
            trIfExists(`${textKey}--${num + 1}`) ||
            tr(`reminders-measure--ORAL_THERMOMETER--${type}--text--${num + 1}`),
        }
        keys.push(item)
      })

    return keys
  }

  private setupSubscriptions(isPartner: boolean): void {
    if (isPartner) return

    this.account$
      .pipe(
        distinctUntilDeeplyChanged(),
        combineLatestWith(
          this.localNotifications$.pipe(distinctUntilDeeplyChanged()),
          this.todayDate$.pipe(distinctUntilChanged()),
        ),
      )
      .subscribe(([acc, localNotifications]) =>
        this.checkForOldCopy(localNotifications, acc.lang, acc.hwId),
      )
  }

  public async disableAllNotifications(isPartner: boolean): Promise<void> {
    const { notifications } = getState()

    notifications.local.items.forEach((item: MeasureReminder) => {
      if (item.enabled) {
        this.toggleLocalNotification(false, item.id)
      }
    })

    const pushNotifications: Record<ReminderKey, false> = {
      fertilityStatus: false,
      firstRedDay: false,
      firstGreenDay: false,
      checkLH: false,
      startTesting: false,
      pms: false,
      period: false,
      breastSelfCheck: false,
      fertileDays: false,
      newPregWeek: false,
      newTrimester: false,
      localMeasuringReminder: false,
    }

    if (isPartner) {
      await this.savePartnerReminders(pushNotifications)
    } else {
      await this.saveAccountReminders(pushNotifications)
    }
  }

  public getFirstEnabledMorningReminderTime(): string | undefined {
    const { items } = getState().notifications.local

    if (!items.length) return

    const enabledReminders = items.filter(
      item => item.enabled && item.type === ReminderType.MEASURING,
    )

    if (!enabledReminders.length) return

    return enabledReminders[0]!.time
  }

  @decorate({
    errorHandlerType: ErrorHandlerType.LOG,
  })
  public async savePartnerReminders(reminders: Reminders): Promise<void> {
    await this.partnerService.patchPartner({
      reminders,
    })
  }

  @decorate({
    errorHandlerType: ErrorHandlerType.LOG,
  })
  public async saveAccountReminders(reminders: Reminders): Promise<void> {
    await this.accountService.patch({
      reminders,
    })
  }
}
