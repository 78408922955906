<ion-header>
  <ion-toolbar mode="ios">
    <ion-buttons slot="start">
      <ion-button mode="md" (click)="dismissModal()">
        <ion-icon slot="icon-only" name="close" />
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-text-center">
  <app-illustration
    src="https://assets.naturalcycles.com/images/hardware/freshBattery--CR2032.png"
    alt=""
  />

  <h2>{{ 'txt-fresh-battery--title' | tr }}</h2>
  <p>{{ 'txt-fresh-battery--body' | tr }}</p>
</ion-content>

<ion-footer>
  <ion-button
    uid="freshBattery__continueBtn"
    expand="block"
    shape="round"
    (click)="dismissModal(true)"
  >
    <ion-label>{{ 'txt-continue' | tr }}</ion-label>
  </ion-button>

  <ion-button
    uid="freshBattery__comeBackLaterBtn"
    expand="block"
    shape="round"
    fill="outline"
    (click)="dismissModal()"
  >
    <ion-label>{{ 'txt-come-back-later' | tr }}</ion-label>
  </ion-button>
</ion-footer>
