import {
  BreakpointAction,
  ChoiceFunction,
  ConceiveTimingBreakpointOption,
  QuizDataInput,
} from '@naturalcycles/shared'
import { Section, sectionSexQuestions } from '@src/app/cnst/quiz.cnst'
import { QuizPage } from '../quiz-pages.cnst'

const lessThanAYearTransformFunction: ChoiceFunction<QuizPage, QuizDataInput> = flow => {
  flow[Section.cycle] = [...sectionSexQuestions, QuizPage.hormonalBirthControlUsage]
  return flow
}
const moreThanAYearTransformFunction: ChoiceFunction<QuizPage, QuizDataInput> = flow => {
  flow[Section.cycle] = [
    ...sectionSexQuestions,
    QuizPage.plannedPregnancyInfo,
    QuizPage.cycleLength,
    QuizPage.periodRegularity,
    QuizPage.periodRegularityInfo,
    QuizPage.cycleStartDate,
    QuizPage.medicalConditionsPlan,
  ]
  return flow
}
export const conceiveTiming: BreakpointAction<
  ConceiveTimingBreakpointOption,
  QuizPage,
  QuizDataInput
> = {
  [ConceiveTimingBreakpointOption.justStarted]: lessThanAYearTransformFunction,
  [ConceiveTimingBreakpointOption.fewMonths]: lessThanAYearTransformFunction,
  [ConceiveTimingBreakpointOption.sixMonths]: lessThanAYearTransformFunction,
  [ConceiveTimingBreakpointOption.notStarted]: lessThanAYearTransformFunction,
  [ConceiveTimingBreakpointOption.moreThanAYear]: moreThanAYearTransformFunction,
}
