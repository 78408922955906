import { inject, Injectable } from '@angular/core'
import { ROUTES } from '@app/cnst/nav.cnst'
import { isAndroidApp, isWebApp } from '@app/cnst/userDevice.cnst'
import { StatusBar, Style } from '@capacitor/status-bar'
import { DomController } from '@ionic/angular/standalone'
import { AndroidNotch } from '@src/typings/capacitor'
import { distinctUntilChanged } from 'rxjs'
import { EventService } from './event.service'
import { NavService } from './nav.service'

@Injectable({ providedIn: 'root' })
export class StatusBarService {
  private eventService = inject(EventService)
  private dom = inject(DomController)
  private navService = inject(NavService)

  public async init(): Promise<void> {
    if (isWebApp) return

    if (isAndroidApp) {
      void StatusBar.setOverlaysWebView({ overlay: true })

      const { statusBarHeight } = await AndroidNotch.getStatusBarHeight()

      document.documentElement.style.setProperty(
        '--ion-safe-area-top',
        `${statusBarHeight || 24}px`,
      )
    }

    this.navService.navigationEnd$.subscribe(route => {
      this.handleStatusBarChange(route)
    })

    this.eventService.transitionDone$.subscribe(() => {
      const route = this.navService.getCurrentRoute()

      this.handleStatusBarChange(route)
    })

    this.eventService.modalClosed$.subscribe(() => {
      const route = this.navService.getCurrentRoute()

      this.handleStatusBarChange(route)
    })

    this.eventService.appearanceUpdated$.pipe(distinctUntilChanged()).subscribe(() => {
      const route = this.navService.getCurrentRoute()

      this.handleStatusBarChange(route)
    })
  }

  private handleStatusBarChange(route: string): void {
    // landscape pages
    if (route.startsWith(ROUTES.GraphPage)) {
      void this.hideStatusBar()
      return
    }

    void this.showStatusBar()
  }

  private async hideStatusBar(): Promise<void> {
    const { visible } = await StatusBar.getInfo()
    if (!visible) return

    void StatusBar.hide()
  }

  private async showStatusBar(): Promise<void> {
    const { visible } = await StatusBar.getInfo()

    const { r, g, b } = await this.getBackgroundColor()
    const backgroundLuminance = this.calculateLuminance(r, g, b)

    const whiteLuminance = this.calculateLuminance(255, 255, 255)

    const contrast = this.calculateContrast(whiteLuminance, backgroundLuminance)

    if (contrast < 1 / 7) {
      void StatusBar.setStyle({ style: Style.Dark })
    } else {
      void StatusBar.setStyle({ style: Style.Light })
    }

    if (isAndroidApp) {
      // Set transparent status bar background
      void StatusBar.setBackgroundColor({ color: '#00000000' })
    }

    if (visible) return

    void StatusBar.show()
  }

  private async getBackgroundColor(): Promise<{ r: number; g: number; b: number }> {
    return await new Promise(resolve => {
      this.dom.read(() => {
        let rgb: number[] = []

        const backdrop = (
          document.querySelector('.modal--alert')?.shadowRoot ?? document.querySelector('ion-alert')
        )?.querySelector('ion-backdrop')
        if (backdrop) {
          const backdropBgColor = getComputedStyle(backdrop).backgroundColor.replace(
            /(?:rgba|rgb)/,
            '',
          )
          rgb = backdropBgColor
            .substring(1, backdropBgColor.length - 1)
            .split(',')
            .map(c => Number(c.trim().replace(/\\D/g, '')))

          if (rgb.length && !(rgb[0] === 0 && rgb[1] === 0 && rgb[2] === 0)) {
            resolve({
              r: rgb[0] ?? 0,
              g: rgb[1] ?? 0,
              b: rgb[2] ?? 0,
            })
          }
        }

        const elements = document.elementsFromPoint(6, 1)
        for (let elem of elements) {
          if (!elem) continue
          if (elem.nodeName === 'ION-CONTENT') {
            elem = elem.shadowRoot!.getElementById('background-content') ?? elem
          }
          if (elem.nodeName === 'ION-TOOLBAR') {
            elem = elem.shadowRoot!.querySelector('.toolbar-background') ?? elem
          }
          const color = getComputedStyle(elem).backgroundColor.replace(/(?:rgba|rgb)/, '')
          rgb = color
            .substring(1, color.length - 1)
            .split(',')
            .map(c => Number(c.trim().replace(/\\D/g, '')))
          if (!(rgb[0] === 0 && rgb[1] === 0 && rgb[2] === 0)) break
        }

        if (rgb.length === 0) resolve({ r: 255, g: 255, b: 255 })

        resolve({
          r: rgb[0] ?? 0,
          g: rgb[1] ?? 0,
          b: rgb[2] ?? 0,
        })
      })
    })
  }

  private calculateLuminance(r: number, g: number, b: number): number {
    const a = [r, g, b].map(v => {
      v /= 255
      return v <= 0.03928 ? v / 12.92 : ((v + 0.055) / 1.055) ** 2.4
    })
    return a[0]! * 0.2126 + a[1]! * 0.7152 + a[2]! * 0.0722
  }

  private calculateContrast(foreground: number, background: number): number {
    const contrast =
      foreground > background
        ? (background + 0.05) / (foreground + 0.05)
        : (foreground + 0.05) / (background + 0.05)

    return contrast
  }
}
