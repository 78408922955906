import { inject, Injectable } from '@angular/core'
import { decorate, ErrorHandlerType, LoaderType } from '@app/decorators/decorators'
import { Message } from '@app/model/message.model'
import { AccountService } from '@app/srv/account.service'
import { api } from '@app/srv/api.service'
import { HealthKitService } from '@app/srv/healthkit/healthkit.service'
import { markdownService } from '@app/srv/markdown.service'
import { PopupController, Priority } from '@app/srv/popup.controller'
import { dispatch, getState, select } from '@app/srv/store.service'
import { tr } from '@app/srv/translation.util'
import {
  _filterFalsyValues,
  Class,
  DeferredPromise,
  localTime,
  pDefer,
} from '@naturalcycles/js-lib'
import {
  Goal,
  Message2Answer,
  MessageAnswerInput,
  MessageFeedbackInput,
  MessageSettingsInput,
  MessageStyle,
} from '@naturalcycles/shared'
import { BehaviorSubject, Observable } from 'rxjs'
import { combineLatestWith, debounceTime } from 'rxjs/operators'
import { RemindersService } from '../../settings/reminders/reminders.service'

const CHANGE_TO_PLAN_ANSWERS = [
  'msg-QA-redSexPrevent-ans1',
  'msg-QA-redSexPrevent2-ans1',
  'msg-QA-switchPreventPlan-question1-ans2',
]

@Injectable({ providedIn: 'root' })
export class MessageService {
  private healthKitService = inject(HealthKitService)
  private accountService = inject(AccountService)
  private popupController = inject(PopupController)
  private remindersService = inject(RemindersService)
  public messages$ = new BehaviorSubject<Message[]>([])

  private markdownService = markdownService

  public messageDeleted?: DeferredPromise<void>

  @select(['messages', 'messages'])
  private msgs$!: Observable<Message[]>

  @select(['messages', 'deletedMessages'])
  private deletedMessages$!: Observable<Message[]>

  init(): void {
    this.msgs$
      .pipe(combineLatestWith(this.deletedMessages$), debounceTime(100))
      .subscribe(messages => {
        const merged = [...messages[0], ...messages[1]]
        this.messages$.next(merged.sort((a, b) => b.created - a.created))
      })
  }

  visitedMessagesTab(): void {
    const { messages } = getState()
    if (messages.unreadMessages) {
      dispatch('extendMessages', { unreadMessages: 0 })
    }

    if (!messages.deletedMessages.length) return

    dispatch('clearDeletedMessages')
  }

  shouldShowFeedback(message: Message, disabled?: boolean): boolean {
    // feedback not enabled
    if (disabled || !message.feedbackEnabled) return false

    // feedback already given
    if (message.feedback) return false

    // dont show feedback on messages that are read more than 3 days ago
    if (message.read && localTime(message.read).isOlderThan(3, 'day')) return false

    return true
  }

  async readNewest(): Promise<void> {
    const { messages } = getState().messages
    const unreadMessages: Message[] = messages.filter(message => !message.read)

    if (!unreadMessages.length) return
    return await this.msgRead(unreadMessages[0]!.id, true)
  }

  checkIfPopup(messageModal: Class): boolean {
    const { messages } = getState().messages
    const unreadMessage: Message = messages.find(message => !message.read && message.popup)!

    if (!unreadMessage) return false

    // dont popup achievement messages
    if (unreadMessage.id.includes('achievement')) {
      // todo: remove this when the achievement messages are removed from backend
      return false
    }

    void this.popupMessage(unreadMessage, messageModal)
    return true
  }

  private popupMessage(message: Message, messageModal: Class): void {
    const { style } = message
    const isModal = style === MessageStyle.STATUS || style === MessageStyle.SPECIAL

    if (isModal) {
      this.showMessageModal(message, messageModal)
    } else {
      this.showMessageAlert(message)
    }

    this.readMessage(message)
  }

  private modalDismiss(): void {
    dispatch('setMessageWillPopup', false)
  }

  private showMessageModal(message: Message, messageModal: Class): void {
    void this.popupController.presentModal(
      {
        component: messageModal,
        componentProps: {
          message,
          PAGE_NAME: 'MessageModal',
          analyticsProps: { Message_key: message.msgKey }, // eslint-disable-line @typescript-eslint/naming-convention
        },
      },
      `modal-message-${message.msgKey}`,
      Priority.NONE,
      2000,
    )
  }

  private showMessageAlert(message: Message): void {
    void this.popupController.presentAlert(
      {
        cssClass: 'alert--message',
        message: this.markdownService.parse(message.msg),
        buttons: [
          {
            text: tr('txt-ok'),
            role: 'cancel',
            handler: () => this.modalDismiss(),
          },
        ],
      },
      `alert-message-${message.msgKey}`,
      Priority.NONE,
      2000,
    )
  }

  private readMessage(message: Message): void {
    void this.msgRead(message.id) // async
    dispatch('readMessage', message)
  }

  deleteMessage(message: Message): void {
    dispatch('moveDeleteMessage', { message })
    void this.msgDelete(message.id)
  }

  async undoDeleteMessage(message: Message): Promise<void> {
    if (this.messageDeleted) await this.messageDeleted

    void this.msgDelete(message.id, true)

    dispatch('undoDeleteMessage', { message })
  }

  removeFromDeletedMessagesInStore(message: Message): void {
    dispatch('removeFromDeleteMessage', { message })
  }

  public async answerMessage(message: Message, answers: Message2Answer[]): Promise<void> {
    if (!answers.length) return

    for (const answer of answers) {
      await this.handleAnswer(answer.key)
    }

    // msgAnswer requires array of numeric ids which were inserted into the keys by the backend so we have to parse each key to get the id
    // It actually equals to {answer index}+1 but we want to be on the safe side in case backend logic gets updated
    const answerIds: number[] = answers
      .map(answer => {
        const segments = answer.key.split('-ans')
        return segments[segments.length - 1]
      })
      .filter(Boolean)
      .map(Number)
    await this.msgAnswer(message.id, answerIds)

    if (message.msgKey === 'msg-QA-watchWrist-Apple') {
      const { notifications, account } = getState()

      void this.remindersService.checkForOldCopy(
        notifications.local.items,
        account.lang,
        account.hwId,
        true,
      )
    }
  }

  @decorate({
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  private async handleAnswer(key: string): Promise<void> {
    if (['msg-QA-hkConsent-ans1', 'msg-QA-hkConsentRemind-ans1'].includes(key)) {
      dispatch('extendAccount', {
        hkConsent: true,
      })
      await this.accountService.patch({ hkConsent: true })
      void this.healthKitService.processScienceData()
    }

    if (key === 'msg-QA-hkConsent-ans2') {
      await this.accountService.patch({ hkConsent: false })
    }

    if (CHANGE_TO_PLAN_ANSWERS.includes(key)) {
      await this.changeGoal(Goal.PLAN)
    }
  }

  removeAnswer(message: Message): void {
    message.selectedAnswers?.map(answer => {
      if (answer) {
        dispatch('removeAnswerFromMessage', { message, answer })
      }
    })
  }

  @decorate({
    loaderType: LoaderType.GHOST,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  private async changeGoal(goal: Goal): Promise<void> {
    await this.accountService.changeGoal({ goal })
  }

  @decorate({
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  private async msgRead(msgId: string, andOlder = false): Promise<void> {
    if (!getState().ui.online) return

    await api.put(`messages/${msgId}/read`, {
      searchParams: _filterFalsyValues({
        andOlder,
      }),
    })
  }

  @decorate({
    errorHandlerType: ErrorHandlerType.LOG,
  })
  public async msgFeedback(input: MessageFeedbackInput): Promise<void> {
    if (!getState().ui.online) return

    await api.put('messages/feedback', {
      json: input,
    })
  }

  @decorate({
    errorHandlerType: ErrorHandlerType.LOG,
  })
  public async msgSettingsSave(input: MessageSettingsInput): Promise<void> {
    if (!getState().ui.online) return

    await api.put(`messages/settings`, {
      json: input,
    })
  }

  @decorate({
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  private async msgAnswer(msgId: string, answers: number[]): Promise<void> {
    if (!getState().ui.online) return

    await api.put(`messages/${msgId}/answer`, {
      json: {
        answers,
      } satisfies MessageAnswerInput,
    })
  }

  @decorate({
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  private async msgDelete(msgId: string, undo = false): Promise<void> {
    if (!getState().ui.online) return
    this.messageDeleted = pDefer<void>()
    await api.delete(`messages/${msgId}`, {
      searchParams: _filterFalsyValues({
        undo,
      }),
    })
    this.messageDeleted.resolve()
  }
}
