import { Goal, HardwareId, ReminderKey } from '@naturalcycles/shared'

export enum ReminderType {
  MEASURING = 'MEASURING',
  PREPARATION = 'PREPARATION',
}

export interface ReminderKeys {
  title: string
  text: string
}

export interface ReminderSetting {
  key: ReminderKey
  enabled: boolean
}

export const numberOfReminderCopys: Record<HardwareId, Record<ReminderType, number>> = {
  [HardwareId.ORAL_THERMOMETER]: {
    [ReminderType.MEASURING]: 14,
    [ReminderType.PREPARATION]: 19,
  },
  [HardwareId.UEBE_THERMOMETER]: {
    [ReminderType.MEASURING]: 14,
    [ReminderType.PREPARATION]: 19,
  },
  [HardwareId.T3_THERMOMETER]: {
    [ReminderType.MEASURING]: 14,
    [ReminderType.PREPARATION]: 19,
  },
  [HardwareId.OURA]: {
    [ReminderType.MEASURING]: 5,
    [ReminderType.PREPARATION]: 5,
  },
  [HardwareId.APPLE_WATCH]: {
    [ReminderType.MEASURING]: 0,
    [ReminderType.PREPARATION]: 5,
  },
  [HardwareId.B1]: {
    [ReminderType.MEASURING]: 0,
    [ReminderType.PREPARATION]: 0,
  },
  [HardwareId.SAMSUNG_RING]: {
    [ReminderType.MEASURING]: 0,
    [ReminderType.PREPARATION]: 0,
  },
}

const preventReminders: ReminderKey[] = [
  ReminderKey.fertilityStatus,
  ReminderKey.period,
  ReminderKey.firstRedDay,
  ReminderKey.checkLH,
  ReminderKey.pms,
  ReminderKey.breastSelfCheck,
]

export const reminderKeysByGoal: Record<Goal, ReminderKey[]> = {
  [Goal.PREVENT]: preventReminders,
  [Goal.POSTPARTUM]: preventReminders,
  [Goal.PLAN]: [
    ReminderKey.fertilityStatus,
    ReminderKey.fertileDays,
    ReminderKey.startTesting,
    ReminderKey.checkLH,
    ReminderKey.pms,
    ReminderKey.breastSelfCheck,
  ],
  [Goal.RECOVERY]: [
    ReminderKey.fertileDays,
    ReminderKey.startTesting,
    ReminderKey.checkLH,
    ReminderKey.pms,
    ReminderKey.breastSelfCheck,
  ],
  [Goal.PREGNANT]: [ReminderKey.newPregWeek, ReminderKey.newTrimester],
}

/**
 * ReminderKeys that are only allowed on specific HardwareId.
 */
export const reminderKeyAllowListByHwId: Partial<Record<ReminderKey, HardwareId[]>> = {
  [ReminderKey.fertilityStatus]: [HardwareId.APPLE_WATCH, HardwareId.OURA],
}

const partnerPreventReminders: ReminderKey[] = [
  ReminderKey.firstGreenDay,
  ReminderKey.firstRedDay,
  ReminderKey.period,
  ReminderKey.checkLH,
  ReminderKey.pms,
  ReminderKey.breastSelfCheck,
]

const partnerPlanReminders: ReminderKey[] = [
  ReminderKey.fertileDays,
  ReminderKey.startTesting,
  ReminderKey.checkLH,
  ReminderKey.pms,
  ReminderKey.breastSelfCheck,
]

export const partnerReminderKeysByGoal: Record<Goal, ReminderKey[]> = {
  [Goal.PREVENT]: partnerPreventReminders,
  [Goal.POSTPARTUM]: partnerPreventReminders,
  [Goal.PLAN]: partnerPlanReminders,
  [Goal.RECOVERY]: partnerPlanReminders,
  [Goal.PREGNANT]: [ReminderKey.newPregWeek, ReminderKey.newTrimester],
}
