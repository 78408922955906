import { BackendResponseFM, UserLocale } from '@naturalcycles/shared'
import { Action, Reducer } from 'redux'

const initialState: UserLocale = {} as UserLocale

const actions: any = {
  replaceUserLocale(_state: UserLocale, action: { type: string; payload: UserLocale }): UserLocale {
    return Object.assign({}, action.payload)
  },

  extendUserLocale(
    state: UserLocale,
    action: { type: string; payload: Partial<UserLocale> },
  ): UserLocale {
    return Object.assign({}, state, action.payload)
  },

  onBackendResponse(
    state: UserLocale,
    action: { type: string; payload: BackendResponseFM },
  ): UserLocale {
    // replace!
    if (action.payload.userLocale) {
      return {
        ...action.payload.userLocale,
      }
    }

    return state
  },
}

// biome-ignore lint/style/useDefaultParameterLast: ok
export const userLocaleReducer: Reducer<UserLocale> = (state = initialState, action: Action) => {
  if (actions[action.type]) return actions[action.type](state, action)
  return state
}
