import { storage3Service } from '@app/srv/storage3/storage3.service'
import { distinctUntilDeeplyChanged } from '@app/util/distinctUntilDeeplyChanged'
import { logUtil } from '@app/util/log.util'
import {
  BackendResponseFM,
  UFRaw,
  UserFertility,
  userFertilitySharedUtil,
} from '@naturalcycles/shared'
import { Action, Reducer } from 'redux'
import { Subject } from 'rxjs'
import { debounceTime, mergeMap } from 'rxjs/operators'

type State = UserFertility

const initialState: State = {} as UserFertility

export const actions: any = {
  setUf(_state: State, action: { type: string; payload: UserFertility }): State {
    return {
      ...action.payload,
    }
  },

  extendUf(state: State, action: { type: string; payload: Partial<UserFertility> }): State {
    return {
      ...state,
      ...action.payload,
    }
  },

  onBackendResponse(state: State, action: { type: string; payload: BackendResponseFM }): State {
    // replace!
    if (action.payload.uf) {
      try {
        if (action.payload.uf.todayDate) {
          // Side-effect, but it's faster than having ufRaw in the store
          persistUFSubject.next(action.payload.uf)
        }

        // Kirill: I know, it's not a pure function any more, let's see how it goes and if it brings any issues
        // technically we can copy/paste the parsing function here (which is pure) and fulfill the pureness requirement
        return userFertilitySharedUtil.parseUFRaw(action.payload.uf)
      } catch {
        logUtil.error('Error parsing uf')

        return state
      }
    }

    return state
  },
}

export const userFertilityReducer: Reducer<UserFertility> = (
  // biome-ignore lint/style/useDefaultParameterLast: ok
  state = initialState,
  action: Action,
) => {
  if (actions[action.type]) return actions[action.type](state, action)
  return state
}

const persistUFSubject = new Subject<UFRaw | undefined>()

persistUFSubject
  .pipe(
    debounceTime(1000),
    distinctUntilDeeplyChanged(),
    mergeMap(async ufRaw => {
      await storage3Service.setItem('ufRaw', ufRaw).catch(err => {
        logUtil.log('Error storing userFertility')
        logUtil.error(err)
      })
    }, 1),
  )
  .subscribe()
