import { AppTracker, DailyCheckinSettings } from '@naturalcycles/shared'
import { PAIN_FLAGS_DEFAULT_FOR_TRACKERS } from './trackers.cnst'

export const defaultDailyCheckinTrackers: DailyCheckinSettings = {
  [AppTracker.SLEEP]: true,
  [AppTracker.PAIN]: PAIN_FLAGS_DEFAULT_FOR_TRACKERS,
  [AppTracker.SKIN]: [],
}

export const defaultDailyCheckinTrackersOff: DailyCheckinSettings = {
  [AppTracker.SLEEP]: false,
  [AppTracker.PAIN]: [],
  [AppTracker.SKIN]: [],
}
