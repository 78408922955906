import { Injectable } from '@angular/core'
import { getState } from '@app/srv/store.service'

export enum Bucket {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
}

const BUCKETS: Bucket[] = [Bucket.A, Bucket.B, Bucket.C, Bucket.D]

@Injectable({ providedIn: 'root' })
export class ABTestService {
  /**
   * Will take first letter of string and use `.charCodeAt(0) % buckets` as a dice roll.
   */
  getBucketByString(s: string, buckets = 2): Bucket {
    if (!s) return Bucket.A

    return BUCKETS[s.codePointAt(0)! % buckets] || Bucket.A
  }

  getBucketByAccountId(buckets = 2): Bucket {
    const { account } = getState()
    return this.getBucketByString(account.id, buckets)
  }
}
