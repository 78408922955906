import { inject, Injectable } from '@angular/core'
import { EVENT } from '@app/analytics/analytics.cnst'
import { AnalyticsService } from '@app/analytics/analytics.service'
import { InAppBrowserService } from '@app/srv/inappbrowser.service'
import { PopupController, Priority } from '@app/srv/popup.controller'
import { SessionService } from '@app/srv/session.service'
import { tr } from '@app/srv/translation.util'
import { logUtil } from '@app/util/log.util'
import { AlertButton } from '@ionic/angular/standalone'
import { _errorSnippet, _stringify, AppError, pHang } from '@naturalcycles/js-lib'
import { ErrorCode, LANG, OS } from '@naturalcycles/shared'
import { env } from '@src/environments/environment'
import { appVer } from '../cnst'
import { LINK } from '../cnst/links.cnst'
import { AccountService } from './account.service'
import { di } from './di.service'
import { dispatch, getState, StoreService } from './store.service'

@Injectable({ providedIn: 'root' })
export class ErrorService {
  private analyticsService = inject(AnalyticsService)
  private popupController = inject(PopupController)
  private accountService = inject(AccountService)
  private inAppBrowserService = inject(InAppBrowserService)
  private storeService = inject(StoreService)

  public logError(err: any): string | undefined {
    const sentryId = logUtil.error(err)
    const message = _stringify(err)

    let userFriendly = false
    let code: string | undefined
    if (err instanceof AppError) {
      userFriendly = !!err.cause?.data.userFriendly
      code = err.cause?.data.code || err.data.code
    }

    void this.analyticsService.trackEvent(EVENT.ERROR, {
      message,
      generic: !userFriendly,
      sentryId,
      code,
    })

    return sentryId
  }

  public async showErrorDialog(err: any): Promise<void> {
    const errorId = this.logError(err)

    if (err instanceof AppError && err.cause?.data.code === ErrorCode.INVALID_SESSION) {
      return await di.get(SessionService).handleInvalidSession()
    }

    let message: string
    let header: string | undefined

    const buttons: (AlertButton | string)[] = ['OK']

    if (err instanceof AppError && (err.cause?.data.userFriendly || err.data.userFriendly)) {
      // User friendly error!
      message = err.cause?.message || err.message
    } else {
      const snippet = [
        errorId && `Error id: ${errorId}`,
        env.prod ? _errorSnippet(err) : _stringify(err, { includeErrorData: true }),
      ]
        .filter(Boolean)
        .join('\n')

      let details: string
      const {
        account: { completeDate },
        sessionId,
      } = getState()
      const isLoggedInOnboardedUser = !!sessionId && !!completeDate
      if (
        isLoggedInOnboardedUser &&
        err.cause?.message.toLocaleLowerCase().includes('invalid request body')
      ) {
        buttons.unshift({
          text: tr('txt-clear-cache'),
          handler: async () => {
            this.analyticsService.trackEvent(EVENT.CLICK, { element: 'alert-error-clearCache-btn' })
            await this.clearCache(true)
          },
        })

        details = tr('msg-error-alternative-txt') // Please clear the cache and try again
      } else {
        details = tr('msg-error-txt') // Please close the app and reopen
        const browserVer = this.inAppBrowserService.getBrowserVersion()
        const { appVer, partnerAccount, userDevice, ui } = getState()

        if (!partnerAccount && (ui.lang === LANG.en_US || ui.lang === LANG.en_GB)) {
          buttons.push({
            text: tr('txt-report-issue'),
            handler: async () => {
              this.analyticsService.trackEvent(EVENT.CLICK, {
                element: 'alert-error-reportIssue-btn',
              })

              const chatbotAuthToken = await this.accountService.getChatbotToken()
              const link = env.prod ? LINK.REPORT_BUG_CHATBOT : LINK.REPORT_BUG_CHATBOT_TEST
              await this.inAppBrowserService.open(link, true, 'ErrorModal', {
                chatbotAuthToken,
                appVer,
                osVer:
                  userDevice.os === OS.IOS
                    ? `${userDevice.os} ${userDevice.version}`
                    : (userDevice.version ?? ''),
                browserVer,
                model: userDevice.model ?? '',
              })
            },
          })
        }
      }

      message = [
        `<small class="errorSnippet">${tr('txt-nc-version')}: ${appVer}<br>${snippet}</small>`,
        details,
      ]
        .filter(Boolean)
        .join('<br><br>')
      header = tr('msg-error-title') // Oops! Something went wrong
    }

    const alert = await this.popupController.presentAlert(
      {
        header,
        // subTitle: errorId,
        message,
        buttons,
      },
      `alert-error-${err.name}`,
      Priority.IMMEDIATE,
    )
    await alert.onDidDismiss()
  }

  public async clearCache(reloadPage: boolean): Promise<void> {
    // Clear Add data props
    dispatch('clearModifiedDailyEntries')
    dispatch('clearT3Metadata')
    dispatch('clearWristTempMetadata')
    dispatch('clearEntryStash')

    // Clear pregnancy data
    dispatch('resetUnplannedPregnancyData')

    // Clear HK data
    dispatch('extendHKScienceDataPosted', undefined)

    this.analyticsService.trackEvent(EVENT.CACHE_CLEARED, { reloadPage })

    if (reloadPage) {
      await this.storeService.persistStateNowAndDisablePersistence()
      location.reload()
      await pHang()
    }
  }
}

/**
 * Wraps the passing function with safe try/catch
 */
export async function tryCatch<T = void>(
  name: string,
  fn: (...args: any[]) => any,
): Promise<T | undefined> {
  try {
    return await fn()
  } catch (err) {
    logUtil.log(`tryCatch error in ${name}`)
    logUtil.error(err)
  }
}
