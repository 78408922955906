import { Directive, ElementRef, HostBinding, OnDestroy } from '@angular/core'
import { ClassName } from '@app/analytics/analytics.cnst'
import { addAnalyticsProps } from '@app/analytics/analytics.service'
import { ROUTES } from '@app/cnst/nav.cnst'
import { isAndroidApp } from '@app/cnst/userDevice.cnst'
import { di } from '@app/srv/di.service'
import { addNavParams, getNavParams } from '@app/srv/nav.service'
import { PopupController } from '@app/srv/popup.controller'
import { logUtil } from '@app/util/log.util'
import { NavigationOptions } from '@ionic/angular/common/providers/nav-controller'
import { DomController, NavController, Platform } from '@ionic/angular/standalone'
import { AnimationBuilder } from '@ionic/core'
import { StringMap } from '@naturalcycles/js-lib'
import { env } from '@src/environments/environment'
import { AndroidMinimize, NavigationBar } from '@src/typings/capacitor'
import { BehaviorSubject, Subscription } from 'rxjs'

@Directive()
export abstract class BasePage implements ClassName, OnDestroy {
  /**
   * Used for Analytics pageView reporting.
   * Needed after we decided to stop doing `keep_names` in minification.
   * Now we can keep_names=false and still know the page names.
   * This names should exactly match the name of the Class.
   *
   * attr.id sets the id attribute in html, used in e2e
   */
  @HostBinding('attr.id')
  abstract readonly className: string

  protected subscriptions: Subscription[] = []
  protected elementRef?: ElementRef
  protected blockPopups = false
  public isModal = false
  public showGhost$ = new BehaviorSubject<boolean>(true)
  public shouldGoToCalendarOnBack = false
  public shouldDismissOnBack = false
  private backButtonSubscription?: Subscription

  // do nothing here, can be overwritten by sub class
  public ionViewDidEnter(): void {}
  public ionViewWillEnter(): void {}
  public ionViewWillLeave(): void {}
  public ionViewDidLeave(): void {}

  constructor() {
    const originalIonViewDidEnter = this.ionViewDidEnter
    this.ionViewDidEnter = () => {
      this._ionViewDidEnter()
      originalIonViewDidEnter.apply(this)
    }

    const originalIonViewWillEnter = this.ionViewWillEnter
    this.ionViewWillEnter = () => {
      this._ionViewWillEnter()
      originalIonViewWillEnter.apply(this)
    }

    const originalIonViewWillLeave = this.ionViewWillLeave
    this.ionViewWillLeave = () => {
      this._ionViewWillLeave()
      originalIonViewWillLeave.apply(this)
    }

    const originalIonViewDidLeave = this.ionViewDidLeave
    this.ionViewDidLeave = () => {
      this._ionViewDidLeave()
      originalIonViewDidLeave.apply(this)
    }
  }

  public ngOnDestroy(): void {
    logUtil.log('[prf] ngOnDestroy', this.className)
    this.resetSubscriptions()
  }

  private _ionViewWillEnter(): void {
    if (!env.prod) {
      const params = getNavParams()

      if (Object.keys(params).length) {
        console.warn(`Entering ${this.className} with params`, { ...params })
      }
    }

    logUtil.log('[prf] ionViewWillEnter', this.className)

    if (this.blockPopups) {
      di.get(PopupController).blockPopups()
    }

    di.get(DomController).read(() => {
      const element = document.getElementById(this.className || '')
      if (!element) return

      const ancestors = this.getAncestors(element)

      this.isModal = ancestors.some(el => el && el.tagName === 'ION-MODAL')
    })
  }

  private _ionViewDidEnter(): void {
    this.subscribeToBackButton()
  }

  private subscribeToBackButton(): void {
    if (this.backButtonSubscription) return

    // pages that open as modals
    if (
      this.elementRef?.nativeElement.classList.contains('show-close-button') ||
      this.elementRef?.nativeElement.classList.contains('can-go-back')
    ) {
      this.backButtonSubscription = di
        .get(Platform)
        .backButton.subscribeWithPriority(1, async () => await di.get(NavController).pop())
      return
    }

    if (!(this.shouldDismissOnBack || this.shouldGoToCalendarOnBack)) return

    this.backButtonSubscription = di.get(Platform).backButton.subscribeWithPriority(1, async () => {
      if (this.shouldGoToCalendarOnBack) {
        return await di.get(NavController).navigateForward(ROUTES.TodayPage)
      }

      void AndroidMinimize.minimizeApp()
    })
  }

  private unsubscribeFromBackButton(): void {
    if (!this.backButtonSubscription) return

    this.backButtonSubscription.unsubscribe()
    this.backButtonSubscription = undefined
  }

  private _ionViewWillLeave(): void {
    logUtil.log('[prf] ionViewWillLeave', this.className)
    this.unsubscribeFromBackButton()

    if (this.blockPopups) {
      di.get(PopupController).unblockPopups(this)
    }
  }

  private _ionViewDidLeave(): void {
    logUtil.log('[prf] ionViewDidLeave', this.className)
  }

  protected resetSubscriptions(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe())
    this.subscriptions.length = 0 // this clears the array
  }

  protected async navigateForward(
    url: string,
    params?: { navParams?: StringMap<any>; analytics?: StringMap<any> },
    options?: NavigationOptions,
  ): Promise<void> {
    if (params?.['navParams']) addNavParams(params['navParams'])
    if (params?.['analytics']) addAnalyticsProps(params['analytics'])

    await di.get(NavController).navigateForward(url, options)
  }

  protected async navigateBack(
    url: string,
    params?: { navParams?: StringMap<any>; analytics?: StringMap<any> },
    animation?: AnimationBuilder,
    animated = true,
  ): Promise<void> {
    if (params?.['navParams']) addNavParams(params['navParams'])
    if (params?.['analytics']) addAnalyticsProps(params['analytics'])

    await di.get(NavController).navigateBack(url, {
      animated,
      animation,
    })
  }

  private getAncestors(el: HTMLElement): HTMLElement[] {
    const elements = [el]
    let last = el

    do {
      last = last.parentNode as HTMLElement
      elements.push(last)
    } while (last)

    return elements
  }

  protected updateNavigationBarColor(): void {
    if (!isAndroidApp) return

    void NavigationBar.updateNavigationBarColor()
  }

  public canDeactivate(): boolean | Promise<boolean> {
    return true
  }
}
