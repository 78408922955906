import { Injectable } from '@angular/core'
import { NavigationParams } from '@app/cnst/nav.params.cnst'
import { PageGuard } from '@app/guards/page.guard'
import { getState } from '@app/srv/store.service'
import { GuideStyle } from '@naturalcycles/shared'

@Injectable({
  providedIn: 'root',
})
export class GuidePageGuard extends PageGuard {
  className = 'GuidePageGuard'

  get requirements(): boolean[] {
    const id = this.route.params[NavigationParams.ID]

    const requirements = [
      getState().guides.items.some(
        guide => guide.id === id && (!guide.style || guide.style === GuideStyle.DEFAULT),
      ),
    ]

    return requirements
  }
}
