<ion-header>
  <ion-toolbar mode="ios">
    <ion-buttons slot="start">
      <ion-button mode="md" icon="arrow-back-sharp" (click)="dismissModal()">
        <ion-icon slot="icon-only" name="arrow-back-sharp" />
      </ion-button>
    </ion-buttons>
    <ion-title>
      {{ 'biometric-auth-permission-revoked-title' | tmd: { biometryType } | stripTags }}</ion-title
    >
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <p>{{ 'biometric-auth-permission-revoked-body' | tmd: { biometryType } | stripTags }}</p>
  <ol>
    <li tmd="biometric-auth-permission-revoked-1"></li>
    <li tmd="biometric-auth-permission-revoked-2"></li>
    <li tmd="biometric-auth-permission-revoked-3"></li>
    <li tmd="biometric-auth-permission-revoked-4"></li>
  </ol>
</ion-content>

<ion-footer>
  <ion-button
    expand="block"
    shape="round"
    uid="biometricAuthPermissionRevoked__btn__continue"
    (click)="continue()"
  >
    {{ 'btn-continue' | tr }}
  </ion-button>
</ion-footer>
