import { IsoDate, UnixTimestamp } from '@naturalcycles/js-lib'
import { DataFlag, TestResult } from '@naturalcycles/shared'

export interface WatchInitInput {
  loadingState: GhostData
  notConnectedState: MessageData
  outdatedState: GhostData
  analytics: AnalyticsCfg
}

export interface WatchInput {
  app?: WatchAppData
  message?: MessageData
}

export interface WatchStatus {
  isComplicationEnabled?: boolean
  isPaired?: boolean
  isReachable?: boolean
  isWatchAppInstalled?: boolean
}

interface WatchAppData {
  today: TodayData
  ui: UIData
  pregnancy?: PregnancyData
}

export interface TodayData {
  date: IsoDate
  weekday: string
  cd: number
  dateLabel: string
  fertilityStatus: string
  fertilityColor: string
  unit: string
  temperature?: number
  temperatureInfo?: string
  deviated?: boolean
  deviationIcons: string[]
  phaseTitle?: string
  phaseDescription?: string
  predictions?: PredictionItem[]
}

export interface PredictionItem {
  text: string
  icon?: string
  prediction?: boolean
}

export interface UIData {
  today: string
  footnote: string
  cycleDay: string
  predictions: string
}

export interface PregnancyData {
  week: string
  title: string
  conceptionTitle: string
  conceptionDate: UnixTimestamp
  dueDateTitle: string
  dueDate: UnixTimestamp
}

interface MessageData {
  title?: string
  text: string
  buttonTitle?: string
  analyticsId: string
  iconName?: string // Please use the SF Symbols only
}

interface GhostData {
  headline: string
  color: string
  title: string
  unit: string
}

interface AnalyticsCfg {
  distinctId: string
  mixpanelToken: string
  loggingEnabled: boolean
  globalProperties: GlobalAnalyticsProperties
}

interface GlobalAnalyticsProperties {
  appVersion: string
  complete?: boolean
  demoMode?: boolean
  goal?: string
  plan?: string
  hwId?: string
}

// TODO: move all things icons to better place, shared with widget
export const IOS_ICON_BY_DATAFLAG: Partial<Record<DataFlag, string>> = {
  [DataFlag.DEVIATION_REASON_ALCOHOL]: 'excluded_hungover',
  [DataFlag.DEVIATION_REASON_SICK]: 'excluded_sick',
  [DataFlag.DEVIATION_REASON_SLEEP]: 'excluded_sleep',
  [DataFlag.OURA_ADJUSTED_TEMPERATURE]: 'adjusted_algo',
  [DataFlag.OURA_IRREGULAR_SLEEP]: 'adjusted_algo',
  [DataFlag.DEVIATION_REASON_ALGO]: 'excluded_algo',
}

export const iconByLHResult: Record<TestResult, string> = {
  [TestResult.NO]: 'lh_negative',
  [TestResult.YES]: 'lh_positive',
}
