import { inject, Injectable } from '@angular/core'
import { EVENT } from '@app/analytics/analytics.cnst'
import { AnalyticsService } from '@app/analytics/analytics.service'
import { select } from '@app/srv/store.service'
import { NCAppearance } from '@src/typings/capacitor'
import { BehaviorSubject, combineLatestWith, map, Observable, startWith, tap } from 'rxjs'
import { EventService } from './event.service'
import { dispatch } from './store.service'

export enum AppearanceSettings {
  LIGHT = 1,
  DARK = 2,
  SYSTEM = 3,
}

@Injectable({ providedIn: 'root' })
export class AppearanceService {
  private analyticsService = inject(AnalyticsService)
  private eventService = inject(EventService)
  @select(['userSettings', 'appearance'])
  private _appearance$!: Observable<AppearanceSettings | undefined>

  public appearanceSettings$ = this._appearance$.pipe(
    combineLatestWith(this.eventService.onResume$.pipe(startWith(1))),
    map(([appearance]) => appearance || AppearanceSettings.LIGHT),
    tap(async appearance => {
      if (appearance === AppearanceSettings.SYSTEM) {
        appearance = await this.getSystemPreference()
      }

      this.appearance$.next(appearance)
    }),
  )

  public appearance$ = new BehaviorSubject<AppearanceSettings>(AppearanceSettings.LIGHT)
  public appearanceName$ = this.appearance$.pipe(map(appearance => AppearanceSettings[appearance]))

  public init(): void {
    this.appearance$.pipe(combineLatestWith(this.appearanceSettings$)).subscribe(([appearance]) => {
      document.documentElement.setAttribute('appearance', AppearanceSettings[appearance])
    })
  }

  public saveSettings(appearance: AppearanceSettings): void {
    dispatch('extendUserSettings', { appearance })

    this.analyticsService.trackEvent(EVENT.SAVE_APPEARANCE_SETTINGS, {
      appearance: AppearanceSettings[appearance],
    })

    this.eventService.appearanceUpdated$.next(AppearanceSettings[appearance])
  }

  private async getSystemPreference(): Promise<AppearanceSettings> {
    const { appearance } = await NCAppearance.getSystemPreference()

    return appearance
  }
}
