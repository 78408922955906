export interface FeedbackItem {
  label: string
  uid: string
}

export type FeedbackType = 'questions' | 'input'

interface FeedbackInput {
  id: string
  title: string
  type: FeedbackType
  feedbackItems?: FeedbackItem[]
}

const DEVIATION_SLEEP: Readonly<FeedbackInput> = {
  id: 'deviationSleep',
  title: 'txt-feature-feedback-title',
  type: 'questions',
  feedbackItems: [
    {
      label: 'txt-zzz-feedback-q1',
      uid: 'deviationSleepHelpful',
    },
    {
      label: 'txt-zzz-feedback-q2',
      uid: 'deviationSleepEasyToUse',
    },
  ],
}

const HOLISTIC_GRAPH: Readonly<FeedbackInput> = {
  id: 'holisticGraph',
  title: 'feedback-holistic-graph-title',
  type: 'questions',
  feedbackItems: [
    {
      label: 'feedback-holistic-graph-q1',
      uid: 'holisticGraphEasyToUse',
    },
    {
      label: 'feedback-holistic-graph-q2',
      uid: 'holisticGraphPatterns',
    },
  ],
}

const APPLE_WATCH: Readonly<FeedbackInput> = {
  id: 'appleWatch-setup-questions',
  title: 'feedback-holistic-graph-title',
  type: 'questions',
  feedbackItems: [
    {
      label: 'feedback-apple-watch-q1',
      uid: 'AW-Setup',
    },
    {
      label: 'feedback-apple-watch-q2',
      uid: 'AW-SleepSchedule',
    },
    {
      label: 'feedback-apple-watch-q3',
      uid: 'AW-ContinueToUse',
    },
  ],
}

const APPLE_WATCH_INPUT: Readonly<FeedbackInput> = {
  id: 'appleWatch-setup-input',
  title: 'feedback-apple-watch-q4',
  type: 'input',
}

const FERTILITY_NOTIFICATION: Readonly<FeedbackInput> = {
  id: 'fertility_status_notification',
  title: 'feedback-fertility-notification-title',
  type: 'questions',
  feedbackItems: [
    {
      label: 'feedback-fertility-notification-q1',
      uid: 'fertility-notification',
    },
  ],
}

const FERTILITY_NOTIFICATION_INPUT: Readonly<FeedbackInput> = {
  id: 'fertility_status_notification-input',
  title: 'feedback-fertility-notification-q2',
  type: 'input',
}

const PERIOD_PROMPT_CARD: Readonly<FeedbackInput> = {
  id: 'period-prompt-card',
  title: 'feedback-period-prompt-card-title',
  type: 'questions',
  feedbackItems: [
    {
      label: 'feedback-period-prompt-card-q1',
      uid: 'period-prompt',
    },
  ],
}

export const FEEDBACK = {
  DEVIATION_SLEEP,
  HOLISTIC_GRAPH,
  APPLE_WATCH,
  APPLE_WATCH_INPUT,
  FERTILITY_NOTIFICATION,
  FERTILITY_NOTIFICATION_INPUT,
  PERIOD_PROMPT_CARD,
}
