import { logUtil } from '@app/util/log.util'
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core'

export class AppMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): string {
    logUtil.warn(`Translation missing: ${params.key}`)

    return `[${params.key}]`
  }
}
