import { Injectable } from '@angular/core'
import { PageGuard } from '../../guards/page.guard'

@Injectable({
  providedIn: 'root',
})
export class GraphPageGuard extends PageGuard {
  className = 'GraphPageGuard'

  get requirements(): boolean[] {
    const requirements = [
      !this.requiresAppConsent(),
      this.hasSession(),
      this.hasAccountCompleteDate() || this.hasPartnerAccount(),
      this.hasUserFertilityTodayDate(),
    ]

    return requirements
  }
}
