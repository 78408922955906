import { Injectable } from '@angular/core'
import { NavigationParams } from '@app/cnst/nav.params.cnst'
import { PageGuard } from '@app/guards/page.guard'
import { getState } from '@app/srv/store.service'

@Injectable({
  providedIn: 'root',
})
export class GlossaryItemPageGuard extends PageGuard {
  className = 'GlossaryItemPageGuard'

  get requirements(): boolean[] {
    const glossaryId = this.route.params[NavigationParams.ID]
    const items = getState().glossary.items
    const requirements = [items.some(item => item.id === glossaryId)]

    return requirements
  }
}
