import { inject, Injectable } from '@angular/core'
import { NavigationParams } from '@app/cnst/nav.params.cnst'
import { PageGuard } from '@app/guards/page.guard'
import { getNavParams } from '@app/srv/nav.service'
import { CartService } from '@src/app/srv/cart.service'

@Injectable({
  providedIn: 'root',
})
export class OrderPageGuard extends PageGuard {
  className = 'OrderPageGuard'

  private cartService = inject(CartService)

  get requirements(): boolean[] {
    const params = getNavParams()
    const requirements = [
      !this.requiresAppConsent(),
      this.hasAccountId(),
      this.hasCartItems(),
      this.cartTotalPriceNoAmount() || !!params[NavigationParams.PAYMENT_METHOD],
      this.cartService.hasValidThermometerSelection(),
    ]

    return requirements
  }
}
