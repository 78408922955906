import { inject, Injectable } from '@angular/core'
import { addAnalyticsProps } from '@app/analytics/analytics.service'
import { verticalSlideAnimation } from '@app/animations/vertical-slide'
import { AddDataSource } from '@app/cnst/add-data.cnst'
import { ROUTES } from '@app/cnst/nav.cnst'
import { NavigationParams } from '@app/cnst/nav.params.cnst'
import { AddDataPreviewModal } from '@app/modals/add-data-preview/add-data-preview.modal'
import { addNavParams } from '@app/srv/nav.service'
import { PopupController, Priority } from '@app/srv/popup.controller'
import { getState } from '@app/srv/store.service'
import { UFService } from '@app/srv/uf.service'
import { NavController } from '@ionic/angular/standalone'
import { _Throttle, IsoDate, localDate } from '@naturalcycles/js-lib'
import { SyncedTemperature } from '@src/app/srv/bluetooth.service'

interface AddDataOpenInput {
  source: AddDataSource
  date?: IsoDate
  temperatures?: SyncedTemperature[]
  skipPreview?: boolean
  forcePreview?: boolean
  skipAnimation?: boolean
}

@Injectable({ providedIn: 'root' })
export class AddDataPageService {
  private navController = inject(NavController)
  private popupController = inject(PopupController)
  private ufService = inject(UFService)
  // Prevent opening several Add datas
  @_Throttle(1000)
  public async openAddData(input: AddDataOpenInput): Promise<void> {
    const { source, temperatures, skipAnimation, skipPreview, forcePreview } = input

    let date: IsoDate | undefined = input.date || localDate.todayString()

    const {
      account: { personaId },
      partnerAccount,
    } = getState()

    if (forcePreview || (!skipPreview && personaId !== 'PERCY' && this.shouldOpenPreview(date))) {
      date = await this.openPreview(date, source)

      if (!date) return
    }

    // partners are not allowed to open add data
    if (partnerAccount) return

    if (localDate(date).isAfter(localDate.today())) return

    window.addDataOpenStarted = Date.now()

    const sourcePath = source === AddDataSource.TOUR ? ROUTES.TodayPage : location.pathname
    // If add data is already open don't open it again
    if (sourcePath.includes('add-data')) return

    addAnalyticsProps({ source })

    addNavParams({ [NavigationParams.SOURCE_PATH]: sourcePath })
    await this.navController.navigateForward(`${ROUTES.AddData}/${date}`, {
      animation: verticalSlideAnimation,
      animated: !skipAnimation,
      state: {
        [NavigationParams.BLUETOOTH_TEMPERATURES]: temperatures,
      },
    })
  }

  private async openPreview(date: IsoDate, source: AddDataSource): Promise<IsoDate | undefined> {
    const todayOffset = localDate(date).diff(localDate.today(), 'day')
    addAnalyticsProps({ source, todayOffset })

    const modal = await this.popupController.presentModal(
      {
        component: AddDataPreviewModal,
        componentProps: {
          date,
        },
        cssClass: ['modal--transparent', 'modal--alert', 'modal--slides'],
        backdropDismiss: true,
      },
      'modal-addDataPreview',
      Priority.IMMEDIATE,
    )
    const { data } = await modal.onWillDismiss()

    return data
  }

  private shouldOpenPreview(date: string): boolean {
    const entry = this.ufService.getUfEntry(date)

    if (!entry) return false

    const { updated, skipped, ovulationStatus, hwIdChange, prediction, pregnancyEndCare } = entry

    const conditions = [
      ovulationStatus,
      updated && !skipped,
      prediction,
      hwIdChange,
      pregnancyEndCare,
    ]

    return conditions.some(Boolean)
  }
}
