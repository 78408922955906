import { BackendResponseFM, QuizResponse } from '@naturalcycles/shared'
import { Action, Reducer } from 'redux'

export interface QuizzesState extends QuizResponse {}

type State = QuizzesState

const initialState: State = {
  quizHash: '',
  items: [],
}

const actions: any = {
  onBackendResponse(state: State, action: { type: string; payload: BackendResponseFM }): State {
    if (action.payload.quizzes) {
      return { ...action.payload.quizzes }
    }

    return state
  },
}

// biome-ignore lint/style/useDefaultParameterLast: ok
export const quizzesReducer: Reducer<State> = (state = initialState, action: Action) => {
  if (actions[action.type]) return actions[action.type](state, action)
  return state
}
