export enum PregnancyEndTrigger {
  GOAL = 'goal',
  NEGATIVE_TEST = 'negativeTest',
  NO_LONGER_PREGNANT = 'noLongerPregnant',
  ONBOARDING = 'onboarding',
  PREVENT_SITUATION_CHANGE = 'pregnancyChange',
}

export enum PregnancyEndConfirmation {
  LOST_PREGNANCY = 1,
  STILL_PREGNANT = 2,
  WAS_NOT_PREGNANT = 3,
  GAVE_BIRTH = 4,
  PREVENT_NO_LONGER_PREGNANT = 5,
  CHANGE_TO_FOLLOW = 6,
}
