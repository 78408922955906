import { BackendResponseFM, PaymentState } from '@naturalcycles/shared'
import { AnyAction, Reducer } from 'redux'

const initialState: PaymentState = {
  items: [],
  methods: [],
}

const actions: any = {
  replacePayment(
    _state: PaymentState,
    action: { type: string; payload: PaymentState },
  ): PaymentState {
    return {
      ...action.payload,
    }
  },

  extendPayment(
    state: PaymentState,
    action: { type: string; payload: PaymentState },
  ): PaymentState {
    return {
      ...state,
      ...action.payload,
    }
  },

  onBackendResponse(
    state: PaymentState,
    action: { type: string; payload: BackendResponseFM },
  ): PaymentState {
    // replace!
    if (action.payload.payment) {
      return {
        ...action.payload.payment,
      }
    }

    return state
  },
}

// biome-ignore lint/style/useDefaultParameterLast: ok
export const paymentReducer: Reducer<PaymentState> = (state = initialState, action: AnyAction) => {
  if (actions[action.type]) return actions[action.type](state, action)
  return state
}
