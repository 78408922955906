import { nextIdle } from '@app/perf/idle.util'
import { LagCounter, lagCounter } from '@app/perf/lagCounter'
import { StringMap } from '@naturalcycles/js-lib'

declare global {
  interface Window {
    lagMon: LagMon
  }
}

export class LagMon {
  currentName?: string

  measures: StringMap<number> = {}

  currentCounter?: LagCounter

  async run(name: string, job: () => Promise<any>): Promise<number> {
    if (this.currentName) {
      console.log(`[lag] already started ${this.currentName}`)
      return 0
    }

    this.start(name)

    await job()

    return this.stop()
  }

  start(name = 'lag'): void {
    if (this.currentName) {
      console.log(`[lag] already started ${this.currentName}`)
      return
    }

    this.currentName = name
    this.currentCounter = lagCounter()
  }

  /**
   * Returns number of lag milliseconds from the previously started LagCounter.
   */
  stop(): number {
    if (!this.currentName) {
      console.log(`[lag] not started`)
      return 0
    }

    // Find next unique name
    let name = this.currentName
    let i = 1
    while (this.measures[name] !== undefined) {
      name = this.currentName + ++i
    }

    // Save the record into "measures" map
    const lag = this.currentCounter!()
    this.measures[name] = lag
    console.log(`[lag] ${name}: ${lag}`, this.measures)

    delete this.currentCounter
    delete this.currentName

    return lag
  }

  /**
   * Convenience method that awaits nextIdle and then returns the lag.
   */
  async stopAfterIdle(idleLength = 3000, timeout?: number): Promise<number> {
    await nextIdle(idleLength, timeout)
    return this.stop()
  }
}
