import { BackendResponseFM, PartnerAccountFM } from '@naturalcycles/shared'
import { Action, Reducer } from 'redux'

type State = PartnerAccountFM[]

const initialState: State = []

const actions: any = {
  onBackendResponse(state: State, action: { type: string; payload: BackendResponseFM }): State {
    return action.payload.partners || state
  },
}

// biome-ignore lint/style/useDefaultParameterLast: ok
export const partnersReducer: Reducer<State> = (state = initialState, action: Action) => {
  if (actions[action.type]) return actions[action.type](state, action)
  return state
}
