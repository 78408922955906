import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { ICON_BY_LH } from '@app/cnst/icons.cnst'
import { DatePipe } from '@app/pipes/date.pipe'
import { LhService } from '@app/srv/lh.service'
import { logUtil } from '@app/util/log.util'
import { IonChip, IonIcon, IonImg, IonLabel } from '@ionic/angular/standalone'
import { pProps } from '@naturalcycles/js-lib'
import { TestResult } from '@naturalcycles/shared'

@Component({
  selector: 'app-lh-image',
  templateUrl: 'lh-image.component.html',
  styleUrls: ['lh-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DatePipe, IonChip, IonLabel, IonIcon, IonImg],
})
export class LhImageComponent implements OnChanges {
  private lhService = inject(LhService)
  private cdr = inject(ChangeDetectorRef)

  @Input()
  protected id?: string

  @ViewChild(IonImg)
  private ionImg!: IonImg

  protected testResult?: TestResult
  protected timestamp?: number

  protected ICON_BY_LH = ICON_BY_LH

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['id']?.currentValue) {
      void this.updateImage(changes['id'].currentValue)
    }
  }

  private async updateImage(id: string): Promise<void> {
    const { image, data } = await pProps({
      image: this.lhService.getImageSrc(id),
      data: this.lhService.getImageData(id),
    }).catch(err => {
      logUtil.log('Error getting lh image')
      logUtil.error(err)

      return { image: undefined, data: undefined }
    })

    const { testResult, created } = data || {}

    this.ionImg.src = image
    this.testResult = testResult
    this.timestamp = created

    this.cdr.markForCheck()
  }
}
