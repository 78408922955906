import { clickUid } from '@app/perf/dom.util'
import { nextIdle } from '@app/perf/idle.util'
import { SELECTORS } from '@app/perf/selectors'
import { pDelay } from '@naturalcycles/js-lib'

export async function scenario01AddDataOpenClose(): Promise<void> {
  await window.lagMon.run('addDataOpen', async () => {
    await clickUid(SELECTORS.todayAddDataButton)
    await nextIdle()
  })

  await clickUid(SELECTORS.numpadDismisserArea)
  await pDelay(1000)

  await window.lagMon.run('addDataClose', async () => {
    await clickUid(SELECTORS.addDataClose)
    await nextIdle()
  })
}
