import { BackendResponseFM } from '@naturalcycles/shared'
import { Action, Reducer } from 'redux'
import { AppSettingsFM, appSettingsTMtoFM } from '../srv/appSettings.cnst'

type State = AppSettingsFM | null

const initialState: State = null

const actions: any = {
  onBackendResponse(state: State, action: { type: string; payload: BackendResponseFM }): State {
    if (action.payload.account?.appSettings) {
      return {
        ...appSettingsTMtoFM(action.payload.account.appSettings, action.payload.account.goal),
      }
    }

    return state
  },
}

// biome-ignore lint/style/useDefaultParameterLast: ok
export const appSettingsReducer: Reducer<State> = (state = initialState, action: Action) => {
  if (actions[action.type]) return actions[action.type](state, action)
  return state
}
