import { storage3Service } from '@app/srv/storage3/storage3.service'
import { distinctUntilDeeplyChanged } from '@app/util/distinctUntilDeeplyChanged'
import { logUtil } from '@app/util/log.util'
import { BackendResponseFM, GuideResponse } from '@naturalcycles/shared'
import { Action, Reducer } from 'redux'
import { Subject } from 'rxjs'
import { debounceTime, mergeMap } from 'rxjs/operators'

export interface GuideState extends GuideResponse {}

type State = GuideState

const initialState: State = {
  guideHash: '',
  items: [],
}

const actions: any = {
  onBackendResponse(state: State, action: { type: string; payload: BackendResponseFM }): State {
    if (action.payload.guides) {
      // Side-effect
      persistGuideSubject.next(action.payload.guides)

      return { ...action.payload.guides }
    }

    return state
  },
}

// biome-ignore lint/style/useDefaultParameterLast: ok
export const guideReducer: Reducer<State> = (state = initialState, action: Action) => {
  if (actions[action.type]) return actions[action.type](state, action)
  return state
}

const persistGuideSubject = new Subject<GuideResponse | undefined>()

persistGuideSubject
  .pipe(
    debounceTime(1000),
    distinctUntilDeeplyChanged(),
    mergeMap(async guides => {
      await storage3Service.setItem('guides', guides).catch(err => {
        logUtil.log('guides.reducer Error storing guides')
        logUtil.error(err)
      })
    }, 1),
  )
  .subscribe()
