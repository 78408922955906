import { animate, style, transition, trigger } from '@angular/animations'
import { _numberEnumKey } from '@naturalcycles/js-lib'
import {
  BreakpointActions,
  ConsideringPurchaseOption,
  CycleChangesBreakpointOption,
  EligibleAppleWatchBreakpointOption,
  Goal,
  GoalBreakpointOption,
  HormonalBirthControlHowLongBreakpointOption,
  HormonalBirthControlUsageBreakpointOption,
  LANG,
  PurchaseNewWatchOption,
  QuizDataInput,
  QuizFlow,
  QuizGuardData,
  SleepWithTrackerOption,
} from '@naturalcycles/shared'
import { ExtendedGoalBreakpointOption, ExtraGoalBreakpointOption } from '@src/ab272/ab272'
import { BreakpointOption } from '../srv/quiz/quiz.service'
import { birthControlMethod } from './breakpoints/birth-control-method'
import { conceiveTiming } from './breakpoints/conceive-timing'
import { cycleChanges } from './breakpoints/cycle-changes'
import { eligibleAppleWatch } from './breakpoints/eligible-apple-watch'
import { goalBreakpoint } from './breakpoints/goal'
import { hormonalBirthControlHowLong } from './breakpoints/hormonal-birth-control-how-long'
import { hormonalBirthControlUsage } from './breakpoints/hormonal-birth-control-usage'
import { medicalConditions } from './breakpoints/medical-conditions'
import { sideEffects } from './breakpoints/side-effects'
import { sleepWearable } from './breakpoints/sleep-wearable'
import { whichWearable } from './breakpoints/which-wearable'
import { QuizPage } from './quiz-pages.cnst'

/**
 * Quiz pages and flow
 */

export type AppQuizFlow = QuizFlow<QuizPage>

export enum Section {
  intro = 0,
  goal = 1,
  cycle = 2,
  cycleChanges = 3,
  routine = 4,
}

export const quizParentSlug = 'quiz'
export const baseImgLink = 'https://assets.naturalcycles.com/images/onboarding-quiz/'

export enum Breakpoint {
  goal = 'goal',
  birthControlMethod = 'birthControlMethod',
  sideEffects = 'sideEffects',
  conceiveTiming = 'conceiveTiming',
  hormonalBirthControlUsage = 'hormonalBirthControlUsage',
  hormonalBirthControlHowLong = 'hormonalBirthControlHowLong',
  medicalConditions = 'medicalConditions',
  cycleChanges = 'cycleChanges',
  whichWearable = 'whichWearable',
  sleepWearableAW = 'sleepWearableAW',
  sleepWearable = 'sleepWearable',
  eligibleAppleWatch = 'eligibleAppleWatch',
}

export const commonQuizFlow: AppQuizFlow = [
  [QuizPage.privacy, QuizPage.name, QuizPage.usps, QuizPage.birthday],
  [QuizPage.fertilityGoal, QuizPage.fertilityGoalInfo],
]

export const sectionRoutine = [
  QuizPage.naturalMethod,
  QuizPage.yourMeasuringDevice,
  QuizPage.whichWearable,
  QuizPage.howDoYouSleep,
  QuizPage.sleepWearable,
  QuizPage.yourDescription,
  QuizPage.fitResult,
  QuizPage.deviceResult,
]

export const sectionSexQuestions = [
  QuizPage.learnAboutSexInfo,
  QuizPage.bestTimeInCycle,
  QuizPage.knowingYourSexDrive,
  QuizPage.sexDriveInfo,
  QuizPage.sexFrequency,
  QuizPage.knowPregnantDays,
  QuizPage.menstrualCycleInfo,
  QuizPage.testimonialInfo,
]

/**
 * Between these two set of pages below, there are more pages, decided by breakpoint FlowBreakpoint.cycleChanges.
 * Start and end part are joined by default as it is the flow when the user does not select any cycle changes on FlowBreakpoint.cycleChanges.
 * More pages are injected in between if user selects cycle changes on FlowBreakpoint.cycleChanges
 */
export const sectionCycleChangesStart = [QuizPage.emotionalChangesInfo, QuizPage.cycleChanges]
export const sectionCycleChangesEnd = [QuizPage.trackingInfo]
// ====================================================================================================================================

/**
 * Quiz UI interfaces and constants
 */
export interface QuizSelectOption {
  key: number
  textKey: string
  img?: string
  imgPosition?: 'top' | 'right'
}
export interface InfoPageContent {
  imageUrl?: string
  titleKey?: string
  subtitleKey?: string
  buttonKey?: string
}

export interface TranslatedInfoPageContent {
  title?: string
  subtitle?: string
  markdown?: string
}

export const quizAnimation = trigger('fadeIn', [
  transition(':enter', [style({ opacity: 0 }), animate('200ms', style({ opacity: 1 }))]),
  transition(':leave', [style({ opacity: 1 }), animate('200ms', style({ opacity: 0 }))]),
])

export const goalMap: Record<ExtendedGoalBreakpointOption, Goal> = {
  [GoalBreakpointOption.prevent]: Goal.PREVENT,
  [GoalBreakpointOption.preventNowPlanLater]: Goal.PREVENT,

  [GoalBreakpointOption.undecided]: Goal.PREVENT,
  [GoalBreakpointOption.improveWellbeing]: Goal.PREVENT,
  [GoalBreakpointOption.manageSymptoms]: Goal.PREVENT,
  [GoalBreakpointOption.trackCycle]: Goal.PREVENT,

  [GoalBreakpointOption.plan]: Goal.PLAN,
  [GoalBreakpointOption.trackPregnancy]: Goal.PREGNANT,
  [GoalBreakpointOption.postPartum]: Goal.POSTPARTUM,
  [ExtraGoalBreakpointOption.perimenopause]: Goal.PREVENT, // AB272
}

export const guardQuizData: QuizGuardData<QuizPage, QuizDataInput> = {
  [QuizPage.privacy]: () => true,
  [QuizPage.name]: () => true,
  [QuizPage.usps]: state => {
    return !!state?.name
  },
  [QuizPage.birthday]: state => {
    return !!state?.name
  },
  [QuizPage.fertilityGoal]: state => {
    return !!state?.dateOfBirth
  },
  [QuizPage.fertilityGoalInfo]: state => {
    return !!state?.fertilityGoal
  },
  [QuizPage.birthControlMethod]: state => {
    return state?.fertilityGoal !== GoalBreakpointOption.plan
  },
  [QuizPage.birthControlMethodInfo]: state => {
    return !!state?.birthControlMethod
  },
  [QuizPage.sideEffects]: () => true,
  [QuizPage.sideEffectsInfo]: state => {
    return !!state?.sideEffects
  },
  [QuizPage.plannedPregnancyTiming]: state => {
    return state?.fertilityGoal === GoalBreakpointOption.plan
  },
  [QuizPage.hormonalBirthControlUsage]: state => {
    return !!state?.conceiveTiming
  },
  [QuizPage.hormonalBirthControlHowLongPlan]: state => {
    return state?.hormonalBirthControlUsage === HormonalBirthControlUsageBreakpointOption.lastMonth
  },
  [QuizPage.hormonalBirthControlHowLongPrevent]: state => {
    return !!state?.birthControlMethod
  },
  [QuizPage.plannedPregnancyInfo]: state => {
    // This page appears in many flows. To reduce complexity and increase readability we decided to use the last "common" question as the guard.
    // Discussion here: https://github.com/NaturalCycles/WebSignup/pull/1497/files#r1231149370
    return !!state?.conceiveTiming
  },
  [QuizPage.cycleLength]: state => {
    // This page appears in many flows. To reduce complexity and increase readability we decided to use a simplified logic for the route guard.
    // Discussion here https://github.com/NaturalCycles/WebSignup/pull/1497/files#r1231149370
    return !!state?.birthControlMethod || !!state?.conceiveTiming
  },
  [QuizPage.periodRegularity]: state => {
    return !!state?.avgCycleLength
  },
  [QuizPage.periodRegularityInfo]: state => {
    return (
      !!state?.periodRegularity ||
      state?.hormonalBirthControlHowLong ===
        HormonalBirthControlHowLongBreakpointOption.moreThanAYear
    )
  },
  [QuizPage.cycleStartDate]: state => {
    return !!state?.periodRegularity
  },
  [QuizPage.medicalConditionsPlan]: state => {
    return !!state?.conceiveTiming
  },
  [QuizPage.medicalConditionsPrevent]: state => {
    return (
      (!!state?.periodRegularity && state.fertilityGoal !== GoalBreakpointOption.plan) ||
      state?.hormonalBirthControlHowLong ===
        HormonalBirthControlHowLongBreakpointOption.moreThanAYear
    )
  },
  [QuizPage.medicalConditionsInfo]: state => {
    return !!state?.medicalConditions
  },
  [QuizPage.doctorInvolvement]: state => {
    return !!state?.medicalConditions && state.fertilityGoal === GoalBreakpointOption.plan
  },
  [QuizPage.partnerInvolvement]: state => {
    return !!state?.discussedFertility
  },
  [QuizPage.partnerInvolvementInfo]: state => {
    return !!state?.partnerInvolvement
  },
  [QuizPage.followPregnancyInfo]: state => {
    return !!state?.partnerInvolvement
  },
  [QuizPage.emotionalChangesInfo]: () => true,
  [QuizPage.cycleChanges]: state => {
    return (
      (state?.fertilityGoal !== GoalBreakpointOption.plan && !!state?.medicalConditions) ||
      (state?.fertilityGoal === GoalBreakpointOption.plan && !!state?.partnerInvolvement)
    )
  },
  [QuizPage.cycleChangesEmotions]: state => {
    return !!state?.cycleChanges?.includes(CycleChangesBreakpointOption.emotions)
  },
  [QuizPage.cycleChangesBleeding]: state => {
    return !!state?.cycleChanges?.includes(CycleChangesBreakpointOption.bleeding)
  },
  [QuizPage.cycleChangesSkin]: state => {
    return !!state?.cycleChanges?.includes(CycleChangesBreakpointOption.skinChanges)
  },
  [QuizPage.cycleChangesSexAndLibido]: state => {
    return !!state?.cycleChanges?.includes(CycleChangesBreakpointOption.sexAndLibido)
  },
  [QuizPage.cycleChangesCervicalMucus]: state => {
    return !!state?.cycleChanges?.includes(CycleChangesBreakpointOption.cervicalMucus)
  },
  [QuizPage.cycleChangesPain]: state => {
    return !!state?.cycleChanges?.includes(CycleChangesBreakpointOption.pain)
  },
  [QuizPage.trackingInfo]: state => {
    /*
    NOTES:
    1) This rule does not comprise all possible combinations of ".cycleChanges" + their pages (Page.cycleChangesEmotions, Page.cycleChangesSkin, etc).
    2) Possible values of state?.cycleChanges are:
          - undefined // evaluates to false, user has never seen the page
          - [] // evaluates to true, user has seen the page, but skipped
          - [CycleChangesBreakpointOption.emotions, CycleChangesBreakpointOption.bleeding, ...] // evaluates to true, user has seen the page and selected some options
    */
    return !!state?.cycleChanges
  },
  [QuizPage.naturalMethod]: state => {
    return !!state?.cycleChanges
  },
  [QuizPage.yourMeasuringDevice]: state => {
    return !!state?.cycleChanges
  },
  [QuizPage.whichWearable]: state => {
    return !!state?.cycleChanges
  },
  [QuizPage.eligibleAppleWatch]: state => {
    return !!state?.whichWearable
  },
  [QuizPage.purchaseNewWatch]: state => {
    return state?.eligibleAppleWatch === EligibleAppleWatchBreakpointOption.no
  },
  [QuizPage.consideringPurchase]: state => {
    /*
    NOTES:
    This rule does not comprise all possible combinations of Apple Watch in the flow.
    User will either see [QuizPage.fitResult], [QuizPage.consideringPurchase] or [QuizPage.eligibleAppleWatch]
    next depending on their action in [QuizPage.whichWearable].
    */
    return !!state?.cycleChanges
  },
  [QuizPage.howDoYouSleep]: state => {
    return !!state?.whichWearable
  },
  [QuizPage.sleepWearable]: state => {
    return !!state?.sleep
  },
  [QuizPage.wearYourWatchToBed]: state => {
    return (
      (state?.eligibleAppleWatch === EligibleAppleWatchBreakpointOption.yes ||
        state?.purchaseNewWatch === PurchaseNewWatchOption.yes ||
        state?.consideringPurchase === ConsideringPurchaseOption.yesAppleWatch) &&
      (state?.sleepWithTracker === SleepWithTrackerOption.yesWithRing ||
        state?.sleepWithTracker === SleepWithTrackerOption.neither)
    )
  },
  [QuizPage.yourDescription]: state => {
    return !!state?.sleepWithTracker
  },
  [QuizPage.fitResult]: state => {
    return !!state?.sleepWithTracker
  },
  [QuizPage.deviceResult]: state => {
    return !!state?.whichWearable
  },
}

export const breakpointActions: BreakpointActions<
  Breakpoint,
  BreakpointOption,
  QuizPage,
  QuizDataInput
> = {
  [Breakpoint.goal]: goalBreakpoint, // common
  [Breakpoint.birthControlMethod]: birthControlMethod, // prevent
  [Breakpoint.sideEffects]: sideEffects, // prevent
  [Breakpoint.conceiveTiming]: conceiveTiming, // plan
  [Breakpoint.hormonalBirthControlUsage]: hormonalBirthControlUsage, // plan
  [Breakpoint.hormonalBirthControlHowLong]: hormonalBirthControlHowLong, // common
  [Breakpoint.medicalConditions]: medicalConditions, // common
  [Breakpoint.cycleChanges]: cycleChanges, // common
  [Breakpoint.whichWearable]: whichWearable, // common
  [Breakpoint.eligibleAppleWatch]: eligibleAppleWatch, // common
  [Breakpoint.sleepWearable]: sleepWearable, // common
}

const fertilityGoalInfoContentData = (goal: Goal): InfoPageContent => {
  if (goal === Goal.PLAN) {
    return {
      titleKey: 'txt-quiz-pregnant-in-three-cycles',
      subtitleKey: 'txt-quiz-study-on-users',
      buttonKey: 'get-pregnant-faster',
    }
  }
  return {
    titleKey: 'txt-quiz-effective-typical-use',
    buttonKey: 'txt-next',
  }
}

export const fertilityGoalInfoContent = (goal: Goal, locale: LANG): InfoPageContent => {
  const lang = locale.split('-')[0] || 'en'
  const goalKey = _numberEnumKey(Goal, goal)
  const ext = goal === Goal.PLAN ? 'png' : 'svg'
  return {
    ...fertilityGoalInfoContentData(goal),
    imageUrl: `${baseImgLink}fertility-goal-info-${goalKey.toLowerCase()}-${lang}.${ext}`,
  }
}
