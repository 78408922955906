import { _deepCopy, UnixTimestamp } from '@naturalcycles/js-lib'
import { BackendResponseFM, Message2Answer, Message2FM } from '@naturalcycles/shared'
import { Action, Reducer } from 'redux'
import { Message } from '../model/message.model'

export interface MessagesState {
  messages: Message[]
  deletedMessages: Message[]
  unreadMessages: number
}

type State = MessagesState

const initialState: State = {
  messages: [],
  deletedMessages: [],
  unreadMessages: 0,
}

const actions: any = {
  extendMessages(state: State, action: { type: string; payload: MessagesState }): State {
    return {
      ...state,
      ...action.payload,
    }
  },

  readMessage(state: State, action: { type: string; payload: Message }): State {
    const newState = {
      messages: [...state.messages],
      deletedMessages: [...state.deletedMessages],
      unreadMessages: state.unreadMessages,
    }

    const readMessageIndex = newState.messages.findIndex(m => m.id === action.payload.id)

    newState.messages[readMessageIndex] = {
      ...newState.messages[readMessageIndex]!,
      read: Date.now() as UnixTimestamp, // todo: this looks like a bug! should be timestamp in seconds, instead of millis
    }

    return newState
  },

  moveDeleteMessage(state: State, action: { type: string; payload: { message: Message } }): State {
    const messages = state.messages.filter(m => m.id !== action.payload.message.id)
    const deletedMessages = [action.payload.message]

    const newState = {
      messages,
      deletedMessages: [...state.deletedMessages],
      unreadMessages: state.unreadMessages,
    }

    deletedMessages.forEach(m => {
      newState.deletedMessages.push({
        ...m,
        deleted: true,
      })
    })
    return newState
  },

  removeFromDeleteMessage(
    state: State,
    action: { type: string; payload: { message: Message } },
  ): State {
    return {
      ...state,
      deletedMessages: [...state.deletedMessages].filter(m => m.id !== action.payload.message.id),
    }
  },

  undoDeleteMessage(state: State, action: { type: string; payload: { message: Message } }): State {
    return {
      ...state,
      deletedMessages: [...state.deletedMessages].filter(m => m.id !== action.payload.message.id),
      messages: [
        ...state.messages,
        {
          ...action.payload.message,
          deleted: undefined,
        },
      ].sort((a, b) => b.created - a.created),
    }
  },

  clearDeletedMessages(state: State): State {
    return {
      messages: [...state.messages],
      deletedMessages: [],
      unreadMessages: state.unreadMessages,
    }
  },

  removeAnswerFromMessage(
    state: State,
    action: {
      type: string
      payload: { message: Message; answer: Message2Answer }
    },
  ): State {
    const messages = _deepCopy(state.messages)
    messages.map(m => {
      if (m.id !== action.payload.message.id) return
      if (!m.answers) return
      m.selectedAnswers = undefined
      m.answers.map(a => {
        a.selected = false
      })
    })

    return {
      messages,
      deletedMessages: [...state.deletedMessages],
      unreadMessages: state.unreadMessages,
    }
  },

  onBackendResponse(state: State, action: { type: string; payload: BackendResponseFM }): State {
    if (
      !action.payload.generatedMessages2 &&
      !action.payload.messages2 &&
      !action.payload.unreadMessages
    ) {
      return state
    }

    const newState = {
      messages: [...state.messages],
      deletedMessages: [...state.deletedMessages],
      unreadMessages: state.unreadMessages,
    }

    if (action.payload.unreadMessages !== undefined) {
      newState.unreadMessages = action.payload.unreadMessages
    }

    if (action.payload.generatedMessages2) {
      action.payload.generatedMessages2.forEach(message => {
        const generatedMessage = {
          ...message,
          id: `${message.msgKey}_${message.created}`,
        }
        if (!newState.messages.some(m => m.id === generatedMessage.id)) {
          newState.messages.push(generatedMessage)
        }
      })
    }

    if (action.payload.messages2?.length) {
      newState.messages = action.payload.messages2.map((message: Message2FM) => {
        const answers = message.answers?.map(a => a.numberOfAnswers || 0)

        const totalAnswers = answers?.reduce((a, b) => a + b)
        const selectedAnswers = message.answers?.filter(a => !!a.selected)

        return {
          ...message,
          id: `${message.msgKey}_${message.created}`,
          totalAnswers,
          selectedAnswers,
        }
      })
    }

    newState.messages.sort((a, b) => b.created - a.created)

    return newState
  },
}

// biome-ignore lint/style/useDefaultParameterLast: ok
export const messagesReducer: Reducer<State> = (state = initialState, action: Action) => {
  if (actions[action.type]) return actions[action.type](state, action)
  return state
}
