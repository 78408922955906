import { ICON } from '@app/cnst/icons.cnst'
import { IsoDate } from '@naturalcycles/js-lib'
import {
  BaseColorCode,
  DailyEntryBM,
  DataFlag,
  DataIntensity,
  DataQuantity,
  HardwareId,
  PregnancyEndCareType,
} from '@naturalcycles/shared'

// Base interface to display a UserFertility day (colorMap + predictionMap)
// Color should be updated to Offline if there are Modified Daily Entries
export interface UFDay extends BaseColorCode {
  colorClass?: string
  fertilityStatus?: FertilityStatus
  prediction?: boolean
  today?: boolean
  offline?: boolean
  /**
   * Prediction
   */
  mensQuantity?: DataQuantity
  /**
   * Prediction
   */
  dataFlags?: DataFlag[]
  cycleStartDate?: string
  hwIdChange?: HardwareId
  pregnancyEndCare?: PregnancyEndCareType[]
  pregnancyData?: PregnancyNumbers
  gaveBirth?: true
  /**
   * Postpartum cycle (until user adds period),
   * returns true on all days in one cycle right after the pregnant cycle
   */
  isCycleAfterBirth?: boolean
}

export type TrimesterNumber = 1 | 2 | 3

export interface PregnancyNumbers {
  /**
   * Example for pregnancy day 26+4 => 27
   */
  weekInclusive: number
  /**
   * Example for pregnancy day 26+4 => 26
   */
  week: number
  /**
   * Example for pregnancy day 26+4 => 4
   */
  day: number
  trimester: TrimesterNumber
}

// Base interface to display a day with entry (UFDay + EntryMap + Modified Daily Entries)
export interface UFEntry extends UFDay, Omit<DailyEntryBM, 'dataFlags' | 'babyFeeding'> {
  moods?: DataFlag[]
  pains?: DataFlag[]
  skinFlags?: DataFlag[]
  deviationReasons?: DataFlag[]
  emergencyFlags?: DataFlag[]
  /** Oura specific DataFlags that does not exclude the temperature */
  ouraFlags?: DataFlag[]
  covidFlags?: DataFlag[]
  sexFlags?: DataFlag[]
  intensity?: DataIntensity
  babyFeeding?: BabyFeedingFM
}

export interface FertilityCircleEntry extends UFDay {
  icon?: FertilityIcon
  statusIcon?: ICON
  topLabel?: string
}

export interface FertilityIcon {
  icon: ICON
  prediction: boolean
}

export interface FertilityStatus {
  key: string
  num?: number
}

export enum NoStatusState {
  NO_THERM = 'NO_THERM',
  WAITING = 'WAITING',
  HAS_THERM = 'HAS_THERM',
  CONNECTED_THERM_UNPAIRED = 'CONNECTED_THERM_UNPAIRED',
  UEBE_NO_THERM = 'UEBE_NO_THERM',
  T3_NO_THERM = 'T3_NO_THERM',
}

export const NO_THERM_STATES = [
  NoStatusState.NO_THERM,
  NoStatusState.UEBE_NO_THERM,
  NoStatusState.T3_NO_THERM,
]

export interface TrimesterProgress {
  length: number
  progress: number
  transform: string
}

export interface PregnancyInfo {
  conceptionDate: IsoDate
  dueDate: IsoDate
  weekString: string
}

export interface BabyFeedingFM {
  breastFeeding?: number
  bottleFeeding?: number
  pumping?: number
}
