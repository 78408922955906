import { _mapValues, StringMap } from '@naturalcycles/js-lib'
import { LANG } from '@naturalcycles/shared'

export enum LINK {
  // website
  ABOUT = 'https://www.naturalcycles.com/about/?utm_source=NCApp',
  SCIENCE = 'https://www.naturalcycles.com/the-science/?utm_source=NCApp',

  // shop
  SHOP = 'https://www.naturalcycles.com/shop',

  // cyclerpedia
  SUPPORT = 'https://help.naturalcycles.com/hc/en-us/?utm_source=NCApp',
  SUPPORT_REQUEST = 'https://help.naturalcycles.com/hc/en-us/requests/new/?utm_source=NCApp',
  BREASTFEED = 'https://help.naturalcycles.com/hc/en-us/articles/360003291094-Measuring-and-breastfeeding/?utm_source=NCApp',
  PERIOD = 'https://help.naturalcycles.com/hc/en-us/articles/360003357753-What-is-a-period-/?utm_source=NCApp',
  ANOVULATORY = 'https://help.naturalcycles.com/hc/en-us/articles/360003362933-Anovulatory-cycles/?utm_source=NCApp',
  FOLLICULAR = 'https://help.naturalcycles.com/hc/en-us/articles/360003334234-What-is-the-follicular-phase-/?utm_source=NCApp',
  LUTEAL = 'https://help.naturalcycles.com/hc/en-us/articles/360003358153-What-is-the-luteal-phase-/?utm_source=NCApp',
  CYCLE_LENGTH = 'https://help.naturalcycles.com/hc/en-us/articles/360003357553-Irregular-cycles/?utm_source=NCApp',
  LH_TESTS = 'https://help.naturalcycles.com/hc/en-us/articles/360003363393-How-and-when-to-measure-LH/?utm_source=NCApp',
  TEMPERATURE = 'https://help.naturalcycles.com/hc/en-us/articles/360003290754-My-temperature-fluctuates-why-and-what-can-I-do-about-this-/?utm_source=NCApp',
  OVULATION = 'https://help.naturalcycles.com/hc/en-us/articles/360003357993--What-is-ovulation-/?utm_source=NCApp',
  OVULATION_DETECTION = 'https://help.naturalcycles.com/hc/en-us/articles/360003335494-Ovulation-detection/?utm_source=NCApp',
  SLEEP = 'https://help.naturalcycles.com/hc/en-us/articles/360003290914-I-wake-up-during-the-night/?utm_source=NCApp',
  EXCLUDING = 'https://help.naturalcycles.com/hc/en-us/articles/360003316673-Excluding-a-temperature/?utm_source=NCApp',
  GRAPH_COMPARE = 'https://help.naturalcycles.com/hc/en-us/articles/360000767397-Compare-Cycles/?utm_source=NCApp',
  OURA_SECTION = 'https://help.naturalcycles.com/hc/en-us/sections/360004218317-Oura/?utm_source=NCApp',
  SUPPORT_OURA = 'https://help.naturalcycles.com/hc/en-us/articles/360013838318/?utm_source=NCApp',
  FERTILITY_INDICATORS = 'https://help.naturalcycles.com/hc/en-us/articles/360003287314-Fertility-indicators-used-by-Natural-Cycles/?utm_source=NCApp',
  DOCTOR_USE_DATA = 'https://help.naturalcycles.com/hc/en-us/articles/360003369073--How-can-a-doctor-use-the-information-/?utm_source=NCApp',
  HOW_NC_WORKS = 'https://help.naturalcycles.com/hc/en-us/articles/360003306893/?utm_source=NCApp',

  // used for redirecting to cyclerpedia with chatbot token as url param
  SUPPORT_CHATBOT_REDIRECT = 'https://help.naturalcycles.com/hc/en-us/p/redirect/?utm_source=NCApp',
  REDIRECT_TO_SUPPORT = 'hc/en-us/?utm_source=NCApp',

  // T2 articles
  T2_BATTERY_REPLACEMENT = 'https://help.naturalcycles.com/hc/sv/articles/4412684139153-How-to-change-the-battery-on-the-NC-Thermometer-Gen2-/?utm_source=NCApp',

  // T3 articles
  T3_FAQ = 'https://help.naturalcycles.com/hc/en-us/sections/9059899356701-NC-Thermometer-Gen3/?utm_source=NCApp',
  T3_BATTERY_REPLACEMENT = 'https://help.naturalcycles.com/hc/en-us/articles/9243681555741-How-to-change-the-battery-on-the-NC-Thermometer-Gen3/?utm_source=NCApp',
  T3_TEMPERATURE_SYNCING = 'https://help.naturalcycles.com/hc/en-us/articles/9242539601565-How-to-sync-your-temperature-to-the-app-with-the-NC-Thermometer-Gen3/?utm_source=NCApp',
  T3_TROUBLESHOOTING = 'https://help.naturalcycles.com/hc/en-us/articles/9243087783453-How-to-troubleshoot-the-NC-Thermometer-Gen3/?utm_source=NCApp',
  T3_PAIRING = 'https://help.naturalcycles.com/hc/en-us/articles/9242357893533-How-to-pair-your-NC-Thermometer-Gen3-with-the-app/?utm_source=NCApp',
  T3_FOTA = 'https://help.naturalcycles.com/hc/en-us/articles/9313469995549-How-to-do-a-firmware-update-on-the-NC-Thermometer-Gen3/?utm_source=NCApp',

  // Chatbot flows
  GIVE_FEEDBACK_CHATBOT = 'https://help.naturalcycles.com/hc/en-us/requests/new?flowRedirectionId=4',
  GIVE_FEEDBACK_CHATBOT_TEST = 'https://chatbot.naturalcycles.com/?agent=SupportAgent&flowRedirectionId=4',
  REPORT_BUG_CHATBOT = 'https://help.naturalcycles.com/hc/en-us/requests/new?flowRedirectionId=3',
  REPORT_BUG_CHATBOT_TEST = 'https://chatbot.naturalcycles.com/?agent=SupportAgent&flowRedirectionId=3',
  T3_REPLACEMENT_CHATBOT = 'https://help.naturalcycles.com/hc/en-us/requests/new?flowRedirectionId=1',
  T3_REPLACEMENT_CHATBOT_TEST = 'https://chatbot.naturalcycles.com/?env=SupportAgent&flowRedirectionId=1',

  // Apple Watch
  APPLE_WATCH_FAQ = 'https://help.naturalcycles.com/hc/en-us/sections/10974259347357-Apple-Watch?utm_source=NCApp',

  // legal
  TERMS_OF_USE = 'https://www.naturalcycles.com/app-legal/?utm_source=NCApp',
  PRIVACY_POLICY = 'https://www.naturalcycles.com/app-legal/privacy-policy.html?utm_source=NCApp',

  // reviews
  TRUSTPILOT = 'https://www.trustpilot.com/evaluate/www.naturalcycles.com',
  AMAZON = 'https://www.amazon.com/ap/signin?openid.pape.max_auth_age=3600&openid.return_to=https%3A%2F%2Fwww.amazon.com%2Freview%2Fcreate-review%2Fref%3Dcm_cr_othr_d_wr_but_top%3Fchannel%3Dglance-detail%26asin%3DB096L84TM8%26ie%3DUTF8&openid.identity=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&openid.assoc_handle=amzn_scarface_mobile_us&openid.mode=checkid_setup&language=en_US&openid.claimed_id=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&pageId=login&openid.ns=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0',

  // other
  BLOG = 'https://www.naturalcycles.com/cyclematters/?utm_source=NCApp',
  INSTAGRAM = 'https://www.instagram.com/naturalcycles/',
  FACEBOOK = 'https://www.facebook.com/NaturalCyclesNC',
  TWITTER = 'https://twitter.com/naturalcycles',
  APP_STORE = 'https://apps.apple.com/us/app/natural-cycles-birth-control/id765535549',
  GOOGLE_PLAY = 'https://play.google.com/store/apps/details?id=com.naturalcycles.cordova',
  OURA_AUTH = 'https://cloud.ouraring.com/oauth/authorize?response_type=code&client_id=4OKNWEOTPVHEEKLJ&scope=daily%20email%20ring_configuration',
  OURA_AUTH_INTERNAL = 'https://cloud.ouraring.com/oauth/authorize?response_type=code&client_id=T2KM5J5L2ZW3YDOB&scope=daily%20email%20ring_configuration',

  CLEARBLUE_APP_STORE = 'https://itunes.apple.com/app/naturalcycles/id1579515596?ls=1&mt=8',
  CLEARBLUE_GOOGLE_PLAY = 'https://play.google.com/store/apps/details?id=com.naturalcycles.clearblue',
}

const LINKS_EN_UK: Readonly<StringMap> = {
  // website
  [LINK.ABOUT]: 'https://www.naturalcycles.com/en/about',
  [LINK.SCIENCE]: 'https://www.naturalcycles.com/en/the-science',

  // legal
  [LINK.TERMS_OF_USE]: 'https://www.naturalcycles.com/en/app-legal',
  [LINK.PRIVACY_POLICY]: 'https://www.naturalcycles.com/en/app-legal/privacy-policy.html',
}

const LINKS_SV: Readonly<StringMap> = {
  // website
  [LINK.ABOUT]: 'https://www.naturalcycles.com/sv/about',
  [LINK.SCIENCE]: 'https://www.naturalcycles.com/sv/science',

  // cyclerpedia
  [LINK.REDIRECT_TO_SUPPORT]: 'hc/sv/?utm_source=NCApp',
  [LINK.SUPPORT]: 'https://help.naturalcycles.com/hc/sv',
  [LINK.BREASTFEED]:
    'https://help.naturalcycles.com/hc/sv/articles/360003291094-Measuring-and-breastfeeding',
  [LINK.PERIOD]: 'https://help.naturalcycles.com/hc/sv/articles/360003357753-What-is-a-period-',
  [LINK.ANOVULATORY]:
    'https://help.naturalcycles.com/hc/sv/articles/360003362933-Anovulatory-cycles',
  [LINK.FOLLICULAR]:
    'https://help.naturalcycles.com/hc/sv/articles/360003334234-What-is-the-follicular-phase-',
  [LINK.LUTEAL]:
    'https://help.naturalcycles.com/hc/sv/articles/360003358153-What-is-the-luteal-phase-',
  [LINK.CYCLE_LENGTH]:
    'https://help.naturalcycles.com/hc/sv/articles/360003357553-Irregular-cycles',
  [LINK.LH_TESTS]:
    'https://help.naturalcycles.com/hc/sv/articles/360003363393-How-and-when-to-measure-LH',
  [LINK.TEMPERATURE]:
    'https://help.naturalcycles.com/hc/sv/articles/360003290754-My-temperature-fluctuates-why-and-what-can-I-do-about-this-',
  [LINK.OVULATION]:
    'https://help.naturalcycles.com/hc/sv/articles/360003357993--What-is-ovulation-',
  [LINK.OVULATION_DETECTION]:
    'https://help.naturalcycles.com/hc/sv/articles/360003335494-Ovulation-detection',
  [LINK.SLEEP]:
    'https://help.naturalcycles.com/hc/sv/articles/360003290914-I-wake-up-during-the-night',
  [LINK.EXCLUDING]:
    'https://help.naturalcycles.com/hc/sv/articles/360003316673-Excluding-a-temperature',
  [LINK.GRAPH_COMPARE]:
    'https://help.naturalcycles.com/hc/sv/articles/360000767397-J%C3%A4mf%C3%B6r-dina-cykler',
  [LINK.FERTILITY_INDICATORS]:
    'https://help.naturalcycles.com/hc/sv/articles/360003287314-Fertility-indicators-used-by-Natural-Cycles',
  [LINK.DOCTOR_USE_DATA]:
    'https://help.naturalcycles.com/hc/sv/articles/360003369073--How-can-a-doctor-use-the-information-',
}

const LINKS_ES: Readonly<StringMap> = {
  // website
  [LINK.ABOUT]: 'https://www.naturalcycles.com/es/about',
  [LINK.SCIENCE]: 'https://www.naturalcycles.com/es/science',

  // cyclerpedia
  [LINK.REDIRECT_TO_SUPPORT]: 'hc/es/?utm_source=NCApp',
  [LINK.SUPPORT]: 'https://help.naturalcycles.com/hc/es',
  [LINK.BREASTFEED]:
    'https://help.naturalcycles.com/hc/es/articles/360003291094-Measuring-and-breastfeeding',
  [LINK.PERIOD]: 'https://help.naturalcycles.com/hc/es/articles/360003357753-What-is-a-period-',
  [LINK.ANOVULATORY]:
    'https://help.naturalcycles.com/hc/es/articles/360003362933-Anovulatory-cycles',
  [LINK.FOLLICULAR]:
    'https://help.naturalcycles.com/hc/es/articles/360003334234-What-is-the-follicular-phase-',
  [LINK.LUTEAL]:
    'https://help.naturalcycles.com/hc/es/articles/360003358153-What-is-the-luteal-phase-',
  [LINK.CYCLE_LENGTH]:
    'https://help.naturalcycles.com/hc/es/articles/360003357553-Irregular-cycles',
  [LINK.LH_TESTS]:
    'https://help.naturalcycles.com/hc/es/articles/360003363393-How-and-when-to-measure-LH',
  [LINK.TEMPERATURE]:
    'https://help.naturalcycles.com/hc/es/articles/360003290754-My-temperature-fluctuates-why-and-what-can-I-do-about-this-',
  [LINK.OVULATION]:
    'https://help.naturalcycles.com/hc/es/articles/360003357993--What-is-ovulation-',
  [LINK.OVULATION_DETECTION]:
    'https://help.naturalcycles.com/hc/es/articles/360003335494-Ovulation-detection',
  [LINK.SLEEP]:
    'https://help.naturalcycles.com/hc/es/articles/360003290914-I-wake-up-during-the-night',
  [LINK.EXCLUDING]:
    'https://help.naturalcycles.com/hc/es/articles/360003316673-Excluding-a-temperature',
  [LINK.GRAPH_COMPARE]:
    'https://help.naturalcycles.com/hc/es/articles/360000767397-Comparar-ciclos',
  [LINK.FERTILITY_INDICATORS]:
    'https://help.naturalcycles.com/hc/es/articles/360003287314-Fertility-indicators-used-by-Natural-Cycles',
  [LINK.DOCTOR_USE_DATA]:
    'https://help.naturalcycles.com/hc/es/articles/360003369073--How-can-a-doctor-use-the-information-',
}

const LINKS_PT: Readonly<StringMap> = {
  [LINK.ABOUT]: 'https://www.naturalcycles.com/pt-br/about',
  [LINK.SCIENCE]: 'https://www.naturalcycles.com/pt-br/science',

  // cyclerpedia
  [LINK.REDIRECT_TO_SUPPORT]: 'hc/pt/?utm_source=NCApp',
  [LINK.SUPPORT]: 'https://help.naturalcycles.com/hc/pt',
  [LINK.BREASTFEED]:
    'https://help.naturalcycles.com/hc/pt/articles/360003291094-Measuring-and-breastfeeding',
  [LINK.PERIOD]: 'https://help.naturalcycles.com/hc/pt/articles/360003357753-What-is-a-period-',
  [LINK.ANOVULATORY]:
    'https://help.naturalcycles.com/hc/pt/articles/360003362933-Anovulatory-cycles',
  [LINK.FOLLICULAR]:
    'https://help.naturalcycles.com/hc/pt/articles/360003334234-What-is-the-follicular-phase-',
  [LINK.LUTEAL]:
    'https://help.naturalcycles.com/hc/pt/articles/360003358153-What-is-the-luteal-phase-',
  [LINK.CYCLE_LENGTH]:
    'https://help.naturalcycles.com/hc/pt/articles/360003357553-Irregular-cycles',
  [LINK.LH_TESTS]:
    'https://help.naturalcycles.com/hc/pt/articles/360003363393-How-and-when-to-measure-LH',
  [LINK.TEMPERATURE]:
    'https://help.naturalcycles.com/hc/pt/articles/360003290754-My-temperature-fluctuates-why-and-what-can-I-do-about-this-',
  [LINK.OVULATION]:
    'https://help.naturalcycles.com/hc/pt/articles/360003357993--What-is-ovulation-',
  [LINK.OVULATION_DETECTION]:
    'https://help.naturalcycles.com/hc/pt/articles/360003335494-Ovulation-detection',
  [LINK.SLEEP]:
    'https://help.naturalcycles.com/hc/pt/articles/360003290914-I-wake-up-during-the-night',
  [LINK.EXCLUDING]:
    'https://help.naturalcycles.com/hc/pt/articles/360003316673-Excluding-a-temperature',
  [LINK.GRAPH_COMPARE]: 'https://help.naturalcycles.com/hc/pt/articles/360000767397-Compare-Ciclos',
  [LINK.FERTILITY_INDICATORS]:
    'https://help.naturalcycles.com/hc/pt/articles/360003287314-Fertility-indicators-used-by-Natural-Cycles',
  [LINK.DOCTOR_USE_DATA]:
    'https://help.naturalcycles.com/hc/pt/articles/360003369073--How-can-a-doctor-use-the-information-',
}

// Links translated by ui.lang
export const TRANSLATED_LINKS: Readonly<StringMap<StringMap>> = {
  [LANG.en_GB]: LINKS_EN_UK,
  [LANG.sv_SE]: LINKS_SV,
  [LANG.es_US]: LINKS_ES,
  [LANG.pt_BR]: LINKS_PT,
}

_mapValues(TRANSLATED_LINKS, (_k, lang) =>
  _mapValues(lang, (_k2, link) => `${link}/?utm_source=NCApp`, true),
)

export enum DynamicLink {
  IFU = 'IFU',
}
