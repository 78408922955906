import { DecimalPipe } from '@angular/common'
import { Injectable } from '@angular/core'
import { getState } from '@app/srv/store.service'

@Injectable({ providedIn: 'root' })
export class UnitsService {
  ensureCorrectTempUnit(v: number, shouldBeFahrenheit: boolean): number {
    if (shouldBeFahrenheit) return this.convertTempToFahrenheit(v)
    return this.convertTempToCelsius(v)
  }

  ensureCorrectTempDiffUnit(
    v: number,
    vIsFahrenheit: boolean,
    shouldBeFahrenheit: boolean,
  ): number {
    if (shouldBeFahrenheit) {
      return this.convertTempDiffToFahrenheit(v, vIsFahrenheit)
    }
    return this.convertTempDiffToCelsius(v, !vIsFahrenheit)
  }

  convertTempToFahrenheit(v: number): number {
    if (v > 50) return v // already F
    return v * 1.8 + 32
  }

  /**
   Converts T3 temperatures represented as Celsius * 1000 to fahrenheit equivalent
   Uses integer multiplication to mimic implementation in C
   */
  public convertIntTempToFahrenheit(v: number): number {
    if (v > 50_000) return v // already F
    return Math.floor(v * 1.8 + 32_000)
  }

  convertTempToCelsius(v: number): number {
    if (v <= 50) return v // already C
    return (v - 32) / 1.8
  }

  convertTempDiffToFahrenheit(v: number, alreadyFahrenheit: boolean): number {
    if (alreadyFahrenheit) return v
    return v * 1.8
  }

  convertTempDiffToCelsius(v: number, alreadyCelsius: boolean): number {
    if (alreadyCelsius) return v
    return v / 1.8
  }

  getWeightUnit(imperialUnits?: boolean): string {
    return imperialUnits ? 'lbs' : 'kg'
  }

  calcWeightGain(pweek: number, h: number, w: number, imp: boolean): number {
    // if h is in mm, convert to cm
    if (h > 1000) {
      h /= 10
    }

    // if w is in gram, convert to kg
    if (w > 1000) {
      w /= 1000
    }

    let bmi = 22.5
    if (h && w) {
      bmi = (100 * 100 * w) / (h * h)
      // if (imp) bmi = bmi * 703 // we always use kg and cm on server
    }

    // weight gain in pounds, total should be 34, 27, 20, 15
    let wg = 0
    if (pweek > 5) {
      for (let i = 6; i <= pweek; i++) {
        if (i < 14) {
          if (bmi < 19) wg += 0.6
          else if (bmi < 25) wg += 0.4
          else if (bmi < 30) wg += 0.1
        } else {
          if (bmi < 19) wg += 1.1
          else if (bmi < 25) wg += 0.9
          else if (bmi < 30) wg += 0.7
          else wg += 0.6
        }
      }
    }

    if (!imp) wg *= 0.4536
    return wg
  }

  getNumberSeparator(): string {
    const { locale } = getState().ui
    const num = 1
    return num
      .toLocaleString(locale, { style: 'decimal', minimumFractionDigits: 2 })
      .substring(1, 2)
  }

  localizeNumber(num: number, decimals = 0): string {
    const { locale } = getState().ui
    return new DecimalPipe(locale).transform(num, `0.${decimals}-${decimals}`) || `${num}`
  }

  /**
   * Epsilon thingy is inspired by this: https://stackoverflow.com/a/11832950/4919972
   */
  roundToTwoDecimals(n: number): number {
    return Math.round((n + Number.EPSILON) * 100) / 100
  }
}
