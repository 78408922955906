import { ICON } from '@app/cnst/icons.cnst'
import { UFEntry } from '@app/model/uf.model'
import { IsoDate, UnixTimestampMillis } from '@naturalcycles/js-lib'
import { DataIntensity, Mens, OvulationStatus } from '@naturalcycles/shared'
export interface YAxis {
  min: number
  max: number
}

export interface Timestamp {
  start: UnixTimestampMillis
  end: UnixTimestampMillis
  mid: UnixTimestampMillis
}

export enum MensStatus {
  CONFIRMED = 1,
  SPOTTING = 2,
  PREDICTION = 3,
  WITHDRAWAL = 4,
  MISCARRIAGE = 5,
  POSTPARTUM = 6,
}

export const STATUS_BY_MENS: Record<Mens, MensStatus> = {
  [Mens.MENSTRUATION]: MensStatus.CONFIRMED,
  [Mens.SPOTTING]: MensStatus.SPOTTING,
  [Mens.WITHDRAWAL]: MensStatus.WITHDRAWAL,
  [Mens.MISCARRIAGE_BLEEDING]: MensStatus.MISCARRIAGE,
  [Mens.POSTPARTUM_BLEEDING]: MensStatus.POSTPARTUM,
}

export interface GraphEntry extends Omit<UFEntry, 'mens' | 'fertilityStatus' | 'color' | 'nCycle'> {
  timestamp: Timestamp
  color: string
  nCycle: number
  mens?: MensStatus
  fertile?: boolean
  middleOfCycle?: boolean
}

export interface CycleGraphEntry extends GraphEntry {
  sexIcon?: TrackerIcon
  trackerIcon?: TrackerIcon
}

export interface HolisticGraphEntry
  extends Omit<GraphEntry, 'mensQuantity' | 'libido' | 'sleep' | 'pains' | 'moods' | 'hwIdChange'> {
  hasTrackers?: boolean
  mensQuantity?: GraphEntryTracker
  cervicalMucus?: GraphEntryTracker
  libido?: GraphEntryTracker
  sleep?: GraphEntryTracker
  pain?: GraphEntryTracker
  mood?: GraphEntryTracker
  skin?: GraphEntryTracker
}

export interface BabyFeedingGraphEntry extends GraphEntry {
  weekday: string
  totalFeedings: number
  startOfWeek?: boolean
  first?: boolean
  last?: boolean
}

export const FEEDING_PATTERN_DISPLAY_DATES = 7

export type GraphCanvasEntry =
  | GraphEntry
  | HolisticGraphEntry
  | CycleGraphEntry
  | BabyFeedingGraphEntry

export interface TrackerIcon {
  backgroundColor: string
  icon?: GraphIcon
  label?: string
}

export interface GraphArea {
  timestamp: Timestamp
}

export interface OvulationArea extends GraphArea {
  status: OvulationStatus
}

export interface MensArea extends GraphArea {
  status: MensStatus
}

export interface FertilityColorLine {
  color: string
  temperatures: ColorLineTemperature[]
  prediction?: boolean
}

export interface ColorLineTemperature {
  x: number
  y: number
}

export interface CompareItem {
  nCycle: number
  entries: GraphEntry[]
  mensAreas: MensArea[]
  ovulationAreas: OvulationArea[]
  fertilityColorLines: FertilityColorLine[]
  pregnantCycle?: boolean
}

export interface GradientDrawing {
  // Must use RGBA Colors due to safari
  startColor: string
  stopColor: string
}

export interface TextStyle {
  color?: string
  font?: string
  baseline?: CanvasTextBaseline
  textAlign?: CanvasTextAlign
}

export interface GraphIcon {
  icon: ICON
  color?: string
  colorDark?: string // if we need to specify dark mode color, DARK_COLOR_MAP will be used by default
  img?: HTMLImageElement
}

export interface WH {
  width: number
  height: number
}

export interface GraphConfig {
  xAxis?: boolean
  topIcons?: boolean
  coverLineTemperature?: boolean
  todayLine?: boolean
  areaLabels?: boolean
}

/* global GlobalCompositeOperation */
export interface GraphCanvasConfig {
  iconPadding: number
  cycleAreaBounds: CycleAreaBounds
  areaGlobalCompositeOperation?: GlobalCompositeOperation
}

export type AreaPosition = 'top' | 'bottom'

export interface AreaInput {
  timestamp: Timestamp
  gradient?: GradientDrawing
  reverseGradient?: boolean
  icon?: GraphIcon
  text?: string
  position?: AreaPosition
}

export interface AreaCoordinates {
  startX: number
  startY: number
  midX: number
  width: number
  height: number
}

export interface CycleAreaBounds {
  top: number
  bottom: number
}

export interface BorderRadius {
  topLeft: number
  topRight: number
  bottomRight: number
  bottomLeft: number
}

export interface GraphEntryTracker {
  color: string
  quantity: number
  backgroundOpacity: string
  icons?: GraphIcon[]
  intensity?: DataIntensity
}

export interface GraphEntryClick {
  date: IsoDate
  skipPreview?: boolean
  hasData?: boolean
}
