import { inject, Injectable } from '@angular/core'
import { AdminService } from '@app/srv/admin.service'
import { getState } from '@app/srv/store.service'
import { logUtil } from '@app/util/log.util'
import {
  Adjust,
  AdjustConfig,
  AdjustEnvironment,
  AdjustEvent,
  AdjustEventFailure,
  AdjustLogLevel,
} from '@awesome-cordova-plugins/adjust/ngx'
import { Platform } from '@ionic/angular/standalone'
import { env } from '@src/environments/environment'

@Injectable({ providedIn: 'root' })
export class AdjustService {
  private loaded = false

  private platform = inject(Platform)
  private adjust = inject(Adjust)
  private adminService = inject(AdminService)

  async init(): Promise<void> {
    if (!this.platform.is('hybrid') || !env.adjustApiToken) return

    const { account } = getState()

    // Abort adjust initialization if this user matches any of the below conditions
    // Initializing adjust (unless we setEnabled(false) ) will send session/APP_ACTIVATE events that would be forwarded
    // to ad partners. This only ok to do if user never paid/had active subscription AND she didn't disable social media
    // marketing
    if (
      account.nextPaymentDate ||
      account.completeDate ||
      account.marketingSocialConsent === false
    ) {
      logUtil.log("User doesn't qualify for adjust events, skipping adjust initialization")
      if (await this.adjust.isEnabled()) {
        // If user qualified earlier and there's an instance of adjust, disable it
        this.adjust.setEnabled(false)
      }
      this.loaded = false
      return
    }

    const config = new AdjustConfig(
      env.adjustApiToken,
      env.adjustProd ? AdjustEnvironment.Production : AdjustEnvironment.Sandbox,
    )
    config.setAppSecret(2, 1196655376, 214278009, 871465970, 1441882063)
    // config.setLogLevel(AdjustLogLevel.Verbose) // use for debugging
    config.setLogLevel(AdjustLogLevel.Info)
    config.setEventTrackingFailedCallbackListener((e: AdjustEventFailure) => {
      // alert('adjust event failed: ' + e.eventToken)

      logUtil.log('adjust.service adjust event failed', { adjustEventFailure: e })
    })

    this.adjust.create(config)
    this.loaded = true
  }

  trackEvent(
    eventToken: string,
    revenue?: number,
    cur?: string,
    transactionId?: string,
    callbackParams: any = {},
  ): void {
    if (!this.loaded) return
    if (!env.analyticsForAdmins && this.adminService.isAdmin() && !this.adminService.isTestUser()) {
      return
    } // don't track events in prod if you are logged in with red dot

    const e = new AdjustEvent(eventToken)
    if (revenue && cur) e.setRevenue(revenue, cur)
    if (transactionId) e.setTransactionId(transactionId)
    if (callbackParams) {
      Object.keys(callbackParams).forEach(k => {
        e.addCallbackParameter(k, callbackParams[k])
      })
    }
    this.adjust.trackEvent(e)
  }

  addSessionCallbackParameter(key: string, value: string): void {
    if (!this.loaded) return
    this.adjust.addSessionCallbackParameter(key, value)
  }
}
