// 160ms corresponds to ~6fps (160 * 6 ~= 1000), so, it's considered "laggy" if it drops below that
// const LAG_THRESHOLD = 160
// const LAG_IDEAL = 16 // 16ms, which corresponds to the ideal world 60fps

// These thresholds are now aligned with Lighthouse/Google ones,
// everything above 50ms is considered a "long task"
const LAG_THRESHOLD = 50
const LAG_IDEAL = 50

/**
 * LagCounter is a "stop function", when called - returns the number of lag milliseconds measured.
 */
export type LagCounter = () => number

/**
 * Starts the "Lag counter".
 * Returns a "stop function", when called - returns the number of lag milliseconds measured.
 */
export function lagCounter(): LagCounter {
  let stopped = false
  const started = Date.now()
  let now = started
  let lastNow = started
  let totalLag = 0
  let delta: number

  // Zone.js is configured to NOT patch RAF now
  requestAnimationFrame(animate)

  function animate(): void {
    if (stopped) return

    now = Date.now()
    delta = now - lastNow

    if (delta > LAG_THRESHOLD) {
      // LAGGY
      totalLag += delta - LAG_IDEAL
      console.log(`[lag] lag ${delta}, total ${totalLag}`)
    }

    lastNow = now

    requestAnimationFrame(animate)
  }

  return () => {
    stopped = true
    return totalLag
  }
}
