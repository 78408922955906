import { HKDevice, HKQuantitySample } from '@naturalcycles/shared'

export const MIN_OS_VER_FOR_AW = '16'

export enum AWWristTempSupportState {
  NOT_SUPPORTED = 1,
  UNKNOWN = 2,
  REPORTED_HR = 3,
  SUPPORTED = 4,
}

export enum AWSetupState {
  INCORRECT_HW_ID = 1,
  ANDROID = 2,
  TOO_LOW_IOS = 3,
  INCOMPATIBLE_WATCH = 4,
  SETUP_INCOMPLETE = 5,
  REVOKED_PERMISSIONS = 6,
  NO_SYNCED_TEMPERATURES = 7,
  SETUP_COMPLETE = 8,
}

export interface AWWristTempsDevice {
  lastUsedTimestamp: number
  sourceName?: string
  device?: HKDevice
}

export interface MultipleAWData {
  daysWithSingleWatch: HKQuantitySample[]
  daysWithMultipleWatches: HKQuantitySample[]
}
