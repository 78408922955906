import { appVer } from '@app/cnst'
import { isNativeApp } from '@app/cnst/userDevice.cnst'
import { DeviceService } from '@app/srv/device.service'
import { di } from '@app/srv/di.service'
import { firebaseService } from '@app/srv/firebase.service'
import { bootstrapDone } from '@app/srv/milestones'
import { buildInfo } from '@app/util/buildInfo.util'
import { addEventListenerNoZone } from '@app/util/zone.util'
import { _ms } from '@naturalcycles/js-lib'
import { env } from '@src/environments/environment'
import { logUtil } from './log.util'

/**
 * Index 0: milliseconds since window.mainStarted
 * Index 1: Text label of event
 *
 * @example: [512, 'bootstrap done']
 */
type PerfRecord = [millis: number, label: string]

declare global {
  interface Window {
    prf: PerfRecord[]

    prfFind: (label: string) => number | null

    /**
     * Returns human-readable prf report.
     */
    prflog: () => void

    prflogString: () => string

    cdCount: () => number
    enableCDCount: (enabled?: boolean) => void
    resetCDCount: () => void
  }
}

const { mainStarted } = window

export function prf(
  label: string,
  started = mainStarted,
  ended = Date.now(),
  preventDuplicates = true,
): void {
  void bootstrapDone.then(() => {
    if (preventDuplicates && window.prf.some(p => p[1] === label)) return // prevent duplicates

    // First step in refactoring our custom [prf] into a standard performance.* api
    performance.mark(label)

    const newTimestamp = ended
    const took = newTimestamp - started
    const duration = newTimestamp - started

    if (duration <= 0) {
      logUtil.log(`[prf] Non-positive trace duration! Label: ${label}, Duration: ${duration}`)
      return
    }

    firebaseService.recordTrace(label, started, duration, {
      attributes: {
        // max 5 attributes allowed!
        appVer,
        buildInfoVer: buildInfo.ver,
        cordova: String(isNativeApp),
        platform: di.get(DeviceService).getOS() || 'OTHER',
      },
    })

    logUtil.log([`[prf]`, label, _ms(took)].join(' '))

    window.prf.push([took, label])
  })
}

window.prflogString = () => {
  const tokens = [
    '[prf] prflog:',
    ...window.prf.map(p => {
      const [took, label] = p
      return [label, _ms(took)].join(' ')
    }),
  ]

  return tokens.join('\n')
}

window.prflog = () => console.log(window.prflogString())

let logCDCount = false

/**
 * Number of ChangeDetection runs since the beginning.
 */
export let cdCount = 0

export function incrementCdCount(): void {
  cdCount++
  if (logCDCount) logUtil.log(`[prf] cdCount ${cdCount}`)
}

window.cdCount = () => cdCount
window.enableCDCount = (enabled = true) => (logCDCount = enabled)
window.resetCDCount = () => (cdCount = 0)

// Not in test, cause it makes a weird circular dep between this file and DI
if (!env.test) {
  addEventListenerNoZone('readystatechange', () => {
    if (document.readyState === 'complete') prf('readyStateComplete')
  })
}

window.prfFind = (label: string) => window.prf.find(p => p[1] === label)?.[0] ?? null
