import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router'
import { PageGuard } from '@app/guards/page.guard'
import { bootstrapDone } from '@app/srv/milestones'
import { getState } from '@app/srv/store.service'
import { urlUtil } from '@app/util/url.util'

@Injectable({
  providedIn: 'root',
})
export class PartnerLoginPageGuard extends PageGuard {
  className = 'PartnerLoginPageGuard'

  private searchParams?: URLSearchParams

  override async canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): Promise<boolean | UrlTree> {
    this.route = _route

    await bootstrapDone

    const url = urlUtil.parseUrl(_state.url)
    this.searchParams = url.searchParams

    return await super.canActivate(_route, _state)
  }

  get requirements(): boolean[] {
    const email = this.searchParams?.get('email')
    return [
      !(this.hasSession() && getState().partnerAccount?.email === email), // partner already logged in
      !!this.searchParams?.get('name'),
      !!email,
      !!this.searchParams?.get('token'),
    ]
  }
}
