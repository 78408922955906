/**
 * MoEngage Capacitor SDK
 * https://developers.moengage.com/hc/en-us/categories/4417334650260-Capacitor-SDK
 */

import { Injectable } from '@angular/core'
import { StringMap } from '@naturalcycles/js-lib'
import { ExternalPersonalId } from '@naturalcycles/shared'
import { env } from '@src/environments/environment'
import {
  MoECapacitorCore,
  MoEInAppCustomActionData,
  MoEInAppLifecycleData,
  MoEInAppNavigationData,
  MoEInitConfig,
  MoEPushCampaign,
  MoEPushCampaignData,
} from 'capacitor-moengage-core'
import { isAndroidApp, isIOSApp, isWebApp } from '../cnst/userDevice.cnst'
import { di } from '../srv/di.service'
import { PushNotificationService } from '../srv/notification.push.service'
import { getState } from '../srv/store.service'
import { logUtil } from '../util/log.util'
import { EVENT } from './analytics.cnst'
import { AnalyticsService } from './analytics.service'

const appId = env.moeAppId || ''

@Injectable({ providedIn: 'root' })
export class MoeService {
  private disabled = true

  public async init(): Promise<void> {
    this.disabled = isWebApp || !env.moeAppId || !getState().remoteConfig.moEnabled

    if (this.disabled) return

    const initConfig: MoEInitConfig = {
      analyticsConfig: {
        shouldTrackUserAttributeBooleanAsNumber: true,
      },
    }

    try {
      await MoECapacitorCore.initialize({ appId, initConfig })

      this.setupListeners()
    } catch (err) {
      this.logMoError(err)
    }
  }

  public async setUniqueId(uniqueId: ExternalPersonalId): Promise<void> {
    if (this.disabled) return

    try {
      await MoECapacitorCore.setUniqueId({ uniqueId, appId })
    } catch (err) {
      this.logMoError(err)
    }
  }

  // TODO [2025-05-01] revisit me
  // It was decided to not track page views for now, but keeping the code in case we want to add it again
  // public async trackEvent(eventName: string, eventAttributes?: MoEProperties): Promise<void> {
  //   if (this.disabled || !this.eventTrackingEnabled) return

  //   try {
  //     await MoECapacitorCore.trackEvent({ eventName, eventAttributes, appId })
  //   } catch (err) {
  //     this.logMoError(err)
  //   }
  // }

  public async initPush(): Promise<void> {
    if (this.disabled) return

    try {
      if (isIOSApp) {
        await MoECapacitorCore.registerForPush()
      } else if (isAndroidApp) {
        await MoECapacitorCore.requestPushPermissionAndroid()
      }
    } catch (err) {
      this.logMoError(err)
    }
  }

  /**
   * https://developers.moengage.com/hc/en-us/articles/4417459093140-InApp-NATIV
   */
  public async displayInApp(): Promise<void> {
    if (this.disabled) return

    await MoECapacitorCore.showInApp({ appId })
  }

  public async resetUser(): Promise<void> {
    if (this.disabled) return

    try {
      await MoECapacitorCore.logoutUser({ appId })
    } catch (err) {
      this.logMoError(err)
    }
  }

  private setupListeners(): void {
    const analyticsService = di.get(AnalyticsService)

    void MoECapacitorCore.addListener('inAppCampaignShown', (data: MoEInAppLifecycleData) => {
      analyticsService.trackEvent(EVENT.MOE_APP_EVENT, {
        'Moe.event': 'inAppCampaignShown',
        ...this.flattenObject(data),
      })
    })

    void MoECapacitorCore.addListener('inAppCampaignDismissed', (data: MoEInAppLifecycleData) => {
      analyticsService.trackEvent(EVENT.MOE_APP_EVENT, {
        'Moe.event': 'inAppCampaignDismissed',
        ...this.flattenObject(data),
      })
    })

    void MoECapacitorCore.addListener('inAppCampaignClicked', (data: MoEInAppNavigationData) => {
      analyticsService.trackEvent(EVENT.MOE_APP_EVENT, {
        'Moe.event': 'inAppCampaignClicked',
        ...this.flattenObject(data),
      })
    })

    void MoECapacitorCore.addListener(
      'inAppCampaignCustomAction',
      (data: MoEInAppCustomActionData) => {
        analyticsService.trackEvent(EVENT.MOE_APP_EVENT, {
          'Moe.event': 'inAppCampaignCustomAction',
          ...this.flattenObject(data),
        })
      },
    )

    void MoECapacitorCore.addListener('pushClicked', (data: MoEPushCampaignData) => {
      di.get(AnalyticsService).trackEvent(EVENT.MOE_APP_EVENT, {
        'Moe.event': 'pushClicked',
        ...this.flattenObject(data),
      })

      // FIXME fix types when possible
      const { payload } = (data as unknown as { data: MoEPushCampaign }).data
      const url = (payload as unknown as StringMap<string>)['gcm_webUrl']

      void di.get(PushNotificationService).handlePushNavigation(url)
    })
  }

  private logMoError(err: any): void {
    logUtil.log('Moe error')
    logUtil.error(err)
  }

  // private get eventTrackingEnabled(): boolean {
  //   return !!getState().remoteConfig.moEventTrackingEnabled
  // }

  private flattenObject = (obj: StringMap<any>, nested = false): StringMap => {
    const resultObj: StringMap = {}
    const prefix = nested ? '' : 'Moe.'

    for (const [key, value] of Object.entries(obj)) {
      if (typeof value === 'object' && !Array.isArray(value)) {
        const tempObj = this.flattenObject(value, true)
        for (const [nestedKey, nestedValue] of Object.entries(tempObj)) {
          resultObj[`${prefix}${key}.${nestedKey}`] = nestedValue
        }
      } else {
        resultObj[`${prefix}${key}`] = value
      }
    }

    return resultObj
  }
}
