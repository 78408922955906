import { Goal, UFColor } from '@naturalcycles/shared'

export const COLOR_PREGNANT = 'PREGNANT'
export const COLOR_RECOVERY = 'Recovery'
export const COLOR_OFFLINE = 'Offline'
export const COLOR_OFFLINE_PARTNER = 'OfflinePartner'
export const COLOR_NO_DATA = 'NoData'
export const COLOR_GHOST = 'Ghost'
export const COLOR_WARNING = 'Warning'

const PREVENT_STATUS_BY_COLOR: Record<UFColor, string> = {
  [UFColor.GREEN]: 'fertility-notFertile',
  [UFColor.RED]: 'fertility-fertilePrevent',
  [UFColor.YELLOW]: 'fertility-fertile',
  [UFColor.PURPLE]: 'fertility-recovery-prevent', // Prevent users should not get this status, but adding a custom key so we will get "broken translations" error in Sentry if it happens
}

const PLAN_STATUS_BY_COLOR: Record<UFColor, string> = {
  [UFColor.GREEN]: 'fertility-notFertile',
  [UFColor.RED]: 'fertility-fertile',
  [UFColor.YELLOW]: 'fertility-uncertain',
  [UFColor.PURPLE]: 'fertility-recovery-plan', // same story as Prevent
}

const RECOVERY_STATUS_BY_COLOR: Record<UFColor, string> = {
  [UFColor.GREEN]: 'fertility-recovery',
  [UFColor.RED]: 'fertility-recovery',
  [UFColor.YELLOW]: 'fertility-recovery',
  [UFColor.PURPLE]: 'fertility-recovery',
}

const POSTPARTUM_STATUS_BY_COLOR: Record<UFColor, string> = {
  [UFColor.GREEN]: 'fertility-notFertile',
  [UFColor.RED]: 'fertility-fertilePrevent',
  [UFColor.YELLOW]: 'fertility-fertile',
  [UFColor.PURPLE]: '', // will use `txt-postpartum-days/weeks`
}

export const STATUS_BY_REDSCALE: Partial<Record<number, string>> = {
  1: 'fertility-peakFertility',
  2: 'fertility-veryHighFertility',
  3: 'fertility-highFertility',
  4: 'fertility-mediumFertility',
  5: 'fertility-lowFertility',
  6: 'fertility-fertilityUnlikely',
}

export const STATUS_BY_GOAL: Record<Goal, Record<UFColor, string>> = {
  [Goal.PREVENT]: PREVENT_STATUS_BY_COLOR,
  [Goal.PLAN]: PLAN_STATUS_BY_COLOR,
  [Goal.PREGNANT]: PLAN_STATUS_BY_COLOR, // Pregnant will have its own status, but lets keep this here as a safety
  [Goal.RECOVERY]: RECOVERY_STATUS_BY_COLOR,
  [Goal.POSTPARTUM]: POSTPARTUM_STATUS_BY_COLOR,
}
