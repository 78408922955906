import { AnalyticsEvent, AnalyticsView } from '@app/analytics/analytics.model'
import { Action, Reducer } from 'redux'

export interface OfflineEvents {
  mixpanelViews?: AnalyticsView[]
  mixpanelEvents?: AnalyticsEvent[]
}

type State = OfflineEvents

const initialState: State = {
  mixpanelViews: [],
  mixpanelEvents: [],
}

const actions: any = {
  addMixpanelEvent(state: State, action: { type: string; payload: AnalyticsEvent }): State {
    return {
      ...state,
      mixpanelEvents: [...(state.mixpanelEvents || []), action.payload],
    }
  },

  addMixpanelView(state: State, action: { type: string; payload: AnalyticsView }): State {
    return {
      ...state,
      mixpanelViews: [...(state.mixpanelViews || []), action.payload],
    }
  },

  clearOfflineEvents(): State {
    return {
      ...initialState,
    }
  },
}

// biome-ignore lint/style/useDefaultParameterLast: ok
export const offlineEventsReducer: Reducer<State> = (state = initialState, action: Action) => {
  if (actions[action.type]) return actions[action.type](state, action)
  return state
}
