import { BackendResponseFM } from '@naturalcycles/shared'
import { Action, Reducer } from 'redux'

type State = string

const initialState: State = ''

const actions: any = {
  setSessionId(_state: State, action: { type: string; payload: string }): State {
    return action.payload
  },

  onBackendResponse(state: State, action: { type: string; payload: BackendResponseFM }): State {
    return action.payload.sessionId || state
  },
}

// biome-ignore lint/style/useDefaultParameterLast: ok
export const sessionReducer: Reducer<string> = (state = initialState, action: Action) => {
  if (actions[action.type]) return actions[action.type](state, action)
  return state
}
