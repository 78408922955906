import { BackendResponseFM, SubscriptionStateFM } from '@naturalcycles/shared'
import { Action, Reducer } from 'redux'

const initialState: SubscriptionStateFM = {
  current: null,
  future: null,
}

const actions: any = {
  onBackendResponse(
    state: SubscriptionStateFM,
    action: { type: string; payload: BackendResponseFM }, // Todo: payload: BackendResponse
  ): SubscriptionStateFM {
    // replace!
    if (action.payload.subscriptions) {
      return {
        ...action.payload.subscriptions,
      }
    }

    return state
  },
}

export const subscriptionReducer: Reducer<SubscriptionStateFM> = (
  // biome-ignore lint/style/useDefaultParameterLast: ok
  state = initialState,
  action: Action,
) => {
  if (actions[action.type]) return actions[action.type](state, action)
  return state
}
