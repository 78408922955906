import { Injectable } from '@angular/core'
import { api } from '@app/srv/api.service'
import { DailyEntrySaveBatchInput } from '@naturalcycles/shared'

@Injectable({ providedIn: 'root' })
export class DailyEntryService {
  async saveBatch(input: DailyEntrySaveBatchInput): Promise<void> {
    await api.put('dailyentries/saveBatch', {
      json: input,
    })
  }
}
