import { Injectable } from '@angular/core'
import { PageGuard } from '@app/guards/page.guard'
import { getState } from '@src/app/srv/store.service'

@Injectable({
  providedIn: 'root',
})
export class VerifyEmailPageGuard extends PageGuard {
  className = 'VerifyEmailPageGuard'

  private hasLastPaymentGate(): boolean {
    return !!getState().account.lastPaymentGate
  }

  private hasVerifiedEmail(): boolean {
    return !!getState().account.emailVerified
  }

  get requirements(): boolean[] {
    const requirements = [this.hasSession(), !this.hasLastPaymentGate(), !this.hasVerifiedEmail()]
    return requirements
  }
}
