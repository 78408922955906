import { Action, Reducer } from 'redux'

interface State {
  hasSelectedPerimenopause: boolean
  shouldHidePerimenopause: boolean
}

const initialState: State = {
  hasSelectedPerimenopause: false,
  shouldHidePerimenopause: false,
}

const actions: any = {
  setHasSelectedPerimenopause(state: State, action: { type: string; payload: boolean }): State {
    return { ...state, hasSelectedPerimenopause: action.payload }
  },
  setShouldHidePerimenopause(state: State, action: { type: string; payload: boolean }): State {
    return { ...state, shouldHidePerimenopause: action.payload }
  },
}

export const perimenopauseBackdoorReducer: Reducer<State> = (
  // biome-ignore lint/style/useDefaultParameterLast: ok
  state = initialState,
  action: Action,
) => {
  if (actions[action.type]) return actions[action.type](state, action)
  return state
}
