import { inject, Injectable } from '@angular/core'
import { AnalyticsEvent, MixPanelServiceConf, ViewType } from '@app/analytics/analytics.model'
import { appVer } from '@app/cnst'
import { ABTestService, Bucket } from '@app/srv/abTest.service'
import { AdminService } from '@app/srv/admin.service'
import { AppearanceSettings } from '@app/srv/appearance.service'
import { dispatch, getState } from '@app/srv/store.service'
import { logUtil } from '@app/util/log.util'
import { _isEmptyObject, _Memo, _objectAssign, AnyObject } from '@naturalcycles/js-lib'
import {
  ActivationChannel,
  ExternalAccountId,
  Goal,
  HardwareId,
  partnerAnalyticsPrefix,
  ProductKey,
} from '@naturalcycles/shared'
import { isE2e } from '@src/environments/env.util'
import { env } from '@src/environments/environment'
import mixpanel from 'mixpanel-browser'
import { CompanionWatchService } from '../srv/companionWatch.service'
import { di } from '../srv/di.service'

interface MixpanelGenericProperties {
  'App version': string
  'OS version'?: string
  // distinct_id: string
  'view type': ViewType
  accountIdBucketAB?: Bucket
  complete?: boolean
  appearance?: string // AppearanceSettings as string
  fwVersion?: string
  // Legacy
  demo?: boolean
  goal?: string // Goal as string
  plan?: ProductKey
  hwId?: HardwareId // wrongly sent as number, should be String
  // Modern
  'Account.demoMode'?: boolean
  'Account.goal'?: string // Goal as string
  'Account.plan'?: ProductKey
  'Account.hwId'?: string // HardwareId as string
  'Account.activationChannel'?: string // ActivationChannel as string
  // Apple watch status
  'AppleWatch.isComplicationEnabled'?: boolean
  'AppleWatch.isPaired'?: boolean
  'AppleWatch.isReachable'?: boolean
  'AppleWatch.isWatchAppInstalled'?: boolean
}

// Mixpanel is disabled for e2e tests, to save some quota
const enabled = env.mixpanelEnabled && !isE2e

@Injectable({ providedIn: 'root' })
export class MixPanelService {
  private abTestService = inject(ABTestService)
  private adminService = inject(AdminService)

  public distinctId!: string
  public deviceId?: string

  private cfg(): MixPanelServiceConf {
    return env.mixpanelServiceConf
  }

  @_Memo()
  init(): void {
    if (!enabled) return

    // Expose Mixpanel as window.mixpanel (for analytics purposes)
    window.mixpanel = mixpanel
    mixpanel.init(this.cfg().mixpanelId, {
      autotrack: false,
      cross_subdomain_cookie: env.prod,
      // api_host: 'https://api-eu.mixpanel.com', // it only works if project is created with EU residency initially
    })
    this.distinctId = mixpanel.get_distinct_id()
    this.deviceId = mixpanel.get_property('$device_id')
  }

  identify(id: ExternalAccountId): void {
    if (!enabled) return

    mixpanel.identify(id)
    this.distinctId = mixpanel.get_distinct_id()
  }

  trackEvent(eventName: string, props = {}): void {
    if (!enabled) return
    if (!env.analyticsForAdmins && this.adminService.isAdmin() && !this.adminService.isTestUser()) {
      return
    } // don't track events in prod if you are logged in with red dot

    const properties = {
      ...this.getGenericProperties(),
      time: Date.now(),
      ...props,
    }

    logUtil.log('mixpanel trackEvent', eventName, props)

    if (!getState().ui.online) {
      const event: AnalyticsEvent = {
        name: eventName,
        properties,
      }
      dispatch('addMixpanelEvent', event)
    } else {
      mixpanel.track(eventName, properties)
    }
  }

  trackView(path: string, props?: AnyObject, partner?: boolean): void {
    const prefix = partner ? `${partnerAnalyticsPrefix} ` : ''

    this.trackEvent(prefix + 'Viewed ' + path, props)
  }

  reset(): void {
    if (!enabled) return
    // To be called when the user logs out
    // https://help.mixpanel.com/hc/en-us/articles/115004497803-Identity-Management-Best-Practices
    mixpanel.reset()
  }

  private getGenericProperties(): MixpanelGenericProperties {
    const { account, userDevice, userSettings, hwDevice } = getState()
    const p: MixpanelGenericProperties = {
      'App version': appVer,
      'OS version': userDevice.version,
      'view type': this.cfg().viewType,
      // distinct_id: this.distinctId,
    }

    if (account.externalAccountId) {
      _objectAssign(p, {
        accountIdBucketAB: this.abTestService.getBucketByAccountId(),
        complete: !!account.completeDate,
        appearance: AppearanceSettings[userSettings.appearance || AppearanceSettings.LIGHT],
        // Legacy
        demo: account.demoMode,
        goal: Goal[account.goal!],
        plan: account.plan,
        hwId: account.hwId,
        // Modern
        'Account.demoMode': account.demoMode,
        'Account.goal': Goal[account.goal!],
        'Account.plan': account.plan,
        'Account.hwId': HardwareId[account.hwId],
        'Account.activationChannel': ActivationChannel[account.activationChannel],
      })
    }

    if (account.hwId === HardwareId.T3_THERMOMETER) {
      _objectAssign(p, { fwVersion: hwDevice?.fwVersion })
    }

    const watchStatus = di.get(CompanionWatchService).watchStatus$.getValue()
    if (!_isEmptyObject(watchStatus)) {
      _objectAssign(p, {
        'AppleWatch.isComplicationEnabled': watchStatus.isComplicationEnabled,
        'AppleWatch.isPaired': watchStatus.isPaired,
        'AppleWatch.isReachable': watchStatus.isReachable,
        'AppleWatch.isWatchAppInstalled': watchStatus.isWatchAppInstalled,
      })
    }

    return p
  }
}
