import { Injectable } from '@angular/core'
import { NavigationParams } from '@app/cnst/nav.params.cnst'
import { PageGuard } from '@app/guards/page.guard'
import { GuideStyle } from '@naturalcycles/shared'
import { getState } from '@src/app/srv/store.service'

@Injectable({
  providedIn: 'root',
})
export class CardsPageGuard extends PageGuard {
  className = 'CardsPageGuard'

  get requirements(): boolean[] {
    const id = this.route.params[NavigationParams.ID]

    const requirements = [
      getState().guides.items.some(guide => guide.id === id && guide.style === GuideStyle.CARD),
    ]

    return requirements
  }
}
