import { BackendResponseFM } from '@naturalcycles/shared'
import { Action, Reducer } from 'redux'

export interface UserAchievements {
  newAchievement?: string
}

type State = UserAchievements

const initialState: UserAchievements = {}

const actions: any = {
  extendAchievements(state: State, action: { type: string; payload: UserAchievements }): State {
    return {
      ...state,
      ...action.payload,
    }
  },

  onBackendResponse(state: State, action: { type: string; payload: BackendResponseFM }): State {
    const { newAchievement, newAchievementShouldPopUp } = action.payload

    if (!newAchievementShouldPopUp) {
      return state
    }

    return {
      newAchievement: newAchievementShouldPopUp ? newAchievement : undefined,
    }
  },
}

// biome-ignore lint/style/useDefaultParameterLast: ok
export const achievementsReducer: Reducer<State> = (state = initialState, action: Action) => {
  if (actions[action.type]) return actions[action.type](state, action)
  return state
}
