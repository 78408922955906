import { QuizDataInput } from '@naturalcycles/shared'
import { AppQuizFlow, commonQuizFlow } from '@src/app/cnst/quiz.cnst'
import { Action } from 'redux'

export interface QuizState {
  flow: AppQuizFlow
  data: QuizDataInput | null
}

type State = QuizState

const initialState: State = {
  flow: [],
  data: null,
}

const actions: any = {
  replaceQuizFlow(state: State, action: { type: string; payload: AppQuizFlow }): State {
    return {
      data: state.data,
      flow: [...action.payload],
    }
  },

  updateQuizData(state: State, action: { type: string; payload: QuizDataInput }): State {
    return {
      data: {
        ...state.data,
        ...action.payload,
      },
      flow: state.flow,
    }
  },

  clearQuizData(): State {
    return {
      data: {},
      flow: commonQuizFlow,
    }
  },
}

// biome-ignore lint/style/useDefaultParameterLast: ok
export function quizReducer(state = initialState, action: Action): State {
  if (actions[action.type]) return actions[action.type](state, action)
  return state
}
