import { clickUid } from '@app/perf/dom.util'
import { nextIdle } from '@app/perf/idle.util'
import { SELECTORS } from '@app/perf/selectors'

export async function scenario03BottomTabs(): Promise<void> {
  await window.lagMon.run('calendarToMessages', async () => {
    await clickUid(SELECTORS.homeTabMyMessagesBtn)
    await nextIdle()
  })

  await window.lagMon.run('messagesToMyCycle', async () => {
    await clickUid(SELECTORS.homeTabMyCycleBtn)
    await nextIdle()
  })

  // Just go back to Calendar
  await clickUid(SELECTORS.homeTabCalendarBtn)
  await nextIdle()
}
