export enum QuizPage {
  privacy = 'privacy',
  name = 'name',
  usps = 'usps',
  birthday = 'birthday',

  // === SECTION 2
  // ====== prevent
  fertilityGoal = 'fertility-goal',
  fertilityGoalInfo = 'fertility-goal-info',
  birthControlMethod = 'birth-control-method',
  birthControlMethodInfo = 'birth-control-method-info',
  sideEffects = 'side-effects',
  sideEffectsInfo = 'side-effects-info',
  // ====== plan
  plannedPregnancyTiming = 'planned-pregnancy',

  // sex questions - start
  learnAboutSexInfo = 'learn-about-sex-info',
  bestTimeInCycle = 'best-time-in-cycle',
  knowingYourSexDrive = 'knowing-your-sex-drive',
  sexDriveInfo = 'sex-drive-info',
  sexFrequency = 'sex-frequency',
  knowPregnantDays = 'know-pregnant-days',
  menstrualCycleInfo = 'menstrual-cycle-info',
  testimonialInfo = 'testimonial-info',
  // sex questions - end

  // === SECTION 3
  hormonalBirthControlUsage = 'hormonal-birth-control-usage',

  // These two pages look the same, but their output is different, therefore it is better to keep them separate
  hormonalBirthControlHowLongPlan = 'hormonal-birth-control-how-long-plan',
  hormonalBirthControlHowLongPrevent = 'hormonal-birth-control-how-long-prevent',
  // =============================================================
  plannedPregnancyInfo = 'planned-pregnancy-info',
  cycleLength = 'cycle-length',
  periodRegularity = 'period-regularity',
  periodRegularityInfo = 'period-regularity-info',
  cycleStartDate = 'cycle-start-date',

  // These two pages look the same, but their output is different, therefore it is better to keep them separate
  medicalConditionsPlan = 'medical-conditions-plan',
  medicalConditionsPrevent = 'medical-conditions-prevent',
  // =============================================================
  medicalConditionsInfo = 'medical-conditions-info',
  compareBirthControlAB281 = 'compare-birth-control', // AB281
  doctorInvolvement = 'doctor-involvement',
  partnerInvolvement = 'partner-involvement',
  partnerInvolvementInfo = 'partner-involvement-info',
  followPregnancyInfo = 'follow-pregnancy-info',

  // === SECTION 4
  emotionalChangesInfo = 'emotional-changes-info',
  cycleChanges = 'cycle-changes',
  // ====== sub cycle changes
  cycleChangesEmotions = 'emotions',
  cycleChangesBleeding = 'bleeding',
  cycleChangesSkin = 'skin',
  cycleChangesSexAndLibido = 'sex-and-libido',
  cycleChangesCervicalMucus = 'cervical-mucus',
  cycleChangesPain = 'pain',
  // =======================
  trackingInfo = 'tracking-info',

  // === SECTION 5
  naturalMethod = 'natural-method',
  yourMeasuringDevice = 'your-measuring-device',
  yourDescription = 'your-description',
  howDoYouSleep = 'how-do-you-sleep',
  sleepWearable = 'sleep-wearable',
  whichWearable = 'which-wearable',
  consideringPurchase = 'considering-purchase',
  eligibleAppleWatch = 'eligible-apple-watch',
  purchaseNewWatch = 'purchase-new-watch',
  wearYourWatchToBed = 'wear-your-device-to-bed',
  fitResult = 'fit-result',
  deviceResult = 'device-result',
}
