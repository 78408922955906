import { clickUid, numpadTemp } from '@app/perf/dom.util'
import { nextIdle } from '@app/perf/idle.util'
import { SELECTORS } from '@app/perf/selectors'

export async function scenario06AutoAddDataOpen(): Promise<void> {
  await window.lagMon.run('autoAddDataOpenAndSave', async () => {
    await nextIdle()
    await numpadTemp('3656')
    await nextIdle()
    await clickUid(SELECTORS.addDataFooterSaveBtn)
    await nextIdle()
  })
}
