import { BackendResponseFM, HardwareDeviceTM } from '@naturalcycles/shared'
import { Action, Reducer } from 'redux'

// Omit, because 'id' is deprecated
export type HardwareDevice = Omit<HardwareDeviceTM, 'id'>

export interface PendingHardwareDeviceSettings extends Partial<HardwareDevice> {
  fahrenheit?: boolean
  lang?: string
  name1?: string
}

type State = HardwareDevice | null

const initialState: State = null

const actions: any = {
  setHardwareDevice(state: State, action: { type: string; payload: HardwareDevice }): State {
    return {
      ...state,
      ...action.payload,
    }
  },

  clearHardwareDevice(): State {
    return initialState
  },

  onBackendResponse(state: State, action: { type: string; payload: BackendResponseFM }): State {
    // replace!
    if (action.payload.hwDevice || action.payload.hwDevice === null) {
      return { ...action.payload.hwDevice }
    }

    return state
  },
}

// biome-ignore lint/style/useDefaultParameterLast: ok
export const hardwareDeviceReducer: Reducer<State> = (state = initialState, action: Action) => {
  if (actions[action.type]) return actions[action.type](state, action)
  return state
}
