import { runOutsideAngular } from '@app/srv/di.service'
import { setTimeoutNoZone } from '@app/util/zone.util'
import { _since, pDefer, Promisable, UnixTimestampMillis } from '@naturalcycles/js-lib'
import { logUtil } from './log.util'

/**
 * Execute `fn` until predicate function returns truthy value.
 * Resolves promise with whatever `fn` returned that satisfied the condition.
 *
 * Fn can return promise - then it will wait for it to resolve before checking predicate.
 * Predicate also can return promise.
 *
 * Default predicate checks if returned value is truthy.
 */
export async function waitFor<T = void>(
  id: string,
  fn: (...args: any[]) => Promisable<T>,
  predicate?: (res: T, attempt: number) => any,
  interval = 50,
  maxAttempts = 1000,
): Promise<T> {
  const done = pDefer<T>()
  const started = Date.now() as UnixTimestampMillis
  let attempt = 0
  predicate ||= Boolean

  const next = async (): Promise<void> => {
    // todo: check if we need try/catch there to be able to reject the promise
    const res = await fn()
    if (await predicate(res, ++attempt)) {
      logUtil.log(`[prf] waitFor [${id}] took ${_since(started)} and ${attempt} attempt(s)`)

      done.resolve(res)
    } else {
      if (maxAttempts && attempt > maxAttempts) {
        logUtil.log(`[prf] waitFor [${id}] took ${_since(started)} and ${attempt} attempt(s)`)
        logUtil.warn(`[prf] waitFor [${id}] reached maxAttempts=${maxAttempts}`)

        done.resolve(undefined as any)
      } else {
        setTimeoutNoZone(() => next(), interval)
      }
    }
  }

  void next()

  return await done
}

// just a wrapper
export async function waitForOutsideAngular<T>(
  id: string,
  fn: (...args: any[]) => Promisable<T>,
  predicate?: (res: T, attempt: number) => any,
  interval?: number,
  maxAttempts?: number,
): Promise<T> {
  return await runOutsideAngular(() => waitFor(id, fn, predicate, interval, maxAttempts))
}
