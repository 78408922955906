import {
  BreakpointAction,
  ChoiceFunction,
  EligibleAppleWatchBreakpointOption,
  QuizDataInput,
} from '@naturalcycles/shared'
import { Section } from '@src/app/cnst/quiz.cnst'
import { addPageToFlowAfterCurrent, removePageFromFlow } from '@src/app/util/quiz.util'
import { QuizPage } from '../quiz-pages.cnst'

const noTransformFunction: ChoiceFunction<QuizPage, QuizDataInput> = flow => {
  return addPageToFlowAfterCurrent(
    flow,
    Section.routine,
    QuizPage.eligibleAppleWatch,
    QuizPage.purchaseNewWatch,
  )
}

const yesTransformFunction: ChoiceFunction<QuizPage, QuizDataInput> = flow => {
  return removePageFromFlow(flow, Section.routine, QuizPage.purchaseNewWatch)
}

export const eligibleAppleWatch: BreakpointAction<
  EligibleAppleWatchBreakpointOption,
  QuizPage,
  QuizDataInput
> = {
  [EligibleAppleWatchBreakpointOption.yes]: yesTransformFunction,
  [EligibleAppleWatchBreakpointOption.no]: noTransformFunction,
}
