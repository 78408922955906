import { inject, Injectable } from '@angular/core'
import {
  BiometricAuth,
  BiometryError,
  BiometryErrorType,
  BiometryType,
  CheckBiometryResult,
} from '@aparajita/capacitor-biometric-auth'
import { _filterEmptyValues, _stringify, localTime } from '@naturalcycles/js-lib'
import { EVENT } from '@src/app/analytics/analytics.cnst'
import { AnalyticsService } from '@src/app/analytics/analytics.service'
import { isIOSApp } from '@src/app/cnst/userDevice.cnst'
import { BiometricAuthBlockModal } from '@src/app/modals/biometric-auth-block/biometric-auth-block.modal'
import { EventService } from '@src/app/srv/event.service'
import { Popup, PopupController, Priority } from '@src/app/srv/popup.controller'
import { dispatch, getState } from '@src/app/srv/store.service'
import { tr } from '@src/app/srv/translation.util'
import { logUtil } from '@src/app/util/log.util'
import { env } from '@src/environments/environment'
import { firstValueFrom, map, Subject } from 'rxjs'

export const biometryType = 'Face ID'

/**
 * Biometric auth will be skipped if the app is resumed within this number of seconds.
 */
const BIOMETRIC_AUTH_BLOCK_TIMEOUT_SEC = env.dev ? 5 : 60

interface BiometricAuthResult {
  success: boolean
  errorCode?: BiometryErrorType | 'unknown'
}

@Injectable({ providedIn: 'root' })
export class BiometricAuthService {
  private popupController = inject(PopupController)
  private eventService = inject(EventService)
  private analyticsService = inject(AnalyticsService)

  private onPause$ = new Subject<void>()
  public onResume$ = new Subject<void>()

  public async faceIdSupported(): Promise<boolean> {
    if (!isIOSApp) return false
    const { biometryType } = await BiometricAuth.checkBiometry()
    return biometryType === BiometryType.faceId
  }

  public isScreenBlocked(): boolean {
    this.onPause$.next()
    return !!this.popupController.getActivePopupById('modal-biometric-auth-block')
  }

  /**
   * Shows biometric auth block modal if biometric auth is enabled.
   * The modal shows on cold start or onResume.
   * @returns `true` if authentication isn't needed or authentication is succeeded, `false` otherwise.
   */
  public async authenticateIfNeeded(coldStart: boolean): Promise<boolean> {
    const authenticated = await this.checkAndAuthenticate(coldStart)

    if (!authenticated) return false

    dispatch('setLastActiveNow')
    return true
  }

  private async checkAndAuthenticate(coldStart: boolean): Promise<boolean> {
    const { biometricAuthEnabled, lastActive } = getState().userSettings
    if (!biometricAuthEnabled) return true

    if (
      !coldStart &&
      lastActive &&
      lastActive + BIOMETRIC_AUTH_BLOCK_TIMEOUT_SEC > localTime.nowUnix()
    ) {
      return true
    }

    const modal = await this.presentBiometricAuthBlockModal()
    this.onResume$.next()

    // Wait for modal to be dismissed. If the app is paused before that, return false.
    const { data } = await Promise.race([
      modal.onDidDismiss(),
      firstValueFrom(this.eventService.onPause$.pipe(map(() => ({ data: false })))),
    ])
    return data
  }

  private async presentBiometricAuthBlockModal(): Promise<Popup> {
    const authBlockModal = this.popupController.getActivePopupById('modal-biometric-auth-block')
    if (authBlockModal) return authBlockModal

    return await this.popupController.presentModal(
      {
        component: BiometricAuthBlockModal,
        animated: false,
      },
      'modal-biometric-auth-block',
      Priority.IMMEDIATE,
    )
  }

  /**
   * Checks if the device has biometric authentication capablity.
   */
  public async checkAvailability(): Promise<CheckBiometryResult> {
    const availability = await BiometricAuth.checkBiometry()
    void this.analyticsService.trackEvent(EVENT.BIO_AUTH_CHECK_AVAILABILITY, {
      ..._filterEmptyValues(availability),
      biometryType: BiometryType[availability.biometryType],
    })
    return availability
  }

  /**
   * Calls biometric authentication API.
   * true if authentication was successful, false otherwise
   * @param allowDeviceCredential Whether to allow passcode as a fallback method for biometric auth (default: true)
   */
  public async authenticate(allowDeviceCredential = true): Promise<BiometricAuthResult> {
    try {
      // After two failed Face ID attempts, the system offers a fallback option,
      // but stops trying to authenticate with Face ID.
      // https://developer.apple.com/documentation/localauthentication/lapolicy/deviceownerauthenticationwithbiometrics
      await BiometricAuth.authenticate({
        cancelTitle: tr('btn-cancel'),
        allowDeviceCredential,
      })
      return { success: true }
    } catch (err) {
      void this.analyticsService.trackEvent(EVENT.BIO_AUTH_FAILED, {
        code: err instanceof BiometryError ? err.code : 'unknown',
        response: err instanceof BiometryError ? err.message : _stringify(err),
      })

      if (err instanceof BiometryError) {
        return { success: false, errorCode: err.code }
      }

      logUtil.error(`Biometric Auth Error: ${err}`)
      return { success: false, errorCode: 'unknown' }
    }
  }
}
