<ion-button
  uid="addData__notes__display"
  class="notes"
  expand="block"
  [disabled]="disabled"
  (click)="openInput()"
>
  <ion-label
    class="notes__text"
    [class.notes__text--placeholder]="!notes && !!placeholder"
    [style.--maxLines]="maxLines"
  >
    {{ notes || placeholder }}
  </ion-label>
</ion-button>
