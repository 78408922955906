import { inject, Injectable } from '@angular/core'
import { EVENT } from '@app/analytics/analytics.cnst'
import { AnalyticsService } from '@app/analytics/analytics.service'
import { usesBluetoothDevice, usesConnectedThermometer } from '@app/cnst/hardware.cnst'
import { HardwareDevice, PendingHardwareDeviceSettings } from '@app/reducers/hardwareDevice.reducer'
import { FirmwareUpdate } from '@app/reducers/userSettings.reducer'
import { api } from '@app/srv/api.service'
import { select2 } from '@app/srv/store.service'
import { convertBlobToBase64 } from '@app/util/convertBlobToBase64'
import { logUtil } from '@app/util/log.util'
import { Directory, Encoding, Filesystem } from '@capacitor/filesystem'
import { _omit, localTime } from '@naturalcycles/js-lib'
import {
  BackendResponseBM,
  BatteryStatus,
  HardwareDeviceSaveInput,
  HardwareId,
} from '@naturalcycles/shared'
import { normalize } from '@sentry/utils'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { map } from 'rxjs/operators'
import { BluetoothService } from './bluetooth.service'
import { dispatch, getState } from './store.service'

@Injectable({ providedIn: 'root' })
export class HardwareDeviceService {
  private analyticsService = inject(AnalyticsService)
  private bluetoothService = inject(BluetoothService)

  private hwDevice$ = select2(s => s.hwDevice)

  public hardwareDeviceSettingsSaved$ = new Subject<Partial<PendingHardwareDeviceSettings>>()
  public hwDeviceStash$ = new BehaviorSubject<HardwareDevice | null>(null)

  private testMeasurementDone?: boolean

  // Will make hardwareDevice non-optional and make all props optional, for frontend convenience :)
  public hardwareDevice$: Observable<Partial<HardwareDevice>> = this.hwDevice$.pipe(
    map(hardwareDevice => {
      this.testMeasurementDone = hardwareDevice?.testMeasurementDone

      return { ...hardwareDevice }
    }),
  )

  public saveBatteryStatus(batteryStatus: BatteryStatus, batteryPercentage?: number): void {
    const { hwDevice } = getState()

    if (!hwDevice) return

    void this.extendHardwareDevice({ batteryStatus })

    void this.analyticsService.trackEvent(EVENT.BLUETOOTH_BATTERY_STATUS, {
      batteryPercentage,
      batteryStatus: BatteryStatus[batteryStatus],
    })
  }

  public async extendHardwareDevice(props: Partial<PendingHardwareDeviceSettings>): Promise<void> {
    const {
      account: { hwId },
      hwDevice,
    } = getState()

    if (!hwDevice?.mac || !usesConnectedThermometer()) return

    const { fwVersion } = props
    if (hwDevice?.fwVersion && fwVersion && fwVersion !== hwDevice.fwVersion) {
      const fwUpdate: FirmwareUpdate = {
        hwId: HardwareId.T3_THERMOMETER,
        mac: hwDevice.mac,
        previousVersion: hwDevice.fwVersion,
        fwVersion,
        timestamp: localTime.nowUnix(),
      }
      dispatch('extendFirmwareUpdates', fwUpdate)
    }

    this.hardwareDeviceSettingsSaved$.next(props)

    dispatch(
      'extendPendingBluetoothSettings',
      _omit(props, ['mac', 'testMeasurementDone', 'batteryStatus', 'fwVersion']),
    ) // exclude props which are not settings

    Object.entries(props).forEach(([name, value]) => {
      void this.analyticsService.trackEvent(EVENT.THERMOMETER_SETTINGS_CHANGE, {
        name,
        value,
      })
    })

    await this.saveHardwareDevice({ ...hwDevice, ...props }, hwId)
  }

  /**
   * To be called only when pairing or from `extendHardwareDevice` above
   */
  public async saveHardwareDevice(hardwareDevice: HardwareDevice, hwId: HardwareId): Promise<void> {
    const { hwDevice } = await api.put<BackendResponseBM>('hardwaredevices', {
      json: {
        ...hardwareDevice,
        hwId,
      } satisfies HardwareDeviceSaveInput,
    })

    if (hwDevice) return

    // update local state in case backend didnt return updated hwDevice
    dispatch('setHardwareDevice', hardwareDevice)
  }

  public clearPendingBluetoothSettings(): void {
    dispatch('clearPendingBluetoothSettings')
  }

  public async archiveHardwareDevice(mac: string, saveTestMeasurement?: boolean): Promise<void> {
    // TODO: update when NCB3 supports archived HardwareDevices
    return await this.clearHardwareDevice(mac, saveTestMeasurement)
  }

  public async clearHardwareDevice(mac: string, saveTestMeasurement?: boolean): Promise<void> {
    if (saveTestMeasurement) {
      dispatch('extendUserSettings', { testMeasurementDone: this.testMeasurementDone })
    }

    // manually clear hwDevice since backend wont return hwDevice: null here
    dispatch('clearHardwareDevice')

    if (usesBluetoothDevice()) await this.bluetoothService.stopScan('HardwareDevice cleared')

    // fake thermometer
    if (mac === '00:00:00:00:00:00') return

    await api.delete(`hardwaredevices/${mac}`)
  }

  public async downloadFWBinary(version: string): Promise<boolean> {
    const { latestT3FWVersionDownloaded } = getState().userSettings
    if (latestT3FWVersionDownloaded && latestT3FWVersionDownloaded >= version) return true

    try {
      const blob = await api.get<Blob>(`hardwaredevices/download-fw/${version}`, {
        responseType: 'blob',
      })
      const base64 = await convertBlobToBase64(blob)

      await Filesystem.mkdir({
        directory: Directory.Data,
        path: 'fw',
      }).catch(() => {}) // folder already created

      const filename = this.getFWFilename(version)
      await Filesystem.writeFile({
        path: `fw/${filename}.bin`,
        data: base64,
        directory: Directory.Data,
        encoding: Encoding.UTF8,
      })

      dispatch('extendUserSettings', { latestT3FWVersionDownloaded: version })

      this.analyticsService.trackEvent(EVENT.FW_DOWNLOADED, { version, fileSize: blob.size })
    } catch (err) {
      logUtil.log('downloadFWBinary failed', JSON.stringify(normalize(err)))
      return false
    }

    return true
  }

  public getFWFilename(version: string): string {
    return `fw_${version.replace(/\./g, '_')}`
  }
}
