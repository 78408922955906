import { Action, Reducer } from 'redux'

export interface MeasureStreakState {
  current: number
  numberOfStreaks: number
  longest: number
  daysSinceTemp: number
  lastStreakLength: number
  lastSnackbarShownDate?: string
}

type State = MeasureStreakState

const initialState: State = {
  current: 0,
  numberOfStreaks: 0,
  longest: 0,
  daysSinceTemp: 0,
  lastStreakLength: 0,
}

const actions: any = {
  extendMeasureStreak(state: State, action: { type: string; payload: MeasureStreakState }): State {
    return {
      ...state,
      ...action.payload,
    }
  },
}

// biome-ignore lint/style/useDefaultParameterLast: ok
export const measureStreakReducer: Reducer<State> = (state = initialState, action: Action) => {
  if (actions[action.type]) return actions[action.type](state, action)
  return state
}
