import { _numberEnumKeys } from '@naturalcycles/js-lib'
import { Goal, PregnancyReason, UnplannedPregnancyCause } from '@naturalcycles/shared'
import { InfoTemplate } from '../_templates/info/flow-info.page'
import { RadioGroupTemplate } from '../_templates/radio-group/flow-radio-group.page'
import { SingleSelectTemplate } from '../_templates/single-select/flow-single-select.page'
import { FlowId, FlowInputData, FlowTemplate } from '../flow.cnst'
import { UnplannedPregnancyTrigger } from './unplanned-pregnancy.cnst'
import { UnplannedPregnancyFlowDataInput } from './unplanned-pregnancy.service'

type InputType = FlowInputData<UnplannedPregnancyFlowDataInput>

type GetFlowConfigFn<T> = (input: InputType) => T
type GetInfoScreenFn = (input: InputType) => InfoTemplate | undefined

const pregnancyReason: GetFlowConfigFn<RadioGroupTemplate<number | null>> = input => ({
  analyticsPageName: 'PregnancyReasonPage',
  template: FlowTemplate.RADIO,
  title: 'txt-unplanned-pregnancy-reason-title',
  body: 'txt-unplanned-pregnancy-reason-body',
  saveButton: {
    label: 'txt-next',
    uid: 'pregnancy__reason__nextBtn',
  },
  radioGroups: [
    {
      key: 'pregnancyReason',
      items: [
        {
          title: 'txt-unplanned-pregnancy-reason--UNPLANNED',
          value: PregnancyReason.UNPLANNED,
          uid: 'pregnancy__reason--UNPLANNED',
        },
        {
          title: 'txt-unplanned-pregnancy-reason--INDIFFERENT',
          value: PregnancyReason.INDIFFERENT,
          uid: 'pregnancy__reason--INDIFFERENT',
        },
        {
          title: 'txt-unplanned-pregnancy-reason--PLANNED--' + Goal[input.currentGoal!],
          value: PregnancyReason.PLANNED,
          uid: 'pregnancy__reason--PLANNED',
        },
        {
          title: 'txt-unplanned-pregnancy-reason--NOT_PREGNANT',
          value: -1,
          uid: 'pregnancy__reason--NOT_PREGNANT',
        },
        {
          title: 'txt-prefer-not-to-answer',
          value: null,
          uid: 'pregnancy__reason--SKIP',
        },
      ],
    },
  ],
  sideEffects: (input: InputType) => {
    const { pregnancyReason, switchToPregnant, trigger } = input

    switch (pregnancyReason) {
      case undefined:
        return {
          unplannedPregnancyCause: undefined,
          switchToPregnant: undefined,
        }

      case null:
        return {
          unplannedPregnancyCause: null,
          switchToPregnant: null,
        }

      case PregnancyReason.PLANNED:
        return {
          unplannedPregnancyCause: null,
          switchToPregnant:
            switchToPregnant || trigger === UnplannedPregnancyTrigger.GOAL ? true : undefined,
        }

      default:
        return {
          unplannedPregnancyCause: undefined,
          switchToPregnant: null,
        }
    }
  },
})

const unplannedPregnancyCause: GetFlowConfigFn<
  RadioGroupTemplate<UnplannedPregnancyCause>
> = _input => ({
  analyticsPageName: 'UnplannedPregnancyCausePage',
  template: FlowTemplate.RADIO,
  title: 'txt-unplanned-pregnancy-cause-title',
  body: 'txt-unplanned-pregnancy-cause-body',
  saveButton: {
    label: 'txt-finish',
    uid: 'unplanned__pregnancy__cause__nextBtn',
  },
  radioGroups: [
    {
      key: 'unplannedPregnancyCause',
      items: _numberEnumKeys(UnplannedPregnancyCause).map(key => {
        return {
          title: 'txt-unplanned-pregnancy-cause--' + key,
          value: UnplannedPregnancyCause[key],
          uid: 'unplanned__pregnancy__cause--' + key,
        }
      }),
    },
  ],
})

const switchToPregnant: GetFlowConfigFn<SingleSelectTemplate<true | null>> = input => ({
  analyticsPageName: 'FollowPlannedPage',
  template: FlowTemplate.SINGLE_SELECT,
  title: 'txt-follow-planned-title',
  body: 'txt-follow-planned-body',
  buttons: [
    {
      uid: 'goalPregnant__button',
      label: 'txt-enter--' + Goal[Goal.PREGNANT],
      value: true,
    },
    {
      uid: 'goalPrevent__button',
      label: input.currentGoal === Goal.PREVENT ? 'txt-keep-using--PREVENT' : 'btn-no-thank-you',
      value: null,
      outline: true,
    },
  ],
})

// Order of this map is important, it decides which page comes next
export const UNPLANNED_PREGNANCY_CONFIG = new Map<
  keyof UnplannedPregnancyFlowDataInput,
  GetFlowConfigFn<any>
>([
  ['pregnancyReason', pregnancyReason],
  ['unplannedPregnancyCause', unplannedPregnancyCause],
  ['switchToPregnant', switchToPregnant],
])

/**
 * Map of functions that can return an info screen based on the previous page and data.
 */
export const UNPLANNED_PREGNANCY_INFO_SCREEN = new Map<
  keyof UnplannedPregnancyFlowDataInput,
  GetInfoScreenFn
>([])

export const UNPLANNED_PREGNANCY_PAGES_BY_ROUTE = [...UNPLANNED_PREGNANCY_CONFIG.entries()].map(
  ([key, value]) => {
    const config = value({}) || {}
    return {
      key: `/flow/${FlowId.UNPLANNED_PREGNANCY}/${config?.template}/${key}`,
      value: config.analyticsPageName,
    }
  },
)
