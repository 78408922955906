import { _deepCopy } from '@naturalcycles/js-lib'
import {
  BirthControlMethodBreakpointOption,
  BreakpointAction,
  ChoiceFunction,
  QuizDataInput,
} from '@naturalcycles/shared'
import { Section } from '@src/app/cnst/quiz.cnst'
import { QuizPage } from '../quiz-pages.cnst'

const hormonalTransformFunction: ChoiceFunction<QuizPage, QuizDataInput> = flow => {
  if (!flow[Section.goal]!.includes(QuizPage.sideEffects)) {
    flow[Section.goal] = [...flow[Section.goal]!, QuizPage.sideEffects]
  }

  flow[Section.cycle] = [QuizPage.hormonalBirthControlHowLongPrevent]
  return flow
}

const copperIUDTransformFunction: ChoiceFunction<QuizPage, QuizDataInput> = flow => {
  if (!flow[Section.goal]!.includes(QuizPage.sideEffects)) {
    flow[Section.goal] = [...flow[Section.goal]!, QuizPage.sideEffects]
  }

  flow[Section.cycle] = [
    QuizPage.cycleLength,
    QuizPage.periodRegularity,
    QuizPage.periodRegularityInfo,
    QuizPage.cycleStartDate,
    QuizPage.medicalConditionsPrevent,
  ]

  return flow
}

const nonHormonalTransformFunction: ChoiceFunction<QuizPage, QuizDataInput> = flow => {
  const f = _deepCopy(flow)

  /**
   * When nonHormonal is selected, sideEffects page is skipped.
   * QuizPage.sideEffects is in the array in case the user selected hormonal/copperUID earlier.
   */
  let position = f[Section.goal]!.indexOf(QuizPage.sideEffects)
  if (position !== -1) f[Section.goal]!.splice(position, 1)

  /**
   * When nonHormonal is selected, sideEffectsInfo page is skipped.
   * Page.sideEffectsInfo is in the array in case the user selected hormonal/copperUID earlier and selected a side effect.
   */
  position = f[Section.goal]!.indexOf(QuizPage.sideEffectsInfo)
  if (position !== -1) f[Section.goal]!.splice(position, 1)

  f[Section.cycle] = [
    QuizPage.cycleLength,
    QuizPage.periodRegularity,
    QuizPage.periodRegularityInfo,
    QuizPage.cycleStartDate,
    QuizPage.medicalConditionsPrevent,
  ]
  return f
}

export const birthControlMethod: BreakpointAction<
  BirthControlMethodBreakpointOption,
  QuizPage,
  QuizDataInput
> = {
  // Hormonal
  [BirthControlMethodBreakpointOption.pill]: hormonalTransformFunction,
  [BirthControlMethodBreakpointOption.shot]: hormonalTransformFunction,
  [BirthControlMethodBreakpointOption.implant]: hormonalTransformFunction,
  [BirthControlMethodBreakpointOption.hormonalIUD]: hormonalTransformFunction,
  [BirthControlMethodBreakpointOption.patch]: hormonalTransformFunction,
  [BirthControlMethodBreakpointOption.nuvaRing]: hormonalTransformFunction,

  // Copper IUD
  [BirthControlMethodBreakpointOption.copperIUD]: copperIUDTransformFunction,

  // Non-hormonal
  [BirthControlMethodBreakpointOption.condom]: nonHormonalTransformFunction,
  [BirthControlMethodBreakpointOption.pullOut]: nonHormonalTransformFunction,
  [BirthControlMethodBreakpointOption.other]: nonHormonalTransformFunction,
  [BirthControlMethodBreakpointOption.none]: nonHormonalTransformFunction,
}
