/**
 * "1234567890" with chunkSize=2 will result in:
 * ["12", "34", "56", "78", "90"]
 */
import { ClassName } from '@app/analytics/analytics.cnst'

export function splitStringInChunks(s: string, chunkSize: number): string[] {
  const regex = new RegExp(`(.|[\\r\\n]){1,${chunkSize}}`, 'g')
  return s.match(regex) as string[]
}

export function getClassName(obj: any): string | undefined {
  return (obj as ClassName)?.className
}
