<ion-radio-group [value]="value" (ionChange)="ionChange($any($event))">
  @if (title) {
    <h3>{{ title | tr }}</h3>
  }

  @for (item of items; track item.value) {
    @if (item.infoButton) {
      <app-info-button
        class="ion-align-self-center"
        [html]="item.infoButton | tmd"
        [small]="true"
      />
    }

    <ion-radio
      slot="start"
      [value]="item.value"
      [attr.uid]="item.uid"
      [disabled]="item.disabled"
      labelPlacement="end"
      justify="start"
      alignment="start"
      [class.hasIcon]="!!item.icon"
    >
      @if (item.icon; as icon) {
        <ion-icon [src]="icon" />
      }

      <div>
        <div>{{ item.title }}</div>

        @if (item.subtitle) {
          <div class="radioGroup__subtitle ion-text-wrap">
            {{ item.subtitle }}
          </div>
        }
      </div>
    </ion-radio>
  }
</ion-radio-group>
