import { BackendResponseFM, CartState, Price } from '@naturalcycles/shared'
import { AnyAction, Reducer } from 'redux'

// @ts-expect-error
const initialState: CartState = {
  items: [],
  totalPrice: new Price('0[USD]'),
  totalPriceWithoutDiscount: new Price('0[USD]'),
}

const actions: any = {
  replaceCart(_state: CartState, action: { type: string; payload: CartState }): CartState {
    return {
      ...action.payload,
    }
  },

  extendCart(state: CartState, action: { type: string; payload: CartState }): CartState {
    return {
      ...state,
      ...action.payload,
    }
  },

  onBackendResponse(
    state: CartState,
    action: { type: string; payload: BackendResponseFM },
  ): CartState {
    // replace!
    if (action.payload.cart) {
      return {
        ...action.payload.cart,
      }
    }

    return state
  },
}

// biome-ignore lint/style/useDefaultParameterLast: ok
export const cartReducer: Reducer<CartState> = (state = initialState, action: AnyAction) => {
  if (actions[action.type]) return actions[action.type](state, action)
  return state
}
